import { z } from "zod";

export const shopifyMetafieldSchema = z.object({
  namespace: z.string(),
  key: z.string(),
  type: z.string(),
  value: z.union([z.string(), z.number(), z.boolean()]).optional(),
});

export const shopifyMetafieldNamespacedKeySchema = shopifyMetafieldSchema.pick({
  namespace: true,
  key: true,
});

export type ShopifyMetafield = z.infer<typeof shopifyMetafieldSchema>;
export type ShopifyMetafieldNamespacedKey = z.infer<
  typeof shopifyMetafieldNamespacedKeySchema
>;
