import * as React from "react";

import { getAnnouncementFeatureFlags } from "@editor/infra/featureFlags";
import { getAnnouncementsByPostHogFeatureFlag } from "@editor/sanity/client";
import useCurrentUser from "@hooks/useCurrentUser";
import { useModal } from "@hooks/useModal";

import { isSameDay } from "date-fns";
import isEmpty from "lodash-es/isEmpty";

const useUserHasNewAnnouncements = () => {
  const { openModal } = useModal();
  const { user, isLoading } = useCurrentUser();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    const showAnnouncements = async () => {
      try {
        const announcementsData = await getAnnouncementsData();
        if (!isEmpty(announcementsData)) {
          openModal({
            type: "announcementsModal",
            props: { announcements: announcementsData! },
          });
        }
      } catch (error) {
        console.error("Error trying to load announcements: ", error);
      }
    };
    if (!isLoading && user) {
      if (user?.registeredAt) {
        const today = new Date();
        const registeredAt = new Date(user?.registeredAt);
        // NOTE (Sebas, 2023-12-29): We only show the announcements after the user
        // has been registered for 1 day.
        if (!isSameDay(today, registeredAt)) {
          void showAnnouncements();
        }
      } else {
        // NOTE (Sebas, 2023-12-29): In case the user has no registeredAt date we show
        // the announcements because this means that they are an old user.
        void showAnnouncements();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, user]);
};

const getAnnouncementsData = async () => {
  try {
    const announcementFeatureFlags = getAnnouncementFeatureFlags();
    const newAnnouncementsFeatureFlags: string[] = [];

    announcementFeatureFlags.forEach((flag) => {
      const flagStatus = localStorage.getItem(`replo.${flag}`);
      if (!flagStatus) {
        newAnnouncementsFeatureFlags.push(flag);
      }
    });

    if (newAnnouncementsFeatureFlags.length > 0) {
      const announcementsData = await getAnnouncementsByPostHogFeatureFlag(
        newAnnouncementsFeatureFlags,
      );
      if (announcementsData.length > 0) {
        return announcementsData;
      }
    }
    return [];
  } catch (error) {
    console.error("Error trying to load announcements: ", error);
  }
};

export default useUserHasNewAnnouncements;
