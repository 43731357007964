import type { EditorDispatch, EditorRootState } from "@editor/store";
import type { ComponentActionType } from "@editor/types/component-action-type";
import type { CoreState } from "@editor/types/core-state";
import type { AnyAction, PayloadAction } from "@reduxjs/toolkit";
import type {
  MetafieldKey,
  MetafieldNamespace,
  ProductId,
  ProductMetafieldsDependency,
  VariantId,
  VariantMetafieldsDependency,
} from "replo-runtime/shared/types";
import type {
  PreviewElementProps,
  ReploElement,
  ReploPartialElement,
  UpdateElementSource,
} from "schemas/generated/element";

import { API_ACTIONS } from "@constants/action-types";
import { EditorActionType } from "@constants/editor-action-types";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectLocaleData,
  selectProductData,
  selectShopifyUrlRoot,
  setProductMetafieldValues,
  setVariantsMetafieldValues,
} from "@editor/reducers/commerce-reducer";
import {
  setLastSelectedId,
  setMultipleSelectedIds,
} from "@editor/reducers/selection-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { setIsRichTextEditorFocused } from "@editor/reducers/ui-reducer";
import { trpcClient } from "@editor/utils/trpc";
import {
  applyComponentRTKAction,
  selectDataTablesMapping,
  selectDraftElementId,
  selectElementsMapping,
  selectProjectId,
  setDraftElement as setDraftElementAction,
  setPendingElementUpdate,
} from "@reducers/core-reducer";
import {
  calculateDependencies,
  getMetafieldsNamespaceKeyTypeMapping,
  getProductsDataForDependencies,
} from "@utils/dependencies";

import groupBy from "lodash-es/groupBy";
import mapValues from "lodash-es/mapValues";
import size from "lodash-es/size";
import uniq from "lodash-es/uniq";
import { RSAA } from "redux-api-middleware";
import {
  DependencyType,
  MetafieldEntityType,
} from "replo-runtime/shared/types";
import { recordValues } from "replo-runtime/shared/utils/object";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";

type SetDraftElementPayloadOptions = {
  minimizeLeftSidebar: boolean;
};

export type SetDraftElementPayload = {
  id?: string | null;
  componentId?: string | null;
  selectedIds?: string[];
  repeatedIndex?: string | null;
  symbolId?: string;
  variantId?: string;
  options?: SetDraftElementPayloadOptions;
};

export type SetComponentVariantPayload = {
  componentId?: string;
  variantId?: string;
};

const getSetSelectIdsToActions = (payload: SetDraftElementPayload) => {
  let { selectedIds } = payload;
  const actions: (PayloadAction<string[]> | PayloadAction<string | null>)[] =
    [];
  // Note: Ovishek (2022-03-29) If the selectedIds are not provided, then we need to set the selectedIds according to draftComponentId
  if (!selectedIds) {
    if (payload.componentId === null) {
      selectedIds = [];
      actions.push(setLastSelectedId(null));
    } else if (payload.componentId) {
      selectedIds = [payload.componentId];
      actions.push(setLastSelectedId(payload.componentId));
    }
  }

  if (selectedIds) {
    actions.push(setMultipleSelectedIds(selectedIds));
  }
  return actions;
};

export const setDraftElement = (payload: SetDraftElementPayload) => {
  const actions: (
    | PayloadAction<SetDraftElementPayload>
    | PayloadAction<string[]>
    | PayloadAction<string | null>
    | PayloadAction<boolean>
  )[] = [setDraftElementAction(payload)];

  // Note (Ovishek, 2022-05-25, REPL-2317) we are setting isRichTextEditorFocused to be "false" here, b/c rte is too dumb that
  // it's onBlur doesn't always trigger if you click on the canvas from rte editing mode. So it's safe to make
  // it false here. This solves the problem with copy/pasting after editing on rte.
  actions.push(
    ...getSetSelectIdsToActions(payload),
    setIsRichTextEditorFocused(false),
  );

  return actions;
};

/**
 * Create a thunk (action) which executes a given action _then_ updates a given
 * element, or queues the element for immediate update if there's already an
 * element update in progress.
 *
 * Use this action creator any time you need to update and save an element.
 */
export const createElementUpdatingThunk = (
  action: any,
  getElementId: (getState: () => EditorRootState) => string | null,
  source: UpdateElementSource = "component",
  isSettingHomepage: boolean = false,
) => {
  return (dispatch: (action: any) => any, getState: () => EditorRootState) => {
    const state = getState();
    const coreState = state.core;
    const elementId = getElementId(getState);
    const element = getFromRecordOrNull(state.core.elements.mapping, elementId);
    // NOTE (Gabe 2024-03-07): We need to check if the element is null here
    // because sometimes this fires before the element is in the redux store.
    // Without this we send an incomplete request to the backend causing zod
    // errors.
    if (!elementId || !element) {
      dispatch(action);
      return;
    }
    if (coreState.elementUpdateInProgress) {
      // If there are in progress updates, then execute the action but don't
      // make the request, just mark the element as queued
      dispatch([action, setPendingElementUpdate(elementId)]);
    } else {
      // If there are no pending requests, execute the action, set the element
      // as pending so savePendingElements knows to pop it off the queue,
      // then dispatch an savePendingElements to kick off the request
      dispatch([
        action,
        setPendingElementUpdate(elementId),
        savePendingElements(source, isSettingHomepage),
      ]);
    }
  };
};

export const createFetchMetafieldValuesIfNeededThunk = (
  elementId: string | null,
) => {
  return (dispatch: EditorDispatch, getState: () => EditorRootState) => {
    const state = getState();
    const draftElementId = elementId ?? state.core.elements.draftElementId;
    const { activeCurrency, activeLanguage, moneyFormat } =
      selectLocaleData(state);
    const templateProduct = selectTemplateEditorStoreProduct(state) ?? null;
    const draftElement = getFromRecordOrNull(
      state.core.elements.mapping,
      draftElementId,
    );
    const projectId = selectProjectId(state);
    if (!draftElement || !projectId) {
      return;
    }
    const { dependencies: dependenciesRecord } = calculateDependencies(
      draftElement.component,
      {
        dataTables: state.core.dataTables.mapping,
        productResolutionDependencies: {
          products: recordValues(selectProductData(state)),
          currencyCode: activeCurrency,
          language: activeLanguage,
          moneyFormat,
          templateProduct,
        },
        // Note (Noah, 2022-11-26): Okay to pass empty mapping here because we only
        // care about the metafield keys/namespaces, not the types of the metafields
        // here
        metafieldsNamespaceKeyTypeMapping: { product: {}, variant: {} },
      },
    );

    // Group dependencies by product id so that we can fetch all the necessary
    // metafields' values from different namespaces in a single request.
    // @ts-ignore
    const productIdToMetafieldNamespacesAndKeys: Record<
      ProductId,
      Record<MetafieldNamespace, MetafieldKey[]>
    > = mapValues(
      groupBy(
        Object.values(dependenciesRecord)
          .flat()
          .filter(
            (dependency) =>
              dependency.type === DependencyType.metafields &&
              dependency.entityType === MetafieldEntityType.product,
          ),
        "productId",
      ),
      (dependencies: ProductMetafieldsDependency[]) => {
        const uniqueNamespaces = uniq(
          // Extract all needed namespaces from the dependencies so that
          // we only fetch the necessary metafields' values.
          dependencies.flatMap((dependency) =>
            (dependency as ProductMetafieldsDependency).metafieldKeys.map(
              ({ namespace }) => namespace,
            ),
          ),
        );
        const namespaceToKeys: Record<MetafieldNamespace, MetafieldKey[]> = {};
        for (const dependency of dependencies) {
          for (const namespace of uniqueNamespaces) {
            if (!namespaceToKeys[namespace]) {
              namespaceToKeys[namespace] = [];
            }
            uniq(
              dependency.metafieldKeys
                .filter((key) => key.namespace === namespace)
                .map(({ key }) => key),
            ).forEach((key) => {
              if (!namespaceToKeys[namespace]?.find((k) => key === k)) {
                namespaceToKeys[namespace]?.push(key);
              }
            });
          }
        }
        return namespaceToKeys;
      },
    );

    if (size(productIdToMetafieldNamespacesAndKeys) > 0) {
      void trpcClient.shopify.getProductMetafieldValues
        .mutate({
          projectId,
          productIdsWithNamespaces: productIdToMetafieldNamespacesAndKeys,
        })
        .then((response) => {
          const { metafields: productsMetafieldValues } = response;
          if (
            productsMetafieldValues &&
            Object.keys(productsMetafieldValues).length > 0
          ) {
            dispatch(setProductMetafieldValues(productsMetafieldValues));
          }
        });
    }

    // @ts-ignore
    const variantIdToMetafieldNamespacesAndKeys: Record<
      VariantId,
      Record<MetafieldNamespace, MetafieldKey[]>
    > = mapValues(
      groupBy(
        Object.values(dependenciesRecord)
          .flat()
          .filter(
            (dependency) =>
              dependency.type === DependencyType.metafields &&
              dependency.entityType === MetafieldEntityType.variant,
          ),
        "variantId",
      ),
      (dependencies: VariantMetafieldsDependency[]) => {
        const uniqueNamespaces = uniq(
          // Extract all neeeded namespaces from the dependencies so that
          // we only fetch the necessary metafields' values.
          dependencies.flatMap((dependency) =>
            (dependency as VariantMetafieldsDependency).metafieldKeys.map(
              ({ namespace }) => namespace,
            ),
          ),
        );
        const namespaceToKeys: Record<MetafieldNamespace, MetafieldKey[]> = {};
        for (const dependency of dependencies) {
          for (const namespace of uniqueNamespaces) {
            if (!namespaceToKeys[namespace]) {
              namespaceToKeys[namespace] = [];
            }
            uniq(
              dependency.metafieldKeys
                .filter((key) => key.namespace === namespace)
                .map(({ key }) => key),
            ).forEach((key) => {
              if (!namespaceToKeys[namespace]?.find((k) => key === k)) {
                namespaceToKeys[namespace]?.push(key);
              }
            });
          }
        }
        return namespaceToKeys;
      },
    );
    if (size(variantIdToMetafieldNamespacesAndKeys) > 0) {
      void trpcClient.shopify.getVariantMetafieldValues
        .mutate({
          projectId,
          variantIdsWithNamespaces: variantIdToMetafieldNamespacesAndKeys,
        })
        .then((response) => {
          const { metafields: variantsMetafieldValues } = response;

          if (
            variantsMetafieldValues &&
            Object.keys(variantsMetafieldValues).length > 0
          ) {
            dispatch(setVariantsMetafieldValues(variantsMetafieldValues));
          }
        });
    }
  };
};

export const applyComponentAction = (
  payload: ComponentActionType,
): AnyAction => {
  // @ts-ignore
  return [
    createElementUpdatingThunk(
      applyComponentRTKAction(payload),
      (getState) =>
        payload.elementId ?? getState().core.elements.draftElementId,
    ),
    createFetchMetafieldValuesIfNeededThunk(payload.elementId ?? null),
  ];
};

/**
 * Updates part or the entirety of the element
 *
 * This should be used exclusively by the element settings modal to update attributes
 * about the corresponding Shopify page
 */
export const updateAndSaveElement = (
  elementId: string,
  payload: ReploElement | ReploPartialElement,
  isSettingHomepage: boolean = false,
) => {
  return createElementUpdatingThunk(
    {
      type: EditorActionType.UPDATE_ELEMENT,
      payload: {
        ...payload,
        id: elementId,
      },
    },
    () => elementId,
    "pageSettings",
    isSettingHomepage,
  );
};

export const updateElement = (
  elementId: string,
  payload: ReploElement | ReploPartialElement,
) => {
  return {
    type: EditorActionType.UPDATE_ELEMENT,
    payload: {
      ...payload,
      id: elementId,
    },
  };
};

export const savePendingElements = (
  source: UpdateElementSource,
  isSettingHomepage: boolean = false,
) => {
  return (dispatch: (action: any) => void, getState: () => EditorRootState) => {
    const coreState = getState().core;
    dispatch(
      createOrUpdateElementRSAAAction(coreState, source, isSettingHomepage),
    );
  };
};

export const createOrUpdateElementRSAAAction = (
  coreState: CoreState,
  source: UpdateElementSource,
  isSettingHomepage: boolean = false,
) => {
  const pendingElementIds = coreState.pendingElementUpdates;
  // Pop off the next pending element update and yeet it back to the API
  let elementIdToUpdate: string | null;
  if (pendingElementIds.length === 0) {
    console.warn(
      "No pending element updates, falling back to draftElementId. Something may be misconfigured",
    );
    elementIdToUpdate = coreState.elements.draftElementId;
  } else {
    elementIdToUpdate = pendingElementIds[0]!;
  }

  const getBody = (state: EditorRootState) => {
    try {
      return JSON.stringify({
        element: {
          ...getFromRecordOrNull(
            state.core.elements.mapping,
            elementIdToUpdate,
          ),
          version:
            getFromRecordOrNull(
              state.core.elements.versionMapping,
              elementIdToUpdate,
            ) ?? 0,
        },
        themeId: state.ui.themeId,
        source,
      });
    } catch (error) {
      // Warn in the console if we see an error here, by default RSAA doesn't
      // propagate the error
      console.error(error);
      throw error;
    }
  };

  return {
    [RSAA]: {
      // NOTE (Gabe 2023-09-13): The baseUrl for this endpoint is set in the
      // auth middleware.
      endpoint: "api/v1/element",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: (state: EditorRootState) => getBody(state),
      types: [
        {
          type: API_ACTIONS.CREATE_OR_UPDATE_ELEMENT.start,
          meta: {
            elementId: elementIdToUpdate,
            source,
          },
        },
        {
          type: API_ACTIONS.CREATE_OR_UPDATE_ELEMENT.success,
          meta: {
            elementId: elementIdToUpdate,
            source,
            isSettingHomepage,
          },
        },
        {
          type: API_ACTIONS.CREATE_OR_UPDATE_ELEMENT.error,
          meta: {
            elementId: elementIdToUpdate,
            source,
          },
        },
      ],
    },
  };
};

export const getDependencyData = (state: EditorRootState) => {
  const draftElementId = state.core.elements.draftElementId;
  const productDataFromStore = selectProductData(state);
  const productMetafieldValues = state.commerce.productMetafieldValues;
  const variantMetafieldValues = state.commerce.variantMetafieldValues;
  const templateProduct = selectTemplateEditorStoreProduct(state) ?? null;
  const { activeCurrency, activeLanguage, moneyFormat } =
    selectLocaleData(state);

  const draftElement = getFromRecordOrNull(
    state.core.elements.mapping,
    draftElementId,
  );

  const { dependencies, allReferencedMetafields } = draftElement
    ? calculateDependencies(draftElement.component, {
        dataTables: state.core.dataTables.mapping,
        productResolutionDependencies: {
          products: Object.values(productDataFromStore),
          currencyCode: activeCurrency,
          language: activeLanguage,
          moneyFormat,
          templateProduct,
        },
        metafieldsNamespaceKeyTypeMapping: {
          // NOTE (Gabe 2024-09-18): This function ends up producing a mapping
          // that only contains keys of metafields that are present in products
          // in redux. For product templates it's possible that a metafield is
          // referenced, but not present in a product loaded into redux during
          // dependency generation. For that reason we obtain the types on the
          // backend from the Shopify API for Template Products.
          product: getMetafieldsNamespaceKeyTypeMapping(productMetafieldValues),
          variant: getMetafieldsNamespaceKeyTypeMapping(variantMetafieldValues),
        },
      })
    : {
        dependencies: {},
        allReferencedMetafields: {
          product: [],
          variant: [],
        },
      };

  const productsData = getProductsDataForDependencies(
    dependencies,
    productDataFromStore,
  );

  const useShopifyDebugTools = Boolean(
    JSON.parse(
      localStorage.getItem("replo.debug.useShopifyDebugTools") ?? "false",
    ),
  );

  return {
    elementId: draftElementId,
    dependencies,
    products: productsData,
    variantMetafieldValues,
    allReferencedMetafields,
    productMetafieldValues,
    carouselV4: isFeatureEnabled("carousel-v4"),
    theme_id: state.ui.themeId,
    okendoNamespace: state.commerce.okendoWidgetNamespace,
    useShopifyDebugTools,
  };
};

type CreatePreviewDataProps = {
  state: EditorRootState;
  projectId: string;
  storeName: string;
};

export const createPreviewData = (
  props: CreatePreviewDataProps,
): PreviewElementProps | undefined => {
  const { state, projectId, storeName } = props;
  const draftElementId = selectDraftElementId(state);
  const elementsMapping = selectElementsMapping(state);
  const dataTablesMapping = selectDataTablesMapping(state);
  const { activeCurrency, activeLanguage, moneyFormat } =
    selectLocaleData(state);
  const activeShopifyUrlRoot = selectShopifyUrlRoot(state);

  const draftElement = getFromRecordOrNull(elementsMapping, draftElementId);
  const version = draftElement ? draftElement.version : null;

  if (!draftElementId || !projectId || version === null || !storeName) {
    return;
  }

  const publishData = getDependencyData(state);

  if (publishData.elementId === null) {
    return;
  }

  return {
    ...publishData,
    elementId: publishData.elementId,
    projectId,
    previewElementVersion: version,
    shop: storeName ?? "",
    elementProducts: publishData.products,
    dataTables: Object.values(dataTablesMapping),
    currencyCode: activeCurrency,
    moneyFormat,
    activeLanguage,
    activeShopifyUrlRoot,
  };
};

export const publishDraftElement = () => {
  return [
    (dispatch: EditorDispatch) => {
      // Note (Noah, 2021-09-21): We requestAnimationFrame here to wait for the
      // current react render pass to complete, since in the case of Shopify liquid
      // components we need them to have their liquid content in the DOM at the
      // time when we snapshot them
      window.requestAnimationFrame(function () {
        // TODO (Fran, Noah, RTK migration): When we migrate to RTK for the publish
        // endpoint, we need to make sure to dispatch an openBillingModalIfNeeded
        // in the onQueryStarted .catch subscription, just like we do for the createProjectMembership
        // mutation
        void dispatch({
          [RSAA]: {
            endpoint: `api/v1/element/publish`,
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: (state: EditorRootState): string => {
              try {
                const dependencies = getDependencyData(state);

                return JSON.stringify(dependencies);
              } catch (error) {
                console.error("[Replo] Detected error publishing");
                console.error(error);
                throw error;
              }
            },
            types: [
              API_ACTIONS.PUBLISH_SNIPPET.start,
              API_ACTIONS.PUBLISH_SNIPPET.success,
              API_ACTIONS.PUBLISH_SNIPPET.error,
            ],
          },
        });
      });
    },
  ];
};

// NOTE (Fran 2024-01-15): This is deprecated, we will remove it once we migrate this action to RTK
export type UpdateEditorAction = {
  type: "UPDATE_ELEMENT";
  payload: ReploElement | (ReploPartialElement & { id: ReploElement["id"] });
};
