import type {
  FlowStepConfigPropsValueOf,
  FlowStepDataValueOf,
} from "schemas/flow";

import * as React from "react";

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import SelectableButtons from "@editor/components/common/designSystem/SelectableButtons";
import { useGetCurrentStepResultsData } from "@editor/components/flows/hooks/useGetCurrentStepResultsData";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepsLayout from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";
import { useLocalStorage } from "@editor/hooks/useLocalStorage";

import { Controller, useForm, useWatch } from "react-hook-form";

type FormValues = {
  experienceLevel: string;
};

const WhatIsYourExperienceLevelWithNoCodePageBuilders: React.FC = () => {
  const stepResultsData =
    useGetCurrentStepResultsData<"onboarding.user.experience-level-with-nocode-builders">();
  const { currentStep, submitStep } = useReploFlowsStepContext();
  const { handleSubmit, control } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      experienceLevel: stepResultsData?.experienceLevel
        ? stepResultsData.experienceLevel
        : "",
    },
  });
  const experienceLevelValue = useWatch({ control, name: "experienceLevel" });

  const localStorage = useLocalStorage();

  const { submitOrSkipStepCallback: submitStepCallback } =
    useRouterFlowCallbacks();

  const onSubmit = ({ experienceLevel }: FormValues) => {
    if (currentStep) {
      void submitStep(
        currentStep.id,
        currentStep.type,
        {
          experienceLevel,
        },
        ({ instance, nextStep }) => {
          // NOTE (Fran 2024-02-22): We should save in localStorage the projectId created in the
          // step four (are you using solo or team) to redirect to the correct project
          // after the onboarding flow is completed.
          // We should do this always in the last step of the flow!
          const stepResultsData = instance.stepResults[
            "are-you-using-solo-or-team"
          ]
            ?.data as FlowStepDataValueOf<"onboarding.user.are-you-using-solo-or-team">;
          if (stepResultsData?.projectId) {
            localStorage.setItem("firstProjectId", stepResultsData.projectId);
          }

          submitStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          });
        },
      );
    }
  };

  const stepProps =
    currentStep?.props as FlowStepConfigPropsValueOf<"onboarding.user.experience-level-with-nocode-builders">;

  return (
    <OnboardingStepsLayout
      rightPanelContent={<StepImage src="/images/flows/grid.png" />}
    >
      <div className="flex flex-col gap-14">
        <StepTitle>
          What is your experience level with no-code page{" "}
          <span className="whitespace-nowrap">
            builders?
            <span className="text-red-600">*</span>
          </span>
        </StepTitle>
        <form
          className="flex flex-col gap-16"
          onSubmit={(data) => {
            void handleSubmit(onSubmit)(data);
          }}
        >
          <Controller
            name="experienceLevel"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectableButtons
                multiSelect={false}
                options={stepProps.options ?? []}
                value={[value]}
                onChange={(value) => onChange(value[0] ?? "")}
                className="my-4"
                textClassName="font-normal"
                unselectedClassName="border border-slate-200 bg-transparent text-default hover:border-blue-600 hover:bg-blue-200 hover:text-blue-600"
                selectedClassName="border border-blue-600 bg-blue-200 text-blue-600 hover:bg-blue-300"
              />
            )}
          />
          <FlowActionButtons
            shouldDisableNextButton={!Boolean(experienceLevelValue)}
          />
        </form>
      </div>
    </OnboardingStepsLayout>
  );
};

export default WhatIsYourExperienceLevelWithNoCodePageBuilders;
