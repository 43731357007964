import type { ComponentTemplate } from "@editor/types/component-template";

import * as React from "react";

import ComponentTemplatesListPane, {
  ComponentTemplateListPaneItem,
} from "@components/ComponentTemplateListPane";
import { Loader } from "@components/marketplace/Loader";
import useCurrentDragType from "@editor/hooks/useCurrentDragType";
import useInfiniteTemplates from "@editor/hooks/useInfiniteTemplates";
import useSearchableTemplateLeftBarCategories from "@editor/hooks/useSearchableTemplateLeftBarCategories";
import { groupComponentTemplatesByCategory } from "@editor/utils/component-templates";

import startCase from "lodash-es/startCase";

import { Group } from "./common/designSystem/Group";
import { HotkeyIndicator } from "./common/HotkeyIndicator";
import { useIsDebugMode } from "./editor/debug/useIsDebugMode";
import { InfiniteLoadingGrid } from "./InfiniteLoadingGrid";

export const BaseComponentTemplates: React.FC<{
  searchString: string;
  leftBarCategoryName?: string;
}> = ({ searchString, leftBarCategoryName }) => {
  const leftBarCategories = useSearchableTemplateLeftBarCategories(
    "left-bar",
    searchString,
  );
  const hasTemplatesForCategory = leftBarCategories.categories.some(
    (category) =>
      category.subCategories.some(
        (subCategory) => subCategory.componentTemplates.length > 0,
      ),
  );
  if (!hasTemplatesForCategory) {
    return (
      <div className="overflow-y-hidden flex flex-col items-center justify-start gap-2 pt-10">
        <p className="text-center text-xs font-semibold">
          No Results For {searchString}
        </p>
      </div>
    );
  }

  if (searchString) {
    const searchResults: ComponentTemplate[] = [];

    for (const category of leftBarCategories.categories) {
      for (const subCategory of category.subCategories) {
        searchResults.push(...subCategory.componentTemplates);
      }
    }

    return (
      <ul className="grid grid-cols-2 gap-2">
        {searchResults.map((componentTemplate) => (
          <ComponentTemplateListPaneItem
            key={componentTemplate.id}
            scope="left-bar"
            componentTemplate={componentTemplate}
          />
        ))}
      </ul>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      {leftBarCategoryName &&
        leftBarCategories.categories.map((category) => {
          if (category.mainCategory === leftBarCategoryName) {
            return (
              <>
                {category.subCategories.map((subCategory) => (
                  <Group
                    key={subCategory.subCategory}
                    name={startCase(subCategory.subCategory)}
                  >
                    <ul
                      className="grid grid-cols-2 gap-1 py-2"
                      key={subCategory.subCategory}
                    >
                      {subCategory.componentTemplates.map(
                        (componentTemplate) => (
                          <ComponentTemplateListPaneItem
                            key={componentTemplate.id}
                            scope="left-bar"
                            componentTemplate={componentTemplate}
                          />
                        ),
                      )}
                    </ul>
                  </Group>
                ))}
              </>
            );
          }
          return null;
        })}
    </div>
  );
};

export const StoreComponentTemplates: React.FC<{
  searchString: string;
}> = ({ searchString }) => {
  const {
    componentTemplatesList,
    hasNextPage,
    fetchNextPage,
    isLoadingInitialPage,
  } = useInfiniteTemplates({
    searchString,
    scope: "store",
    includeComponent: true,
    templateType: "section",
  });
  const { currentDragIdentifier } = useCurrentDragType();
  const isDraggingComponentTemplate = currentDragIdentifier !== null;
  const isDebugMode = useIsDebugMode();
  const categories = groupComponentTemplatesByCategory(
    componentTemplatesList ?? [],
    {
      scope: "store",
      isDebugMode,
    },
  );

  return isLoadingInitialPage ? (
    <Loader label="Loading..." />
  ) : (
    <InfiniteLoadingGrid
      listLength={componentTemplatesList?.length ?? 0}
      hasNextPage={hasNextPage}
      loadMoreItems={() => void fetchNextPage()}
      scrollableTargetId="component-template-pane-wrapper"
      style={{ overflow: isDraggingComponentTemplate ? "visible" : "hidden" }}
    >
      {categories.length > 0 ? (
        categories.map((category) =>
          category.componentTemplates ? (
            <ComponentTemplatesListPane
              key={category.title}
              scope="store"
              category={category}
            />
          ) : null,
        )
      ) : (
        <ComponentsPaneEmptyState title="No Saved Components" />
      )}
    </InfiniteLoadingGrid>
  );
};

type ComponentsPaneEmptyStateProps = {
  title: string;
};

const ComponentsPaneEmptyState = (props: ComponentsPaneEmptyStateProps) => {
  return (
    <div className="h-screen overflow-y-hidden flex flex-col items-center justify-center gap-2">
      <p className="text-center text-xs font-semibold">{props.title}</p>
      <p className="text-center text-xs">
        Save a component by selecting it and pressing Command + Shift + S
      </p>
      <HotkeyIndicator hotkey="saveComponentTemplate" title="" />
    </div>
  );
};
