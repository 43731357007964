import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import useScript from "../../../shared/hooks/useScript";
import {
  GlobalWindowContext,
  RuntimeHooksContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import RenderComponentPlaceholder from "../RenderComponentPlaceholder";

const BuyWithPrimeButton: React.FC<RenderComponentProps> = ({
  componentAttributes: attributes,
  component,
  context,
}) => {
  const { _siteId, _widgetId } = component.props;
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const product = useProductFromProps(component.props, context);
  const variantSku = product?.variant?.sku;
  const selectedQuantity = product?.quantity;

  useScript("https://code.buywithprime.amazon.com/bwp.v1.js", {
    globalWindow,
    forceSkip: isEditorCanvas,
  });
  React.useEffect(() => {
    const quantity = selectedQuantity ?? 1;
    if (
      !isEditorCanvas &&
      globalWindow?.bwp?.updateWidget &&
      variantSku &&
      (variantSku !== globalWindow.bwp.sku ||
        quantity !== globalWindow.bwp.quantity)
    ) {
      globalWindow.bwp.updateWidget(variantSku, quantity);
    }
  }, [variantSku, selectedQuantity, globalWindow, isEditorCanvas]);

  const placeholder =
    _siteId && _widgetId
      ? "Buy With Prime Widget will appear here when published."
      : "Once you set your Buy With Prime Site ID and Widget ID in the Config tab, your embedded Buy With Prime Widget will appear here.";
  return (
    <div {...attributes}>
      {isEditorCanvas ? (
        <RenderComponentPlaceholder title={placeholder} />
      ) : (
        <div
          id="amzn-buy-now"
          data-site-id={_siteId}
          data-widget-id={_widgetId}
          data-sku={variantSku}
        />
      )}
    </div>
  );
};

export default BuyWithPrimeButton;
