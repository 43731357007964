import type {
  RuntimeStyleAttribute,
  SupportedCssProperties,
} from "replo-runtime/shared/styleAttribute";
import type {
  ModifierType,
  ModifierTypeToControlType,
} from "schemas/modifiers";

import {
  selectAlignSelf,
  selectBackgroundColor,
  selectBackgroundImage,
  selectFlexDirection,
  selectMaxHeight,
  selectMaxWidth,
  selectMinHeight,
  selectMinWidth,
  selectOverflow,
  selectPosition,
  selectTextDecoration,
  selectTextOutline,
  selectTextShadow,
  selectTextTransform,
  selectZIndex,
} from "@editor/reducers/core-reducer";
import {
  getDefaultControls,
  ModifierTypeToControls,
} from "@editor/utils/modifiers";

import { createSelector } from "@reduxjs/toolkit";
import { styleAttributeToDefaultStyle } from "replo-runtime/shared/styleAttribute";

const selectSizeModifierControlsToRender = createSelector(
  selectMinWidth,
  selectMinHeight,
  selectMaxWidth,
  selectMaxHeight,
  selectAlignSelf,
  (minWidth, minHeight, maxWidth, maxHeight, alignSelf) => {
    const defaultControls = getDefaultControls<"size">(
      ModifierTypeToControls["size"],
    );
    const properties: SupportedCssProperties = {
      minWidth: minWidth ?? undefined,
      minHeight: minHeight ?? undefined,
      maxWidth: maxWidth ?? undefined,
      maxHeight: maxHeight ?? undefined,
      alignSelf: alignSelf ?? undefined,
    };
    const controlsToRender =
      // NOTE (Sebas, 2024-09-10): We need to cast to (keyof SupportedCssProperties)[]
      // because TypeScript cannot infer the type of keys from the object.
      (Object.keys(properties) as (keyof SupportedCssProperties)[]).filter(
        (key) => {
          const value = properties[key];
          return !isDefaultValue(value, key);
        },
      );

    return new Set([...defaultControls, ...controlsToRender]);
  },
);

export const selectTextModifierControlsToRender = createSelector(
  selectTextDecoration,
  selectTextTransform,
  selectTextOutline,
  selectTextShadow,
  (textDecoration, textTransform, textOutline, textShadow) => {
    const defaultControls = getDefaultControls<"text">(
      ModifierTypeToControls["text"],
    );
    if (!isDefaultValue(textDecoration, "textDecoration")) {
      defaultControls.add("textDecoration");
    }
    if (!isDefaultValue(textTransform, "textTransform")) {
      defaultControls.add("textTransform");
    }
    if (!isDefaultValue(textOutline, "__textStroke")) {
      defaultControls.add("textOutline");
    }
    if (!isDefaultValue(textShadow, "textShadow")) {
      defaultControls.add("textShadow");
    }
    return defaultControls;
  },
);

export const selectPositionModifierControlsToRender = createSelector(
  selectPosition,
  selectZIndex,
  (position, zIndex) => {
    const defaultControls = getDefaultControls<"position">(
      ModifierTypeToControls["position"],
    );
    if (!isDefaultValue(position, "position")) {
      defaultControls.add("position");
    }
    if (!isDefaultValue(zIndex, "zIndex")) {
      defaultControls.add("zIndex");
    }
    return defaultControls;
  },
);

const selectLayoutModifierControlsToRender = createSelector(
  selectFlexDirection,
  selectOverflow,
  (flexDirection, overflow) => {
    const defaultControls = getDefaultControls<"layout">(
      ModifierTypeToControls["layout"],
    );
    const [overflowX, overflowY] = overflow?.split(" ") ?? [];

    if (!isDefaultValue(flexDirection, "flexDirection")) {
      defaultControls.add("order");
    }
    if (!isDefaultValue(overflowX, "overflowX")) {
      defaultControls.add("overflowX");
    }
    if (!isDefaultValue(overflowY, "overflowY")) {
      defaultControls.add("overflowY");
    }

    return defaultControls;
  },
);

const selectBackgroundModifierControlsToRender = createSelector(
  selectBackgroundColor,
  selectBackgroundImage,
  (backgroundColor, backgroundImage) => {
    const controls = new Set<ModifierTypeToControlType["background"]>();
    if (!isDefaultValue(backgroundColor, "backgroundColor")) {
      controls.add("backgroundColor");
    }
    if (!isDefaultValue(backgroundImage, "backgroundImage")) {
      controls.add("backgroundImage");
    }

    return controls;
  },
);

const isDefaultValue = (
  value: string | number | null | undefined,
  cssProperty: RuntimeStyleAttribute,
) => {
  // Note (Sebas, 2022-10-12): In case we set "alignSelf: stretch" with
  // the width or height toggle group (depends on the flex direction), we
  // return 'auto' to prevent showing the alignment toggle group.
  const isDefaultByAlignSelf =
    value === "stretch" && cssProperty === "alignSelf";

  const defaultStyleValue = styleAttributeToDefaultStyle[cssProperty];

  return (
    !value ||
    String(value) === String(defaultStyleValue) ||
    isDefaultByAlignSelf
  );
};

export const modifierTypeToSelector: Record<ModifierType, any> = {
  position: selectPositionModifierControlsToRender,
  size: selectSizeModifierControlsToRender,
  layout: selectLayoutModifierControlsToRender,
  text: selectTextModifierControlsToRender,
  background: selectBackgroundModifierControlsToRender,
  // effects: selectEffectsModifierControlsToRender,
};
