import * as React from "react";

import { Button, ButtonPhony } from "@common/designSystem/Button";
import { Menu } from "@common/designSystem/Menu";
import Tooltip from "@common/designSystem/Tooltip";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import ModalIcon from "@svg/componenticon-modal";

type TreeModalSelectorProps = {
  modals: {
    id: string;
    name?: string;
  }[];
};

export const TreeModalSelector = ({ modals }: TreeModalSelectorProps) => {
  const setDraftElement = useSetDraftElement();

  if (modals.length === 0) {
    return null;
  }

  if (modals.length === 1) {
    return (
      <Tooltip content="Jump to Popup Component" triggerAsChild>
        <div>
          <Button
            type="secondary"
            style={{ minWidth: 0 }}
            className="bg-transparent text-blue-600"
            onClick={(e) => {
              e.stopPropagation();
              setDraftElement({
                componentId: modals[0]!.id,
              });
            }}
            data-testid="tree-modal-selector"
          >
            <ModalIcon />
          </Button>
        </div>
      </Tooltip>
    );
  }

  const options = modals.map((modal) => {
    return {
      id: modal.id,
      type: "leaf" as const,
      value: modal.id,
      title: modal.name,
      onSelect: () =>
        setDraftElement({
          componentId: modal.id,
        }),
    };
  });

  return (
    <Tooltip content="Jump to Popup Components" triggerAsChild>
      <div>
        <Menu
          items={options}
          menuType="normal"
          customWidth={150}
          trigger={
            <ButtonPhony
              // NOTE (Chance, 2023-05-02): This is purely decorative, as Menu
              // creates a trigger that renders the button element around the
              // visual button.
              type="secondary"
              style={{ minWidth: 0 }}
              className="bg-transparent text-blue-600"
            >
              <ModalIcon />
            </ButtonPhony>
          }
        />
      </div>
    </Tooltip>
  );
};
