import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { BoxShadow } from "replo-runtime/shared/types";
import type { PreviewableSubProperty } from "replo-runtime/shared/utils/preview";
import type { TransformObject } from "replo-runtime/shared/utils/transform";

import * as React from "react";

import BadgeV2 from "@editor/components/common/designSystem/BadgeV2";
import InlinePopover from "@editor/components/common/designSystem/InlinePopover";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Popover from "@editor/components/common/designSystem/Popover";
import Selectable from "@editor/components/common/designSystem/Selectable";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import Separator from "@editor/components/common/designSystem/Separator";
import Slider from "@editor/components/common/designSystem/Slider";
import ToggleGroup from "@editor/components/common/designSystem/ToggleGroup";
import Tooltip from "@editor/components/common/designSystem/Tooltip";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useModal } from "@editor/hooks/useModal";
import { isNewRightBarUIEnabled } from "@editor/infra/featureFlags";
import {
  selectBoxShadow,
  selectCursor,
  selectDraftComponentId,
  selectDraftComponentPositionKey,
  selectDraftComponentType,
  selectOpacity,
  selectRotation,
  selectTransform,
  selectTransformOrigin,
  selectZoom,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import {
  getBoxShadowObject,
  getBoxShadowString,
} from "@editor/utils/boxShadow";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import ModifierGroup from "@editorExtras/ModifierGroup";
import LengthInputModifier, {
  LengthInputSelector,
} from "@editorModifiers/LengthInputModifier";
import {
  isEmptyOrCssDefault,
  transformDefaultValues,
} from "@editorModifiers/utils";

import merge from "lodash-es/merge";
import { BsX } from "react-icons/bs";
import { RiDropLine, RiZoomInLine } from "react-icons/ri";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { getTransformStyleString } from "replo-runtime/shared/utils/transform";
import {
  CSS_ANGLE_TYPES,
  CSS_LENGTH_TYPES,
} from "replo-runtime/shared/utils/units";
import { filterNulls } from "replo-utils/lib/array";
import { v4 as uuidv4 } from "uuid";

import ModifierLabel from "../extras/ModifierLabel";
import SolidColorSelector from "../SolidColorSelector";

const EffectsModifier: React.FC = () => {
  const opacity = useEditorSelector(selectOpacity);
  const zoom = useEditorSelector(selectZoom);
  const cursor = useEditorSelector(selectCursor);

  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  return (
    <ModifierGroup
      title="Effects"
      isDefaultOpen={
        !isEmptyOrCssDefault(
          opacity ? String(opacity) : null,
          styleAttributeToEditorData.opacity.defaultValue,
        ) ||
        !isEmptyOrCssDefault(
          zoom ? String(zoom) : null,
          styleAttributeToEditorData.__zoom.defaultValue,
        ) ||
        !isEmptyOrCssDefault(cursor, "default") // NOTE (Sebas, 2022-08-01): The "default" value is hardcoded because the initial value is "auto".
      }
    >
      <div className="flex flex-col gap-2">
        {isNewRightBarEnabled ? (
          <>
            <TransformControl />
            <TransformOriginControl />
          </>
        ) : null}
        <OpacityControl />
        {!isNewRightBarEnabled ? <ZoomControl /> : null}
        <CursorControl />
        {isNewRightBarEnabled ? <ShadowControl /> : null}
      </div>
    </ModifierGroup>
  );
};

const OpacityControl: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const opacity = useEditorSelector(selectOpacity);
  const draftComponentId = useEditorSelector(selectDraftComponentId);

  const onChange = (newValue: string) => {
    applyComponentAction({
      componentId: draftComponentId,
      type: "setStyles",
      value: { opacity: newValue },
    });
  };

  const opacityDefaultValue = `${
    Number.parseInt(styleAttributeToEditorData.opacity.defaultValue) * 100
  }%`;

  if (isNewRightBarUIEnabled()) {
    return (
      <div className="flex items-center">
        <LengthInputModifier
          label={<ModifierLabel label="Opacity" />}
          dragTrigger="label"
          menuOptions={["0%", "50%", "100%"].map((v) => ({
            label: v,
            value: v,
          }))}
          placeholder={opacityDefaultValue}
          anchorValue="50%"
          resetValue={opacityDefaultValue}
          metrics={["%"]}
          field="style.opacity"
          onChange={onChange}
          minValues={{ "%": 0 }}
          maxValues={{ "%": 100 }}
          minDragValues={{ "%": 0 }}
          maxDragValues={{ "%": 100 }}
          previewProperty="opacity"
        />
      </div>
    );
  }

  return (
    <LabeledControl label="Opacity" size="sm">
      <div className="flex w-full items-center gap-2">
        <div className="w-1/2">
          <LengthInputModifier
            startEnhancer={() => (
              <Tooltip inheritCursor content="Adjust Opacity" triggerAsChild>
                <span tabIndex={0}>
                  <RiDropLine size={12} className="text-slate-400" />
                </span>
              </Tooltip>
            )}
            menuOptions={["0%", "50%", "100%"].map((option) => ({
              label: option,
              value: option,
            }))}
            placeholder={opacityDefaultValue}
            anchorValue="50%"
            resetValue={opacityDefaultValue}
            metrics={["%"]}
            field="style.opacity"
            onChange={onChange}
            minValues={{ "%": 0 }}
            maxValues={{ "%": 100 }}
            minDragValues={{ "%": 0 }}
            maxDragValues={{ "%": 100 }}
            previewProperty="opacity"
          />
        </div>
        <Slider
          value={
            opacity ? Number.parseInt(String(opacity).replace("%", "")) : 100
          }
          debounce
          minimum={0}
          maximum={100}
          onChange={(value: number) => {
            onChange(`${value}%`);
          }}
          className="w-1/2"
        />
      </div>
    </LabeledControl>
  );
};

// TODO (Fran 2024-10-04 REPL-13669): Remove after release right bar changes
const ZoomControl: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const draftComponentType = useEditorSelector(selectDraftComponentType);

  if (draftComponentType !== "image") {
    return null;
  }
  const zoomDefaultValue = styleAttributeToEditorData.__zoom.defaultValue;
  return (
    <LabeledControl label="Zoom" size="sm">
      <div className="flex w-full">
        <LengthInputModifier
          startEnhancer={() => (
            <Tooltip inheritCursor content="Adjust Zoom" triggerAsChild>
              <span tabIndex={0}>
                <RiZoomInLine size={12} className="text-slate-400" />
              </span>
            </Tooltip>
          )}
          placeholder={zoomDefaultValue}
          anchorValue={zoomDefaultValue}
          resetValue={zoomDefaultValue}
          metrics={["%"]}
          field="style.__zoom"
          onChange={(newValue) => {
            applyComponentAction({
              type: "setStyles",
              value: { __zoom: newValue },
            });
          }}
          className="w-full"
          menuOptions={["Reset", "100%", "110%", "120%"].map((option) => ({
            label: option,
            value: option === "Reset" ? zoomDefaultValue : option,
          }))}
          minValues={{ "%": 1 }}
          minDragValues={{ "%": 1 }}
        />
      </div>
    </LabeledControl>
  );
};

const cursorSelectableItems = [
  {
    label: "Default",
    value: "default",
  },
  {
    label: "Pointer",
    value: "pointer",
  },
  {
    label: "Crosshair",
    value: "crosshair",
  },
  {
    label: "Not Allowed",
    value: "not-allowed",
  },
  {
    label: "Input",
    value: "text",
  },
  {
    label: "Arrows",
    value: "ew-resize",
  },
  {
    label: "Vertical Arrows",
    value: "ns-resize",
  },
  {
    label: "No Cursor",
    value: "none",
  },
];

const CursorControl: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const value = useEditorSelector(selectCursor);

  if (isNewRightBarUIEnabled()) {
    return (
      <div className="flex w-full items-center">
        <ModifierLabel label="Cursor" />
        <Selectable
          value={value && value !== "auto" ? value : "default"}
          options={cursorSelectableItems}
          onSelect={(newValue) => {
            applyComponentAction({
              type: "setStyles",
              value: { cursor: newValue },
            });
          }}
        />
      </div>
    );
  }

  return (
    <LabeledControl label="Cursor" size="sm">
      <div className="flex w-full">
        <Selectable
          value={value && value !== "auto" ? value : "default"}
          options={cursorSelectableItems}
          onSelect={(newValue) => {
            applyComponentAction({
              type: "setStyles",
              value: { cursor: newValue },
            });
          }}
          className="w-full"
        />
      </div>
    </LabeledControl>
  );
};

const TransformControl: React.FC = () => {
  const [verticalPosition, horizontalPosition] = useEditorSelector(
    selectDraftComponentPositionKey,
  );
  const shouldDisableTranslateTab =
    verticalPosition === "center" || horizontalPosition === "center";
  const activeTabInitialValue = shouldDisableTranslateTab ? "scale" : "move";
  const [activeTab, setActiveTab] = React.useState<Transform>(
    activeTabInitialValue,
  );

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const applyComponentAction = useApplyComponentAction();

  const transformObjectOrNull = useEditorSelector(selectTransform);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const draftElementAlchemyRotation = useEditorSelector(selectRotation);
  const draftElementTransform = merge(
    {},
    transformDefaultValues,
    transformObjectOrNull,
  );

  const handleApplyTransform = (value: TransformObject) => {
    const actions: UseApplyComponentActionType[] = [
      {
        type: "setStyles",
        componentId: draftComponentId,
        value: { __transform: value },
      },
    ];
    // Note (Sebas, 2022-07-20): The __alchemyRotation value is not necessary anymore.
    // If it exists we need to reset it to null
    if (draftElementAlchemyRotation) {
      actions.push({
        componentId: draftComponentId,
        type: "setStyles",
        value: {
          __alchemyRotation:
            styleAttributeToEditorData.__alchemyRotation.defaultValue,
        },
      });
    }
    applyComponentAction({
      componentId: draftComponentId,
      type: "applyCompositeAction",
      value: actions,
    });
  };

  const handleChange = (value: TransformObject) => {
    handleApplyTransform(value);
  };

  const onPropertyChange = (value: string, type: string) => {
    const updatedProps = { ...draftElementTransform, [type]: value };
    handleChange(updatedProps);
  };

  const onMultiplePropertyChange = (types: string[], value: string) => {
    const newValues = types.reduce(
      (accum: Record<string, string>, currentValue: string) => {
        accum[currentValue] = value;
        return accum;
      },
      {},
    );
    handleChange({ ...draftElementTransform, ...newValues });
  };

  const calculateValue = (
    value: string,
    operation: "add" | "substract" | "multiply",
    operand: number,
    unit?: string,
  ) => {
    const operations = {
      add: `${(Number.parseInt(value, 10) + operand).toString()}`,
      substract: `${(Number.parseInt(value, 10) - operand).toString()}`,
      multiply: `${(Number.parseInt(value, 10) * operand).toString()}`,
    };
    return `${operations[operation]}${unit ?? ""}`;
  };

  const scaleSharedMenuOptions = [
    { label: "110%", value: "110%" },
    { label: "120%", value: "120%" },
    { label: "130%", value: "130%" },
  ];

  const rotateSharedMenuOptions = [
    { label: "90deg", value: "90deg" },
    { label: "180deg", value: "180deg" },
    { label: "270deg", value: "270deg" },
  ];

  const getScaleSimplifiedValue = () => {
    const values = draftElementTransform;
    if (values.scaleX !== values.scaleY) {
      return "100%";
    } else if (values.scaleX.includes("%")) {
      return values.scaleX;
    }
    return calculateValue(values.scaleX, "multiply", 100);
  };

  const toggleGroupOptions: ToggleGroupOption<Transform>[] = [
    {
      label: "Move",
      value: "move",
      metrics: CSS_LENGTH_TYPES,
      inputs: [
        {
          label: "X Axis",
          resetValue: transformDefaultValues.translateX,
          anchorValue: transformDefaultValues.translateX,
          placeholder: transformDefaultValues.translateX,
          onChange: (value: string) => onPropertyChange(value, "translateX"),
          value: draftElementTransform.translateX,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.translateX },
          ],
          previewSubProperty: "translateX",
          autofocus: activeTab === "move",
        },
        {
          label: "Y Axis",
          resetValue: transformDefaultValues.translateY,
          anchorValue: transformDefaultValues.translateY,
          placeholder: transformDefaultValues.translateY,
          onChange: (value: string) => onPropertyChange(value, "translateY"),
          value: draftElementTransform.translateY,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.translateY },
          ],
          previewSubProperty: "translateY",
        },
        {
          label: "Z Axis",
          resetValue: transformDefaultValues.translateZ,
          anchorValue: transformDefaultValues.translateZ,
          placeholder: transformDefaultValues.translateZ,
          onChange: (value: string) => onPropertyChange(value, "translateZ"),
          value: draftElementTransform.translateZ,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.translateZ },
          ],
          previewSubProperty: "translateZ",
        },
      ],
    },
    {
      label: "Scale",
      value: "scale",
      metrics: ["%"],
      inputs: [
        {
          label: "Transform",
          resetValue: transformDefaultValues.scaleX,
          anchorValue: transformDefaultValues.scaleX,
          placeholder: transformDefaultValues.scaleX,
          onChange: (value: string) =>
            onMultiplePropertyChange(["scaleX", "scaleY"], value),
          value: getScaleSimplifiedValue(),
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.scaleX },
            ...scaleSharedMenuOptions,
          ],
          previewSubProperty: "scaleXY",
          hasDivider: true,
        },
        {
          label: "X Axis",
          resetValue: transformDefaultValues.scaleX,
          anchorValue: transformDefaultValues.scaleX,
          placeholder: transformDefaultValues.scaleX,
          onChange: (value: string) => onPropertyChange(value, "scaleX"),
          value: draftElementTransform.scaleX.includes("%")
            ? draftElementTransform.scaleX
            : calculateValue(draftElementTransform.scaleX, "multiply", 100),
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.scaleX },
            ...scaleSharedMenuOptions,
          ],
          previewSubProperty: "scaleX",
        },
        {
          label: "Y Axis",
          resetValue: transformDefaultValues.scaleY,
          anchorValue: transformDefaultValues.scaleY,
          placeholder: transformDefaultValues.scaleY,
          onChange: (value: string) => onPropertyChange(value, "scaleY"),
          value: draftElementTransform.scaleY.includes("%")
            ? draftElementTransform.scaleY
            : calculateValue(draftElementTransform.scaleY, "multiply", 100),
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.scaleY },
            ...scaleSharedMenuOptions,
          ],
          previewSubProperty: "scaleY",
        },
        {
          label: "Z Axis",
          resetValue: transformDefaultValues.scaleZ,
          anchorValue: transformDefaultValues.scaleZ,
          placeholder: transformDefaultValues.scaleZ,
          onChange: (value: string) => onPropertyChange(value, "scaleZ"),
          value: draftElementTransform.scaleZ.includes("%")
            ? draftElementTransform.scaleZ
            : calculateValue(draftElementTransform.scaleZ, "multiply", 100),
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.scaleZ },
            ...scaleSharedMenuOptions,
          ],
          previewSubProperty: "scaleZ",
        },
      ],
    },
    {
      label: "Rotate",
      value: "rotate",
      metrics: ["deg", "rad", "turn"],
      inputs: [
        {
          label: "Transform",
          resetValue: transformDefaultValues.rotateZ,
          anchorValue: transformDefaultValues.rotateZ,
          placeholder: transformDefaultValues.rotateZ,
          onChange: (value: string) => onPropertyChange(value, "rotateZ"),
          value: draftElementTransform.rotateZ,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.rotateZ },
            ...rotateSharedMenuOptions,
          ],
          previewSubProperty: "rotateZ",
          hasDivider: true,
        },
        {
          label: "X Axis",
          resetValue: transformDefaultValues.rotateX,
          anchorValue: transformDefaultValues.rotateX,
          placeholder: transformDefaultValues.rotateX,
          onChange: (value: string) => onPropertyChange(value, "rotateX"),
          value: draftElementTransform.rotateX,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.rotateX },
            ...rotateSharedMenuOptions,
          ],
          previewSubProperty: "rotateX",
        },
        {
          label: "Y Axis",
          resetValue: transformDefaultValues.rotateY,
          anchorValue: transformDefaultValues.rotateY,
          placeholder: transformDefaultValues.rotateY,
          onChange: (value: string) => onPropertyChange(value, "rotateY"),
          value: draftElementTransform.rotateY,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.rotateY },
            ...rotateSharedMenuOptions,
          ],
          previewSubProperty: "rotateY",
        },
        {
          label: "Z Axis",
          resetValue: transformDefaultValues.rotateZ,
          anchorValue: transformDefaultValues.rotateZ,
          placeholder: transformDefaultValues.rotateZ,
          onChange: (value: string) => onPropertyChange(value, "rotateZ"),
          value: draftElementTransform.rotateZ,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.rotateZ },
            ...rotateSharedMenuOptions,
          ],
          previewSubProperty: "rotateZ",
        },
      ],
    },
    {
      label: "Skew",
      value: "skew",
      metrics: CSS_ANGLE_TYPES,
      inputs: [
        {
          label: "X Axis",
          resetValue: transformDefaultValues.skewX,
          anchorValue: transformDefaultValues.skewX,
          placeholder: transformDefaultValues.skewX,
          onChange: (value: string) => onPropertyChange(value, "skewX"),
          value: draftElementTransform.skewX,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.skewX },
          ],
          previewSubProperty: "skewX",
          autofocus: activeTab === "skew",
        },
        {
          label: "Y Axis",
          resetValue: transformDefaultValues.skewY,
          anchorValue: transformDefaultValues.skewY,
          placeholder: transformDefaultValues.skewY,
          onChange: (value: string) => onPropertyChange(value, "skewY"),
          value: draftElementTransform.skewY,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.skewY },
          ],
          previewSubProperty: "skewY",
        },
      ],
    },
  ];

  const activeGroupOption = toggleGroupOptions.find(
    (option) => option.value === activeTab,
  );

  const transformTitle = getReadableTransformValue(
    getTransformStyleString(draftElementTransform),
  );

  return (
    <div className="flex items-center w-full">
      <ModifierLabel label="Transform" />
      <InlinePopover
        isOpen={isOpen}
        shouldPreventDefaultOnInteractOutside={false}
        onOpenChange={setIsOpen}
        title="Transform"
        triggerAsChild
        sideOffset={82}
        content={
          <div className="flex flex-col gap-2">
            <ToggleGroup
              allowsDeselect={false}
              type="single"
              options={toggleGroupOptions}
              value={activeTab}
              onChange={(value) => {
                setActiveTab(value);
              }}
              style={{ width: "100%" }}
            />
            {activeGroupOption
              ? activeGroupOption.inputs.map(
                  ({
                    label,
                    resetValue,
                    anchorValue,
                    placeholder,
                    value,
                    onChange,
                    menuOptions,
                    previewSubProperty,
                    hasDivider,
                  }) => (
                    <div key={label} className="flex flex-col gap-2">
                      <div className="flex flex-row items-end justify-between">
                        <LengthInputSelector
                          metrics={activeGroupOption.metrics}
                          className="col-span-1"
                          label={<ModifierLabel label={label} />}
                          resetValue={resetValue}
                          anchorValue={anchorValue}
                          placeholder={placeholder}
                          value={value!}
                          onChange={onChange!}
                          menuOptions={menuOptions}
                          previewProperty="transform"
                          previewSubProperty={previewSubProperty}
                          autofocus
                          dragTrigger="label"
                          // NOTE (Fran 2024-10-02): Actually Length Input selector does not need
                          // this prop to set a value. It is used for testing or parse the unit, we don't
                          // need that on these fields.
                          field=""
                        />
                      </div>
                      {hasDivider ? <Separator /> : null}
                    </div>
                  ),
                )
              : null}
          </div>
        }
      >
        <div className="flex-1">
          <SelectionIndicator
            className="max-w-40"
            title={transformTitle}
            onClick={() => setIsOpen(true)}
            onReset={() => handleApplyTransform(transformDefaultValues)}
            placeholder="Translate 0px"
            endEnhancer={
              transformTitle.length > 0 && (
                <BsX
                  size={12}
                  className="cursor-pointer text-subtle"
                  onClick={(e) => {
                    // NOTE (Sebas, 2024-10-10): This is necessary to prevent executing the onClick event of
                    // the parent element.
                    e.stopPropagation();
                    handleApplyTransform(transformDefaultValues);
                  }}
                />
              )
            }
          />
        </div>
      </InlinePopover>
    </div>
  );
};

const TransformOriginControl: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const draftElementTransformOrigin =
    useEditorSelector(selectTransformOrigin) ?? "";
  const draftElementTransformOriginObject = getTransformOriginObject(
    String(draftElementTransformOrigin),
  );

  const draftComponentId = useEditorSelector(selectDraftComponentId);

  const applyComponentAction = useApplyComponentAction();

  const transformOriginDefaultValues =
    styleAttributeToEditorData.transformOrigin.defaultValue.split(" ");
  const transformOriginX = transformOriginDefaultValues[0]!;
  const transformOriginY = transformOriginDefaultValues[1]!;
  const transformOriginZ = transformOriginDefaultValues[2]!;
  const transformOriginInputs = [
    {
      label: "X Axis",
      title: "transformOriginX" as const,
      resetValue: transformOriginX,
      anchorValue: transformOriginY,
      placeholder: transformOriginZ,
      metrics: CSS_LENGTH_TYPES,
      menuOptions: [
        {
          label: "Reset",
          value: transformOriginX,
        },
        {
          label: "Left",
          value: "0%",
        },
        {
          label: "Center",
          value: "50%",
        },
        {
          label: "Right",
          value: "100%",
        },
      ],
      autofocus: true,
    },
    {
      label: "Y Axis",
      title: "transformOriginY" as const,
      resetValue: transformOriginY,
      anchorValue: transformOriginY,
      placeholder: transformOriginY,
      metrics: CSS_LENGTH_TYPES,
      menuOptions: [
        {
          label: "Reset",
          value: transformOriginY,
        },
        {
          label: "Top",
          value: "0%",
        },
        {
          label: "Center",
          value: "50%",
        },
        {
          label: "Bottom",
          value: "100%",
        },
      ],
    },
    {
      label: "Z Axis",
      title: "transformOriginZ" as const,
      resetValue: transformOriginZ,
      anchorValue: transformOriginZ,
      placeholder: transformOriginZ,
      metrics: CSS_LENGTH_TYPES,
      menuOptions: [
        {
          label: "Reset",
          value: transformOriginZ,
        },
      ],
    },
  ];

  const handleApplyTransformOrigin = (value: string | null) => {
    applyComponentAction({
      componentId: draftComponentId,
      type: "setStyles",
      value: {
        transformOrigin: value,
      },
    });
  };

  const handleChange = (value: string) => {
    handleApplyTransformOrigin(value);
  };

  const internalOnChange = (
    value: string,
    inputName: "transformOriginX" | "transformOriginY" | "transformOriginZ",
  ) => {
    const newValues = {
      ...draftElementTransformOriginObject,
      [inputName]: value,
    };
    handleChange(getTransformOriginString(newValues));
  };

  return (
    <div className="flex items-center w-full">
      <ModifierLabel label="Origin" />
      <InlinePopover
        isOpen={isOpen}
        shouldPreventDefaultOnInteractOutside={false}
        onOpenChange={setIsOpen}
        title="Transform Origin"
        triggerAsChild
        sideOffset={82}
        content={
          <div className="flex flex-col gap-2">
            {transformOriginInputs.map(
              ({
                label,
                title,
                anchorValue,
                placeholder,
                resetValue,
                menuOptions,
                metrics,
                autofocus = false,
              }) => (
                <LengthInputSelector
                  label={<ModifierLabel label={label} />}
                  key={title}
                  metrics={metrics}
                  className="col-span-1"
                  field={title}
                  resetValue={resetValue}
                  anchorValue={anchorValue}
                  placeholder={placeholder}
                  value={draftElementTransformOriginObject[title]}
                  onChange={(value: string) => internalOnChange(value, title)}
                  menuOptions={menuOptions}
                  previewProperty="transformOrigin"
                  previewSubProperty={title}
                  autofocus={autofocus}
                  dragTrigger="label"
                />
              ),
            )}
          </div>
        }
      >
        <div className="flex-1">
          <SelectionIndicator
            title={draftElementTransformOrigin}
            onClick={() => setIsOpen(true)}
            onReset={() => handleApplyTransformOrigin(null)}
            placeholder="0px 0px 0px"
            endEnhancer={
              draftElementTransformOrigin !== "" && (
                <BsX
                  size={12}
                  className="cursor-pointer text-subtle"
                  onClick={(e) => {
                    // NOTE (Sebas, 2024-10-10): This is necessary to prevent executing the onClick event of
                    // the parent element.
                    e.stopPropagation();
                    handleApplyTransformOrigin(null);
                  }}
                />
              )
            }
          />
        </div>
      </InlinePopover>
    </div>
  );
};

type Transform = "move" | "scale" | "rotate" | "skew";
type ToggleGroupInput = {
  label: string;
  resetValue: string;
  anchorValue: string;
  placeholder: string;
  onChange(value: string): void;
  value: string | null;
  menuOptions?: { label: string; value: string }[];
  previewSubProperty?: PreviewableSubProperty;
  autofocus?: boolean;
  hasDivider?: boolean;
};
type ToggleGroupOption<Value extends string> = {
  label: string;
  value: Value;
  metrics: string[];
  inputs: ToggleGroupInput[];
};
type TransformOriginObject = {
  transformOriginX: string;
  transformOriginY: string;
  transformOriginZ: string;
};

/**
 * Converts a CSS transform value into a human-readable format.
 * @param transformValue The CSS transform value as a string.
 * @returns The human-readable transform value.
 */
const getReadableTransformValue = (transformValue: string): string => {
  const transformDefinitions: [RegExp, string][] = [
    [/translate3d\((.+?)\)/, "Translate"],
    [/scale3d\((.+?)\)/, "Scale"],
    [/rotateX\((.+?)\)/, "Rotate X"],
    [/rotateY\((.+?)\)/, "Rotate Y"],
    [/rotateZ\((.+?)\)/, "Rotate Z"],
    [/skew\((.+?)\)/, "Skew"],
  ];
  const readableTransforms = transformDefinitions.map(([regex, label]) => {
    const match = transformValue.match(regex);

    if (!match || !match[1]) {
      return null;
    }

    const values = match[1].split(",").map((val) => val.trim());

    const hasIdenticalValues = values.every((val) => val === values[0]);

    if (hasIdenticalValues) {
      return `${label} ${values[0]}`;
    }
    return `${label} ${values.join(" ")}`;
  });

  const filteredTransforms = filterNulls(readableTransforms);
  return filteredTransforms.join(" ");
};

const getTransformOriginString = (values: TransformOriginObject) => {
  return Object.values(values).join(" ").trim();
};

const getTransformOriginObject = (value: string) => {
  const stringArray = value.split(" ");
  const [transformOriginX, transformOriginY, transformOriginZ] = stringArray;
  const transformOriginDefaultValues =
    styleAttributeToEditorData.transformOrigin.defaultValue.split(" ");
  const defaultTransformOriginX = transformOriginDefaultValues[0]!;
  const defaultTransformOriginY = transformOriginDefaultValues[1]!;
  const defaultTransformOriginZ = transformOriginDefaultValues[2]!;
  return {
    transformOriginX: transformOriginX || defaultTransformOriginX,
    transformOriginY: transformOriginY || defaultTransformOriginY,
    transformOriginZ: transformOriginZ || defaultTransformOriginZ,
  };
};

const ShadowControl: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const [activeShadow, setActiveShadow] = useOverridableState<BoxShadow | null>(
    null,
  );
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const draftComponentBoxShadow = useEditorSelector(selectBoxShadow) || "";
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const draftComponentBoxShadows = draftComponentBoxShadow.split(",");
  // Note (Sebas, 2022-08-30): This is necessary to prevent breaking the editing of box shadows.
  const boxShadowIds = React.useRef<string[]>([]);
  const boxShadows =
    draftComponentBoxShadow.length > 0
      ? getBoxShadowObject(draftComponentBoxShadows, boxShadowIds)
      : [];

  const onCreate = (value: string) => {
    applyComponentAction({
      componentId: draftComponentId,
      type: "setStyles",
      value: {
        boxShadow: value,
      },
    });
  };

  const handleAddBoxShadow = () => {
    let newId = boxShadowIds.current[boxShadows.length]!;
    // Note (Sebas, 2022-09-01): In case there is no id
    // in the ref we need to create a new one
    if (!boxShadowIds.current[boxShadows.length]) {
      newId = uuidv4();
      boxShadowIds.current = [...boxShadowIds.current, newId];
    }
    const newBoxShadow = {
      id: newId,
      shadowType: "dropShadow" as const,
      offsetX: "0px",
      offsetY: "4px",
      blur: "4px",
      spread: "0px",
      shadowColor: "#00000040",
    };
    const newBoxShadowsArray = [...boxShadows, newBoxShadow];
    const newBoxShadows = getBoxShadowString(newBoxShadowsArray);
    setActiveShadow(newBoxShadow);
    setIsPopoverOpen(true);
    onCreate(newBoxShadows);
  };

  const handleRemoveBoxShadow = (id: string) => {
    // Note (Sebas, 2022-08-30): Remove id from ref
    boxShadowIds.current = boxShadowIds.current.filter((uuid) => uuid !== id);
    const filteredBoxShadows = boxShadows.filter(
      (boxShadow) => boxShadow.id !== id,
    );
    const updatedBoxShadowes = getBoxShadowString(filteredBoxShadows);
    onCreate(updatedBoxShadowes);
  };

  const handleEditBoxShadowPopover = (boxShadow: BoxShadow) => {
    setActiveShadow(boxShadow);
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleBoxShadowChange = (value: BoxShadow) => {
    setActiveShadow(value);
    const newBoxShadows = boxShadows.map((boxShadow) => {
      return boxShadow.id === value.id ? value : boxShadow;
    });
    const updatedBoxShadows = getBoxShadowString(newBoxShadows);
    onCreate(updatedBoxShadows);
  };

  const currentBoxShadowIndex = boxShadows.findIndex(
    (boxShadow) => boxShadow.id === activeShadow?.id,
  );

  return (
    <div className="flex w-full">
      <ModifierLabel label="Shadow" className="mt-1" />
      <div className="flex w-full flex-col">
        <div className="flex flex-col gap-1 flex-1">
          {boxShadows.length > 0
            ? boxShadows.map((boxShadow) => (
                <SelectionIndicator
                  className="max-w-40"
                  key={boxShadow.id}
                  title={formatTitle(boxShadow)}
                  onClick={() => handleEditBoxShadowPopover(boxShadow)}
                  startEnhancer={
                    <BadgeV2
                      type="color"
                      isFilled
                      backgroundColor={boxShadow.shadowColor}
                    />
                  }
                  endEnhancer={
                    <BsX
                      size={12}
                      className="cursor-pointer text-slate-400"
                      onClick={(e) => {
                        // NOTE (Sebas, 2024-10-10): This is necessary to prevent executing the onClick event of
                        // the parent element.
                        e.stopPropagation();
                        handleRemoveBoxShadow(boxShadow.id);
                      }}
                    />
                  }
                />
              ))
            : null}
          {/* 
              TODO (Fran 2024-10-07 REPL-13404): This Should be removed when we finish optional fields
              on modifiers. 
          */}
          <SelectionIndicator
            title=""
            placeholder="Add Shadow"
            onClick={handleAddBoxShadow}
            startEnhancer={
              <BadgeV2 type="color" backgroundColor="text-subtle" />
            }
          />
          <BoxShadowPopover
            isOpen={isPopoverOpen}
            setIsOpen={setIsPopoverOpen}
            activeShadow={activeShadow!}
            handleBoxShadowChange={handleBoxShadowChange}
            boxShadowIndex={currentBoxShadowIndex}
          />
        </div>
      </div>
    </div>
  );
};

const BoxShadowPopover: React.FC<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activeShadow: BoxShadow;
  handleBoxShadowChange(value: BoxShadow): void;
  boxShadowIndex: number;
}> = ({
  isOpen = false,
  setIsOpen,
  activeShadow,
  handleBoxShadowChange,
  boxShadowIndex,
}) => {
  const modal = useModal();
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const handleInputChange = (
    value: string,
    inputType:
      | "shadowType"
      | "offsetX"
      | "offsetY"
      | "blur"
      | "spread"
      | "shadowColor",
  ) => {
    const newBoxShadow = {
      ...activeShadow,
      [inputType]: value,
    };
    handleBoxShadowChange(newBoxShadow);
  };

  const openDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT_COLOR,
        referrerData: {
          type: "callback",
          onChange: (value: string) => {
            handleInputChange(value, "shadowColor");
          },
        },
        initialPath: activeShadow?.shadowType
          ? getPathFromVariable(activeShadow.shadowType)
          : undefined,
      },
    });
  };

  return (
    <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
      <Popover.Content
        title="Box Shadow"
        shouldPreventDefaultOnInteractOutside={areModalsOpen}
        sideOffset={82}
      >
        <div className="flex flex-col gap-2">
          <div className="flex items-center w-full">
            <ModifierLabel label="Style" />
            <Selectable
              className="col-span-2"
              onSelect={(value: string) =>
                handleInputChange(value, "shadowType")
              }
              value={activeShadow?.shadowType}
              options={[
                {
                  label: "None",
                  value: "none",
                },
                {
                  label: "Drop Shadow",
                  value: "dropShadow",
                },
                { label: "Inset", value: "inset" },
              ]}
            />
          </div>
          <LengthInputSelector
            label={<ModifierLabel label="X Axis" />}
            metrics={CSS_LENGTH_TYPES}
            className="col-span-1"
            field="offsetX"
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            value={activeShadow?.offsetX || null}
            onChange={(value: string) => handleInputChange(value, "offsetX")}
            isDisabled={activeShadow?.shadowType === "none"}
            previewProperty="boxShadow"
            previewSubProperty="offsetX"
            previewPropertyIndex={boxShadowIndex}
            autofocus
            dragTrigger="label"
          />
          <LengthInputSelector
            label={<ModifierLabel label="Y Axis" />}
            metrics={CSS_LENGTH_TYPES}
            className="col-span-1"
            field="offsetY"
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            dragTrigger="label"
            value={activeShadow?.offsetY || null}
            onChange={(value: string) => handleInputChange(value, "offsetY")}
            isDisabled={activeShadow?.shadowType === "none"}
            previewProperty="boxShadow"
            previewSubProperty="offsetY"
            previewPropertyIndex={boxShadowIndex}
          />
          <LengthInputSelector
            label={<ModifierLabel label="Blur" />}
            metrics={CSS_LENGTH_TYPES}
            className="col-span-1"
            minDragValues={{ px: 0 }}
            minValues={{ px: 0 }}
            field="blur"
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            dragTrigger="label"
            value={activeShadow?.blur || null}
            onChange={(value: string) => handleInputChange(value, "blur")}
            isDisabled={activeShadow?.shadowType === "none"}
            previewProperty="boxShadow"
            previewSubProperty="blur"
            previewPropertyIndex={boxShadowIndex}
          />
          <LengthInputSelector
            label={<ModifierLabel label="Spread" />}
            metrics={CSS_LENGTH_TYPES}
            className="col-span-1"
            field="spread"
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            dragTrigger="label"
            value={activeShadow?.spread || null}
            onChange={(value: string) => handleInputChange(value, "spread")}
            isDisabled={activeShadow?.shadowType === "none"}
            previewProperty="boxShadow"
            previewSubProperty="spread"
            previewPropertyIndex={boxShadowIndex}
          />
          <div className="flex items-center w-full">
            <ModifierLabel label="Color" />
            <SolidColorSelector
              popoverTitle="Shadow Color"
              value={activeShadow?.shadowColor || ""}
              onChange={(value: string) =>
                handleInputChange(value, "shadowColor")
              }
              isDisabled={activeShadow?.shadowType === "none"}
              defaultValue="#00000040"
              openDynamicData={openDynamicData}
              popoverSideOffset={88}
            />
          </div>
        </div>
      </Popover.Content>
      <Popover.Anchor className="relative top-0 left-0" />
    </Popover>
  );
};

const formatTitle = (boxShadow: BoxShadow) => {
  const boxShadowTitle = getBoxShadowString([boxShadow], true);
  return boxShadowTitle.includes("none") ? "None" : boxShadowTitle;
};

export default EffectsModifier;
