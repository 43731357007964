import * as React from "react";

import BadgeV2 from "@editor/components/common/designSystem/BadgeV2";
import ColorPicker from "@editor/components/common/designSystem/ColorPicker";
import ColorSwatch from "@editor/components/common/designSystem/ColorSwatch";
import DynamicDataButton from "@editor/components/common/designSystem/DynamicDataButton";
import InlinePopover from "@editor/components/common/designSystem/InlinePopover";
import Input from "@editor/components/common/designSystem/Input";
import Tooltip from "@editor/components/common/designSystem/Tooltip";
import {
  getAutoCompletedColor,
  getHex8Color,
} from "@editor/components/common/designSystem/utils/colors";
import { isNewRightBarUIEnabled } from "@editor/infra/featureFlags";
import {
  selectDraftComponentId,
  selectDraftElementColors,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import {
  getFormattedColor,
  getFormattedColorWithoutOpacity,
} from "@editor/utils/colors";
import { getHotKey } from "@editor/utils/getHotKey";
import { hasDynamicData } from "@editorModifiers/utils";
import HashIcon from "@svg/hash";

import classNames from "classnames";
import debounce from "lodash-es/debounce";
import { BsX } from "react-icons/bs";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";

type SolidColorSelectorProps = {
  value: string | null;
  defaultValue?: string;
  onPreviewChange?(value: string | null): void;
  onChange(value: string | null): void;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  openDynamicData?(): void;
  popoverTitle?: string;
  isDisabled?: boolean;
  autofocus?: boolean;
  allowColorPickerPopover?: boolean;
  popoverSideOffset?: number;
};

const SolidColorSelector: React.FC<SolidColorSelectorProps> = ({
  value,
  defaultValue,
  onChange,
  onPreviewChange,
  onDragEnd,
  onDragStart,
  openDynamicData,
  popoverTitle = "Color",
  isDisabled = false,
  autofocus = false,
  allowColorPickerPopover = true,
  popoverSideOffset = 28,
}) => {
  const rightBarUIEnabled = isNewRightBarUIEnabled();
  const [lastValidColorValue, setLastValidColorValue] = React.useState(value);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const elementColors = useEditorSelector(selectDraftElementColors);
  const [inputValue, setInputValue] = useOverridableState(
    getFormattedColorWithoutOpacity(value),
  );

  const dynamicData =
    hasDynamicData(draftComponentId) && openDynamicData !== undefined;

  /* eslint-disable react-hooks/exhaustive-deps */
  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = React.useCallback(
    debounce((value) => onChange(value), 300),
    [onChange],
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleColorChange = () => {
    //  Note (Sebas, 2022-05-29): Check if the user enters a value
    // or if the value is the same as the last valid color to prevent
    // the border from updating if it is not needed.
    if (inputValue !== null && inputValue === lastValidColorValue) {
      return;
    }
    const completedColor = getAutoCompletedColor(inputValue);
    if (completedColor) {
      const hexColor = getFormattedColor(completedColor);
      setLastValidColorValue(hexColor);
      handleChangeComplete(hexColor, { debounce: false });
    } else if (lastValidColorValue) {
      setInputValue(lastValidColorValue);
      onChange(lastValidColorValue);
    } else {
      setInputValue("");
    }
  };

  const handleChangeComplete = (
    color: string | null,
    opts: { debounce: boolean },
  ) => {
    let value: string | null = null;
    if (color) {
      const hex8color = getHex8Color(color);
      const formattedHex8Color = getFormattedColor(hex8color);
      value = formattedHex8Color;
    }

    onPreviewChange?.(value);
    if (opts.debounce) {
      debounceOnChange(value);
    } else {
      onChange(value);
    }
  };

  const handleColorRemove = () => {
    setInputValue(
      defaultValue ? getFormattedColorWithoutOpacity(defaultValue) : "",
    );
    onChange(defaultValue || null);
  };

  // Note (Sebas 2022-11-14): In case we want to reset the value with opt + click
  // we need to prevent the popover from opening
  const onTriggerClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const hotkey = getHotKey(e);
    if (hotkey === "altKey") {
      e.preventDefault();
    }
  };

  const shouldShowColorRemoveButton = inputValue && inputValue !== defaultValue;

  return (
    <div
      className={classNames(
        "modifier flex flex-grow flex-row justify-items-start",
        {
          "gap-2": !rightBarUIEnabled,
          "gap-1": rightBarUIEnabled,
        },
      )}
    >
      {!rightBarUIEnabled && (
        <InlinePopover
          shouldPreventDefaultOnInteractOutside={false}
          title={popoverTitle}
          content={
            <ColorPicker
              value={value ?? ""}
              allowsGradientSelection={false}
              onChange={(value) =>
                handleChangeComplete(value, { debounce: true })
              }
              documentColors={elementColors}
              onDragEnd={onDragEnd}
              onDragStart={onDragStart}
            />
          }
          triggerAsChild
        >
          <button
            type="button"
            className={classNames({
              "pointer-events-none": isDisabled,
            })}
            onClick={onTriggerClick}
          >
            <Tooltip content={popoverTitle} triggerAsChild>
              <div tabIndex={0}>
                <ColorSwatch
                  value={{ type: "color", color: value }}
                  onOptionClick={handleColorRemove}
                />
              </div>
            </Tooltip>
          </button>
        </InlinePopover>
      )}
      <Input
        unsafe_className="flex-grow"
        value={inputValue ?? ""}
        placeholder="#000000"
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
        startEnhancer={() => {
          if (rightBarUIEnabled) {
            return allowColorPickerPopover ? (
              <ColorPickerPopover
                value={inputValue?.replace("#", "") ?? ""}
                onChange={(value) =>
                  handleChangeComplete(value, { debounce: true })
                }
                onPreviewChange={onPreviewChange}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onTriggerClick={onTriggerClick}
                popoverSideOffset={popoverSideOffset}
              >
                <BadgeV2 type="color" isFilled backgroundColor={value ?? ""} />
              </ColorPickerPopover>
            ) : (
              <BadgeV2 type="color" isFilled backgroundColor={value ?? ""} />
            );
          }
          return (
            <Tooltip
              content={!isDisabled ? "Set Hex Color" : null}
              triggerAsChild
            >
              <div tabIndex={isDisabled ? -1 : 0}>
                <HashIcon />
              </div>
            </Tooltip>
          );
        }}
        onOptionClick={handleColorRemove}
        onBlur={handleColorChange}
        onEnter={handleColorChange}
        isDisabled={isDisabled}
        endEnhancer={() =>
          handleColorRemove &&
          shouldShowColorRemoveButton && (
            <div
              className="h-4 w-4 cursor-pointer text-subtle flex items-center justify-center"
              onClick={() => {
                setLastValidColorValue(null);
                handleColorRemove?.();
              }}
            >
              <BsX size={12} />
            </div>
          )
        }
        autoFocus={autofocus}
      />
      {dynamicData && (
        <div className="flex">
          <DynamicDataButton
            tooltipText="Add Dynamic Data"
            onClick={(e) => {
              e.stopPropagation();
              openDynamicData?.();
            }}
          />
        </div>
      )}
    </div>
  );
};

type ColorPickerPopoverProps = {
  value: string | null;
  onChange(value: string | null): void;
  onPreviewChange?(value: string | null): void;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  openDynamicData?(): void;
  popoverTitle?: string;
  onTriggerClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  popoverSideOffset?: number;
};

const ColorPickerPopover: React.FC<
  React.PropsWithChildren<ColorPickerPopoverProps>
> = ({
  popoverTitle = "Color",
  value,
  onChange,
  onPreviewChange,
  onDragStart,
  onDragEnd,
  onTriggerClick,
  children,
  popoverSideOffset = 28,
}) => {
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const elementColors = useEditorSelector(selectDraftElementColors);
  return (
    <InlinePopover
      title={popoverTitle}
      shouldPreventDefaultOnInteractOutside={areModalsOpen}
      content={
        <ColorPicker
          value={value}
          allowsGradientSelection={false}
          onChange={onChange}
          onPreviewChange={onPreviewChange}
          documentColors={elementColors}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
        />
      }
      triggerAsChild
      sideOffset={popoverSideOffset}
    >
      <button type="button" onClick={onTriggerClick}>
        <Tooltip content={popoverTitle} triggerAsChild>
          <div tabIndex={0}>{children}</div>
        </Tooltip>
      </button>
    </InlinePopover>
  );
};

export default SolidColorSelector;
