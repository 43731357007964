import * as React from "react";

import Tooltip from "@editor/components/common/designSystem/Tooltip";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import {
  selectGridColumnEnd,
  selectGridRowEnd,
  selectIsParentGrid,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import ModifierGroup from "@editorExtras/ModifierGroup";
import Stepper from "@editorModifiers/Stepper";

import { HiOutlineMenu } from "react-icons/hi";
import { MdViewColumn } from "react-icons/md";
import { coerceNumberToString } from "replo-utils/lib/misc";

export const RowColumnSpanModifier = () => {
  const applyComponentAction = useApplyComponentAction();

  const gridColumnEnd = useEditorSelector(selectGridColumnEnd);
  const gridRowEnd = useEditorSelector(selectGridRowEnd);

  const isParentGrid = useEditorSelector(selectIsParentGrid);

  const gridColumnEndValue = Number.parseInt(
    coerceNumberToString(gridColumnEnd)?.split?.("span")?.[1] ?? "1",
    10,
  );
  const gridRowEndValue = Number.parseInt(
    coerceNumberToString(gridRowEnd)?.split?.("span")?.[1] ?? "1",
    10,
  );

  if (!isParentGrid) {
    return null;
  }

  return (
    <ModifierGroup title="Row/Column span">
      <div className="flex flex-col gap-2">
        <Stepper
          value={gridColumnEndValue}
          placeholder="1"
          onChange={(value: number) => {
            applyComponentAction({
              type: "setStyles",
              value: {
                gridColumnEnd: `span ${value}`,
              },
            });
          }}
          toolTipPlusButton="Add column span"
          toolTipMinusButton="Remove column span"
          field="style.gridColumnEnd"
          startEnhancer={() => (
            <Tooltip inheritCursor content="Column/Row span" triggerAsChild>
              <span tabIndex={0}>
                <MdViewColumn size={18} />
              </span>
            </Tooltip>
          )}
          resetValue="1"
          anchorValue="1"
          minDragValue={1}
        />
        <Stepper
          value={gridRowEndValue}
          placeholder="1"
          onChange={(value: number) => {
            applyComponentAction({
              type: "setStyles",
              value: {
                gridRowEnd: `span ${value}`,
              },
            });
          }}
          toolTipPlusButton="Add row span"
          toolTipMinusButton="Remove row span"
          field="style.gridRowEnd"
          startEnhancer={() => (
            <Tooltip inheritCursor content="Column span" triggerAsChild>
              <span tabIndex={0}>
                <HiOutlineMenu size={18} />
              </span>
            </Tooltip>
          )}
          resetValue="1"
          anchorValue="1"
          minDragValue={1}
        />
      </div>
    </ModifierGroup>
  );
};
