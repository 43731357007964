import type { ComponentActionType } from "@editor/types/component-action-type";
import type { CoreState } from "@editor/types/core-state";
import type {
  RuntimeStyleAttribute,
  RuntimeStyleProperties,
} from "replo-runtime/shared/styleAttribute";
import type { ReploElement } from "schemas/generated/element";

import { getAttributeRetriever } from "@editor/utils/component-attribute";
import {
  convertIntoCompositeAction,
  resolveComponent,
} from "@reducers/utils/component-actions";

import capitalize from "lodash-es/capitalize";
import {
  findDefault,
  findVariant,
  isHoverVariant,
} from "replo-runtime/shared/variant";

export default function transformSetStyles(
  action: ComponentActionType & { type: "setStyles" },
  elements: Record<string, ReploElement>,
  state: CoreState,
): ComponentActionType {
  const { value } = action;
  if (hasBorderWidth(value)) {
    return hoverBorderWidthTransform(action, elements, state);
  }

  return action;
}

function hasBorderWidth(value: Record<string, any>) {
  const multipleBorderWidth = {
    borderBottomWidth: value.borderBottomWidth,
    borderLeftWidth: value.borderLeftWidth,
    borderRightWidth: value.borderRightWidth,
    borderTopWidth: value.borderTopWidth,
  };

  return multipleToShorthand(multipleBorderWidth) !== undefined;
}

/**
 * Function to deal with border-width for multiple variants.
 */
function hoverBorderWidthTransform(
  action: ComponentActionType & { type: "setStyles" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) {
  const { value } = action;

  const multipleBorderWidth: RuntimeStyleProperties = {
    borderBottomWidth: value.borderBottomWidth,
    borderLeftWidth: value.borderLeftWidth,
    borderRightWidth: value.borderRightWidth,
    borderTopWidth: value.borderTopWidth,
  };

  const borderWidth = multipleToShorthand(multipleBorderWidth)!;

  const component = resolveComponent(action, elements, state);
  if (!component) {
    return action;
  }

  const { id, variants = [] } = component;
  const currentVariant = findVariant(
    variants,
    state.elements.componentIdToDraftVariantId[id]!,
  );

  if (!currentVariant || !isHoverVariant(currentVariant)) {
    return action;
  }

  const defaultVariant = findDefault(variants);
  const getAttribute = getAttributeRetriever(state, action.activeCanvas);

  const getStyleAttribute = (
    property: RuntimeStyleAttribute,
    variantId?: string,
  ) => {
    return getAttribute(
      component,
      `style.${property}`,
      null,
      variantId ? { variantId } : null,
    ).value;
  };

  const defaultBorderWidth = multipleToShorthand(
    shorthandToMultiple("borderWidth", (property) =>
      getStyleAttribute(property as RuntimeStyleAttribute, defaultVariant.id),
    ),
  );

  const defaultBorderColor = multipleToShorthand(
    shorthandToMultiple("borderColor", (property) =>
      getStyleAttribute(property as RuntimeStyleAttribute, defaultVariant.id),
    ),
  );

  if (defaultBorderWidth === undefined || defaultBorderColor === undefined) {
    return action;
  }

  if (Number.parseInt(borderWidth, 10) >= 1) {
    if (!defaultBorderWidth || borderWidth !== defaultBorderWidth) {
      // If setting border-width in the hover variant and border-width
      // of default variant is different, we need to unify those values.
      const newValue = Object.assign(
        {},
        multipleBorderWidth,
        // Initial transparent color if defaultVariant has no border-color
        !defaultBorderColor
          ? shorthandToMultiple("borderColor", "transparent")
          : {},
      );

      return convertIntoCompositeAction([
        action,
        {
          type: "setStyles",
          activeCanvas: action.activeCanvas,
          componentId: component.id,
          variantId: defaultVariant.id,
          value: newValue,
        },
      ]);
    }
  } else {
    if (defaultBorderColor === "transparent") {
      // If setting border-width to 0 in the hover variant and border-color
      // is transparent in the default variant, it means it was automatically
      // set before so we need to undo it.
      return convertIntoCompositeAction([
        action,
        {
          type: "setStyles",
          activeCanvas: action.activeCanvas,
          componentId: component.id,
          variantId: defaultVariant.id,
          value: shorthandToMultiple("borderWidth", "0px"),
        },
      ]);
    }
  }
  return action;
}

/*
 * Function that returns the value of a style property if all the
 * single values of the property are set and are the same.
 */
function multipleToShorthand(
  properties: RuntimeStyleProperties,
): string | undefined {
  const propertiesAsEntries = Object.entries(properties);
  if (propertiesAsEntries.some((prop) => !prop)) {
    return undefined;
  }

  return propertiesAsEntries.every((val, _, arr) => val[1] === arr[0]![1])
    ? propertiesAsEntries[0]![1]
    : undefined;
}

/*
 * Function that converts a shorthand style property into multiple
 * properties for each side.
 */
function shorthandToMultiple(
  property: string,
  value: string | ((property: string) => string),
): Record<string, string> {
  // Split property for combining it with sides
  const [first, last] = property.split(/(?=[A-Z])/);
  return Object.fromEntries(
    ["top", "right", "bottom", "left"].map((side) => {
      const builtProp = [first, capitalize(side), capitalize(last)].join("");
      return [
        builtProp,
        typeof value === "function" ? value(builtProp) : value,
      ];
    }),
  );
}
