import * as React from "react";

import Button from "@common/designSystem/Button";
import IconButton from "@common/designSystem/IconButton";

import {
  Content,
  Portal,
  Provider,
  Root,
  Trigger,
} from "@radix-ui/react-tooltip";
import classNames from "classnames";
import isString from "lodash-es/isString";
import { animated, config, useTransition } from "react-spring";
import { useControllableState } from "replo-utils/react/use-controllable-state";

type TooltipSide = "top" | "bottom" | "left" | "right";

type TooltipProps = {
  children: React.ReactNode;
  content: React.ReactNode | null;
  side?: TooltipSide;
  sideOffset?: number;
  isOpen?: boolean;
  onOpenChange?(): void;
  delay?: number;
  className?: string;
  style?: React.CSSProperties;
  /**
   * NOTE: (Mariano, 2022-02-16): **Setting `triggerAsChild` to false will avoid
   * the Trigger component creating a new button tag.** This is useful when the
   * child component is already a button.
   *
   * NOTE (Chance 2023-11-02): This prop is now required because we need to
   * think purposefully about what is rendered based on the context, as it's
   * currently too easy to accidentally nest buttons and end up with invalid
   * HTML. Long term we probably want rethink the API to deal with this.
   *
   * It's also important to note that **this prop only works correctly if the
   * child is either a built-in DOM component (div, span, etc.) or a custom
   * component that forwards refs**. If the child is a function component that
   * does not forward refs, you will need to add an additional div or span
   * wrapper.
   *
   * @example
   * ```tsx
   * // This is generally ok! Tooltip will wrap the text in a button element.
   * // Ensure that this does is not nested inside another button higher in
   * // the component tree.
   * <Tooltip>
   *   Important information
   * </Tooltip>
   *
   * // This is also generally ok! The trigger may not need to be a button in
   * // all cases, but we want to ensure its content is still accessible to
   * // non-mouse users so we provide a tabIndex so that it can be focused,
   * // which will trigger the tooltip.
   * <Tooltip triggerAsChild>
   *   <span tabIndex={0}>Important information</span>
   * </Tooltip>
   *
   * // This is NOT ok! Set `triggerAsChild` to true to prevent nested buttons.
   * <Tooltip>
   *   <button>Click me</button>
   * </Tooltip>
   *
   * // This is NOT ok! Our `Button` component will render its own button element.
   * <Tooltip>
   *   <Button>Click me</Button>
   * </Tooltip>
   *
   * // This is NOT ok! `a` tags are interactive on their own and shoult not be
   * // nested inside a button. Set `triggerAsChild` to true when wrapping links.
   * <Tooltip>
   *   <a href="/">Home</a>
   * </Tooltip>
   *
   * // This is *probably* not ok! Most icon components do not forward refs. Wrap
   * // the icon in a `div` or `span` to avoid nesting buttons.
   * <Tooltip triggerAsChild>
   *   <IconFromLib />
   * </Tooltip>
   * ```
   */
  triggerAsChild: boolean;
  inheritCursor?: boolean;
  disableHoverableContent?: boolean;
};

type TooltipProviderProps = {
  children: React.ReactNode;
  show: boolean;
  setShow(show: boolean): void;
};

type TransitionElement = {
  fromX: string;
  fromY: string;
  toX: string;
  toY: string;
  leaveX: string;
  leaveY: string;
};

function _getTransition(side: TooltipSide) {
  const sideToTransition: Record<TooltipSide, TransitionElement> = {
    left: {
      fromX: "50%",
      fromY: "0",
      toX: "0%",
      toY: "0",
      leaveX: "50%",
      leaveY: "0",
    },
    right: {
      fromX: "-50%",
      fromY: "0",
      toX: "0%",
      toY: "0",
      leaveX: "-50%",
      leaveY: "0",
    },
    top: {
      fromX: "0",
      fromY: "50%",
      toX: "0",
      toY: "0%",
      leaveX: "0",
      leaveY: "50%",
    },
    bottom: {
      fromX: "0",
      fromY: "-50%",
      toX: "0",
      toY: "0%",
      leaveX: "0",
      leaveY: "-50%",
    },
  };
  return {
    from: {
      opacity: 0,
      transform: `translate(${sideToTransition[side].fromX}, ${sideToTransition[side].fromY})`,
    },
    enter: {
      opacity: 1,
      transform: `translate(${sideToTransition[side].toX},${sideToTransition[side].toY})`,
    },
  };
}

const TooltipRoot = ({ children, show, setShow }: TooltipProviderProps) => {
  return (
    <Root onOpenChange={setShow} open={show}>
      {children}
    </Root>
  );
};

const Tooltip = ({
  children,
  content,
  side = "top",
  sideOffset = 10,
  isOpen,
  onOpenChange,
  className,
  style,
  triggerAsChild,
  inheritCursor = false,
  disableHoverableContent = true,
  delay = 50,
}: TooltipProps) => {
  const [show, setShow] = useControllableState(isOpen, false, onOpenChange);

  const transitions = useTransition(isOpen === undefined ? show : isOpen, {
    ..._getTransition(side),
    config: config.stiff,
  });

  if (!content) {
    return <>{children}</>;
  }

  // Note (Chance, 2023-09-16) By default Radix Tooltip.Trigger will render a
  // button element. In cases where the child is already a button we want to
  // avoid nesting buttons which is invalid HTML and will cause
  // usability/accessibility issues. `triggerAsChild` allows us to avoid this in
  // the consuming component, but it's easy to miss, and in practice we end up
  // with nested buttons all over the place as a result. If the child is a
  // button and the parents hasn't already set `triggerAsChild` we will render a
  // focusable <span> instead.
  //
  // It's important to note that this is not bulletproof. The child may not be a
  // "button" or one of our DS Button elements. It could be a <div> with a click
  // handler or some other component that closes over a button. Wrapping other
  // interactive elements such as links is also problematic for similar reasons.
  // The responsibility for ensuring proper semantics will ultimately fall on
  // the consuming component, but this is a guard we can use for simple cases.
  //
  // IMO a better long-term solution would be to strictly enforce allowable
  // children in Tooltip. Restricting this at the DS layer makes sense and keeps
  // us from going crazy with tooltips where they don't belong.
  let child: React.ReactNode = children;
  let asChild = triggerAsChild;
  if (
    !triggerAsChild &&
    React.isValidElement(children) &&
    (children.type === "button" ||
      children.type === "a" ||
      children.type === Button ||
      children.type === IconButton)
  ) {
    if (
      // Note (Chance, 2023-09-16) Ensure that disabled buttons have a focusable
      // wrapper so that tooltip content can be accessible via keyboard.
      //
      // TODO Ensure that the inner content makes it clear when the child is
      // disabled.
      children.props?.disabled === true ||
      children.props?.disabled === "true" ||
      children.props?.isDisabled === true ||
      children.props?.tabIndex === -1
    ) {
      child = <span tabIndex={0}>{children}</span>;
    }
    asChild = true;
  }

  return (
    // Note (Noah, 2022-04-19): This is non-zero because 0 creates issues where you'll hover
    // over a tooltip on the way to another button and the tooltip will start to animate, but
    // then you'll click on the button but you'll still technically be hovering over the tooltip
    <Provider
      delayDuration={delay}
      disableHoverableContent={disableHoverableContent}
    >
      <TooltipRoot show={show} setShow={setShow}>
        <Trigger
          asChild={asChild}
          style={inheritCursor ? { cursor: "inherit" } : undefined}
          // NOTE (Gabe 2023-10-18): The radix tooltip trigger needs a type of
          // button to ensure that when used inside a form it does not trigger a
          // submit.
          type="button"
        >
          {child}
        </Trigger>
        <Portal>
          {transitions(
            (styles, item) =>
              item && (
                <Content forceMount asChild side={side} sideOffset={sideOffset}>
                  <animated.div
                    style={{
                      ...styles,
                      pointerEvents: disableHoverableContent ? "none" : "auto",
                    }}
                  >
                    <div
                      className={classNames(
                        "rounded bg-slate-800 px-2 py-1 text-xs text-slate-50",
                        { "max-w-[250px]": isString(content) },
                        className,
                      )}
                      style={style}
                    >
                      {content}
                    </div>
                  </animated.div>
                </Content>
              ),
          )}
        </Portal>
      </TooltipRoot>
    </Provider>
  );
};

export default Tooltip;
