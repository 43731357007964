import { reploComponentSchema } from "schemas/component";
import {
  dependencySchema,
  metafieldValuesMappingSchema,
  storeProductSchema,
} from "schemas/element";
import { z } from "zod";

export const altTextSchema = z.object({
  result: z.string().nullable(),
});

const supportedAiModels = ["claude-3.5-sonnet", "gpt-4o"] as const;

export type SupportedAiModel = (typeof supportedAiModels)[number];

const baseAiActionSchema = z.object({
  // TODO (Gabe 2024-08-15): Make this required once client have been updated.
  elementId: z.string().optional(),
  component: reploComponentSchema,
  projectId: z.string(),
  streamingUpdateId: z.string().uuid(),

  screenshotDependencies: z.object({
    dependencies: z.record(z.array(dependencySchema)),
    products: z.array(storeProductSchema),
    productMetafieldValues: z.record(metafieldValuesMappingSchema),
    variantMetafieldValues: z.record(metafieldValuesMappingSchema),
    fullComponent: reploComponentSchema,
  }),
  model: z.enum(supportedAiModels).optional(),
});

// TODO (Evan, 2024-09-13): for a little while, we're going to have two version of this -
// one with brand URL (to infer tone), and the other with the tone already inferred. Eventually,
// we will only have the second - after UI updates for AI in onboarding.
const aiProjectContextBase = z.object({
  whatBusinessSells: z.string().optional(),
  whoIsCustomer: z.string().optional(),
});

export const aiProjectContextSchema = aiProjectContextBase.extend({
  useExistingSite: z.boolean().optional(),
  existingSiteUrl: z.string().optional(),
});

export type AiProjectContext = z.infer<typeof aiProjectContextSchema>;

export const brandDetailsSchema = z.object({
  brandName: z.string().max(512).optional(),
  whatBusinessSells: z.string().max(512).optional(),
  whoIsCustomer: z.string().max(512).optional(),
  brandVoice: z.string().max(512).optional(),
});

export type BrandDetails = z.infer<typeof brandDetailsSchema>;

export const textv2Schema = baseAiActionSchema
  .extend({
    userPrompt: z.string(),
    // TODO (Evan, 2024-09-24): Remove this once clients have been updated
    projectContext: aiProjectContextSchema.optional(),
  })
  .describe("TextV2Payload");

export type TextV2Body = z.infer<typeof textv2Schema>;

export type TextContextPresent = Extract<
  keyof AiProjectContext,
  "whatBusinessSells" | "whoIsCustomer" | "existingSiteUrl"
>[];

export const mobileResponsiveSchema = baseAiActionSchema
  .extend({})
  .describe("MobileResponsivePayload");

export type MobileResponsiveBody = z.infer<typeof mobileResponsiveSchema>;

// Note (Evan, 2024-06-14): Our generated actions are either component actions (i.e., from ComponentActionType)
// or pseudo-actions actions that aren't part of ComponentActionType but are mapped to ComponentActionType actions.
// This is not 1:1 with ComponentActionType, since only a subset of actions are generated by the AI model.
export const bulkReplaceTextActionSchema = z.object({
  type: z.literal("bulkReplaceText"),
  mapping: z.record(z.string(), z.string()),
});

export const setPropsActionSchema = z.object({
  componentId: z.string(),
  type: z.literal("setProps"),
  value: z.record(z.any()),
});

export const setStylesActionSchema = z
  .object({
    componentId: z.string(),
    type: z.literal("setStyles"),
    // Note (Evan, 2024-07-10): Zod doesn't support passing the actual enum
    // (https://github.com/colinhacks/zod/discussions/2125)
    activeCanvas: z.enum(["desktop", "tablet", "mobile"]),
    value: z.record(z.any()),
  })
  .describe("SetStylesAction");

export const setStylesMobileActionSchema = z
  .object({
    componentId: z.string(),
    type: z.literal("setStylesMobile"),
    value: z.record(z.any()),
  })
  .describe("SetStylesMobileAction");

// Note (Evan, 2024-07-03): These are separated so that we can accept only
// "real" component actions (not pseudoactions) on the frontend.
const aiComponentActions = [
  setPropsActionSchema,
  setStylesActionSchema,
] as const;
export const aiComponentActionSchema = z.union(aiComponentActions);

const aiPseudoActions = [
  bulkReplaceTextActionSchema,
  setStylesMobileActionSchema,
] as const;

const allAiActions = [...aiComponentActions, ...aiPseudoActions] as const;

// Note (Evan, 2024-07-03): Slight confusion here:
// - AnyAiActionSchema is the Typescript type for any (single) schema.
// - AnyAiAction is the Typescript type inferred from any (single) schema.
export type AnyAiActionSchema = (typeof allAiActions)[number];
export type AnyAiAction = z.infer<AnyAiActionSchema>;
