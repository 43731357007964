import type { SolidOrGradient } from "replo-runtime/shared/types";

import * as React from "react";

import BadgeV2 from "@editor/components/common/designSystem/BadgeV2";
import ColorPicker from "@editor/components/common/designSystem/ColorPicker";
import ColorSwatch from "@editor/components/common/designSystem/ColorSwatch";
import DynamicDataButton from "@editor/components/common/designSystem/DynamicDataButton";
import InlinePopover from "@editor/components/common/designSystem/InlinePopover";
import Input from "@editor/components/common/designSystem/Input";
import Tooltip from "@editor/components/common/designSystem/Tooltip";
import { getAutoCompletedColor } from "@editor/components/common/designSystem/utils/colors";
import { isNewRightBarUIEnabled } from "@editor/infra/featureFlags";
import {
  selectDraftComponentId,
  selectDraftElementColors,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import {
  getFormattedColor,
  getFormattedColorWithoutOpacity,
} from "@editor/utils/colors";
import { getHotKey } from "@editor/utils/getHotKey";
import { hasDynamicData } from "@editorModifiers/utils";
import HashIcon from "@svg/hash";

import classNames from "classnames";
import debounce from "lodash-es/debounce";
import { BsX } from "react-icons/bs";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { gradientToCssGradient } from "replo-runtime/shared/utils/gradient";
import { mapNull } from "replo-runtime/shared/utils/optional";

type Props = {
  value: SolidOrGradient | null;
  onPreviewChange?(value: SolidOrGradient | null): void;
  onChange(value: SolidOrGradient | null): void;
  onRemove?(): void;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  openDynamicData?(): void;
  popoverTitle?: string;
  isDisabled?: boolean;
  allowColorPickerPopover?: boolean;
  popoverSideOffset?: number;
};

const GradientSelector: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  onChange,
  onRemove,
  onPreviewChange,
  onDragEnd,
  onDragStart,
  openDynamicData,
  popoverTitle = "Color",
  isDisabled,
  allowColorPickerPopover = true,
  popoverSideOffset,
}) => {
  const rightBarUIEnabled = isNewRightBarUIEnabled();
  const [inputValue, setInputValue] = useOverridableState(value);
  const [lastValidColorValue, setLastValidColorValue] = React.useState(value);
  const debounceOnChange = debounce((value) => onChange(value), 300);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const dynamicData =
    hasDynamicData(draftComponentId) && openDynamicData !== undefined;

  const inputValueText = getInputTextValue(inputValue);
  const badgeValue = getBadgeValue(inputValue);

  const handleChangeComplete = (value: SolidOrGradient) => {
    if (value.type === "solid") {
      setInputValue(value);
      setLastValidColorValue(value);
      onPreviewChange?.(value);
      debounceOnChange(value);
    } else {
      setInputValue(value);
      onPreviewChange?.(value);
      debounceOnChange(value);
    }
  };

  const handleColorInputChange = (value: string) => {
    setInputValue({ type: "solid", color: getFormattedColor(value) });
  };

  const handleColorChange = () => {
    if (inputValue?.type === "solid") {
      const color = getAutoCompletedColor(inputValue.color);
      if (color) {
        // Always convert to uppercase for display in the input field
        const hexColor = getFormattedColor(color);
        setInputValue({
          type: "solid",
          color: hexColor,
        });
        setLastValidColorValue({ type: "solid", color: hexColor });
        onChange({ type: "solid", color: hexColor });
      } else if (color === "") {
        setLastValidColorValue({ type: "solid", color });
        setInputValue({ type: "solid", color });
        onChange(null);
      } else {
        setInputValue(lastValidColorValue);
      }
    }
  };

  const handleColorRemove = () => {
    if (onRemove) {
      onRemove();
    } else {
      setInputValue({ type: "solid", color: "" });
      onChange({ type: "solid", color: "" });
    }
  };

  // Note (Sebas 2022-11-14): In case we want to reset the value with opt + click
  // we need to prevent the popover from opening
  const onTriggerClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const hotkey = getHotKey(e);
    if (hotkey === "altKey") {
      e.preventDefault();
    }
  };

  if (isDisabled) {
    return (
      <div
        className={classNames(
          "modifier flex flex-grow flex-row justify-items-start",
          {
            "gap-2": !rightBarUIEnabled,
            "gap-1": rightBarUIEnabled,
          },
        )}
      >
        {!rightBarUIEnabled && (
          <ColorSwatch
            data-testid={`${popoverTitle.replace(" ", "")}-colorSwatch`}
            value={mapNull(inputValue, (inputValue) =>
              inputValue.type === "solid"
                ? { type: "color", color: inputValue.color }
                : { type: "gradient", gradient: inputValue.gradient },
            )}
          />
        )}
        <div className="flex flex-grow flex-row justify-start">
          <Input
            unsafe_className="flex-grow"
            value={inputValueText}
            startEnhancer={() =>
              rightBarUIEnabled ? (
                <BadgeV2 type="color" isFilled backgroundColor={badgeValue} />
              ) : (
                <Tooltip
                  content={!isDisabled ? "Set Hex Color" : null}
                  triggerAsChild
                >
                  <div tabIndex={isDisabled ? -1 : 0}>
                    <HashIcon />
                  </div>
                </Tooltip>
              )
            }
            isDisabled
            endEnhancer={() =>
              handleColorRemove &&
              inputValueText && (
                <div
                  className="h-4 w-4 cursor-pointer text-subtle"
                  onClick={() => {
                    setLastValidColorValue(null);
                    handleColorRemove?.();
                  }}
                >
                  <BsX size={12} />
                </div>
              )
            }
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "modifier flex flex-grow flex-row justify-items-start",
        {
          "gap-2": !rightBarUIEnabled,
          "gap-1": rightBarUIEnabled,
        },
      )}
    >
      {!rightBarUIEnabled && (
        <ColorPickerPopover
          value={inputValue}
          onChange={handleChangeComplete}
          onPreviewChange={onPreviewChange}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onTriggerClick={onTriggerClick}
        >
          <ColorSwatch
            data-testid={`${popoverTitle.replace(" ", "")}-colorSwatch`}
            value={mapNull(inputValue, (inputValue) =>
              inputValue.type === "solid"
                ? { type: "color", color: inputValue.color }
                : { type: "gradient", gradient: inputValue.gradient },
            )}
            onOptionClick={handleColorRemove}
          />
        </ColorPickerPopover>
      )}
      <div
        className={classNames("flex flex-grow flex-row", {
          "gap-1": rightBarUIEnabled,
          "gap-2": !rightBarUIEnabled,
        })}
      >
        <Input
          unsafe_className="flex-grow"
          value={inputValueText.replace("#", "")}
          placeholder="#000000"
          onChange={(e) => handleColorInputChange(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
          startEnhancer={() => {
            if (rightBarUIEnabled) {
              return allowColorPickerPopover ? (
                <ColorPickerPopover
                  value={inputValue}
                  onChange={handleChangeComplete}
                  onPreviewChange={onPreviewChange}
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                  onTriggerClick={onTriggerClick}
                  popoverSideOffset={popoverSideOffset}
                >
                  <BadgeV2 type="color" isFilled backgroundColor={badgeValue} />
                </ColorPickerPopover>
              ) : (
                <BadgeV2 type="color" isFilled backgroundColor={badgeValue} />
              );
            }
            return (
              <Tooltip
                content={!isDisabled ? "Set Hex Color" : null}
                triggerAsChild
              >
                <div tabIndex={isDisabled ? -1 : 0}>
                  <HashIcon />
                </div>
              </Tooltip>
            );
          }}
          onOptionClick={handleColorRemove}
          onBlur={handleColorChange}
          onEnter={handleColorChange}
          isDisabled={badgeValue.includes("linear-gradient")}
          endEnhancer={() =>
            handleColorRemove &&
            inputValueText && (
              <div
                className="h-3 w-3 cursor-pointer text-slate-400"
                onClick={() => {
                  setLastValidColorValue(null);
                  handleColorRemove?.();
                }}
              >
                <BsX size={12} />
              </div>
            )
          }
          autoFocus={false}
        />
        {dynamicData && (
          <div className="flex">
            <DynamicDataButton
              tooltipText="Add Dynamic Data"
              onClick={(e) => {
                e.stopPropagation();
                openDynamicData?.();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

type ColorPickerPopoverProps = {
  value: SolidOrGradient | null;
  onChange(value: SolidOrGradient | null): void;
  onPreviewChange?(value: SolidOrGradient | null): void;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  openDynamicData?(): void;
  popoverTitle?: string;
  onTriggerClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  popoverSideOffset?: number;
};

const ColorPickerPopover: React.FC<
  React.PropsWithChildren<ColorPickerPopoverProps>
> = ({
  popoverTitle = "Color",
  value,
  onChange,
  onPreviewChange,
  onDragStart,
  onDragEnd,
  onTriggerClick,
  children,
  popoverSideOffset = 28,
}) => {
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const elementColors = useEditorSelector(selectDraftElementColors);
  return (
    <InlinePopover
      title={popoverTitle}
      shouldPreventDefaultOnInteractOutside={areModalsOpen}
      sideOffset={popoverSideOffset}
      content={
        <ColorPicker
          value={value}
          allowsGradientSelection
          onChange={onChange}
          onPreviewChange={onPreviewChange}
          documentColors={elementColors}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
        />
      }
      triggerAsChild
    >
      <button type="button" onClick={onTriggerClick}>
        <Tooltip content={popoverTitle} triggerAsChild>
          <div tabIndex={0}>{children}</div>
        </Tooltip>
      </button>
    </InlinePopover>
  );
};

const getInputTextValue = (color: SolidOrGradient | null): string => {
  if (!color) {
    return "";
  }
  if (color.type === "solid") {
    return getFormattedColorWithoutOpacity(color.color) ?? "";
  }
  return "Gradient";
};

const getBadgeValue = (color: SolidOrGradient | null): string => {
  if (!color) {
    return "";
  }
  if (color.type === "solid") {
    return color.color ?? "";
  }
  return gradientToCssGradient(color.gradient) ?? "";
};

export default GradientSelector;
