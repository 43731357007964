import type { TextShadow } from "@editor/utils/textShadow";

import * as React from "react";

import Badge from "@editor/components/common/designSystem/Badge";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Popover from "@editor/components/common/designSystem/Popover";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import Slider from "@editor/components/common/designSystem/Slider";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import {
  selectDraftComponentId,
  selectTextShadow,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { DraggingTypes } from "@editor/utils/editor";
import { getIntegerValueFromString } from "@editor/utils/getIntegerValueFromString";
import {
  formatTitle,
  getTextShadowString,
  parseTextShadows,
} from "@editor/utils/textShadow";
import SolidColorSelector from "@editorComponents/SolidColorSelector";
import ModifierGroup from "@editorExtras/ModifierGroup";
import { LengthInputSelector } from "@editorModifiers/LengthInputModifier";

import { BsSubtract } from "react-icons/bs";
import { MdAdd } from "react-icons/md";
import { RiCloseFill } from "react-icons/ri";
import { TbDropletFilled } from "react-icons/tb";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { CSS_LENGTH_TYPES } from "replo-runtime/shared/utils/units";
import { v4 as uuidv4 } from "uuid";

// NOTE (Fran 2024-10-07): This will be part of the text style modifier
const TextShadowModifierDeprecated: React.FC = () => {
  const store = useEditorStore();
  const applyComponentAction = useApplyComponentAction();
  const [activeShadowId, setActiveShadowId] = useOverridableState<
    string | null
  >(null);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const draftComponentTextShadow = useEditorSelector(selectTextShadow);
  const draftComponentTextShadows = draftComponentTextShadow?.split(",") ?? [];
  // Note (Sebas, 2022-08-30): This is necessary to prevent breaking the editing of text shadows.
  const textShadowIds = React.useRef<string[]>([]);
  const textShadows =
    draftComponentTextShadows.length > 0
      ? parseTextShadows(draftComponentTextShadows, textShadowIds)
      : [];

  const activeTextShadow = textShadows.find(
    (textShadow) => textShadow.id === activeShadowId,
  );

  const createOrUpdateTextShadow = (value: string) => {
    const componentId = selectDraftComponentId(store.getState());
    applyComponentAction({
      componentId,
      type: "setStyles",
      value: {
        textShadow: value,
      },
    });
  };

  const handleAddTextShadow = () => {
    let newId = textShadowIds.current[textShadows.length];
    // Note (Sebas, 2024-04-22): In case there is no id in the ref we
    // need to create a new one
    if (!newId) {
      newId = uuidv4();
      textShadowIds.current = [...textShadowIds.current, newId];
    }
    const newTextShadow = {
      id: newId,
      offsetX: "0px",
      offsetY: "4px",
      blur: "1px",
      color: "#00000040",
    };
    const newTextShadowsArray = [...textShadows, newTextShadow];
    const newTextShadows = getTextShadowString(newTextShadowsArray);
    setActiveShadowId(newTextShadow.id);
    setIsPopoverOpen(true);
    createOrUpdateTextShadow(newTextShadows);
  };

  const handleRemoveTextShadow = (id: string) => {
    // Note (Sebas, 2022-08-30): Remove id from ref
    textShadowIds.current = textShadowIds.current.filter((uuid) => uuid !== id);
    const filteredTextShadows = textShadows.filter(
      (textShadow) => textShadow.id !== id,
    );
    const updatedTextShadowes = getTextShadowString(filteredTextShadows);
    createOrUpdateTextShadow(updatedTextShadowes);
  };

  const handleEditTextShadowPopover = (textShadow: TextShadow) => {
    setActiveShadowId(textShadow.id);
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleTextShadowChange = (value: TextShadow) => {
    setActiveShadowId(value.id);
    const newTextShadows = textShadows.map((textShadow) => {
      return textShadow.id === value.id ? value : textShadow;
    });
    const updatedTextShadows = getTextShadowString(newTextShadows);
    createOrUpdateTextShadow(updatedTextShadows);
  };

  const currentTextShadowIndex = textShadows.findIndex(
    (textShadow) => textShadow.id === activeShadowId,
  );

  return (
    <ModifierGroup
      title="Text Shadow"
      endEnhancer={
        <MdAdd
          className="cursor-pointer"
          size={12}
          onClick={handleAddTextShadow}
        />
      }
      isDefaultOpen={textShadows.length > 0}
    >
      <div className="flex flex-col gap-1">
        {textShadows.length > 0 ? (
          textShadows.map((textShadow) => (
            <SelectionIndicator
              key={textShadow.id}
              title={formatTitle(textShadow)}
              onClick={() => handleEditTextShadowPopover(textShadow)}
              startEnhancer={
                <Badge
                  isFilled
                  backgroundColor="bg-blue-600"
                  className="h-[18px] w-[18px]"
                >
                  <BsSubtract size={10} className="text-white" />
                </Badge>
              }
              endEnhancer={
                <RiCloseFill
                  size={12}
                  className="cursor-pointer text-slate-400"
                  onClick={() => handleRemoveTextShadow(textShadow.id)}
                />
              }
            />
          ))
        ) : (
          <div
            className="mx-auto w-full cursor-pointer text-left text-xs text-gray-400"
            onClick={handleAddTextShadow}
          >
            <span>Click the + icon to add a text shadow.</span>
          </div>
        )}
        <TextShadowPopover
          isOpen={isPopoverOpen}
          setIsOpen={setIsPopoverOpen}
          activeTextShadow={activeTextShadow}
          handleTextShadowChange={handleTextShadowChange}
          textShadowIndex={currentTextShadowIndex}
        />
      </div>
    </ModifierGroup>
  );
};

const TextShadowPopover: React.FC<
  React.PropsWithChildren<{
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    activeTextShadow: TextShadow | undefined;
    handleTextShadowChange(value: TextShadow): void;
    textShadowIndex: number;
  }>
> = ({
  isOpen = false,
  setIsOpen,
  activeTextShadow,
  handleTextShadowChange,
  textShadowIndex,
}) => {
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const handleInputChange = (
    value: string,
    inputType: "offsetX" | "offsetY" | "blur" | "color",
  ) => {
    if (!activeTextShadow) {
      return;
    }

    const newTextShadow = {
      ...activeTextShadow,
      [inputType]: value,
    };
    handleTextShadowChange(newTextShadow);
  };

  return (
    <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
      <Popover.Content
        title="Text Shadow"
        shouldPreventDefaultOnInteractOutside={areModalsOpen}
      >
        <div className="grid columns-2 gap-2">
          <LengthInputSelector
            metrics={CSS_LENGTH_TYPES}
            className="col-span-1"
            startEnhancer={() => <span className="text-xs">X</span>}
            field="offsetX"
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            draggingType={DraggingTypes.Vertical}
            value={activeTextShadow?.offsetX || null}
            onChange={(value: string) => handleInputChange(value, "offsetX")}
            previewProperty="textShadow"
            previewSubProperty="offsetX"
            previewPropertyIndex={textShadowIndex}
            autofocus
          />
          <LengthInputSelector
            metrics={CSS_LENGTH_TYPES}
            className="col-span-1"
            startEnhancer={() => <span className="text-xs">Y</span>}
            field="offsetY"
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            draggingType={DraggingTypes.Vertical}
            value={activeTextShadow?.offsetY || null}
            onChange={(value: string) => handleInputChange(value, "offsetY")}
            previewProperty="textShadow"
            previewSubProperty="offsetY"
            previewPropertyIndex={textShadowIndex}
          />
          <div className="col-span-2 flex flex-col gap-2">
            <LabeledControl label="Blur" size="sm">
              <div className="flex gap-2">
                <LengthInputSelector
                  metrics={CSS_LENGTH_TYPES}
                  className="col-span-1"
                  startEnhancer={() => <TbDropletFilled />}
                  minDragValues={{ px: 0 }}
                  minValues={{ px: 0 }}
                  field="blur"
                  resetValue="0px"
                  anchorValue="0px"
                  placeholder="0px"
                  draggingType={DraggingTypes.Vertical}
                  value={activeTextShadow?.blur || null}
                  onChange={(value: string) => handleInputChange(value, "blur")}
                  previewProperty="textShadow"
                  previewSubProperty="blur"
                  previewPropertyIndex={textShadowIndex}
                />
                <Slider
                  value={getIntegerValueFromString(
                    activeTextShadow?.blur || null,
                  )}
                  debounce
                  minimum={0}
                  maximum={10}
                  step={0.5}
                  onChange={(value: number) =>
                    handleInputChange(`${value.toString()}px`, "blur")
                  }
                />
              </div>
            </LabeledControl>
            <LabeledControl label="Color" size="sm">
              <SolidColorSelector
                popoverTitle="Color"
                value={activeTextShadow?.color || ""}
                onChange={(value: string) => handleInputChange(value, "color")}
                defaultValue="#00000040"
              />
            </LabeledControl>
          </div>
        </div>
      </Popover.Content>
      <Popover.Anchor className="relative top-0 left-0" />
    </Popover>
  );
};

export default TextShadowModifierDeprecated;
