import type { AppRouterQueryArgs } from "@editor/utils/trpc";
import type { ExperimentStatus } from "schemas/experiment";
import type { Experiment } from "schemas/generated/experiment";
import type { ReploProject } from "schemas/generated/project";

import * as React from "react";

import { InfoTag } from "@editor/components/projectDashboard/common/InfoTag";
import { trpc } from "@editor/utils/trpc";

import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import { ExperimentStatuses } from "schemas/experiment";
import { v4 as uuid } from "uuid";

/**
 * Simple way to get create a default slug that is probably unique,
 * and reasonable to the user.
 */
export function positiveIntToCapitalLetter(i: number): string {
  return String.fromCharCode(96 + Math.min(Math.max(i, 1), 26));
}

export function getExperimentBaseUrl(project: ReploProject) {
  return `https://${project.customDomain ?? `reploedge.com/${project.slug}`}/`;
}

export const StatusTag = ({ status }: { status: ExperimentStatus }) => {
  const mapping = {
    [ExperimentStatuses.ACTIVE]: ["bg-lime-200", "Active"],
    [ExperimentStatuses.DRAFT]: ["bg-slate-200", "Draft"],
    [ExperimentStatuses.ARCHIVED]: ["bg-red-200", "Archived"],
    [ExperimentStatuses.COMPLETE]: ["bg-blue-200", "Completed"],
  };
  const [color, text] = mapping[status]!;
  return <InfoTag className={color}>{text}</InfoTag>;
};

export function buildCreateExperimentArgs(
  workspaceId: string,
  projectId: string,
  experiments: Array<Experiment>,
  url?: string | undefined | null,
): AppRouterQueryArgs["experiment"]["create"] {
  const newExperimentId = uuid();
  const placeholderUrl = url
    ? `https://${url}/pages/example`
    : "https://example.com";
  let slug = `experiment-${positiveIntToCapitalLetter(experiments?.length ?? 0 + 1)}`;
  if (experiments?.find((v) => v.slug === slug)) {
    slug = `experiment-${uuid().slice(0, 8)}`;
  }
  return {
    id: newExperimentId,
    projectId,
    workspaceId,
    name: slug,
    description: "",
    slug,
    variations: [
      {
        id: uuid(),
        target: placeholderUrl,
        slug: "a",
        allocationPercent: 50,
      },
      {
        id: uuid(),
        target: placeholderUrl,
        slug: "b",
        allocationPercent: 50,
      },
    ],
  };
}

/**
 * Provides all experiment API interactions as a single hook, allowing us to more easily refetch
 * the list and achieve "cache-like" behavior across uses of the query while avoiding indirection.
 *
 * @author Ben 2024-04-26
 */
export function useExperimentApi({
  workspaceId,
}: {
  workspaceId: string | undefined;
}) {
  /**
   * TODO (Max, 2024-10-10): Wait for Edgeserver to be updated (workspaceId-based), then
   * delete all the code that calls useExperimentApi by finding the workspaceId through the project
   * (we should use useCurrentWorkspaceId() instead of doing project?.ownerWorkspaceId).
   * https://linear.app/replo/issue/REPL-14020/edgeserver-kv-changes
   */
  const trpcUtils = trpc.useUtils();
  const list = trpc.experiment.list.useQuery(
    workspaceId
      ? {
          workspaceId,
        }
      : reactQuerySkipToken,
  );
  const onSuccess = async () => {
    void trpcUtils.experiment.list.invalidate({ workspaceId });
  };
  const create = trpc.experiment.create.useMutation({
    onSuccess,
  });
  const update = trpc.experiment.update.useMutation({
    onSuccess,
  });
  const remove = trpc.experiment.remove.useMutation({
    onSuccess,
  });
  return { list, update, remove, create };
}
