import type { ExperimentStatus } from "schemas/experiment";
import type { Experiment } from "schemas/generated/experiment";
import type { ReploProject } from "schemas/generated/project";
import type { Variation } from "schemas/generated/variation";

import * as React from "react";

import DeleteConfirmationModal from "@editor/components/common/DeleteConfirmationModal";
import Banner from "@editor/components/common/designSystem/Banner";
import Button from "@editor/components/common/designSystem/Button";
import IconButton from "@editor/components/common/designSystem/IconButton";
import Modal from "@editor/components/common/designSystem/Modal";
import {
  RadioGroup,
  RadioGroupItem,
} from "@editor/components/common/designSystem/RadioGroup";
import { successToast } from "@editor/components/common/designSystem/Toast";
import { Loader } from "@editor/components/common/Loader";
import { BetaTag } from "@editor/components/projectDashboard/common/BetaTag";
import { InfoTag } from "@editor/components/projectDashboard/common/InfoTag";
import {
  getExperimentBaseUrl,
  StatusTag,
  useExperimentApi,
} from "@editor/components/projectDashboard/experiments/common";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { docs } from "@editor/utils/docs";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import classNames from "classnames";
import copy from "copy-to-clipboard";
import { format } from "date-fns";
import { BiCopy } from "react-icons/bi";
import { BsBoxArrowUpRight, BsChevronRight } from "react-icons/bs";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import { BillingTiers } from "schemas/billing";
import { ExperimentStatuses, getExperimentStatus } from "schemas/experiment";

export const ExperimentDetailsTab = () => {
  const { experimentId } = useParams();
  const { project, isLoading: isLoadingProject } = useCurrentProjectContext();
  const workspaceId = project?.ownerWorkspaceId;
  const {
    list: { data, isFetching: isFetchingExperiments },
  } = useExperimentApi({ workspaceId });
  const experiment = data?.experiments.find((exp) => exp.id === experimentId);

  if (isLoadingProject || isFetchingExperiments || !experiment || !project) {
    return <Loader />;
  }
  return (
    <ExperimentDetailsComponent project={project} experiment={experiment} />
  );
};

const ExperimentDetailsComponent: React.FC<{
  project: ReploProject;
  experiment: Experiment;
}> = (props) => {
  const { project } = props;
  const [experiment, setExperiment] = React.useState(props.experiment);
  const projectId = project.id;
  const workspaceId = project.ownerWorkspaceId;
  const experimentId = experiment.id;
  const { createdAt, activatedAt, archivedAt, completedAt } = experiment;
  const logEvent = useLogAnalytics();
  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;
  const navigate = useNavigate();
  const experimentBaseUrl = getExperimentBaseUrl(project);
  const fullExperimentURL = `${experimentBaseUrl}${experiment.slug}`;
  const status = getExperimentStatus(experiment);
  const noDescription =
    experiment.description === "" || experiment.description === null;
  const createDateHuman = format(createdAt, "MMMM d, hh:mma");
  const activateDateHuman =
    activatedAt && status !== ExperimentStatuses.DRAFT
      ? format(activatedAt, "MMMM d, hh:mma")
      : null;
  const doneDateHuman =
    status === ExperimentStatuses.ARCHIVED ||
    status === ExperimentStatuses.COMPLETE
      ? format((completedAt ?? archivedAt)!, "MMMM d, hh:mma")
      : null;
  const allowEdit =
    status === ExperimentStatuses.ACTIVE || status === ExperimentStatuses.DRAFT;
  const allowResetToDraft =
    status === ExperimentStatuses.ACTIVE ||
    status === ExperimentStatuses.COMPLETE;
  const allowActivate = status === ExperimentStatuses.DRAFT;
  const allowComplete = status === ExperimentStatuses.ACTIVE;
  const allowArchive = status !== ExperimentStatuses.ARCHIVED;
  const allowUnarchive = status === ExperimentStatuses.ARCHIVED;
  const { data: planAllowsActivation } = trpc.experiment.canActivate.useQuery({
    workspaceId,
  });

  const { update: updateExperiment, remove: removeExperiment } =
    useExperimentApi({ workspaceId });
  const update = updateExperiment.mutateAsync;
  const isUpdating = updateExperiment.isPending;
  const remove = removeExperiment.mutateAsync;
  const isRemoving = removeExperiment.isPending;
  const [isCompletionModalOpen, setCompletionModalOpen] = React.useState(false);
  const disableControls = isRemoving || isUpdating;
  const [isDeletionConfirmationOpen, setDeletionConfirmationOpen] =
    React.useState(false);
  const [isArchiveConfirmationOpen, setArchiveConfirmationOpen] =
    React.useState(false);

  const modal = useModal();
  const openBillingModal = () =>
    modal.openModal({
      type: "billingModal",
      props: {
        source: "activate.experiments",
        billingPlanMessageKey: "billingPlan.experimentActivationAttempt",
      },
    });
  /**
   * Update the experiment with a non-"complete" status, as all non-complete
   * status disallow a winning variation.
   */
  const updateWithStatus = async (status: ExperimentStatus) => {
    const updatedExperiment = await update({
      ...experiment,
      status,
      variations: experiment.variations.map((v) => ({ ...v, isWinner: false })),
    });
    setExperiment(updatedExperiment);
    logEvent("experiment.updated", { billingPlanTier: subscriptionTier });
  };
  const onActivate = async () => {
    if (planAllowsActivation) {
      await updateWithStatus(ExperimentStatuses.ACTIVE);
      successToast("Experiment Activated", "");
      logEvent("experiment.activated", { billingPlanTier: subscriptionTier });
    } else {
      openBillingModal();
    }
  };
  const onComplete = () => setCompletionModalOpen(true);
  const onCompleteCancel = () => setCompletionModalOpen(false);
  const onCompleteConfirm = async (winnerId: string) => {
    await update({
      ...experiment,
      status: ExperimentStatuses.COMPLETE,
      variations: experiment.variations.map((variation) => ({
        ...variation,
        isWinner: variation.id === winnerId,
      })),
    });
    logEvent("experiment.completed", { billingPlanTier: subscriptionTier });
    successToast("Experiment Completed 🥳", "");
  };
  const onResetToDraft = async () => {
    await updateWithStatus(ExperimentStatuses.DRAFT);
    logEvent("experiment.reset", { billingPlanTier: subscriptionTier });
    successToast(
      "Experiment Updated",
      "Experiment reset to 'Draft' successfully! 🎉 ",
    );
  };
  const onArchiveButtonClick = () => setArchiveConfirmationOpen(true);
  const onArchiveConfirmation = async () => {
    await updateWithStatus(ExperimentStatuses.ARCHIVED);
    logEvent("experiment.archived", { billingPlanTier: subscriptionTier });
    successToast("Experiment Archived", "");
  };
  const onArchiveCancel = () => setArchiveConfirmationOpen(false);
  const onUnarchive = async () => {
    await updateWithStatus(ExperimentStatuses.DRAFT);
    logEvent("experiment.unarchived", { billingPlanTier: subscriptionTier });
    successToast("Experiment Unarchived", "");
  };
  const onDeleteButtonClick = () => setDeletionConfirmationOpen(true);
  const onDeleteConfirmation = async () => {
    await remove({
      experimentId,
      workspaceId,
    });
    logEvent("experiment.deleted", { billingPlanTier: subscriptionTier });
    successToast("Experiment Deleted", "");
    navigate(generatePath(routes.editor.experiments.list, { projectId }));
  };
  const onDeleteCancel = () => setDeletionConfirmationOpen(false);

  return (
    <div className="space-y-6 pb-32">
      {isDeletionConfirmationOpen && (
        <DeleteConfirmationModal
          assetName={experiment.name}
          assetTypeDisplayName="Experiment"
          confirmationType="delete"
          extraMessage={
            <span className="font-medium">
              It will no longer serve traffic.
            </span>
          }
          onDelete={() => void onDeleteConfirmation()}
          onRequestClose={onDeleteCancel}
        />
      )}
      <ArchiveConfirmationModal
        name={experiment.name}
        isOpen={isArchiveConfirmationOpen}
        onConfirm={() => void onArchiveConfirmation()}
        onCancel={onArchiveCancel}
      />
      <CompleteModal
        variations={experiment.variations}
        onCancel={onCompleteCancel}
        onConfirm={(id) => void onCompleteConfirm(id)}
        isOpen={isCompletionModalOpen}
      />
      <div className="flex justify-between items-center content-center gap-x-3 text-sm max-w-full">
        <div className="flex-shrink-0 flex-none flex items-center content-center justify-between space-x-3">
          <Link
            className="font-semibold text-blue-600"
            to={generatePath(routes.editor.experiments.list, {
              projectId,
            })}
          >
            Experiments
          </Link>
          <BetaTag />
          <span>
            <BsChevronRight size={18} />
          </span>
        </div>
        <div className="flex-grow truncate">{experiment.name}</div>
        <div className="flex-none flex items-center content-center justify-between gap-x-3">
          <Button
            type="secondaryDanger"
            size="base"
            onClick={() => void onDeleteButtonClick()}
            isDisabled={disableControls}
          >
            Delete
          </Button>
          {allowArchive && (
            <Button
              type="tertiary"
              size="base"
              onClick={onArchiveButtonClick}
              isDisabled={disableControls}
            >
              Archive
            </Button>
          )}
          {allowUnarchive && (
            <Button
              type="primary"
              size="base"
              onClick={() => void onUnarchive()}
              isDisabled={disableControls}
            >
              Unarchive
            </Button>
          )}
          {allowEdit && (
            <Button
              type="primary"
              size="base"
              onClick={() =>
                navigate(
                  generatePath(routes.editor.experiments.edit, {
                    projectId,
                    experimentId,
                  }),
                )
              }
              isDisabled={disableControls}
            >
              Edit
            </Button>
          )}
          {allowResetToDraft && (
            <Button
              type="primary"
              size="base"
              onClick={() => void onResetToDraft()}
              isDisabled={disableControls}
            >
              Reset to Draft
            </Button>
          )}
          {allowActivate && (
            <Button
              type="primary"
              size="base"
              tooltipText={
                planAllowsActivation
                  ? undefined
                  : `A-B Testing is available only to users on the Growth plan. Please your plan to activate your experiment.`
              }
              onClick={() => void onActivate()}
              isDisabled={disableControls || !planAllowsActivation}
            >
              Activate
            </Button>
          )}
          {allowComplete && (
            <Button
              type="primary"
              size="base"
              onClick={() => void onComplete()}
              isLoading={disableControls}
            >
              Complete
            </Button>
          )}
        </div>
      </div>
      <div className="flex flex-row items-center content-center justify-start space-x-4">
        <div className="truncate text-2xl font-medium">{experiment.name}</div>
        <div className="text-base flex-shrink-0">
          <StatusTag status={status} />
        </div>
        <div className="text-base flex-grow"></div>
        {!planAllowsActivation && (
          <div className="text-sm flex-shrink-0 h-full min-w-lg w-content">
            <Banner
              isDismissable={false}
              backgroundColor="bg-gray-200"
              className="py-2 px-3 rounded"
            >
              <div className="min-h-full h-full">
                A-B Testing is available only to users on the Growth plan.{" "}
                <span
                  className="hover:underline cursor-pointer text-blue-600"
                  onClick={openBillingModal}
                >
                  Upgrade your plan
                </span>{" "}
                to activate your experiment.
              </div>
            </Banner>
          </div>
        )}
      </div>
      <div className="space-y-6 text-sm">
        <div
          className={classNames("flex flex-col w-full max-w-3xl", {
            "italic text-slate-500": noDescription,
          })}
        >
          <div>{noDescription ? "No description" : experiment.description}</div>
        </div>
        <div className="p-4 mt-2 rounded border border-slate-300 grid grid-cols-5 space-x-4">
          <div className="col-span-1">
            <div className="text-slate-500 font-medium uppercase">
              Created At
            </div>
            <div>{createDateHuman}</div>
          </div>
          <div className="col-span-1">
            <div className="text-slate-500 font-medium uppercase">
              Activated At
            </div>
            <div>{activateDateHuman ?? "-"}</div>
          </div>
          <div className="col-span-1">
            {doneDateHuman && (
              <>
                <div className="text-slate-500 font-medium uppercase">
                  {archivedAt && "Archived At"}
                  {completedAt && "Completed At"}
                </div>
                <div>{doneDateHuman ?? "-"}</div>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col w-full max-w-3xl">
          <div className="font-semibold">Experiment URL</div>
          <div className="mt-2 flex flex-row items-center content-center justify-start">
            <a
              className="text-blue-600 max-w-fit flex flex-row items-center content-center justify-start"
              href={fullExperimentURL}
              rel="noreferrer"
              target="_blank"
            >
              {fullExperimentURL}
              <span className="ml-2">
                <BsBoxArrowUpRight />
              </span>
            </a>
            <div className="ml-3">
              <IconButton
                tooltipText="Copy Experiment URL"
                type="tertiary"
                className="h-7 w-7"
                icon={<BiCopy size={16} />}
                onClick={() => {
                  copy(fullExperimentURL);
                  successToast("Experiment URL Copied", "");
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="font-semibold">Variations</div>
          <div className="text-slate-400">
            Unique variations name your experiment groups.
          </div>
          <div className="p-4 mt-2 rounded border border-slate-300">
            <div className="grid grid-cols-12 gap-4 h-8 items-center content-center font-semibold">
              <div className="col-span-2">Variation Name</div>
              <div className="col-span-8">Redirect To URL</div>
              <div className="col-span-2">Percent</div>
            </div>
            <div className="space-y-1">
              {experiment.variations.map((variation) => (
                <VariationEntry key={variation.id} variation={variation} />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="font-semibold">Experiment Results</div>
          <p className="max-w-xl mt-1 text-slate-400">
            Experimental results are pushed into your existing analytic
            platforms via appended{" "}
            <span className="font-semibold">replo-experiment</span> and{" "}
            <span className="font-semibold">replo-variation</span> parameters.
            See our{" "}
            <a
              className="hover:underline text-blue-600"
              href={docs.abTesting.analytics}
            >
              docs
            </a>{" "}
            for more info.
          </p>
        </div>
      </div>
    </div>
  );
};

type ArchiveConfirmationModalProps = {
  name: string;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const ArchiveConfirmationModal: React.FC<ArchiveConfirmationModalProps> = ({
  name,
  isOpen,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal isOpen={isOpen} className="max-w-xl" onRequestClose={onCancel}>
      <div className="flex flex-col items-start rounded-lg bg-white p-3 text-default space-y-3">
        <h1 className="text-lg font-medium text-default">
          Are you sure you want to archive this Experiment?
        </h1>
        <div className="max-w-full">
          <p className="text-sm text-slate-400">Experiment Name:</p>
          <div className="text-base text-default my-1 px-2 py-1 rounded bg-slate-100">
            <div className="truncate">{name}</div>
          </div>
        </div>
        <div className="text-sm">
          <div className="text-slate-400 mb-2">
            An archived Experiment will still exist for this project, but will
            no longer serve traffic on its URL. You can always unarchive this
            Experiment later.
          </div>
        </div>
        <div className="mt-20 flex flex-row items-center content-center flex-grow w-full justify-end">
          <Button
            type="secondaryDanger"
            size="lg"
            className="mt-4"
            htmlType="button"
            onClick={onConfirm}
          >
            Archive Experiment
          </Button>
        </div>
      </div>
    </Modal>
  );
};

type CompleteModalProps = {
  variations: Array<Variation>;
  isOpen: boolean;
  onConfirm: (id: string) => void;
  onCancel: () => void;
};

const CompleteModal: React.FC<CompleteModalProps> = ({
  variations,
  isOpen,
  onConfirm,
  onCancel,
}) => {
  const [selection, setSelection] = React.useState(variations[0]!.id);
  return (
    <Modal isOpen={isOpen} className="max-w-4xl" onRequestClose={onCancel}>
      <div className="flex flex-col items-stretch justify-between rounded-lg bg-white p-3 text-default space-y-3 w-full">
        <h1 className="text-lg font-medium text-default">
          Choose a winning variation to complete this experiment
        </h1>
        <p>
          The experiment URL will direct all traffic to the winning variation.
        </p>
        <div className="flex flex-row">
          <div className="text-sm grow">
            <div className="p-4 mt-2 rounded border border-slate-300">
              <div className="grid grid-cols-12 gap-4 h-8 items-center content-center font-semibold">
                <div className="col-span-1"></div>
                <div className="col-span-2">Variation</div>
                <div className="col-span-7">URL</div>
                <div className="col-span-2">Percent</div>
              </div>
              <div className="space-y-1">
                <RadioGroup onValueChange={setSelection}>
                  {variations.map((variation) => (
                    <SelectableVariation
                      key={variation.id}
                      variation={variation}
                      isSelected={selection === variation.id}
                    />
                  ))}
                </RadioGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-20 flex flex-row items-center content-center flex-grow w-full justify-between">
          <Button
            type="tertiary"
            size="lg"
            className="mt-4"
            htmlType="button"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="lg"
            className="mt-4"
            htmlType="button"
            onClick={() => onConfirm(selection)}
          >
            Complete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

type SelectableVariationProps = {
  variation: Variation;
  isSelected: boolean;
};

const SelectableVariation: React.FC<SelectableVariationProps> = ({
  variation: { id, slug, target, allocationPercent },
  isSelected,
}) => {
  return (
    <div className="grid grid-cols-12 gap-4 h-8 items-center content-center">
      <div className="col-span-1">
        <RadioGroupItem isSelected={isSelected} value={id} />
      </div>
      <div className="col-span-2">
        <div className="rounded py-1 w-full">{slug}</div>
      </div>
      <div className="col-span-7 flex items-center content-center">
        <div className="rounded py-1 w-full truncate">{target}</div>
        <IconButton
          tooltipText="Copy variation URL"
          type="tertiary"
          className="h-7 w-7"
          icon={<BiCopy size={16} />}
          onClick={() => {
            copy(target);
            successToast(
              "URL Copied",
              "Variation target URL copied successfully! 🎉 ",
            );
          }}
        />
      </div>
      <div className="col-span-2">
        <div className="rounded py-1 w-full">{allocationPercent}%</div>
      </div>
    </div>
  );
};

type VariationEntryProps = {
  variation: Variation;
};

const VariationEntry: React.FC<VariationEntryProps> = ({
  variation: { slug, target, allocationPercent, isWinner },
}) => {
  return (
    <div className="grid grid-cols-12 gap-4 h-8 items-center content-center">
      <div className="col-span-2">
        <div className="mt-2 py-1 w-full flex items-center content-center justify-between gap-x-3">
          <div className="truncate">{slug}</div>
          {isWinner && <InfoTag className="bg-green-300">Winner</InfoTag>}
        </div>
      </div>
      <div className="col-span-8 flex items-center content-center">
        <div className="rounded mt-2 py-1 w-full truncate">{target}</div>
        <IconButton
          tooltipText="Copy variation URL"
          type="tertiary"
          className="h-7 w-7"
          icon={<BiCopy size={16} />}
          onClick={() => {
            copy(target);
            successToast(
              "URL Copied",
              "Variation target URL copied successfully! 🎉 ",
            );
          }}
        />
      </div>
      <div className="col-span-2">
        <div className="rounded mt-2 py-1 w-full">{allocationPercent}%</div>
      </div>
    </div>
  );
};
