import type { AssetLibraryModalProps } from "@editor/components/AppModalTypes";
import type { Asset } from "@editor/types/component-template";

import * as React from "react";

import { ModalLayout } from "@common/ModalLayout";
import Button from "@editor/components/common/designSystem/Button";
import InputComponent from "@editor/components/common/designSystem/Input";
import Modal from "@editor/components/common/designSystem/Modal";
import { ConnectShopifyCallout } from "@editor/components/editor/page/ConnectShopifyCallout";
import DropZone from "@editor/components/editor/page/Dropzone";
import { InfiniteLoadingGrid } from "@editor/components/InfiniteLoadingGrid";
import { Loader } from "@editor/components/marketplace/Loader";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useModal } from "@editor/hooks/useModal";
import {
  selectIsShopifyIntegrationEnabled,
  selectStore,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import { BsArrowRight, BsSearch } from "react-icons/bs";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import { isValidHttpUrl } from "replo-utils/lib/url";
import { twMerge } from "tailwind-merge";

const assetSourceToEditorData = {
  shopify: {
    displayName: "From Theme",
  },
  url: {
    displayName: "From URL",
  },
  files: {
    displayName: "From Files",
  },
};

type AssetSource = keyof typeof assetSourceToEditorData;
export type SourceType = "theme" | "files";
const assetSourceMenuItems: AssetSource[] = ["files", "url", "shopify"];

export const AssetLibraryModal: React.FC<AssetLibraryModalProps> = (props) => {
  const [assetSource, setAssetSource] = React.useState<AssetSource>("files");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedAsset, setSelectedAsset] = React.useState<string | null>(
    props.value,
  );
  const applyComponentAction = useApplyComponentAction();
  const modal = useModal();
  const editorData = getReferrerEditorData(props.referrer ?? null);
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );

  const onPaste = (url: string) => {
    setAssetSource("url");
    setSelectedAsset(url);
  };

  const onSubmit = () => {
    if (props.referrer == "modifier/customProp") {
      applyComponentAction({
        type: "setProps",
        value: {
          [`${props.referrerData.customPropId}`]: {
            src: selectedAsset,
          },
        },
      });
    }
    if (props.referrer == "modifier/image") {
      props.onChange(selectedAsset!);
    }
    if (props.referrer == "modifier/background") {
      applyComponentAction({
        type: "setStyles",
        value: {
          // Note (Sebas, 2022-10-12): We need to use double quotes to prevent
          // data:image sources which may contain simple quotes from not working.
          backgroundImage: `url("${selectedAsset}")`,
          backgroundSize: "cover",
        },
      });
    }
    if (props.referrer == "modifier/video") {
      applyComponentAction({
        type: "setProps",
        value: { url: selectedAsset },
      });
    }
    if (props.referrer === "modifier/videoPoster") {
      applyComponentAction({
        type: "setProps",
        value: { poster: selectedAsset },
      });
    }
    modal.closeModal({ type: "assetLibraryModal" });
  };

  const isSelectedAssetImage =
    props.assetContentType === "image" && Boolean(selectedAsset);

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => modal.closeModal({ type: "assetLibraryModal" })}
      className="w-auto"
      includesCloseIcon
      onEnterKeyPress={() => {
        if (selectedAsset) {
          onSubmit();
        }
      }}
    >
      <ModalLayout
        height={600}
        // Note (Noah, 2023-12-23): Set overflow to visible so the input focus ring shows
        // up correctly. The default is scroll, but the asset grid handles scrolling internally
        // so we don't need that
        mainContentClassnames="overflow-visible"
        mainContent={() => {
          return (
            <div className="flex h-full w-full flex-col gap-1">
              <div className="flex flex-col gap-2">
                <p className="self-center text-xl font-medium">Asset Library</p>
                <p className="self-center text-sm text-slate-400">
                  Images and assets from your Shopify store
                </p>

                <div className="flex gap-4 pt-8 text-base">
                  <MenuItems
                    assetSource={assetSource}
                    setAssetSource={setAssetSource}
                  />
                </div>
              </div>
              <div className="flex flex-grow flex-col">
                {assetSource === "url" && (
                  <>
                    <div className="w-1/3">
                      <InputComponent
                        value={selectedAsset ?? undefined}
                        onChange={(e) => setSelectedAsset(e.target.value)}
                        startEnhancer={() => <BsSearch size={12} />}
                        autoFocus={true}
                        placeholder="URL"
                      />
                    </div>
                    <div className="flex h-full items-center justify-center">
                      {isSelectedAssetImage && (
                        <img
                          src={selectedAsset!}
                          style={{ maxHeight: "300px", objectFit: "cover" }}
                        />
                      )}
                      {!isSelectedAssetImage && (
                        <p className="text-xs text-slate-400">
                          Add URL to see a preview
                        </p>
                      )}
                    </div>
                  </>
                )}
                {assetSource !== "url" && (
                  <>
                    {isShopifyIntegrationEnabled && (
                      <div className="w-1/3">
                        <InputComponent
                          autoFocus={true}
                          placeholder="Search Assets"
                          startEnhancer={() => <BsSearch size={12} />}
                          value={searchTerm}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (isValidHttpUrl(value)) {
                              onPaste(value);
                            } else {
                              setSearchTerm(value);
                            }
                          }}
                        />
                      </div>
                    )}
                    {isShopifyIntegrationEnabled ? (
                      <ExistingAssets
                        // in order to reset the collection of items on scrolling
                        // we must use a key to force a re-render when assetSource
                        // changes.
                        key={assetSource}
                        sourceType={
                          assetSource === "shopify" ? "theme" : "files"
                        }
                        editorData={editorData}
                        onSubmit={onSubmit}
                        props={props}
                        searchTerm={searchTerm}
                        selectedAsset={selectedAsset}
                        setSelectedAsset={setSelectedAsset}
                      />
                    ) : (
                      <ConnectShopifyCallout
                        type="assets"
                        className="self-center grow justify-center max-w-80"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          );
        }}
        footerContent={() => {
          return (
            (isShopifyIntegrationEnabled || assetSource === "url") &&
            editorData.canSubmit &&
            editorData.submitText && (
              <div className="flex w-full flex-row justify-end">
                <Button
                  onClick={onSubmit}
                  isDisabled={selectedAsset ? false : true}
                  type="primary"
                  size="base"
                >
                  {editorData.submitText(selectedAsset)}
                </Button>
              </div>
            )
          );
        }}
      />
    </Modal>
  );
};

const MenuItems: React.FC<{
  assetSource: AssetSource;
  setAssetSource: React.Dispatch<React.SetStateAction<AssetSource>>;
}> = ({ assetSource, setAssetSource }) => {
  const items = assetSourceMenuItems.map((t) => {
    const isActive = t === assetSource;
    const editorData = assetSourceToEditorData[t];
    const activeClassName = classNames({
      "text-default": isActive,
      "text-gray-300": !isActive,
    });
    return (
      <div
        key={t}
        onClick={() => {
          if (!isActive) {
            setAssetSource(t);
            // Note (Noah, 2022-04-20, REPL-1856): Don't reset the selected asset here or anything,
            // since we want the current asset to stay selected if the user switches tabs.
          }
        }}
        className={`cursor-pointer text-base font-medium transition-colors ${activeClassName}`}
      >
        {editorData.displayName}
      </div>
    );
  });

  return <>{items}</>;
};

const ExistingAssets: React.FC<{
  props: AssetLibraryModalProps;
  sourceType: SourceType;
  editorData: Record<string, any>;
  searchTerm: string;
  selectedAsset: string | null;
  setSelectedAsset: React.Dispatch<React.SetStateAction<string | null>>;
  onSubmit(): void;
}> = ({
  props: { assetContentType },
  editorData,
  searchTerm,
  selectedAsset,
  setSelectedAsset,
  onSubmit,
  sourceType,
}) => {
  const projectId = useCurrentProjectId();
  const store = useEditorSelector(selectStore);
  const [assetsById, setAssetsById] = React.useState<Record<string, Asset>>({});
  const doesNotHaveReadFilesAccess =
    sourceType === "files" &&
    // Note (Noah, 2022-09-12, REPL-4086): If we don't have the read_files permission
    // then the server will not be able to return file assets, so there's no point in
    // requesting them
    !store?.shopifyAccessScopes?.includes("read_files");

  const {
    data,
    isLoading: findAssetsIsLoading,
    isFetching: findAssetsIsFetching,
    refetch,
    fetchNextPage,
  } = trpc.shopify.libraryAssets.find.useInfiniteQuery(
    !projectId || doesNotHaveReadFilesAccess
      ? skipToken
      : {
          projectId,
          contentType: assetContentType,
          sourceType,
          pageSize: 15,
        },
    {
      getNextPageParam: ({ pageInfo }) =>
        pageInfo?.hasNextPage ? pageInfo.nextPage : undefined,
    },
  );

  const assetsFromServer = React.useMemo(() => {
    return data?.pages.map((group) => group.assets).flat();
  }, [data]);

  const pagesLength = data?.pages.length ?? 0;
  const pageInfo = data?.pages[pagesLength ? pagesLength - 1 : 0]?.pageInfo;

  const loadMoreItems = () => {
    if (!pageInfo?.hasNextPage || !pageInfo?.nextPage) {
      return;
    }
    void fetchNextPage();
  };

  React.useEffect(() => {
    if (assetsFromServer) {
      setAssetsById(() => {
        const assets: Record<string, Asset> = {};
        assetsFromServer.forEach((asset: Asset) => {
          assets[asset.id] = asset;
        });
        return assets;
      });
    }
  }, [assetsFromServer]);

  // Note (gabe, 2023-05-09): we could move this query to the server if we run
  // into performance issues doing this filtering clientside.
  const filteredAssets = (Object.values(assetsById) || []).filter(
    (asset: Asset) =>
      searchTerm === "" ||
      asset.publicUrl.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const acceptDropAssetType = getAcceptDropAssetType(
    assetContentType,
    sourceType,
  );
  const loaderLabel = getLoaderLabelFromAssetType(acceptDropAssetType);

  if (findAssetsIsLoading) {
    return (
      <div className="grid h-full w-full">
        <Loader
          label={loaderLabel}
          className="col-span-3 flex w-full flex-col items-center gap-2 pt-8 pb-4 text-blue-600"
        />
      </div>
    );
  }

  if (
    sourceType === "files" &&
    store &&
    (!store.shopifyAccessScopes?.includes("read_files") ||
      !store.shopifyAccessScopes?.includes("write_files"))
  ) {
    return (
      <div
        className="flex h-full w-full flex-col items-center justify-center gap-2"
        onClick={() => {
          window.open(`https://${store.shopifyUrl}/admin`);
        }}
      >
        <div className="flex cursor-pointer flex-row items-center justify-items-center gap-x-2 text-sm">
          <p className="text-blue-600">
            Re-authorization of the Replo Shopify app is required to upload to
            Files.
          </p>
          <BsArrowRight size={16} className="text-blue-600" />
        </div>
        <p className="text-xs text-gray-400">
          Click the link above to open in Shopify Admin, then select Replo from
          Apps.
        </p>
      </div>
    );
  }

  const scrollableTargetId = "asset-library-modal-scrollable-target";

  // TODO (Fran 2024-03-26): Move the search to a query because we are filtering only on the assets
  // that were already fetched. If we have assets and the user doesn't scroll to the end, the search
  // will not working for the rest of the assets.
  // REPL-9338 (https://linear.app/replo/issue/REPL-9338/image-search-only-works-for-images-that-have-been-loaded)
  const hasNextPage = searchTerm.length === 0 && pageInfo?.hasNextPage;
  return (
    // Note (Gabe, 5/15/23): for whatever reason tailwind uses flex: 1 1 0% for
    // the flex-1 class which doesn't appropriately limit the size of the
    // scrollable area so we must use the style prop here.
    <div id={scrollableTargetId} style={{ flex: "1 1 0", overflow: "scroll" }}>
      <InfiniteLoadingGrid
        listLength={filteredAssets.length + (pageInfo?.hasNextPage ? 15 : 0)}
        loadMoreItems={loadMoreItems}
        hasNextPage={hasNextPage ?? false}
        scrollableTargetId={scrollableTargetId}
        className="no-scrollbar overflow-y-auto"
        loaderLabel={loaderLabel}
      >
        <div
          className="mt-4 grid auto-rows-auto grid-cols-4 gap-x-4 gap-y-6"
          style={{ minHeight: 128 }}
        >
          {(sourceType === "theme" || sourceType === "files") && (
            <DropZone
              acceptDropAssetType={acceptDropAssetType}
              assetContentType={assetContentType}
              forceLoading={findAssetsIsFetching}
              onUploadComplete={() => {
                void refetch();
              }}
              projectId={projectId}
              sourceType={sourceType}
            />
          )}

          {filteredAssets.map((asset: Asset) => {
            const canSelect = editorData.canSubmit;
            const assetClassName = canSelect ? "cursor-pointer" : "";

            const isSelected = selectedAsset === asset.publicUrl;
            const imageClassName = isSelected ? "border-blue-500 border-2" : "";
            const assetUrlComponents = new URL(asset.publicUrl).pathname.split(
              "/",
            );
            const assetName = assetUrlComponents[assetUrlComponents.length - 1];
            return (
              <div
                key={asset.publicUrl}
                className={classNames(
                  "flex flex-col items-center justify-center",
                  assetClassName,
                )}
                onClick={(e) => {
                  if (!canSelect) {
                    return;
                  }
                  setSelectedAsset(asset.publicUrl);
                  if (e.detail === 2) {
                    onSubmit();
                  }
                }}
              >
                {assetContentType === "video" && (
                  <>
                    <div
                      className={twMerge(
                        classNames(
                          "flex flex-col h-32 w-full border-2 border-white transition-all duration-100 ease-in-out",
                          imageClassName,
                        ),
                      )}
                    >
                      <video
                        className={classNames(
                          "grow min-h-0 basis-0 w-full object-cover transition-all duration-150 ease-in-out",
                        )}
                        muted
                      >
                        <source src={asset.publicUrl} />
                      </video>
                      <div className="mt-1 h-auto w-full truncate text-left text-xs text-slate-400">
                        {assetName}
                      </div>
                    </div>
                  </>
                )}
                {assetContentType === "image" && (
                  <>
                    <div
                      className={twMerge(
                        classNames(
                          "h-24 w-full border-2 border-white hover:shadow-symmetrical transition-all duration-100 ease-in-out",
                          imageClassName,
                        ),
                      )}
                    >
                      <img
                        src={asset.publicUrl}
                        className={classNames(
                          "h-full w-full object-contain transition-all duration-150 ease-in-out",
                        )}
                      />
                    </div>
                    <div className="mt-1 h-auto w-full truncate text-left text-xs text-slate-400">
                      {assetName}
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </InfiniteLoadingGrid>
    </div>
  );
};

function getAcceptDropAssetType(
  assetContentType: "image" | "video",
  sourceType: SourceType,
) {
  switch (assetContentType) {
    case "image":
      return "image/*";
    case "video":
      return sourceType === "files"
        ? ["video/mp4", "video/quicktime"]
        : "video/*";
    default:
      return "*";
  }
}

const truncateText = (text: string | null, maxLength: number) => {
  let result = text;
  if (result && result.length > maxLength) {
    result = `...${result.slice(result.length - maxLength)}`;
  }
  return result;
};

const assetNameDisplay = (assetName: string | null) => {
  if (!assetName) {
    return "Update";
  }
  const components = assetName.split("/");
  const lastComponent = components[components.length - 1]!;
  return `Use ${truncateText(lastComponent, 40)}`;
};

const assetLibraryReferrerToEditorData: Record<
  string,
  { submitText: (assetName?: string) => string; canSubmit: boolean }
> = {
  "modifier/customProp": {
    submitText: (assetName) => {
      return assetNameDisplay(assetName ?? null);
    },
    canSubmit: true,
  },
  "modifier/image": {
    submitText: () => {
      return "Insert Image";
    },
    canSubmit: true,
  },
  "modifier/videoPoster": {
    submitText: () => {
      return "Set Poster Image";
    },
    canSubmit: true,
  },
  "modifier/background": {
    submitText: () => {
      return "Insert Image";
    },
    canSubmit: true,
  },
  "modifier/video": {
    submitText: () => {
      return "Insert Video";
    },
    canSubmit: true,
  },
  sidebar: {
    submitText: (assetName) => {
      return assetNameDisplay(assetName ?? null);
    },
    canSubmit: false,
  },
};

const getReferrerEditorData = (referrer: string | null) => {
  const defaultEditorData: Record<string, any> = {
    canSubmit: false,
  };
  return (
    getFromRecordOrNull(assetLibraryReferrerToEditorData, referrer) ??
    defaultEditorData
  );
};

const getLoaderLabelFromAssetType = (
  assetContentType: "image/*" | "video/*" | "*" | string[],
) => {
  if (assetContentType === "image/*") {
    return "Beep Boop, Loading Images";
  } else if (assetContentType === "video/*") {
    return "Beep Boop, Loading Videos";
  }

  return "Beep Boop, Loading Assets";
};

export default AssetLibraryModal;
