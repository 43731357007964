import "./index.scss";

import * as React from "react";

import AuthPage from "@components/account/AuthPage";
import AccountDashboard from "@components/account/Dashboard";
import ProfileBoard from "@components/account/Dashboard/ProfileBoard";
import SecurityBoard from "@components/account/Dashboard/SecurityBoard";
import InitResetPassword from "@components/account/InitResetPassword";
import LoginForm from "@components/account/LoginForm";
import ResetPassord from "@components/account/ResetPasswordForm";
import SignupForm from "@components/account/SignupForm";
import { FullScreenLoader } from "@components/common/FullScreenLoader";
import { setLibraryTemplateDataToLocalStorage } from "@components/common/utils";
import ShopifyIntegrationSettings from "@components/dashboard/integrations/ShopifyIntegrationSettings";
import WorkspaceIntegrations from "@components/dashboard/integrations/WorkspaceIntegrations";
import { PartnerProgramLPContent } from "@components/dashboard/partner/partner-program-v2-lp-components/PartnerProgramLP";
import { PartnershipReferralBoard } from "@components/dashboard/partner/PartnershipReferralBoard";
import PartnershipDashboard from "@components/dashboard/PartnershipDashboard";
import NewProjectFlow from "@components/dashboard/projects/NewProjectFlow";
import ProjectDashboard from "@components/dashboard/projects/ProjectDashboard";
import FlowOrchestrator from "@components/flows/FlowOrchestrator";
import useExecuteFlowCallback from "@components/flows/hooks/useExecuteFlowCallback";
import { useGetCurrentFlow } from "@components/flows/hooks/useGetCurrentFlow";
import RouterFlows from "@components/flows/RouterFlows";
import MarketplaceModal from "@components/marketplace/MarketplaceModal";
import TemplateCollectionsPageModal from "@components/marketplace/TemplateCollectionsPageModal";
import { TemplateDetailsModal } from "@components/marketplace/TemplateDetailsModal";
import DesignSystemTab from "@components/projectDashboard/DesignSystemTab";
import { ExperimentDetailsTab } from "@components/projectDashboard/experiments/ExperimentDetailsTab";
import { ExperimentEditTab } from "@components/projectDashboard/experiments/ExperimentEditTab";
import { ExperimentsTab } from "@components/projectDashboard/experiments/ExperimentsTab";
import ProjectDashboardLayout from "@components/projectDashboard/ProjectDashboardLayout";
import { ProjectIntegrationSettingsTab } from "@components/projectDashboard/ProjectIntegrationSettingsTab";
import { ProjectSettingsTab } from "@components/projectDashboard/ProjectSettingsTab";
import QuickStartModal from "@components/QuickStartModal";
import AuthenticatedUserRequiredRoute from "@components/router/AuthenticatedUserRequiredRoute";
import ReferralCodeRedirectRoute from "@components/router/ReferralCodeRedirectRoute";
import RouteWithModals from "@components/router/RouteWithModals";
import EditorApp from "@editor/EditorApp";
import { LocalStorageProvider, useLocalStorage } from "@hooks/useLocalStorage";
import useLoginSupportChatUser from "@hooks/useLoginSupportChatUser";
import { useUserHasConnectedStore } from "@hooks/useUserHasConnectedStore";
import { routes } from "@utils/router";

import Analytics from "@/features/analytics/Analytics";
import ComingSoon from "@/features/analytics/ComingSoon";
import PageDetails from "@/features/analytics/PageDetails";
import WorkspaceAnalyticsDashboard from "@/features/analytics/WorkspaceAnalyticsDashboard";
import Billing from "@/features/workspace/Billing";
import Members from "@/features/workspace/Members";
import WorkspaceSettings from "@/features/workspace/Settings";
import { WorkspaceBillingRedirectRoute } from "@/features/workspace/WorkspaceBillingRedirectRoute";
import WorkspaceDashboard from "@/features/workspace/WorkspaceDashboard";
import WorkspaceProjects from "@/features/workspace/WorkspaceProjects";
import { skipToken } from "@tanstack/react-query";
import { CookiesProvider } from "react-cookie";
import {
  createBrowserRouter,
  createRoutesFromElements,
  generatePath,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useParams,
  useSearchParams,
} from "react-router-dom";

import SentryBoundary from "./components/common/SentryBoundary";
import WorkspaceProvider from "./components/WorkspaceProvider";
import CurrentProjectProvider from "./contexts/CurrentProjectContext";
import useCurrentUser from "./hooks/useCurrentUser";
import useCurrentWorkspaceId from "./hooks/useCurrentWorkspaceId";
import { AIStreamingProvider } from "./providers/AIStreamingProvider";
import { trpc } from "./utils/trpc";

export function App() {
  const { isLoading, isAuthenticated } = useCurrentUser();
  const { isLoading: isLoadingUserHasConnectedStore } =
    useUserHasConnectedStore();
  // NOTE (Fran 2024-03-21): We need to fetch the workspaces before redirecting to the correct route
  // This endpoint should only get the list of the workspaces so is not an expensive one.
  const { isLoading: isLoadingWorkspaces } =
    trpc.workspace.getUserWorkspacesList.useQuery(
      isAuthenticated ? undefined : skipToken,
    );

  // Note (Noah, 2024-08-18): jss checks for this csp-nonce meta property any
  // time a stylesheet is created, memoizing the result. Adding in the nonce
  // here, even though it's a dummy value, means that jss won't do an expensive
  // querySelector every time a new stylesheet is added, which would adversely
  // affect performance. See
  // https://github.com/cssinjs/jss/blob/901882a894c7e802450696ebe2ea633ae63c5977/packages/jss/src/DomRenderer.js#L219
  React.useEffect(() => {
    const nonce = document.createElement("meta");
    nonce.setAttribute("property", "csp-nonce");
    nonce.setAttribute("content", "replo-jss-nonce");
    document.head.append(nonce);
  }, []);

  return (
    <FlowOrchestrator>
      {isLoading || isLoadingUserHasConnectedStore || isLoadingWorkspaces ? (
        <FullScreenLoader />
      ) : (
        <RouterProvider router={router} />
      )}
    </FlowOrchestrator>
  );
}

function RootRoute() {
  // NOTE (Fran 2024-02-22): We should check if the user has an uncompleted
  // flow and redirect to the next step.
  const { currentFlow } = useGetCurrentFlow();
  useExecuteFlowCallback(currentFlow?.slug);

  // Note (Sebas, 2023-07-05): This is the hook that initializes the support
  // chat user. It uses useLocation hook from react-router so it needs to be
  // inside the routes tree.
  useLoginSupportChatUser();

  return (
    // NOTE (Martin, 2024-10-09): We need an extra SentryBoundary component
    // here so that Sentry can catch errors inside the routing tree before
    // the React Router Boundary does it.
    <SentryBoundary>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <LocalStorageProvider>
          <WorkspaceProvider>
            <CurrentProjectProvider>
              <AIStreamingProvider>
                <Outlet />
              </AIStreamingProvider>
            </CurrentProjectProvider>
          </WorkspaceProvider>
          <LibraryTemplateDataToLocalStorage />
        </LocalStorageProvider>
      </CookiesProvider>
    </SentryBoundary>
  );
}

function IndexRedirectRoute() {
  const { isAuthenticated, isLoading } = useCurrentUser();
  const [searchParams] = useSearchParams();
  const { data: workspaceData } =
    trpc.workspace.getUserWorkspacesList.useQuery();
  const userHasOnlyOneWorkspace =
    (workspaceData?.workspaces ?? []).length === 1;

  let redirectPath;
  if (!isAuthenticated && !isLoading) {
    redirectPath = "/auth/signup";
  } else if (userHasOnlyOneWorkspace && workspaceData?.workspaces?.[0]?.id) {
    redirectPath = generatePath("/workspace/:workspaceId/projects", {
      workspaceId: workspaceData.workspaces[0].id,
    });
  } else {
    redirectPath = routes.dashboard;
  }

  const referral = searchParams.get("referral");
  if (referral) {
    redirectPath += `?referral=${referral}`;
  }

  return <Navigate to={redirectPath} replace />;
}

function PartnerProgramRedirectRoute() {
  const { user } = useCurrentUser();
  return (
    <Navigate
      to={
        user!.workspace?.id
          ? generatePath("/workspace/:workspaceId/partner/program", {
              workspaceId: user!.workspace.id,
            })
          : routes.dashboard
      }
      replace
    />
  );
}

function ActivePartnershipRequiredRoute({
  children,
}: {
  children: JSX.Element;
}) {
  const workspaceId = useCurrentWorkspaceId();

  const { data, isPending: isLoadingWorkspace } =
    trpc.workspace.getById.useQuery(
      workspaceId ? { id: workspaceId } : skipToken,
    );

  if (!isLoadingWorkspace && !data?.workspace?.isOnboarded) {
    return (
      <Navigate
        to={
          workspaceId
            ? generatePath("/workspace/:workspaceId/partner/program", {
                workspaceId,
              })
            : // NOTE (Fran 2023-11-21): Fallback to home if user doesn't have a workspace
              routes.dashboard
        }
        replace
      />
    );
  }

  return children;
}

function LibraryTemplateDataToLocalStorage() {
  const localStorage = useLocalStorage();

  const [searchParams] = useSearchParams();
  const libraryItemSlug = searchParams.get("libraryItemSlug");
  const templateId = searchParams.get("templateId");
  const libraryItemName = searchParams.get("libraryItemName");
  const type = searchParams.get("type");

  // biome-ignore lint/correctness/useExhaustiveDependencies: localStorage here is weird, I'm pretty sure we don't need this
  React.useEffect(() => {
    if (templateId) {
      setLibraryTemplateDataToLocalStorage({
        templateId,
        libraryItemSlug: libraryItemSlug ?? undefined,
        libraryItemName: libraryItemName ?? undefined,
        type: type ?? undefined,
      });
    }
  }, [libraryItemName, libraryItemSlug, localStorage, templateId, type]);

  return null;
}

function WorkspaceIndexRedirectRoute() {
  const params = useParams();
  const toUrl = params?.workspaceId
    ? `/workspace/${params.workspaceId}/projects`
    : routes.dashboard;
  return <Navigate to={toUrl} replace />;
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootRoute />}>
      {/* Index redirect route */}
      <Route index element={<IndexRedirectRoute />} />

      {/* Auth routes */}
      <Route path="/auth" element={<AuthPage />}>
        <Route path="login" element={<LoginForm />} />
        <Route path="signup" element={<SignupForm />} />
        <Route path="password/reset/init" element={<InitResetPassword />} />
        <Route path="password/reset" element={<ResetPassord />} />
      </Route>

      {/* Redirect routes */}
      <Route
        path="/signup/:referralCode"
        element={<ReferralCodeRedirectRoute />}
      />
      <Route
        path="/partner/program"
        element={<PartnerProgramRedirectRoute />}
      />
      <Route
        path="/workspace/billing"
        element={<WorkspaceBillingRedirectRoute />}
      />

      {/* Project routes */}
      <Route
        path="/project"
        element={
          <AuthenticatedUserRequiredRoute>
            <RouteWithModals>
              <ProjectDashboard />
            </RouteWithModals>
          </AuthenticatedUserRequiredRoute>
        }
      >
        <Route path="new" element={<NewProjectFlow />} />
      </Route>

      {/* Dashboard routes */}
      <Route
        path={routes.dashboard}
        element={
          <AuthenticatedUserRequiredRoute>
            <RouteWithModals>
              <ProjectDashboard />
            </RouteWithModals>
          </AuthenticatedUserRequiredRoute>
        }
      >
        <Route
          path="details/:templateId"
          element={
            <AuthenticatedUserRequiredRoute>
              <TemplateDetailsModal />
            </AuthenticatedUserRequiredRoute>
          }
        />
      </Route>

      {/* Legacy settings routes */}
      <Route
        path="/settings"
        element={
          <AuthenticatedUserRequiredRoute>
            <RouteWithModals>
              <AccountDashboard />
            </RouteWithModals>
          </AuthenticatedUserRequiredRoute>
        }
      >
        <Route path="profile" element={<ProfileBoard />} />
        <Route path="security" element={<SecurityBoard />} />
      </Route>

      {/* Workspace routes */}
      <Route
        path="/workspace/:workspaceId"
        element={
          <AuthenticatedUserRequiredRoute>
            <RouteWithModals>
              <WorkspaceDashboard />
            </RouteWithModals>
          </AuthenticatedUserRequiredRoute>
        }
      >
        <Route index element={<WorkspaceIndexRedirectRoute />} />
        <Route path="projects" element={<WorkspaceProjects />} />
        <Route
          path="project"
          element={
            <AuthenticatedUserRequiredRoute>
              <RouteWithModals>
                <WorkspaceProjects />
              </RouteWithModals>
            </AuthenticatedUserRequiredRoute>
          }
        >
          <Route path="new" element={<NewProjectFlow />} />
        </Route>
        <Route path="members" element={<Members />} />
        <Route path="billing" element={<Billing />} />
        <Route path="partner" element={<PartnershipDashboard />}>
          <Route path="program" element={<PartnerProgramLPContent />} />

          <Route
            path="home"
            element={
              <ActivePartnershipRequiredRoute>
                <PartnershipReferralBoard />
              </ActivePartnershipRequiredRoute>
            }
          />
        </Route>
        <Route path="integrations" element={<WorkspaceIntegrations />} />
        <Route
          path="integrations/shopify"
          element={<ShopifyIntegrationSettings />}
        />
        <Route path="analytics" element={<WorkspaceAnalyticsDashboard />}>
          <Route path="overview" element={<Analytics />} />
          <Route path="coming-soon" element={<ComingSoon />} />
          <Route path=":host/:pageUrlPath" element={<PageDetails />} />
        </Route>
        <Route path="settings" element={<WorkspaceSettings />} />
      </Route>

      {/* Editor routes */}
      <Route
        path={routes.editor.project}
        element={
          <RouteWithModals>
            <EditorApp />
          </RouteWithModals>
        }
      >
        <Route path={routes.marketplaceModal} element={<MarketplaceModal />} />
        <Route
          path={`${routes.marketplaceModal}/category/:categorySlug`}
          element={<MarketplaceModal />}
        />
        <Route
          path={`${routes.marketplaceModal}/collections`}
          element={<TemplateCollectionsPageModal />}
        />
        <Route
          path={`${routes.marketplaceModal}/collection/:collectionId`}
          element={<TemplateCollectionsPageModal />}
        />
        <Route
          path={`${routes.marketplaceModal}/details/:templateId`}
          element={<TemplateDetailsModal />}
        />
        <Route path="add" element={<QuickStartModal />} />
      </Route>

      {/* Editor alias routes for element */}
      <Route
        path={routes.editor.element}
        element={
          <RouteWithModals>
            <EditorApp />
          </RouteWithModals>
        }
      >
        <Route path={routes.marketplaceModal} element={<MarketplaceModal />} />
        <Route
          path={`${routes.marketplaceModal}/category/:categorySlug`}
          element={<MarketplaceModal />}
        />
        <Route
          path={`${routes.marketplaceModal}/collections`}
          element={<TemplateCollectionsPageModal />}
        />
        <Route
          path={`${routes.marketplaceModal}/collection/:collectionId`}
          element={<TemplateCollectionsPageModal />}
        />
        <Route
          path={`${routes.marketplaceModal}/details/:templateId`}
          element={<TemplateDetailsModal />}
        />
        <Route path="add" element={<QuickStartModal />} />
      </Route>

      {/* Editor alias route for product */}
      <Route
        path={routes.editor.product}
        element={
          <RouteWithModals>
            <EditorApp />
          </RouteWithModals>
        }
      />

      {/* Editor routes with alternate layout */}
      <Route
        path={routes.editor.project}
        element={
          <RouteWithModals>
            <ProjectDashboardLayout />
          </RouteWithModals>
        }
      >
        <Route path="project-settings" element={<ProjectSettingsTab />} />
        <Route
          path="integrations"
          element={<ProjectIntegrationSettingsTab />}
        />
        <Route path="template-defaults" element={<DesignSystemTab />} />
        <Route path="experiments" element={<ExperimentsTab />} />
        <Route
          path="experiments/:experimentId"
          element={<ExperimentDetailsTab />}
        />
        <Route
          path="experiments/:experimentId/edit"
          element={<ExperimentEditTab />}
        />
      </Route>

      {/* Flow routes */}
      <Route
        path="/flows/:flowSlug"
        element={
          <AuthenticatedUserRequiredRoute>
            <RouterFlows />
          </AuthenticatedUserRequiredRoute>
        }
      >
        <Route
          path=":flowStepId"
          element={
            <AuthenticatedUserRequiredRoute>
              <RouterFlows />
            </AuthenticatedUserRequiredRoute>
          }
        />
      </Route>

      {/*  404 redirect route */}
      <Route path="*" element={<Navigate to={routes.dashboard} replace />} />
    </Route>,
  ),
);
