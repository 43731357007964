import type { MenuItem } from "@editor/components/common/designSystem/Menu";
import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { UploadResult } from "@editor/reducers/commerce-reducer";
import type { RichTextEditorTag } from "@editor/types/rich-text-editor";
import type { TextShadow } from "@editor/utils/textShadow";
import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import type { Editor as CoreEditor } from "@tiptap/core";
import type { GradientStop, SolidOrGradient } from "replo-runtime/shared/types";
import type { TextControlType } from "schemas/modifiers";

import * as React from "react";

import ToggleGroup from "@common/designSystem/ToggleGroup";
import ResizablePane from "@components/ResizablePane";
import BadgeV2 from "@editor/components/common/designSystem/BadgeV2";
import { ButtonPhony } from "@editor/components/common/designSystem/Button";
import DynamicDataButton from "@editor/components/common/designSystem/DynamicDataButton";
import { Menu } from "@editor/components/common/designSystem/Menu";
import Popover from "@editor/components/common/designSystem/Popover";
import Selectable from "@editor/components/common/designSystem/Selectable";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import {
  errorToast,
  successToast,
} from "@editor/components/common/designSystem/Toast";
import Tooltip from "@editor/components/common/designSystem/Tooltip";
import { TIPTAP_EDITOR_SCROLLABLE_DIV_ID } from "@editor/components/editor/constants";
import DropZone from "@editor/components/editor/page/Dropzone";
import ControlGroup from "@editor/components/editor/page/element-editor/components/extras/ControlGroup";
import { useRichTextComponent } from "@editor/components/RichTextComponentContext";
import TipTapToolbar from "@editor/components/TipTapRichTextToolbar";
import Tiptap, { useTipTapEditor } from "@editor/components/TiptapTextEditor";
import { useGetModifierControls } from "@editor/hooks/rightBar/useGetModifierControls";
import { useApplyComponentAction } from "@editor/hooks/useApplyComponentAction";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useEnableNonDynamicTextEditing } from "@editor/hooks/useEnableNonDynamicTextEditing";
import {
  usePageFontOptions,
  useShopifyFontOptions,
} from "@editor/hooks/useFontFamilyOptions";
import { useGetAttribute } from "@editor/hooks/useGetAttribute";
import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { useModal } from "@editor/hooks/useModal";
import { useTargetFrameDocument } from "@editor/hooks/useTargetFrame";
import { isNewRightBarUIEnabled } from "@editor/infra/featureFlags";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  selectAncestorTextColor,
  selectColor,
  selectColorGradientStops,
  selectColorGradientTilt,
  selectDraftComponent,
  selectDraftComponentComputedStyleValue,
  selectDraftComponentId,
  selectDraftComponentNodeFromActiveCanvas,
  selectDraftComponentText,
  selectDraftComponentType,
  selectDraftElementId,
  selectDraftRepeatedIndex,
  selectFontFamily,
  selectFontSize,
  selectFontStyle,
  selectFontWeight,
  selectIsShopifyIntegrationEnabled,
  selectLetterSpacing,
  selectLineHeight,
  selectParsedTextShadows,
  selectTextAlign,
  selectTextDecoration,
  selectTextOutline,
  selectTextShadow,
  selectTextTransform,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import {
  selectOpenPopoverId,
  setIsRichTextEditorFocused,
  setOpenPopoverId,
} from "@editor/reducers/ui-reducer";
import {
  convertCSSStylesToReploStyles,
  getValidFilteredStyleProps,
} from "@editor/reducers/utils/component-actions";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { getEditorComponentNode } from "@editor/utils/component";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import {
  filterOutPageFonts,
  FORMATTED_GOOGLE_FONT_OPTIONS,
  GENERIC_FONT_FAMILIES,
  getEndEnhancer,
} from "@editor/utils/font";
import { useInCanvasPreview } from "@editor/utils/preview";
import { isAllTextColored } from "@editor/utils/rte";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import {
  getTextOutlineObject,
  getTextOutlineString,
} from "@editor/utils/textOutline";
import {
  formatTitle,
  getTextShadowString,
  parseTextShadows,
} from "@editor/utils/textShadow";
import SelectablePopover from "@editorComponents/SelectablePopover";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";
import ModifierGroup from "@editorExtras/ModifierGroup";
import LengthInputModifier, {
  LengthInputSelector,
} from "@editorModifiers/LengthInputModifier";
import {
  getCurrentTag,
  getUpdatedLineHeightForFontSize,
  normalizeFontFamily,
} from "@editorModifiers/utils";

import {
  selectActiveCanvas,
  selectActiveCanvasFrame,
} from "@/features/canvas/canvas-reducer";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import differenceBy from "lodash-es/differenceBy";
import intersectionBy from "lodash-es/intersectionBy";
import { EditorView } from "prosemirror-view";
import { AiOutlineEllipsis, AiOutlineFontSize } from "react-icons/ai";
import { BsBorderWidth, BsFonts, BsX } from "react-icons/bs";
import {
  RiAlignCenter,
  RiAlignJustify,
  RiAlignLeft,
  RiAlignRight,
  RiItalic,
  RiLineHeight,
  RiStrikethrough,
  RiTextSpacing,
  RiUnderline,
} from "react-icons/ri";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { FONT_WEIGHT_OPTIONS } from "replo-runtime/shared/utils/font";
import {
  CSS_LENGTH_TYPES,
  CSS_LENGTH_TYPES_WITH_COMPUTED,
  parseUnit,
} from "replo-runtime/shared/utils/units";
import {
  coerceNumberToString,
  hasOwnProperty,
  isNotNullish,
} from "replo-utils/lib/misc";
import { useForceUpdate } from "replo-utils/react/use-force-update";
import { v4 as uuidv4 } from "uuid";

import ModifierLabel from "../extras/ModifierLabel";
import SolidColorSelector from "../SolidColorSelector";
import DynamicColorModifier from "./DynamicColorModifier";

// NOTE (Reinaldo 2022-06-03): Hacky solution to fix editor breaking when selecting a text node
// https://github.com/ueberdosis/tiptap/issues/1451#issuecomment-953348865
EditorView.prototype.updateState = function updateState(state) {
  // @ts-ignore
  if (!this.docView) {
    return;
  } // This prevents the matchesNode error on hot reloads
  // @ts-ignore
  this.updateStateInner(state, this.state.plugins != state.plugins);
};

const DEFAULT_TEXT_SHADOW = {
  id: uuidv4(),
  offsetX: "0px",
  offsetY: "4px",
  blur: "1px",
  color: "#00000040",
};

const TEXT_TRANSFORM_OPTIONS = [
  {
    value: "none",
    label: "Ag",
    tooltipContent: "Normal",
  },
  {
    value: "uppercase",
    label: "AG",
    tooltipContent: "Uppercase",
  },
  {
    value: "lowercase",
    label: "ag",
    tooltipContent: "Lowercase",
  },
];

const TextStyleModifier: React.FC = () => {
  const modal = useModal();
  const text = useEditorSelector(selectDraftComponentText);
  useTextStyleHotkeys();

  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  const handleOpenDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT,
        referrerData: {
          type: "prop",
          propId: "text",
        },
        initialPath: getPathFromVariable(text),
      },
    });
  };

  if (isNewRightBarEnabled) {
    return (
      <NewTextStyleModifierUI handleOpenDynamicData={handleOpenDynamicData} />
    );
  }

  // TODO (Fran 2024-10-08): Remove when isNewRightBarEnabled is removed
  return (
    <ModifierGroup
      title="Text"
      endEnhancer={
        !isNewRightBarEnabled && (
          <div className="flex flex-row gap-2">
            <DynamicDataButton onClick={handleOpenDynamicData} />
            <HeaderMenu />
          </div>
        )
      }
    >
      <div
        id="text-style-modifier"
        className={classNames({
          "grid grid-cols-2 gap-2": !isNewRightBarEnabled,
          "flex flex-col gap-2": isNewRightBarEnabled,
        })}
      >
        <TextControl handleOpenDynamicData={handleOpenDynamicData} />
        <div id="font-family-selectable">
          <FontFamilyControl />
        </div>
        <FontWeightControl />
        <FontSizeControl />
        <LineHeightControl />
        <LetterSpacingControl />
        <TextAlignmentControl />
        <TextFormatControl />
        <TextTransformControl />
      </div>
    </ModifierGroup>
  );
};

const NewTextStyleModifierUI: React.FC<{ handleOpenDynamicData(): void }> = ({
  handleOpenDynamicData,
}) => {
  const [controls, addControl] = useGetModifierControls<"text">("text");
  const handleAddTextShadow = useAddDefaultShadow();

  const menuItems: MenuItem[] = [
    {
      id: "textDecoration",
      title: "Format",
      type: "leaf",
      onSelect: () => addControl("textDecoration"),
      isDisabled: controls.has("textDecoration"),
    },
    {
      id: "textTransform",
      title: "Casing",
      type: "leaf",
      onSelect: () => addControl("textTransform"),
      isDisabled: controls.has("textTransform"),
    },
    {
      id: "textOutline",
      title: "Outline",
      type: "leaf",
      onSelect: () => addControl("textOutline"),
      isDisabled: controls.has("textOutline"),
    },
    {
      id: "textShadow",
      title: "Shadow",
      type: "leaf",
      onSelect: () => {
        addControl("textShadow");
        handleAddTextShadow();
      },
    },
  ];

  const mapControlsToComponent: Array<{
    property: TextControlType;
    component: React.ReactNode;
  }> = [
    { property: "textAlign", component: <TextAlignmentControl /> },
    {
      property: "fontFamily",
      component: (
        <div id="font-family-selectable">
          <FontFamilyControl />
        </div>
      ),
    },
    { property: "fontWeight", component: <FontWeightControl /> },
    { property: "fontSize", component: <FontSizeControl /> },
    { property: "lineHeight", component: <LineHeightControl /> },
    { property: "color", component: <ForegroundColorControl /> },
    { property: "letterSpacing", component: <LetterSpacingControl /> },
    { property: "textDecoration", component: <TextFormatControl /> },
    { property: "htmlTag", component: <TagControl /> },
    { property: "textTransform", component: <TextTransformControl /> },
    { property: "textOutline", component: <TextOutlineControl /> },
    {
      property: "textShadow",
      component: <TextShadowControl />,
    },
  ];

  return (
    <ModifierGroup title="Text" menuItems={menuItems}>
      <div id="text-style-modifier" className="flex flex-col gap-2">
        <TextControl handleOpenDynamicData={handleOpenDynamicData} />
        {mapControlsToComponent.map(({ property, component }) => (
          <React.Fragment key={property}>
            {controls.has(property) && component}
          </React.Fragment>
        ))}
      </div>
    </ModifierGroup>
  );
};

const HeaderMenu: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const colorValue = useEditorSelector(selectColor);
  const textValue = useEditorSelector(selectDraftComponentText);
  return (
    <Menu
      items={[
        {
          type: "leaf",
          id: "pasteFromFigma",
          title: "Paste Style From Figma",
          onSelect: () => {
            void navigator.clipboard.readText().then((text) => {
              const styleObject = convertCSSStylesToReploStyles(text, true);
              const { style: textValidStyles } = getValidFilteredStyleProps(
                { style: styleObject },
                "text",
                { colorValue: colorValue ?? null, textValue },
              );
              applyComponentAction({
                type: "setStyles",
                value: textValidStyles ?? {},
              });
            });
          },
        },
      ]}
      customWidth={200}
      trigger={
        <ButtonPhony
          // NOTE (Chance 2023-11-02): `Menu` renders a button by default so this
          // is just for the styles
          type="secondary"
          style={{ minWidth: 0 }}
          className="h-6 w-6 bg-subtle hover:bg-subtle p-0 text-subtle"
        >
          <AiOutlineEllipsis className="h-4 w-4" />
        </ButtonPhony>
      }
    />
  );
};

const TextControl: React.FC<{
  handleOpenDynamicData: () => void;
}> = ({ handleOpenDynamicData }) => {
  const text = useEditorSelector(selectDraftComponentText);

  return typeof text === "string" && text.includes("{{") ? (
    <DynamicTextControl handleOpenDynamicData={handleOpenDynamicData} />
  ) : (
    <RichTextControl handleOpenDynamicData={handleOpenDynamicData} />
  );
};

const DynamicTextControl: React.FC<{
  handleOpenDynamicData: () => void;
}> = ({ handleOpenDynamicData }) => {
  const text = useEditorSelector(selectDraftComponentText);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const applyComponentAction = useApplyComponentAction();

  return (
    <div className="col-span-2">
      <DynamicDataValueIndicator
        type="text"
        templateValue={text}
        componentId={draftComponentId ?? undefined}
        onClick={handleOpenDynamicData}
        onRemove={() => {
          applyComponentAction({
            type: "applyCompositeAction",
            value: [
              {
                type: "setStyles",
                value: {
                  fontWeight: "normal",
                  textDecoration: "none",
                  fontStyle: "normal",
                },
                analyticsExtras: {
                  actionType: "edit",
                  createdBy: "replo",
                },
              },
              {
                type: "setProps",
                value: { text: "Add new text here" },
                analyticsExtras: {
                  actionType: "edit",
                  createdBy: "replo",
                },
              },
            ],
          });
        }}
      />
    </div>
  );
};

const toolbarId = "pane-rte-toolbar";

const RichTextControl: React.FC<{ handleOpenDynamicData(): void }> = ({
  handleOpenDynamicData,
}) => {
  const dispatch = useEditorDispatch();

  const { applyChanges, setTipTapEditor, setTag } = useRichTextComponent();
  const onChangeRichTextEditor = React.useMemo(
    () => debounce((newValue: string) => applyChanges(newValue), 300),
    [applyChanges],
  );

  const onBlurRichTextEditor = React.useCallback(() => {
    dispatch(setIsRichTextEditorFocused(false));
  }, [dispatch]);

  const onFocusRichTextEditor = React.useCallback(() => {
    dispatch(setIsRichTextEditorFocused(true));
  }, [dispatch]);

  const onSelectionUpdate = (editor: CoreEditor | null) => {
    setTag(getCurrentTag(editor));
  };

  const onCreate = (editor: CoreEditor | null) => {
    setTag(getCurrentTag(editor));
  };

  const text = useEditorSelector(selectDraftComponentText);
  const tipTapEditor = useTipTapEditor(
    text,
    onChangeRichTextEditor,
    onBlurRichTextEditor,
    onFocusRichTextEditor,
    onSelectionUpdate,
    onCreate,
  );

  const isRichTextEditorFocused = useEditorSelector(
    (state) => state.ui.isRichTextEditorFocused,
  );

  const minEditorHeight = 40;
  const cutoffEditorExpansion = 200;

  const [resizableHeight, setResizableHeight] = React.useState(minEditorHeight);
  const [currentEditorHeight, setCurrentEditorHeight] = React.useState(40);

  // Synchronize text changes to the editor that don't originate from the
  // editor.
  const textRef = React.useRef(text);
  React.useEffect(() => {
    const prevText = textRef.current;
    textRef.current = text;
    if (!isRichTextEditorFocused && tipTapEditor && text !== prevText) {
      tipTapEditor.commands.setContent(text!);
    }
  }, [isRichTextEditorFocused, tipTapEditor, text]);

  React.useEffect(() => {
    // pass this instance of the editor to context so it can be used elsewhere
    if (tipTapEditor) {
      setTipTapEditor(tipTapEditor);
    }
    // Make sure to remove the editor from context when this component is
    // unmounted
    return () => {
      setTipTapEditor(null);
    };
  }, [setTipTapEditor, tipTapEditor]);

  // NOTE (Max 2024-05-08): Listens for when editor's height changes, as we need
  // to update the resizable's component height accordingly. We only allow to
  // automatically increase the height until cutoffEditorExpansion
  React.useEffect(() => {
    const editorDiv = document.getElementById(TIPTAP_EDITOR_SCROLLABLE_DIV_ID);

    if (editorDiv) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { height } = entry.contentRect;
          setCurrentEditorHeight(height);
          if (height <= cutoffEditorExpansion) {
            setResizableHeight(height + 10);
          }
        }
      });

      resizeObserver.observe(editorDiv);
      return () => resizeObserver.disconnect();
    }
  }, []);

  // NOTE (Max 2024-05-08): In the context of the ResizablePane's height being larger than the
  // actual editor's height, we want the user to be able to click on ResizablePane and still
  // trigger the editor. Otherwise, the only place where user could click to start typing
  // would be within the actual editor - not good UX.
  const propagateClick = () => {
    const editorDiv = document.getElementById(
      "in-tiptap-editor",
    ) as HTMLElement | null;
    editorDiv?.focus();
  };

  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  return (
    <>
      <div
        className="col-span-2"
        data-testid="rich-text-editor"
        // NOTE (Sebas, 2024-06-27): We need to stop propagation of the keydown event
        // to prevent the hotkeys from being triggered.
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div
          className={classNames(
            "rounded bg-subtle p-2 focus-within:outline focus-within:outline-1",
            {
              "p-2": !isNewRightBarEnabled,
              "p-1 flex flex-col gap-2": isNewRightBarEnabled,
            },
          )}
        >
          <TipTapToolbar editor={tipTapEditor} id={toolbarId} />
          <div onClick={propagateClick}>
            <ResizablePane
              isVertical={true}
              minSize={minEditorHeight}
              maxSize={1500}
              size={resizableHeight}
              onResize={(newSize) => {
                setResizableHeight(newSize);
              }}
              contentClassName={`cursor-text ${currentEditorHeight > resizableHeight ? "overflow-auto" : "overflow-none"}`}
            >
              <div
                id={TIPTAP_EDITOR_SCROLLABLE_DIV_ID}
                className="pb-2 text-xs w-full h-max"
              >
                <Tiptap editor={tipTapEditor} />
              </div>
              {isNewRightBarEnabled ? (
                <div className="absolute right-0 bottom-0">
                  <DynamicDataButton onClick={handleOpenDynamicData} />
                </div>
              ) : null}
            </ResizablePane>
          </div>
        </div>
      </div>
      {!isNewRightBarEnabled ? (
        <div className="col-span-2">
          <TagControl />
        </div>
      ) : null}
    </>
  );
};

const tagOptions: {
  label: string;
  value: RichTextEditorTag;
}[] = [
  {
    label: "Paragraph",
    value: "P",
  },
  {
    label: "Heading 1",
    value: "1",
  },
  {
    label: "Heading 2",
    value: "2",
  },
  {
    label: "Heading 3",
    value: "3",
  },
];

const TagControl: React.FC = () => {
  const { setTag, currentTag } = useRichTextComponent();
  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  return (
    <div
      className={classNames({
        "flex items-center w-full": isNewRightBarEnabled,
      })}
    >
      {isNewRightBarEnabled && <ModifierLabel label="Tag" />}
      <Selectable
        className="w-full"
        placeholder="Select tag"
        defaultValue="P"
        value={currentTag}
        options={tagOptions}
        onSelect={setTag}
      />
    </div>
  );
};

const getDisplayName = (
  fontValue: string | null,
  nameToDisplayName: Record<string, string | undefined>,
) => {
  if (!fontValue) {
    return undefined;
  }
  return nameToDisplayName[fontValue];
};

export const FontFamilyControl: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const fontFamily = useEditorSelector(selectFontFamily) ?? null;
  const normalizedFontFamily = normalizeFontFamily(fontFamily)
    ?.replaceAll("_", " ")
    .replaceAll("-", " ");
  const pageFontOptions = usePageFontOptions();
  const {
    fontOptions: shopifyFontOptions,
    fallbacks,
    refetch,
    nameToDisplayName,
  } = useShopifyFontOptions();

  // NOTE (Gabe 2024-05-15): Only show the fonts that are actually available
  // (via Shopify or Google fonts).
  const filteredPageFonts = intersectionBy(
    pageFontOptions,
    [...shopifyFontOptions, ...FORMATTED_GOOGLE_FONT_OPTIONS],
    (font) => font.value,
  );

  const fontOptions = [
    // Note (Evan, 6/2/23) We add the end enhancers to page fonts here, since we have to know whether they're shopify-uploaded fonts or not
    ...filteredPageFonts.map((font) => ({
      ...font,
      endEnhancer: getEndEnhancer(font, shopifyFontOptions),
      label: getDisplayName(font.value, nameToDisplayName) ?? font.label,
    })),
    // Note (Evan, 6/2/23) Filter out page fonts from shopify/google fonts so that we don't have any duplicates
    ...filterOutPageFonts(shopifyFontOptions, filteredPageFonts).map(
      (font) => ({
        ...font,
        label: getDisplayName(font.value, nameToDisplayName) ?? font.label,
      }),
    ),
    ...filterOutPageFonts(FORMATTED_GOOGLE_FONT_OPTIONS, filteredPageFonts),
  ];

  const draftComponentNode = useEditorSelector(
    selectDraftComponentNodeFromActiveCanvas,
  );

  const rerender = useForceUpdate();
  const computedFontStyle =
    draftComponentNode && getComputedStyle(draftComponentNode).fontFamily;

  const onSelect = (fontValue: string | null) => {
    let sanitizedFont = fontValue;

    // NOTE (Mariano, 2022-09-23): We check for blank spaces in the font name,
    // if we found one then we just wrap the font with quotes to prevent
    // `invalid value` issues when setting the CSS font rule
    if (fontValue && /\s/g.test(fontValue)) {
      sanitizedFont = `"${fontValue}"`;
    }

    applyComponentAction({
      type: "setStyles",
      value: {
        fontFamily: sanitizedFont
          ? `${sanitizedFont}, ${fallbacks.join(",")}`
          : null,
      },
    });
  };

  // NOTE (Sebas, 2023-03-22): When we reset the font to the default value,
  // we need to wait some time to get the updated computed font style.
  // NOTE (Chance, 2024-04-09) This should probably be handled in onSelect
  // instead to reduce complexity.
  const fontFamilyRef = React.useRef(fontFamily);
  React.useEffect(() => {
    const previousFontFamily = fontFamilyRef.current;
    fontFamilyRef.current = fontFamily;
    if (previousFontFamily && !fontFamily) {
      const id = setTimeout(() => {
        rerender();
      }, 500);
      return () => {
        clearTimeout(id);
      };
    }
  }, [rerender, fontFamily]);

  const onFontUploadComplete = async (data: UploadResult) => {
    const newFonts = await refetch();
    const fontName = newFonts?.find((font) =>
      // NOTE (Evan, 7/13/23) We replace spaces with underscores here because
      // we're matching against the Shopify files system, which does the same.
      data.asset.publicUrl.includes(font.name.replace(/ /g, "_")),
    )?.name;
    if (fontName) {
      onSelect(fontName);
    }
  };

  // Note (Evan, 2024-08-07): Attempt to retrieve a display name from
  // nameToDisplayName, checking first for the fontFamily, then the
  // fontFamily after removing any comma-joined fallbacks
  let displayName: string | undefined = undefined;
  if (fontFamily) {
    displayName =
      nameToDisplayName[fontFamily] ??
      nameToDisplayName[fontFamily.split(",")[0] ?? ""];
  }

  const fontDisplayName = displayName ?? normalizedFontFamily;

  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  return (
    <FontFamilySelectableWrapper>
      <SelectablePopover
        title="Fonts"
        itemSize={26}
        options={fontOptions}
        fallbackValidValues={GENERIC_FONT_FAMILIES}
        onSelect={onSelect}
        startEnhancer={<BsFonts size={16} />}
        placeholder={normalizeFontFamily(computedFontStyle)}
        extraContent={
          <UploadCustomFont
            onUploadComplete={(data) => {
              void onFontUploadComplete(data);
            }}
          />
        }
        isRemovable={Boolean(fontFamily)}
        allowNull
        selectedItems={[normalizeFontFamily(fontFamily)]}
        triggerId="font-family-selectable-trigger"
        placeholderClassname={classNames(
          "text-ellipsis truncate",
          isNewRightBarEnabled ? "w-32" : "w-28",
        )}
        childrenClassname={classNames(
          "text-ellipsis truncate",
          isNewRightBarEnabled ? "w-[105px]" : "w-full",
        )}
        popoverSideOffset={isNewRightBarEnabled ? 82 : undefined}
      >
        {fontDisplayName ? fontDisplayName : null}
      </SelectablePopover>
    </FontFamilySelectableWrapper>
  );
};

const FontFamilySelectableWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  if (isNewRightBarEnabled) {
    return <ControlGroup label="Font">{children}</ControlGroup>;
  }

  return children;
};

const calculateFontWeights = (
  paintableDocument: Document | null,
  fontFamily: string | null,
  isSafeOrNotAvailableFont?: boolean,
) => {
  if (!fontFamily || isSafeOrNotAvailableFont) {
    return FONT_WEIGHT_OPTIONS.map(({ label, value }) => ({
      label,
      value: value.toString(),
    }));
  }
  if (!paintableDocument) {
    return [];
  }
  const editorFontsArray = Array.from(paintableDocument.fonts);
  return FONT_WEIGHT_OPTIONS.filter((fontWeight) =>
    editorFontsArray
      .filter((font) => fontFamily.includes(font.family))
      .find((font) => font.weight === fontWeight.value.toString()),
  ).map(({ label, value }) => ({
    label,
    value: value.toString(),
  }));
};

const useFontWeightOptions = () => {
  const fontFamily = useEditorSelector(selectFontFamily) ?? null;
  // NOTE (Chance 2024-06-24): It shouldn't matter which canvas we use here
  // since the DOM contents should be the same for each. We just need to read
  // the document's `fonts` property.
  const targetFrameDocument = useTargetFrameDocument("desktop");
  // NOTE (Sebas, 2023-03-27): This counter is required to avoid
  // an infinite loop in case the font is not found on the document.
  // This can happen if the user selects a safe font, like Arial,
  // or if the font is not being imported correctly.
  const intervalCounter = React.useRef(0);
  const [options, setOptions] = React.useState(
    calculateFontWeights(targetFrameDocument, fontFamily),
  );
  // NOTE (Sebas, 2023-03-21): We need this effect because we need a timeout in case the
  // user selects a new font we need to wait for the font to be downloaded/added to the
  // DOM and then calculate the available weights for that font.
  React.useEffect(() => {
    const interval = setInterval(() => {
      const newOptions = calculateFontWeights(
        targetFrameDocument,
        fontFamily,
        intervalCounter.current > 5,
      );
      setOptions(newOptions);
      intervalCounter.current += 1;
      if (newOptions.length > 0) {
        clearInterval(interval);
        intervalCounter.current = 0;
      }
    }, 500);
    return () => {
      clearInterval(interval);
      intervalCounter.current = 0;
    };
  }, [fontFamily, targetFrameDocument]);

  return options;
};

function uploadWasSuccessful(
  res:
    | { data: UploadResult }
    | { error: FetchBaseQueryError | SerializedError },
): res is { data: UploadResult } {
  return hasOwnProperty(res, "data");
}

const UploadCustomFont: React.FC<{
  onUploadComplete(data: UploadResult): void;
}> = ({ onUploadComplete }) => {
  const projectId = useCurrentProjectId();
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const onUpload = (
    res:
      | { data: UploadResult }
      | { error: FetchBaseQueryError | SerializedError },
  ) => {
    if (uploadWasSuccessful(res)) {
      successToast("Font Uploaded", "");
      onUploadComplete(res.data);
    } else {
      errorToast(
        "Failed Uploading Font",
        "Please try again or reach out to support@replo.app for help.",
      );
    }
  };

  return (
    <>
      {isShopifyIntegrationEnabled ? (
        <div id="text-style-modifier-font-upload">
          <DropZone
            acceptDropAssetType={[".woff", ".woff2"]}
            onUploadComplete={onUpload}
            projectId={projectId}
            sourceType="files"
            inputSize="small"
            uploadText="Upload Custom Fonts"
          />
        </div>
      ) : (
        <ButtonPhony
          type="secondary"
          style={{ minWidth: 0 }}
          className="bg-transparent text-slate-400 hover:bg-transparent"
          isDisabled={true}
          tooltipText="Connect to Shopify to add custom fonts"
        >
          Upload Custom Fonts
        </ButtonPhony>
      )}
    </>
  );
};

const FontWeightControlWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  if (isNewRightBarUIEnabled()) {
    return <ControlGroup label="Weight">{children}</ControlGroup>;
  }

  return children;
};

const FontWeightControl: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const fontWeight = useEditorSelector(selectFontWeight);
  const options = useFontWeightOptions();

  return (
    <FontWeightControlWrapper>
      <Selectable
        className="w-full"
        placeholder="Weight"
        options={options}
        ignoreValueMismatchError
        value={fontWeight?.toString()}
        onSelect={(newValue: string) =>
          applyComponentAction({
            type: "setStyles",
            value: {
              fontWeight: newValue,
            },
          })
        }
      />
    </FontWeightControlWrapper>
  );
};

const FontSizeControl: React.FC = () => {
  const draftComponent = useEditorSelector(selectDraftComponent);
  const draftElementId = useEditorSelector(selectDraftElementId);
  const lineHeight = useEditorSelector(selectLineHeight);
  const applyComponentAction = useApplyComponentAction();
  const getAttribute = useGetAttribute();
  const {
    setPreviewCSSPropertyValue,
    enableCanvasPreviewCSSProperties,
    disableCanvasPreviewCSSProperties,
  } = useInCanvasPreview();

  const draftRepeatedIndex = useEditorSelector(selectDraftRepeatedIndex);

  const activeCanvasFrame = useEditorSelector(selectActiveCanvasFrame);
  const targetDocument = activeCanvasFrame?.contentDocument;

  if (!draftComponent) {
    return null;
  }

  const fontSizeMenuItems = [
    "Reset",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "24px",
    "32px",
  ].map((v) => ({
    label: v,
    value: v === "Reset" ? null : v,
  }));

  // Note (Noah, 2022-12-09, REPL-5261): Calculate the computed value of the
  // node's font size in order to use it as a placeholder if no value is set. We
  // actually use the parent node's computed value here, to avoid an issue where
  // when you reset the font size, the input will still show the old value as
  // the placeholder because React hasn't yet completed its DOM commits (this is
  // fine, since it's the same value on the parent due to CSS cascading)
  const draftComponentNode = targetDocument
    ? getEditorComponentNode(
        targetDocument,
        draftElementId,
        draftComponent.id,
        draftRepeatedIndex,
      )?.parentElement
    : null;
  const propertyForPlaceholder = draftComponentNode
    ? getComputedStyle(draftComponentNode).fontSize
    : null;

  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  return (
    <LengthInputModifier
      label={isNewRightBarEnabled && <ModifierLabel label="Size" />}
      field="style.fontSize"
      dragTrigger={isNewRightBarEnabled ? "label" : "startEnhancer"}
      minDragValues={{ px: 0 }}
      minValues={{ px: 0 }}
      allowsNegativeValue={false}
      anchorValue="16px"
      resetValue={null}
      placeholder={propertyForPlaceholder ?? "Font Size"}
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      startEnhancer={() => (
        <Tooltip inheritCursor content="Font Size" triggerAsChild>
          <span tabIndex={0}>
            <AiOutlineFontSize />
          </span>
        </Tooltip>
      )}
      menuOptions={fontSizeMenuItems}
      onPreviewChange={(value: string) => {
        const lineHeight = getUpdatedLineHeightForFontSize(
          value,
          draftComponent,
          getAttribute,
        );
        setPreviewCSSPropertyValue(["lineHeight"], lineHeight);
      }}
      onDragStart={() => {
        const lineHeightValue = lineHeight ?? "normal";
        enableCanvasPreviewCSSProperties(
          ["lineHeight"],
          String(lineHeightValue),
        );
        setPreviewCSSPropertyValue(["lineHeight"], String(lineHeightValue));
      }}
      onDragEnd={() => {
        disableCanvasPreviewCSSProperties(["lineHeight"]);
      }}
      onChange={(value: string) => {
        const lineHeight = getUpdatedLineHeightForFontSize(
          value,
          draftComponent,
          getAttribute,
        );
        applyComponentAction({
          type: "setStyles",
          value: {
            fontSize: value,
            lineHeight,
          },
        });
      }}
      previewProperty="fontSize"
    />
  );
};

const LineHeightControl: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const fontSize = useEditorSelector(selectFontSize);
  const computedFontSize =
    useEditorSelector((state) =>
      selectDraftComponentComputedStyleValue(state, "fontSize", activeCanvas),
    ) ?? "16px";
  const lineHeightDefaultValue =
    styleAttributeToEditorData.lineHeight.defaultValue;
  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  return (
    <LengthInputModifier
      label={isNewRightBarEnabled && <ModifierLabel label="Line" />}
      startEnhancer={() => (
        <Tooltip inheritCursor content="Line Height" triggerAsChild>
          <span tabIndex={0}>
            <RiLineHeight />
          </span>
        </Tooltip>
      )}
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      field="style.lineHeight"
      anchorValue={fontSize ? String(fontSize) : String(computedFontSize)}
      dragTrigger={isNewRightBarEnabled ? "label" : "startEnhancer"}
      minDragValues={{ px: 0 }}
      minValues={{ px: 0 }}
      resetValue={lineHeightDefaultValue}
      allowsNegativeValue={false}
      previewProperty="lineHeight"
      onChange={(newValue: string) => {
        applyComponentAction({
          type: "setStyles",
          value: {
            lineHeight: newValue === "auto" ? lineHeightDefaultValue : newValue,
          },
        });
      }}
    />
  );
};

const LetterSpacingControl: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const letterSpacingDefaultValue =
    styleAttributeToEditorData.letterSpacing.defaultValue;
  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  return (
    <LengthInputModifier
      label={isNewRightBarEnabled && <ModifierLabel label="Spacing" />}
      startEnhancer={() => (
        <Tooltip inheritCursor content="Letter Spacing" triggerAsChild>
          <span tabIndex={0}>
            <RiTextSpacing />
          </span>
        </Tooltip>
      )}
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      field="style.letterSpacing"
      resetValue={letterSpacingDefaultValue}
      anchorValue="1px"
      dragTrigger={isNewRightBarEnabled ? "label" : "startEnhancer"}
      onChange={(newValue: string) =>
        applyComponentAction({
          type: "setStyles",
          value: {
            letterSpacing: newValue,
          },
        })
      }
      menuOptions={[
        { label: "Reset", value: "" },
        { label: "1px", value: "1px" },
        { label: "2px", value: "2px" },
        { label: "4px", value: "4px" },
      ]}
      previewProperty="letterSpacing"
    />
  );
};

const normalizeTextDirectionForInput = (
  textDirection?: CSSStyleDeclaration["textAlign"] | null,
) => {
  if (textDirection === "start") {
    return "left";
  } else if (textDirection === "end") {
    return "right";
  }
  return textDirection;
};

const TextAlignmentControlWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  if (isNewRightBarUIEnabled()) {
    return <ControlGroup label="Alignment">{children}</ControlGroup>;
  }

  return <div className="col-span-2">{children}</div>;
};

const TextAlignmentControl: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const textAlign = useEditorSelector(selectTextAlign);
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const computedStyleValue =
    normalizeTextDirectionForInput(
      useEditorSelector((state) =>
        selectDraftComponentComputedStyleValue(
          state,
          "textAlign",
          activeCanvas,
        ),
      ),
    ) ?? null;

  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  const options = [
    {
      value: "left",
      label: <RiAlignLeft size={16} />,
      tooltipContent: "Align Text Left",
    },
    {
      value: "center",
      label: <RiAlignCenter size={16} />,
      tooltipContent: "Align Text Center",
    },
    {
      value: "right",
      label: <RiAlignRight size={16} />,
      tooltipContent: "Align Text Right",
    },
  ];

  if (!isNewRightBarEnabled) {
    options.push({
      value: "justify",
      label: <RiAlignJustify size={16} />,
      tooltipContent: "Align Text Justify",
    });
  }

  return (
    <TextAlignmentControlWrapper>
      <ToggleGroup
        type="single"
        style={{
          height: !isNewRightBarEnabled ? 26 : undefined,
          // TODO (Fran 2024-10-09 REPL-14029): Make the changes need it in the ToggleGroup component to use
          // w-full instead of this.
          width: "100%",
        }}
        value={textAlign ?? computedStyleValue}
        options={options}
        onChange={(newValue: string) => {
          applyComponentAction({
            type: "setStyles",
            value: { textAlign: newValue },
          });
        }}
      />
    </TextAlignmentControlWrapper>
  );
};

const TextFormatControlWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  if (isNewRightBarUIEnabled()) {
    return <ControlGroup label="Format">{children}</ControlGroup>;
  }

  return <div className="col-span-2">{children}</div>;
};

const TextFormatControl: React.FC = () => {
  const textDecoration = useEditorSelector(selectTextDecoration);
  const fontStyle = useEditorSelector(selectFontStyle);

  const { tipTapEditor, queueAction, clearQueuedActions } =
    useRichTextComponent();

  const applyComponentAction = useApplyComponentAction();

  const selectedValues = [textDecoration, fontStyle]
    .filter(isNotNullish)
    .map((value) => String(value));

  function onChange(newValue: string, isSelected: boolean) {
    let chain = tipTapEditor?.chain().selectAll();
    let action: UseApplyComponentActionType | undefined;
    if (newValue !== "italic") {
      let textDecoration;
      if (
        newValue === "line-through" &&
        textDecoration !== newValue &&
        isSelected
      ) {
        textDecoration = "line-through";
      } else if (
        newValue === "underline" &&
        textDecoration !== newValue &&
        isSelected
      ) {
        textDecoration = "underline";
      } else {
        textDecoration = "none";
      }
      action = {
        type: "setStyles",
        value: { textDecoration },
      };
      queueAction(action);
      chain = chain?.unsetStrike().unsetUnderline();
    } else if (newValue === "italic") {
      let fontStyle;
      if (fontStyle !== newValue && isSelected) {
        fontStyle = "italic";
      } else {
        fontStyle = "normal";
      }
      action = {
        type: "setStyles",
        value: { fontStyle },
      };
      queueAction(action);
      chain = chain?.unsetItalic();
    }
    const prevHtml = tipTapEditor?.getHTML();
    chain?.run();
    const postHtml = tipTapEditor?.getHTML();
    // if the html is the same, then we need to manually apply the changes
    if (prevHtml === postHtml && action) {
      applyComponentAction(action);
      clearQueuedActions();
    }
  }

  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  const options = [
    {
      value: "italic",
      label: <RiItalic size={16} />,
      tooltipContent: "Italic",
    },
    {
      value: "line-through",
      label: <RiStrikethrough size={16} />,
      tooltipContent: "Strikethrough",
    },
    {
      value: "underline",
      label: <RiUnderline size={16} />,
      tooltipContent: "Underline",
    },
  ];

  const handleOnChange = (values: string[]) => {
    if (selectedValues.length > values.length) {
      const [deselectValue] = differenceBy(selectedValues, values);
      // NOTE (Chance 2023-12-05): Non-null assertion is safe here because
      // we know checked that `selectedValues.length > values.length`, which
      // means it's not empty.
      onChange(deselectValue!, false);
    } else if (values.length > selectedValues.length) {
      // NOTE (Chance 2023-12-05): Non-null assertion is safe here because
      // we know checked that `values.length > selectedValues.length`, which
      // means it's not empty.
      const [selectValue] = differenceBy(values, selectedValues);
      onChange(selectValue!, true);
    }
  };

  return (
    <TextFormatControlWrapper>
      <ToggleGroup
        type="multi"
        style={{
          // TODO (Fran 2024-10-09 REPL-14029): Make the changes need it in the ToggleGroup component to use
          // w-full instead of this.
          width: "100%",
          height: !isNewRightBarEnabled ? 26 : undefined,
        }}
        value={selectedValues}
        options={options}
        onChange={handleOnChange}
      />
    </TextFormatControlWrapper>
  );
};

const TextTransformControlWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  if (isNewRightBarUIEnabled()) {
    return <ControlGroup label="Casing">{children}</ControlGroup>;
  }

  return <div className="col-span-2">{children}</div>;
};

const TextTransformControl: React.FC<React.PropsWithChildren> = () => {
  const applyComponentAction = useApplyComponentAction();
  const textTransform = useEditorSelector(selectTextTransform);

  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  const handleOnChange = (newValue: string) => {
    applyComponentAction({
      type: "setStyles",
      value: { textTransform: newValue },
    });
  };

  return (
    <TextTransformControlWrapper>
      <ToggleGroup
        type="single"
        style={{
          // TODO (Fran 2024-10-09 REPL-14029): Make the changes need it in the ToggleGroup component to use
          // w-full instead of this.
          width: "100%",
          height: !isNewRightBarEnabled ? 26 : undefined,
        }}
        value={textTransform ?? "none"}
        options={TEXT_TRANSFORM_OPTIONS}
        onChange={handleOnChange}
      />
    </TextTransformControlWrapper>
  );
};

const ForegroundColorControl: React.FC = () => {
  // NOTE (Fran 2024-04-15): Given the text color inheritance, we need to show if the component
  // has a color or if any ancestor text color will apply.
  const ancestorTextColor = useEditorSelector(selectAncestorTextColor);
  const color = useEditorSelector(selectColor);
  const colorGradientTilt = useEditorSelector(selectColorGradientTilt);
  const colorGradientStops = useEditorSelector(
    selectColorGradientStops,
  ) as GradientStop[];

  const applyComponentAction = useApplyComponentAction();

  // if there is a instance of the tipTapEditor being used by the rich text
  // control we must use the same one and queue our changes
  const { tipTapEditor, queueAction } = useRichTextComponent();

  const changeColor = (newValue: string | SolidOrGradient) => {
    let gradientOrSolid = null;
    if (typeof newValue === "string") {
      gradientOrSolid = newValue;
    } else if (newValue.type === "solid") {
      gradientOrSolid = newValue.color;
    } else {
      gradientOrSolid = newValue.gradient;
    }

    const newStyleValue =
      gradientOrSolid && typeof gradientOrSolid === "object"
        ? {
            color: "alchemy:gradient",
            __alchemyGradient__color__tilt: gradientOrSolid?.tilt,
            __alchemyGradient__color__stops: gradientOrSolid?.stops,
          }
        : { color: gradientOrSolid };
    const action: UseApplyComponentActionType = {
      type: "setStyles",
      value: newStyleValue,
    };
    // If all text in the editor already has a color then we've got to unset it
    // in order for the changes to have an effect.
    if (tipTapEditor && isAllTextColored(tipTapEditor)) {
      queueAction(action);
      tipTapEditor?.chain().selectAll().unsetColor().run();
    } else {
      applyComponentAction(action);
    }
  };

  const modifierValue = color ?? ancestorTextColor;

  const onRemove = () => {
    // NOTE (Fran 2024-05-09): If the color is not set we will show the inherited color from any
    // ancestor, so in this case, we should set the color to transparent. If the color is set, we
    // should remove it and show the inherited color.
    const onRemoveNewColor = color ? null : "#00000000";
    changeColor({
      type: "solid",
      color: onRemoveNewColor,
    });
  };

  return (
    <ControlGroup label="Color">
      <div className="flex gap-1 w-full">
        <DynamicColorModifier
          previewProperty="color"
          popoverTitle="Text Color"
          gradientSelectionType="color"
          gradientData={{
            tilt: coerceNumberToString(colorGradientTilt) ?? "90deg",
            stops: colorGradientStops ?? [
              {
                id: "c7795a8c-4e13-4011-889b-64adb0e11e41",
                color: "#df9393",
                location: "0%",
              },
            ],
          }}
          field="style.color"
          value={modifierValue ?? undefined}
          onChange={changeColor}
          onRemove={onRemove}
          popoverSideOffset={88}
        />
      </div>
    </ControlGroup>
  );
};

const TextOutlineControl: React.FC = () => {
  const store = useEditorStore();
  const applyComponentAction = useApplyComponentAction();
  const draftComponentTextOutline = useEditorSelector(selectTextOutline);
  const textOutline = draftComponentTextOutline
    ? getTextOutlineObject(draftComponentTextOutline)
    : null;

  const handleInputChange = (value: string, inputType: "width" | "color") => {
    let textOutlineString = null;

    if (value && value !== "0px") {
      const newTextOutline = {
        width: textOutline?.width || "1px",
        color: textOutline?.color || "#000000",
        [inputType]: value,
      };
      textOutlineString = getTextOutlineString(newTextOutline);
    }
    const componentId = selectDraftComponentId(store.getState());
    applyComponentAction({
      componentId,
      type: "setStyles",
      value: {
        __textStroke: textOutlineString,
      },
    });
  };

  return (
    <>
      <LengthInputSelector.Root
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        minDragValues={{ px: 0 }}
        minValues={{ px: 0 }}
        maxValues={{ px: 30 }}
        maxDragValues={{ px: 30 }}
        field="width"
        resetValue="0px"
        anchorValue="0px"
        value={textOutline?.width ?? null}
        onChange={(value: string) => handleInputChange(value, "width")}
        previewProperty="__textStroke"
        previewSubProperty="width"
        dragTrigger="label"
      >
        <div
          className="grid grid-cols-2 w-full gap-y-2 items-center"
          style={{
            gridTemplateColumns: "74px 1fr",
          }}
        >
          <LengthInputSelector.DraggableArea>
            <ModifierLabel label="Outline" />
          </LengthInputSelector.DraggableArea>
          <LengthInputSelector.Input
            placeholder="0px"
            startEnhancer={() => <BsBorderWidth />}
          />
          <div className="col-start-2">
            <SolidColorSelector
              popoverTitle="Color"
              value={textOutline?.color ?? null}
              onChange={(value: string) => handleInputChange(value, "color")}
              popoverSideOffset={88}
            />
          </div>
        </div>
      </LengthInputSelector.Root>
    </>
  );
};

const TextShadowPopover: React.FC<
  React.PropsWithChildren<{
    isOpen: boolean;
    activeTextShadow: TextShadow | undefined;
    textShadowIndex: number;
    handleTextShadowChange(value: TextShadow): void;
    onRequestClose(): void;
  }>
> = ({
  isOpen,
  activeTextShadow,
  handleTextShadowChange,
  textShadowIndex,
  onRequestClose,
}) => {
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);

  if (!activeTextShadow) {
    return null;
  }

  const handleInputChange = (
    value: string,
    inputType: "offsetX" | "offsetY" | "blur" | "color",
  ) => {
    if (!activeTextShadow) {
      return;
    }

    const newTextShadow = {
      ...activeTextShadow,
      [inputType]: value,
    };
    handleTextShadowChange(newTextShadow);
  };

  return (
    <Popover isOpen={isOpen}>
      <Popover.Content
        title="Text Shadow"
        shouldPreventDefaultOnInteractOutside={areModalsOpen}
        onRequestClose={onRequestClose}
      >
        <TextShadowPopoverContent
          offsetX={activeTextShadow.offsetX}
          offsetY={activeTextShadow.offsetY}
          blur={activeTextShadow.blur}
          color={activeTextShadow.color}
          textShadowIndex={textShadowIndex}
          handleInputChange={handleInputChange}
        />
      </Popover.Content>
      <Popover.Anchor className="relative top-0 left-0" />
    </Popover>
  );
};

const TextShadowControl: React.FC = () => {
  const draftComponentTextShadows = useEditorSelector(selectParsedTextShadows);

  const openPopoverId = useEditorSelector(selectOpenPopoverId);
  const dispatch = useEditorDispatch();

  const store = useEditorStore();
  const applyComponentAction = useApplyComponentAction();

  if (!draftComponentTextShadows) {
    return null;
  }

  const isPopoverOpen =
    openPopoverId &&
    typeof openPopoverId === "object" &&
    "text-shadow" in openPopoverId;

  const currentShadowIndex = isPopoverOpen
    ? openPopoverId["text-shadow"]
    : null;
  const currentShadow =
    currentShadowIndex != null
      ? draftComponentTextShadows[currentShadowIndex]
      : null;

  const createOrUpdateTextShadow = (value: string) => {
    const componentId = selectDraftComponentId(store.getState());
    applyComponentAction({
      componentId,
      type: "setStyles",
      value: {
        textShadow: value,
      },
    });
  };

  const handleRemoveTextShadow = (index: number) => {
    const filteredTextShadows = draftComponentTextShadows.filter(
      (_, shadowIndex) => shadowIndex !== index,
    );
    const updatedTextShadowes = getTextShadowString(filteredTextShadows);
    createOrUpdateTextShadow(updatedTextShadowes);
  };

  const handleTextShadowChange = (value: TextShadow) => {
    const newTextShadows = draftComponentTextShadows.map((textShadow) => {
      return textShadow.id === value.id ? value : textShadow;
    });
    const updatedTextShadows = getTextShadowString(newTextShadows);
    createOrUpdateTextShadow(updatedTextShadows);
  };

  return (
    <div
      className="grid grid-cols-2 w-full items-center gap-y-2"
      style={{
        gridTemplateColumns: "74px 1fr",
      }}
    >
      <ModifierLabel label="Shadow" />
      {draftComponentTextShadows.map((textShadow, index) => (
        <div className="col-start-2" key={textShadow.id}>
          <SelectionIndicator
            className="max-w-40"
            title={formatTitle(textShadow)}
            onClick={() => dispatch(setOpenPopoverId({ "text-shadow": index }))}
            startEnhancer={
              <BadgeV2
                type="color"
                isFilled
                backgroundColor={textShadow.color ?? "text-subtle"}
              />
            }
            endEnhancer={
              <BsX
                size={12}
                className="cursor-pointer text-slate-400"
                onClick={(event) => {
                  event.stopPropagation();
                  handleRemoveTextShadow(index);
                }}
              />
            }
          />
        </div>
      ))}
      {currentShadow && (
        <TextShadowPopover
          isOpen={isPopoverOpen ?? false}
          activeTextShadow={currentShadow}
          handleTextShadowChange={handleTextShadowChange}
          textShadowIndex={currentShadowIndex ?? 0}
          onRequestClose={() => dispatch(setOpenPopoverId(null))}
        />
      )}
    </div>
  );
};

const TextShadowPopoverContent: React.FC<{
  offsetX?: string;
  offsetY?: string;
  blur?: string;
  color?: string;
  textShadowIndex: number;
  handleInputChange(
    value: string,
    inputType: "offsetX" | "offsetY" | "blur" | "color",
  ): void;
}> = ({
  offsetX,
  offsetY,
  blur,
  color,
  textShadowIndex,
  handleInputChange,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <LengthInputSelector
        label={<ModifierLabel label="X" />}
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        field="offsetX"
        resetValue="0px"
        anchorValue="0px"
        placeholder="0px"
        value={offsetX || null}
        onChange={(value: string) => handleInputChange(value, "offsetX")}
        previewProperty="textShadow"
        previewSubProperty="offsetX"
        previewPropertyIndex={textShadowIndex}
        autofocus
        dragTrigger="label"
      />
      <LengthInputSelector
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        label={<ModifierLabel label="Y" />}
        field="offsetY"
        resetValue="0px"
        anchorValue="0px"
        placeholder="0px"
        value={offsetY || null}
        onChange={(value: string) => handleInputChange(value, "offsetY")}
        previewProperty="textShadow"
        previewSubProperty="offsetY"
        previewPropertyIndex={textShadowIndex}
        dragTrigger="label"
      />
      <LengthInputSelector
        label={<ModifierLabel label="Blur" />}
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        minDragValues={{ px: 0 }}
        minValues={{ px: 0 }}
        field="blur"
        resetValue="0px"
        anchorValue="0px"
        placeholder="0px"
        value={blur || null}
        onChange={(value: string) => handleInputChange(value, "blur")}
        previewProperty="textShadow"
        previewSubProperty="blur"
        previewPropertyIndex={textShadowIndex}
        dragTrigger="label"
      />
      <div className="flex items-center">
        <ModifierLabel label="Color" />
        <SolidColorSelector
          popoverTitle="Color"
          value={color || ""}
          onChange={(value: string) => handleInputChange(value, "color")}
          defaultValue="#00000040"
        />
      </div>
    </div>
  );
};

function useTextStyleHotkeys() {
  const store = useEditorStore();
  const dispatch = useEditorDispatch();
  const applyComponentAction = useApplyComponentAction();
  const { setTag, toggleBulletList, toggleNumberedList } =
    useRichTextComponent();
  const { isMenuOpen: isAIMenuOpen } = useAIStreaming();

  const handleToggleBoldText = React.useCallback(() => {
    const draftComponentType = selectDraftComponentType(store.getState());
    if (draftComponentType === "text") {
      const fontWeight = selectFontWeight(store.getState());
      applyComponentAction({
        type: "setStyles",
        value: {
          fontWeight: fontWeight === "700" ? null : "700",
        },
      });
    }
  }, [applyComponentAction, store]);

  const handleToggleItalicText = React.useCallback(() => {
    const draftComponentType = selectDraftComponentType(store.getState());
    const fontStyle = selectFontStyle(store.getState());

    if (draftComponentType === "text") {
      applyComponentAction({
        type: "setStyles",
        value: {
          fontStyle: fontStyle === "italic" ? null : "italic",
        },
      });
    }
  }, [applyComponentAction, store]);

  const handleToggleUnderlineText = React.useCallback(() => {
    const draftComponentType = selectDraftComponentType(store.getState());
    const textDecoration = selectTextDecoration(store.getState());

    if (draftComponentType === "text") {
      applyComponentAction({
        type: "setStyles",
        value: {
          textDecoration: textDecoration === "underline" ? null : "underline",
        },
      });
    }
  }, [applyComponentAction, store]);

  const handleToggleStrikethroughText = React.useCallback(() => {
    const draftComponentType = selectDraftComponentType(store.getState());
    const textDecoration = selectTextDecoration(store.getState());

    if (draftComponentType === "text") {
      applyComponentAction({
        type: "setStyles",
        value: {
          textDecoration: textDecoration ? null : "line-through",
        },
      });
    }
  }, [applyComponentAction, store]);

  const handleToggleLinkText = React.useCallback(() => {
    dispatch(setOpenPopoverId("tiptap-toolbar-link"));
  }, [dispatch]);

  const getFontSizeData = React.useCallback(() => {
    const fontSize = selectFontSize(store.getState());
    const { value, unit } = parseUnit(
      fontSize ?? 16,
      { value: "", unit: "" },
      "default",
      "px",
    );
    const newValue = typeof value === "string" ? Number.parseInt(value) : value;

    return {
      value: newValue,
      unit,
    };
  }, [store]);

  const adjustFontSize = React.useCallback(
    (type: "decrease" | "increase") => {
      const { value, unit } = getFontSizeData();

      let newFontSize;
      if (type === "decrease") {
        if (value === 1) {
          return;
        }
        newFontSize = Math.max(1, value - 1);
      } else {
        newFontSize = value + 1;
      }

      applyComponentAction({
        type: "setStyles",
        value: {
          fontSize: `${newFontSize}${unit}`,
        },
      });
    },
    [applyComponentAction, getFontSizeData],
  );

  const getLetterSpacingData = React.useCallback(() => {
    const letterSpacing = selectLetterSpacing(store.getState());
    const { value, unit } = parseUnit(
      letterSpacing ?? 0,
      { value: "", unit: "" },
      "default",
      "px",
    );
    const newValue = typeof value === "string" ? Number.parseInt(value) : value;

    return {
      value: newValue,
      unit,
    };
  }, [store]);

  const adjustLetterSpacing = React.useCallback(
    (type: "decrease" | "increase") => {
      const { value, unit } = getLetterSpacingData();

      let newLetterSpacing;
      if (type === "decrease") {
        if (value === 0) {
          return;
        } else if (value === 1) {
          newLetterSpacing = null;
        } else {
          newLetterSpacing = value - 1;
        }
      } else {
        newLetterSpacing = value + 1;
      }

      applyComponentAction({
        type: "setStyles",
        value: {
          letterSpacing:
            newLetterSpacing !== null
              ? `${newLetterSpacing}${unit}`
              : newLetterSpacing,
        },
      });
    },
    [applyComponentAction, getLetterSpacingData],
  );

  const getLineHeightData = React.useCallback(() => {
    const lineHeight = selectLineHeight(store.getState());
    const { value, unit } = parseUnit(
      lineHeight ?? 0,
      { value: "", unit: "" },
      "default",
      "px",
    );
    const newValue = typeof value === "string" ? Number.parseInt(value) : value;

    return {
      value: newValue,
      unit,
    };
  }, [store]);

  const adjustLineHeight = React.useCallback(
    (type: "decrease" | "increase") => {
      const { value, unit } = getLineHeightData();

      let newLineHeight;
      if (type === "decrease") {
        if (value === 0) {
          return;
        } else if (value === 1) {
          newLineHeight = null;
        } else {
          newLineHeight = value - 1;
        }
      } else {
        newLineHeight = value + 1;
      }

      applyComponentAction({
        type: "setStyles",
        value: {
          lineHeight:
            newLineHeight !== null ? `${newLineHeight}${unit}` : newLineHeight,
        },
      });
    },
    [applyComponentAction, getLineHeightData],
  );

  const adjustFontWeight = React.useCallback(
    (type: "decrease" | "increase") => {
      const fontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900, 950];
      const fontWeight = selectFontWeight(store.getState());
      const currentFontWeight = fontWeight ? Number(fontWeight) : 400;
      let currentIndex = fontWeights.indexOf(currentFontWeight);

      if (type === "decrease") {
        if (currentIndex > 0) {
          currentIndex -= 1;
        } else {
          return;
        }
      } else {
        if (currentIndex < fontWeights.length - 1) {
          currentIndex += 1;
        } else {
          return;
        }
      }

      const newFontWeight = fontWeights[currentIndex];

      applyComponentAction({
        type: "setStyles",
        value: {
          fontWeight: newFontWeight,
        },
      });
    },
    [applyComponentAction, store],
  );
  const enableNonDynamicTextEditing = useEnableNonDynamicTextEditing();
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);

  useReploHotkeys({
    toggleBoldText: handleToggleBoldText,
    toggleH1Text: () => setTag("1"),
    toggleH2Text: () => setTag("2"),
    toggleH3Text: () => setTag("3"),
    toggleBulletList: () => toggleBulletList(),
    toggleNumberedList: () => toggleNumberedList(),
    toggleLinkText: handleToggleLinkText,
    toggleItalicText: handleToggleItalicText,
    toggleUnderlineText: handleToggleUnderlineText,
    toggleStrikethroughText: handleToggleStrikethroughText,
    decreaseFontSize: () => adjustFontSize("decrease"),
    increaseFontSize: () => adjustFontSize("increase"),
    decreaseLetterSpacing: () => adjustLetterSpacing("decrease"),
    ...(!isAIMenuOpen && !areModalsOpen
      ? { editText: enableNonDynamicTextEditing }
      : {}),
    increaseLetterSpacing: () => adjustLetterSpacing("increase"),
    decreaseLineHeight: () => adjustLineHeight("decrease"),
    increaseLineHeight: () => adjustLineHeight("increase"),
    decreaseFontWeight: () => adjustFontWeight("decrease"),
    increaseFontWeight: () => adjustFontWeight("increase"),
  });
}

const useAddDefaultShadow = () => {
  const store = useEditorStore();
  const applyComponentAction = useApplyComponentAction();
  const dispatch = useEditorDispatch();

  return () => {
    const draftComponentTextShadow = selectTextShadow(store.getState());
    const draftComponentId = selectDraftComponentId(store.getState());
    const draftComponentTextShadows =
      draftComponentTextShadow?.split(",") ?? [];
    const textShadows =
      draftComponentTextShadows.length > 0
        ? parseTextShadows(draftComponentTextShadows)
        : [];
    const updatedTextShadows = getTextShadowString([
      ...textShadows,
      DEFAULT_TEXT_SHADOW,
    ]);

    const lastIndex = textShadows.length;
    dispatch(setOpenPopoverId({ "text-shadow": lastIndex }));

    applyComponentAction({
      componentId: draftComponentId,
      type: "setStyles",
      value: {
        textShadow: updatedTextShadows,
      },
    });
  };
};

export default TextStyleModifier;
