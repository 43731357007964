import * as React from "react";

import Tooltip from "@common/designSystem/Tooltip";
import { isTailwindClassName } from "@editor/utils/tailwind-classname";

import { Root, Thumb } from "@radix-ui/react-switch";
import classNames from "classnames";
import { animated, config, useSpring } from "react-spring";
import { useControllableState } from "replo-utils/react/use-controllable-state";

type SwitchProps = {
  label?: React.ReactNode;
  backgroundOffColor?: string;
  backgroundOnColor?: string;
  thumbColor?: string;
  isOn?: boolean;
  onChange?(isOn: boolean): void;
  className?: string;
  style?: React.CSSProperties;
  isDisabled?: boolean;
  tooltipText?: string | null;
  id?: string;
  "aria-label"?: string;
  "aria-labelledby"?: string;
  "aria-describedby"?: string;
};
const Switch = ({
  backgroundOffColor,
  backgroundOnColor,
  thumbColor,
  isOn,
  onChange,
  className,
  style,
  label,
  isDisabled,
  tooltipText,
  id,
  "aria-label": ariaLabel,
  "aria-labelledby": ariaLabelledBy,
  "aria-describedby": ariaDescribedBy,
  ...props
}: SwitchProps) => {
  const [isChecked, setIsChecked] = useControllableState(isOn, false, onChange);
  const styles = useSpring({
    config: config.stiff,
    to: {
      transform: (isOn !== undefined ? isOn : isChecked)
        ? "translateX(20px)"
        : "translateX(2px)",
    },
  });
  let backgroundStyleColor: string | undefined = undefined;
  if (
    backgroundOnColor &&
    isChecked &&
    !isTailwindClassName(backgroundOnColor, "bg")
  ) {
    backgroundStyleColor = backgroundOnColor;
  } else if (
    !isChecked &&
    backgroundOffColor &&
    !isTailwindClassName(backgroundOffColor, "bg")
  ) {
    backgroundStyleColor = backgroundOffColor;
  }
  const root = (
    <Root
      id={id}
      disabled={isDisabled ?? false}
      onCheckedChange={setIsChecked}
      checked={isChecked}
      className={classNames(
        "relative flex h-[18px] w-[36px] flex-row items-center rounded-full shrink-0",
        {
          "bg-slate-200": !isDisabled && !isChecked && !backgroundOffColor,
          "bg-slate-800": !isDisabled && isChecked && !backgroundOnColor,
          "bg-disabled cursor-not-allowed": isDisabled,
          "cursor-pointer": !isDisabled,
        },
        backgroundOffColor &&
          !isDisabled &&
          isTailwindClassName(backgroundOffColor, "bg") &&
          !isChecked
          ? backgroundOffColor
          : null,
        backgroundOnColor &&
          !isDisabled &&
          isTailwindClassName(backgroundOnColor, "bg") &&
          isChecked
          ? backgroundOnColor
          : null,
        className,
      )}
      style={{
        ...style,
        backgroundColor: backgroundStyleColor,
      }}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
    >
      <Thumb asChild>
        <animated.div style={styles}>
          <div
            {...props}
            className={classNames(
              "block h-[14px] w-[14px] rounded-full",
              {
                "bg-slate-50": !thumbColor,
              },
              thumbColor && isTailwindClassName(thumbColor, "bg")
                ? thumbColor
                : null,
            )}
            style={{
              backgroundColor:
                thumbColor && !isTailwindClassName(thumbColor, "bg")
                  ? thumbColor
                  : undefined,
            }}
          />
        </animated.div>
      </Thumb>
    </Root>
  );
  let switchElement = root;
  if (label) {
    switchElement = (
      <label className="flex w-full flex-row justify-between">
        <div className="text-slate-400">{label}</div>
        {root}
      </label>
    );
  }
  if (tooltipText) {
    return (
      <SwitchTooltip isDisabled={isDisabled} tooltipText={tooltipText}>
        {switchElement}
      </SwitchTooltip>
    );
  }
  return switchElement;
};

export default Switch;

function SwitchTooltip({
  children,
  isDisabled,
  tooltipText,
}: {
  children: React.ReactNode;
  isDisabled: boolean | undefined;
  tooltipText: string | null | undefined;
}) {
  if (!tooltipText) {
    return children;
  }
  return (
    <Tooltip content={tooltipText} triggerAsChild>
      <div
        // Note (Chance, 2023-09-16) Ensure that disabled buttons have a
        // focusable wrapper so that tooltip content can be accessible via
        // keyboard.
        tabIndex={isDisabled ? 0 : undefined}
      >
        {children}
      </div>
    </Tooltip>
  );
}
