import type { Component } from "replo-runtime/shared/Component";
import type { ReploSymbol, ReploVariant } from "schemas/generated/symbol";

import get from "lodash-es/get";
import omit from "lodash-es/omit";
import { findDefault, findVariant } from "replo-runtime/shared/variant";
import { getBuiltInSymbol } from "replo-runtime/store/builtInSymbolDirectory";
import { deepCloneAndMergeReplacingArrays } from "replo-utils/lib/misc";

export function resolveVariants(args: {
  componentType: Component["type"];
  componentVariants: ReploVariant[] | null | undefined;
  symbolVariants: ReploVariant[] | null | undefined;
}) {
  const { symbolVariants, componentType, componentVariants } = args;
  return (
    symbolVariants ||
    componentVariants ||
    getBuiltInSymbol(componentType)?.variants ||
    []
  );
}

/**
 * Apply any of the component's variantOverrides to the symbol's variants and
 * return the newly overridden variants.
 */
export const applyVariantOverrides = (
  variants: ReploVariant[],
  variantOverrides: Component["variantOverrides"],
): ReploVariant[] => {
  if (!variantOverrides) {
    return variants;
  }
  return variants.map((variant) => {
    const overrides = variantOverrides?.[variant.id];
    if (!overrides) {
      return variant;
    }
    return deepCloneAndMergeReplacingArrays(variant, overrides);
  });
};

/**
 * Return a component which has been appropriately overridden as an instance of
 * the given symbol, assuming the activeVariantId is active. The resulting component
 * from this function is certified GOOD TO RENDER
 */
export const applySymbolOverrides = (
  component: Component,
  symbol: ReploSymbol | null,
  activeVariantId: string | null,
  ancestorComponentOverrides: Record<string, any> | null,
): {
  component: Component;
  variantAndSymbolOverrides: Record<string, Object>;
} => {
  // First, update the variants with any variant overrides from the symbol instance
  // (aka, the component with type: "symbolRef"
  const variants = applyVariantOverrides(
    resolveVariants({
      componentType: component.type,
      componentVariants: component.variants,
      symbolVariants: symbol?.variants,
    }),
    component.variantOverrides,
  );

  const defaultVariant = findDefault(variants);
  const activeVariant = activeVariantId
    ? findVariant(variants, activeVariantId)
    : defaultVariant;

  // Get props which are overridden from the symbol DEFINITION for this variant
  const symbolComponentOverrides = get(
    activeVariant,
    `componentOverrides.${symbol?.component.id}`,
  );

  // Get props which are overridden from the default variant of this component
  const defaultVariantComponentOverrides = get(
    defaultVariant,
    `componentOverrides.${component.id}`,
  );

  // Get props which are overridden from the symbol INSTANCE for this variant
  const thisComponentOverrides = get(
    activeVariant,
    `componentOverrides.${component.id}`,
  );

  // Merge the props together. In priority order lowest to highest, take the
  // symbol's default props, then the symbol's variant's overrides, then the
  // instance's default variant overrides, then the active variant's overrides
  const mergedWithSymbolDefinition =
    symbol || symbolComponentOverrides
      ? deepCloneAndMergeReplacingArrays(
          component,
          symbol ? omit(symbol.component, "id") : {},
          symbolComponentOverrides ?? {},
        )
      : component;

  // Apply any overrides which might have been specified by the Alchemy Variant
  // of a parent (e.g. if we had a parent with a hover state, and in the hover
  // state the user indicated that they wanted its child to have a different color)
  // Note (Noah, 2021-10-01): We merge replacing arrays to make sure that
  // if array props are overridden, they will be replaced instead of merged
  const parentOverridesForThisComponent = ancestorComponentOverrides
    ? ancestorComponentOverrides?.[mergedWithSymbolDefinition.id]
    : null;

  let componentWithMergedOverrides = mergedWithSymbolDefinition;

  if (
    defaultVariantComponentOverrides ||
    parentOverridesForThisComponent ||
    thisComponentOverrides
  ) {
    componentWithMergedOverrides = deepCloneAndMergeReplacingArrays(
      mergedWithSymbolDefinition,
      defaultVariantComponentOverrides ?? {},
      parentOverridesForThisComponent ?? {},
      thisComponentOverrides ?? {},
    );
  }

  const variantAndSymbolOverrides = deepCloneAndMergeReplacingArrays(
    {},
    defaultVariant?.componentOverrides || {},
    activeVariant?.componentOverrides || {},
  );

  return { component: componentWithMergedOverrides, variantAndSymbolOverrides };
};

/**
 * Get a symbol from a symbol reference in the component.
 */
export const getSymbol = (
  symbolId: string,
  symbols: Record<string, ReploSymbol>,
): ReploSymbol | null => {
  if (!symbolId) {
    return null;
  }

  return get(symbols, symbolId, null);
};

export const resolveSymbolRef = (
  symbolRefComponent: Component,
  symbols: Record<string, ReploSymbol> | null,
) => {
  if (symbolRefComponent.type !== "symbolRef") {
    return symbolRefComponent;
  }
  if (!symbolRefComponent.symbolId) {
    return null;
  }
  const symbol = get(symbols, symbolRefComponent.symbolId, null);
  if (!symbol) {
    return null;
  }
  const variants = applyVariantOverrides(
    resolveVariants({
      componentType: symbolRefComponent.type,
      componentVariants: symbolRefComponent.variants,
      symbolVariants: symbol?.variants,
    }),
    symbolRefComponent.variantOverrides,
  );

  const { component: resolved } = applySymbolOverrides(
    symbolRefComponent,
    symbol,
    findDefault(variants).id,
    null,
  );
  return resolved;
};
