import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    url: {
      type: "string",
      name: "TikTok URL",
      description: "URL of the TikTok post to embed.",
      defaultValue: "",
    },
  };
}

export const customProperties = {
  display: "--rdi",
};

export const styleElements = {
  root: {},
  inner: {
    defaultStyles: {
      display: `var(${customProperties.display}, none)`,
      minWidth: "390px",
      width: "100%",
      height: "auto",
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
