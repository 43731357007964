import type { DataTable } from "../../shared/DataTable";
import type { ProductRef } from "../../shared/types";
import type { Context } from "../AlchemyVariable";
import type { ProductResolutionDependencies } from "../ReploProduct";

import { exhaustiveSwitch } from "replo-utils/lib/misc";

import {
  getRowObjectsFromDataTable,
  preloadAllImagesFromDataTable,
} from "../AlchemyDataTable";
import { resolveDynamicDataString } from "../AlchemyVariable";
import { getProduct } from "../ReploProduct";

// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum ItemsConfigType {
  dataTable = "dataTable",
  productImages = "productImages",
  inline = "inline",
  htmlAttributes = "htmlAttributes",
}

export type DataTablesItemsConfig = {
  type: ItemsConfigType.dataTable;
  id?: string | null;
};
export type ProductImagesConfig = {
  type: ItemsConfigType.productImages;
  productRef?: ProductRef;
};

type InlineItemsValues =
  | {
      valueType: "string";
      values: { id: string; value: string | undefined }[] | null;
    }
  | {
      valueType: "dynamic";
      dynamicPath: string;
    };

export type InlineItemsConfig = {
  type: ItemsConfigType.inline;
} & InlineItemsValues;

type HTMLAttributeTypes = "dataset";

export type HTMLAttributesValues = {
  id: string;
  valueType: HTMLAttributeTypes;
  key: string | undefined;
  value: string | undefined;
};

export type HTMLAttributesConfig = {
  type: ItemsConfigType.htmlAttributes;
  values: HTMLAttributesValues[];
};

export type ItemsConfig =
  | DataTablesItemsConfig
  | ProductImagesConfig
  | InlineItemsConfig
  | HTMLAttributesConfig;

export const itemTypeToRenderData = (itemType: ItemsConfigType) => {
  return exhaustiveSwitch({ type: itemType })({
    dataTable: {
      displayName: "Data Collection",
    },
    productImages: {
      displayName: "Product Images",
    },
    inline: {
      displayName: "Specific Values",
    },
    htmlAttributes: {
      displayName: "HTML Attributes",
    },
  });
};

export const itemsConfigToRenderData = (
  config: ItemsConfig,
): {
  getValues: (config: {
    dataTables: Record<string, DataTable>;
    context?: Context;
    productDependencies: ProductResolutionDependencies;
  }) => any[];
} => {
  return exhaustiveSwitch(config)({
    dataTable: (config) => ({
      getValues: ({ dataTables, productDependencies }) => {
        if (!config || !config.id) {
          return [];
        }
        preloadAllImagesFromDataTable(config.id, dataTables);
        return (
          getRowObjectsFromDataTable(
            config.id,
            dataTables,
            productDependencies,
          ) ?? []
        );
      },
    }),
    productImages: (config) => ({
      getValues: ({ context, productDependencies }) => {
        const {
          products,
          currencyCode,
          language,
          moneyFormat,
          fakeProducts,
          templateProduct,
        } = productDependencies;
        const product = getProduct(
          config?.productRef ?? null,
          context ?? null,
          {
            productMetafieldValues: {},
            variantMetafieldValues: {},
            products,
            currencyCode,
            moneyFormat,
            fakeProducts,
            language,
            templateProduct,
          },
        );
        return product?.images ?? [];
      },
    }),
    // @ts-expect-error
    inline: (config) => ({
      getValues: ({ context }) => {
        if (config.valueType === "dynamic") {
          return resolveDynamicDataString(config.dynamicPath, "", context);
        }
        return config.values ?? [];
      },
    }),
  });
};

export const getItemObjectsForRender = (
  itemsConfig: ItemsConfig,
  dataTables: Record<string, DataTable>,
  context: Context,
  productDependencies: ProductResolutionDependencies,
): unknown[] | null => {
  if (!itemsConfig?.type) {
    return null;
  }
  return itemsConfigToRenderData(itemsConfig).getValues({
    dataTables,
    context,
    productDependencies,
  });
};

export const isItemsDynamic = (items: ItemsConfig | null | undefined) => {
  return Boolean(
    items &&
      exhaustiveSwitch(items)({
        [ItemsConfigType.htmlAttributes]: ({ values }) => values.length > 0,
        [ItemsConfigType.dataTable]: ({ id }) => Boolean(id),
        [ItemsConfigType.inline]: (inlineItems) => {
          return !(
            inlineItems.valueType === "string" &&
            inlineItems.values?.length === 0
          );
        },
        [ItemsConfigType.productImages]: ({ productRef }) =>
          Boolean(productRef),
      }),
  );
};
