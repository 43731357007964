import type {
  AnalyticsReadQuery,
  MetricName,
  RangeResult,
} from "schemas/generated/analyticsRead";

import * as React from "react";

import MetricWithDelta from "@editor/components/analytics/MetricWithDelta";
import { SimpleSkeletonLoader } from "@editor/components/common/designSystem/SkeletonLoader";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { calculateDelta } from "@editor/utils/analytics";
import { routes } from "@editor/utils/router";

import AnalyticsFilters from "@/features/analytics/AnalyitcsFilters";
import { getLineChartDataFromAnalyticsRead } from "@/features/analytics/chart";
import {
  DEFAULT_ANALYTICS_CHART_CONFIG,
  DETAILS_PAGE_CARD_METRICS,
  METRICS_REQUIRING_CURRENCY,
  METRICS_REQUIRING_PERCENTAGE,
} from "@/features/analytics/constants";
import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import AnalyticsLayout from "@/features/analytics/Layout";
import AnalyticsQueryIntervalCombobox from "@/features/analytics/selects/AnalyticsQueryIntervalCombobox";
import useAnalyticsRead from "@/features/analytics/useAnalyticsRead";
import useWorkspaceUrlHosts from "@/features/analytics/useWorkspaceUrlHosts";
import ReploLineChart from "@replo/design-system/components/shadcn/LineChart";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { hours } from "replo-utils/lib/datetime";
import { formatWithCommasAndTwoDecimals } from "replo-utils/lib/math";
import { ConditionOperatorEnum } from "schemas/analyticsRead";

import { getRangeInDays } from "./time";

const PageDetails: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();
  const shopifyURLOptions = useWorkspaceUrlHosts(workspaceId);
  const { pageUrlPath = "", host = "" } = useParams<{
    pageUrlPath: string;
    host: string;
  }>();
  const decodedPageUrlPath = decodeURIComponent(pageUrlPath);
  const decodedHost = decodeURIComponent(host);
  const navigate = useNavigate();

  return (
    <AnalyticsLayout
      headerTitle="Deep Dive"
      backNavigation={{
        label: "Back to Overview",
        onClick: () => {
          navigate(
            generatePath(routes.analytics.overview, {
              workspaceId,
            }),
          );
        },
      }}
    >
      {shopifyURLOptions.isLoading ? (
        <SimpleSkeletonLoader height="300" width="100%" />
      ) : (
        <LoadedPageDetails
          workspaceId={workspaceId ?? ""}
          pageUrlPath={decodedPageUrlPath}
          host={decodedHost}
        />
      )}
    </AnalyticsLayout>
  );
};

type LoadedPageDetailsProps = {
  workspaceId: string;
  pageUrlPath: string;
  host: string;
};

const LoadedPageDetails: React.FC<LoadedPageDetailsProps> = ({
  workspaceId,
  pageUrlPath,
  host,
}) => {
  const { query: generalQuery, workspaceUrlHosts } = useAnalyticsQueryContext();

  const pageQuery = React.useMemo<AnalyticsReadQuery>(
    () => ({
      ...generalQuery,
      filters: {
        ...generalQuery.filters,
        urlPath: [
          {
            operator: ConditionOperatorEnum.EQUALS,
            value: [pageUrlPath],
          },
        ],
      },
      urlHosts: [host],
    }),
    [generalQuery, pageUrlPath, host],
  );

  const [currentInterval, setCurrentInterval] = React.useState(hours(1));

  const mainRangeQuery = pageQuery.ranges.mainRange;
  // NOTE (Max, 2024-09-10): We take the 1st element as in the All Metrics Page, we only allow
  // compareAt with 1 other range.
  const compareAtRangesQuery = pageQuery.ranges.compareAtRanges[0];

  const rangeInDays = React.useMemo(() => {
    return getRangeInDays(mainRangeQuery);
  }, [mainRangeQuery]);

  const { rangeResults, isLoading } = useAnalyticsRead(pageQuery, workspaceId);

  const filterRangeResults = React.useCallback(
    (
      rangeResults: RangeResult[] | null | undefined,
      rangeId: string | undefined,
      pageName: string,
    ) => {
      return (
        rangeResults?.filter(
          (rangeResultObj) =>
            rangeResultObj.rangeId === rangeId &&
            rangeResultObj.urlPath === pageName,
        ) ?? []
      );
    },
    [],
  );

  const mainRangeResults = React.useMemo(
    () => filterRangeResults(rangeResults, mainRangeQuery.id, pageUrlPath),
    [rangeResults, mainRangeQuery.id, pageUrlPath, filterRangeResults],
  );

  const compareAtRangeResults = React.useMemo(
    () =>
      filterRangeResults(rangeResults, compareAtRangesQuery?.id, pageUrlPath),
    [rangeResults, compareAtRangesQuery?.id, pageUrlPath, filterRangeResults],
  );

  const chartMetricQuery = React.useMemo(
    () => ({
      ...pageQuery,
      ranges: {
        ...pageQuery.ranges,
        compareAtRanges: [
          {
            ...pageQuery.ranges.compareAtRanges[0],
            id: pageQuery.ranges.compareAtRanges[0]?.id || crypto.randomUUID(),
            startDatetime:
              pageQuery.ranges.compareAtRanges[0]?.startDatetime ?? Date.now(),
            endDatetime:
              pageQuery.ranges.compareAtRanges[0]?.endDatetime ?? Date.now(),
            interval: currentInterval,
          },
        ],
        mainRange: {
          ...pageQuery.ranges.mainRange,
          interval: currentInterval,
        },
      },
    }),
    [pageQuery, currentInterval],
  );

  const { rangeResults: chartResults, isLoading: isChartResultsLoading } =
    useAnalyticsRead(chartMetricQuery, workspaceId);

  const chartMainRangeResults = React.useMemo(
    () => filterRangeResults(chartResults, mainRangeQuery.id, pageUrlPath),
    [chartResults, mainRangeQuery.id, pageUrlPath, filterRangeResults],
  );

  const chartCompareAtRangeResults = React.useMemo(
    () =>
      filterRangeResults(chartResults, compareAtRangesQuery?.id, pageUrlPath),
    [chartResults, compareAtRangesQuery?.id, pageUrlPath, filterRangeResults],
  );

  const chartMetrics = [
    {
      key: "conversion_rates",
      label: "Conversion Rate",
      tooltipValueFormatter: (value: string) =>
        `${formatWithCommasAndTwoDecimals(Number(value), true)}%`,
    },
    {
      key: "unique_sessions",
      label: "Total Sessions",
      tooltipValueFormatter: (value: string) => `${value} sessions`,
    },
    {
      key: "average_order_value",
      label: "Average Order Value",
      tooltipValueFormatter: (value: string) =>
        `$${formatWithCommasAndTwoDecimals(Number(value), true)}`,
    },
    {
      key: "revenue_per_session",
      label: "Revenue Per Session",
      tooltipValueFormatter: (value: string) =>
        `$${formatWithCommasAndTwoDecimals(Number(value), true)}`,
    },
  ];

  const getChartData = React.useCallback(
    (metricKey: string) => {
      // NOTE (Kurt, 2024-10-04): We use the first element of these arrays because each array contains results for a single range (main or compare).
      // The filterRangeResults function ensures that we only have results for the specific page and range we're interested in.
      // Therefore, there should only be one element in each array, representing the data for our current page and range.
      if (chartMainRangeResults[0] && chartCompareAtRangeResults[0]) {
        return {
          data: getLineChartDataFromAnalyticsRead(
            chartMainRangeResults[0],
            chartCompareAtRangeResults[0],
            chartMetricQuery,
            metricKey as MetricName,
          ),
          showPercentage: METRICS_REQUIRING_PERCENTAGE.includes(
            metricKey as MetricName,
          ),
          showCurrency: METRICS_REQUIRING_CURRENCY.includes(
            metricKey as MetricName,
          ),
        };
      }
      return null;
    },
    [chartMainRangeResults, chartCompareAtRangeResults, chartMetricQuery],
  );

  const chartData = chartMetrics.map((metric) => ({
    ...metric,
    ...getChartData(metric.key),
  }));

  const handleIntervalChange = (newInterval: number) => {
    setCurrentInterval(newInterval);
  };

  return (
    <div className="flex flex-col gap-4">
      <AnalyticsFilters />
      {isLoading && workspaceUrlHosts.isLoading ? (
        <SimpleSkeletonLoader height="200" width="100%" />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {DETAILS_PAGE_CARD_METRICS.map((metric, index) => {
            const metricData =
              mainRangeResults[0]?.metrics[metric.key as MetricName];
            const compareAtMetricData =
              compareAtRangeResults[0]?.metrics[metric.key as MetricName];
            const delta = calculateDelta(
              metricData?.[0] as number,
              compareAtMetricData?.[0] as number,
            );
            const doesCompareValueExist =
              ((compareAtMetricData?.[0] as number) ?? 0) > 0;

            return (
              <div
                key={index}
                className="border border-slate-300 rounded-xl p-6"
              >
                <div className="flex flex-col">
                  <div className="text-base text-slate-800 font-medium pb-2">
                    <div className="inline-block">{metric.label}</div>
                  </div>
                  <MetricWithDelta
                    name={metric.key}
                    value={metricData?.[0] as number}
                    delta={delta}
                    doesCompareValueExist={doesCompareValueExist}
                    wrapperClassName="justify-left"
                    valueClassName="text-2xl font-semibold min-w-[40px] text-slate-800"
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="flex flex-row w-full justify-end">
        <AnalyticsQueryIntervalCombobox
          currentInterval={currentInterval}
          rangeInDays={rangeInDays}
          onChange={handleIntervalChange}
        />
      </div>
      {isChartResultsLoading ? (
        <SimpleSkeletonLoader height="200" width="100%" />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {chartData.map((metric) => (
            <div key={metric.key} className="w-full h-[350px]">
              <ReploLineChart<string>
                title={`${metric.label}`}
                data={metric.data ?? []}
                config={{
                  ...DEFAULT_ANALYTICS_CHART_CONFIG,
                  xAxis: {
                    ...DEFAULT_ANALYTICS_CHART_CONFIG.xAxis,
                    // NOTE (Kurt, 2024-10-03): We reconstruct the config object here based on the current query state
                    // to format the x-axis labels differently based on the interval. This is necessary since we don't want
                    // to store any formatted data in the chart data object. This makes it necessary for us to use formatter
                    // functions that can be passed to the chart component. We conditionally construct the x-axis tick
                    // formatter based on the current interval and range in days.
                    tickFormatter: (value) => {
                      const date = new Date(value);
                      if (currentInterval === hours(1) && rangeInDays <= 2) {
                        return date.toISOString().slice(11, 16);
                      } else {
                        return date.toLocaleDateString("en-US", {
                          month: "numeric",
                          day: "2-digit",
                        });
                      }
                    },
                  },
                }}
                showTooltipCursor={true}
                tooltipKey={DEFAULT_ANALYTICS_CHART_CONFIG.xAxis.dataKey}
                tooltipValueFormatter={metric.tooltipValueFormatter}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PageDetails;
