import * as React from "react";

import IconButton from "@common/designSystem/IconButton";
import Input from "@common/designSystem/Input";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import useFigmaPluginPaste from "@editor/hooks/figmaToReplo/useFigmaPluginPaste";
import useCurrentDragType from "@editor/hooks/useCurrentDragType";
import useDynamicCSSOverflow from "@editor/hooks/useDynamicCSSOverflow";
import { useFindParentForPaste } from "@editor/hooks/useFindParentForPaste";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { isNewLeftBarUIEnabled } from "@editor/infra/featureFlags";
import { selectInternalDebugModeOn } from "@editor/reducers/core-reducer";
import { setLastMarketplacePath } from "@editor/reducers/marketplace-reducer";
import {
  selectLeftBarNavigationOpenedMenu,
  selectLeftBarWidth,
  setLeftBarNavigationOpenedMenu,
} from "@editor/reducers/ui-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { routes } from "@editor/utils/router";

import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import classNames from "classnames";
import { BiSearch } from "react-icons/bi";
import {
  BsBookmarkFill,
  BsCalendar3Fill,
  BsChevronRight,
  BsDistributeVertical,
  BsGrid1X2Fill,
  BsParagraph,
  BsPlayBtnFill,
  BsPuzzleFill,
  BsStarFill,
  BsTagFill,
  BsTerminalFill,
  BsToggleOn,
} from "react-icons/bs";
import { RiCloseFill } from "react-icons/ri";
import { Link } from "react-router-dom";

import {
  BaseComponentTemplates,
  StoreComponentTemplates,
} from "./BaseComponentTemplates";
import { debugPanelWidth, headerHeight } from "./editor/constants";

const ComponentTemplatePane: React.FC = () => {
  const leftBarWidth = useEditorSelector(selectLeftBarWidth);
  const { currentDragType } = useCurrentDragType();
  const openedMenu = useEditorSelector(selectLeftBarNavigationOpenedMenu);
  const [isVisible, setIsVisible] = React.useState(true);
  const { elementRef, overflowClassname } = useDynamicCSSOverflow();
  const [searchString, setSearchString] = React.useState("");
  const isDebugPanelVisible = useEditorSelector(selectInternalDebugModeOn);

  // NOTE (Fran 2024-07-09): We must prevent the user from opening different categories when moving
  // the mouse to the right diagonally. This is to prevent the user from accidentally opening a category
  // when trying to go to the menu content to drag a component.
  const [openState, setOpenState] = React.useState<
    "allowOpen" | "disallowOpen"
  >("allowOpen");
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const searchInputProps = useOverridableInput({
    onValueChange: (value) => {
      setSearchString(value);
    },
    value: searchString,
  });

  const analytics = useLogAnalytics();
  const dispatch = useEditorDispatch();

  // NOTE (Sebas, 2024-06-26): We need to hide the component template pane when
  // dragging a new component.
  React.useEffect(() => {
    if (currentDragType === "newComponent") {
      setIsVisible(false);
    } else {
      dispatch(setLeftBarNavigationOpenedMenu(""));
      setIsVisible(true);
    }
  }, [currentDragType, dispatch]);

  const handleOpenMenu = React.useCallback(
    (value: string) => {
      // NOTE (Fran 2024-07-09): We should open the menu when is allowed to open. This is to prevent the user
      // from accidentally opening a category when trying to go to the menu content to drag a component.
      if (openState === "allowOpen") {
        dispatch(setLeftBarNavigationOpenedMenu(value));
      }
    },
    [dispatch, openState],
  );

  const clearTimeoutRef = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Biome is wrong about this, react-hooks/exhaustive-deps doesn't complain
  const onMouseMove = React.useCallback((e: React.MouseEvent) => {
    if (e.movementX < 3) {
      clearTimeoutRef();
      setOpenState("allowOpen");
      // NOTE (Fran 2024-07-09): We need to detect if the user is moving the mouse to the right
      // to prevent the user from accidentally opening a category when trying to go to the
      // menu content to drag a component.
    } else if (e.movementX >= 3 && timeoutRef.current === null) {
      setOpenState("disallowOpen");
      timeoutRef.current = setTimeout(() => {
        setOpenState("allowOpen");
        timeoutRef.current = null;
      }, 1000);
    }
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Biome is wrong about this, react-hooks/exhaustive-deps doesn't complain
  React.useEffect(() => {
    // NOTE (Fran 2024-07-09): Clean up timeout when the component is unmounted.
    return () => {
      clearTimeoutRef();
    };
  }, []);

  const findParentForPaste = useFindParentForPaste();

  const { pasteFromFigmaOrToast } = useFigmaPluginPaste({
    findParentForPaste,
  });

  const isNewLeftBarEnabled = isNewLeftBarUIEnabled();

  return (
    <div className="no-scrollbar row-start-2 flex-1 flex flex-col gap-3">
      <div className="z-max sticky top-0 bg-white w-full">
        <Input
          {...searchInputProps}
          unsafe_className="pr-0"
          placeholder="Search Components"
          name="search-components"
          autoFocus={true}
          endEnhancer={() =>
            searchString.length > 0 ? (
              <IconButton
                type="tertiary"
                className="bg-clear hover:bg-slate-50"
                tooltipText="Clear Search"
                icon={<RiCloseFill size={12} />}
                onClick={() => setSearchString("")}
              />
            ) : (
              <BiSearch size={12} className="mr-2" />
            )
          }
          onOptionClick={() => setSearchString("")}
          autoComplete="off"
        />
      </div>
      {searchString ? (
        <div
          ref={elementRef}
          className={classNames(
            "h-full styled-scrollbar bg-white pb-2",
            overflowClassname,
          )}
          // NOTE (Juan/Sebas/Fran 2024-06-24): We need to calculate the height of the component template
          // pane based on the height of the left bar header and the editor header.
          style={{ height: `calc(100vh - ${headerHeight + 80}px)` }}
        >
          <BaseComponentTemplates searchString={searchString} />
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <p className="text-xs font-bold">Templates</p>
          <div className="grid grid-cols-2 gap-2">
            {marketplaceItems.map((item) => (
              <Link
                to={routes.marketplaceModal}
                state={{
                  ...(item.name === "sections" && {
                    elementType: "shopifySection",
                  }),
                  marketplaceModalRequestType: "browse",
                }}
                onClick={() => {
                  analytics("editor.marketplace.browse", {
                    from: "leftbar",
                  });
                  dispatch(setLastMarketplacePath(null));
                }}
                key={item.name}
                className={classNames(
                  "flex flex-col justify-center items-center gap-2 capitalize text-xs font-semibold  p-2 rounded border border-slate-200",
                  {
                    "hover:bg-hover": isNewLeftBarEnabled,
                    "hover:bg-slate-50": !isNewLeftBarEnabled,
                  },
                )}
              >
                <div className="bg-subtle rounded p-2">
                  <item.icon size={16} className="text-slate-700" />
                </div>
                {item.name}
              </Link>
            ))}
          </div>
          <p className="text-xs font-bold">Components</p>
          <NavigationMenu.Root
            value={openedMenu}
            onValueChange={handleOpenMenu}
            onMouseMove={onMouseMove}
          >
            <NavigationMenu.List>
              {categoryItems.map((item) => (
                <NavigationMenu.Item key={item.value} value={item.value}>
                  <NavigationMenu.Trigger
                    className={classNames(
                      "text-xs font-semibold flex w-full justify-between p-2 items-center rounded",
                      {
                        "bg-slate-50":
                          !isNewLeftBarEnabled && openedMenu === item.value,
                        "bg-selected":
                          isNewLeftBarEnabled && openedMenu === item.value,
                      },
                    )}
                    onClick={(e) => {
                      if (openedMenu === item.value) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <div className="bg-subtle rounded p-2">
                        <item.icon size={16} className="text-slate-700" />
                      </div>
                      {item.name}
                    </div>
                    <BsChevronRight className="text-slate-500" />
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content
                    id="component-template-pane-wrapper"
                    onPointerLeave={(e) => {
                      // NOTE (Fran 2024-07-01): We need to prevent the default behavior of the pointer leave event
                      // when the user is in the saved components, to avoid closing the content when the user right click
                      // to open the context menu to delete the saved component.
                      if (openedMenu === "store") {
                        e.preventDefault();
                      }
                    }}
                    ref={elementRef}
                    style={{
                      // NOTE (Fran 2024-06-24): We need to calculate the distance from the left edge of the
                      // component template pane based on the width of the left bar and the debug panel.
                      left: isDebugPanelVisible
                        ? leftBarWidth + debugPanelWidth
                        : leftBarWidth,
                      top: 0,
                    }}
                    className={classNames(
                      "absolute px-2 w-64 bg-white pt-2 pb-32 h-full",
                      overflowClassname,
                      {
                        invisible: !isVisible,
                        "no-scrollbar": !isNewLeftBarEnabled,
                        "rounded styled-scrollbar shadow-lg":
                          isNewLeftBarEnabled,
                      },
                    )}
                  >
                    {item.value === "store" ? (
                      <StoreComponentTemplates searchString={searchString} />
                    ) : (
                      <BaseComponentTemplates
                        searchString={searchString}
                        leftBarCategoryName={item.value}
                      />
                    )}
                  </NavigationMenu.Content>
                </NavigationMenu.Item>
              ))}
              <NavigationMenu.Item>
                <button
                  className={classNames(
                    "text-xs font-semibold flex w-full hover:bg-slate-50 justify-between p-2 items-center rounded mb-2",
                  )}
                  onClick={() => {
                    void pasteFromFigmaOrToast();
                  }}
                >
                  <div className="flex items-center gap-2">
                    <div className="bg-slate-100 rounded p-2">
                      <img
                        className="h-[16px] w-[16px]"
                        src="/images/figma/figma-logo.svg"
                        alt="Figma logo"
                      />
                    </div>
                    Import from Figma
                  </div>
                </button>
              </NavigationMenu.Item>
            </NavigationMenu.List>
            <NavigationMenu.Viewport />
          </NavigationMenu.Root>
        </div>
      )}
    </div>
  );
};

const marketplaceItems = [
  {
    name: "pages",
    icon: BsCalendar3Fill,
  },
  {
    name: "sections",
    icon: BsDistributeVertical,
  },
];

const categoryItems = [
  {
    name: "Basic",
    value: "basic",
    icon: BsParagraph,
  },
  {
    name: "Saved",
    value: "store",
    icon: BsBookmarkFill,
  },
  {
    name: "Layout",
    value: "layout",
    icon: BsGrid1X2Fill,
  },
  {
    name: "Interactive",
    value: "interactive",
    icon: BsToggleOn,
  },
  {
    name: "Product",
    value: "product",
    icon: BsTagFill,
  },
  {
    name: "Product Integrations",
    value: "productIntegrations",
    icon: BsStarFill,
  },
  {
    name: "Page Integrations",
    value: "pageIntegrations",
    icon: BsPuzzleFill,
  },
  {
    name: "Media",
    value: "media",
    icon: BsPlayBtnFill,
  },

  {
    name: "Custom Code",
    value: "customCode",
    icon: BsTerminalFill,
  },
];

export default ComponentTemplatePane;
