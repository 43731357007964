import * as React from "react";

import Tooltip from "@common/designSystem/Tooltip";
import Separator from "@editor/components/common/designSystem/Separator";
import Header from "@editor/components/dashboard/Header";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { trpc } from "@editor/utils/trpc";

import PageTypeSelect from "@/features/analytics/selects/PageTypeSelect";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import classNames from "classnames";
import { BsArrowLeft, BsBook } from "react-icons/bs";

import { METRIC_DEFINITIONS_URL } from "./constants";

interface AnalyticsLayoutProps {
  headerTitle?: string;
  children: React.ReactNode;
  backNavigation?: {
    label: string;
    onClick: () => void;
  };
}

const AnalyticsLayout: React.FC<AnalyticsLayoutProps> = ({
  headerTitle,
  children,
  backNavigation,
}) => {
  const analytics = useLogAnalytics();
  const workspaceId = useCurrentWorkspaceId();
  const { data: workspaceData, isPending: isLoadingWorkspace } =
    trpc.workspace.getById.useQuery(
      workspaceId ? { id: workspaceId } : reactQuerySkipToken,
    );
  const isLoadingRequiredData = isLoadingWorkspace;

  return (
    <div className="flex flex-col px-6 w-full relative overflow-hidden">
      <div
        className={classNames("flex", {
          "justify-between": backNavigation,
          "justify-end": !backNavigation,
        })}
      >
        {backNavigation && (
          <button
            className="flex items-center justify-center gap-1 font-medium text-sm text-blue-600"
            onClick={backNavigation.onClick}
          >
            <BsArrowLeft size={18} />
            {backNavigation.label}
          </button>
        )}
      </div>
      <div className="flex flex-row gap-4 justify-between">
        <Header
          title={headerTitle ?? `${workspaceData?.workspace?.name} Analytics`}
          isLoading={isLoadingRequiredData}
        />
        <div className="w-auto flex items-center justify-between gap-2  ">
          <PageTypeSelect />
          <Tooltip triggerAsChild content="Metric Definitions" delay={300}>
            <a
              href={METRIC_DEFINITIONS_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-end justify-center gap-[4px]"
              onClick={() => {
                analytics("analytics.definitions.open", {});
              }}
            >
              <div className="flex items-center justify-center bg-slate-100 hover:bg-blue-100 gap-[10px] h-8 w-8 rounded-[4px] group">
                <BsBook className="h-4 w-4 group-hover:text-blue-600" />
              </div>
            </a>
          </Tooltip>
        </div>
      </div>
      <Separator className="my-4 col-span-12" />
      {children}
    </div>
  );
};

export default AnalyticsLayout;
