import type { UnpublishableWarningType } from "@editor/components/header/unpublishableWarnings";
import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import type {
  PreviewElementProps,
  ReploElement,
} from "schemas/generated/element";

import * as React from "react";

import { createPreviewData } from "@actions/core-actions";
import InlinePopover from "@common/designSystem/InlinePopover";
import Spinner from "@common/designSystem/Spinner";
import { errorToast, successToast } from "@common/designSystem/Toast";
import { PreviewLinkAndSVGSkeleton } from "@components/editor/SkeletonLoaders";
import Modal from "@editor/components/common/designSystem/Modal";
import { unpublishableWarnings } from "@editor/components/header/unpublishableWarnings";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useGetStoreNameAndUrl from "@editor/hooks/useGetStoreNameAndUrl";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import usePreviewBeforePublishInfo from "@editor/hooks/usePreviewBeforePublishInfo";
import usePublishOnClickInfo from "@editor/hooks/usePublishingInfo";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { useStoreProductsFromDraftElement } from "@editor/hooks/useStoreProducts";
import { selectUnpublishableComponentIssues } from "@editor/reducers/core-reducer";
import { selectIsShopifyStoreClosed } from "@editor/reducers/ui-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import { Button, ButtonGroup } from "@replo/design-system/components/button";
import copy from "copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import { QRCode } from "react-qrcode-logo";
import { exhaustiveSwitch, isEmpty } from "replo-utils/lib/misc";

import IconButton from "../common/designSystem/IconButton";
import { ModalLayout } from "../common/ModalLayout";

type PublishPageButtonProps = {
  isPreviewBeforePublishView: boolean;
};

function shouldShowPreviewsForElementType(
  draftElementType: ReploElement["type"],
): boolean {
  return exhaustiveSwitch({ type: draftElementType })({
    page: true,
    shopifyArticle: false,
    shopifySection: true,
    shopifyProductTemplate: true,
  });
}

export const PublishPageButton: React.FC<PublishPageButtonProps> = ({
  isPreviewBeforePublishView,
}) => {
  const reduxStore = useEditorStore();
  const { storeName } = useGetStoreNameAndUrl();
  const projectId = useCurrentProjectId();
  const [productTemplateConfirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);
  const [warningsModalOpen, setWarningsModalOpen] = React.useState(false);
  const isStoreClosed = useEditorSelector(selectIsShopifyStoreClosed);
  const logEvent = useLogAnalytics();
  const setDraftElement = useSetDraftElement();
  const { products } = useStoreProductsFromDraftElement();
  const componentUnpublishableIssues = useEditorSelector((state) =>
    selectUnpublishableComponentIssues(state, products),
  );

  const onSelectComponentId = (componentId: string) => {
    setDraftElement({ componentId });
    setWarningsModalOpen(false);
  };

  const {
    publishNotAvailableReason,
    draftElementType,
    numAssignedProducts,
    publish,
    isPublishing,
    isTurbo,
    elementRecentlyPublished,
    pageEditorData,
  } = usePublishOnClickInfo();

  const publishOnClick = () => {
    if (publishNotAvailableReason) {
      errorToast(
        publishNotAvailableReason.title,
        publishNotAvailableReason.message,
      );
      return;
    }
    if (!isEmpty(componentUnpublishableIssues)) {
      setWarningsModalOpen(true);
    } else if (
      draftElementType === "shopifyProductTemplate" &&
      numAssignedProducts > 0
    ) {
      setConfirmationModalOpen(true);
    } else {
      publish();
    }
  };

  const showPreviewButton =
    isPreviewBeforePublishView &&
    shouldShowPreviewsForElementType(draftElementType);

  return (
    <>
      {showPreviewButton ? (
        <PublishWithPreviewButtons
          isStoreClosed={isStoreClosed}
          isPublishing={isPublishing}
          wasRecentlyPublished={elementRecentlyPublished}
          onPublish={() => {
            logEvent("header.publish", {
              buttonLocation: "header",
            });
            publishOnClick();
          }}
          onCreatePreviewData={() => {
            if (!projectId) {
              return;
            }
            return createPreviewData({
              state: reduxStore.getState(),
              projectId,
              storeName: storeName ?? "",
            });
          }}
        />
      ) : (
        <div className="flex flex-1">
          <Button
            fullWidth
            onClick={() => {
              if (isPreviewBeforePublishView) {
                logEvent("header.publish", {
                  buttonLocation: "preview",
                });
              }
              publishOnClick();
            }}
            /**
             * Note (Noah, 2022-12-18): We specifically don't want the button to show
             * as disabled while an update to the element is saving, because it's really
             * distracting if it changes between blue and gray all the time when you're
             * editing the page. Instead, we bail out early in onClick if that's the case
             */
            isDisabled={
              elementRecentlyPublished || isPublishing || isStoreClosed
            }
            variant={isPublishing ? "secondary" : "primary"}
            size="base"
            tooltipText={`Publish ${pageEditorData.singularDisplayName} to ${
              isTurbo ? "Turbo" : "Shopify"
            }`}
            isBusy={isPublishing}
            id="publish-button"
            data-testid="publish-button"
          >
            {elementRecentlyPublished
              ? "Published"
              : `Publish ${pageEditorData.singularDisplayName || ""} `}
          </Button>
        </div>
      )}
      <Modal
        isOpen={productTemplateConfirmationModalOpen}
        className="max-w-xl"
        onRequestClose={() => {
          setConfirmationModalOpen(false);
        }}
      >
        <ProductTemplateConfirmationModalContent
          numProducts={numAssignedProducts ?? 0}
          onCancel={() => {
            setConfirmationModalOpen(false);
          }}
          onConfirm={() => {
            publish();
            setConfirmationModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={warningsModalOpen}
        className="max-w-xl"
        onRequestClose={() => {
          setWarningsModalOpen(false);
        }}
      >
        <ModalLayout
          width="100%"
          mainContent={() => (
            <UnpublishableComponentsWarningModal
              onSelectComponentId={onSelectComponentId}
              onPublish={() => {
                publish();
                setWarningsModalOpen(false);
              }}
            />
          )}
        />
      </Modal>
    </>
  );
};

const ProductTemplateConfirmationModalContent = ({
  numProducts,
  onConfirm,
  onCancel,
}: {
  numProducts: number;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <div
      className="flex flex-col items-start rounded-lg bg-white p-3 text-default space-y-3"
      data-testid="product-template-publish-confirmation-modal"
    >
      <h1 className="text-lg font-medium text-default">
        Publish Product Template?
      </h1>
      <div className="text-sm">
        <div className="mb-2">
          After publishing, this Product Template will apply to the{" "}
          {numProducts > 1 ? numProducts : ""} assigned product
          {numProducts > 1 ? "s" : ""} on your live store.
        </div>
      </div>
      <div className="mt-20 flex flex-row items-center content-center flex-grow w-full justify-end">
        <div className="mt-4 flex gap-2 items-center">
          <Button
            variant="secondary"
            size="lg"
            htmlType="button"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="lg"
            htmlType="button"
            onClick={onConfirm}
          >
            Publish Product Template
          </Button>
        </div>
      </div>
    </div>
  );
};

const UnpublishableComponentsWarningModal = ({
  onSelectComponentId,
  onPublish,
}: {
  onSelectComponentId: (componentId: string) => void;
  onPublish: () => void;
}) => {
  const { products } = useStoreProductsFromDraftElement();
  const componentUnpublishableIssues = useEditorSelector((state) =>
    selectUnpublishableComponentIssues(state, products),
  );
  const processedIssueTypes = new Set();
  return (
    <div className="w-full flex flex-col items-start rounded-lg bg-white p-3 text-default gap-3">
      <h1 className="text-lg font-medium text-default">Publishing Errors</h1>
      {componentUnpublishableIssues.flatMap(
        (componentUnpublishableIssue, i) => {
          return componentUnpublishableIssue.issues.map((issue) => {
            if (processedIssueTypes.has(issue.type)) {
              return null;
            }
            processedIssueTypes.add(issue.type);
            const issueData =
              unpublishableWarnings[issue.type as UnpublishableWarningType];
            return (
              <div key={issue.type}>
                {i > 0 && <hr className="h-px my-2 bg-gray-200 border-0" />}
                <h2 className="text-base font-medium">{issueData?.title}</h2>
                <p className="mb-4">{issueData?.text}</p>
                <ul className="list-disc list-inside mb-4">
                  {componentUnpublishableIssues
                    .filter((component) =>
                      component.issues.some((iss) => iss.type === issue.type),
                    )
                    .map(({ componentId, componentData }) => (
                      <li key={componentId} className="mt-2 pl-4">
                        <button
                          onClick={() => onSelectComponentId(componentId)}
                          className="underline text-blue-600 hover:text-blue-800"
                        >
                          {componentData.label}
                        </button>
                      </li>
                    ))}
                </ul>
                {issueData?.solution && (
                  <p>
                    <b>Solution: </b>
                    {issueData?.solution}
                  </p>
                )}
              </div>
            );
          });
        },
      )}
      <div className="mt-20 flex flex-row items-center content-center flex-grow w-full justify-end bottom-0 sticky">
        <div className="mt-4 w-full">
          <Button
            variant="primary"
            size="lg"
            fullWidth
            htmlType="button"
            onClick={onPublish}
          >
            Publish Anyway
          </Button>
        </div>
      </div>
    </div>
  );
};

/**
 * NOTE (Chance 2023-12-24): Ideally this would be composed from a "ButtonGroup"
 * component in our design system, but that component doesn't exist. Previously
 * this was implemented as a single design system button with a nested button
 * that triggered a popover. This was incorrect because nesting buttons is
 * invalid HTML and we had to hack the onclick handler to prevent the popover
 * trigger from also triggering a publish. This implementation is a behavioral
 * improvement but it requires duplicating some styles from the button
 * component. We should get a proper reference design for a button group
 * component, then implement it in our DS.
 */
function PublishWithPreviewButtons({
  isStoreClosed,
  isPublishing,
  wasRecentlyPublished,
  onPublish,
  onCreatePreviewData,
  onCreatePreviewError,
  onCreatePreviewSuccess,
}: {
  isStoreClosed: boolean;
  isPublishing: boolean;
  wasRecentlyPublished: boolean;
  onPublish: () => void;
  onCreatePreviewData: () => PreviewElementProps | undefined;
  onCreatePreviewError?: (error: FetchBaseQueryError | SerializedError) => void;
  onCreatePreviewSuccess?: (data: { html: string }) => void;
}) {
  const [isPreviewPopoverOpen, setIsPreviewPopoverOpen] = React.useState(false);

  const logEvent = useLogAnalytics();

  const {
    mutate: createPreviewElement,
    isPending: isPreviewCreateLoading,
    isSuccess: isPreviewCreateSuccessful,
  } = trpc.element.createPreview.useMutation({
    onSuccess: (data) => {
      if (data.html) {
        onCreatePreviewSuccess?.({ html: data.html });
      }
    },
    onError: (error) => {
      onCreatePreviewError?.(error);
      handleCreatePreviewElementError(error);
    },
  });

  function onPopoverOpen(isPreviewPopoverOpen: boolean) {
    setIsPreviewPopoverOpen(isPreviewPopoverOpen);
    logEvent("preview.requested", {});
    const previewData = onCreatePreviewData();
    if (previewData) {
      createPreviewElement(previewData);
    }
  }

  function handleCreatePreviewElementError(error: {}) {
    setIsPreviewPopoverOpen(false);
    if ("status" in error && error.status === 404) {
      errorToast(
        "Page Not Found",
        "The page you are trying to preview does not exist. Maybe it was deleted by another user?",
      );
    } else {
      errorToast("Error", "Something went wrong. Please try again later.");
    }
  }

  const isPublishDisabled = wasRecentlyPublished || isPublishing;
  const showLoadingSpinner = isPublishing && !isPreviewPopoverOpen;

  return (
    <ButtonGroup variant="segmented" isDisabled={isStoreClosed}>
      <Button
        id="preview-publish-button"
        isDisabled={isPublishDisabled}
        isBusy={showLoadingSpinner}
        onClick={() => {
          if (!isPublishDisabled) {
            onPublish();
          }
        }}
      >
        {wasRecentlyPublished ? "Published" : "Publish"}
      </Button>
      <InlinePopover
        isOpen={isPreviewPopoverOpen}
        onOpenChange={onPopoverOpen}
        shouldPreventDefaultOnInteractOutside={false}
        content={
          <PublishPreview
            isPreviewCreateLoading={isPreviewCreateLoading}
            isPreviewCreateSuccessful={isPreviewCreateSuccessful}
          />
        }
        side="bottom"
        title="Preview Options"
        align="end"
        sideOffset={16}
        triggerAsChild
        skipTriggerStyles
      >
        <Button
          tooltipText="Preview Options"
          aria-label="Preview Options"
          isDisabled={showLoadingSpinner}
          data-testid="preview-popover-button"
          icon={<BsChevronDown aria-hidden size={12} />}
        />
      </InlinePopover>
    </ButtonGroup>
  );
}

type PublishPreviewProps = {
  isPreviewCreateLoading: boolean;
  isPreviewCreateSuccessful: boolean;
};

const PublishPreview: React.FC<PublishPreviewProps> = (props) => {
  const { isPreviewCreateLoading, isPreviewCreateSuccessful } = props;
  const logEvent = useLogAnalytics();
  const { url: previewUrl, previewUrlAvailable } =
    usePreviewBeforePublishInfo();

  const CopyIcon = () => {
    return (
      <div className="-mt-0.5">
        <IconButton
          tooltipText="Copy Preview Link"
          aria-label="Copy Preview Link"
          type="tertiary"
          icon={<BiCopy size={16} color="royalblue" />}
          onClick={() => {
            copy(previewUrl);
            successToast(
              "Preview URL copied",
              "You can now open the Preview in a new window.",
            );
            logEvent("preview.copied", {});
          }}
        />
      </div>
    );
  };

  return (
    <div className="grid justify-items-center">
      <div className="h-auto m-0 self-start pt-2">
        {!isPreviewCreateLoading &&
        isPreviewCreateSuccessful &&
        previewUrlAvailable ? (
          <QRCode
            value={previewUrl}
            qrStyle="dots"
            bgColor="black"
            fgColor="white"
            logoImage="/replo-logo-quartercircle-black-bg.svg"
            logoWidth={50}
            removeQrCodeBehindLogo={true}
            quietZone={5}
          />
        ) : (
          <PreviewLinkAndSVGSkeleton />
        )}
      </div>
      <div className="flex flex-row space-x-0.5 pl-2 pt-3 pb-1 h-10 content-center text-blue-600 text-sm">
        {!isPreviewCreateLoading &&
        isPreviewCreateSuccessful &&
        previewUrlAvailable ? (
          <>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={previewUrl}
              className="cursor-pointer"
            >
              View Preview in Browser
            </a>
            <CopyIcon />
          </>
        ) : (
          <>
            <Spinner className="mr-1 mt-0.5" type="primary" />
            <p>Loading Preview...</p>
          </>
        )}
      </div>
      <hr className="w-full h-1 mx-auto pb-3" />
      <div className="w-56">
        <PublishPageButton isPreviewBeforePublishView={false} />
      </div>
    </div>
  );
};
