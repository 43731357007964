import type { FlowStepConfigPropsValueOf } from "schemas/flow";

import * as React from "react";

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import Input from "@editor/components/common/designSystem/Input";
import SelectableButtons from "@editor/components/common/designSystem/SelectableButtons";
import { useGetCurrentStepResultsData } from "@editor/components/flows/hooks/useGetCurrentStepResultsData";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepsLayout from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";
import useCurrentUser from "@editor/hooks/useCurrentUser";

import { Controller, useForm, useWatch } from "react-hook-form";

type FormValues = {
  usingAs: string;
  workspaceName: string;
};

const AreYouUsingReploSoloOrWithATeam: React.FC = () => {
  const stepResultsData =
    useGetCurrentStepResultsData<"onboarding.user.are-you-using-solo-or-team">();
  const { user } = useCurrentUser();
  const { currentStep, submitStep } = useReploFlowsStepContext();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      usingAs: stepResultsData?.usingAs ?? "solo",
      workspaceName:
        stepResultsData?.workspaceName ?? `${user?.name}'s Workspace`,
    },
  });
  const { submitOrSkipStepCallback: submitStepCallback } =
    useRouterFlowCallbacks();

  const stepProps =
    currentStep?.props as FlowStepConfigPropsValueOf<"onboarding.user.are-you-using-solo-or-team">;

  const usingAsValue = useWatch({ control, name: "usingAs" });
  const workspaceNameValue = useWatch({ control, name: "usingAs" });
  const workspaceNameValueError = errors.workspaceName?.message;
  const shouldDisableNextButton =
    !usingAsValue || !workspaceNameValue || Boolean(errors.workspaceName);

  const imageUrl =
    usingAsValue === "solo"
      ? "/images/flows/person-logo.png"
      : "/images/flows/group-logo.png";

  const onSubmit = ({ usingAs, workspaceName }: FormValues) => {
    if (currentStep) {
      void submitStep(
        currentStep.id,
        currentStep.type,
        {
          usingAs,
          workspaceName,
        },
        ({ instance, nextStep }) => {
          submitStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          });
        },
      );
    }
  };

  const workspaceNamePlaceholder =
    usingAsValue === "solo"
      ? `${user?.name}'s Workspace`
      : "Name your team's workspace";

  const setWorkspaceHelperText = (usingAsValue: string) => {
    if (usingAsValue === "solo") {
      setValue("workspaceName", `${user?.name}'s Workspace`);
    } else {
      setValue("workspaceName", "");
    }
  };

  return (
    <OnboardingStepsLayout rightPanelContent={<StepImage src={imageUrl} />}>
      <div className="flex flex-col gap-14">
        <div>
          <StepTitle>
            Are you using Replo solo or with a{" "}
            <span className="whitespace-nowrap">
              team?
              <span className="text-red-600">*</span>
            </span>
          </StepTitle>
        </div>
        <form
          className="flex flex-col gap-8"
          onSubmit={(data) => {
            void handleSubmit(onSubmit)(data);
          }}
        >
          <Controller
            name="usingAs"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectableButtons
                multiSelect={false}
                options={stepProps.options ?? []}
                value={[value]}
                onChange={(value) => {
                  setWorkspaceHelperText(value[0] ?? "");
                  onChange(value[0] ?? "");
                }}
                className="my-4"
                textClassName="font-normal"
                unselectedClassName="border border-slate-200 bg-transparent text-default hover:border-blue-600 hover:bg-blue-200 hover:text-blue-600"
                selectedClassName="border border-blue-600 bg-blue-200 text-blue-600 hover:bg-blue-300"
              />
            )}
          />

          <div className="flex flex-col gap-1">
            <h3 className="text-default font-semibold">
              What do you want to name your workspace?
              <span className="text-red-600">*</span>
            </h3>
            <Controller
              name="workspaceName"
              control={control}
              rules={{
                required: "Please enter a workspace name.",
                pattern: {
                  value: /^[\d 'A-Za-z-]+$/,
                  message:
                    "Please enter only alphanumeric characters, whitespaces, apostrophes ( ' ), or hyphens ( - ).",
                },
              }}
              render={({ field: { value, onChange, name } }) => (
                <Input
                  type="text"
                  size="base"
                  autoFocus
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  name={name}
                  placeholder={workspaceNamePlaceholder}
                  validityState={
                    Boolean(workspaceNameValueError) ? "invalid" : "valid"
                  }
                  aria-invalid={
                    Boolean(workspaceNameValueError) ? "true" : undefined
                  }
                  aria-describedby={
                    Boolean(workspaceNameValueError)
                      ? "error-workspace-name"
                      : undefined
                  }
                />
              )}
            />
            <ErrorMessage
              id="error-workspace-name"
              error={workspaceNameValueError}
            />
          </div>
          <FlowActionButtons
            shouldDisableNextButton={shouldDisableNextButton}
          />
        </form>
      </div>
    </OnboardingStepsLayout>
  );
};

export default AreYouUsingReploSoloOrWithATeam;
