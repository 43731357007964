import type { SelectedValue } from "@editorComponents/Lists";

import * as React from "react";

import ElementNameEditor from "@components/editor/elementEditors/ElementNameEditor";
import { ShopifyThemeSettings } from "@components/editor/elementEditors/ShopifyThemeSettings";
import Input from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import { InfiniteProductSummaryList } from "@editor/components/editor/page/ProductSummaryList";
import { useElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import { useInitial } from "@editor/hooks/useInitial";

import { BsSearch } from "react-icons/bs";
import { RiCloseFill } from "react-icons/ri";

import { ElementEditorHeading } from "./ElementEditorHeading";
import { ElementSettingsSwitch } from "./ElementSettingsSwitch";

const ProductTemplateElementEditor: React.FC<{
  initialName?: string;
}> = ({ initialName }) => {
  const { element, onChangeElement } = useElementEditorDataContext();
  const initialElementIsPublished = useInitial(element.isPublished);
  const onChangeProductIds = (item: SelectedValue) => {
    const itemAsString = String(item);
    const newTemplateShopifyProductIds =
      element.templateShopifyProductIds?.includes(itemAsString)
        ? element.templateShopifyProductIds.filter((i) => i !== itemAsString)
        : [...(element.templateShopifyProductIds ?? []), itemAsString];
    onChangeElement({
      ...element,
      // Note (Evan, 2023-10-13): We sort this so we can reliably reset the template editor product to the first assigned product
      templateShopifyProductIds: newTemplateShopifyProductIds.sort(),
    });
  };

  return (
    <>
      <div className="my-4 mx-2 flex h-fit flex-col gap-y-2">
        <ElementNameEditor
          type="shopifyProductTemplate"
          inputName="page-name"
          initialName={initialName ?? element.name}
          onChange={(name) => onChangeElement({ ...element, name })}
        />
        <LabeledControl label="Template Path" isPhonyLabel>
          <Input
            isPhonyInput
            startEnhancer={() => (
              <div className="absolute inset-[6px] flex gap-1 flex-row w-full shrink-0">
                <div className="rounded text-xs h-6 cursor-not-allowed py-1 px-2 bg-slate-400 text-white">
                  products/
                </div>
                <div className="inline-flex rounded text-xs h-6 whitespace-nowrap cursor-not-allowed py-1 px-2 bg-slate-200 text-slate-400">
                  product-slug
                </div>
              </div>
            )}
            size="base"
          />
        </LabeledControl>
        {initialElementIsPublished && (
          <div className="mt-2 flex flex-col gap-4 pl-1">
            <ElementSettingsSwitch
              title="Is Published"
              description="If the Product Template is published it means your products will be displayed using this template."
              isOn={element.isPublished}
              onChange={(isPublished) =>
                onChangeElement({ ...element, isPublished })
              }
            />
          </div>
        )}
        <ProductSelector
          selectedIds={element.templateShopifyProductIds ?? []}
          onChange={onChangeProductIds}
        />
        <ShopifyThemeSettings className="mt-2" />
      </div>
    </>
  );
};

const ProductSelector: React.FC<{
  selectedIds: SelectedValue[];
  onChange: (item: SelectedValue) => void;
}> = ({ selectedIds, onChange }) => {
  const [searchTerm, setSearchTerm] = React.useState("");

  return (
    <div className="mt-2">
      <ElementEditorHeading className="text-base">
        Products Assigned to this Template
        {selectedIds.length > 0 ? ` (${selectedIds.length})` : ""}
      </ElementEditorHeading>
      <div className="my-2">
        <Input
          size="base"
          value={searchTerm}
          startEnhancer={() => <BsSearch size={10} />}
          endEnhancer={() =>
            searchTerm?.trim() && (
              <RiCloseFill
                size={12}
                className="cursor-pointer text-slate-400"
                onClick={() => setSearchTerm("")}
              />
            )
          }
          placeholder="Name or Product ID"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="min-h-[120px]">
        <InfiniteProductSummaryList
          selectedProductIds={selectedIds.map((id) => Number(id))}
          onSelect={onChange}
          isMultiselect
          searchTerm={searchTerm}
          itemSize={40}
          itemsOnViewCount={4}
        />
      </div>
    </div>
  );
};

export default ProductTemplateElementEditor;
