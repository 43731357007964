import * as React from "react";

import LabeledControl from "@common/designSystem/LabeledControl";
import Button from "@editor/components/common/designSystem/Button";
import Modal from "@editor/components/common/designSystem/Modal";

type DeleteConfirmationModalProps = {
  onDelete: () => void;
  onRequestClose?: () => void;
  isLoading?: boolean;
  assetName: string;
  assetTypeDisplayName: string;
  confirmationType?: "unpublish" | "delete";
  extraMessage?: React.ReactNode;
};

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  onDelete,
  onRequestClose,
  assetName,
  assetTypeDisplayName,
  isLoading,
  confirmationType = "delete",
  extraMessage,
}) => {
  const title =
    confirmationType === "unpublish"
      ? `Are you sure you want to unpublish this ${assetTypeDisplayName}?`
      : `Are you sure you want to delete this ${assetTypeDisplayName}?`;
  const subtitle =
    confirmationType === "unpublish"
      ? `Unpublishing this ${assetTypeDisplayName} will remove it from all pages or templates where it has been added in the Shopify Theme Customizer.`
      : `By deleting this ${assetTypeDisplayName} you will remove it and all its
  contents from your account permanently.`;

  return (
    <Modal
      isOpen={true}
      onRequestClose={onRequestClose}
      closesOnOverlayClick={Boolean(onRequestClose)}
      className="w-[490px]"
      onEnterKeyPress={onDelete}
    >
      <div className="flex flex-col gap-y-2">
        <h1 className="text-lg font-medium text-default">{title}</h1>
        <LabeledControl label={`${assetTypeDisplayName} Name`}>
          <p className="text-sm text-default font-bold">{assetName}</p>
        </LabeledControl>
        <p className="text-sm text-slate-400">
          {subtitle} {extraMessage}
        </p>
      </div>
      <div className="mt-20 flex justify-end">
        <Button
          type="danger"
          size="lg"
          onClick={onDelete}
          isLoading={isLoading}
          data-testid="delete-confirmation-button"
        >
          {confirmationType === "delete"
            ? `Delete ${assetTypeDisplayName}`
            : "I Understand"}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
