import * as React from "react";

import { Button } from "@common/designSystem/Button";
import { Loader } from "@editor/components/common/Loader";
import { ConnectShopifyIntegrationCta } from "@editor/components/editor/page/ConnectShopifyCallout";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useModal } from "@editor/hooks/useModal";

export const ProjectIntegrationSettingsTab = () => {
  const projectId = useCurrentProjectId();
  if (!projectId) {
    return <Loader />;
  }
  return <InnerProjectIntegrationsTab />;
};

const InnerProjectIntegrationsTab = () => {
  const { project } = useCurrentProjectContext();
  const { openModal } = useModal();
  if (project) {
    const shopifyIntegration = project.integrations?.shopify?.store;
    return (
      <div className="space-y-6">
        <div className="font-semibold items-center text-sm space-y-6">
          Shopify Integration
        </div>
        {shopifyIntegration ? (
          <>
            <p className="text-sm text-slate-500 mb-3 max-w-xl">
              All pages on this project will be published to the following
              Shopify store:
            </p>
            <div className="flex justify-between items-center max-w-md">
              <p className="text-xs font-medium">
                {shopifyIntegration.shopifyUrl}
              </p>
              <Button
                type="secondary"
                size="base"
                textClassNames="text-xs"
                htmlType="submit"
                onClick={() =>
                  openModal({
                    type: "removeShopifyIntegrationModal",
                    props: {
                      projectId: project.id,
                      integrationId: shopifyIntegration.integrationId!,
                    },
                  })
                }
              >
                Disconnect
              </Button>
            </div>
          </>
        ) : (
          <>
            <p className="text-sm text-slate-500 mb-3 max-w-xl">
              This project does not have a connected Shopify integration.
            </p>
            <div className="max-w-[400px]">
              <ConnectShopifyIntegrationCta type="projectSettings" />
            </div>
          </>
        )}
      </div>
    );
  }
};
