import { getStoreData } from "@editor/utils/project-utils";
import { trpc } from "@editor/utils/trpc";
import useCurrentUser from "@hooks/useCurrentUser";

import { skipToken } from "@tanstack/react-query";

export function useUserHasConnectedStore() {
  const {
    isLoading: isLoadingUser,
    user,
    isAuthenticated,
    isError,
  } = useCurrentUser();

  const { data, isLoading: isLoadingAllProjects } =
    trpc.project.findByUserId.useQuery(
      isAuthenticated && user?.id ? user.id : skipToken,
    );

  if (isLoadingUser || isLoadingAllProjects || isError) {
    return {
      userHasConnectedStore: false,
      isLoading: isLoadingUser || isLoadingAllProjects,
      connectedStoreId: null,
    };
  }
  if (!isAuthenticated) {
    return {
      userHasConnectedStore: false,
      isLoading: isLoadingUser || isLoadingAllProjects,
      connectedStoreId: null,
    };
  }

  if (data?.allProjects) {
    const connectedStore = data?.allProjects.find(
      (project) => getStoreData(project)?.shopifyUrl,
    );

    return {
      userHasConnectedStore: Boolean(connectedStore),
      isLoading: isLoadingUser || isLoadingAllProjects,
      connectedStoreId: connectedStore?.id ?? null,
    };
  }

  return {
    userHasConnectedStore: false,
    isLoading: isLoadingUser || isLoadingAllProjects,
    connectedStoreId: null,
  };
}
