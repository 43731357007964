import * as React from "react";

import DraftComponentPaneHeader from "@editor/components/DraftComponentPaneHeader";
import { configMenuMap } from "@editor/components/editor/page/element-editor/components/config-menus/configMap";
import TourStepTrigger from "@editor/components/flows/TourStepTrigger";
import {
  selectDraftComponentHasVariants,
  selectDraftComponentType,
  selectNearestAncestorHasVariants,
} from "@editor/reducers/core-reducer";
import { selectRightBarActiveTab } from "@editor/reducers/ui-reducer";
import { useEditorSelector } from "@editor/store";
import DesignTab from "@editorComponents/DesignTab";
import InteractionsTab from "@editorComponents/InteractionsTab";
import TabOptions from "@editorComponents/TabOptions";
import CustomPropModifier from "@editorModifiers/CustomPropModifier";
import VariantModifier from "@editorModifiers/VariantModifier";

const DraftComponentPane: React.FC = () => {
  const currentTab = useEditorSelector(selectRightBarActiveTab);
  const draftComponentType = useEditorSelector(selectDraftComponentType);

  const hasVariants = useEditorSelector(selectDraftComponentHasVariants);
  const hasParentVariants = useEditorSelector(selectNearestAncestorHasVariants);
  const shouldShowVariantModifier =
    currentTab === "design" || hasVariants || hasParentVariants;

  if (!draftComponentType) {
    return null;
  }
  const ConfigMenu =
    configMenuMap[draftComponentType]?.config ?? CustomPropModifier;
  const AccessibilityMenu = configMenuMap[draftComponentType]?.accessibility;

  return (
    <div className="max-w-[250px] px-2 pt-2 pb-32 text-default">
      {/* Note (Juan, 2024-03-20): pb-32 is needed to improve rightBar visibility when most modifiers are open */}
      <div className="flex flex-col gap-2">
        <div className="z-10 sticky top-0 flex flex-col gap-1 bg-white pb-2">
          <DraftComponentPaneHeader />
          <TourStepTrigger step="step-3">
            <TabOptions currentTab={currentTab} containerClassName="gap-2" />
          </TourStepTrigger>
          {shouldShowVariantModifier && <VariantModifier />}
        </div>
        {
          {
            design: <DesignTab />,
            interactions: <InteractionsTab />,
            custom: <ConfigMenu />,
            accessibility: AccessibilityMenu ? <AccessibilityMenu /> : null,
          }[currentTab]
        }
      </div>
    </div>
  );
};

export default DraftComponentPane;
