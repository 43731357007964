import { styleAttributeToDefaultStyle } from "../styleAttribute";

export type TransformObject = {
  translateX: string;
  translateY: string;
  translateZ: string;
  scaleX: string;
  scaleY: string;
  scaleZ: string;
  rotateX: string;
  rotateY: string;
  rotateZ: string;
  skewX: string;
  skewY: string;
};

export const getTransformStyleString = ({
  translateX,
  translateY,
  translateZ,
  scaleX,
  scaleY,
  scaleZ,
  rotateX,
  rotateY,
  rotateZ,
  skewX,
  skewY,
}: TransformObject) => {
  const translate = `translate3d(${translateX ?? "0px"}, ${
    translateY ?? "0px"
  }, ${translateZ ?? "0px"})`;
  const scale = `scale3d(${scaleX ?? "100%"}, ${scaleY ?? "100%"}, ${
    scaleZ ?? "100%"
  })`;
  const rotateXString = `rotateX(${rotateX ?? "0deg"})`;
  const rotateYString = `rotateY(${rotateY ?? "0deg"})`;
  const rotateZString = `rotateZ(${rotateZ ?? "0deg"})`;
  const skew = `skew(${skewX ?? "0deg"}, ${skewY ?? "0deg"})`;

  const validProps = [];

  if (translate !== "translate3d(0px, 0px, 0px)") {
    validProps.push(translate);
  }
  if (scale !== "scale3d(100%, 100%, 100%)") {
    validProps.push(scale);
  }
  if (rotateXString !== "rotateX(0deg)") {
    validProps.push(rotateXString);
  }
  if (rotateYString !== "rotateY(0deg)") {
    validProps.push(rotateYString);
  }
  if (rotateZString !== "rotateZ(0deg)") {
    validProps.push(rotateZString);
  }
  if (skew !== "skew(0deg, 0deg)") {
    validProps.push(skew);
  }

  const defaultValue = styleAttributeToDefaultStyle.transform ?? "none";

  // Note (Ovishek, 2022-12-10): We need this default value b/c when there's a
  // desktop transform and an empty mobile override which doesn't have any transform value
  // we should return 'none' to make the override work
  return validProps.length > 0 ? validProps.join(" ") : defaultValue;
};
