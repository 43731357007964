import * as React from "react";

import Button from "@common/designSystem/Button";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import { errorToast } from "@editor/components/common/designSystem/Toast";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import get from "lodash-es/get";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { z } from "zod";

const validationSchema = z.object({
  isAgreed: z
    .boolean()
    .refine(
      (val) => val === true,
      "You must agree to the terms of the partnership agreement",
    ),
});

const initialValues = {
  isAgreed: true,
};

export const PartnershipAgreementForm = () => {
  const navigate = useNavigate();
  const analytics = useLogAnalytics();
  const workspaceId = useCurrentWorkspaceId();
  const { user } = useCurrentUser();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(validationSchema),
  });

  const hasErrors = Object.keys(errors).length > 0;
  const { mutateAsync: createPartnershipAgreement, isPending } =
    trpc.workspace.createPartnershipAgreement.useMutation();

  const onSubmit = async ({ isAgreed }: { isAgreed: boolean }) => {
    if (workspaceId) {
      try {
        const result = await createPartnershipAgreement({
          isAgreed,
          workspaceId,
        });

        if (result.workspace) {
          analytics("referralCode.terms", {
            workspaceId,
            email: user?.email ?? "",
          });
          if (result.workspace.referralCodeId) {
            analytics("referralCode.created", {
              workspaceId,
              code: result.workspace.referralCodeId ?? "",
              email: user?.email ?? "",
            });
          }

          await trpcUtils.workspace.getById.invalidate({
            id: workspaceId,
          });

          navigate(
            generatePath("/workspace/:workspaceId/partner/home", {
              workspaceId,
            }),
          );
        }
      } catch {
        errorToast(
          "Failed Creating Agreement",
          "Failed creating partnership agreement. Please try again or reach out to support@replo.app for help.",
        );
      }
    }
  };

  return (
    <div className="flex w-full flex-col">
      <form
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
      >
        <ErrorMessage error={get(errors, `isAgreed.message`)} />
        <div className="flex flex-row items-center justify-items-start gap-x-4">
          <input
            className="rounded-xs h-5 w-5 cursor-pointer bg-white checked:border-blue-600 checked:bg-blue-600"
            type="checkbox"
            {...register("isAgreed")}
          />
          <div className="text-base text-slate-400">
            By clicking &quot;I Agree&quot;, you are agreeing to the{" "}
            <a
              href="https://www.replo.app/referral-partner-program-terms-and-conditions"
              className="cursor-pointer text-blue-600"
              target="_blank"
              rel="noreferrer"
            >
              Replo Partnerships Terms and Agreements
            </a>
            .
          </div>
        </div>
        <Button
          type="primary"
          htmlType="submit"
          size="lg"
          isFullWidth
          className="mt-4 px-6"
          isDisabled={isPending || hasErrors}
          isLoading={isPending}
        >
          I Agree
        </Button>
      </form>
    </div>
  );
};
