import * as React from "react";

import { useWorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useModal } from "@editor/hooks/useModal";
import { routes } from "@editor/utils/router";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { Controller, useForm } from "react-hook-form";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "../common/designSystem/Button";
import Input from "../common/designSystem/Input";
import LabeledControl from "../common/designSystem/LabeledControl";
import Modal from "../common/designSystem/Modal";
import { toast } from "../common/designSystem/Toast";
import { WorkspaceSelectable } from "../dashboard/projects/NewProjectFlow";

type WorkspaceFormValues = {
  workspaceId?: string;
  workspaceName?: string;
};

export const PendingShopifyIntegrationModal = () => {
  const [createNewWorkspace, setCreateNewWorkspace] = React.useState(false);
  const [searchParams] = useSearchParams();
  const pendingAppInstallationId = searchParams.get("pendingAppInstallationId");
  const { register, handleSubmit, formState, control } =
    useForm<WorkspaceFormValues>({
      mode: "onBlur",
    });
  const navigate = useNavigate();
  const { setWorkspaceId } = useWorkspaceDashboardContext();
  const { closeModal } = useModal();
  const { user } = useCurrentUser();

  const { mutate: connectIntegration, isPending: isConnectingWorkspace } =
    trpc.integration.convertPendingAppInstallationToIntegration.useMutation({
      onSuccess: (_, { workspaceId }) => {
        navigate(
          generatePath(routes.workspace.integrations.shopify, {
            workspaceId,
          }),
        );
        setWorkspaceId(workspaceId);
        closeModal({ type: "pendingShopifyIntegrationModal" });
      },
    });

  const { mutate: createWorkspace, isPending: isCreatingWorkspace } =
    trpc.workspace.create.useMutation({
      onSuccess: ({ workspace }) => {
        toast({
          header: "Workspace created",
        });
        void trpcUtils.workspace.getUserWorkspacesList.invalidate();
        if (pendingAppInstallationId) {
          connectIntegration({
            workspaceId: workspace.id,
            pendingAppInstallationId,
          });
        }
      },
    });

  const onSubmit = ({ workspaceId, workspaceName }: WorkspaceFormValues) => {
    if (createNewWorkspace) {
      if (workspaceName && user) {
        createWorkspace({
          name: workspaceName,
          preferredPaymentProcessor:
            user.source === "shopify" ? "shopify" : "stripe",
          eventOrigin: "shopifyInstall",
        });
      }
    } else if (workspaceId && pendingAppInstallationId) {
      connectIntegration({
        workspaceId,
        pendingAppInstallationId,
      });
    }
  };

  const isPending = isCreatingWorkspace || isConnectingWorkspace;

  return (
    <Modal
      isOpen={Boolean(pendingAppInstallationId)}
      closesOnOverlayClick={false}
      includesCloseIcon={false}
      className="w-[500px]"
    >
      {createNewWorkspace ? (
        <div className="flex flex-col gap-4">
          <h1 className="text-lg font-semibold">Create a new Workspace</h1>
          <p className="text-xs">Add your workspace name:</p>
          <form
            onSubmit={(values) => {
              void handleSubmit(onSubmit)(values);
            }}
            className="flex flex-col gap-4"
          >
            <LabeledControl label="Workspace Name">
              <Input
                autoFocus
                placeholder="Add Name"
                {...register("workspaceName", {
                  shouldUnregister: true,
                  required: createNewWorkspace,
                })}
                autoComplete="off"
                size="base"
              />
            </LabeledControl>
            <div className="flex justify-between gap-4">
              <Button
                type="secondary"
                size="base"
                className="w-1/2"
                isDisabled={isPending}
                onClick={() => setCreateNewWorkspace(!createNewWorkspace)}
              >
                Back
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                size="base"
                className="w-1/2"
                isDisabled={!formState.isValid || isPending}
              >
                Create Workspace
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <h1 className="text-lg font-semibold">
            Thanks for connecting a Shopify Store
          </h1>
          <p className="text-xs">
            Select a workspace to add this Shopify Integration to. If you do not
            see the workspace in the dropdown, create a new workspace using the
            button below.
          </p>
          <form
            onSubmit={(values) => {
              void handleSubmit(onSubmit)(values);
            }}
            className="flex flex-col gap-4"
          >
            <LabeledControl label="Workspace">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <WorkspaceSelectable
                    value={value ?? null}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: !createNewWorkspace,
                }}
                shouldUnregister
                control={control}
                name="workspaceId"
              />
            </LabeledControl>

            <div className="flex justify-between gap-4">
              <Button
                type="secondary"
                size="base"
                className="w-1/2"
                isDisabled={isPending}
                onClick={() => setCreateNewWorkspace(!createNewWorkspace)}
              >
                Create New Workspace
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                size="base"
                className="w-1/2"
                isDisabled={!formState.isValid || isPending}
              >
                Connect Workspace
              </Button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};
