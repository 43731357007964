import type { User } from "replo-runtime/shared/types";
import type {
  Flow,
  FlowInstance,
  FlowNextStep,
  FlowStep,
} from "schemas/generated/flow";

import { useReploFlowsContext } from "@components/flows/context/ReploFlowsContext";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { trpc } from "@editor/utils/trpc";

import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import { differenceInMinutes } from "date-fns";
import { useParams } from "react-router-dom";

/**
 * Given a flow and a next step, find the next step render data.
 */
const findNextStepData = (
  flow: Flow,
  nextStep: FlowNextStep,
): FlowStep | null => {
  const nextStepData = flow?.steps.find((step) => {
    if (typeof nextStep === "string") {
      return step.id === nextStep;
    }
    // TODO (Fran 2024-01-19): Implement Branching - REPL-10037
    return false;
  });

  return nextStepData ?? null;
};

const filterOnboardingForNonNewUsers = (
  flows: Flow[],
  entityType: Flow["type"],
  user?: User | null,
) => {
  let flowsToFilter = flows;
  if (!user) {
    return flowsToFilter;
  }
  const now = new Date();
  const isNewUser =
    user.registeredAt &&
    differenceInMinutes(now, new Date(user.registeredAt)) <= 30;

  if (entityType === "user") {
    if (
      flowsToFilter.some((flow) => flow.slug === "editor-tour") &&
      !isNewUser
    ) {
      flowsToFilter = flowsToFilter.filter(
        (flow) => flow.slug !== "editor-tour",
      );
    }

    // NOTE (Fran 2024-01-30): If the user is new, we will start the onboarding flow. We define a
    // new user as someone who has registered in the last 30 minutes or has yet to complete the name
    // in the onboarding flow, because they can register and then leave the browser and come back later.
    // In that case, the user will still not have an instance, but it should consider a new user
    // because it has not started the onboarding flow.
    if (
      flowsToFilter.some((flow) => flow.slug.includes("onboarding")) &&
      !isNewUser &&
      user.firstName
    ) {
      flowsToFilter = flowsToFilter.filter(
        (flow) => !flow.slug.includes("onboarding"),
      );
    }
  }

  return flowsToFilter;
};

type GetCurrentFlowProps = {
  entityType?: Flow["type"];
  isDebug?: boolean;
};

export const useGetCurrentFlow = ({
  entityType,
  isDebug = false,
}: GetCurrentFlowProps = {}): {
  currentFlow: Flow | null;
  currentInstance: FlowInstance | null;
  nextStep: FlowStep | null;
  isLoading: boolean;
} => {
  const {
    debug: { flowToDebug: flowToDebugFromContext },
  } = useReploFlowsContext();
  const {
    user,
    isAuthenticated,
    // TODO (Gabe 2024-09-27): It's pretty egregious that we're attaching flow
    // data to the user object and that we depend on the user being refetched to
    // ensure we have the most up to date flow instance data.
    isFetching: areFlowInstancesLoading,
  } = useCurrentUser();
  const { data, isLoading: isLoadingFlows } = trpc.flow.getMany.useQuery(
    // TODO (Sebas, 2024-02-21): I need to refactor the debug part to work
    // only using the context and not the query params.
    !isAuthenticated || isDebug || flowToDebugFromContext
      ? reactQuerySkipToken
      : {},
  );

  // NOTE (Sebas, 2024-02-14): If we are in debug mode, we use a different service
  // to obtain all the flows from the database, including the ones that are not live.
  const { data: debugData, isLoading: isLoadingDebugFlows } =
    trpc.flow.getManyDebug.useQuery(
      !isDebug && !flowToDebugFromContext ? reactQuerySkipToken : {},
    );

  const isLoading = isLoadingFlows || isLoadingDebugFlows;

  const onboardingFlowToStrip = isFeatureEnabled("ai-onboarding")
    ? "onboarding"
    : "onboarding-ai";

  const incompleteFlows =
    data?.flows
      .filter((flow) => flow.slug !== onboardingFlowToStrip)
      .filter(
        (flow) =>
          !Boolean(
            user?.flowInstances?.find(
              ({ instance }) =>
                instance.flow.slug === flow.slug &&
                instance.completedAt &&
                !flow.isRepeatable,
            ),
          ),
      ) ?? [];

  const params = useParams();

  const requestedFlowSlugFromParams = params.flowSlug;
  const requestedFlow = incompleteFlows.find(
    (flow) => flow.slug === requestedFlowSlugFromParams,
  );

  // NOTE (Sebas, 2024-02-14): Debug Mode logic
  if (isDebug || flowToDebugFromContext) {
    const requestedDebugFlow = debugData?.flows?.find(
      (flow) =>
        flow.slug === requestedFlowSlugFromParams ||
        flow.slug === flowToDebugFromContext,
    );
    if (requestedDebugFlow) {
      const currentDebugInstance = user?.flowInstances?.find(
        (instance) =>
          instance.instance.flow.slug === requestedDebugFlow?.slug &&
          instance.instance.isDebug &&
          !instance.instance.completedAt,
      );
      const nextStep = currentDebugInstance?.instance.flow
        ? findNextStepData(
            currentDebugInstance?.instance.flow,
            currentDebugInstance?.nextStep,
          )
        : null;
      return {
        currentFlow: currentDebugInstance?.instance.flow ?? requestedDebugFlow,
        currentInstance: areFlowInstancesLoading
          ? null
          : currentDebugInstance?.instance ?? null,
        nextStep: nextStep ?? requestedDebugFlow?.steps[0] ?? null,
        isLoading,
      };
    }
    return {
      currentFlow: null,
      currentInstance: null,
      nextStep: null,
      isLoading,
    };
  }

  // NOTE (Sebas, 2024-02-14): We want to search for the flow instance
  // that is not in debug mode/completed.
  const inProgressUserLastInstance = user?.flowInstances?.find(
    ({ instance }) => !instance.isDebug && !instance.completedAt,
  );

  if (inProgressUserLastInstance) {
    const nextStep = findNextStepData(
      inProgressUserLastInstance.instance.flow,
      inProgressUserLastInstance.nextStep,
    );

    return {
      currentFlow: inProgressUserLastInstance.instance.flow,
      currentInstance: areFlowInstancesLoading
        ? null
        : inProgressUserLastInstance.instance,
      nextStep,
      isLoading,
    };
  }

  // NOTE (Fran 2024-01-18): If the user was sent to a particular flow, and the flow is available we will
  // start that one.
  if (requestedFlow) {
    const [flow] = filterOnboardingForNonNewUsers(
      [requestedFlow],
      entityType ?? "user",
      user,
    );

    if (flow) {
      return {
        currentFlow: flow,
        currentInstance: null,
        nextStep: flow.steps[0] ?? null,
        isLoading,
      };
    }
  }

  // NOTE (Fran 2024-01-18): If the user was sent to a part of the app that requires a particular flow
  // and that flow is available we will start that one.
  if (entityType && incompleteFlows.length > 0) {
    const flowsForEntityType = filterOnboardingForNonNewUsers(
      incompleteFlows.filter((flow) => flow.type === entityType),
      entityType,
      user,
    );

    return {
      currentFlow: flowsForEntityType[0] ?? null,
      currentInstance: null,
      nextStep: flowsForEntityType[0]?.steps?.[0] ?? null,
      isLoading,
    };
  } else if (incompleteFlows.length > 0) {
    // NOTE (Fran 2024-01-18): Otherwise we will start the user's flow, then the workspace's flow, then
    // the first available flow.
    // TODO (Fran 2024-01-16): Some flows can have prioritization logic, for now we just return
    // user's flow first, then the first flow.
    const globalFlows = filterOnboardingForNonNewUsers(
      incompleteFlows.filter((flow) => flow.type === (entityType ?? "user")),
      entityType ?? "user",
      user,
    );
    const userFlow = globalFlows.find((flow) => flow.type === "user");
    if (userFlow) {
      return {
        currentFlow: userFlow,
        currentInstance: null,
        nextStep: userFlow.steps[0] ?? null,
        isLoading,
      };
    }

    return {
      currentFlow: globalFlows[0] ?? null,
      currentInstance: null,
      nextStep: globalFlows[0]?.steps?.[0] ?? null,
      isLoading,
    };
  }

  return {
    currentFlow: null,
    currentInstance: null,
    nextStep: null,
    isLoading,
  };
};
