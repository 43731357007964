import type { MenuItem } from "@common/designSystem/Menu";
import type { PlanInfo } from "schemas/billing";
import type { SubscriptionDetails } from "schemas/generated/billing";

import * as React from "react";

import Button from "@common/designSystem/Button";
import { Menu } from "@common/designSystem/Menu";
import TableHeadTitle from "@components/dashboard/tables/TableHeadTitle";
import {
  SkeletonLoaderItem,
  SkeletonLoaderWrapper,
} from "@editor/components/common/designSystem/SkeletonLoader";
import Header from "@editor/components/dashboard/Header";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useModal } from "@editor/hooks/useModal";
import { usePrefetchBillingInfoImmediately } from "@editor/hooks/usePrefetchImmediately";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import { format } from "date-fns";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {
  BsExclamationCircle,
  BsExclamationCircleFill,
  BsThreeDots,
} from "react-icons/bs";
import { Outlet } from "react-router-dom";
import { getPublisherUrl } from "replo-runtime/shared/config";
import { isSelfServeSubscription } from "replo-utils/billing";
import { formatPrice } from "replo-utils/lib/math";
import { isNullish } from "replo-utils/lib/misc";
import { BILLING_TIER_INFO_MAP } from "schemas/billing";

const getStripePortalUrl = (workspaceId: string) => {
  const url = new URL(`${getPublisherUrl()}/api/v1/stripe/portal`);
  url.searchParams.append("workspaceId", workspaceId);
  url.searchParams.append("returnUrl", window.location.href);
  return url.toString();
};

const Billing: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();

  const { data } = trpc.workspace.getById.useQuery(
    workspaceId ? { id: workspaceId } : skipToken,
  );
  const { data: activeSubscription, isSuccess } =
    trpc.subscriptions.getActiveSubscriptionByWorkspace.useQuery(
      workspaceId ?? skipToken,
    );

  const isWorkspaceOwner = useIsWorkspaceOwner(workspaceId);
  usePrefetchBillingInfoImmediately();

  const { mutate: generateStripeConversionLink } =
    trpc.billing.getStripeConversionLink.useMutation({
      onSuccess: (conversionUrl) => {
        window.location.href = conversionUrl;
      },
    });

  const managePaymentDetailsUrl =
    workspaceId && getStripePortalUrl(workspaceId);

  const showStripeConversion =
    isWorkspaceOwner &&
    activeSubscription?.paymentProcessor === "shopify" &&
    data?.workspace.preferredPaymentProcessor === "stripe";

  return (
    <div className="flex w-full flex-col px-6">
      <Header
        title="Billing"
        subtitle="View and manage workspace billing"
        buttonLabel="Manage Payment Details"
        // NOTE (Gabe 2024-03-27): if no href is passed the button will not be
        // rendered.
        href={
          activeSubscription?.paymentProcessor === "stripe"
            ? managePaymentDetailsUrl || undefined
            : undefined
        }
        isButtonDisabled={!isWorkspaceOwner}
        buttonTooltipText={
          !isWorkspaceOwner
            ? "Only Workspace Owners have the ability to manage billing details"
            : undefined
        }
      />
      <hr className="my-4" />
      {isSuccess && (
        <div>
          {showStripeConversion && (
            <div className="max-w-screen-sm flex flex-row gap-3 mb-8">
              <BsExclamationCircleFill size={24} className="flex-shrink-0" />
              <div className="flex flex-col gap-2">
                <div className="text-sm">Update your payment method</div>
                <div className="text-xs text-slate-400">
                  {`We recently upgraded to Stripe as our preferred payment platform. 
                  To take advantage of benefits such as multiple Shopify integrations within
                  a single workspace, please update your payment method. `}
                  <a
                    className="text-blue-600 hover:text-blue-500 hover:underline"
                    target="_new"
                    href="https://replo.help.usepylon.com/articles/7579785312-how-do-i-migrate-to-payment-method-to-stripe"
                  >
                    Learn more
                  </a>
                </div>
                <Button
                  type="primary"
                  size="base"
                  className="mt-1"
                  onClick={() => {
                    if (!workspaceId) {
                      return;
                    }
                    void generateStripeConversionLink({ workspaceId });
                  }}
                >
                  Update Payment Method
                </Button>
              </div>
            </div>
          )}
          <div className="max-w-screen-lg">
            <BillingInfo
              activeSubscription={activeSubscription ?? undefined}
              isWorkspaceOwner={isWorkspaceOwner}
            />
            <BillingTable
              activeSubscription={activeSubscription ?? undefined}
              isWorkspaceOwner={isWorkspaceOwner}
            />
          </div>
        </div>
      )}
      <Outlet />
    </div>
  );
};

type ErrorMessageProps = {
  className: string;
  children: React.ReactNode;
};
const ErrorMessage: React.FC<ErrorMessageProps> = ({ className, children }) => {
  return (
    <div
      role="region"
      aria-live="polite"
      aria-label="billing error message"
      className={classNames(
        "p-4 bg-blue-50 w-full rounded-lg justify-start items-center gap-4 inline-flex",
        className,
      )}
    >
      {children}
    </div>
  );
};

type BillingInfo = {
  id: string;
  statInfo: string;
  text: string | undefined;
};

type BillingInfoCardProps = {
  infoArray: BillingInfo[];
  flexGrowEnd?: boolean;
  button?: { text: string; onClick: () => void };
};
const BillingInfoCard: React.FC<BillingInfoCardProps> = ({
  infoArray,
  flexGrowEnd,
  button,
}) => {
  return (
    <div
      className={classNames(
        "w-fit bg-slate-50 rounded-lg xl:px-2 flex flex-col py-6  items-start gap-4",
        { "flex-grow": flexGrowEnd },
      )}
    >
      <div className="flex divide-x-1 divide-slate-200">
        {infoArray.map((stat, i) => (
          <div
            key={stat.id}
            className={classNames("px-5 xl:px-8", {
              "flex-grow": flexGrowEnd && i === infoArray.length - 1,
            })}
          >
            {stat.text !== undefined ? (
              <p className="text-xs xl:text-sm text-slate-400 text-nowrap">
                {stat.text}
              </p>
            ) : (
              <SkeletonLoaderWrapper height={20} width={180}>
                <SkeletonLoaderItem height="20" width="180" yAxis="0" />
              </SkeletonLoaderWrapper>
            )}
            <p className="text-xl xl:text-2xl font-semibold text-nowrap text-black mt-1 mb-4">
              {stat.statInfo}
            </p>
          </div>
        ))}
      </div>
      {button && (
        <div className="px-8">
          <button
            className="text-sm font-semibold text-blue-600 underline"
            onClick={() => {
              button?.onClick();
            }}
          >
            {button.text}
          </button>
        </div>
      )}
    </div>
  );
};

const checkLimits = (
  planInfo: PlanInfo | undefined,
  counts: {
    pagesCount: number;
    shopifyArticlesCount: number;
    shopifySectionsCount: number;
    shopifyProductTemplatesCount: number;
  },
): boolean => {
  if (!planInfo) {
    return false;
  }
  // Note (Juan, 2024-04-23): If the user is on a free plan and doesn't have any published items we should not show the message
  if (
    Object.values(counts).some((count) => count === 0) &&
    planInfo.tier === "free"
  ) {
    return false;
  }
  // Note (Juan, 2024-04-23): check if any limit is exceeded or exactly met
  return (
    counts.pagesCount >= (planInfo.maxPublishedPages ?? 0) ||
    counts.shopifyArticlesCount >= (planInfo.maxPublishedBlogPosts ?? 0) ||
    counts.shopifySectionsCount >= (planInfo.maxPublishedSections ?? 0) ||
    counts.shopifyProductTemplatesCount >=
      (planInfo.maxPublishedProductTemplates ?? 0)
  );
};

const BillingInfo = ({
  activeSubscription,
  isWorkspaceOwner,
}: {
  activeSubscription: SubscriptionDetails | undefined;
  isWorkspaceOwner: boolean;
}) => {
  const modal = useModal();
  const workspaceId = useCurrentWorkspaceId();

  const { data: responseData, isPending: isLoading } =
    trpc.workspace.getPublishedElementsCountByWorkspaceId.useQuery(
      workspaceId ?? skipToken,
    );

  const {
    data: nextSubscriptionBillingDate,
    isLoading: isLoadingNextSubscriptionBillingDate,
  } = trpc.billing.getStripeNextBillingDate.useQuery(
    workspaceId &&
      activeSubscription?.paymentProcessor === "stripe" &&
      activeSubscription.paymentProcessorSubscriptionId
      ? {
          workspaceId,
          stripeSubscriptionId:
            activeSubscription.paymentProcessorSubscriptionId,
        }
      : skipToken,
  );

  const { data: subscriptionStatus } = trpc.subscriptions.getStatus.useQuery(
    workspaceId &&
      activeSubscription?.paymentProcessor === "stripe" &&
      activeSubscription.paymentProcessorSubscriptionId
      ? {
          workspaceId,
          subscriptionId: activeSubscription.id,
        }
      : skipToken,
  );

  const {
    page: pagesCount,
    shopifyArticle: shopifyArticlesCount,
    shopifySection: shopifySectionsCount,
    shopifyProductTemplate: shopifyProductTemplatesCount,
  } = {
    page: responseData?.page ?? 0,
    shopifyArticle: responseData?.shopifyArticle ?? 0,
    shopifySection: responseData?.shopifySection ?? 0,
    shopifyProductTemplate: responseData?.shopifyProductTemplate ?? 0,
  };

  const planName = activeSubscription?.name ?? "free";
  const planInfo = BILLING_TIER_INFO_MAP[planName];

  const isLimitExceeded = checkLimits(planInfo, {
    pagesCount,
    shopifyArticlesCount,
    shopifySectionsCount,
    shopifyProductTemplatesCount,
  });

  const nextBillText = (() => {
    if (!activeSubscription || activeSubscription.paymentProcessor === "none") {
      return "N/A";
    }
    if (activeSubscription.paymentProcessor === "shopify") {
      return "Charge details in Shopify";
    }

    // Note (Evan, 2024-03-28): Return undefined so a loader is shown
    if (isLoadingNextSubscriptionBillingDate) {
      return undefined;
    }

    // Note (Evan, 2024-03-28): If there was some issue loading the
    // next date, fall back to a generic message
    if (!nextSubscriptionBillingDate) {
      return "Charge details in Stripe";
    }

    return `Next cycle date: ${format(new Date(nextSubscriptionBillingDate), "M/d/yyyy")}`;
  })();

  const planDetails: BillingInfo[] = [
    {
      id: "currentPlan",
      statInfo: `${planInfo.displayName}`,
      text: "Current Plan",
    },
    ...(planName === "free"
      ? []
      : [
          {
            id: "nextBill",
            statInfo: formatPrice(activeSubscription?.monthlyAmount ?? 0),
            text: nextBillText,
          },
        ]),
  ];

  const elementUsageStats: BillingInfo[] = [
    {
      id: "productTemplates",
      statInfo: !isNullish(planInfo?.maxPublishedProductTemplates)
        ? `${shopifyProductTemplatesCount}/${planInfo.maxPublishedProductTemplates}`
        : "Unlimited",
      text: "Product Templates",
    },
    {
      id: "publishedPages",
      statInfo: !isNullish(planInfo?.maxPublishedPages)
        ? `${pagesCount}/${planInfo.maxPublishedPages}`
        : "Unlimited",
      text: "Pages",
    },
    {
      id: "publishedBlogPosts",
      statInfo: !isNullish(planInfo?.maxPublishedBlogPosts)
        ? `${shopifyArticlesCount}/${planInfo.maxPublishedBlogPosts}`
        : "Unlimited",
      text: "Blog Posts",
    },
    {
      id: "publishedSections",
      statInfo: !isNullish(planInfo?.maxPublishedSections)
        ? `${shopifySectionsCount}/${planInfo.maxPublishedSections}`
        : "Unlimited",
      text: "Sections",
    },
  ];
  if (isLoading) {
    return null;
  }
  const shouldShowLimitExceededMessage =
    isLimitExceeded && planName !== "custom";
  const shouldShowPaymentError = subscriptionStatus?.status === "past_due";
  return (
    <div className="flex flex-col gap-4">
      {shouldShowLimitExceededMessage && (
        <ErrorMessage className="bg-blue-50 text-default">
          <BsExclamationCircle size={24} />
          <div className="text-base font-medium ">
            You have reached the publishing limits on your current account.
            Upgrade to increase the publishing limits.
          </div>
        </ErrorMessage>
      )}
      {shouldShowPaymentError && (
        <ErrorMessage className="bg-red-50 text-red-600">
          <BsExclamationCircle size={24} />
          <div className="text-base font-medium">
            There is an error with your payment method. Please{" "}
            <a
              className="text-blue-500 hover:text-blue-400"
              href={
                (workspaceId && getStripePortalUrl(workspaceId)) ?? undefined
              }
            >
              update your payment method
            </a>{" "}
            or your Subscription will be canceled.
          </div>
        </ErrorMessage>
      )}
      <div
        className={classNames("flex gap-5 flex-col", {
          "min-[1460px]:flex-row": planName === "custom",
          "min-[950px]:flex-row": planName !== "custom",
        })}
      >
        <BillingInfoCard
          infoArray={planDetails}
          button={{
            text: "Pricing Details",
            onClick: () => {
              window.open(
                "https://support.replo.app/hc/en-us/articles/22389707341325-What-is-the-best-Replo-plan-for-me",
                "_blank",
              );
            },
          }}
        />
        <BillingInfoCard
          infoArray={elementUsageStats}
          flexGrowEnd
          button={
            isWorkspaceOwner
              ? {
                  text: "Upgrade Plan",
                  onClick: () => {
                    modal.openModal({
                      type: "billingModal",
                      props: { source: "direct.billingDashboard" },
                    });
                  },
                }
              : undefined
          }
        />
      </div>
    </div>
  );
};

type BillingTableProps = {
  activeSubscription: SubscriptionDetails | undefined;
  isWorkspaceOwner: boolean;
};

const BillingTable: React.FC<BillingTableProps> = ({
  activeSubscription,
  isWorkspaceOwner,
}) => {
  const workspaceId = useCurrentWorkspaceId();

  const modal = useModal();

  const isSelfServe =
    activeSubscription && isSelfServeSubscription(activeSubscription);

  const menuItems: MenuItem[] = [
    {
      type: "leaf",
      id: "manage-membership",
      title: "Manage Plan",
      onSelect: () => {
        modal.openModal({
          type: "billingModal",
          props: { source: "direct.billingDashboard" },
        });
      },
    },
  ];
  if (isSelfServe) {
    menuItems.push({
      type: "leaf" as const,
      id: "cancel-membership",
      title: "Cancel",
      onSelect: () => {
        if (!workspaceId) {
          return;
        }
        modal.openModal({
          type: "billingPlanChangeSurvey",
          props: {
            tier: activeSubscription.name,
            downgradeTo: "free",
            source: "direct.billingDashboard",
            type: "cancel",
            workspaceId,
          },
        });
      },
    });
  }

  return (
    <div className="flex flex-col w-full max-w-screen-lg h-full">
      {activeSubscription && (
        <>
          <div className="grid grid-cols-12 my-2 mt-6">
            <TableHeadTitle
              title="Item"
              wrapperClassName="col-span-3"
              shouldShowArrow={false}
            />
            <TableHeadTitle
              title="Monthly Cost"
              shouldShowArrow={false}
              wrapperClassName="col-span-2"
            />
            <TableHeadTitle
              title="Start Date"
              shouldShowArrow={false}
              wrapperClassName="col-span-4"
            />
          </div>
          <div className="py-2">
            <div className="grid grid-cols-12 items-center">
              <div className="flex gap-1 items-center col-span-3 flex-nowrap overflow-auto md:gap-2">
                <p className="text-sm font-medium text-default truncate capitalize">
                  {activeSubscription.name} Plan
                </p>
              </div>
              <div className="col-span-2">
                <p className="text-slate-400">
                  {formatPrice(activeSubscription.monthlyAmount)}
                </p>
              </div>
              <div className="col-span-3">
                <p className="text-slate-400">
                  {formatDistanceToNow(activeSubscription.startedAt, {
                    addSuffix: true,
                  })}
                </p>
              </div>
              {isWorkspaceOwner && (
                <div className="flex col-span-1">
                  <Menu
                    items={menuItems}
                    customWidth="auto"
                    align="start"
                    trigger={
                      <BsThreeDots size={16} className="text-slate-400" />
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {!activeSubscription && (
        <div className="flex flex-row items-around w-full rounded-lg py-4 bg-blue-50 justify-center mt-4">
          <div className="flex flex-row gap-4 text-default items-center">
            Ready to access more Replo features?
            <Button
              type="primary"
              className="py-4 px-3"
              onClick={() => {
                modal.openModal({
                  type: "billingModal",
                  props: { source: "direct.billingDashboard" },
                });
              }}
            >
              <span className="text-sm">Upgrade Plan</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Billing;
