import type {
  Flow,
  FlowInstance,
  FlowSlugs,
  FlowStep,
} from "schemas/generated/flow";

import * as React from "react";

type ReploFlowsContextValue = {
  currentFlow: Flow | null;
  currentInstance: FlowInstance | null;
  nextStep: FlowStep | null;
  debug: {
    flowToDebug: FlowSlugs | null;
    setFlowToDebug: React.Dispatch<
      React.SetStateAction<FlowSlugs | null>
    > | null;
  };
};

export const ReploFlowsContext = React.createContext<ReploFlowsContextValue>({
  currentFlow: null,
  currentInstance: null,
  nextStep: null,
  debug: {
    flowToDebug: null,
    setFlowToDebug: null,
  },
});

export function useReploFlowsContext() {
  return React.useContext(ReploFlowsContext);
}
