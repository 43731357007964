import * as React from "react";

import { URL_PARAMS_FILTER_OPTIONS } from "@/features/analytics/moreFilters/constants";
import { findLabelForValue } from "@/features/analytics/moreFilters/utils";
import { Combobox } from "@replo/design-system/components/shadcn/combobox/Combobox";
import { BsFilter } from "react-icons/bs";

type TriggerBarPopoverComboboxProps = {
  handleUrlParamOptionClick: (value: string) => void;
};

const TriggerBarPopoverCombobox: React.FC<TriggerBarPopoverComboboxProps> = ({
  handleUrlParamOptionClick,
}) => {
  const utmFilterOptionDisplay = (value: string) => {
    const label = findLabelForValue(URL_PARAMS_FILTER_OPTIONS, value);

    return (
      <span onClick={() => handleUrlParamOptionClick(value)}>{label}</span>
    );
  };

  return (
    <Combobox
      options={URL_PARAMS_FILTER_OPTIONS.map((option) => ({
        ...option,
        component: utmFilterOptionDisplay(option.value),
      }))}
      staticPlaceholder="More Filters"
      endEnhancer={() => <BsFilter className="h-4 w-4 text-subtle" />}
      className="bg-white"
    />
  );
};

export default TriggerBarPopoverCombobox;
