import type { AlchemyActionType } from "replo-runtime/shared/enums";
import type { Action } from "replo-runtime/shared/types";
import type { Context } from "replo-runtime/store/AlchemyVariable";

import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { evaluateVariableAsString } from "replo-runtime/store/AlchemyVariable";

type AlchemyActionTypeRenderData = {
  isRedirectToUrl: boolean;
} & (
  | { supportsDynamicData: false }
  | {
      supportsDynamicData: true;
      evaluateDynamicData(config: { action: Action; context: Context }): Action;
      getDynamicDataValues(config: { action: Action }): string[];
    }
);

export const actionTypeToRenderData: Record<
  AlchemyActionType,
  AlchemyActionTypeRenderData
> = {
  redirect: {
    isRedirectToUrl: true,
    supportsDynamicData: true,
    getDynamicDataValues: ({ action }) => {
      if (action.type === "redirect") {
        const value = action.value;
        if (typeof value === "string") {
          return isDynamicDataValue(value) ? [value] : [];
        }
        return isDynamicDataValue(value.url) ? [value.url] : [];
      }
      return [];
    },
    evaluateDynamicData: ({ action, context }) => {
      if (action.type === "redirect") {
        const value = action.value;

        if (typeof value === "string") {
          const evaluated = isDynamicDataValue(value)
            ? evaluateVariableAsString(value, context)
            : null;
          return {
            ...action,
            value: evaluated ?? value,
          };
        }
        const evaluated = isDynamicDataValue(value.url)
          ? evaluateVariableAsString(value.url, context)
          : null;
        return {
          ...action,
          value: {
            ...value,
            url: evaluated ?? value.url,
          },
        };
      }
      return action;
    },
  },
  redirectToProductPage: {
    isRedirectToUrl: true,
    // TODO (Noah, 2023-05-25, REPL-7427): This is actually not true, this
    // action actually does support dynamic data, but we haven't implemented the
    // appropriate evaluation so that the evaluated value can be passed to components
    supportsDynamicData: false,
  },
  phoneNumber: {
    isRedirectToUrl: true,
    // TODO (Noah, 2023-05-25, REPL-7427): This is actually not true, this
    // action actually does support dynamic data, but we haven't implemented the
    // appropriate evaluation so that the evaluated value can be passed to components
    supportsDynamicData: false,
  },
  addProductVariantToCart: {
    // TODO (Noah, 2023-05-25, REPL-7427): This is actually not true, this
    // action actually does support dynamic data, but we haven't implemented the
    // appropriate evaluation so that the evaluated value can be passed to components
    supportsDynamicData: false,
    isRedirectToUrl: false,
  },
  multipleProductVariantsAddToCart: {
    // TODO (Noah, 2023-05-25, REPL-7427): This is actually not true, this
    // action actually does support dynamic data, but we haven't implemented the
    // appropriate evaluation so that the evaluated value can be passed to components
    supportsDynamicData: false,
    isRedirectToUrl: false,
  },
  updateCart: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  clearCart: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  close: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  createUserTag: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  carouselNext: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  carouselPrevious: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  closeModalComponent: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  openModal: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  openKlaviyoModal: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  scrollToUrlHashmark: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  toggleCollapsible: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  setSelectedListItem: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  togglePlay: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  toggleFullScreen: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  toggleMute: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  scrollContainerRight: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  scrollContainerLeft: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  setCurrentCollectionSelection: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  toggleDropdown: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  setDropdownItem: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  setActiveVariant: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  setActiveOptionValue: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  addVariantToTemporaryCart: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  removeVariantFromTemporaryCart: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  decreaseVariantCountInTemporaryCart: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  scrollToNextCarouselItem: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  scrollToPreviousCarouselItem: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  scrollToSpecificCarouselItem: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  applyDiscountCode: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  addTemporaryCartProductsToCart: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  activateTabId: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  setActiveTabIndex: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  executeJavascript: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  setActiveAlchemyVariant: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  increaseProductQuantity: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  decreaseProductQuantity: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  setProductQuantity: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  goToItem: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  goToNextItem: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  goToPrevItem: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  updateCurrentProduct: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
  setActiveSellingPlan: {
    isRedirectToUrl: false,
    supportsDynamicData: false,
  },
};
