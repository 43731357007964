import * as React from "react";

import InlinePopover from "@common/designSystem/InlinePopover";
import { ConnectShopifyCallout } from "@editor/components/editor/page/ConnectShopifyCallout";
import { selectDoesExistDraftElement } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { Button } from "@replo/design-system/components/button";

export const PublishPageButtonWithCallout: React.FC = () => {
  const draftElementExists = useEditorSelector(selectDoesExistDraftElement);

  return (
    <InlinePopover
      shouldPreventDefaultOnInteractOutside={false}
      content={<ConnectShopifyCallout type="publish" />}
      side="bottom"
      align="end"
      sideOffset={16}
      triggerAsChild
      hideCloseButton
      className="w-72"
    >
      <Button variant="primary" size="base" isDisabled={!draftElementExists}>
        Publish
      </Button>
    </InlinePopover>
  );
};
