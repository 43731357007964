import type { ReploComponent } from "schemas/component";
import type { ComponentTemplateScope } from "schemas/generated/componentTemplates";

import { reploComponentSchema } from "schemas/component";
import {
  componentTemplatePreviewPropsSchema,
  reploElementTypeSchema,
} from "schemas/element";
import { processArrayUrlParam } from "schemas/utils";
import { z } from "zod";

export const componentCategoryTypeEnum = z.enum([
  "page",
  "section",
  "component",
]);

export const getComponentTemplateQuerySchema = z
  .object({
    templateId: z.string(),
    storeId: z.string().nullish(),
    shouldUseStoreDesignSystem: z
      .preprocess((value) => value === "true", z.boolean())
      .default(false),
  })
  .describe("GetComponentTemplateArgs");

export const getComponentTemplatePreviewsQuerySchema = z.object({
  templateIds: z.array(z.string()),
});

export const componentTemplateTypeSchema = z.enum([
  "page",
  "section",
  "component",
]);
export type ComponentTemplateType = z.infer<typeof componentTemplateTypeSchema>;

export const scopeSchema = z
  .enum(["global", "store", "unlisted", "left-bar"])
  .describe("ComponentTemplateScope");
export type ComponentTemplateScopeAsTextTabOption = {
  label: string;
  value: ComponentTemplateScope;
};

export const environmentSchema = z.enum(["production", "dev"]);

const categoryIds = [
  "3ad88fad-1019-49f4-b970-f3fcbb61f4fb",
  "6cc4c4ae-5c4b-469c-85d3-59385422e9da",
  "824b2b1b-85e7-48a1-b380-41fa448c9b83",
  "21f108b8-14c5-445c-adad-c631776c0b7f",
  "ad406848-df2c-4d15-8399-e03d6bb768f4",
  "83177fa6-ce90-4c33-bdf3-da00bff510dd",
  "535fc5cf-db07-46dd-b2d4-9a1f7657c196",
  "9f1231b8-7e7d-4758-9fd7-0233452a9ef9",
  "c5cd3ebe-9548-463f-8056-4e27f0214768",
  "e1a8154e-82cb-4014-ad9e-5770e8ac7c55",
  "1c283f55-d1a0-4173-804a-a622f1f6c2fe",
  "d9be7379-beba-4334-b7fc-7e7f06f474f3",
  "a5ff41d0-c798-40e3-ad7c-448679ddf021",
  "a8b32fa0-02f8-4c8c-8e40-5c8069baf720",
  "def7687f-1488-4b81-8679-8944a92d56be",
  "33fd6bd7-a5ad-4e26-a8ff-66505873174e",
  "8cf813d0-6460-11ee-b489-f2472ab43fa3",
  "885e9648-d0f1-46b3-9e97-c2a446bb43d2",
] as const;
export const categoryIdSchema = z.enum(categoryIds);
export type ComponentTemplateCategoryId = (typeof categoryIds)[number];
// Note (Noah, 2023-05-03): These are a all the known categories we have - eventually
// we plan on moving these to the database. The ids are arbitrary, all that matters
// is that this code and the database agree on them.
export const CATEGORIES_IDS = {
  /* Sections */
  heros: "3ad88fad-1019-49f4-b970-f3fcbb61f4fb",
  banners: "6cc4c4ae-5c4b-469c-85d3-59385422e9da",
  sections: "824b2b1b-85e7-48a1-b380-41fa448c9b83",
  products: "21f108b8-14c5-445c-adad-c631776c0b7f",
  bundles: "ad406848-df2c-4d15-8399-e03d6bb768f4",
  footers: "83177fa6-ce90-4c33-bdf3-da00bff510dd",
  faqs: "535fc5cf-db07-46dd-b2d4-9a1f7657c196",
  content: "9f1231b8-7e7d-4758-9fd7-0233452a9ef9",
  testimonials: "c5cd3ebe-9548-463f-8056-4e27f0214768",
  logos: "e1a8154e-82cb-4014-ad9e-5770e8ac7c55",
  /* Pages */
  landingPage: "1c283f55-d1a0-4173-804a-a622f1f6c2fe",
  blogPostPage: "d9be7379-beba-4334-b7fc-7e7f06f474f3",
  listiclePage: "a5ff41d0-c798-40e3-ad7c-448679ddf021",
  videoSalesLetterPage: "a8b32fa0-02f8-4c8c-8e40-5c8069baf720",
  advertorialPage: "def7687f-1488-4b81-8679-8944a92d56be",
  productDropPage: "33fd6bd7-a5ad-4e26-a8ff-66505873174e",
  productPageTemplates: "8cf813d0-6460-11ee-b489-f2472ab43fa3",
  aboutUs: "885e9648-d0f1-46b3-9e97-c2a446bb43d2",
} as const satisfies Record<string, ComponentTemplateCategoryId>;

const sharedCategoryIds = [
  "shared.page-templates",
  "shared.component-templates",
] as const;
export const sharedCategoryIdSchema = z.enum(sharedCategoryIds);
export type SharedCategoryId = (typeof sharedCategoryIds)[number];
export const SHARED_CATEGORIES_SELECTIONS = {
  sharedPages: "shared.page-templates",
  sharedComponents: "shared.component-templates",
} as const satisfies Record<string, SharedCategoryId>;

export const componentTemplateSelectionIdSchema = z.union([
  categoryIdSchema,
  sharedCategoryIdSchema,
]);
export type ComponentTemplateSelectionId = z.infer<
  typeof componentTemplateSelectionIdSchema
>;

const categorySlugs = [
  "heros",
  "banners",
  "sections",
  "products",
  "bundles",
  "footers",
  "faqs",
  "content",
  "testimonials",
  "logos",
  "landing-pages",
  "advertorial",
  "listicle",
  "video-sales-letter",
  "blog-posts",
  "product-drop",
  "product-page-templates",
  "about-us",
] as const;
export const categorySlugSchema = z.enum(categorySlugs);
export type CategorySlug = z.infer<typeof categorySlugSchema>;

const sharedCategorySlugs = ["shared-components", "shared-pages"] as const;
export const sharedCategorySlugSchema = z.enum(sharedCategorySlugs);
export type SharedCategorySlug = z.infer<typeof sharedCategorySlugSchema>;

export const componentTemplateCategorySelectionSlug = z.union([
  categorySlugSchema,
  sharedCategorySlugSchema,
]);
export type ComponentTemplateCategorySelectionSlug = z.infer<
  typeof componentTemplateCategorySelectionSlug
>;

const componentTemplateBadgesIds = [
  "295e4057-1b55-46ea-89e9-b0b26bbc3bb7",
  "d4e28d8a-28ac-46ea-85c6-b181b670f5d8",
] as const;
export const badgeIdSchema = z.enum(componentTemplateBadgesIds);
export type ComponentTemplateBadgeId =
  (typeof componentTemplateBadgesIds)[number];

export type ComponentTemplateBadge = {
  id: ComponentTemplateBadgeId;
  name: string;
  primaryColor: string;
  secondaryColor: string;
};

const componentTemplatesIndustriesIds = [
  "dbec89c6-0222-4115-a994-967c07514b5b",
  "58785c14-4db0-47b5-9fcf-bd48cbb92938",
  "ef386249-c49f-49b3-8fcc-350a7b8ab1e0",
  "a95bc923-28ac-41da-978f-46be5c024793",
  "d16c03d2-c5b8-40e9-8f8b-a59d2c5755a1",
  "26fd6a4e-525c-48b9-bfb4-718332f2a661",
  "5b5f7cf6-2aee-4ea3-85c6-ea73111aca9e",
  "2932fe19-bbbf-4219-8b8d-b181b670f5d8",
  "3f39766e-9c1b-44ae-b105-5d64d93f2cad",
  "68395a9c-e77b-460b-9806-9cb1ec7e0dc1",
  "9107a7ec-4a26-4ed3-9307-98fbad60fbb1",
  "56ec366e-8626-46c3-ba69-6343b651f534",
  "be3a36d9-113e-4188-99dc-97a4420409e5",
  "73cb5719-5bd0-47a0-a861-9fa66818debf",
  "b785dd5d-efb4-4fe0-85a6-c3b3b59a34db",
  "d4e28d8a-9c59-4744-9044-121162f9b247",
  "7c7825e2-bf9f-4cae-81c4-2692d5213603",
] as const;
export const industryIdSchema = z.enum(componentTemplatesIndustriesIds);
export type ComponentTemplateIndustryId =
  (typeof componentTemplatesIndustriesIds)[number];

export const componentTemplateIndustryNames = [
  "Accessories",
  "Activities & Outdoors",
  "Baby & Kids",
  "Beauty",
  "Bags & Luggage",
  "Clothing",
  "Drinks",
  "Food",
  "Hair Care",
  "Health & Wellness",
  "Home",
  "Personal Care",
  "Pets",
  "Shoes",
  "Skin Care",
  "Supplements",
  "Tech",
] as const;
export const componentTemplateIndustryNamesSchema = z.enum(
  componentTemplateIndustryNames,
);
type ComponentTemplateIndustryName =
  (typeof componentTemplateIndustryNames)[number];
export type ComponentTemplateIndustry = {
  id: ComponentTemplateIndustryId;
  name: ComponentTemplateIndustryName;
};

const leftBarMainCategories = [
  "basic",
  "layout",
  "product",
  "productIntegrations",
  "media",
  "interactive",
  "pageIntegrations",
  "customCode",
] as const;
const leftBarMainCategorySchema = z.enum(leftBarMainCategories);
export type LeftBarMainCategory = (typeof leftBarMainCategories)[number];

const leftBarSubCategories = [
  "text",
  "core",
  "shapes",
  "columns",
  "productLayouts",
  "productData",
  "buttons",
  "variants",
  "options",
  "sellingPlans",
  "subscription",
  "okendo",
  "junip",
  "yotpo",
  "loox",
  "reviewsIo",
  "stamped",
  "fera",
  "judgeMe",
  "infiniteOptions",
  "image",
  "video",
  "toggles",
  "animations",
  "countdown",
  "popup",
  "carousel",
  "form",
  "map",
  "shopify",
  "rebuy",
  "beforeAfterSlider",
  "other",
] as const;
export const leftBarSubCategorySchema = z
  .enum(leftBarSubCategories)
  .describe("ComponentTemplateLeftBarSubCategory");

type LeftBarSubCategory = (typeof leftBarSubCategories)[number];

export type ComponentTemplateCategoryType = "page" | "section" | "shared";
type BaseComponentTemplateCategories = {
  name: string;
  title?: string;
  slug: ComponentTemplateCategorySelectionSlug;
  componentTemplateCategoryType: ComponentTemplateCategoryType;
  order: number;
};
export type ComponentTemplateSelectionCategories =
  BaseComponentTemplateCategories & {
    id: ComponentTemplateSelectionId;
  };
export type ComponentTemplateCategories = BaseComponentTemplateCategories & {
  id: ComponentTemplateCategoryId;
};

export type FilterOptionId =
  | ComponentTemplateBadgeId
  | ComponentTemplateSelectionId
  | ComponentTemplateIndustryId;

type IndustryFilter = {
  label: string;
  options: ComponentTemplateIndustry[];
  type: "industry";
};

type BadgeFilter = {
  label: string;
  options: ComponentTemplateBadge[];
  type: "badge";
};

type CategoryFilter = {
  label: string;
  options: ComponentTemplateSelectionCategories[];
  type: "category";
};

export type Filter = IndustryFilter | BadgeFilter | CategoryFilter;

export type SelectedFilters = {
  category: ComponentTemplateSelectionId[];
  badge: ComponentTemplateBadgeId[];
  industry: ComponentTemplateIndustryId[];
};

export const collectionSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    organizationName: z.string().optional(),
    descriptionMarkdown: z.string().nullable(),
    profileImageUrl: z.string(),
    coverImageUrl: z.string(),
    websiteUrl: z.string().nullable(),
    priority: z.number().nullable(),
    updatedAt: z.coerce.date(),
    createdAt: z.coerce.date(),
  })
  .describe("ComponentTemplateCollection");

export const leftBarCategorySchema = z
  .object({
    main: leftBarMainCategorySchema,
    subCategory: leftBarSubCategorySchema.optional(),
  })
  .describe("ComponentTemplateLeftBarCategory");

export const componentTemplateSchema = z.object({
  /* Base Schema */
  id: z.string(),
  name: z.string(),
  type: componentTemplateTypeSchema,
  scope: scopeSchema.optional(),
  env: environmentSchema,
  template: reploComponentSchema.optional(),
  // NOTE (Fran 2024-08-15): These are strings because to get these templates we are not using the ORM
  // instead we use a Raw query.
  createdAt: z.coerce.string().optional(),
  updatedAt: z.coerce.string().optional(),
  deletedAt: z.coerce.string().optional(),

  /* Marketplace Specific Schema */
  categoryId: z.string().nullish(),
  badgeId: badgeIdSchema.nullish(),
  industryId: industryIdSchema.nullish(),
  collectionId: z.string().nullish(),
  storeId: z.string().uuid().nullable(),
  metadata: z.object({ originalWidth: z.number().nullish() }).nullish(),
  mobilePreviewUrl: z.string().optional(),
  desktopPreviewUrl: z.string().optional(),
  collection: collectionSchema
    .pick({
      id: true,
      name: true,
      organizationName: true,
      coverImageUrl: true,
      profileImageUrl: true,
      descriptionMarkdown: true,
    })
    .nullish(),
  brandSlug: z.string().nullish(),
  userGuideLink: z.string().nullish(),
  metaDescription: z.string().nullish(),
  markdownDocumentation: z.string().nullish(),
  markdownDescription: z.string().nullish(),
  mobileThumbnail: z.string().nullish(),
  desktopThumbnail: z.string().nullish(),

  /* Left Bar Specific Schema */
  leftBarCategory: leftBarCategorySchema.optional(),
  // NOTE (Fran 2024-08-15): This is a little build an schema so and we only need this for hardcoded
  // templates so we can use type this as any, and complete the type with actual types.
  transforms: z.any(),
  // NOTE (Matt, 2023-08-02) canBeAddedAsChild is being deprecated in favor of the newer 'ancestorAllow`,
  // which is defined in Replo Runtime and is more extensible.
  // @deprecated
  // NOTE (Fran 2024-08-15): This is a little build an schema so and we only need this for hardcoded
  // templates so we can use type this as any, and complete the type with actual types.
  canBeAddedAsChild: z.any(),
  /* Tags to allow a narrow search in the left panel */
  tags: z.array(z.string()).optional(),

  preview: z.string().optional(),
  selectedPreview: z.string().optional(),
});

export type ComponentTemplate = {
  id: string;
  name: string;
  type: "page" | "component" | "section";
  scope?: "global" | "store" | "unlisted" | "left-bar";
  env: "production" | "dev";
  template?: ReploComponent;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  categoryId?: string | null;
  badgeId?: ComponentTemplateBadgeId | null;
  industryId?: ComponentTemplateIndustryId | null;
  collectionId?: string | null;
  storeId: string | null;
  metadata?: {
    originalWidth?: number | null;
  } | null;
  mobilePreviewUrl?: string;
  desktopPreviewUrl?: string;
  collection?: {
    id: string;
    name: string;
    organizationName?: string;
    coverImageUrl: string;
    profileImageUrl: string;
    descriptionMarkdown: string | null;
  } | null;

  brandSlug?: string | null;
  userGuideLink?: string | null;
  metaDescription?: string | null;
  markdownDocumentation?: string | null;
  markdownDescription?: string | null;
  mobileThumbnail?: string | null;
  desktopThumbnail?: string | null;
  leftBarCategory?: {
    main: LeftBarMainCategory;
    subCategory?: LeftBarSubCategory;
  };
  transforms?: any;
  canBeAddedAsChild?: any;
  tags?: string[];
  preview?: string;
  selectedPreview?: string;
};

export const componentTemplateWithoutIdSchema = componentTemplateSchema.omit({
  id: true,
});
export const createComponentTemplateBody = z.object({
  componentTemplate: componentTemplateWithoutIdSchema,
  storeId: z.string().uuid(),
});

export const createComponentTemplateV2Body = z.object({
  componentTemplate: componentTemplateWithoutIdSchema,
  storeId: z.string().uuid(),
  previewRenderProps: componentTemplatePreviewPropsSchema,
});

export const componentTemplatesQuerySchema = z
  .object({
    storeId: z.string().nullish(),
    componentCategoryType: z.array(componentTemplateTypeSchema),
    userId: z.string().nullish(),
    componentCollectionId: z.string().nullish(),
    searchText: z.string().nullish(),
    pageSize: z.preprocess(Number, z.number()).default(24),
    cursor: z.number().default(0),
    scope: z.enum(["global", "store"]).default("global"),
    includeComponent: z
      .preprocess(
        // TODO (Fran 2024-08-29): Convert this to be a boolean a few days after of removing the old
        // find many templates endpoint.
        (value) => value === "true" || z.boolean().safeParse(value).success,
        z.boolean(),
      )
      .default(false),
    selectedBadges: processArrayUrlParam(z.string(), []),
    selectedIndustries: processArrayUrlParam(z.string(), []),
    selectedCategories: processArrayUrlParam(z.string(), []),
    includeArchivedTemplates: z
      .preprocess((value) => value === "true", z.boolean())
      .default(false),
  })
  .describe("SummarizePayload");

export const collectionQuerySchema = z.object({
  storeId: z.string().uuid(),
  componentCollectionId: z.string().nullish(),
  pageSize: z.preprocess(Number, z.number()).default(24),
  skip: z.preprocess(Number, z.number()).default(0),
  elementType: reploElementTypeSchema.nullish(),
  includeEmpty: z
    .preprocess((value) => value === "true", z.boolean())
    .default(false),
});

export const findManyComponentTemplatesResponseSchema = z
  .object({
    // NOTE (Fran 2024-08-19): We don't need the template here, because we are showing only the list
    // and the previews are just images.
    componentTemplates: z.array(componentTemplateSchema),
    queryHasResults: z.boolean(),
    count: z.number(),
    nextSkip: z.number().nullable(),
  })
  .describe("FindManyComponentTemplatesResponse");
