import type {
  Option,
  SelectedValue,
} from "@editor/components/editor/page/element-editor/components/Lists";
import type { ReploShopifyOption } from "replo-runtime/shared/types";

import * as React from "react";

import Badge from "@common/designSystem/Badge";
import Selectable from "@editor/components/common/designSystem/Selectable";
import useCurrentContext from "@editor/hooks/useCurrentContext";
import { useStoreProductsFromDraftElement } from "@editor/hooks/useStoreProducts";
import { selectProductOptionValues } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import SelectablePopover from "@editorComponents/SelectablePopover";

import { BsFillBagPlusFill } from "react-icons/bs";

const OptionValuesSelector = (props: {
  option: { label: string; values: string[] };
  selectedValues: SelectedValue[];
  onChange: (newValue: SelectedValue[] | null) => void;
}) => {
  const { option, selectedValues, onChange } = props;
  const options = option.values.map((optionValue) => {
    return {
      label: optionValue,
      value: optionValue,
      isSelectable: true,
      isDefaultActive: !selectedValues || selectedValues.includes(optionValue),
    } as Option;
  });
  if (options) {
    const allSelected =
      !selectedValues || selectedValues.length >= options.length;
    return (
      <SelectablePopover
        key={option.label}
        title="Option Values"
        itemSize={40}
        itemsOnViewCount={5}
        options={options}
        isMultiselect
        allowSelectAll
        isRemovable={false}
        onSelect={(values) => {
          // Note (Noah, 2023-12-21, USE-627): If the user selects all
          // option values, we prefer to set null instead of all specific option
          // value names because then if the user changes option value names or adds
          // new option values in Shopify, they'll still be shown here
          if (Array.isArray(values)) {
            if (values.length === options.length) {
              onChange(null);
            } else {
              onChange(values);
            }
          }
        }}
        placeholder="Select Option Value"
        startEnhancer={
          <Badge
            isFilled
            backgroundColor="bg-blue-600"
            foregroundColor="text-slate-50"
          >
            <BsFillBagPlusFill size={10} />
          </Badge>
        }
      >
        {allSelected
          ? "All Option Values"
          : `${selectedValues.length} Option Value${
              selectedValues.length !== 1 ? "s" : ""
            }`}
      </SelectablePopover>
    );
  }
};

export const OptionsCustomPropModifier = (props: {
  value: { label: string; values?: SelectedValue[] } | string;
  onChange: (
    value: { label: string; values?: SelectedValue[] | null } | string,
  ) => void;
}) => {
  const { value, onChange } = props;
  const { products } = useStoreProductsFromDraftElement();

  // NOTE (Gabe 2023-10-04): Context doesn't contain the required data until
  // after the first render of the Option component so we must use this hook.
  const context = useCurrentContext();

  const existingValueIsLegacyString = typeof value == "string";

  const options: ReploShopifyOption[] = useEditorSelector((state) =>
    selectProductOptionValues(state, products, context),
  );

  const optionsWithValues = options.reduce(
    (
      optionsObject: Record<string, { label: string; values: string[] }>,
      option,
    ) => {
      if (option.values) {
        optionsObject[option.name] = {
          label: option.name,
          values: option.values.map(({ title }) => title),
        };
      }
      return optionsObject;
    },
    {},
  );
  const optionNames = Object.keys(optionsWithValues);
  if (optionNames[0]) {
    const existingOptionName = existingValueIsLegacyString
      ? value
      : value?.label;
    const selectedOptionName = optionsWithValues[existingOptionName]
      ? existingOptionName
      : optionNames[0];

    const draftValue =
      existingValueIsLegacyString || selectedOptionName != existingOptionName
        ? { label: selectedOptionName }
        : { ...value };
    const selectedOption = optionsWithValues[selectedOptionName]!;

    const existingOptionDoesNotMatchCurrentOptions =
      existingOptionName !== selectedOptionName;

    return (
      <>
        <div className="flex flex-col justify-between">
          <div className="justify-self-auto">
            <div className="flex w-full flex-col gap-2">
              <div className="text-left text-xs text-gray-400">
                Select which Product Option to use for this list
              </div>
              <Selectable
                options={optionNames.map((name) => {
                  return { label: name, value: name };
                })}
                onSelect={(newLabel: string) => {
                  onChange({
                    label: newLabel,
                    values: null,
                  });
                }}
                placeholder="Select an option"
                // Note (Noah, 2024-09-01, USE-1259): Use the existing option name specifically
                // here, because the user might have renamed the option in Shopify. If this is
                // the case, we want to show them specifically that they're referencing an option
                // which does not exist
                value={existingOptionName}
              />
              {existingOptionDoesNotMatchCurrentOptions && (
                <div className="text-left text-xs text-red-400">
                  A previously selected option does not exist for the current
                  product. This may be because the option has been renamed in
                  Shopify. Please choose a new option.
                </div>
              )}
              {selectedOption.values.length > 0 && (
                <>
                  <OptionValuesSelector
                    option={selectedOption}
                    selectedValues={draftValue.values!}
                    onChange={(newValues) => {
                      onChange({ ...draftValue, values: newValues });
                    }}
                  />
                  <div className="text-left text-xs text-gray-400 mt-2">
                    Select which values for this Product Option will be shown
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
};
