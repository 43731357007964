import type { ReploElement, ReploElementType } from "schemas/generated/element";
import type { UploadedFont } from "schemas/generated/project";
import type { Component } from "../shared/Component";
import type { RuntimeContextNullableValueMap } from "../shared/runtime-context";
import type { ProductRef, StoreProduct } from "../shared/types";
import type { MediaSize } from "../shared/utils/breakpoints";
import type { GlobalWindow } from "../shared/Window";
import type { Context } from "./AlchemyVariable";
import type { ReploComponentProps } from "./components/ReploComponent";

import * as React from "react";

import classNames from "classnames";
import isEmpty from "lodash-es/isEmpty";
import isEqual from "lodash-es/isEqual";
import { scrollToElement } from "replo-utils/dom/scroll";
import { EMPTY_ARRAY } from "replo-utils/lib/array";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import snippetStyles from "../scss/snippet.scss?inline";
import useScript from "../shared/hooks/useScript";
import {
  CustomFontsContext,
  DynamicDataStoreContext,
  EditorCanvasContext,
  ExtraContext,
  GlobalWindowContext,
  RenderEnvironmentContext,
  ReploElementContext,
  RuntimeContextProviders,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../shared/runtime-context";
import { selectRuntimeElementRootNode } from "../shared/utils/dom";
import {
  buildGoogleFontApiUrl,
  getFontFamiliesForElement,
} from "../shared/utils/font";
import { editorStyles } from "../shared/utils/preview";
import PreviewBanner from "./components/PreviewBanner";
import { ReploComponent } from "./components/ReploComponent";
import { useElementsMethods } from "./hooks/useElementsMethods";
import { useScrollSpy } from "./hooks/useScrollSpy";
import { getRuntimeScriptURL } from "./RuntimeScript";
import {
  shopifyAnnouncementBarSelector,
  shopifyFooterSelector,
  shopifyHeaderSelector,
} from "./utils/cssSelectors";

const shopifyStoresExcludedFromScriptInstallation = new Set([
  "studs-piercing.myshopify.com", // Studs is a special case, they didn't want script installed on all pages
  "snow-maps.myshopify.com", // Snow Maps uses a localhost url directly in its theme for the script
]);

export const hideHeaderCss = `
  ${shopifyHeaderSelector} { display: none !important }
  .main-content { padding-top: 0 !important; margin-top: 0 !important }
`;
export const hideFooterCss = `
  ${shopifyFooterSelector} { display: none !important; }
  .main-content { padding-bottom: 0 !important; margin-bottom: 0 !important; }
`;
export const hideAnnouncementBarCss = `
  ${shopifyAnnouncementBarSelector} { display: none !important; }
`;

// Note (Noah, 2022-05-02): customEditorCss is a few CSS snippets that we add to the page
// by default, only when the editor is active - these are useful for getting around
// issues with certain themes/apps where overlays cover the page and it can't be edited, etc
const customEditorCss = [
  // HACK (Noah, 2021-07-21): We need this custom CSS to be loaded in the editor
  // otherwise Aloha's pages break. Super hacky for now, but doesn't affect other
  // customers - in the future what I think we should do is have a way to specify
  // custom CSS on a per-store or per-element basis and have that configurable in
  // some UI somewhere
  `
  .go-cart__overlay { display: none; }
  .go-cart__modal { display: none; }`,
  // HACK (Noah, 2022-04-46): Turns out there's also an issue with sites using the Debutify
  // theme (a common one) where there's some javascript callback that doesn't get
  // executed so the page transition overlay (which imo doesn't make sense to have at
  // all but cest la vie) doesn't get removed in the editor. We just hardcode it to
  // display: none here.
  `
  .dbtfy-page_transition { display: none; }`,
  // Note (Noah, 2022-05-02): We add this CSS by default in the editor to hide Klaviyo
  // popups, which would otherwise interfere with editing the page.
  `
  *:not(.klaviyo-form, .needsclick) > div[class*="kl-private-reset-css"] { display: none !important; }`,
  // Note (Ovishek 2022-07-20): Need this html height to be auto b/c it's necesassary to calc
  // perfect height of the canvas, which we are currently doing in Canvas.tsx
  `
  html {
    height: auto;
  }
  `,
  // Note (Noah, 2022-10-19, REPL-4645): Adding this specifically for Teapotmerch now
  `
  .age-gate-wrapper {
    filter: none !important;
    display: none !important;
  }
  `,
  // Note (Noah, 2022-10-27, REPL-4770): Some themes set their main element to opacity: 0
  // lol, this is similar to the debutify issue above. This is specifically for Bailey's Blanket
  // Note (Ovishek/Fran): Some people like on REPL-5171, uses body opacity to 0 when page loading.
  // Mirror take html on that weird loading state maybe, so it shows blank all the time in editor.
  // That shouldn't be an issue on the published page.
  `
  main, body {
    opacity: 1 !important;
  }
  `,
].join("\n");

const getRuntimeCss = (element: ReploElement | null) => {
  // Note (Noah, 2023-02-17): Always make overflow-y visible on the body in
  // order to get around themes which start with overflow-hidden and then reset
  // it later (sigh)
  //
  // Note (Noah, 2023-02-16, REPL-6182): We don't set overflow-x to visible,
  // because we've seen this interfere with some themes' carousels on other
  // parts of the page when the element type is a section. Maybe we should
  // revisit using this only for pages (not sections) in the future?
  //
  // Note (Chance, 2023-07-06, USE-279): We can't support `zoom` on the `body`
  // or `html` element because it breaks the editor's ability to accurately
  // calculate the size and position of component bounding boxes.
  // https://replohq.slack.com/archives/C01JYU70754/p1688579617727909
  return `
    html,
    body {
      zoom: unset !important;
    }

    body {
      overflow-y: visible !important;
    }
      ${
        // Reset several CSS properties to 0 for full page and product template
        // elements. This doesn't solve for every theme, but many themes have
        // margin/padding/etc on the main element that messes with our layout.
        // We reset the following:
        // - Margin
        // - Padding
        // - Max width/height (messes with editor preview and leads to confusion)
        ["page", "shopifyProductTemplate"].includes(element?.type ?? "")
          ? `main, #main {
        margin: 0 !important;
        padding: 0 !important;
        max-width: none !important;
        max-height: none !important;
      }`
          : ""
      }
     `;
};

interface AlchemyElementComponentProps {
  triggeredWhen?: any[];
  component: Component;
  id: string;
  type: ReploElementType;
  className?: string;
  style?: React.CSSProperties;
  mediaSize?: MediaSize;
  useSectionSettings: boolean | undefined;
}

const AlchemyElementComponent: React.FC<AlchemyElementComponentProps> = (
  props,
) => {
  const { isEditorApp, isPublishing } = useRuntimeContext(
    RenderEnvironmentContext,
  );
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const { overrideProductLiquidOff } = useRuntimeContext(ExtraContext);
  const { store } = useRuntimeContext(DynamicDataStoreContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();

  const rootComponent = props.component;

  const templateProduct = useTemplateProduct({
    products,
    elementType: props.type,
    isEditorApp,
  });

  const {
    state: { dropdownValues, openModalComponent },
    actionHooks: { openModal, closeCurrentModal, setDropdownValue },
  } = useActionHookState();

  const isEditorEditModeRenderEnvironment =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();

  // TODO (Noah, 2021-08-09): This is here so that dynamic data can see it, and
  // dynamic data depends on the attribute key. There should be a better way to
  // expose this to dynamic data, weird to have it inside attributes
  const attributes = React.useMemo<Context["attributes"]>(() => {
    return { _templateProduct: templateProduct };
  }, [templateProduct]);

  // NOTE (Chance 2024-04-26): This is probably safe to just move outside of the
  // component scope and avoid a useless memoization, but I'm not confident this
  // isn't mutated somewhere along the line so doing this out of caution.
  const state = React.useMemo<Context["state"]>(() => {
    return {};
  }, []);

  const variantTriggers = React.useMemo<Context["variantTriggers"]>(() => {
    return props.type === "shopifyProductTemplate"
      ? { "state.product.templateProductEquals": true }
      : {};
  }, [props.type]);

  const context = React.useMemo<Context>(() => {
    return {
      repeatedIndexPath: "0",
      selfOrParentHasVariants: false,
      elementId: props.id,
      elementType: props.type,
      openModalComponent,
      state,
      attributes,
      dropdownValues,
      variantTriggers,
      actionHooks: {
        openModal,
        closeCurrentModal,
        setDropdownValue,
      },
      isPublishing,
      overrideProductLiquidOff,
      useSectionSettings: props.useSectionSettings,
      store,
      globalWindow,
    };
  }, [
    attributes,
    closeCurrentModal,
    dropdownValues,
    isPublishing,
    openModal,
    openModalComponent,
    overrideProductLiquidOff,
    props.id,
    props.type,
    props.useSectionSettings,
    setDropdownValue,
    state,
    variantTriggers,
    store,
    globalWindow,
  ]);

  if (
    !rootComponent ||
    // NOTE (Gabe 2023-06-23): Non-PDPs must have a container as
    // their root component
    (props.type !== "shopifyProductTemplate" &&
      rootComponent?.type !== "container")
  ) {
    return null;
  }

  return (
    <div
      className={classNames(
        "alchemy__element alchemy-reset",
        props.className,
        // Note (Noah, 2024-07-17): Add this style here if we're in the editor
        // in edit mode (not preview mode) so that we can target nodes there to
        // do things like disable animations
        isEditorEditModeRenderEnvironment && "replo-editor-edit-mode",
      )}
      style={props.style}
    >
      <RootReploComponent
        component={rootComponent}
        context={context}
        repeatedIndexPath=".0"
      />
    </div>
  );
};

const RootReploComponent = React.memo<ReploComponentProps>(
  function RootReploComponent(props) {
    return <ReploComponent {...props} />;
  },
);

function elementTypeSupportsHidingHeaderFooter(elementType: ReploElementType) {
  return exhaustiveSwitch({ type: elementType })({
    page: true,
    shopifyArticle: true,
    shopifyProductTemplate: true,
    shopifySection: false,
  });
}

interface WrappedAlchemyElementProps {
  element: ReploElement;
  // NOTE (Chance 2024-04-09): Ideally we would always render the runtime
  // context providers above the element, but because updates are manually
  // managed in `paint` the context provider won't re-render which means calls
  // to useContext will return stale values. This is a temporary workaround to
  // render the context provider internally in the editor.
  contexts?: RuntimeContextNullableValueMap;
}

export const WrappedAlchemyElement = ({
  element: originalElement,
  contexts,
}: WrappedAlchemyElementProps) => {
  // NOTE (Chance 2024-04-09): In the editor we need to update runtime context
  // manually because of how element updates are managed in the `paint`
  // function. Once the repainter is removed we should be able to get rid of
  // this entirely.
  const [runtimeContexts, setRuntimeContexts] = React.useState(contexts);
  // NOTE (Matt 2024-09-30): On the published page, GoPuff can change product data
  // in the `contexts` prop, data that is coming from the `window.alchemy.features` object.
  // In order to ensure those changes to product data are propogated, we need to compare
  // our props and update the `runtimeContexts`. This is awful and we should remove it
  // when it's okay to no longer support GoPuff.
  React.useEffect(() => {
    if (contexts && !isEqual(contexts, runtimeContexts)) {
      setRuntimeContexts(contexts);
    }
  }, [contexts, runtimeContexts]);

  // NOTE (Chance 2024-04-15): Because we don't know if context was provided via
  // props or a rendered provider, we need to switch between the two until the
  // repainter is removed and context is always rendered above this component.
  const _customFontsContext = React.useContext(CustomFontsContext);
  const customFontsContext = runtimeContexts
    ? runtimeContexts.customFonts
    : _customFontsContext!;

  const _reploElementContext = React.useContext(ReploElementContext);
  const reploElementContext = runtimeContexts
    ? runtimeContexts.reploElement
    : _reploElementContext!;

  const _renderEnvironmentContext = React.useContext(RenderEnvironmentContext);
  const renderEnvironmentContext = runtimeContexts
    ? runtimeContexts.renderEnvironment
    : _renderEnvironmentContext!;
  const _editorCanvasContext = React.useContext(EditorCanvasContext);
  const editorCanvasContext = runtimeContexts
    ? runtimeContexts.editorCanvas
    : _editorCanvasContext!;

  const _globalWindowContext = React.useContext(GlobalWindowContext);
  const globalWindowContext = runtimeContexts
    ? runtimeContexts.globalWindow
    : _globalWindowContext!;

  const _extraContext = React.useContext(ExtraContext);
  const extraContext = runtimeContexts
    ? runtimeContexts.extraContext
    : _extraContext!;

  const _runtimeHooksContext = React.useContext(RuntimeHooksContext);
  const runtimeHooksContext = runtimeContexts
    ? runtimeContexts.runtimeHooks
    : _runtimeHooksContext!;

  const { isEditorApp, previewEnvironment, isElementPreview, isPublishing } =
    renderEnvironmentContext;
  const globalWindow = globalWindowContext;
  const _shopifyStoreContext = React.useContext(ShopifyStoreContext);
  const shopifyStoreContext = runtimeContexts
    ? runtimeContexts.shopifyStore
    : _shopifyStoreContext!;

  const { uploadedFonts } = customFontsContext ?? {};
  const { useSectionSettings } = reploElementContext;
  const { storeUrl } = shopifyStoreContext;

  const [reploElement, setReploElement] =
    React.useState<ReploElement>(originalElement);
  const shouldSkipInstallation =
    shopifyStoresExcludedFromScriptInstallation.has(storeUrl!) ||
    previewEnvironment === null;
  const installationScriptUrl = getRuntimeScriptURL({
    projectId: reploElement.projectId,
    elementId: reploElement.id,
    version: extraContext.runtimeVersion,
    isElementPreview,
  });

  // NOTE (Chance 2024-05-21): We use a ref to track whether or not a scroll has
  // taken place in the canvas. We need this to determine whether or not to
  // scroll to a hashmark once the canvas has fully loaded.
  const hasScrolled = React.useRef(false);

  const [initialHashmark, setInitialHashmark] = React.useState<string>();
  React.useEffect(() => {
    const hashmark = globalWindow?.location.hash;
    if (hashmark) {
      setInitialHashmark((initialHashmark) => initialHashmark ?? hashmark);
    }
  }, [globalWindow]);

  const editorCanvas = editorCanvasContext;
  useScrollSpy(reploElement.id, { hasScrolled, globalWindow });
  useElementsMethods(editorCanvas ?? null, reploElement.id, {
    setRuntimeContexts,
    setReploElement,
    isEditor: isEditorApp,
  });

  useScript(installationScriptUrl, {
    globalWindow,
    forceSkip: shouldSkipInstallation,
  });

  useScrollToHashmarkIfNeeded(reploElement.id, {
    hasScrolled,
    globalWindow,
    initialHashmark,
  });

  const {
    useHideShopifyHeader,
    useHideShopifyFooter,
    useHideShopifyAnnouncementBar,
  } = runtimeHooksContext;

  const hideDefaultHeader = useHideShopifyHeader() ?? false;
  const hideDefaultFooter = useHideShopifyFooter() ?? false;
  const hideShopifyAnnouncementBar = useHideShopifyAnnouncementBar() ?? false;

  const shouldHideHeader =
    (elementTypeSupportsHidingHeaderFooter(reploElement.type) &&
      hideDefaultHeader) ||
    (isEditorApp &&
      !isPublishing &&
      !elementTypeSupportsHidingHeaderFooter(reploElement.type));

  const shouldHideFooter =
    (elementTypeSupportsHidingHeaderFooter(reploElement.type) &&
      hideDefaultFooter) ||
    (isEditorApp &&
      !isPublishing &&
      !elementTypeSupportsHidingHeaderFooter(reploElement.type));

  return (
    <>
      <style
        id="snippet-styles"
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: snippetStyles,
        }}
      />
      <style
        id="alchemy-runtime-css"
        dangerouslySetInnerHTML={{
          __html: getRuntimeCss(reploElement),
        }}
      />
      {/* Note (Noah, 2021-11-03): Don't add a style here if we're publishing,
        because then the element hydration will get messed up which can result in
        style tags not getting rendered for elements which use hydrate. */}
      {isEditorApp && !isPublishing && (
        <>
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html: editorStyles,
            }}
          />
          <style
            id="alchemy-page-custom-css"
            dangerouslySetInnerHTML={{ __html: customEditorCss }}
          />
        </>
      )}
      {shouldHideHeader && (
        <style
          dangerouslySetInnerHTML={{
            __html: hideHeaderCss,
          }}
        />
      )}
      {shouldHideFooter && (
        <style
          dangerouslySetInnerHTML={{
            __html: hideFooterCss,
          }}
        />
      )}
      {(hideShopifyAnnouncementBar ||
        (isEditorApp &&
          !isPublishing &&
          reploElement.type === "shopifySection")) && (
        <style
          dangerouslySetInnerHTML={{
            __html: hideAnnouncementBarCss,
          }}
        />
      )}
      <PageFonts
        component={reploElement.component}
        uploadedFonts={uploadedFonts ?? EMPTY_ARRAY}
        isEditor={isEditorApp}
      />
      {runtimeContexts ? (
        <RuntimeContextProviders {...runtimeContexts!}>
          <AlchemyElementComponent
            id={reploElement.id}
            type={reploElement.type}
            component={reploElement.component}
            useSectionSettings={useSectionSettings}
          />
        </RuntimeContextProviders>
      ) : (
        <AlchemyElementComponent
          id={reploElement.id}
          type={reploElement.type}
          component={reploElement.component}
          useSectionSettings={useSectionSettings}
        />
      )}
      {isElementPreview && <PreviewBanner />}
    </>
  );
};

/**
 * If there's a hashmark we need to initially scroll do (from the current url),
 * execute the scroll
 */
function performInitialScrollToHashmarkIfNeeded(
  elementId: string,
  props: {
    hasScrolled: React.MutableRefObject<boolean>;
    initialHashmark: string | undefined;
    globalWindow: GlobalWindow | null;
  },
) {
  const { hasScrolled, initialHashmark, globalWindow } = props;
  if (!hasScrolled.current && initialHashmark && globalWindow) {
    const targetElement: HTMLElement | null =
      selectRuntimeElementRootNode(
        globalWindow.document,
        elementId,
      )?.querySelector(
        `[data-alchemy-url-hashmark="${initialHashmark.replace("#", "")}"]`,
      ) ?? null;
    if (targetElement) {
      hasScrolled.current = true;
      // Note (Ovishek, 2022-11-24, REPL-5166): This solves the issue with hashmark is not scrolling to proper element
      // Seems like browser always saves previous window.scrollY, and if any re-render happens on ReploComponent it scrolls back to previous position
      // ref: https://www.folkstalk.com/2022/10/react-scroll-to-top-on-page-refresh-dont-restore-position-with-code-examples-2.html
      if (globalWindow.history) {
        globalWindow.history.scrollRestoration = "manual";
      }
      scrollToElement(targetElement, {
        topOffset: Number.parseInt(
          targetElement.dataset?.alchemyUrlHashmarkOffset ?? "0",
        ),
        smoothScroll: false,
      });
    }
  }
}

/**
 * Scroll to hashmark if necessary.
 */
function useScrollToHashmarkIfNeeded(
  elementId: string,
  props: {
    hasScrolled: React.MutableRefObject<boolean>;
    globalWindow: GlobalWindow | null;
    initialHashmark: string | undefined;
  },
) {
  // Good Hack (Ovishek/Noah, 2022-06-25, REPL-2577): We need this useEffect and
  // setHasProcessedHashmark to set hasProcessedHashmark to true to rerender
  // once again - this is a hack to make menu items active when opening in new
  // tab with hashMark. The reason we need this is that in order to activate
  // Replo states, the components have to rerender, but if the page was just
  // hydrated, that will not happen automatically and any initial states that
  // depend on the window.hash will not be activated.
  const { globalWindow, hasScrolled, initialHashmark } = props;
  React.useEffect(() => {
    performInitialScrollToHashmarkIfNeeded(elementId, {
      globalWindow,
      hasScrolled,
      initialHashmark,
    });
  }, [globalWindow, hasScrolled, initialHashmark, elementId]);
}

/**
 * Component that returns a style tag which loads all google fonts for the
 * given component and its descendants.
 *
 * Note (Noah, 2022-11-25, REPL-5254): If you ever see a 403 for this @import
 * url, it could be because we're trying to request a google font that google
 * doesn't actually have. Maybe the Google Fonts list is out of date? See
 * https://github.com/google/fonts/issues/2214 for more info
 */
function PageFonts({
  component,
  uploadedFonts,
  isEditor,
}: {
  component: Component;
  uploadedFonts?: UploadedFont[];
  isEditor: boolean;
}) {
  const { googleFontFamilies, customFontFamilies: elementFonts } =
    getFontFamiliesForElement(component); // on the element
  const googleFontsURL = buildGoogleFontApiUrl(googleFontFamilies);
  // NOTE (Evan, 7/13/23)
  // We do "includes" rather than === because we add quotes to the
  // fonts with spaces in their names.
  const fontsToInclude = uploadedFonts?.filter(
    (uploadedFont) =>
      isEditor ||
      elementFonts.some((elementFont) =>
        elementFont.includes(uploadedFont.name),
      ),
  );

  if (!googleFontsURL && isEmpty(fontsToInclude)) {
    return null;
  }

  return (
    <>
      {googleFontsURL && (
        // Note (Noah, 2023-04-25, USE-92): Use dangerouslySetInnerHTML here because
        // otherwise the ampersands in this URL get escaped automatically by the JSX
        // parser, which means that for pre-rendered font urls with multiple google
        // fonts, only the first font will work. https://github.com/facebook/react/issues/3769
        <style
          dangerouslySetInnerHTML={{ __html: `@import url(${googleFontsURL})` }}
        />
      )}
      {fontsToInclude?.map((f) => (
        <style
          id={`replo-fonts-${f.name}`}
          key={f.name}
          dangerouslySetInnerHTML={{
            __html: `
        @font-face {
          font-family: "${f.name}";
          src: url("${f.url}") format("${f.type}");
        }
      `,
          }}
        />
      ))}
    </>
  );
}

function useTemplateProduct(props: {
  products: StoreProduct[];
  elementType: string;
  isEditorApp: boolean;
}) {
  const templateEditorProduct =
    useRuntimeContext(RuntimeHooksContext).useTemplateEditorProduct();

  const { templateProduct: liquidTemplateProduct } =
    useRuntimeContext(ShopifyStoreContext);

  const { isEditorApp, elementType } = props;

  // NOTE (Matt 2024-09-12): We used to use the first product in the `products` array
  // as the fallback to the templateProduct. However, this would allow for hooks like
  // `useProductFromProps` to return the wrong product in certain circumstainces,
  // like if there are multiple products on a PDP and we're generating the liquid&html
  // for a pdp on publish. We shouldn't even be having a fallback for the templateProduct
  // because it should only be undefined if we are outside of a PDP (in both the editor and
  // the published environment).
  const firstProduct = liquidTemplateProduct;
  const firstProductRef = React.useMemo<ProductRef | undefined>(() => {
    return firstProduct ? { productId: firstProduct.id } : undefined;
  }, [firstProduct]);

  let templateProduct: ProductRef | undefined;
  if (elementType === "shopifyProductTemplate") {
    if (isEditorApp) {
      templateProduct = templateEditorProduct ?? undefined;
    } else {
      templateProduct = firstProductRef;
    }
  }

  return templateProduct;
}

function useActionHookState() {
  const [openModalComponent, setOpenModalComponent] = React.useState<{
    id: string;
    repeatedIndex: string;
  } | null>(null);
  const [dropdownValues, setDropdownValues] = React.useState({});

  const openModal = React.useCallback(
    (componentId: string, repeatedIndex: string) => {
      setOpenModalComponent({ id: componentId, repeatedIndex });
    },
    [],
  );

  const closeCurrentModal = React.useCallback(() => {
    setOpenModalComponent(null);
  }, []);

  const setDropdownValue = React.useCallback(
    (dropdownId: string, value: any) => {
      setDropdownValues((dropdownValues) => {
        if ((dropdownValues as any)?.[dropdownId] === value) {
          return dropdownValues;
        }
        return {
          ...dropdownValues,
          [dropdownId]: value,
        };
      });
    },
    [],
  );

  return {
    state: { openModalComponent, dropdownValues },
    actionHooks: { openModal, closeCurrentModal, setDropdownValue },
  };
}
