import type {
  ModifierType,
  ModifierTypeToControlType,
} from "schemas/modifiers";

import * as React from "react";

import { selectDraftComponentId } from "@editor/reducers/core-reducer";
import { modifierTypeToSelector } from "@editor/selectors/modifiers";
import { useEditorSelector } from "@editor/store";

import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";

export const useGetModifierControls = <T extends ModifierType>(
  modifierType: ModifierType,
): [
  Set<ModifierTypeToControlType[T]>,
  (newControl: ModifierTypeToControlType[T]) => void,
] => {
  const values: Set<ModifierTypeToControlType[T]> = useEditorSelector(
    modifierTypeToSelector[modifierType],
  );
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const [controls, setControls] = useOverridableState<
    Set<ModifierTypeToControlType[T]>
  >(values, undefined, draftComponentId ?? undefined);

  const addControl = React.useCallback(
    (newControl: ModifierTypeToControlType[T]) => {
      const newControls = new Set(controls);
      newControls.add(newControl);
      setControls(newControls);
    },
    [controls, setControls],
  );

  return [controls, addControl];
};
