import * as React from "react";

import { Button } from "@replo/design-system/components/button";
import clsxMerge from "@replo/design-system/components/shadcn/utils/cn-merge";
import classNames from "classnames";

type TriggerSelectProps = {
  isDisabled?: boolean;
  className?: string;
  startEnhancer?: () => React.ReactNode;
  endEnhancer?: () => React.ReactNode;
  staticPlaceholder?: string;
  placeholderPrefix?: string;
  placeholder?: string;
  selectedLabel: string;
  open: boolean;
};

const TriggerSelect: React.FC<TriggerSelectProps> = ({
  isDisabled,
  className,
  startEnhancer,
  endEnhancer,
  staticPlaceholder,
  placeholderPrefix,
  placeholder,
  selectedLabel,
  open,
}) => {
  const displayBoxLabel = () => {
    return (
      <div className="whitespace-nowrap overflow-hidden text-ellipsis">
        {staticPlaceholder ? (
          staticPlaceholder
        ) : (
          <>
            {placeholderPrefix}
            {selectedLabel ?? placeholder}
          </>
        )}
      </div>
    );
  };

  return (
    <Button
      variant="no-style"
      role="combobox"
      aria-expanded={open}
      className={clsxMerge(
        classNames(
          "h-[32px] rounded-[4px] p-2 flex items-center justify-center gap-[8px]",
          {
            " bg-subtle text-slate-800": !isDisabled,
            "border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed":
              isDisabled,
          },
        ),
        className,
      )}
      iconStart={
        startEnhancer ? (startEnhancer() as React.ReactElement) : undefined
      }
      iconEnd={endEnhancer ? (endEnhancer() as React.ReactElement) : undefined}
      innerStyle="flex items-center gap-[8px] w-full justify-between"
      isDisabled={isDisabled}
    >
      <div className="flex text-xs leading-5 items-center justify-center font-normal">
        {displayBoxLabel()}
      </div>
    </Button>
  );
};

export { TriggerSelect };
