import type { HotkeyAction, HotkeyMetaKey } from "@editor/utils/hotkeys";

import * as React from "react";

import Badge from "@common/designSystem/Badge";
import { getHotkeyEditorData } from "@editor/utils/hotkeys";
import { isMacintosh } from "@editor/utils/platform";

import { twMerge } from "tailwind-merge";

const characterAliases: Record<
  HotkeyMetaKey,
  string | { mac: string | React.ReactNode; windows: string | React.ReactNode }
> = {
  meta: {
    mac: "⌘",
    windows: <span style={{ fontSize: "6px", fontWeight: "bold" }}>CTRL</span>,
  },
  shift: "⇧",
  altOption: {
    mac: "⌥",
    windows: "⎇",
  },
  backspace: "⌫",
  delete: "del",
  esc: {
    mac: "⎋",
    windows: <span style={{ fontSize: "6px", fontWeight: "bold" }}>ESC</span>,
  },
  space: "␣",
  arrowRight: "→",
  arrowDown: "↓",
  arrowLeft: "←",
  arrowUp: "↑",
};

export const HotkeyIndicator: React.FC<{
  title?: string;
  hotkey: HotkeyAction;
  className?: string;
  badgeBackgroundColor?: string;
}> = ({ hotkey, title, className, badgeBackgroundColor }) => {
  return (
    <div
      className={twMerge(
        "flex flex-row items-center justify-center gap-1 text-xs",
        className,
      )}
    >
      <span className="block">
        {title ?? getHotkeyEditorData(hotkey).displayName}
      </span>
      <div className="flex flex-row items-center gap-1">
        {getHotkeyEditorData(hotkey)
          .indicatorCharacters.map((character) => {
            const alias = characterAliases[character as HotkeyMetaKey];
            if (alias) {
              if (typeof alias === "string") {
                return { id: character, display: alias };
              }
              return {
                id: character,
                display: !isMacintosh() ? alias.windows : alias.mac,
              };
            }
            return { id: character, display: character.toUpperCase() };
          })
          .map(({ id, display }) => (
            <Badge
              key={id}
              isFilled
              foregroundColor="text-slate-400"
              backgroundColor={badgeBackgroundColor || "bg-slate-200"}
            >
              {display}
            </Badge>
          ))}
      </div>
    </div>
  );
};
