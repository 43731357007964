import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _alchemyEditor_previewProducts: {
      name: "Editor Preview Products",
      type: "products",
      description:
        "Select products to display in the editor preview. This Temporary Cart will act like it contains these products. This has no effect on the real page.",
      defaultValue: null,
    },
  };
}

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: true,
    canContainChildren: true,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
