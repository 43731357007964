import type { Flow } from "schemas/generated/flow";

import { useWorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import { isNewLeftBarUIEnabled } from "@editor/infra/featureFlags";
import { setLeftBarActiveTab } from "@editor/reducers/ui-reducer";
import { useEditorDispatch } from "@editor/store";
import { trpcUtils } from "@editor/utils/trpc";
import useCurrentUser from "@hooks/useCurrentUser";
import useUserProjects from "@hooks/useUserProjects";

import { useNavigate, useSearchParams } from "react-router-dom";

export const useRouterFlowCallbacks = () => {
  const { data } = useUserProjects();
  const projects = data?.allProjects ?? [];
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setWorkspaceId } = useWorkspaceDashboardContext();
  // NOTE (Fran 2024-02-21): Used to get the projectId owned by this user created in step four
  // (are you using solo or team) of the onboarding flow.
  const { user } = useCurrentUser();
  const dispatch = useEditorDispatch();

  const handleResponseNavigation = ({
    nextStep,
    flowSlug,
  }: {
    nextStep: string | null;
    flowSlug: Flow["slug"];
  }) => {
    if (nextStep) {
      return navigate({
        pathname: `/flows/${flowSlug}/${nextStep}`,
        search: searchParams.toString(),
      });
    }
    // NOTE (Sebas, 2024-03-04): We need to set the workspace id when the user finishes the
    // onboarding flow to prevent the sidebar from showing empty data.
    if (user?.workspace?.id) {
      setWorkspaceId(user.workspace.id);
    }
    const firstProjectIdFromLocalStorage =
      localStorage.getItem("firstProjectId");

    // NOTE (Fran 2024-02-22): If the user abandons the flow and returns later,
    // the browser's local storage is likely cleaned up. In that case, we should find the
    // first project created owned by the user and redirect to it.
    const firstProjectIdFromUserProjects =
      projects.find(
        (project) =>
          project.name?.includes("First Project") &&
          project.ownerId === user?.id,
      )?.id ?? projects[0]?.id;
    const projectId =
      firstProjectIdFromLocalStorage ?? firstProjectIdFromUserProjects;
    const pendingAppInstallationId = searchParams.get(
      "pendingAppInstallationId",
    );
    if (!projectId || pendingAppInstallationId) {
      // NOTE (Matt 2024-09-30): we invlidate `findByUserId` before navigating to ensure
      // that if a user is taken to the pendingAppInstallation connection flow, their
      // newly created project is included in the available projects list.
      void trpcUtils.project.findByUserId.invalidate();
      return navigate({
        pathname: "/home",
        search: searchParams.toString(),
      });
    }
    localStorage.removeItem("firstProjectId");

    if (isNewLeftBarUIEnabled()) {
      // NOTE (Fran 2024-10-09): After the Onboarding flow is finished, we send the user to the Editor
      // directly, so we need to be sure that the left bar is open in the pages panel. That way,
      // we can start the Editor Tour flow.
      dispatch(setLeftBarActiveTab("elements"));
    }

    navigate(`/editor/${projectId}`);
  };

  const submitOrSkipStepCallback = ({
    nextStep,
    flowSlug,
  }: {
    nextStep: string | null;
    flowSlug: Flow["slug"];
  }) => {
    handleResponseNavigation({ nextStep, flowSlug });
  };

  const goBackCallback = (
    currentFlowSlug: string | null,
    previousStepId: string,
  ) => {
    navigate({
      pathname: `/flows/${currentFlowSlug}/${previousStepId}`,
      search: searchParams.toString(),
    });
  };

  return { submitOrSkipStepCallback, goBackCallback };
};
