import type { SupportedMediaStyleKey } from "replo-runtime/shared/utils/breakpoints";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";

// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum DraggingTypes {
  Vertical = "vertical",
  Horizontal = "horizontal",
}

// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum DraggingDirections {
  Negative = "negative",
  Positive = "positive",
}

export const getCursor = (
  draggingType?: DraggingTypes,
  draggingDirection?: DraggingDirections,
) => {
  if (draggingType === DraggingTypes.Horizontal) {
    switch (draggingDirection) {
      case DraggingDirections.Positive:
        return "e-resize";
      case DraggingDirections.Negative:
        return "w-resize";
      default:
        return "ew-resize";
    }
  } else if (draggingType === DraggingTypes.Vertical) {
    switch (draggingDirection) {
      case DraggingDirections.Positive:
        return "n-resize";
      case DraggingDirections.Negative:
        return "s-resize";
      default:
        return "ns-resize";
    }
  }
  return "default";
};

export const canvasToStyleMap = {
  desktop: "style",
  tablet: "style@md",
  mobile: "style@sm",
} satisfies Record<EditorCanvas, SupportedMediaStyleKey>;
