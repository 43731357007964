import type { Context } from "../../store/AlchemyVariable";
import type { ProductResolutionDependencies } from "../../store/ReploProduct";
import type { ProductRef, ReploPriceRule } from "../types";
import type { PriceRuleItem } from "./priceRule";

import { filterNulls } from "replo-utils/lib/array";

import { getProduct } from "../../store/ReploProduct";
import { calculateEffectivePrice, isPriceRuleApplicable } from "./priceRule";

export type TempCartItem = { productRef: ProductRef };
export type TempCart = {
  items: TempCartItem[];
};

const itemToPriceRuleItem = (
  item: TempCartItem,
  context: Context,
  productResolutionDependencies: ProductResolutionDependencies,
): PriceRuleItem | null => {
  const product = getProduct(item.productRef, context, {
    productMetafieldValues: {},
    variantMetafieldValues: {},
    products: productResolutionDependencies.products,
    currencyCode: productResolutionDependencies.currencyCode,
    moneyFormat: productResolutionDependencies.moneyFormat,
    language: productResolutionDependencies.language,
    templateProduct: context.attributes?._templateProduct ?? null,
  });
  if (!product) {
    return null;
  }
  return {
    quantity: item.productRef?.quantity || 1,
    variant: product.variant ?? null,
  };
};

export const getPriceIncludingDiscounts = (
  priceRules: ReploPriceRule[],
  item: TempCartItem,
  items: TempCartItem[],
  context: Context,
  productDependencies: ProductResolutionDependencies,
): number => {
  for (const priceRule of priceRules || []) {
    const priceRuleItem = itemToPriceRuleItem(
      item,
      context,
      productDependencies,
    );
    if (!priceRuleItem) {
      continue;
    }
    if (
      isPriceRuleApplicable(
        priceRule,
        priceRuleItem,
        filterNulls(
          items.map((item) =>
            itemToPriceRuleItem(item, context, productDependencies),
          ),
        ),
      )
    ) {
      return calculateEffectivePrice(
        priceRule,
        priceRuleItem,
        filterNulls(
          items.map((item) =>
            itemToPriceRuleItem(item, context, productDependencies),
          ),
        ),
      );
    }
  }
  return (
    Number(
      getProduct(item.productRef, context, {
        productMetafieldValues: {},
        variantMetafieldValues: {},
        products: productDependencies.products,
        currencyCode: productDependencies.currencyCode,
        moneyFormat: productDependencies.moneyFormat,
        language: productDependencies.language,
        templateProduct: productDependencies.templateProduct,
      })?.variant?.price,
    ) || 0
  );
};
