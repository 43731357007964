import type { ComponentTemplate } from "@editor/types/component-template";

import { getCanBeAddedFunction } from "@editor/utils/template";

import { Env } from "replo-runtime/shared/enums";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { v4 as uuidv4 } from "uuid";

const buyNow: ComponentTemplate = {
  id: "8bdd764a-8aea-43a9-980f-379ff9928dc7",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Buy Now Button",
  leftBarCategory: { main: "product", subCategory: "buttons" },
  tags: ["product", "product buy now button"],
  preview: "product/buy-now.svg",

  transforms: [
    (component, parent) => {
      if (!parent) {
        return component;
      }
      const context = getCurrentComponentContext(parent.id, 0);
      if (context?.attributes?.["_product"]) {
        component.props.onClick = [
          {
            id: uuidv4(),
            type: "addProductVariantToCart",
            value: {
              redirectToCart: false,
              redirectToCheckout: true,
              product: {
                type: "contextRef",
                ref: "attributes._variant",
              },
            },
          },
        ];
      }
      return component;
    },
  ],
  template: {
    id: "95749ccb-5c63-45a1-a61b-eb2efcb9e3a4",
    type: "button",
    props: {
      style: {
        color: "#FFFFFF",
        fontSize: "20px",
        fontWeight: "700",
        lineHeight: "20px",
        paddingTop: "20px",
        paddingLeft: "32px",
        paddingRight: "32px",
        letterSpacing: "2px",
        paddingBottom: "20px",
        backgroundColor: "#000000FF",
      },
    },
    children: [
      {
        id: "e46b3fa0-a50e-48cb-bf51-a0167651991c",
        type: "text",
        props: {
          text: "<p>Buy Now</p>",
          style: {
            letterSpacing: "2px",
            color: "#FFFFFF",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "20px",
          },
        },
      },
    ],
  },
  canBeAddedAsChild: getCanBeAddedFunction(
    "product",
    "Product Buy Now Buttons can only be added inside Product boxes.",
  ),
};

export default buyNow;
