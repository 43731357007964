import type {
  ObjectRepeatType,
  ObjectSizeType,
} from "@editor/components/common/designSystem/BackgroundAssetPicker";
import type { GradientStop, SolidOrGradient } from "replo-runtime/shared/types";

import * as React from "react";

import BackgroundAssetPicker from "@editor/components/common/designSystem/BackgroundAssetPicker";
import InlineAssetSelector from "@editor/components/common/designSystem/InlineAssetSelector";
import Popover from "@editor/components/common/designSystem/Popover";
import { useGetModifierControls } from "@editor/hooks/rightBar/useGetModifierControls";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useModal } from "@editor/hooks/useModal";
import { isNewRightBarUIEnabled } from "@editor/infra/featureFlags";
import {
  selectBackgroundColor,
  selectBackgroundGradientStops,
  selectBackgroundGradientTilt,
  selectBackgroundImage,
  selectBackgroundPositionX,
  selectBackgroundPositionY,
  selectBackgroundRepeat,
  selectBackgroundSize,
  selectDraftComponentContext,
  selectDraftComponentId,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import DocumentationInfoIcon from "@editorComponents/DocumentationInfoIcon";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";
import ModifierGroup from "@editorExtras/ModifierGroup";
import DynamicColorModifier from "@editorModifiers/DynamicColorModifier";
import { hasDynamicData } from "@editorModifiers/utils";

import classNames from "classnames";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { evaluateVariableAsString } from "replo-runtime/store/AlchemyVariable";

import ModifierLabel from "../extras/ModifierLabel";

export const BackgroundModifier: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { src } = useBackgroundImage();
  const backgroundColor = useEditorSelector(selectBackgroundColor);
  const [controls, addControl] =
    useGetModifierControls<"background">("background");

  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  const hasControlsToRender = isNewRightBarEnabled ? controls.size > 0 : true;
  return (
    <ModifierGroup
      title="Background"
      titleEnhancer={<DocumentationInfoIcon documentationType="background" />}
      isDefaultOpen={Boolean(src) || Boolean(backgroundColor)}
      isCollapsible={hasControlsToRender}
      menuItems={
        isNewRightBarEnabled
          ? [
              {
                id: "color",
                title: "Color",
                onSelect: () => addControl("backgroundColor"),
                type: "leaf",
                isDisabled: controls.has("backgroundColor"),
              },
              {
                id: "image",
                title: "Image",
                onSelect: () => addControl("backgroundImage"),
                type: "leaf",
                isDisabled: controls.has("backgroundImage"),
              },
            ]
          : undefined
      }
    >
      {hasControlsToRender && (
        <div className="flex flex-col w-full gap-1">
          {controls.has("backgroundImage") || !isNewRightBarEnabled ? (
            <BackgroundImageControl />
          ) : null}
          {controls.has("backgroundColor") || !isNewRightBarEnabled ? (
            <BackgroundColorControl />
          ) : null}
        </div>
      )}
    </ModifierGroup>
  );
};

const BackgroundImageControl: React.FC = () => {
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const backgroundSize = useEditorSelector(
    selectBackgroundSize,
  ) as ObjectSizeType;
  const backgroundRepeat = useEditorSelector(
    selectBackgroundRepeat,
  ) as ObjectRepeatType;
  const backgroundPositionX = useEditorSelector(selectBackgroundPositionX);
  const backgroundPositionY = useEditorSelector(selectBackgroundPositionY);
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const applyComponentAction = useApplyComponentAction();
  const modal = useModal();
  const { src, assetSrc, removeImageSrc } = useBackgroundImage();

  const [isOpen, setIsOpen] = React.useState(false);

  const onClickDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.URL,
        referrerData: {
          type: "style",
          styleAttribute: "backgroundImage",
        },
        initialPath: getPathFromVariable(src!),
      },
    });
  };

  const openModal = () => {
    modal.openModal({
      type: "assetLibraryModal",
      props: {
        referrer: "modifier/background",
        value: assetSrc!,
        assetContentType: "image",
      },
    });
  };

  const openPopover = () => {
    setIsOpen(true);
  };

  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  return (
    <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
      <div
        className={classNames({
          "flex items-center w-full": isNewRightBarEnabled,
        })}
      >
        {isNewRightBarEnabled ? <ModifierLabel label="Source" /> : null}
        {typeof src === "string" && src?.includes("{{") ? (
          <DynamicDataValueIndicator
            type="color"
            templateValue={src}
            onClick={openPopover}
            onRemove={removeImageSrc}
            componentId={draftComponentId ?? undefined}
          />
        ) : (
          <InlineAssetSelector
            size="sm"
            swatchTooltip="Background Image"
            emptyTitle="Choose Image"
            onClickSelectAsset={openPopover}
            onRemoveAsset={removeImageSrc}
            allowRemoveAsset
            allowsDynamicData={hasDynamicData(draftComponentId)}
            onClickDynamicData={onClickDynamicData}
            asset={{
              type: "image",
              src: assetSrc!,
            }}
            onInputChange={(url) =>
              applyComponentAction({
                type: "setStyles",
                value: {
                  // Note (Sebas, 2022-10-12): We need to use double quotes to prevent
                  // data:image sources which may contain simple quotes from not working.
                  backgroundImage: `url("${url}")`,
                  backgroundSize: "cover",
                },
              })
            }
          />
        )}
      </div>
      <Popover.Anchor className="relative top-0 left-0" />
      {isOpen && (
        <Popover.Content
          title="Choose Image"
          shouldPreventDefaultOnInteractOutside={areModalsOpen}
        >
          <BackgroundAssetPicker
            url={assetSrc ?? undefined}
            emptyTitle="No Image Selected"
            selectAssetTitle="Select Background"
            changeAssetTitle="Change Background"
            onClickSelectAsset={openModal}
            onClickDynamicDataForUrl={onClickDynamicData}
            backgroundSizeValue={backgroundSize}
            onChangeBackgroundSize={(value: any) => {
              applyComponentAction({
                type: "setStyles",
                value: { backgroundSize: value },
              });
            }}
            backgroundPositionValue={{
              x: backgroundPositionX ? String(backgroundPositionX) : undefined,
              y: backgroundPositionY ? String(backgroundPositionY) : undefined,
            }}
            onChangeBackgroundPositionX={(value: any) => {
              applyComponentAction({
                type: "setStyles",
                value: { backgroundPositionX: value },
              });
            }}
            onChangeBackgroundPositionY={(value: any) => {
              applyComponentAction({
                type: "setStyles",
                value: { backgroundPositionY: value },
              });
            }}
            backgroundRepeatValue={backgroundRepeat ?? "repeat"}
            onChangeBackgroundRepeat={(value: any) => {
              applyComponentAction({
                type: "setStyles",
                value: { backgroundRepeat: value },
              });
            }}
            allowsSettingDynamicData
          />
        </Popover.Content>
      )}
    </Popover>
  );
};

const BackgroundColorControl: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const backgroundColor = useEditorSelector(selectBackgroundColor);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const backgroundGradientTilt = useEditorSelector(
    selectBackgroundGradientTilt,
  );
  const backgroundGradientStops = useEditorSelector(
    selectBackgroundGradientStops,
  ) as GradientStop[];
  const isNewRightBarEnabled = isNewRightBarUIEnabled();

  return (
    <div className={isNewRightBarEnabled ? "flex items-center" : undefined}>
      {isNewRightBarEnabled ? <ModifierLabel label="Color" /> : null}
      <DynamicColorModifier
        previewProperty="backgroundColor"
        gradientSelectionType="backgroundColor"
        gradientData={{
          tilt: String(backgroundGradientTilt ?? "90deg"),
          stops: backgroundGradientStops || [
            { color: "#df9393", location: "0%" },
          ],
        }}
        field="style.backgroundColor"
        value={backgroundColor ?? undefined}
        popoverTitle="Background Color"
        popoverSideOffset={isNewRightBarEnabled ? 86 : undefined}
        onChange={(value: SolidOrGradient) => {
          const solidOrGradient = value;
          if (solidOrGradient.type === "solid") {
            applyComponentAction({
              componentId: draftComponentId,
              type: "setStyles",
              value: { backgroundColor: solidOrGradient.color },
            });
          } else {
            const gradient = solidOrGradient.gradient;
            applyComponentAction({
              componentId: draftComponentId,
              type: "setStyles",
              value: {
                backgroundColor: "alchemy:gradient",
                __alchemyGradient__backgroundColor__tilt: gradient.tilt,
                __alchemyGradient__backgroundColor__stops: gradient.stops,
              },
            });
          }
        }}
      />
    </div>
  );
};

function useBackgroundImage() {
  const applyComponentAction = useApplyComponentAction();
  const draftComponentContext = useEditorSelector(selectDraftComponentContext);
  const rawBackgroundImage = useEditorSelector(selectBackgroundImage);
  const backgroundImage =
    rawBackgroundImage &&
    rawBackgroundImage?.length > 0 &&
    !rawBackgroundImage.includes("{{")
      ? rawBackgroundImage.slice(5, -2)
      : rawBackgroundImage;

  const removeImageSrc = React.useCallback(() => {
    applyComponentAction({
      type: "setStyles",
      value: { backgroundImage: "" },
    });
  }, [applyComponentAction]);

  return {
    src: backgroundImage,
    assetSrc:
      backgroundImage?.includes("{{") && draftComponentContext
        ? evaluateVariableAsString(backgroundImage, draftComponentContext)
        : backgroundImage,
    removeImageSrc,
  };
}
