import * as React from "react";

import Button from "@common/designSystem/Button";
import Input from "@common/designSystem/Input";
import Modal from "@common/designSystem/Modal";
import { ModalLayout } from "@common/ModalLayout";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";

import LabeledControl from "../common/designSystem/LabeledControl";

type FormValues = {
  code: string;
};

export const AddReferralCodeModal = () => {
  const { closeModal } = useModal();
  const analytics = useLogAnalytics();
  const { workspaceId } = useParams();
  const { user } = useCurrentUser();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      code: "",
    },
  });
  const codeValue = useWatch({ control, name: "code" });
  const hasCodeErrors = !isEmpty(errors.code);

  const { mutateAsync: createReferralCode, isPending: isLoadingCreation } =
    trpc.referralCode.create.useMutation({
      onSuccess: async () => {
        if (workspaceId) {
          void trpcUtils.workspace.getById.invalidate({
            id: workspaceId,
          });
        }
      },
    });

  const onSubmit = ({ code }: FormValues) => {
    if (!workspaceId) {
      return;
    }
    void createReferralCode({
      workspaceId,
      code,
    }).finally(() => {
      analytics("referralCode.created", {
        workspaceId,
        email: user?.email ?? "",
        code: code,
      });
      closeModal({ type: "addReferralCodeModal" });
    });
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        closeModal({ type: "addReferralCodeModal" });
      }}
      className="w-auto"
    >
      <ModalLayout
        height="auto"
        width="25vw"
        minWidth={400}
        mainContent={() => (
          <div className="flex flex-col gap-6 p-2 w-full">
            <h2 className="text-xl font-medium">Create Referral Code</h2>
            <form
              className="flex flex-col gap-6"
              onSubmit={(data) => {
                void handleSubmit(onSubmit)(data);
              }}
            >
              <div className="flex flex-col gap-2">
                <LabeledControl label="Referral Code">
                  <Input
                    id="referralCode"
                    aria-invalid={hasCodeErrors ? "true" : undefined}
                    aria-describedby={
                      hasCodeErrors ? "error-referral-code" : undefined
                    }
                    autoComplete="off"
                    placeholder="Enter referral code"
                    {...register("code", {
                      required: "Please enter the referral code.",
                    })}
                    type="text"
                    size="base"
                    validityState={hasCodeErrors ? "invalid" : "valid"}
                    autoFocus
                  />
                </LabeledControl>
                <ErrorMessage
                  id="error-referral-code"
                  error={errors.code?.message}
                />
              </div>
              <Button
                type="primary"
                size="base"
                className="self-end"
                textClassNames="text-xs"
                htmlType="submit"
                isLoading={isLoadingCreation}
                isDisabled={hasCodeErrors || !codeValue}
              >
                Create
              </Button>
            </form>
          </div>
        )}
      />
    </Modal>
  );
};
