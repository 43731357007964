import type { PlanInfo } from "schemas/billing";
import type { BillingTier } from "schemas/generated/billing";
import type { Workspace } from "schemas/generated/workspace";
import type { BillingModalProps } from "../AppModalTypes";

import * as React from "react";

import { ModalLayout } from "@common/ModalLayout";
import {
  Button,
  ButtonLink,
} from "@editor/components/common/designSystem/Button";
import Modal from "@editor/components/common/designSystem/Modal";
import {
  ConnectShopifyCallout,
  ConnectShopifyIntegrationCta,
} from "@editor/components/editor/page/ConnectShopifyCallout";
import {
  useSubscriptionDetails,
  useSubscriptionInfo,
} from "@editor/hooks/subscription";
import useInitializeSubscriptionUpdateMutation from "@editor/hooks/trpc/useInitializeSubscriptionUpdateMutation";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { selectDraftElementIsPublished } from "@editor/reducers/core-reducer";
import { useGetBillingInformationQuery } from "@editor/reducers/sanity-reducer";
import { useEditorSelector } from "@editor/store";
import { generateEditorPathname } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import { Spinner } from "@replo/design-system/components/spinner";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import { BsArrowRight, BsCheck } from "react-icons/bs";
import { useParams, useSearchParams } from "react-router-dom";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { isSelfServeSubscription } from "replo-utils/billing";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { pluralize } from "replo-utils/lib/pluralize";
import {
  BILLING_PLAN_CONFIRMATION_PARAM,
  BILLING_PLAN_CONFIRMATION_TYPE_PARAM,
  BillingTiers,
  CUSTOM_PLAN_INFO,
  regularBillingPlans,
} from "schemas/billing";
import { twMerge } from "tailwind-merge";

import { BillingConfirmation } from "./shared/BillingConfirmation";

type WorkspaceWithShopifyIntegration = Workspace & {
  hasShopifyIntegration: boolean;
};

const getMessage = ({
  key,
  isElementPublished,
  usage,
}: {
  key?: string;
  usage?: { current: number; maximum: number };
  isElementPublished?: boolean;
}): { primary: string; secondary?: string } => {
  switch (key) {
    case "billingPlan.experimentActivationAttempt":
      return {
        primary: "A/B Testing is available only to users on the Growth plan.",
        secondary: "Please upgrade your plan to activate your experiment.",
      };
    case "billingPlan.projectMembershipLimitExceeded":
      return {
        primary:
          "You've reached a limit on the collaborators allowed for this plan.",
        secondary: "Please upgrade from your current plan.",
      };
    case "billingPlan.entitlements.insufficient.ai.credits":
      return {
        primary: "You've reached a limit on the Replo AI usage for this plan.",
        secondary:
          "Please upgrade from your current plan or wait until the next billing cycle to continue.",
      };
    case "billingPlan.entitlements.insufficient.elements.publishedPages":
    case "billingPlan.entitlements.insufficient.elements.publishedSections":
    case "billingPlan.entitlements.insufficient.elements.publishedProductTemplates": {
      const elementNameSingular = exhaustiveSwitch({ type: key })({
        "billingPlan.entitlements.insufficient.elements.publishedPages": "page",
        "billingPlan.entitlements.insufficient.elements.publishedSections":
          "section",
        "billingPlan.entitlements.insufficient.elements.publishedProductTemplates":
          "product template",
      });
      const elementNamePlural = exhaustiveSwitch({ type: key })({
        "billingPlan.entitlements.insufficient.elements.publishedPages":
          "pages",
        "billingPlan.entitlements.insufficient.elements.publishedSections":
          "sections",
        "billingPlan.entitlements.insufficient.elements.publishedProductTemplates":
          "product templates",
      });
      if (usage && usage.maximum !== 0) {
        const elementsToUnpublish =
          usage.current - usage.maximum + (isElementPublished ? 0 : 1);
        return {
          primary: `You've reached the limit on published ${elementNameSingular}s allowed for this plan.`,
          secondary: `Please upgrade from your current plan or unpublish ${elementsToUnpublish} ${pluralize({ singular: elementNameSingular, plural: elementNamePlural, count: elementsToUnpublish })} to continue.`,
        };
      }
      return {
        primary: `Upgrade to publish this ${elementNameSingular}`,
      };
    }
    case "billingPlan.entitlements.insufficient.integrations.shopify": {
      const isAtOrOverLimit = usage && usage.current >= usage.maximum;
      if (isAtOrOverLimit) {
        const storesToDisconnect = usage.current - usage.maximum + 1;
        return {
          primary:
            "You've reached the limit of the number of Shopify stores you can connect.",
          secondary: `Please upgrade from your current plan or disconnect ${pluralize({ singular: "store", plural: "stores", count: storesToDisconnect })} to continue.`,
        };
      }
      return {
        primary:
          "You've hit the integration limit with your current billing method",
        secondary:
          "To have more integrations available on your plan, please contact support via chat or support@replo.app to enable these integrations.",
      };
    }
    default:
  }
  return {
    primary: "Upgrade your workspace to access more features 🚀",
    secondary: undefined,
  };
};

const getTierIndex = (tier: BillingTier) => {
  const index = regularBillingPlans.findIndex((plan) => plan.tier === tier);
  return index === -1 ? undefined : index;
};

export const BillingModal = (props: BillingModalProps) => {
  // NOTE (Gabe 2024-03-25): This Modal can be used either from within the
  // editor, where a project is selectable, or from the workspace route where a
  // workspaceId is passed in the URL. We need to handle both cases.
  const workspaceId = useCurrentWorkspaceId();
  const isWorkspaceOwner = useIsWorkspaceOwner(workspaceId);

  // Note (Evan, 2024-03-28): If the billing plan confirmation param is set, just
  // render the confirmation component
  const [searchParams] = useSearchParams();
  const isBillingPlanConfirmed =
    searchParams.get(BILLING_PLAN_CONFIRMATION_PARAM) === "true";

  const { data } = trpc.workspace.getById.useQuery(
    workspaceId && isWorkspaceOwner ? { id: workspaceId } : skipToken,
  );

  if (isBillingPlanConfirmed) {
    return <BillingConfirmationModal />;
  }

  const { workspace } = data ?? {};

  return <InnerBillingModal workspace={workspace} {...props} />;
};

const BillingConfirmationModal = () => {
  const modal = useModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const confirmationType = searchParams.get(
    BILLING_PLAN_CONFIRMATION_TYPE_PARAM,
  );

  const { title, body } = (() => {
    if (confirmationType === "cancel") {
      return {
        title: "You successfully canceled your plan.",
        body: "We'll miss you! Don't forget, our free tier is always here for you. 👋🏻",
      };
    }
    if (confirmationType === "upgrade") {
      return {
        title: "Congratulations!",
        body: "You just upgraded your plan. 🥳",
      };
    }
    if (confirmationType === "downgrade") {
      return {
        title: "",
        body: "",
      };
    }
    return {
      title: "Congratulations!",
      body: "You've updated your plan. 🥳",
    };
  })();

  const onClose = () => {
    setSearchParams(
      (params) => {
        params.delete(BILLING_PLAN_CONFIRMATION_PARAM);
        if (params.has(BILLING_PLAN_CONFIRMATION_TYPE_PARAM)) {
          params.delete(BILLING_PLAN_CONFIRMATION_TYPE_PARAM);
        }
        return new URLSearchParams(params);
      },
      { replace: true },
    );
    modal.closeModal({ type: "billingModal" });
  };

  return (
    <Modal
      isOpen
      className="h-auto w-auto overflow-scroll no-scrollbar"
      includesCloseIcon
      data-testid="billing-plan-confirmation-modal"
      onRequestClose={onClose}
    >
      <ModalLayout
        width={1000}
        height={600}
        wrapperClassnames="overflow-scroll"
        mainContent={() => (
          <BillingConfirmation title={title} body={body} onClose={onClose} />
        )}
      />
    </Modal>
  );
};

const InnerBillingModal = ({
  billingPlanMessageKey,
  source,
  usage,
  workspace,
}: BillingModalProps & {
  workspace?: WorkspaceWithShopifyIntegration;
}) => {
  const modal = useModal();
  const analytics = useLogAnalytics();
  const workspaceId = workspace?.id;
  const params = useParams();

  const { data: subscriptionDetails } = useSubscriptionDetails(workspaceId);
  const { subscriptionInfo: currentPlan, isLoading } =
    useSubscriptionInfo(workspaceId);

  const isWorkspaceOwner = useIsWorkspaceOwner(workspaceId);
  const { user } = useCurrentUser();

  const isSelfServe = isSelfServeSubscription(subscriptionDetails ?? undefined);

  const [selectedTier, setSelectedTier] = useOverridableState(
    currentPlan.next?.tier,
  );

  const {
    initializeSubscriptionUpdate,
    isLoading: isInitializeSubscriptionUpdate,
  } = useInitializeSubscriptionUpdateMutation();

  const initializeTierChange = React.useCallback(
    (tier: BillingTier) => {
      const makeTierChange = () => {
        if (!workspace) {
          return;
        }
        setSelectedTier(tier);
        const location = window.location;
        let pathname = location.pathname;
        // Note (Fran, 2024-08-08): If we're coming from the editor page, we want to
        // redirect to the project page, not the editor page. This is beacause Shopify has a
        // limit of 255 characters for return_url length, and passing the elementId param can hit
        // that limit
        if (
          params.projectId &&
          params.elementId &&
          (subscriptionDetails?.paymentProcessor === "shopify" ||
            (!subscriptionDetails?.paymentProcessor &&
              workspace.preferredPaymentProcessor === "shopify"))
        ) {
          pathname = generateEditorPathname("", {
            projectId: params.projectId,
          });
        }
        // Note (Noah, 2022-11-02, REPL-4890): We used to try to pass query params
        // along to this nextUrl, but the problem is that Shopify has a limit of 255
        // characters for return_url length, and passing params can hit that limit
        const nextUrl = `${location.origin}${pathname}`;
        void initializeSubscriptionUpdate({
          workspaceId: workspace.id,
          tier,
          nextUrl,
          source,
        }).then(() => {
          if (workspace.referralCode?.code) {
            analytics("referralCode.redeemed", {
              workspaceId: workspace.id,
              code: workspace.referralCode.code,
              referralCodeId: workspace.referralCode.id,
              email: user?.email ?? "",
            });
          }
        });
      };
      if (
        subscriptionDetails?.paymentProcessor === "shopify" &&
        workspace?.preferredPaymentProcessor === "stripe"
      ) {
        modal.closeModal({ type: "billingModal" });
        modal.openModal({
          type: "billingStripeNotificationModal",
          props: {
            onConfirm: makeTierChange,
            buttonText: "Continue",
          },
        });
      } else {
        makeTierChange();
      }
    },
    [
      analytics,
      initializeSubscriptionUpdate,
      modal,
      params.elementId,
      params.projectId,
      setSelectedTier,
      source,
      subscriptionDetails,
      user?.email,
      workspace,
    ],
  );

  const isActiveStripeSubscription =
    subscriptionDetails?.paymentProcessor === "stripe";

  // Note (Evan, 2024-03-26): There are 3 cases to account for here:
  // 1) If there's an active Stripe subscription, redirect to the billing plan confirmation modal
  // 2) If there's no active Stripe subscription and we're downgrading, redirect to the survey modal
  // 3) If there's no active Stripe subsbscription and we're upgrading, initiate the update immediately
  // (this is because Shopify (for upgrades) and Stripe (for new subscriptions) require confirmation already)
  const handleSubmit = (tier: BillingTier) => {
    const currentTierIndex = getTierIndex(currentPlan.tier);
    const newTierIndex = getTierIndex(tier);
    const isDowngrade =
      currentTierIndex !== undefined &&
      newTierIndex !== undefined &&
      newTierIndex < currentTierIndex;

    if (!workspace) {
      return;
    }

    if (!isActiveStripeSubscription) {
      if (isDowngrade) {
        modal.closeModal({ type: "billingModal" });
        modal.openModal({
          type: "billingPlanChangeSurvey",
          props: {
            tier: currentPlan.tier,
            downgradeTo: tier,
            source,
            type: "downgrade",
            workspaceId: workspace.id,
          },
        });
      } else {
        initializeTierChange(tier);
      }
      return;
    }

    modal.closeModal({ type: "billingModal" });
    modal.openModal({
      type: "billingUpgradeDowngradeModal",
      props: {
        workspaceId: workspace.id,
        currentTier: currentPlan.tier,
        newTier: tier,
        type: isDowngrade ? "downgrade" : "upgrade",
        source,
      },
    });
  };

  const isALowerTier = (plan: PlanInfo) => {
    const currentBillingTier: BillingTier | undefined =
      currentPlan?.tier === "custom" ? "enterprise" : currentPlan?.tier;
    const currentTierOrder = regularBillingPlans.find(
      (pl) => pl.tier === currentBillingTier,
    )?.order;
    if (plan?.order && currentTierOrder) {
      return plan.order < currentTierOrder;
    }
    return false;
  };

  // Note (Evan, 2023-10-09): We don't want to show the basic plan when product template publishing
  // isn't allowed, since the basic plan doesn't allow publishing product templates either
  // NOTE (Ben, 2023-12-29): We basically want to show only the growth plan when the user
  // isn't on the growth plan and opens this modal through the experiment activation attempt,
  // because only the growth, enterprise, and custom plans that get a/b testing,
  // and we don't show enterprise or custom inside the modal in a line up, ever.
  const plansToExclude = (() => {
    if (
      billingPlanMessageKey ===
      "billingPlan.entitlements.insufficient.elements.publishedProductTemplates"
    ) {
      return [BillingTiers.BASIC, BillingTiers.ENTERPRISE];
    }
    if (billingPlanMessageKey === "billingPlan.experimentActivationAttempt") {
      return [
        BillingTiers.FREE,
        BillingTiers.BASIC,
        BillingTiers.STANDARD,
        BillingTiers.ENTERPRISE,
      ];
    }
    // NOTE (Gabe 2024-03-25): We only exclude plans if this is the result of a
    // billingPlanException. This is because we only want to show upgrades when
    // a user hits a billing limit, but if they are just managing their plan the
    // should see all of them.
    if (billingPlanMessageKey) {
      if (currentPlan?.tier === BillingTiers.BASIC) {
        return [BillingTiers.BASIC, BillingTiers.ENTERPRISE];
      }
      if (currentPlan?.tier === BillingTiers.STANDARD) {
        return [
          BillingTiers.BASIC,
          BillingTiers.STANDARD,
          BillingTiers.ENTERPRISE,
        ];
      }
      if (currentPlan?.tier === BillingTiers.GROWTH) {
        return [BillingTiers.BASIC, BillingTiers.STANDARD, BillingTiers.GROWTH];
      }
    }
    return [BillingTiers.ENTERPRISE];
  })();

  const filteredPlans = regularBillingPlans.filter(
    (plan) => !plansToExclude.includes(plan.tier),
  );

  const showingSinglePlan = filteredPlans.length === 1;
  const isElementPublished = useEditorSelector(selectDraftElementIsPublished);

  if (isLoading) {
    return null;
  }

  const hasExhaustedIntegrationsOnShopifyPlan =
    billingPlanMessageKey ===
      "billingPlan.entitlements.insufficient.integrations.shopify" &&
    usage &&
    usage.current < usage.maximum;

  let { primary: billingHeader, secondary: billingSubheader } = getMessage({
    key: billingPlanMessageKey,
    usage,
    isElementPublished,
  });

  if (!isWorkspaceOwner) {
    billingHeader =
      "You don't have permission to update the billing plan, contact your workspace owner to upgrade.";
  }

  const shouldUserConnectShopify =
    workspace?.preferredPaymentProcessor === "shopify" &&
    !workspace?.hasShopifyIntegration &&
    isWorkspaceOwner;

  if (shouldUserConnectShopify) {
    return (
      <Modal
        isOpen
        onRequestClose={() => {
          modal.closeModal({ type: "billingModal" });
        }}
        className="h-auto w-auto"
        includesCloseIcon
        data-testid="billing-modal"
      >
        <ConnectShopifyCallout
          type="billingModal"
          className="p-4 max-w-[300px] gap-6"
        />
      </Modal>
    );
  }

  return (
    <Modal
      isOpen
      onRequestClose={() => {
        modal.closeModal({ type: "billingModal" });
      }}
      className="h-auto w-auto"
      includesCloseIcon
      data-testid="billing-modal"
      onEnterKeyPress={() => selectedTier && handleSubmit(selectedTier)}
    >
      {!isSelfServe ? (
        <ModalLayout
          width={400}
          height="auto"
          wrapperClassnames="overflow-auto"
          mainContent={() => {
            return (
              <div className="flex h-full flex-col justify-between gap-2">
                {billingSubheader && (
                  <p className="self-center text-center text-base text-slate-400">
                    {billingSubheader}
                  </p>
                )}
                <div className="flex w-full justify-center">
                  <PlanInfoComponent
                    plan={CUSTOM_PLAN_INFO}
                    planSelected="custom"
                    isCurrentTier
                    isALowerTier={false}
                    isSelectable={false}
                    onSubmit={handleSubmit}
                    isLoading={isInitializeSubscriptionUpdate}
                    isCustomPlan={true}
                    workspace={workspace}
                  />
                </div>
              </div>
            );
          }}
        />
      ) : (
        <ModalLayout
          width="auto"
          height="auto"
          wrapperClassnames="overflow-auto"
          mainContent={() => {
            return (
              <div className="flex h-full flex-col justify-between gap-2 w-full">
                <div className="flex flex-col">
                  <p className="self-center text-lg font-medium text-center">
                    {billingHeader}
                  </p>
                  {billingSubheader && (
                    <p className="self-center pb-2 text-center text-base text-slate-400">
                      {billingSubheader}
                    </p>
                  )}
                  <div
                    className={classNames(
                      "flex w-full justify-center overflow-scroll no-scrollbar",
                      {
                        "pt-4": filteredPlans.length > 1,
                      },
                    )}
                  >
                    {!hasExhaustedIntegrationsOnShopifyPlan &&
                      filteredPlans.map((plan) => {
                        return (
                          <PlanInfoComponent
                            key={plan.tier}
                            plan={plan}
                            compact={showingSinglePlan}
                            planSelected={selectedTier}
                            isCurrentTier={currentPlan.tier === plan.tier}
                            isALowerTier={isALowerTier(plan)}
                            isSelectable={currentPlan.tier !== plan.tier}
                            onSubmit={handleSubmit}
                            isLoading={isInitializeSubscriptionUpdate}
                            isCustomPlan={false}
                            workspace={workspace}
                          />
                        );
                      })}
                  </div>
                </div>
                {currentPlan.tier !== BillingTiers.FREE && (
                  <div>
                    <hr className="my-1 border-slate-200" />
                    <div className="flex flex-row justify-between pt-1 text-sm">
                      <p>Looking to cancel your Replo Plan?</p>
                      <div
                        className="flex cursor-pointer flex-row items-center gap-2 text-blue-500 hover:text-blue-200"
                        onClick={() => {
                          modal.closeModal({ type: "billingModal" });
                          modal.openModal({
                            type: "billingPlanChangeSurvey",
                            props: {
                              tier: currentPlan.tier,
                              downgradeTo: "free",
                              source,
                              type: "cancel",
                              workspaceId: workspace?.id ?? "",
                            },
                          });
                        }}
                      >
                        Cancel Replo Plan
                        <BsArrowRight size={15} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        />
      )}
    </Modal>
  );
};

const PlanInfoComponent: React.FC<{
  compact?: boolean;
  isALowerTier: boolean;
  isCurrentTier: boolean;
  isCustomPlan: boolean;
  isLoading: boolean;
  isSelectable: boolean;
  onSubmit(value: BillingTier): void;
  plan: PlanInfo;
  planSelected: BillingTier | undefined;
  workspace?: WorkspaceWithShopifyIntegration;
}> = ({
  compact = false,
  isALowerTier,
  isCurrentTier,
  isCustomPlan,
  isLoading,
  isSelectable,
  onSubmit,
  plan,
  planSelected,
  workspace,
}) => {
  const isEnterpriseOrCustomPlan =
    plan.tier === BillingTiers.ENTERPRISE || plan.tier === BillingTiers.CUSTOM;

  const isHigherTier =
    !isEnterpriseOrCustomPlan &&
    isSelectable &&
    !isALowerTier &&
    !isCurrentTier;

  if (plan.tier === BillingTiers.FREE) {
    return null;
  }

  const getTierType = () => {
    if (isALowerTier) {
      return "lower";
    }
    if (isHigherTier) {
      return "higher";
    }
    return "current";
  };

  return (
    <div className="flex flex-col w-full min-w-80 justify-between">
      {!isEnterpriseOrCustomPlan && !compact && (
        <div
          className={classNames(
            "items-center justify-center justify-items-center flex flex-col mx-3",
            {
              "rounded-t-xl bg-gradient-to-r from-blue-600 to-cyan-400":
                plan.isMostPopular && !compact,
            },
          )}
        >
          <div className="leading-8 flex justify-center text-sm font-medium text-white">
            Most Popular
          </div>
        </div>
      )}
      <div
        className={classNames(
          "flex flex-col mx-3 justify-between h-full rounded-lg",
          {
            "border-t-0 rounded-t-none": plan.isMostPopular,
            "border-slate-200 border": !isCustomPlan,
          },
        )}
      >
        <div
          className={classNames(
            "rounded-sm px-4 py-4 text-default h-full flex flex-col justify-between",
            {
              "px-6 pt-6": isEnterpriseOrCustomPlan && !compact,
              "bg-slate-50": isCustomPlan,
            },
          )}
          key={plan.tier}
        >
          <PlanInfoDetails plan={plan} />
          <Cta
            isEnterpriseOrCustomPlan={isEnterpriseOrCustomPlan}
            onClick={() => {
              if (isEnterpriseOrCustomPlan) {
                return;
              }
              onSubmit(plan.tier);
            }}
            isLoading={isLoading && planSelected === plan.tier}
            isMostPopular={plan.isMostPopular}
            tierType={getTierType()}
            planSelected={planSelected}
            planTier={plan.tier}
            planName={plan.displayName}
            workspace={workspace}
          />
          <div className="mt-4 h-16">
            <div className="pb-3 text-xs leading-none text-slate-400">
              {plan.footerText}
            </div>
            {!isEnterpriseOrCustomPlan && plan.imgSrc && (
              <img
                key={plan.imgSrc}
                src={plan.imgSrc}
                className="h-[30px] max-w-[200px]"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// TODO (Evan, 2024-03-28): move this to /shared
export const PlanInfoDetails: React.FC<{
  plan: PlanInfo;
}> = ({ plan }) => {
  // Note (Evan, 2024-03-25): Opting to repeat a little bit of logic here rather than passing
  // multiple props that can be simply derived from the plan.
  const isCustomPlan = plan.tier === BillingTiers.CUSTOM;
  const isEnterpriseOrCustomPlan =
    isCustomPlan || plan.tier === BillingTiers.ENTERPRISE;
  const { data: billingInfo, isLoading } = useGetBillingInformationQuery({});
  const planInfo = billingInfo && billingInfo[plan.tier];

  return (
    <div className="flex flex-col grow justify-between">
      <div className="pb-5">
        <div className="mb-4 flex items-center gap-2">
          <h1 className="flex flex-row text-lg font-semibold">
            Replo&nbsp;
            <div
              className={twMerge(
                "bg-gradient-to-r bg-clip-text text-transparent",
                plan.titleColor,
              )}
            >
              <p className={plan.titleColor}>{plan.displayName}</p>
            </div>
          </h1>
        </div>
        {isCustomPlan && (
          <p
            className={classNames("min-h-[80px] py-1 text-base", {
              "text-slate-600": isEnterpriseOrCustomPlan,
              "text-slate-400": !isEnterpriseOrCustomPlan,
            })}
          >
            {plan.description}
          </p>
        )}
        {!isEnterpriseOrCustomPlan && <PlanPrice plan={plan} />}
        {!isEnterpriseOrCustomPlan && (
          <>
            {!isLoading && planInfo ? (
              <>
                <div
                  className={classNames({
                    "mt-4 xl:max-h-none overflow-scroll no-scrollbar flex flex-col gap-2":
                      planInfo.features.length > 1,
                  })}
                >
                  {planInfo.features.map((feature, i) => {
                    if (feature.shouldShowInEditor) {
                      return (
                        <div
                          key={`sanity_feature_${i}`}
                          className="flex items-center gap-1 text-sm"
                        >
                          <div className="w-6">
                            <BsCheck size={24} className="text-blue-600" />
                          </div>
                          <p className="w-full text-sm">{feature.name}</p>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </>
            ) : (
              <Spinner size={25} className="my-[77px]" />
            )}
          </>
        )}
      </div>
    </div>
  );
};

function Cta({
  isEnterpriseOrCustomPlan,
  isLoading,
  isMostPopular,
  onClick,
  planSelected,
  planTier,
  tierType,
  planName,
  workspace,
}: {
  tierType: "current" | "higher" | "lower";
  isMostPopular: boolean;
  isEnterpriseOrCustomPlan: boolean;
  isLoading: boolean;
  planSelected: BillingTier | undefined;
  planTier: BillingTier;
  onClick?: () => void;
  planName: string;
  workspace?: WorkspaceWithShopifyIntegration;
}) {
  const isLowerTier = tierType === "lower";
  const isCurrentTier = tierType === "current";
  const isHigherTier = tierType === "higher";
  const isWorkspaceOwner = useIsWorkspaceOwner(workspace?.id);
  const isDisabled =
    (isCurrentTier && !isEnterpriseOrCustomPlan) ||
    (isLoading && planSelected !== planTier) ||
    !isWorkspaceOwner;

  const className = classNames("h-auto w-full", {
    "border bg-gradient-to-r from-blue-600 to-cyan-400 hover:to-blue-600":
      isMostPopular && !isCurrentTier && !isLowerTier && !isDisabled,
    "border bg-gray-200": isCurrentTier,
    "border border-blue-600 bg-white text-blue-600 hover:bg-blue-600 hover:text-white":
      isLowerTier && !isLoading,
    "border border-blue-600 bg-blue-600 text-base font-light text-white hover:bg-white hover:text-blue-600":
      isEnterpriseOrCustomPlan,
  });

  function getLabel() {
    if (isEnterpriseOrCustomPlan) {
      return "Contact Billing";
    } else if (isCurrentTier) {
      return "Current Plan";
    }
    return `${isHigherTier ? "Upgrade" : "Downgrade"} to Replo ${planName}`;
  }

  const buttonProps = {
    type: isLowerTier ? "secondary" : "primary",
    size: "base" as const,
    isLoading: isLoading && planSelected === planTier,
    className,
    spinnerSize: 20,
    isDisabled: isDisabled,
    tooltipText: !isWorkspaceOwner
      ? "Only Workspace Owners have the ability to manage billing details"
      : undefined,
  } as const;

  if (isEnterpriseOrCustomPlan) {
    return (
      <ButtonLink
        to="mailto:billing@replo.app"
        target="_blank"
        {...buttonProps}
      >
        {getLabel()}
      </ButtonLink>
    );
  }

  if (
    workspace?.preferredPaymentProcessor === "shopify" &&
    !workspace?.hasShopifyIntegration
  ) {
    return (
      <ConnectShopifyIntegrationCta type="billingModal" className={className} />
    );
  }

  return (
    <div className="flex flex-col gap-2">
      {planTier === "growth" && (
        <Button
          type="tertiary"
          className="w-full text-blue-600"
          size="base"
          onClick={() =>
            window.open("https://replo.app/lets-talk?utm_source=dashboard")
          }
        >
          Talk to Our Team
        </Button>
      )}
      <Button onClick={onClick} {...buttonProps}>
        {getLabel()}
      </Button>
    </div>
  );
}

const PlanPrice: React.FC<{
  plan: PlanInfo;
}> = ({ plan }) => {
  if (plan.tier === "enterprise" || plan.tier === "custom") {
    return <div className="text-2xl font-medium">Custom</div>;
  }

  return <div className="text-2xl font-medium">{`$${plan.price}/mo`}</div>;
};
