export const featureFlags = [
  // NOTE (Evan, 6/27/23) We're putting this behind a feature flag because
  // Highlight is breaking a store on the editor (USE-256)
  "use-highlight",
  "carousel-v4",
  "announcement_figma_to_replo",
  "carousel-debug",
  "turbo",
  "no-theme-in-editor",
  "component-template-screenshot-v2",
  "integration-hub",
  "analytics-dashboard",
  "force-no-mirror",
  "ai-claude",
  "enable-posthog-session-recording",
  "left-panel-ui",
  "saved-styles",
  "ai-onboarding",
  "right-bar-ui",
] as const;
export type FeatureName = (typeof featureFlags)[number];
