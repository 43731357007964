import * as React from "react";

import { twMerge } from "tailwind-merge";

export const imgOrPlaceholder = (
  src: string | undefined | null,
  className: string | undefined,
  placeholderClassName: string | undefined,
) => {
  return src ? (
    <img className={className} src={src} />
  ) : (
    <div className={twMerge(className, placeholderClassName)} />
  );
};
