import type { AppModalType, ModalProps } from "@components/AppModalTypes";
import type { EditorRootState } from "@editor/store";
import type { BillingPlanUpgradeSource } from "@reducers/api-reducer";
import type { AnyAction, PayloadAction } from "@reduxjs/toolkit";
import type { RequiredKeys } from "replo-utils/lib/types";

import { API_ACTIONS } from "@constants/action-types";
import { analytics } from "@infra/analytics";
import {
  getBillingPlanErrorUsage,
  getErrorDetails,
} from "@reducers/error-reducer";

import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";

export type ModalState = {
  [Property in AppModalType]: keyof ModalProps<Property> extends never
    ? never
    : ModalProps<Property>;
};

export interface ModalsState {
  modals: Partial<ModalState>;
}

const initialState: ModalsState = {
  modals: {},
};

const modifyStateWithErrorDetails = (opts: {
  state: ModalsState;
  details: ReturnType<typeof getErrorDetails>;
  billingPlanErrorUsage?: { current: number; maximum: number };
}) => {
  const { state, details, billingPlanErrorUsage } = opts;
  if (!details) {
    return;
  }
  // TODO (Fran, Noah, RTK migration): When we migrate to RTK for the
  // publish endpoint, we need to make sure to dispatch an
  // openBillingModalIfNeeded in the onQueryStarted .catch subscription,
  // just like we do for the createProjectMembership mutation
  if (details.errorKey?.startsWith("billingPlan")) {
    let source: BillingPlanUpgradeSource = "publish.pageOrDefaultElementType";
    if (details.errorKey.includes("publishedProductTemplates")) {
      source = "publish.productTemplate";
    }
    if (details.errorKey.includes("sectionsPublishedLimitExceeded")) {
      source = "publish.section";
    }
    if (details.errorKey.includes("integrations.shopify")) {
      source = "integrationHub.shopify";
    }
    state.modals.billingModal = {
      billingPlanMessageKey: details.errorKey,
      usage: billingPlanErrorUsage,
      source,
    };
  } else if (details.type === "fullPageModal") {
    state.modals.fullPageErrorModal = {
      details: {
        header: details.errorDetails.header,
        message: details.errorDetails.message,
        callToAction: details.errorDetails.callToAction,
        isRecoverable: details.errorDetails.isRecoverable,
      },
    };
  } else if (details.type === "shopifyStorePassword") {
    state.modals.storePasswordRequiredModal = {
      type: details.errorKey as "passwordRequired" | "wrongPassword",
    };
  }
};

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<OpenModalPayload>) => {
      const type = action.payload.type;
      const props = "props" in action.payload ? action.payload.props : {};
      analytics.logEvent("openModal", action.payload);
      // @ts-expect-error
      state.modals[type] = props;
    },
    closeModal: (state, action: PayloadAction<CloseModalPayload>) => {
      const { type } = action.payload;
      /* If type isn't defined, just clear all modals */
      if (!type) {
        state.modals = {};
      }
      // @ts-expect-error
      delete state.modals[type];
    },
    openBillingModalIfNeeded: (
      state,
      action: PayloadAction<{ key: string; source: BillingPlanUpgradeSource }>,
    ) => {
      if (action.payload?.key.startsWith("billingPlan")) {
        state.modals.billingModal = {
          billingPlanMessageKey: action.payload.key,
          source: action.payload.source,
        };
      }
    },
    handleErrorDetails: (
      state,
      action: PayloadAction<{ details: ReturnType<typeof getErrorDetails> }>,
    ) => {
      modifyStateWithErrorDetails({
        state,
        details: action.payload.details,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        /**
         * When we create a new page, redirect to that page
         */
        createAction(API_ACTIONS.CREATE_ELEMENT.success),
        (state) => {
          state.modals = {};
        },
      )
      /**
       * When an element is updated, and the update was triggered
       * from a page settings configurator, close all modals
       */
      .addCase(
        createAction(API_ACTIONS.CREATE_OR_UPDATE_ELEMENT.success),
        (state, action) => {
          // @ts-expect-error
          if (action.meta.source === "pageSettings") {
            state.modals = {};
          }
        },
      )
      .addMatcher(
        (action: AnyAction) => {
          return (
            getErrorDetails({ type: "rsaaOrRtkQueryAction", action }) != null
          );
        },
        (state, action) => {
          const details = getErrorDetails({
            type: "rsaaOrRtkQueryAction",
            action,
          });
          modifyStateWithErrorDetails({
            state,
            details,
            billingPlanErrorUsage: getBillingPlanErrorUsage(action),
          });
        },
      );
  },
});

export const selectOpenModals = createSelector(
  (state: EditorRootState) => state.modals.modals,
  (modals) => {
    return Object.values(modals);
  },
);

export const selectModals = (state: EditorRootState) => state.modals.modals;

export const selectAreModalsOpen = createSelector(
  selectOpenModals,
  (modals) => {
    return modals.length > 0;
  },
);

export const selectOpenModalKeys: (state: EditorRootState) => AppModalType[] =
  createSelector(selectModals, (modals) => {
    return Object.keys(modals) as AppModalType[];
  });

export const modalsReducer = modalsSlice.reducer;
export const {
  openModal,
  closeModal,
  openBillingModalIfNeeded,
  handleErrorDetails,
} = modalsSlice.actions;

export type OpenModalPayload = {
  [K in AppModalType]: RequiredKeys<ModalProps<K>> extends never
    ? keyof ModalProps<K> extends never
      ? { type: K; props?: never }
      : { type: K; props?: ModalProps<K> }
    : { type: K; props: ModalProps<K> };
}[AppModalType];

export type CloseModalPayload = {
  type?: AppModalType;
};
