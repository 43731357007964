import type * as React from "react";
import type { ReploComponentType } from "schemas/component";

import CarouselAccessibilityMenu from "@editor/components/editor/page/element-editor/components/config-menus/CarouselAccessibilityMenu";
import {
  CarouselConfigMenu,
  CarouselSlidesConfigMenu,
} from "@editor/components/editor/page/element-editor/components/config-menus/CarouselConfigMenu";
import {
  ContainerAccessibilityMenu,
  ContainerConfigMenu,
} from "@editor/components/editor/page/element-editor/components/config-menus/ContainerConfigMenus";

export const configMenuMap: Partial<
  Record<ReploComponentType, { config?: React.FC; accessibility?: React.FC }>
> = {
  carouselV3: {
    config: CarouselConfigMenu,
    accessibility: CarouselAccessibilityMenu,
  },
  carouselV3Slides: {
    config: CarouselSlidesConfigMenu,
  },
  container: {
    config: ContainerConfigMenu,
    accessibility: ContainerAccessibilityMenu,
  },
  image: {
    config: ContainerConfigMenu,
    accessibility: ContainerAccessibilityMenu,
  },
};
