import type { FlowStepConfigPropsValueOf } from "schemas/flow";

import * as React from "react";

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import SelectableButtons from "@editor/components/common/designSystem/SelectableButtons";
import { useGetCurrentStepResultsData } from "@editor/components/flows/hooks/useGetCurrentStepResultsData";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepsLayout from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";

import { Controller, useForm, useWatch } from "react-hook-form";
import { isEmpty } from "replo-utils/lib/misc";

type FormValues = {
  yearlyRevenue: string;
};

const WhatIsYourYearlyRevenue: React.FC = () => {
  const stepType = "onboarding.user.who-are-you-building-pages-for.revenue";
  const stepResultsData = useGetCurrentStepResultsData<typeof stepType>();
  const { currentStep, skipStep, submitStep } = useReploFlowsStepContext();
  const { submitOrSkipStepCallback } = useRouterFlowCallbacks();
  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      yearlyRevenue: stepResultsData?.yearlyRevenue ?? "",
    },
  });

  const onSubmit = ({ yearlyRevenue }: FormValues) => {
    if (currentStep) {
      if (currentStep?.isSkippable && isEmpty(yearlyRevenue)) {
        skipStep(currentStep.id, currentStep.type, ({ instance, nextStep }) =>
          submitOrSkipStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          }),
        );
      } else {
        void submitStep(
          currentStep.id,
          currentStep.type,
          {
            yearlyRevenue,
          },
          ({ instance, nextStep }) =>
            submitOrSkipStepCallback({
              nextStep: nextStep ?? null,
              flowSlug: instance.flow.slug,
            }),
        );
      }
    }
  };
  const yearlyRevenueValue = useWatch({ control, name: "yearlyRevenue" });
  const stepProps = currentStep?.props as FlowStepConfigPropsValueOf<
    typeof stepType
  >;
  const selectedValueLabel = stepProps.options?.find(
    (option) => option.value === yearlyRevenueValue,
  )?.label;

  return (
    <OnboardingStepsLayout
      rightPanelContent={<RevenueStepImage label={selectedValueLabel} />}
    >
      <div className="flex flex-col gap-14">
        <div>
          <StepTitle>What is your yearly revenue?</StepTitle>
        </div>
        <form
          className="flex flex-col gap-16"
          onSubmit={(data) => {
            void handleSubmit(onSubmit)(data);
          }}
        >
          <Controller
            name="yearlyRevenue"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectableButtons
                multiSelect={false}
                options={stepProps.options ?? []}
                value={[value]}
                onChange={(value) => onChange(value[0] ?? "")}
                className="my-4"
                textClassName="font-normal"
                unselectedClassName="border border-slate-200 bg-transparent text-default hover:border-blue-600 hover:bg-blue-200 hover:text-blue-600"
                selectedClassName="border border-blue-600 bg-blue-200 text-blue-600 hover:bg-blue-300"
              />
            )}
          />
          <FlowActionButtons shouldDisableNextButton={false} />
        </form>
      </div>
    </OnboardingStepsLayout>
  );
};

const RevenueStepImage: React.FC<{ label?: string }> = ({ label }) => {
  return (
    <div className="col-span-7 xl:col-span-8 mx-auto relative lg:flex px-32 py-44 items-center justify-center hidden overflow-y-hidden">
      <img src="/images/flows/revenue.png" alt="Flow step image" />
      {label ? (
        <span className="absolute font-bold lg:text-5xl xl:text-8xl text-blue-600">
          {label}
        </span>
      ) : null}
    </div>
  );
};

export default WhatIsYourYearlyRevenue;
