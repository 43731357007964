import type { Options } from "@splidejs/react-splide";
import type { SlideComponent, Splide as SplideCore } from "@splidejs/splide";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Component } from "../../../shared/Component";
import type { RuntimeStyleProperties } from "../../../shared/styleAttribute";
import type { RenderComponentProps } from "../../../shared/types";
import type { RenderChildren } from "../../../shared/utils/renderComponents";
import type { GlobalWindow } from "../../../shared/Window";
import type { Context } from "../../AlchemyVariable";
import type { ItemsConfig } from "../../utils/items";

import * as React from "react";

import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
// @ts-ignore
import carouselStyles from "@splidejs/splide/dist/css/splide-core.min.css?inline";
import classNames from "classnames";
import isEqual from "fast-deep-equal";
import useSyncRuntimeValue from "replo-runtime/store/hooks/useSyncRuntimeValue";
import { canUseDOM } from "replo-utils/dom/misc";
import { parseInteger } from "replo-utils/lib/math";
import { isString } from "replo-utils/lib/type-check";
import { normalizeUrlScheme } from "replo-utils/lib/url";
import { useComposedRefs } from "replo-utils/react/use-composed-refs";
import { useOnValueChange } from "replo-utils/react/use-on-value-change";

import { ErrorBoundary } from "../../../shared/ErrorBoundary";
import {
  DraftElementContext,
  FeatureFlagsContext,
  RenderEnvironmentContext,
  ReploEditorActiveCanvasContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import {
  findChildComponentPath,
  findComponentAncestorComponentOrSelf,
  findComponentByTypeDepthFirst,
} from "../../../shared/utils/component";
import { mergeContext } from "../../../shared/utils/context";
import { mapNull } from "../../../shared/utils/optional";
import { useRenderChildren } from "../../../shared/utils/renderComponents";
import { useSharedState } from "../../hooks/useSharedState";
import { carouselCssForSlideTrackComponent } from "../../utils/carousel";
import { isCompletelyHiddenComponent } from "../../utils/isCompletelyHiddenComponent";
import { isItemsDynamic } from "../../utils/items";
import {
  CarouselV4,
  CarouselV4Control,
  CarouselV4Indicators,
  CarouselV4Slides,
} from "../CarouselV4";
import Container from "../Container";
import { ReploComponent } from "../ReploComponent";
import ReploLiquidChunk from "../ReploLiquid/ReploLiquidChunk";
import { SplideRenderer } from "./renderer/SplideRenderer/SplideRenderer";

type AnimationStyle = "slide" | "fade" | "off";

type CarouselV3CustomProps = {
  items?: ItemsConfig;
  name?: string;
  dragToScroll?: boolean;
  autoNextInterval?: string;
  pauseOnHover: boolean;
  animationStyle?: AnimationStyle;
  infinite?: boolean;
  itemsPerView?: number;
  itemsPerMove?: number;
  autoWidth?: boolean;
  mouseWheel?: boolean;
  slideClickTransition?: boolean;
  activeArea?: "first" | "center";
  selectedDynamicItem: any;
};

interface ContextSlides {
  isDynamic: boolean;
  items: RenderChildren;
  total: number;
  currentIndex: number;
  isFirstItem: boolean;
  isLastItem: boolean;
}

export interface ContextWithSlides extends Context {
  group: ContextSlides;
  carousel: {
    mainSlidesComponentId: string;
    dynamicItems?: ItemsConfig | undefined;
    splideOptions?: Options;
    carouselSplideOptions: CarouselSplideConfig;
    carouselSplideOverridableOptions: CarouselSplideOverridableConfig;
    setCurrentSlideIndex: (index: number) => void;
    onMove: (splide: SplideCore, newIndex: number) => void;
    onActive: (splide: SplideCore, slide: SlideComponent) => void;
    globalWindowRef: React.MutableRefObject<GlobalWindow | null>;
  };
}

const shouldUseLiquid = (context: ContextWithSlides) => {
  return (
    context.isPublishing &&
    context.isInsideProductComponent &&
    context.isInsideProductImageCarousel &&
    !context.overrideProductLiquidOff
  );
};

type SplideRef = Splide | null;

export const TRANSITION_SPEED = 800;

const SplideContext = React.createContext<React.RefObject<SplideRef> | null>(
  null,
);

function useSplide() {
  return React.useContext(SplideContext);
}

const getSplideType = (
  animationStyle: CarouselV3CustomProps["animationStyle"],
  infinite: CarouselV3CustomProps["infinite"],
  autoWidth: CarouselV3CustomProps["autoWidth"],
) => {
  const defaultType =
    animationStyle === "fade" && !autoWidth ? animationStyle : "slide";
  const type = animationStyle !== "fade" && infinite ? "loop" : defaultType;
  return type;
};

type CarouselSplideConfig = {
  isEditor: boolean;
  isPreviewMode: boolean;
  isPlaying: boolean;
  pauseOnHover: boolean;
  mouseWheel: boolean;
  interval: number;
  itemsPerMove: number;
  infinite: boolean;
};

type CarouselSplideOverridableConfig = {
  autoWidth: boolean;
  itemsPerView: number;
  animationStyle: AnimationStyle;
  activeArea: "first" | "center";
};

const buildSplideOptions = (
  config: CarouselSplideConfig,
  overridableConfig: CarouselSplideOverridableConfig,
): Options => {
  const type = getSplideType(
    overridableConfig.animationStyle,
    config.infinite,
    overridableConfig.autoWidth,
  );
  const speed =
    overridableConfig.animationStyle === "off" ? 0 : TRANSITION_SPEED;
  const rewind = overridableConfig.animationStyle === "fade" && config.infinite;
  const isEditing = config.isEditor === true && !config.isPreviewMode;
  const options = {
    speed,
    type,
    rewind,
    pagination: false,
    // Note (Noah, 2022-07-16): In order for flex-gap in slides to work
    // without a flicker when loading the page on mobile, we need to define
    // the gap as a css var. Splide passes this value down to the slides
    // component, and the slides component has the css var added via our
    // getAliasedStyles system, based on what flex-gap was set on it in the
    // editor. This is necessary because Splide doesn't support setting CSS
    // directly on the slides container.
    gap: "var(--replo-gap, 0px)",
    perPage: overridableConfig.autoWidth ? 1 : overridableConfig.itemsPerView,
    autoWidth: overridableConfig.autoWidth,
    omitEnd: overridableConfig.autoWidth,
    autoplay: isEditing ? "pause" : config.isPlaying,
    interval: config.interval,
    wheel: config.mouseWheel,
    drag: !config.isEditor || config.isPreviewMode ? "free" : false,
    snap: true,
    perMove: overridableConfig.autoWidth ? 1 : config.itemsPerMove,
    focus: overridableConfig.activeArea === "center" ? "center" : 0,
    pauseOnHover: config.pauseOnHover,
    pauseOnFocus: config.pauseOnHover,
    /**
     * Note (Ovishek, 2023-01-18, REPL-4410): According to docs, this determines whether to
     * release the wheel event when the carousel reaches the first or last slide
     * so that the user can scroll the page continuously.
     * So this means we will always let go when the carousel is on the last slide when
     * going downward and on the first slide when going upward.
     */
    releaseWheel: true,
    // Note (Noah, 2023-05-04, USE-104): This flickPower may seem low, but it's
    // finely tuned to enable "easy enough" flicking through carousel slides on
    // mobile, while also not making it too easy to accidentally flick through
    // multiple slides. It's arbitrary and can probably be adjusted if needed,
    // but from my testing 100 seems to be too much and 10 seems to be too little.
    flickPower: 70,
    clones: isEditing ? 0 : undefined,
  } as const;
  return options;
};

/**
 * Main carousel component that takes care of building the slides that will
 * be used for rendering purposes.
 */
const _CarouselV3: React.FC<RenderComponentProps> = (props) => {
  const { componentAttributes, component, context } = props;
  const { repeatedIndexPath = ".0" } = context;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const isPreviewMode = isEditorApp && !isEditorCanvas;
  const customProps = getCustomProps(component);
  const {
    items,
    autoNextInterval,
    animationStyle = "slide",
    infinite = false,
    autoWidth = false,
    mouseWheel,
    activeArea = "first",
    name,
    selectedDynamicItem,
    pauseOnHover,
  } = customProps;
  let { itemsPerView = 1, itemsPerMove = 1 } = customProps;
  const { storeId } = useRuntimeContext(ShopifyStoreContext);
  const { draftComponentId } = useRuntimeContext(DraftElementContext);
  const { activeCanvas } = useRuntimeContext(ReploEditorActiveCanvasContext);
  const isLoggingEnabled = storeId === "05304eb2-9e79-4fa9-8737-81fce96a0353";

  // NOTE (Evan, 7/27/23) Due to a bug in the custom prop modifiers, these
  // may be strings in production. While this hasn't been an issue, we parse
  // these just to be sure.
  if (typeof itemsPerView === "string") {
    itemsPerView = Number.parseInt(itemsPerView);
  }
  if (typeof itemsPerMove === "string") {
    itemsPerMove = Number.parseInt(itemsPerMove);
  }

  // Note (Noah, 2022-09-11, REPL-4084): It's important to do a DFS here because
  // Splide expects there to be exactly one SplideTrack, and that SplideTrack must
  // _only_ wrap the first carouselV3Slides component in render-order (we don't wrap
  // any additional slide components, because we want them to automatically slide
  // when the active index is changed, and Splide doesn't allow this if you have more
  // than one SplideTrack). Our "main" carouselV3Slides component is the one which
  // we detect and wrap in a SplideTrack, and as such it also must be the first one
  // in render-order. If we did BFS rather than DFS here, there could be a mismatch
  // between the main carouselV3Slides component and the one in render-order, which
  // would cause Splide to throw an error.

  const mainSlidesComponent = findComponentByTypeDepthFirst(
    component,
    "carouselV3Slides",
    "carouselV3",
    // Note (Evan, 2024-03-14): The main slides component cannot be a completely hidden component,
    // (or a descendant of one), since those are rendered as null.
    isCompletelyHiddenComponent,
  );

  const splideType = getSplideType(animationStyle, infinite, autoWidth);

  const [currentSlideIndex, setCurrentSlideIndex] = useSharedState(
    [component.id, "activeSlide"],
    0,
  );
  const mainSplideRef = React.useRef<SplideRef>(null);
  const isDynamic = isItemsDynamic(items);
  const mainSlides = useRenderChildren(mainSlidesComponent ?? null, {
    itemsConfig: items,
    context,
    currentItemId: component.id,
  });

  const rootElementRef = React.useRef<HTMLDivElement | null>(null);
  const rootRef = useComposedRefs(rootElementRef, componentAttributes.ref);
  const globalWindowRef = React.useRef<GlobalWindow | null>(null);
  if (isEditorApp) {
    if (rootElementRef.current && !globalWindowRef.current) {
      const document = rootElementRef.current.ownerDocument;
      // biome-ignore lint/style/noRestrictedGlobals: allow window
      globalWindowRef.current = document.defaultView ?? window;
    }
  } else if (!globalWindowRef.current && canUseDOM) {
    // biome-ignore lint/style/noRestrictedGlobals: allow window
    globalWindowRef.current = window;
  }

  React.useEffect(() => {
    mainSplideRef.current?.go(currentSlideIndex);
  }, [currentSlideIndex]);

  const mainSlidesItems = mainSlides.map((slide) => slide.item);
  const mainSlidesItemsRef = React.useRef(mainSlidesItems);

  // Note (Chance, 2023-06-12) This is an optimization to prevent the carousel
  // from doing a deep comparison in the next effect on every slide change. We
  // don't need to run that effect but we do want an updated reference to it.
  // Also, `setCurrentSlideIndex` should be memoized in our implementation, as
  // we'd generally expect state setters to be stable. We might have bugs
  // because of this if we're not passing it as a dependency to effects
  // elsewhere.
  const currentSlideIndexRef = React.useRef(currentSlideIndex);
  const setCurrentSlideIndexRef = React.useRef(setCurrentSlideIndex);
  React.useEffect(() => {
    setCurrentSlideIndexRef.current = setCurrentSlideIndex;
    currentSlideIndexRef.current = currentSlideIndex;
  }, [currentSlideIndex, setCurrentSlideIndex]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: missing dep mainSlidesItems
  React.useEffect(() => {
    const currentSlideIndex = currentSlideIndexRef.current;
    const setCurrentSlideIndex = setCurrentSlideIndexRef.current;

    // Note (Sebas, 2023-01-26): In case the user selects an option and the
    // carousel slide items change, we need to reset the currentSlideIndex to 0.
    // This only needs to happen for dynamic items.
    if (!isDynamic) {
      mainSlidesItemsRef.current = mainSlidesItems;
      return;
    }

    const previousMainSlidesItems = mainSlidesItemsRef.current;

    if (
      // Note (Chance, 2023-06-12) This check isn't strict necessary, but it
      // prevents a deep comparison if we are already at our current index.
      currentSlideIndex !== 0 &&
      !isEqual(previousMainSlidesItems, mainSlidesItems)
    ) {
      setCurrentSlideIndex(0);
    }

    mainSlidesItemsRef.current = mainSlidesItems;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDynamic, ...mainSlidesItems]);

  const interval = Number.parseFloat(autoNextInterval || "0") * 1000;
  const isPlaying = interval > 0;

  const [isNextArrowDisabled, setIsNextArrowDisabled] = React.useState(false);

  const clonesReady = useClones(isEditorApp, infinite);

  // Enable/disable autoplay while in the editor
  React.useEffect(() => {
    if (isEditorApp && isPlaying) {
      const { Autoplay } = mainSplideRef.current?.splide?.Components || {};
      if (isPreviewMode) {
        Autoplay?.play();
      } else {
        Autoplay?.pause();
      }
    }
  }, [isEditorApp, isPreviewMode, isPlaying]);

  useAutoSetActiveIndex({
    isDynamic,
    mainSlides,
    setCurrentSlideIndex,
    selectedDynamicItem,
  });

  useSyncRuntimeValue([component.id, "totalItems"], mainSlides.length);

  const currentSlide = mainSlides[currentSlideIndex];
  const mainSlidesConfig = mainSlidesComponent?.props._useCustomConfig
    ? {
        itemsPerView: mapNull(
          mainSlidesComponent.props._itemsPerView,
          (value) =>
            typeof value === "string" ? Number.parseInt(value) : value,
        ),
        animationStyle: mainSlidesComponent.props._animationStyle,
        activeArea: mainSlidesComponent.props._activeArea,
        autoWidth: mainSlidesComponent.props._autoWidth,
      }
    : undefined;
  const isAutoWidth = mainSlidesConfig?.autoWidth ?? autoWidth;

  const carouselSplideOptions = {
    isEditor: isEditorApp,
    isPreviewMode,
    isPlaying,
    mouseWheel: Boolean(mouseWheel),
    interval,
    itemsPerMove,
    infinite,
    pauseOnHover: pauseOnHover,
  } satisfies CarouselSplideConfig;

  const carouselSplideOverridableOptions = {
    autoWidth: isAutoWidth,
    itemsPerView: mainSlidesConfig?.itemsPerView ?? itemsPerView,
    animationStyle: mainSlidesConfig?.animationStyle ?? animationStyle,
    activeArea: mainSlidesConfig?.activeArea ?? activeArea,
  } satisfies CarouselSplideOverridableConfig;

  const editorResetKey = useEditorResetCarouselKey(
    {
      component,
      slides: mainSlides,
      slidesComponent: mainSlidesComponent ?? null,
      draftComponentId,
      canvas: activeCanvas,
      globalWindowRef,
      onReset: () => {
        if (currentSlideIndex) {
          setCurrentSlideIndex(0);
        }
      },
    },
    [
      isAutoWidth,
      splideType,
      interval,
      pauseOnHover,
      infinite,
      mouseWheel,
      carouselSplideOverridableOptions.activeArea,
      carouselSplideOverridableOptions.itemsPerView,
      carouselSplideOverridableOptions.animationStyle,
    ],
  );

  if (!mainSlidesComponent) {
    if (isLoggingEnabled) {
      // biome-ignore lint/suspicious/noConsoleLog: allow console log
      console.log(
        "[Replo] error rendering carousel component, main slides component was null",
      );
    }
    if (isEditorApp) {
      return <div ref={rootRef} style={{ display: "contents" }} />;
    }
    return null;
  }

  const splideOptions = buildSplideOptions(
    carouselSplideOptions,
    carouselSplideOverridableOptions,
  );

  const onMove = (_splide: SplideCore, newIndex: number) => {
    setCurrentSlideIndex(newIndex);
  };

  const onActive = (splide: SplideCore, slide: SlideComponent) => {
    if (slide.index >= 0) {
      setCurrentSlideIndex(slide.index % splide.length);
    }
    // We need to track if the native next button gets disabled
    // because that means we're at the end of the carousel. We
    // cannot simply rely on slides size and current index for that
    // matter because there could be a variable amount of slides
    // on the screen at the same time (autoWidth).
    setTimeout(() => {
      setIsNextArrowDisabled(
        splide.Components.Arrows.arrows.next?.disabled ?? false,
      );
    });
  };

  const contextWithSlides: ContextWithSlides = mergeContext(context, {
    attributes: currentSlide?.context.attributes,
    variantTriggers: {
      "state.group.isFirstItemActive": true,
      "state.group.isLastItemActive": true,
    },
    group: {
      isDynamic,
      items: mainSlides,
      total: mainSlides.length,
      currentIndex: clonesReady ? currentSlideIndex : -1,
      isFirstItem: clonesReady ? currentSlideIndex === 0 : false,
      isLastItem: clonesReady
        ? currentSlideIndex === mainSlides.length - 1 || isNextArrowDisabled
        : false,
    } satisfies ContextSlides,
    actionHooks: {
      goToItem: (index: number) => {
        mainSplideRef.current?.splide?.go(Math.max(0, index - 1));
      },
      goToNextItem: () => {
        mainSplideRef.current?.splide?.go(">");
      },
      goToPrevItem: () => {
        mainSplideRef.current?.splide?.go("<");
      },
    },
    carousel: {
      mainSlidesComponentId: mainSlidesComponent.id,
      dynamicItems: items,
      splideOptions,
      carouselSplideOptions,
      carouselSplideOverridableOptions,
      setCurrentSlideIndex,
      onMove,
      onActive,
      globalWindowRef,
    },
    isInsideProductImageCarousel: Boolean(
      items?.type === "productImages" ||
        (items?.type === "inline" &&
          items.valueType === "dynamic" &&
          items.dynamicPath.includes("attributes._product.images")),
    ),
  });

  if (isLoggingEnabled) {
    // biome-ignore lint/suspicious/noConsoleLog: allow console log
    console.log("[Replo] rendering carousel", {
      mainSlidesComponent,
      mainSlides,
      currentSlideIndex,
      currentSlide,
      editorResetKey,
      splideOptions,
      mainSplideRef,
      component,
    });
  }

  // Note (Martin, 2023-03-23): We need to set the visibility to visible
  // for the carousel to actually be visible when server rendering because
  // Splide hides its content by default.
  const visibilityStyling = "visibility: visible !important;";

  // Check if the main slides component is hidden, directly or via an ancestor.
  const areSlidesHidden =
    findComponentAncestorComponentOrSelf(
      component,
      mainSlidesComponent,
      (component) => isCompletelyHiddenComponent(component),
    ) !== null;
  const slidesHaveVariants = (mainSlidesComponent.variants?.length ?? 0) > 1;

  return (
    <div
      {...componentAttributes}
      ref={rootRef}
      key={componentAttributes.key}
      data-replo-carousel
    >
      <ErrorBoundary
        fallback={null}
        onError={(error) => {
          if (isSplideInternalError(error)) {
            // Note (Chance 2023-08-18, USE-350) In some cases when switching
            // between two pages and/or sections with carousels, Splide seems to
            // be instantiated either too late (after its <Splide> component has
            // been removed from the tree) or it is being instantiated on the
            // wrong element. It's really tricky to debug for sure, but in this
            // case we don't want to show an error to the user because we force
            // Splide to remount anyway and the carousel works fine on the next
            // render. This captures all errors in the console and in Sentry so
            // we can still debug it if we want, but it lets us close the USE
            // issue since it shouldn't be something the user needs to see.
            console.error(error);
            // Note (Noah, 2023-10-18): We're deciding specifically to not show an
            // error to the user here, not log it to Sentry, etc. Justification for
            // this is that as mentioned by Chance's comment above, this doesn't
            // actually cause any issues for the rendered page, and it will be
            // completely obsolete once we switch away from Splide in carousel v4.
            // All it does is clog up the Sentry logs, so we don't call onComponentError
            // here, and the error gets silently ignored.
            return;
          }
          throw error;
        }}
      >
        <Splide
          // Automatically add Splide track to prevent errors if the main slide
          // component return null because it's hidden and doesn't have variants.
          hasTrack={areSlidesHidden && !slidesHaveVariants}
          key={editorResetKey}
          options={splideOptions}
          onMounted={() => {
            if (currentSlideIndex > 0) {
              setCurrentSlideIndex(0);
            }
          }}
          onMove={onMove}
          onActive={onActive}
          ref={mainSplideRef}
          className={classNames(
            infinite && "splide--infinite",
            autoWidth && "splide--auto-width",
          )}
          aria-label={name || "Carousel"}
        >
          <SplideContext.Provider value={mainSplideRef}>
            {component.children?.map((child, index) => (
              <ReploComponent
                key={child.id}
                component={child}
                context={contextWithSlides}
                repeatedIndexPath={`${repeatedIndexPath}.${index}`}
              />
            ))}
          </SplideContext.Provider>
        </Splide>
      </ErrorBoundary>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          // NOTE (Martin, 2023-11-30): we add carouselStyles in an independent
          // style tag because we need to make sure that it doesn't break the
          // rest of the styles when imported into Next.js, where carouselStyles
          // is simply an id and the styles are automatically loaded.
          __html: carouselStyles,
        }}
      />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          // We need to add some extra styling to Splide non configurable
          // nodes so that they work along with our components custom style.
          __html: `
          ${carouselCssForSlideTrackComponent(mainSlidesComponent)}
          [data-replo-carousel] .splide {
            display: contents;
            ${visibilityStyling}
          }
          [data-replo-carousel] .splide__track {
            width: 100%;
            height: 100%;
          }
          [data-replo-carousel] .splide__slide {
            display: flex;
            overflow: hidden;
          }
          [data-replo-carousel] .splide__arrows {
            display: none;
          }
          [data-replo-carousel] .splide__track--fade .splide__slide {
            height: 100%;
          }
          ${
            // NOTE (Gabe 2023-08-29): We disable this style so that the
            // carousel arrows don't flash on hydration for Product Carousels.
            shouldUseLiquid(contextWithSlides)
              ? ""
              : `
          [data-replo-carousel] .splide.splide--auto-width:not(.splide--infinite):not(.is-overflow) .splide__arrow {
            display: none !important;
          }`
          }`,
        }}
      />
    </div>
  );
};

export const CarouselV3: React.FC<RenderComponentProps> = (props) => {
  const { featureFlags } = useRuntimeContext(FeatureFlagsContext);
  if (featureFlags.carouselV4) {
    return <CarouselV4 {...props} />;
  }
  return <_CarouselV3 {...props} />;
};

/**
 * Helper function to serialize a style attribute with its multiple values
 * as a string so that it can be easily used as a dependency.
 */
function serializeStyleAttribute(
  component: Component,
  attribute: keyof RuntimeStyleProperties,
) {
  const value = component.props?.style?.[attribute] ?? "";
  const mdValue = component.props?.["style@md"]?.[attribute] ?? "";
  const smValue = component.props?.["style@sm"]?.[attribute] ?? "";
  return `${value}${mdValue}${smValue}`;
}

// Custom hook to control when the Splide carousel should be remounted
function useEditorResetCarouselKey(
  {
    component,
    slides,
    slidesComponent,
    draftComponentId,
    canvas,
    onReset,
    globalWindowRef,
  }: {
    component: Component;
    slides: RenderChildren;
    slidesComponent: Component | null;
    draftComponentId: string | null;
    canvas?: EditorCanvas;
    onReset: () => void;
    globalWindowRef: React.MutableRefObject<GlobalWindow | null>;
  },
  extraResetConditions: any[],
) {
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const [resetKey, setResetKey] = React.useState(0);
  const carouselWidthKey = serializeStyleAttribute(component, "width");
  const carouselSlidesWidthKey = slides
    ?.map(({ component }) => {
      return serializeStyleAttribute(component, "width");
    })
    .join("-");
  const slidesComponentId = slidesComponent?.id ?? null;
  const mainSlidesComponentPath = findChildComponentPath(
    component,
    slidesComponentId,
  );

  const isSameDraftComponentEdited =
    draftComponentId === component.id || draftComponentId === slidesComponentId;

  // Note (Change, 2023-05-23): `onReset` may not be stable, but a) we don't
  // want its changes triggering reactive updates, and b) we want its value to
  // be up-to-date when we call it to prevent stale closures. Storing it in a
  // ref that gets updated in the layout effect phase guarantees this (so long
  // as we isolate usage to calls queued after initialization here).
  const onResetRef = React.useRef(onReset);
  React.useLayoutEffect(() => {
    onResetRef.current = onReset;
  }, [onReset]);

  const handleReset = React.useCallback(() => {
    setResetKey((prevValue) => prevValue + 1);
    onResetRef.current();
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: missing deps: isEditorCanvas, isSameDraftComponentEdited, handleReset
  React.useEffect(() => {
    if (isEditorCanvas && isSameDraftComponentEdited) {
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides.length, carouselWidthKey, carouselSlidesWidthKey, mainSlidesComponentPath, isEditorCanvas, handleReset, isSameDraftComponentEdited].concat(extraResetConditions));

  useOnValueChange(canvas, handleReset);
  useWatchComponentBounds(slidesComponentId, {
    globalWindowRef,
    onChange: () => {
      if (isSameDraftComponentEdited) {
        setResetKey((prevValue) => prevValue + 1);
        onReset?.();
      }
    },
  });

  return resetKey;
}

/**
 * Custom hook to prevent clones from being incorrectly rendered initially,
 * which helps when the first/last/active carousel states are being used.
 */
function useClones(isEditor: boolean, isInfinite: boolean) {
  const [clonesReady, setClonesReady] = React.useState(false);

  React.useEffect(() => {
    if (!clonesReady && isInfinite) {
      const timeoutId = setTimeout(
        () => {
          setClonesReady(true);
        },
        isEditor ? 150 : 50,
      );
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [clonesReady, isEditor, isInfinite]);

  return !isInfinite || clonesReady;
}

/**
 * Slides component for properly rendering the slides.
 */
const _CarouselV3Slides: React.FC<RenderComponentProps> = (props) => {
  const { component, componentAttributes } = props;
  const context = props.context as ContextWithSlides;
  const { carousel } = context;
  const { storeId } = useRuntimeContext(ShopifyStoreContext);

  const isLoggingEnabled = storeId === "05304eb2-9e79-4fa9-8737-81fce96a0353";

  if (!carousel) {
    if (isLoggingEnabled) {
      // biome-ignore lint/suspicious/noConsoleLog: allow console log
      console.log("[Replo] rendering carousel slides", {
        props,
        component,
        carousel,
      });
    }
    return null;
  }

  const { mainSlidesComponentId } = carousel;
  const isMainSlidesComponent = mainSlidesComponentId === component.id;

  if (isLoggingEnabled) {
    // biome-ignore lint/suspicious/noConsoleLog: allow console log
    console.log("[Replo] rendering carousel slides", {
      props,
      isMainSlidesComponent,
      component,
      carousel,
    });
  }

  // NOTE (Gabe 2023-08-29): We use a slightly different technique for deciding
  // whether or not to use the liquid version in the carousel since we need to
  // know whether or not the carousel is being used for product images.
  let CarouselV3SlidesContent: React.FC<RenderComponentProps> =
    _CarouselV3SlidesContent;
  if (shouldUseLiquid(context)) {
    CarouselV3SlidesContent = _CarouselV3SlidesContentLiquid;
  }

  return (
    <div {...componentAttributes} key={componentAttributes.key}>
      {isMainSlidesComponent ? (
        <SplideTrack>
          <CarouselV3SlidesContent {...props} />
        </SplideTrack>
      ) : (
        <CarouselV3SecondarySplide {...props} />
      )}
    </div>
  );
};

export const CarouselV3Slides: React.FC<RenderComponentProps> = (props) => {
  const { featureFlags } = useRuntimeContext(FeatureFlagsContext);
  if (featureFlags.carouselV4) {
    return <CarouselV4Slides {...props} />;
  }
  return <_CarouselV3Slides {...props} />;
};

/**
 * Helper component that renders secondary Splide components for extra slides
 * that needs to be connected to the main slide component.
 */
const CarouselV3SecondarySplide: React.FC<RenderComponentProps> = (props) => {
  const { component } = props;
  const context = props.context as ContextWithSlides;
  const { draftComponentId } = useRuntimeContext(DraftElementContext);
  const customProps = getCustomProps(component);
  const useCustomConfig = component.props._useCustomConfig ?? false;
  const { carousel } = context;
  const {
    dynamicItems,
    carouselSplideOptions,
    carouselSplideOverridableOptions,
    onMove,
    onActive,
    globalWindowRef,
  } = carousel;
  const splideOptions = buildSplideOptions(
    carouselSplideOptions,
    useCustomConfig
      ? {
          autoWidth:
            customProps.autoWidth ??
            carouselSplideOverridableOptions?.autoWidth,
          itemsPerView:
            customProps.itemsPerView ??
            carouselSplideOverridableOptions?.itemsPerView,
          animationStyle:
            customProps.animationStyle ??
            carouselSplideOverridableOptions?.animationStyle,
          activeArea:
            customProps.activeArea ??
            carouselSplideOverridableOptions?.activeArea,
        }
      : carouselSplideOverridableOptions,
  );
  const mainSplideRef = useSplide();
  const secondarySplideRef = React.useRef<SplideRef>(null);

  const slides = useRenderChildren(component, {
    itemsConfig: dynamicItems,
    context,
    currentItemId: component.id,
  });

  const editorResetKey = useEditorResetCarouselKey(
    {
      component,
      slides,
      slidesComponent: component,
      draftComponentId,
      globalWindowRef,
      onReset: () => {
        context.carousel?.setCurrentSlideIndex(0);
      },
    },
    [customProps.autoWidth, splideOptions.type],
  );

  React.useEffect(() => {
    if (
      mainSplideRef?.current &&
      secondarySplideRef.current &&
      secondarySplideRef.current.splide
    ) {
      mainSplideRef?.current.sync(secondarySplideRef.current.splide);
    }
  });
  // NOTE (Gabe 2023-08-29): We use a slightly different technique for deciding
  // whether or not to use the liquid version in the carousel since we need to
  // know whether or not the carousel is being used for product images.
  // TODO (Gabe 2023-10-20): This is duplicated from the _CarouselV3Slides. We
  // could refactor this component to enable composition and avoid requiring
  // this, but since we're getting rid of carousel v3 soon I'm not going to
  // bother.
  let CarouselV3SlidesContent: React.FC<RenderComponentProps> =
    _CarouselV3SlidesContent;
  if (shouldUseLiquid(context)) {
    CarouselV3SlidesContent = _CarouselV3SlidesContentLiquid;
  }

  return (
    <Splide
      key={editorResetKey}
      options={splideOptions}
      ref={secondarySplideRef}
      aria-label="Carousel"
      onMove={onMove}
      onActive={onActive}
    >
      <CarouselV3SlidesContent
        {...props}
        context={
          {
            ...context,
            carousel: {
              ...context.carousel,
              splideOptions,
            },
            group: {
              ...context.group,
              items: slides,
            },
          } as ContextWithSlides
        }
      />
    </Splide>
  );
};

/**
 * Liquid version of Helper component that renders Splide slides.
 */
const _CarouselV3SlidesContent: React.FC<RenderComponentProps> = (props) => {
  const { component, componentAttributes } = props;
  const context = props.context as ContextWithSlides;
  const {
    repeatedIndexPath = ".0",
    group,
    actionHooks,
    carousel: { splideOptions },
  } = context;

  const items = group?.items;
  if (!items) {
    return null;
  }

  return (
    <>
      {items.length > 0 ? (
        items.map((item, index, items) => {
          return (
            <SplideSlide
              key={getSlideKey(item, index, items) as React.Key}
              data-component-id={item.component.id}
            >
              <ReploComponent
                component={item.component}
                context={mergeContext(item.context, {
                  group: {
                    isActiveItem: index === group.currentIndex,
                  },
                  variantTriggers: {
                    "state.group.isCurrentItemActive": true,
                  },
                  actionHooks,
                })}
                repeatedIndexPath={`${repeatedIndexPath}.${index}`}
              />

              <style
                dangerouslySetInnerHTML={{
                  __html: new SplideRenderer(["<p></p>"], splideOptions, {
                    selectorPrefix: `[data-component-id="${item.component.id}"]`,
                  }).styles(),
                }}
              />
            </SplideSlide>
          );
        })
      ) : (
        <Container
          component={component}
          componentAttributes={componentAttributes}
          context={context}
        />
      )}
    </>
  );
};

/**
 * Helper component that renders Splide slides or an empty container.
 */
const _CarouselV3SlidesContentLiquid: React.FC<RenderComponentProps> = (
  props,
) => {
  const { component, componentAttributes } = props;
  const context = props.context as ContextWithSlides;
  const {
    repeatedIndexPath = ".0",
    group,
    actionHooks,
    carousel: { splideOptions },
  } = context;

  const firstItem = group?.items?.[0];

  // Note (Noah, 2023-11-15, USE-564): If there are no items at all in the
  // carousel, then there's no need to render anything. Just render an empty
  // container, which is what the non-liquid component does, so that we hydrate
  // correctly
  if (!firstItem) {
    return (
      <Container
        component={component}
        componentAttributes={componentAttributes}
        context={context}
      />
    );
  }

  return (
    <ReploLiquidChunk>
      {"{% for reploProductImage in product.images %}"}
      <SplideSlide data-component-id={firstItem.component.id}>
        <ReploComponent
          component={firstItem.component}
          context={mergeContext(firstItem.context, {
            // NOTE (Gabe 2024-01-03): The parent's context must be merged here
            // so that the slides know whether or not they are inside of a
            // productImageCarousel.
            ...context,
            group: {
              isActiveItem: false,
            },
            variantTriggers: {
              "state.group.isCurrentItemActive": true,
            },
            actionHooks,
          })}
          repeatedIndexPath={`${repeatedIndexPath}.0`}
        />

        <style
          dangerouslySetInnerHTML={{
            __html: new SplideRenderer(["<p></p>"], splideOptions, {
              selectorPrefix: `[data-component-id="${firstItem.component.id}"]`,
            }).styles(),
          }}
        />
      </SplideSlide>
      {"{% endfor %}"}
    </ReploLiquidChunk>
  );
};

/**
 * Component that renders either a Next or a Previous button.
 */
const _CarouselV3Control: React.FC<RenderComponentProps> = (props) => {
  const { componentAttributes, component } = props;
  const context = props.context as ContextWithSlides;
  const direction = component.props.direction;
  const { featureFlags } = useRuntimeContext(FeatureFlagsContext);
  const splideRef = useSplide();
  const { repeatedIndexPath = ".0", carousel } = context;

  if (featureFlags.carouselV4) {
    return <CarouselV4Control {...props} />;
  }

  if (!carousel) {
    return null;
  }

  return (
    <button
      {...componentAttributes}
      type="button"
      onClick={() => {
        splideRef?.current?.go(direction === "next" ? ">" : "<");
      }}
      aria-label={direction === "next" ? "Next slide" : "Previous slide"}
      key={componentAttributes.key}
      className={classNames("splide__arrow", componentAttributes.className)}
    >
      {component.children?.map((child, index) => {
        return (
          <ReploComponent
            key={child.id}
            component={child}
            context={context}
            repeatedIndexPath={`${repeatedIndexPath}.${index}`}
          />
        );
      })}
    </button>
  );
};

export const CarouselV3Control: React.FC<RenderComponentProps> = (props) => {
  const { featureFlags } = useRuntimeContext(FeatureFlagsContext);
  if (featureFlags.carouselV4) {
    return <CarouselV4Control {...props} />;
  }
  return <_CarouselV3Control {...props} />;
};

/**
 * Component that renders a list of elements that jump between slides.
 */
const _CarouselV3Indicators: React.FC<RenderComponentProps> = (props) => {
  const { componentAttributes, component } = props;
  const context = props.context as ContextWithSlides;
  const { repeatedIndexPath = ".0", carousel, group, actionHooks } = context;
  const splideRef = useSplide();
  const template = component.children?.length && component.children[0];
  const items = group?.items;

  // Note (Noah, 2023-12-07, USE-607): We check specifically for the context
  // here instead of using useLiquidAlternate since whether we want to convert
  // to liquid depends on the carousel-specific context passed to the component
  if (shouldUseLiquid(context)) {
    return <_CarouselV3IndicatorsLiquid {...props} />;
  }

  if (!carousel || !template || !items) {
    return null;
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <ul {...componentAttributes} key={componentAttributes.key}>
      {items.map((item, index) => {
        const childComponent = component.children?.[index] ?? template;
        const isActiveItem = index === group.currentIndex;
        return (
          <li
            key={getSlideKey(item, index, items) as React.Key}
            role="button"
            onClick={() => {
              splideRef?.current?.go(index);
            }}
            style={{ display: "contents" }}
            aria-label={`Go to slide ${index + 1}`}
            aria-selected={isActiveItem}
          >
            <ReploComponent
              component={childComponent}
              context={mergeContext(item.context, {
                group: {
                  isActiveItem,
                },
                variantTriggers: {
                  "state.group.isCurrentItemActive": true,
                },
                actionHooks,
              })}
              repeatedIndexPath={`${repeatedIndexPath}.${index}`}
            />
          </li>
        );
      })}
    </ul>
  );
};

const _CarouselIndicators: React.FC<RenderComponentProps> = (props) => {
  const { featureFlags } = useRuntimeContext(FeatureFlagsContext);
  if (featureFlags.carouselV4) {
    return <CarouselV4Indicators {...props} />;
  }

  return <_CarouselV3Indicators {...props} />;
};

const _CarouselV3IndicatorsLiquid: React.FC<RenderComponentProps> = (props) => {
  const { componentAttributes, component } = props;
  const context = props.context as ContextWithSlides;
  const { repeatedIndexPath = ".0", carousel, group, actionHooks } = context;
  const items = group?.items;

  const template = component.children?.length && component.children[0];
  if (!carousel || !template || !items) {
    return null;
  }

  if (items.length === 0) {
    return null;
  }

  const index = 0;
  const childComponent = component.children?.[index] ?? template;
  const isActiveItem = index === group.currentIndex;
  const item = items[index];

  if (!item) {
    return null;
  }

  return (
    <ul {...componentAttributes}>
      <ReploLiquidChunk>
        {"{% for reploProductImage in product.images %}"}
        <li
          key={item.component.id}
          role="button"
          style={{ display: "contents" }}
          aria-label={`Go to slide ${index + 1}`}
          aria-selected={isActiveItem}
        >
          <ReploComponent
            component={childComponent}
            context={mergeContext(item.context, {
              // NOTE (Gabe 2024-01-03): The parent's context must be merged here
              // so that the slides know whether or not they are inside of a
              // productImageCarousel.
              ...context,
              group: {
                isActiveItem: false,
              },
              variantTriggers: {
                "state.group.isCurrentItemActive": true,
              },
              actionHooks,
            })}
            repeatedIndexPath={`${repeatedIndexPath}.${index}`}
          />
        </li>
        {"{% endfor %}"}
      </ReploLiquidChunk>
    </ul>
  );
};

/**
 * Set up a useEffect to automatically call setCurrentSlideIndex with the index of the
 * match of the selectedDynamicItem within mainSlides.
 */
const useAutoSetActiveIndex = (config: {
  isDynamic: boolean;
  selectedDynamicItem: any;
  mainSlides: RenderChildren;
  setCurrentSlideIndex: (index: number) => void;
}) => {
  const {
    isDynamic,
    selectedDynamicItem: _selectedDynamicItem,
    mainSlides,
    setCurrentSlideIndex,
  } = config;

  // Note (Chance, 2023-06-02) `mainSlides` is stored in a ref and updated in
  // its own useEffect because our effect below doesn't need to run in response
  // to changes to `mainSlides`, but we do need a fresh reference to it to avoid
  // stale closures. selectedDynamicItemRef is because we need to compare the
  // current selectedDynamicItem with its value from the prior render cycle, so
  // that gets updated after our effect is finished.
  const mainSlidesRef = React.useRef(mainSlides);
  const selectedDynamicItemRef = React.useRef(_selectedDynamicItem);
  const setCurrentSlideIndexRef = React.useRef(setCurrentSlideIndex);

  React.useEffect(() => {
    mainSlidesRef.current = mainSlides;
    setCurrentSlideIndexRef.current = setCurrentSlideIndex;
  }, [mainSlides, setCurrentSlideIndex]);

  React.useEffect(() => {
    // Note (Noah, 2022-11-13): In order to implement the feature where we can
    // auto-scroll to a particular item of the carousel, we get the index of the
    // item that matches the "current selected item" and whenever it changes, we
    // set the active slide to the index whose item matches (isEqual). This
    // doesn't do anything if the carousel is not dynamic.
    if (!isDynamic) {
      selectedDynamicItemRef.current = _selectedDynamicItem;
      return;
    }

    const setCurrentSlideIndex = setCurrentSlideIndexRef.current;

    const mainSlides = mainSlidesRef.current;
    // Note (Noah, 2023-03-06, REPL-6475): If the strings we're comparing are
    // urls, we want to normalize them all to include "https://" if they don't
    // have a scheme (i.e. they start with "//" directly), in order to make sure
    // that auto-scrolling to selected variants works if the product images have
    // "//"" but the variant featured images have "https://"
    const _previousDynamicItem = selectedDynamicItemRef.current;
    const previousDynamicItem =
      typeof _previousDynamicItem === "string"
        ? normalizeUrlScheme(_previousDynamicItem)
        : _previousDynamicItem;
    const selectedDynamicItem =
      typeof _selectedDynamicItem === "string"
        ? normalizeUrlScheme(_selectedDynamicItem)
        : _selectedDynamicItem;

    if (
      selectedDynamicItem &&
      // Note (Noah, 2023-03-01, REPL-6475): Check whether the current item is
      // equal to the last dynamic item. We don't want to run this effect if the
      // selectedDynamicItem hasn't changed.
      !isEqual(selectedDynamicItem, previousDynamicItem)
    ) {
      const selectedIndex = mainSlides
        .map((slide) => slide.item)
        .findIndex((item) =>
          isEqual(
            typeof item === "string" ? normalizeUrlScheme(item) : item,
            selectedDynamicItem,
          ),
        );
      if (selectedIndex !== -1) {
        setCurrentSlideIndex(selectedIndex);
      }
    }

    selectedDynamicItemRef.current = _selectedDynamicItem;
  }, [_selectedDynamicItem, isDynamic]);
};

export const CarouselV3Indicators = _CarouselIndicators;

function getCustomProps(component: Component): CarouselV3CustomProps {
  return {
    items: component.props._items,
    name: component.props._name,
    dragToScroll: component.props._dragToScroll,
    autoNextInterval: component.props._autoNextInterval,
    pauseOnHover: component.props._pauseOnHover ?? true,
    animationStyle: component.props._animationStyle,
    infinite: component.props._infinite,
    itemsPerView: coerceToInteger(component.props._itemsPerView) ?? undefined,
    itemsPerMove: coerceToInteger(component.props._itemsPerMove) ?? undefined,
    autoWidth: component.props._autoWidth,
    mouseWheel: component.props._mouseWheel,
    slideClickTransition: component.props._slideClickTransition,
    activeArea: component.props._activeArea,
    selectedDynamicItem: component.props._selectedItem,
  };
}

function isSplideInternalError(value: unknown): value is Error {
  return value instanceof Error && value.message.startsWith("[splide]");
}

/**
 * Looks for a unique identifier for a given slide. If one does not exist, fall
 * back to the index. This can probably be improved for dynamic items where
 * component ID and element ID are not unique but other props are.
 */
function getSlideKey(
  slideChild: RenderChildren[number],
  index: number,
  slideChildren: RenderChildren,
) {
  const matches = new Map<"item" | "componentId" | "elementId", number>();
  for (const child of slideChildren) {
    if (isString(child.item) && child.item === slideChild.item) {
      matches.set("item", (matches.get("item") ?? 0) + 1);
    }
    if (child.component.id === slideChild.component.id) {
      matches.set("componentId", (matches.get("componentId") ?? 0) + 1);
    }
    if (child.context.elementId === slideChild.context.elementId) {
      matches.set("elementId", (matches.get("elementId") ?? 0) + 1);
    }
  }
  if (matches.get("item") === 1) {
    return slideChild.item;
  }
  if (matches.get("componentId") === 1) {
    return slideChild.component.id;
  }
  if (matches.get("elementId") === 1) {
    return slideChild.context.elementId;
  }
  return String(index);
}

function useWatchComponentBounds(
  componentId: string | null,
  opts: {
    onChange: () => void;
    globalWindowRef: React.MutableRefObject<GlobalWindow | null>;
  },
) {
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const { onChange, globalWindowRef } = opts;

  // Note (Chance, 2023-05-23): Lazily set this ref during render, otherwise
  // we're triggering `querySelector` and `getBoundingClientRect` in each render
  // cycle which isn't necessary. The ref is only set again in the effect below,
  // so this would slow down renders for no reason.
  const componentBoundsKeyRef = React.useRef<string | null>(null);
  if (
    // Note (Chance, 2023-05-23): We only need to do this in an editor context.
    // Published pages don't have component bounds.
    isEditorCanvas &&
    // NOTE (Chance 2024-05-29): Laziily initialize the ref but only after we
    // know the global window context is available.
    !componentBoundsKeyRef.current &&
    globalWindowRef.current &&
    componentId != null
  ) {
    const document = globalWindowRef.current?.document;
    const dimensions = getDimensions(getComponentNode(document, componentId));
    const boundsKey = dimensions ? getComponentBoundsKey(dimensions) : null;
    componentBoundsKeyRef.current = boundsKey;
  }

  // Note (Martin, 2022-08-25): We need to run this effect after every
  // DOM re-render so that we know if the component's bounds have changed.
  React.useLayoutEffect(() => {
    if (!isEditorCanvas || componentId == null) {
      return;
    }

    const document = globalWindowRef.current?.document;
    const componentNode = document
      ? getComponentNode(document, componentId)
      : null;
    const newComponentBounds = getDimensions(componentNode);
    const newComponentBoundsKey = getComponentBoundsKey(newComponentBounds);
    const newComponentWidth = newComponentBounds?.width ?? 0;
    const newComponentHeight = newComponentBounds?.height ?? 0;

    if (
      newComponentBoundsKey !== componentBoundsKeyRef.current &&
      newComponentWidth > 0 &&
      newComponentHeight > 0
    ) {
      componentBoundsKeyRef.current = newComponentBoundsKey;
      onChange();
    }
  });
}

function getComponentBoundsKey(dimensions: Record<string, number> | null) {
  return dimensions ? Object.values(dimensions).join("-") : "";
}

function getComponentNode(
  document: Document,
  componentId: string,
): Element | null {
  const node = document?.querySelector(`[data-rid="${componentId}"]`);
  return node ?? null;
}

export function coerceToInteger(value: string | number | null | undefined) {
  // NOTE (Chance 2024-01-19): This check is falsey for 0 but it probably should
  // have been a proper nullish. I spotted this because it was originall using
  // `mapNull`, which also (probably incorrectly) does a falsey check. Kept as
  // is to avoid breaking changes, but I don't think it makes a difference in
  // this context.
  if (!value) {
    return null;
  }
  return parseInteger(value);
}

type Dimensions = {
  left: number;
  top: number;
  bottom: number;
  right: number;
  width: number;
  height: number;
};

function getDimensions(el: null | undefined): null;
function getDimensions<T extends Element>(el: T): Dimensions;
function getDimensions<T extends Element>(
  el: T | null | undefined,
): Dimensions | null;

function getDimensions<T extends Element>(el: T | null | undefined) {
  if (!el) {
    return null;
  }
  const { x, top, left, bottom, right } = el.getBoundingClientRect();
  return {
    left: x,
    top: top,
    bottom: bottom,
    right: right,
    width: right - left,
    height: bottom - top,
  };
}
