import type {
  CompareRangePredefinedPeriod,
  MainRangePredefinedTimeframe,
} from "@/features/analytics/time";
import type { ComboboxProps } from "@replo/design-system/components/shadcn/combobox/types";
import type { DateAfter, DateRange } from "react-day-picker";
import type { AnalyticsReadQuery } from "schemas/generated/analyticsRead";

import * as React from "react";

import { Combobox } from "@replo/design-system/components/shadcn/combobox/Combobox";
import { DatePickerWithRange } from "@replo/design-system/components/shadcn/core/date-range-picker";
import { BsCalendar3, BsCaretDownFill } from "react-icons/bs";

type AnalyticsTimeRangeComboboxProps<
  T extends MainRangeHandleChange | CompareRangeHandleChange,
> = Pick<
  ComboboxProps,
  "options" | "value" | "open" | "onOpenChange" | "placeholderPrefix"
> & {
  currentQueryRange: AnalyticsReadQuery["ranges"];
  handleRangeChange: (timeframe: T) => void;
  isCompare: boolean;
};

type CompareRangeHandleChange = CompareRangePredefinedPeriod | DateRange;
type MainRangeHandleChange = MainRangePredefinedTimeframe | DateRange;

const rangeCustomDatePicker = (
  startDateTime: number,
  endDateTime: number,
  handleRangeChange: (dateRange: DateRange) => void,
  setIsRangePickerOpen: (isOpen: boolean) => void,
): React.ReactNode => {
  return (
    <DatePickerWithRange
      initialDateRange={{
        from: new Date(startDateTime),
        to: new Date(endDateTime),
      }}
      disabledDates={{ after: new Date() } as DateAfter}
      updateAction={(dateRange) => {
        handleRangeChange(dateRange);
        setIsRangePickerOpen(false);
      }}
      popoverTriggerComponent={() => (
        <span className="text-default">Custom date range</span>
      )}
      closeAction={() => setIsRangePickerOpen(false)}
      rangeExtendsOnSelect={false}
    />
  );
};

const AnalyticsTimeRangeCombobox = <
  T extends MainRangeHandleChange | CompareRangeHandleChange,
>({
  handleRangeChange,
  isCompare,
  ...props
}: AnalyticsTimeRangeComboboxProps<T>) => {
  const startDatetime = isCompare
    ? props.currentQueryRange.compareAtRanges[0]?.startDatetime
    : props.currentQueryRange.mainRange.startDatetime;

  const endDatetime = isCompare
    ? props.currentQueryRange.compareAtRanges[0]?.endDatetime
    : props.currentQueryRange.mainRange.endDatetime;

  const options = [
    ...props.options,
    {
      value: isCompare ? "custom-compare-range" : "custom-main-range",
      label: "Custom Range",
      component: rangeCustomDatePicker(
        startDatetime ?? 0,
        endDatetime ?? 0,
        (dateRange) => handleRangeChange(dateRange as T),
        (isOpen) => props.onOpenChange?.(isOpen),
      ),
      isBelowSeparator: true,
    },
  ];
  return (
    <Combobox
      {...props}
      options={options}
      onChange={(value) => {
        handleRangeChange(value as T);
      }}
      startEnhancer={() => <BsCalendar3 className="h-4 w-4" />}
      endEnhancer={() => <BsCaretDownFill className="h-2 w-2 text-subtle" />}
    />
  );
};

export default AnalyticsTimeRangeCombobox;
