import type { CanvasState } from "@/features/canvas/canvas-types";
import type { ComponentTemplatesState } from "@editor/reducers/component-templates-reducer";
import type { CandidateState } from "@reducers/candidate-reducer";
import type { CommerceState } from "@reducers/commerce-reducer";
import type { DragAndDropState } from "@reducers/drag-and-drop-reducer";
import type { EditorMediaUploadState } from "@reducers/editor-media-upload-reducer";
import type { FigmaToReploState } from "@reducers/figma-to-replo-reducer";
import type { LiquidRendererState } from "@reducers/liquid-renderer-reducer";
import type { MarketplaceState } from "@reducers/marketplace-reducer";
import type { ModalsState } from "@reducers/modals-reducer";
import type { PaintState } from "@reducers/paint-reducer";
import type { SelectionState } from "@reducers/selection-reducer";
import type { TreeState } from "@reducers/tree-reducer";
import type { UIState } from "@reducers/ui-reducer";
import type { Store } from "@reduxjs/toolkit";
import type * as React from "react";
import type { TypedUseSelectorHook } from "react-redux";
import type { Action } from "reduce-reducers";
import type { ProductRef } from "replo-runtime/shared/types";
import type { CoreState } from "./types/core-state";

import componentTemplatesReducer from "@editor/reducers/component-templates-reducer";
import errorReducer from "@editor/reducers/error-reducer";
import { sanityApi } from "@editor/reducers/sanity-reducer";
import authMiddleware from "@middleware/auth";
import autoSaveMiddleware from "@middleware/autosave";
import errorMiddleware from "@middleware/error";
import listenerMiddleware from "@middleware/listener";
import loggingMiddleware from "@middleware/logging";
import redirectMiddleware from "@middleware/redirect";
import { sequenceActionMiddleware } from "@middleware/sequence-action";
import { setDraftElementMiddleware } from "@middleware/set-draft-element";
import updateCheckingMiddleware from "@middleware/update-checking";
import { aiApi } from "@reducers/ai-reducer";
import { assetsApi, productsApi, publisherApi } from "@reducers/api-reducer";
import candidateReducer from "@reducers/candidate-reducer";
import { reducer as commerceReducer } from "@reducers/commerce-reducer";
import { coreReducer } from "@reducers/core-reducer";
import dragAndDropReducer from "@reducers/drag-and-drop-reducer";
import editorMediaUploadReducer from "@reducers/editor-media-upload-reducer";
import figmaToReploReducer from "@reducers/figma-to-replo-reducer";
import liquidRendererReducer from "@reducers/liquid-renderer-reducer";
import marketplaceReducer from "@reducers/marketplace-reducer";
import { modalsReducer } from "@reducers/modals-reducer";
import paintReducer from "@reducers/paint-reducer";
import selectionReducer from "@reducers/selection-reducer";
import templateReducer from "@reducers/template-reducer";
import treeReducer from "@reducers/tree-reducer";
import uiReducer from "@reducers/ui-reducer";

import canvasReducer from "@/features/canvas/canvas-reducer";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, useStore } from "react-redux";
import { apiMiddleware } from "redux-api-middleware";
import { canUseDOM } from "replo-utils/dom/misc";

const rootReducer = combineReducers({
  modals: modalsReducer,
  core: coreReducer,
  commerce: commerceReducer,
  liquidRenderer: liquidRendererReducer,
  dragAndDrop: dragAndDropReducer,
  canvas: canvasReducer,
  paint: paintReducer,
  candidate: candidateReducer,
  ui: uiReducer,
  error: errorReducer,
  selection: selectionReducer,
  tree: treeReducer,
  marketplace: marketplaceReducer,
  template: templateReducer,
  componentTemplates: componentTemplatesReducer,
  editorMediaUpload: editorMediaUploadReducer,
  figmaToReplo: figmaToReploReducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [aiApi.reducerPath]: aiApi.reducer,
  [publisherApi.reducerPath]: publisherApi.reducer,
  [assetsApi.reducerPath]: assetsApi.reducer,
  [sanityApi.reducerPath]: sanityApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: isUsingDevMiddleware(),
      immutableCheck: isUsingDevMiddleware(),
    })
      .prepend(
        setDraftElementMiddleware,
        sequenceActionMiddleware,
        errorMiddleware,
        authMiddleware,
        apiMiddleware,
        loggingMiddleware,
        redirectMiddleware,
        autoSaveMiddleware,
        listenerMiddleware.middleware,
      )
      .concat([
        updateCheckingMiddleware,
        aiApi.middleware,
        productsApi.middleware,
        publisherApi.middleware,
        assetsApi.middleware,
        sanityApi.middleware,
      ]),
  devTools:
    process.env.NODE_ENV !== "production"
      ? {
          stateSanitizer: (state) => {
            return {
              ...state,
              // Note (Noah, 2022-02-21): The canvas state includes a very large srcdoc string,
              // which redux devtools copies for every action/new state. This results in a huge
              // memory blowup (every single new state has ~500kb of copied string!) so we
              // sanitize away the large data to make devtools work. See:
              // https://github.com/zalmoxisus/redux-devtools-extension/issues/455
              canvas: {
                // @ts-ignore
                ...state.canvas,
                srcDoc:
                  "[Replo Local Dev]: Canvas srcDoc omitted for Redux devtools performance.",
              },
            };
          },
        }
      : false,
});

export type EditorRootState = {
  modals: ModalsState;
  core: CoreState;
  commerce: CommerceState;
  liquidRenderer: LiquidRendererState;
  dragAndDrop: DragAndDropState;
  canvas: CanvasState;
  paint: PaintState;
  candidate: CandidateState;
  ui: UIState;
  error: {};
  selection: SelectionState;
  tree: TreeState;
  marketplace: MarketplaceState;
  template: { templateEditorProduct: ProductRef | null };
  componentTemplates: ComponentTemplatesState;
  editorMediaUpload: EditorMediaUploadState;
  figmaToReplo: FigmaToReploState;
  [productsApi.reducerPath]: any;
  [publisherApi.reducerPath]: any;
  [aiApi.reducerPath]: any;
  [assetsApi.reducerPath]: any;
  [sanityApi.reducerPath]: any;
};

export type EditorDispatch = typeof store.dispatch & React.Dispatch<Action[]>;
export const useEditorSelector: TypedUseSelectorHook<EditorRootState> =
  useSelector;
export const useEditorDispatch = () => useDispatch<EditorDispatch>();
export const useEditorStore = () => useStore<EditorRootState, Action>();
export type EditorStore = Store<EditorRootState, Action>;

function isUsingDevMiddleware() {
  if (!canUseDOM) {
    return false;
  }
  return (
    new URLSearchParams(window.location.search).get("dev-middleware") === "true"
  );
}
