import type { WorkspaceUrlHost } from "@/features/analytics/useWorkspaceUrlHosts";
import type { AnalyticsReadQuery } from "schemas/generated/analyticsRead";

import * as React from "react";

import { DEFAULT_QUERY } from "@/features/analytics/constants";

export const AnalyticsQueryContext = React.createContext<{
  query: AnalyticsReadQuery;
  setQuery: (query: AnalyticsReadQuery) => void;
  workspaceUrlHosts: WorkspaceUrlHost;
}>({
  query: {
    ...DEFAULT_QUERY,
    urlHosts: [],
  },
  setQuery: () => null,
  workspaceUrlHosts: {
    data: [],
    isLoading: false,
  },
});

export const useAnalyticsQueryContext = () =>
  React.useContext(AnalyticsQueryContext);
