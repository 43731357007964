import * as React from "react";

import { isNewRightBarUIEnabled } from "@editor/infra/featureFlags";
import { getHotKey } from "@editor/utils/getHotKey";

import classNames from "classnames";
import isString from "lodash-es/isString";
import { twMerge } from "tailwind-merge";

type SelectionIndicatorProps = {
  title: React.ReactNode;
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode;
  onClick?(e: React.MouseEvent): void;
  /**
   * function to call when SelectionIndicator is option-clicked. If this is
   * undefined (or not passed), onClick will be called when option-clicked.
   */
  onReset?(e: React.MouseEvent): void;
  className?: string;
  labelClassname?: string;
  style?: React.CSSProperties;
  placeholder?: string;
};

const SelectorTitle = ({
  title,
  labelClassname,
}: {
  title: React.ReactNode;
  labelClassname?: string;
}) => {
  return isString(title) ? (
    <span
      className={twMerge(
        "my-auto grow truncate text-xs text-default",
        labelClassname,
      )}
    >
      {title}
    </span>
  ) : (
    <>{title}</>
  );
};

const SelectionIndicator = React.forwardRef<
  HTMLDivElement,
  SelectionIndicatorProps
>(
  (
    {
      title,
      startEnhancer,
      endEnhancer,
      onClick,
      onReset,
      className,
      labelClassname,
      style,
      placeholder,
    },
    ref,
  ) => {
    const handleClick = (e: React.MouseEvent) => {
      if (onReset && getHotKey(e) === "altKey") {
        onReset(e);
      } else {
        onClick?.(e);
      }
    };
    const isNewRightBarEnabled = isNewRightBarUIEnabled();
    return (
      <div
        ref={ref}
        className={twMerge(
          classNames(
            "flex h-6 w-full rounded bg-subtle p-2 items-center overflow-hidden whitespace-nowrap gap-2",
            {
              "cursor-pointer": Boolean(onClick),
              "p-1": isNewRightBarEnabled,
            },
          ),
          className,
        )}
        onClick={handleClick}
        style={style}
      >
        {startEnhancer ? (
          <div className="flex shrink-0">{startEnhancer}</div>
        ) : null}
        <div className="flex w-full items-center overflow-hidden text-ellipsis">
          {placeholder && !title ? (
            <span
              className={twMerge(
                "my-auto grow text-xs text-subtle text-start",
                labelClassname,
              )}
            >
              {placeholder}
            </span>
          ) : (
            <SelectorTitle title={title} labelClassname={labelClassname} />
          )}
        </div>
        <div
          className={classNames("flex items-center justify-center", {
            "h-4 w-4 shrink-0": isNewRightBarEnabled,
          })}
        >
          {endEnhancer}
        </div>
      </div>
    );
  },
);

SelectionIndicator.displayName = "SelectionIndicator";

export default SelectionIndicator;
