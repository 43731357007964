import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _liquidContent: {
      name: "Liquid Content",
      type: "liquidCodeEditor",
      description:
        "Specify the the Shopify Liquid source code to embed in this component.",
      defaultValue: null,
    },
  };
}

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    customProps: convertToLegacyProps(getConfigurableProps()),
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
    ancestorDisallowList: [
      {
        elementTypes: ["shopifyArticle"],
        message: "Shopify Liquid can't be used in Shopify Articles",
      },
      {
        ancestorTypes: ["button"],
        message: "Shopify Liquid cannot be nested inside buttons.",
      },
    ],
  },
} satisfies ComponentConfig;

export default config;
