import type { FindUrlParamsQuery } from "schemas/analyticsRead";

import * as React from "react";

import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

const useFindUrlParams = (
  query: FindUrlParamsQuery,
  workspaceId: string | null,
) => {
  const shouldFetch = workspaceId && query.urlHosts.length > 0;

  const {
    data,
    isLoading,
    refetch: refetchUrlParams,
  } = trpc.analytics.findUrlParams.useQuery(
    shouldFetch ? { query, workspaceId } : skipToken,
  );

  const [urlParams, setUrlParams] = React.useState(data?.urlParams);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We want this for now
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await refetchUrlParams();
      setUrlParams(response.data?.urlParams);
    };

    if (shouldFetch) {
      void fetchData();
    }
  }, [query, workspaceId, refetchUrlParams, shouldFetch, setUrlParams]);
  return {
    urlParams: urlParams ?? null,
    isLoading,
  };
};

export default useFindUrlParams;
