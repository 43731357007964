import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    circleWidth: {
      type: "pixels",
      name: "Circle Width",
      description: "Size of the spinner",
      defaultValue: 20,
    },
    foregroundColor: {
      type: "color",
      name: "Foreground Color",
      description: "Primary color of the spinner",
      defaultValue: "#e2e8f0",
    },
    secondaryColor: {
      type: "color",
      name: "Secondary Color",
      description: "Secondary color of the spinner",
      defaultValue: "#64748b",
    },
  };
}

export const styleElements = {
  root: {
    defaultStyles: {
      lineHeight: 0,
    },
  },
  icon: {
    overrideStyles({ component }) {
      const circleWidth = String(component.props.circleWidth);
      return {
        width: circleWidth,
        height: circleWidth,
      };
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: true,
    allowsLayoutModification: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: true,
    customProps: convertToLegacyProps(getConfigurableProps()),
    canvasIndicatorDragDirections: [],
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
