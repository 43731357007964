import type {
  CompareRangePredefinedPeriod,
  MainRangePredefinedTimeframe,
} from "@/features/analytics/time";
import type { DateRange } from "react-day-picker";

export function calculateDelta(
  newValue: number | undefined,
  oldValue: number | undefined,
) {
  if (!oldValue || newValue === undefined) {
    return 0;
  }

  return oldValue === 0 ? newValue : ((newValue - oldValue) / oldValue) * 100;
}

/**
 * Given a UNIX startDatetime, endDatetime, and interval, returns the object
 * (with random id) which will be used by QueryRanges. Either for the
 * mainRange, or compareAtRange.
 *
 * @author Max 2024-09-16
 */
export function generateRange(
  startDatetime: number,
  endDatetime: number,
  interval: number,
) {
  return {
    id: crypto.randomUUID(),
    startDatetime,
    endDatetime,
    interval,
  };
}

/**
 * Given a selected timeframe/period, returns true if it's a custom date range.
 * If it returns true, we can be 100% sure that the value has a .from/.to
 *
 * @author Max 2024-09-17
 */
export function isCustomDateRange(
  value:
    | CompareRangePredefinedPeriod
    | MainRangePredefinedTimeframe
    | DateRange,
): value is DateRange {
  return (
    (value as DateRange).from !== undefined &&
    (value as DateRange).to !== undefined
  );
}

export function sanitizePageUrlPath(urlPath: string) {
  return urlPath !== "/" ? urlPath : "Homepage";
}
