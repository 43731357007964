import type { ToggleGroupOption } from "@common/designSystem/ToggleGroup";
import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { LayoutPreset, LayoutPresetChild } from "@editor/types/modifiers";
import type { IconType } from "react-icons/lib";
import type { Component } from "replo-runtime/shared/types";
import type { PreviewableProperty } from "replo-runtime/shared/utils/preview";

import * as React from "react";

import ToggleGroup from "@common/designSystem/ToggleGroup";
import { HotkeyIndicator } from "@common/HotkeyIndicator";
import Badge from "@editor/components/common/designSystem/Badge";
import InlinePopover from "@editor/components/common/designSystem/InlinePopover";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Selectable from "@editor/components/common/designSystem/Selectable";
import Tooltip from "@editor/components/common/designSystem/Tooltip";
import { useApplyComponentAction } from "@editor/hooks/useApplyComponentAction";
import useGetFlexboxWidthOrHeightOnChange from "@editor/hooks/useGetFlexboxWidthOrHeightOnChange";
import {
  selectAlignItems,
  selectAlignSelf,
  selectColumnGap,
  selectComponentChildrenPrivateDimensions,
  selectDisplay,
  selectDraftComponentAcceptsArbitraryChildren,
  selectDraftComponentChildren,
  selectDraftComponentType,
  selectedDraftComponentIsRoot,
  selectFlexGap,
  selectFlexGrow,
  selectFlexWrap,
  selectGridColumnEndTemplate,
  selectHeight,
  selectIsDraftComponentDynamic,
  selectNonTextComponentText,
  selectNumberOfColumns,
  selectNumberOfMinAndMaxDimensionsSet,
  selectOverflow,
  selectParentFlexDirection,
  selectPosition,
  selectPrivateOverflow,
  selectRowGap,
  selectWidth,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { DraggingDirections, DraggingTypes } from "@editor/utils/editor";
import {
  flipFlexStartAndFlexEnd,
  useAlignItems,
  useFlexDirection,
  useFlexJustifyState,
  useJustifyContent,
} from "@editor/utils/flex";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import ModifierGroup from "@editorExtras/ModifierGroup";
import LengthInputModifier from "@editorModifiers/LengthInputModifier";
import { useCurrentPosition } from "@editorModifiers/PositioningModifier";
import Stepper from "@editorModifiers/Stepper";
import { layouts } from "@editorModifiers/utils";
import HeightIcon from "@svg/height";
import MaxSvg from "@svg/max";
import MinSvg from "@svg/min";
import WidthIcon from "@svg/width";

import classNames from "classnames";
import map from "lodash-es/map";
import { AiOutlineColumnHeight, AiOutlineColumnWidth } from "react-icons/ai";
import { BiMoveVertical } from "react-icons/bi";
import {
  BsArrowsCollapse,
  BsArrowsExpand,
  BsDistributeHorizontal,
  BsDistributeVertical,
  BsFillEyeFill,
  BsScissors,
  BsTextWrap,
} from "react-icons/bs";
import { CgOverflow } from "react-icons/cg";
import { HiArrowDown, HiArrowRight } from "react-icons/hi";
import { MdViewColumn } from "react-icons/md";
import { mapNull } from "replo-runtime/shared/utils/optional";
import {
  CSS_LENGTH_TYPES,
  CSS_LENGTH_TYPES_COMPUTED_WITH_PERCENTAGE,
} from "replo-runtime/shared/utils/units";
import { componentTypeToRenderData } from "replo-runtime/store/components";
import { coerceNumberToString } from "replo-utils/lib/misc";
import { twMerge } from "tailwind-merge";

import { useAlign } from "./SizeModifier";

const FLEX_START_PROPERTY_VALUES = ["flex-start", "start"];
const FLEX_END_PROPERTY_VALUES = ["flex-end", "end"];

const ALIGNMENT_PROPERTY_VALUES = [
  ...FLEX_START_PROPERTY_VALUES,
  ...FLEX_END_PROPERTY_VALUES,
  "center",
];

const VALUE_TO_ALIGNMENT_OPTION: Record<string, string> = {
  start: "flex-start",
  center: "center",
  end: "flex-end",
  "flex-start": "flex-start",
  "flex-end": "flex-end",
  "space-between": "space-between",
};

type LayoutPresets = {
  preview: (
    layoutPreset: LayoutPreset,
    onClick: (layoutPreset: LayoutPreset) => void,
    shouldAllowPreset: boolean,
    isSelected: boolean,
  ) => React.ReactNode;
  layouts: LayoutPreset[];
};

const layoutPresets: LayoutPresets = {
  preview: (layoutPreset, onClick, shouldAllowPreset, isSelected) => {
    return (
      <div
        key={layoutPreset.parentStyle.gridTemplateColumns}
        className={twMerge(
          "group flex cursor-pointer flex-row rounded border border-transparent bg-subtle",
          shouldAllowPreset && "hover:border-blue-600",
          isSelected && "border-blue-600",
        )}
        onClick={shouldAllowPreset ? () => onClick(layoutPreset) : undefined}
        style={{ cursor: shouldAllowPreset ? "pointer" : "not-allowed" }}
      >
        {layoutPreset.children.map((child, index) => {
          return (
            <div
              className={twMerge(
                "width-auto m-0.5 flex justify-center rounded-md bg-slate-200 p-1 text-slate-300",
                shouldAllowPreset &&
                  "group-hover:bg-blue-200 group-hover:text-blue-300",
                isSelected && "bg-blue-200 text-blue-300",
              )}
              key={index}
              style={{ ...child.style }}
            >
              {child.style.flexGrow}
            </div>
          );
        })}
      </div>
    );
  },
  layouts,
};

type LayoutPresetSelectorProps = {
  layoutPresets: LayoutPresets;
  isEnabledLayoutPreset: (layoutPreset: LayoutPreset) => boolean;
  onSelectLayoutPreset: (layoutPreset: LayoutPreset) => void;
};

const LayoutPresetSelector: React.FC<LayoutPresetSelectorProps> = ({
  layoutPresets,
  isEnabledLayoutPreset,
  onSelectLayoutPreset,
}) => {
  const gridTemplateColumns = useEditorSelector(selectGridColumnEndTemplate);
  const displayValue = useEditorSelector(selectDisplay);
  const isDisplayGrid = displayValue === "grid";

  return (
    <div className="grid grid-cols-2 gap-2">
      {layoutPresets.layouts.map((layoutPreset) => {
        const shouldAllowPreset = isEnabledLayoutPreset(layoutPreset);
        return layoutPresets.preview(
          layoutPreset,
          onSelectLayoutPreset,
          shouldAllowPreset,
          isDisplayGrid &&
            gridTemplateColumns ===
              layoutPreset.parentStyle.gridTemplateColumns,
        );
      })}
    </div>
  );
};

const DeprecatedFlexboxModifier: React.FC<{
  includeAlignmentModifier?: boolean;
}> = ({ includeAlignmentModifier = true }) => {
  const draftComponentType = useEditorSelector(selectDraftComponentType);
  const nonTextComponentText = useEditorSelector(selectNonTextComponentText);
  const allowsLayoutModification =
    mapNull(draftComponentType, (draftComponentType) => {
      const allowsLayoutModificationOrFunction =
        componentTypeToRenderData[draftComponentType]?.allowsLayoutModification;
      if (typeof allowsLayoutModificationOrFunction === "function") {
        return allowsLayoutModificationOrFunction({
          textValue: nonTextComponentText,
        });
      }
      return allowsLayoutModificationOrFunction;
    }) ?? false;
  const isPageRootComponent = useEditorSelector(selectedDraftComponentIsRoot);
  const draftComponentPosition = useEditorSelector(selectPosition);
  const isFixedOrAbsolutePosition =
    draftComponentPosition &&
    ["fixed", "absolute"].includes(draftComponentPosition);
  const shouldShowAlignSelfModifiers =
    /* If the position is absolute or fixed, there's no point in aligning yourself */
    /* TODO (Yuxin, 2022-02-22) This should be disabled instead of magically hidden */
    !isFixedOrAbsolutePosition &&
    // Note (Sebas, 2022-08-31): If it is the page root component the
    // align self property doesn't make sense
    !isPageRootComponent;
  const shouldShowDimensionModifiers = !isPageRootComponent;

  if (
    !shouldShowAlignSelfModifiers &&
    !allowsLayoutModification &&
    !shouldShowDimensionModifiers
  ) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-4">
      {includeAlignmentModifier &&
        (shouldShowAlignSelfModifiers || shouldShowDimensionModifiers) && (
          <ModifierGroup title="Alignment">
            <div className="flex flex-col gap-y-2">
              {shouldShowAlignSelfModifiers && <AlignmentToggles />}
              {shouldShowDimensionModifiers && <DimensionModifier />}
            </div>
          </ModifierGroup>
        )}
      {allowsLayoutModification && (
        <ModifierGroup title="Layout">
          <LayoutModifier />
        </ModifierGroup>
      )}
    </div>
  );
};

const FlexLayoutModifier: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const onChangeFlexGap = (v: string) => {
    applyComponentAction({
      type: "setStyles",
      value: { __flexGap: v },
    });
  };

  return (
    <>
      <FlexSpacingToggles />
      <div
        className="grid items-center justify-items-start gap-2"
        style={{ gridTemplateColumns: "1fr 1fr" }}
      >
        <div className="h-full w-full">
          <AlignmentQuadrants />
        </div>
        <div
          className="grid items-center justify-items-start gap-2"
          style={{ gridTemplateRows: "1fr 1fr 1fr 1fr" }}
        >
          <FlexDirectionToggles />
          <OverflowSelect />
          <FlexGapInput
            onChange={onChangeFlexGap}
            startEnhancer={() => (
              <Tooltip
                triggerAsChild
                inheritCursor
                content="Set gap between sub-components of this container"
              >
                <span tabIndex={0}>
                  <BsDistributeHorizontal
                    size="16px"
                    className="text-slate-400"
                  />
                </span>
              </Tooltip>
            )}
            placeholder="Gap"
          />
        </div>
      </div>
    </>
  );
};

const options = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
];

const GridLayoutModifier: React.FC = () => {
  const value = (useEditorSelector(selectNumberOfColumns) as number) ?? 1;
  const columnGap = useEditorSelector(selectColumnGap);
  const draftComponentAcceptsArbitraryChildren = useEditorSelector(
    selectDraftComponentAcceptsArbitraryChildren,
  );
  const isDraftComponentDynamic = useEditorSelector(
    selectIsDraftComponentDynamic,
  );

  const shouldAddNewContainer =
    draftComponentAcceptsArbitraryChildren && !isDraftComponentDynamic;

  const rowGap = useEditorSelector(selectRowGap);
  const applyComponentAction = useApplyComponentAction();
  const onChangePreset = (newValue: LayoutPreset) => {
    const actions: UseApplyComponentActionType[] = [
      {
        type: "setStyles",
        value: newValue.parentStyle,
      },
    ];

    if (shouldAddNewContainer) {
      actions.push({
        type: "applyPresetProps",
        value: newValue,
        analyticsExtras: {
          actionType: "create",
          createdBy: "replo",
        },
      });
    }

    applyComponentAction({
      type: "applyCompositeAction",
      value: actions,
    });
  };

  const onNumberOfColumnChange = (value: number) => {
    const layoutPreset: LayoutPreset = {
      children: Array.from({ length: value }).fill({
        style: {},
      }) as LayoutPresetChild[],
      parentStyle: {},
    };

    const actions: UseApplyComponentActionType[] = [
      {
        type: "setStyles",
        value: {
          display: "grid",
          __numberOfColumns: value,
          gridTemplateColumns: `repeat(${value}, minmax(0, 1fr))`,
        },
      },
    ];

    // Note (Ovishek, 2022-11-02): We only add new columns for components that accepts arbitrary children. For dynamic components
    // like product collection they repeat dynamically based on the dynamic data it has been assigned that's why we ignore adding
    // new container to such component type
    if (shouldAddNewContainer) {
      actions.push({
        type: "applyPresetProps",
        value: layoutPreset,
      });
    }

    applyComponentAction({
      type: "applyCompositeAction",
      value: actions,
    });
  };

  return (
    <>
      <Stepper
        onChange={onNumberOfColumnChange}
        placeholder="1"
        value={value}
        field="style.__numberOfColumns"
        startEnhancer={() => (
          <Tooltip inheritCursor content="Number of columns" triggerAsChild>
            <span tabIndex={0}>
              <MdViewColumn size={18} />
            </span>
          </Tooltip>
        )}
        menuOptions={options}
        toolTipMinusButton="Remove column"
        toolTipPlusButton="Add column"
        resetValue="1"
        anchorValue="1"
        minDragValue={1}
        minValue={1}
      />
      <div className="flex flex-row gap-2">
        <FlexGapInput
          value={columnGap ? String(columnGap) : undefined}
          onChange={(value) => {
            applyComponentAction({
              type: "setStyles",
              value: {
                columnGap: value,
              },
            });
          }}
          startEnhancer={() => (
            <Tooltip
              inheritCursor
              content="Set gap between columns of this container"
              triggerAsChild
            >
              <span tabIndex={0}>
                <BsDistributeHorizontal
                  size="16px"
                  className="text-slate-400"
                />
              </span>
            </Tooltip>
          )}
          placeholder="Column Gap"
          previewProperty="columnGap"
        />
        <FlexGapInput
          value={rowGap ? String(rowGap) : undefined}
          onChange={(value) => {
            applyComponentAction({
              type: "setStyles",
              value: {
                rowGap: value,
              },
            });
          }}
          startEnhancer={() => (
            <Tooltip
              inheritCursor
              content="Set gap between rows of this container"
              triggerAsChild
            >
              <span tabIndex={0}>
                <BsDistributeVertical size="16px" className="text-slate-400" />
              </span>
            </Tooltip>
          )}
          placeholder="Row Gap"
          previewProperty="rowGap"
        />
      </div>
      <LayoutPresetSelector
        layoutPresets={layoutPresets}
        onSelectLayoutPreset={onChangePreset}
        isEnabledLayoutPreset={() => {
          return true;
        }}
      />
    </>
  );
};

type Layout = "flex" | "grid";

const TOGGLE_OPTIONS: Record<Layout, ToggleGroupOption> = {
  flex: {
    label: "Auto Layout",
    value: "flex",
    tooltipContent: "Horizontal/Vertical Stack",
  },
  grid: {
    label: "Columns",
    value: "grid",
    tooltipContent: "Grid with Adjustable Columns",
  },
};

const LayoutModifier: React.FC = () => {
  const store = useEditorStore();
  const displayValue = useEditorSelector(selectDisplay);
  const activeTab = displayValue === "grid" ? "grid" : "flex";
  const applyComponentAction = useApplyComponentAction();
  const flexGap = useEditorSelector(selectFlexGap) || 0;
  const overflow = useEditorSelector(selectPrivateOverflow);
  const columnGap = useEditorSelector(selectColumnGap);
  const rowGap = useEditorSelector(selectRowGap);
  const parentFlexDirection = useEditorSelector(selectParentFlexDirection);

  const getActions = (
    draftComponentChildren: Component[],
    styleProperties?: Record<string, string>,
  ) => {
    const childrenPrivateDimensions = selectComponentChildrenPrivateDimensions(
      store.getState(),
    );
    const actions: UseApplyComponentActionType[] =
      draftComponentChildren.map((c, i) => {
        return {
          componentId: c.id,
          type: "setStyles",
          value: styleProperties ?? {
            width: childrenPrivateDimensions?.[i]?.width.value ?? "auto",
            height: childrenPrivateDimensions?.[i]?.height.value ?? "auto",
          },
          analyticsExtras: {
            actionType: "edit",
            createdBy: "replo",
          },
        };
      }) || [];
    return actions;
  };

  const onChange = (value: Layout) => {
    const draftComponentChildren = selectDraftComponentChildren(
      store.getState(),
    );
    const numberOfColumns =
      selectNumberOfColumns(store.getState()) ??
      Math.min(draftComponentChildren.length, 4);
    if (value === "grid") {
      const actions = getActions(draftComponentChildren, {
        width: "auto",
        height: "auto",
      });
      applyComponentAction({
        type: "applyCompositeAction",
        value: actions.concat({
          type: "setStyles",
          value: {
            display: "grid",
            gridTemplateColumns: `repeat(${numberOfColumns}, minmax(0, 1fr))`,
            __numberOfColumns: numberOfColumns,
            __flexGap: 0,
            columnGap: columnGap ?? flexGap,
            rowGap: rowGap ?? flexGap,
            overflow: null,
          },
        }),
      });
    } else {
      const actions = getActions(draftComponentChildren);
      applyComponentAction({
        type: "applyCompositeAction",
        value: actions.concat({
          type: "setStyles",
          value: {
            display: "flex",
            __flexGap:
              parentFlexDirection === "column" ? columnGap ?? 0 : rowGap ?? 0,
            overflow,
          },
        }),
      });
    }
  };

  return (
    <div className="flex flex-col gap-y-2">
      <ToggleGroup
        allowsDeselect={false}
        type="single"
        options={Object.values(TOGGLE_OPTIONS)}
        value={activeTab}
        onChange={onChange}
        style={{ width: "100%" }}
      />
      {activeTab === "flex" && <FlexLayoutModifier />}
      {activeTab === "grid" && <GridLayoutModifier />}
    </div>
  );
};

const DimensionModifier: React.FC = () => {
  const flexGrow = useEditorSelector(selectFlexGrow);
  const width = useEditorSelector(selectWidth);
  const height = useEditorSelector(selectHeight);
  const parentFlexDirection = useEditorSelector(selectParentFlexDirection);
  const alignSelf = useEditorSelector(selectAlignSelf);
  const currentPosition = useCurrentPosition();
  const onChange = useGetFlexboxWidthOrHeightOnChange();
  const isAbsolutePosition = currentPosition === "absolute";

  const toggleGroupWidthValue = getToggleGroupValue(
    "width",
    flexGrow ?? null,
    alignSelf ?? null,
    parentFlexDirection,
    coerceNumberToString(width) ?? null,
    coerceNumberToString(height) ?? null,
    isAbsolutePosition,
  );
  const toggleGroupHeightValue = getToggleGroupValue(
    "height",
    flexGrow ?? null,
    alignSelf ?? null,
    parentFlexDirection,
    coerceNumberToString(width) ?? null,
    coerceNumberToString(height) ?? null,
    isAbsolutePosition,
  );

  const elements = [
    {
      label: "Width",
      value: toggleGroupWidthValue,
      onChange: (value: "fill" | "wrap" | "fixed") => onChange(value, "width"),
      options: [
        {
          label: <BsArrowsExpand size={16} className="rotate-90" />,
          value: "fill",
          tooltipContent: (
            <HotkeyIndicator
              hotkey="setWidthToFillAvailable"
              title="Fill Available Width"
            />
          ),
        },
        {
          label: <BsArrowsCollapse size={16} className="rotate-90" />,
          value: "wrap",
          tooltipContent: (
            <HotkeyIndicator
              hotkey="setWidthToWrapContent"
              title="Wrap Content"
            />
          ),
        },
        {
          label: <AiOutlineColumnWidth size={16} />,
          value: "fixed",
          tooltipContent: "Fixed Width",
          attributes: {
            "data-testid": "fixed-width-button",
          },
        },
      ],
    },
    {
      label: "Height",
      value: toggleGroupHeightValue,
      onChange: (value: "fill" | "wrap" | "fixed") => onChange(value, "height"),
      options: [
        {
          label: <BsArrowsExpand size={16} />,
          value: "fill",
          tooltipContent: (
            <HotkeyIndicator
              hotkey="setHeightToFillAvailable"
              title="Fill Available Height"
            />
          ),
        },
        {
          label: <BsArrowsCollapse size={16} />,
          value: "wrap",
          tooltipContent: (
            <HotkeyIndicator
              hotkey="setHeightToWrapContent"
              title="Wrap Content"
            />
          ),
        },
        {
          label: <AiOutlineColumnHeight size={16} />,
          value: "fixed",
          tooltipContent: "Fixed Height",
          attributes: {
            "data-testid": "fixed-height-button",
          },
        },
      ],
    },
  ];

  return (
    <>
      {elements.map(({ label, onChange, options, value }) => (
        <LabeledControl label={label} key={label} size="sm">
          <div>
            <div className="flex flex-row gap-2">
              <ToggleGroup
                type="single"
                allowsDeselect={false}
                style={{
                  width: "100%",
                }}
                value={String(value)}
                options={options}
                onChange={onChange}
              />
              {label === "Width" && (
                <WidthControl disabled={toggleGroupWidthValue !== "fixed"} />
              )}
              {label === "Height" && (
                <HeightControl disabled={toggleGroupHeightValue !== "fixed"} />
              )}
            </div>
          </div>
        </LabeledControl>
      ))}
      <WidthAndHeightEdit />
    </>
  );
};

const AlignmentToggles: React.FC = () => {
  const { value, options, onChange } = useAlign();
  return (
    <ToggleGroup
      type="single"
      style={{
        width: "100%",
      }}
      value={value ?? styleAttributeToEditorData.alignSelf.defaultValue}
      options={options}
      onChange={onChange}
      allowsDeselect={false}
    />
  );
};

const FlexSpacingToggles: React.FC = () => {
  const [value, setValue] = useFlexJustifyState();
  const rawAlignItems = useEditorSelector(selectAlignItems);
  const alignItems = VALUE_TO_ALIGNMENT_OPTION[rawAlignItems || "center"];
  const applyComponentAction = useApplyComponentAction();

  return (
    <ToggleGroup
      type="single"
      value={value}
      allowsDeselect={false}
      options={[
        {
          label: "Packed",
          value: "packed",
          tooltipContent: "Group items together as close as possible",
        },
        {
          label: "Spaced",
          value: "spaced",
          tooltipContent: "Distribute the space between items equally",
        },
      ]}
      onChange={(newValue: any) => {
        const justifyContent =
          newValue === "packed" ? "center" : "space-between";

        applyComponentAction({
          type: "setStyles",
          value: {
            display: "flex",
            alignItems,
            justifyContent,
          },
        });
        setValue(newValue);
      }}
      style={{ width: "100%" }}
    />
  );
};

const AlignmentQuadrants: React.FC = () => {
  const [value] = useFlexJustifyState();

  return (
    <div className="flex h-full flex-col rounded-sm" style={{ width: "128px" }}>
      {value === "packed" ? (
        <div className="mt-1 grid h-full grid-cols-3 gap-1 bg-subtle p-2">
          {[0, 1, 2].flatMap((rowNum) => {
            return [0, 1, 2].map((colNum) => {
              return (
                <PackedAlignmentQuadrant
                  key={`${rowNum}-${colNum}`}
                  row={rowNum}
                  col={colNum}
                />
              );
            });
          })}
        </div>
      ) : (
        <div className="mt-1 grid h-full grid-cols-3 gap-1 bg-subtle p-2">
          {map([0, 1, 2], (idx) => {
            return <SpacedAlignmentQuadrant idx={idx} />;
          })}
        </div>
      )}
    </div>
  );
};

const quadrantAlignmentClassNamesInOrder = [
  "items-start",
  "items-center",
  "items-end",
];
const alignmentValuesInOrder = ["flex-start", "center", "flex-end"];

const PackedAlignmentQuadrant: React.FC<{ row: number; col: number }> = ({
  row,
  col,
}) => {
  const applyComponentAction = useApplyComponentAction();
  const { orientation } = useFlexDirection();
  const justifyContent = useJustifyContent();
  const alignItems = useAlignItems();
  const { isReversed } = useFlexDirection();

  if (orientation === "row") {
    const justifyOption = alignmentValuesInOrder[col];
    const alignOption = alignmentValuesInOrder[row];
    const isSelected =
      justifyContent === justifyOption && alignItems === alignOption;
    const barBgColorClassName = isSelected
      ? "bg-blue-600"
      : "bg-subtle group-hover:bg-hover";

    return (
      <div
        key={`${row}-${col}`}
        className={`group flex flex-row ${quadrantAlignmentClassNamesInOrder[row]} cursor-pointer rounded`}
        onClick={() => {
          const effectiveJustifyOption = isReversed
            ? flipFlexStartAndFlexEnd(justifyOption!)
            : justifyOption;
          applyComponentAction({
            type: "setStyles",
            value: {
              display: "flex",
              alignItems: alignOption,
              justifyContent: effectiveJustifyOption,
            },
          });
        }}
      >
        <div
          className={classNames("mx-0.5 h-6 w-2 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("mx-0.5 h-8 w-2 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("mx-0.5 h-4 w-2 rounded", barBgColorClassName)}
        />
      </div>
    );
  } else if (orientation === "column") {
    const justifyOption = alignmentValuesInOrder[row];
    const alignOption = alignmentValuesInOrder[col];
    const isSelected =
      justifyContent === justifyOption && alignItems === alignOption;
    const barBgColorClassName = isSelected
      ? "bg-blue-600"
      : "bg-subtle group-hover:bg-hover";

    return (
      <div
        key={`${row}-${col}`}
        className={`group flex flex-col ${quadrantAlignmentClassNamesInOrder[col]} cursor-pointer rounded`}
        onClick={() => {
          applyComponentAction({
            type: "setStyles",
            value: {
              display: "flex",
              alignItems: alignOption,
              justifyContent: justifyOption,
            },
          });
        }}
      >
        <div
          className={classNames("my-0.5 h-2 w-6 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("my-0.5 h-2 w-8 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("my-0.5 h-2 w-4 rounded", barBgColorClassName)}
        />
      </div>
    );
  }
  return null;
};

const SpacedAlignmentQuadrant: React.FC<{ idx: number }> = ({ idx }) => {
  const applyComponentAction = useApplyComponentAction();
  const { orientation } = useFlexDirection();
  const justifyContent = useJustifyContent();
  const alignItems = useAlignItems();

  const isSelected =
    justifyContent === "space-between" &&
    alignItems === alignmentValuesInOrder[idx];
  const barBgColorClassName = isSelected
    ? "bg-blue-600"
    : "bg-subtle group-hover:bg-hover";

  if (orientation === "row") {
    return (
      <div
        key={idx}
        className={`group col-span-3 col-start-1 flex flex-row justify-between ${quadrantAlignmentClassNamesInOrder[idx]} cursor-pointer rounded`}
        onClick={() => {
          applyComponentAction({
            type: "setStyles",
            value: {
              display: "flex",
              alignItems: alignmentValuesInOrder[idx],
              justifyContent: "space-between",
            },
          });
        }}
      >
        <div
          className={classNames("mx-0.5 h-6 w-2 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("mx-0.5 h-8 w-2 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("mx-0.5 h-4 w-2 rounded", barBgColorClassName)}
        />
      </div>
    );
  } else if (orientation === "column") {
    return (
      <div
        key={idx}
        className={`group flex h-28 flex-col justify-between ${quadrantAlignmentClassNamesInOrder[idx]} cursor-pointer rounded`}
        onClick={() => {
          applyComponentAction({
            type: "setStyles",
            value: {
              display: "flex",
              alignItems: alignmentValuesInOrder[idx],
              justifyContent: "space-between",
            },
          });
        }}
      >
        <div
          className={classNames("my-0.5 h-2 w-6 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("my-0.5 h-2 w-8 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("my-0.5 h-2 w-4 rounded", barBgColorClassName)}
        />
      </div>
    );
  }
  return null;
};

const FlexDirectionToggles: React.FC = () => {
  const { value, isReversed, onChange, onReverseChange } = useFlexDirection();

  return (
    <>
      <ToggleGroup
        allowsDeselect={false}
        type="single"
        style={{
          width: "100%",
        }}
        value={value}
        options={[
          {
            value: "row",
            label: <HiArrowRight size="16px" />,
            tooltipContent: "Horizontal Direction",
          },
          {
            value: "column",
            label: <HiArrowDown size="16px" />,
            tooltipContent: "Vertical Direction",
          },
        ]}
        onChange={onChange}
      />
      <ToggleGroup
        allowsDeselect={false}
        type="single"
        style={{
          width: "100%",
        }}
        value={isReversed ? "reverse" : "forward"}
        options={[
          {
            value: "forward",
            label: <span className="text-xs">Fwd</span>,
            tooltipContent: "Forward Order",
          },
          {
            value: "reverse",
            label: <span className="text-xs">Rev</span>,
            tooltipContent: "Reverse Order",
          },
        ]}
        onChange={onReverseChange}
      />
    </>
  );
};

const overflowOptions = [
  { label: "Default", value: "unset", Icon: CgOverflow },
  { label: "Show", value: "visible", Icon: BsFillEyeFill },
  { label: "Wrap", value: "_wrapLines", Icon: BsTextWrap },
  { label: "Scroll", value: "scroll", Icon: BiMoveVertical },
  { label: "Hidden", value: "hidden", Icon: BsScissors },
] as const;

type OverflowOptionValue = (typeof overflowOptions)[number]["value"];
type OverflowOptionLabel = (typeof overflowOptions)[number]["label"];

const OverflowSelectionIndicator: React.FC<{
  value: OverflowOptionValue;
  label: OverflowOptionLabel;
  Icon: IconType;
  showDefaultTooltipText?: boolean;
}> = ({ value, label, Icon, showDefaultTooltipText }) => {
  return (
    <div className="flex flex-row items-center gap-2" key={value}>
      <Tooltip
        triggerAsChild
        content={
          showDefaultTooltipText ? "Manage Overflow" : `Overflow: ${label}`
        }
      >
        <div tabIndex={0}>
          <Badge
            isFilled
            backgroundColor="bg-blue-600"
            foregroundColor="white"
            className="h-4 w-4"
          >
            <Icon className="text-xs text-white" />
          </Badge>
        </div>
      </Tooltip>

      <span>{label}</span>
    </div>
  );
};

const OverflowSelect: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();

  const overflow = useEditorSelector(selectOverflow);
  const flexWrap = useEditorSelector(selectFlexWrap);

  const currentValue = flexWrap === "wrap" ? "_wrapLines" : overflow;

  return (
    <Selectable
      className="w-full"
      value={currentValue ?? "unset"}
      valueIndicator={({ value }) => {
        return (
          <OverflowSelectionIndicator
            value={value as OverflowOptionValue}
            label={overflowOptions.find((o) => o.value === value)!.label}
            Icon={overflowOptions.find((o) => o.value === value)!.Icon}
            showDefaultTooltipText={true}
          />
        );
      }}
      options={overflowOptions.map((option) => {
        const { value, label, Icon } = option;
        return {
          value,
          label: (
            <OverflowSelectionIndicator
              value={value}
              label={label}
              Icon={Icon}
            />
          ),
        };
      })}
      onSelect={(value) => {
        switch (value) {
          case "unset":
            return applyComponentAction({
              type: "setStyles",
              value: {
                overflow: null,
                flexWrap: "nowrap",
                __overflow: null,
              },
            });
          case "_wrapLines":
            return applyComponentAction({
              type: "setStyles",
              value: {
                overflow: "visible",
                flexWrap: "wrap",
                __overflow: "visible",
              },
            });
          default:
            return applyComponentAction({
              type: "setStyles",
              value: {
                overflow: value,
                flexWrap: "nowrap",
                __overflow: value,
              },
            });
        }
      }}
    />
  );
};

type FlexGapInputProps = {
  onChange(value: string): void;
  previewProperty?: PreviewableProperty;
  startEnhancer?(): React.ReactNode;
  placeholder: string;
  value?: string;
};

const FlexGapInput: React.FC<FlexGapInputProps> = (props) => {
  const flexGapDefaultValue = props.value;
  const options = [
    { value: "0px", label: "Reset" },
    { value: "8px", label: "8px" },
    { value: "16px", label: "16px" },
    { value: "24px", label: "24px" },
  ];

  return (
    <div className="flex flex-row items-center gap-2">
      <LengthInputModifier
        startEnhancer={props.startEnhancer}
        value={flexGapDefaultValue}
        className="flex-grow"
        placeholder={props.placeholder}
        field="style.__flexGap"
        dragTrigger="startEnhancer"
        draggingType={DraggingTypes.Vertical}
        resetValue="0px"
        anchorValue="0px"
        draggingDirection={DraggingDirections.Positive}
        allowsNegativeValue={false}
        minDragValues={{ px: 0 }}
        minValues={{ px: 0 }}
        onChange={(v: string) => {
          props.onChange(v);
        }}
        menuOptions={options}
        metrics={CSS_LENGTH_TYPES}
        previewProperty={props.previewProperty ?? "flexGap"}
      />
    </div>
  );
};

export const getToggleGroupValue = (
  type: "width" | "height",
  flexGrow: string | number | null,
  alignSelf: string | null,
  parentFlexDirection: "row" | "column",
  width: string | null,
  height: string | null,
  isAbsolute: boolean,
) => {
  const typeIsWidth = type === "width";
  const isWidthAuto =
    !width || width === styleAttributeToEditorData.width.defaultValue;
  const isHeightAuto =
    !height || height === styleAttributeToEditorData.height.defaultValue;
  const isAlignSelfNotStretch =
    alignSelf && [...ALIGNMENT_PROPERTY_VALUES, "auto"].includes(alignSelf);
  const isFullWidth = width === "100%";
  const isFullHeight = height === "100%";
  const parentFlexDirectionIsRow = parentFlexDirection === "row";

  if (!isAbsolute) {
    if (typeIsWidth && width && width !== "auto") {
      return "fixed";
    }
    if (!typeIsWidth && height && height !== "auto") {
      return "fixed";
    }
  }
  if (isAbsolute) {
    if (
      (typeIsWidth && parentFlexDirectionIsRow && isFullWidth) ||
      (!typeIsWidth && !parentFlexDirectionIsRow && isFullHeight) ||
      (typeIsWidth && !parentFlexDirectionIsRow && isFullWidth) ||
      (!typeIsWidth && parentFlexDirectionIsRow && isFullHeight)
    ) {
      return "fill";
    }
    if (typeIsWidth && width && width !== "auto") {
      return "fixed";
    }
    if (!typeIsWidth && height && height !== "auto") {
      return "fixed";
    }
    if (
      (typeIsWidth && parentFlexDirectionIsRow && !isFullWidth) ||
      (!typeIsWidth && !parentFlexDirectionIsRow && !isFullHeight) ||
      (typeIsWidth && !parentFlexDirectionIsRow && !isFullWidth) ||
      (!typeIsWidth && parentFlexDirectionIsRow && !isFullHeight)
    ) {
      return "wrap";
    }
  }

  if (
    (typeIsWidth && parentFlexDirection === "row" && isWidthAuto) ||
    (!typeIsWidth && parentFlexDirection === "column" && isHeightAuto)
  ) {
    return flexGrow && flexGrow !== "unset" ? "fill" : "wrap";
  }

  if (
    (typeIsWidth && parentFlexDirection === "column" && isWidthAuto) ||
    (!typeIsWidth && parentFlexDirection === "row" && isHeightAuto)
  ) {
    if (isAlignSelfNotStretch) {
      return "wrap";
    }
    return alignSelf ? "fill" : "wrap";
  }
  return null;
};

const widthAndHeightOptions = [
  { value: "auto", label: "auto" },
  { value: "100%", label: "100%" },
];

const WidthControl: React.FC<{
  disabled?: boolean;
  shouldAutofocus?: boolean;
}> = ({ disabled = false, shouldAutofocus = false }) => {
  const applyComponentAction = useApplyComponentAction();
  const alignSelf = useEditorSelector(selectAlignSelf);
  const parentFlexDirection = useEditorSelector(selectParentFlexDirection);
  const widthDefaultValue = styleAttributeToEditorData.width.defaultValue;

  return (
    <LengthInputModifier
      resetValue={widthDefaultValue}
      anchorValue="200px"
      minDragValues={{ px: 0 }}
      minValues={{ px: 0 }}
      placeholder={widthDefaultValue}
      key="style.width"
      field="style.width"
      allowsNegativeValue={false}
      draggingType={DraggingTypes.Vertical}
      maxDragValues={{ "%": 100 }}
      startEnhancer={() => (
        <Tooltip inheritCursor content="Width of Component" triggerAsChild>
          <span tabIndex={0}>
            <WidthIcon />
          </span>
        </Tooltip>
      )}
      isDisabled={disabled}
      onChange={(v: string) => {
        const values: Record<string, string> = { width: v, __width: v };

        // Reset align-self if necessary
        if (parentFlexDirection === "column" && alignSelf === "stretch") {
          values.alignSelf = "auto";
        }

        // Reset flex-grow if necessary
        if (parentFlexDirection === "row") {
          values.flexGrow = "unset";
        }

        applyComponentAction({
          type: "setStyles",
          value: values,
        });
      }}
      previewProperty="width"
      menuOptions={widthAndHeightOptions}
      metrics={CSS_LENGTH_TYPES_COMPUTED_WITH_PERCENTAGE}
      autofocus={shouldAutofocus}
    />
  );
};

const HeightControl: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const applyComponentAction = useApplyComponentAction();
  const alignSelf = useEditorSelector(selectAlignSelf);
  const parentFlexDirection = useEditorSelector(selectParentFlexDirection);

  return (
    <LengthInputModifier
      resetValue="auto"
      anchorValue="200px"
      minDragValues={{ px: 0 }}
      minValues={{ px: 0 }}
      placeholder="auto"
      key="style.height"
      draggingType={DraggingTypes.Vertical}
      metrics={CSS_LENGTH_TYPES_COMPUTED_WITH_PERCENTAGE}
      field="style.height"
      maxDragValues={{ "%": 100 }}
      allowsNegativeValue={false}
      startEnhancer={() => (
        <Tooltip inheritCursor content="Height of Component" triggerAsChild>
          <span tabIndex={0}>
            <HeightIcon />
          </span>
        </Tooltip>
      )}
      isDisabled={disabled}
      onChange={(v: any) => {
        const values: Record<string, any> = {
          height: v,
          __height: v,
        };

        // Reset align-self if necessary
        if (parentFlexDirection === "row" && alignSelf === "stretch") {
          values.alignSelf = "auto";
        }

        if (parentFlexDirection === "column") {
          values.flexGrow = "unset";
        }

        applyComponentAction({
          type: "setStyles",
          value: values,
        });
      }}
      previewProperty="height"
      menuOptions={widthAndHeightOptions}
    />
  );
};

const WidthAndHeightControls: React.FC<{ shouldWidthAutofocus?: boolean }> = ({
  shouldWidthAutofocus,
}) => {
  return (
    <div className="flex">
      <div className="ml-1 w-1/2 flex-col">
        <WidthControl shouldAutofocus={shouldWidthAutofocus} />
      </div>
      <div className="ml-1 w-1/2 flex-col">
        <HeightControl />
      </div>
    </div>
  );
};

const WidthAndHeightEdit: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const draftComponentIsRoot = useEditorSelector(selectedDraftComponentIsRoot);
  const numberOfMinAndMaxDimensionsSet = useEditorSelector(
    selectNumberOfMinAndMaxDimensionsSet,
  );

  const [isExpandedHeightWidth, setIsExpandedHeightWidth] =
    React.useState(false);

  /* Note (Fran, 2022-08-23): If the component doesn't has parent (aka is the
      root component) we don't allow min and max width/height */
  if (draftComponentIsRoot) {
    return null;
  }

  const minWidthDefaultValue = styleAttributeToEditorData.minWidth.defaultValue;
  const maxWidthDefaultValue = styleAttributeToEditorData.maxWidth.defaultValue;
  const minHeightDefaultValue =
    styleAttributeToEditorData.minHeight.defaultValue;
  const maxHeightDefaultValue =
    styleAttributeToEditorData.maxHeight.defaultValue;

  const minMenuOptions = [
    { value: "auto", label: "Reset" },
    { value: "100%", label: "100%" },
  ];
  const maxMenuOptions = [
    { value: "none", label: "Reset" },
    { value: "100%", label: "100%" },
  ];
  return (
    <InlinePopover
      shouldPreventDefaultOnInteractOutside={false}
      style={{ width: "100%" }}
      isOpen={isExpandedHeightWidth}
      onOpenChange={setIsExpandedHeightWidth}
      content={
        <div className="mt-4">
          <WidthAndHeightControls shouldWidthAutofocus />
          <div className="mt-2 flex flex-row items-center justify-center gap-1 pl-1">
            <div className="flex flex-col gap-2">
              <LabeledControl label="Min Width" size="sm">
                <LengthInputModifier
                  style={{ width: "100%" }}
                  startEnhancer={() => {
                    return <MinSvg />;
                  }}
                  placeholder={minWidthDefaultValue}
                  resetValue={minWidthDefaultValue}
                  anchorValue="200px"
                  minDragValues={{ px: 0 }}
                  minValues={{ px: 0 }}
                  key="style.minWidth"
                  field="style.minWidth"
                  maxDragValues={{ "%": 100 }}
                  draggingType={DraggingTypes.Vertical}
                  allowsNegativeValue={false}
                  onChange={(v: any) => {
                    const values: Record<string, any> = {
                      minWidth: v,
                    };
                    applyComponentAction({
                      type: "setStyles",
                      value: values,
                    });
                  }}
                  menuOptions={minMenuOptions}
                  previewProperty="minWidth"
                  metrics={CSS_LENGTH_TYPES_COMPUTED_WITH_PERCENTAGE}
                />
              </LabeledControl>
              <LabeledControl label="Max Width" size="sm">
                <LengthInputModifier
                  style={{ width: "100%" }}
                  startEnhancer={() => {
                    return <MaxSvg />;
                  }}
                  placeholder={maxWidthDefaultValue}
                  resetValue={maxWidthDefaultValue}
                  anchorValue="200px"
                  minDragValues={{ px: 0 }}
                  minValues={{ px: 0 }}
                  key="style.maxWidth"
                  field="style.maxWidth"
                  maxDragValues={{ "%": 100 }}
                  allowsNegativeValue={false}
                  draggingType={DraggingTypes.Vertical}
                  onChange={(v: any) => {
                    const values: Record<string, any> = {
                      maxWidth: v,
                    };
                    applyComponentAction({
                      type: "setStyles",
                      value: values,
                    });
                  }}
                  menuOptions={maxMenuOptions}
                  previewProperty="maxWidth"
                  metrics={CSS_LENGTH_TYPES_COMPUTED_WITH_PERCENTAGE}
                />
              </LabeledControl>
            </div>
            <div className="flex flex-col gap-2">
              <LabeledControl label="Min Height" size="sm">
                <LengthInputModifier
                  style={{ width: "100%" }}
                  startEnhancer={() => {
                    return <MinSvg />;
                  }}
                  placeholder={minHeightDefaultValue}
                  resetValue={minHeightDefaultValue}
                  anchorValue="200px"
                  minDragValues={{ px: 0 }}
                  minValues={{ px: 0 }}
                  key="style.minHeight"
                  metrics={CSS_LENGTH_TYPES_COMPUTED_WITH_PERCENTAGE}
                  field="style.minHeight"
                  allowsNegativeValue={false}
                  maxDragValues={{ "%": 100 }}
                  draggingType={DraggingTypes.Vertical}
                  onChange={(v: any) => {
                    const values: Record<string, any> = {
                      minHeight: v,
                    };
                    applyComponentAction({
                      type: "setStyles",
                      value: values,
                    });
                  }}
                  menuOptions={minMenuOptions}
                  previewProperty="minHeight"
                />
              </LabeledControl>
              <LabeledControl label="Max Height" size="sm">
                <LengthInputModifier
                  style={{ width: "100%" }}
                  startEnhancer={() => {
                    return <MaxSvg />;
                  }}
                  placeholder={maxHeightDefaultValue}
                  resetValue={maxHeightDefaultValue}
                  anchorValue="200px"
                  minDragValues={{ px: 0 }}
                  minValues={{ px: 0 }}
                  key="style.maxHeight"
                  metrics={CSS_LENGTH_TYPES_COMPUTED_WITH_PERCENTAGE}
                  field="style.maxHeight"
                  maxDragValues={{ "%": 100 }}
                  allowsNegativeValue={false}
                  draggingType={DraggingTypes.Vertical}
                  onChange={(v: any) => {
                    const values: Record<string, any> = {
                      maxHeight: v,
                    };
                    applyComponentAction({
                      type: "setStyles",
                      value: values,
                    });
                  }}
                  menuOptions={maxMenuOptions}
                  previewProperty="maxHeight"
                />
              </LabeledControl>
            </div>
          </div>
        </div>
      }
      title="Dimensions"
    >
      <Tooltip
        content="Set Minimum/Maximum Dimensions"
        triggerAsChild
        className="w-full"
      >
        <div className="mt-2 w-full bg-subtle py-1 text-center text-xs font-normal text-subtle">
          Edit Min & Max
          {numberOfMinAndMaxDimensionsSet > 0 && (
            <span className="text-blue-600">{` (${numberOfMinAndMaxDimensionsSet})`}</span>
          )}
        </div>
      </Tooltip>
    </InlinePopover>
  );
};

export default DeprecatedFlexboxModifier;
