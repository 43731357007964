import type {
  ComponentErrorContextValue,
  ComponentInventoryContextValue,
  ComponentUpdateContextValue,
  CustomFontsContextValue,
  DraftElementContextValue,
  DynamicDataStore,
  DynamicDataStoreContextValue,
  ExtraContextValue,
  FeatureFlagsContextValue,
  RenderEnvironmentContextValue,
  ReploEditorActiveCanvasContextValue,
  ReploEditorCanvasContextValue,
  ReploElementContextValue,
  ReploSymbolsContextValue,
  RuntimeHooksContextValue,
  ShopifyStoreContextValue,
  SyncRuntimeStateContextValue,
} from "replo-runtime/shared/runtime-context";
import type { SharedStatePayload } from "replo-runtime/shared/types";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";

import * as React from "react";

import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useGetStoreNameAndUrl from "@editor/hooks/useGetStoreNameAndUrl";
import { useOpenModal } from "@editor/hooks/useModal";
import { useSyncRuntime } from "@editor/hooks/useSyncRuntimeValue";
import { trackError } from "@editor/infra/analytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectLocaleData,
  selectOkendoWidgetNamespace,
  selectShopifyUrlRoot,
} from "@editor/reducers/commerce-reducer";
import {
  selectDraftComponentId,
  selectDraftRepeatedIndex,
} from "@editor/reducers/core-reducer";
import { setSharedState } from "@editor/reducers/paint-reducer";
import { getElementWithRevisionState } from "@editor/reducers/utils/core-reducer-utils";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { isDevelopment } from "@editor/utils/env";
import { trpc } from "@editor/utils/trpc";

import {
  selectActiveCanvas,
  selectCanvasInteractionMode,
  setCanvasInteractionMode,
} from "@/features/canvas/canvas-reducer";
import {
  useDataTableMapping,
  useEditorMediaUploadingComponentIds,
  useEditorOverrideOpenModalId,
  useEditorOverrideText,
  useHideShopifyAnnouncementBar,
  useHideShopifyFooter,
  useHideShopifyHeader,
  useIsLabelledByOtherComponent,
  useIsPreviewMode,
  useIsRenderLiquidLoading,
  useRenderedLiquid,
  useRenderedLiquidCacheLength,
  useRequestRenderLiquid,
  useRuntimeOverrideVariantId,
  useSharedState,
  useShopifyProductMetafieldValues,
  useShopifyProducts,
  useShopifyVariantMetafieldValues,
  useSwatches,
  useTemplateEditorProduct,
} from "@/features/canvas/stores/runtime";
import { skipToken } from "@tanstack/react-query";
import { shallowEqual } from "react-redux";
import { RENDER_ENV_EDITOR } from "replo-runtime/shared/render-environment";
import { componentInventory } from "replo-runtime/store/componentInventory";
import { fakeProducts } from "replo-runtime/store/utils/fakeProducts";
import { canUseDOM } from "replo-utils/dom/misc";

// NOTE (Chance 2024-03-13): The following hooks are used to construct the
// context values that will be passed into all of the providers needed for
// runtime components in the editor. Each environment will have its own set of
// functions to serve a similar function, since the data sources for each
// environment are different.
//
// Also note that these all liberally use `useMemo` to return stable objects. We
// should be able to remove and simplify some of these, but I opted to keep them
// consistent for now since the code is very much in-flux and returned objects
// may change throughout the larger refactor process.

export function useInitComponentErrorContext() {
  const openModal = useOpenModal();
  return React.useMemo<ComponentErrorContextValue>(() => {
    return {
      onComponentError: (componentId, error, reactErrorInfo) => {
        if (isDevelopment) {
          console.error("[REPLO] Component rendering error.", {
            componentId,
            error,
            reactErrorInfo,
          });
        }
        trackError(error);
        openModal({
          type: "fullPageErrorModal",
          props: {
            details: {
              header: "Unable to render content",
              subheader: "Something went wrong displaying this content.",
              message:
                "This usually indicates an internal error. If this error persists, please contact support@replo.app.",
            },
          },
        });
      },
    };
  }, [openModal]);
}

export function useInitComponentInventoryContext() {
  return React.useMemo<ComponentInventoryContextValue>(() => {
    return { componentInventory };
  }, []);
}

export function useInitComponentUpdateContext() {
  const applyComponentAction = useApplyComponentAction();
  const dispatch = useEditorDispatch();

  type OnSubmit =
    ComponentUpdateContextValue["onSubmitContentEditableTextUpdate"];

  const setContentEditing = React.useCallback(
    (contentEditing: boolean) => {
      dispatch(
        setCanvasInteractionMode(contentEditing ? "content-editing" : "edit"),
      );
    },
    [dispatch],
  );

  const onSubmitContentEditableTextUpdate = React.useCallback<OnSubmit>(
    (componentId, htmlContent) => {
      setContentEditing(false);
      applyComponentAction({
        type: "setProps",
        componentId,
        value: {
          text: htmlContent,
        },
      });
    },
    [applyComponentAction, setContentEditing],
  );

  return React.useMemo<ComponentUpdateContextValue>(() => {
    return {
      onSubmitContentEditableTextUpdate,
    };
  }, [onSubmitContentEditableTextUpdate]);
}

export function useInitCustomFontsContext() {
  const projectId = useCurrentProjectId();
  const { data: uploadedFonts } = trpc.store.getFonts.useQuery(
    projectId ? { storeId: projectId } : skipToken,
  );
  return React.useMemo<CustomFontsContextValue>(() => {
    return { uploadedFonts: uploadedFonts ?? [] };
  }, [uploadedFonts]);
}

export function useInitDraftElementContext() {
  const ctx: DraftElementContextValue = useEditorSelector((state) => {
    const elements = getElementWithRevisionState(state.core.elements);
    const draftElementId = elements.draftElementId ?? null;
    const draftComponentId = elements.draftComponentId ?? null;
    const { draftRepeatedIndex, draftSymbolInstanceId, draftSymbolId } =
      elements;
    return {
      draftElementId,
      draftRepeatedIndex,
      draftSymbolInstanceId,
      draftSymbolId,
      draftComponentId,
    };
  }, shallowEqual);
  return ctx;
}

export function useInitDynamicDataStoreContext() {
  const [store, setStore] = React.useState<DynamicDataStore>({});
  return React.useMemo<DynamicDataStoreContextValue>(() => {
    return { store, setStore };
  }, [store]);
}

export function useInitShopifyStoreContext() {
  const {
    storeId,
    activeCurrency,
    activeLanguage,
    activeShopifyUrlRoot,
    moneyFormat,
  } = useEditorSelector((state) => {
    const { activeCurrency, activeLanguage, moneyFormat } =
      selectLocaleData(state);
    const activeShopifyUrlRoot = selectShopifyUrlRoot(state);
    return {
      storeId: state.core.project?.id,
      activeCurrency,
      activeLanguage,
      activeShopifyUrlRoot,
      moneyFormat,
    };
  }, shallowEqual);
  const { storeUrl } = useGetStoreNameAndUrl();

  return React.useMemo<ShopifyStoreContextValue>(() => {
    return {
      storeId,
      storeUrl,
      templateProduct: null,
      fakeProducts,
      activeCurrency,
      activeLanguage,
      moneyFormat,
      activeShopifyUrlRoot,
    };
  }, [
    storeId,
    storeUrl,
    activeCurrency,
    activeLanguage,
    moneyFormat,
    activeShopifyUrlRoot,
  ]);
}

export function useInitExtraContext() {
  const okendoNamespace = useEditorSelector(selectOkendoWidgetNamespace);
  const ctx: ExtraContextValue = React.useMemo<ExtraContextValue>(() => {
    return {
      overrideProductLiquidOff: false,
      runtimeVersion: null,
      okendoNamespace,
    };
  }, [okendoNamespace]);
  return ctx;
}

export function useInitFeatureFlagsContext() {
  const carouselV4 = isFeatureEnabled("carousel-v4");
  const carouselDebug = isFeatureEnabled("carousel-debug");
  return React.useMemo<FeatureFlagsContextValue>(() => {
    return {
      featureFlags: {
        carouselV4,
        carouselDebug,
      },
    };
  }, [carouselV4, carouselDebug]);
}

export type EditorRenderEnvironmentContextValue =
  RenderEnvironmentContextValue & {
    isEditorApp: true;
  };

export function useInitRenderEnvironmentContext() {
  return React.useMemo<EditorRenderEnvironmentContextValue>(() => {
    return {
      isEditorApp: true,
      isElementPreview: false,
      isPublishedPage: false,
      isPublishing: false,
      previewEnvironment: "editor",
      renderEnvironment: RENDER_ENV_EDITOR,
    };
  }, []);
}

export function useInitReploEditorActiveCanvasContext() {
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const ctx: ReploEditorActiveCanvasContextValue = React.useMemo(() => {
    return { activeCanvas };
  }, [activeCanvas]);
  return ctx;
}

export function useInitReploEditorCanvasContext() {
  // TODO (Chance 2024-03-14): Stop doing this and get values from the store.
  // Keeping for back-compat right now. This may cause problems when we remove
  // Repainter since this is not stateful.
  const editorCanvasScale = canUseDOM
    ? window.alchemyEditor?.canvasScale ?? 0
    : 0;
  const editorCanvasYOffset = canUseDOM
    ? window.alchemyEditor?.canvasYOffset ?? 0
    : 0;

  return React.useMemo<ReploEditorCanvasContextValue>(() => {
    return {
      editorCanvasYOffset,
      editorCanvasScale,
    };
  }, [editorCanvasYOffset, editorCanvasScale]);
}

export function useInitReploElementContext() {
  const ctx: ReploElementContextValue = useEditorSelector((state) => {
    const { mapping, draftElementId } = getElementWithRevisionState(
      state.core.elements,
    );
    const draftElement = draftElementId ? mapping[draftElementId] : null;

    return {
      elementType: draftElement?.type ?? "page",
      useSectionSettings: undefined,
    };
  }, shallowEqual);
  return ctx;
}

export function useInitReploSymbolsContext() {
  const symbols = useEditorSelector(
    (state) => Object.values(state.core.symbols.mapping),
    shallowEqual,
  );
  return React.useMemo<ReploSymbolsContextValue>(() => {
    return { symbols };
  }, [symbols]);
}

export function useInitSyncRuntimeStateContext() {
  const { setSyncRuntimeState } = useSyncRuntime();
  const ctx = React.useMemo<SyncRuntimeStateContextValue>(() => {
    return {
      setSyncRuntimeState,
    };
  }, [setSyncRuntimeState]);
  return ctx;
}

export function useInitRuntimeHooksContext() {
  return React.useMemo<RuntimeHooksContextValue>(() => {
    return {
      useEditorOverrideActiveVariantId(componentId: string) {
        return useRuntimeOverrideVariantId(componentId) ?? null;
      },
      useEditorOverrideTextValue(componentId: string) {
        return useEditorOverrideText(componentId) ?? null;
      },
      useIsEditorEditModeRenderEnvironment() {
        const isPreviewMode = useIsPreviewMode();
        return !isPreviewMode;
      },
      useIsLabelledByOtherComponent(componentId: string) {
        return useIsLabelledByOtherComponent(componentId);
      },
      useSharedState(key: string) {
        return useSharedState(key);
      },
      useSetSharedState() {
        const dispatch = useEditorDispatch();
        return React.useCallback(
          (setSharedStatePayload: SharedStatePayload) =>
            dispatch(setSharedState(setSharedStatePayload)),
          [dispatch],
        );
      },
      useSwatches() {
        return useSwatches();
      },
      useDataTableMapping() {
        return useDataTableMapping();
      },
      useTemplateEditorProduct() {
        return useTemplateEditorProduct();
      },
      useRenderedLiquid(liquidSource: string | null) {
        return useRenderedLiquid(liquidSource);
      },
      useIsRenderLiquidLoading(liquidSource: string | null) {
        return useIsRenderLiquidLoading(liquidSource);
      },
      useRequestRenderLiquid() {
        return useRequestRenderLiquid();
      },
      useRenderedLiquidCacheLength() {
        return useRenderedLiquidCacheLength();
      },
      useShopifyProducts() {
        return useShopifyProducts();
      },
      useShopifyProductMetafieldValues() {
        return useShopifyProductMetafieldValues();
      },
      useShopifyVariantMetafieldValues() {
        return useShopifyVariantMetafieldValues();
      },
      useHideShopifyHeader() {
        return useHideShopifyHeader();
      },
      useHideShopifyFooter() {
        return useHideShopifyFooter();
      },
      useHideShopifyAnnouncementBar() {
        return useHideShopifyAnnouncementBar();
      },
      useIsContentEditing({
        componentId,
        repeatedIndex,
        canvas,
      }: {
        componentId: string;
        repeatedIndex: string;
        canvas: EditorCanvas;
      }) {
        const draftComponentId = useEditorSelector(selectDraftComponentId);
        const draftRepeatedIndex = useEditorSelector(selectDraftRepeatedIndex);
        const activeCanvas = useEditorSelector(selectActiveCanvas);
        const isContentEditing =
          useEditorSelector(selectCanvasInteractionMode) === "content-editing";

        if (
          draftComponentId !== componentId ||
          (draftRepeatedIndex && draftRepeatedIndex !== repeatedIndex) ||
          canvas !== activeCanvas
        ) {
          return false;
        }

        return isContentEditing;
      },
      useEditorMediaUploadingComponentIds() {
        return useEditorMediaUploadingComponentIds();
      },
      useEditorOverrideOpenModalId() {
        return useEditorOverrideOpenModalId();
      },
    };
  }, []);
}
