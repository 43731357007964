import type { Component } from "replo-runtime/shared/Component";
import type { ReploElement } from "schemas/generated/element";
import type { ReploSymbol } from "schemas/generated/symbol";

import { applySymbolOverrides } from "replo-runtime/shared/symbol";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";

const _expandAllSymbols = (
  component: Component,
  symbolsMapping: Record<string, ReploSymbol>,
  // TODO (Noah, 2022-03-11): I don't think this param needs to exist,
  // there should never be a difference in element expansion based on
  // which state is selected for a given component
  componentIdToDraftVariantId: Record<string, string | undefined>,
  currentSymbolInstanceId: string | null,
  overrides: Record<string, Object>,
): Component | null => {
  if (!component) {
    return null;
  }

  if (component.type === "symbolRef") {
    const { component: newComponent, variantAndSymbolOverrides: newOverrides } =
      applySymbolOverrides(
        component,
        symbolsMapping[component.symbolId!] ?? null,
        getFromRecordOrNull(componentIdToDraftVariantId, component.id) ?? null,
        null,
      );

    // Note (Noah, 2022-09-05, REPL-3870): If we get back a symbolRef, it means
    // that the symbol could not be resolved. This can happen if you paste component
    // json into the editor that references a symbol that doesn't exist in the current
    // store. Just ignoring this case, since symbols will be eventually removed from
    // the runtime anyway.
    if (!newComponent || newComponent.type === "symbolRef") {
      return null;
    }

    return {
      ..._expandAllSymbols(
        newComponent,
        symbolsMapping,
        componentIdToDraftVariantId,
        component.id,
        newOverrides,
      ),
      type: "symbolRef",
    } as Component;
  }
  const expandedChildren = component.children?.map((child) => {
    return {
      ..._expandAllSymbols(
        child,
        symbolsMapping,
        componentIdToDraftVariantId,
        currentSymbolInstanceId,
        overrides,
      ),
      id: currentSymbolInstanceId
        ? `symbolInstanceId=${currentSymbolInstanceId}&symbolChildId=${child.id}`
        : child?.id,
    };
  });
  return {
    ...component,
    children: expandedChildren,
    ...overrides[component.id],
  } as Component;
};

export const expandAllSymbols = (
  component: Component,
  symbolsMapping: Record<string, ReploSymbol>,
  componentIdToDraftVariantId: Record<string, string | undefined>,
): Component | null => {
  return _expandAllSymbols(
    component,
    symbolsMapping,
    componentIdToDraftVariantId,
    null,
    {},
  );
};

export const expandAllSymbolsOfElement = (
  element: ReploElement,
  symbolsMapping: Record<string, ReploSymbol>,
  componentIdToDraftVariantId: Record<string, string | undefined>,
): ReploElement => {
  const expanded = {
    ...element,
    component: _expandAllSymbols(
      element?.component,
      symbolsMapping,
      componentIdToDraftVariantId,
      null,
      {},
    ),
  };
  return expanded as ReploElement;
};
