import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { getDefaultStyleRulesIfEmpty } from "../../../shared/utils/breakpoints";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _urlHashmark: {
      type: "hashmark",
      name: "URL Hash Mark",
      defaultValue: null,
      description:
        "The URL will have this hash mark when this container is scrolled into view.",
    },
    _autoEnableHashmark: {
      type: "boolean",
      name: "Auto-enable URL Hash Mark",
      defaultValue: false,
      description:
        "If set, the page will automatically adopt this URL hashmark when the container scrolls into view.",
    },
    _urlHashmarkOffset: {
      type: "pixels",
      name: "URL Hash Mark Offset",
      defaultValue: "0px",
      description:
        "Offset (from top of page) to be added when scrolling to this hash mark.",
    },
    _accessibilityRole: {
      type: "string",
      name: "Accessibility Role",
      description:
        "Allows this container to be read as a different type of component by screen readers or other support interaction tools.",
      defaultValue: null,
    },
    _accessibilityHidden: {
      type: "boolean",
      name: "Accessibility Hidden",
      description:
        "If set, removes this component from the accessibility tree, hiding it from screen readers.",
      defaultValue: null,
    },
    _accessibilityChecked: {
      type: "boolean",
      name: "Accessibility Checked",
      description:
        "If set, the component will appear 'Checked' to screen readers. Useful for switches, checkboxes, and radio buttons.",
      defaultValue: null,
      shouldDisplay: ({ component }) => {
        const ariaRole = component.props._accessibilityRole;
        return (
          ariaRole === "switch" || component.props._accessibilityChecked != null
        );
      },
    },
    _accessibilityLabelledBy: {
      type: "selectable",
      name: "Accessibility Labelled By",
      description:
        "If set component from here, the text component will be used in accessibility user interface controls",
      defaultValue: null,
    },
  };
}

export const styleElements = {
  root: {
    defaultStyles: {
      display: "flex",
    },
    overrideStyles({ component, styleProps }) {
      return getDefaultStyleRulesIfEmpty(component, styleProps);
    },
  },
} satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    allowsLayoutModification: true,
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    canContainChildren: true,
    styleElements,
    showAccessibilityMenu: true,
  },
} satisfies ComponentConfig;

export default config;
