import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { GetAttributeFunction } from "@editor/types/get-attribute-function";
import type { DraggableEvent } from "react-draggable";
import type {
  ComponentMapping,
  Component as ReploComponent,
} from "replo-runtime/shared/Component";
import type { RuntimeStyleAttribute } from "replo-runtime/shared/styleAttribute";
import type {
  Edges,
  NumericBounds,
  Position,
} from "replo-runtime/shared/types";
import type { EditorProp } from "replo-runtime/shared/utils/renderComponents";
import type { BoxSide } from "replo-utils/lib/types";
import type { ReploComponentType } from "schemas/component";

import * as React from "react";

import IconButton from "@common/designSystem/IconButton";
import { getComponentName } from "@components/editor/component";
import { maxZIndex } from "@components/editor/constants";
import {
  canRenderEditorControl,
  getEditorPropEditorData,
} from "@components/editor/editorProps";
import { elementTypeToEditorData } from "@components/editor/element";
import Button from "@editor/components/common/designSystem/Button";
import { useEditorPerformanceContext } from "@editor/contexts/editor-performance.context";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useComponentById } from "@editor/hooks/useComponentById";
import useCurrentDragType from "@editor/hooks/useCurrentDragType";
import useDropTarget from "@editor/hooks/useDropTarget";
import { useEnableNonDynamicTextEditing } from "@editor/hooks/useEnableNonDynamicTextEditing";
import { useGetAttribute } from "@editor/hooks/useGetAttribute";
import { useModal } from "@editor/hooks/useModal";
import { usePaintSharedStateValue } from "@editor/hooks/usePaintSharedState";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { useTargetFrameDocument } from "@editor/hooks/useTargetFrame";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  selectCandidateCanvas,
  selectCandidateComponent,
  selectComponentIdToDrag,
  selectComponentNodeToDrag,
} from "@editor/reducers/candidate-reducer";
import {
  selectComponentDataMapping,
  selectComponentMapping,
  selectDraftComponent,
  selectDraftComponentAccordionAncestor,
  selectDraftComponentAncestorWithEditorProps,
  selectDraftComponentAncestorWithEditorPropsNode,
  selectDraftComponentHasParent,
  selectDraftComponentId,
  selectDraftComponentNode,
  selectDraftComponentNodes,
  selectDraftComponentOrDescendantIsOneOfTypes,
  selectDraftComponentType,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftElementId,
  selectDraftElementType,
  selectDraftParentComponentNode,
  selectDraftRepeatedIndex,
  selectEditorMode,
  selectHasDraftComponentAncestorOrSelfWithSpecificType,
  selectImageSource,
  selectPosition,
  selectSymbolsMapping,
} from "@editor/reducers/core-reducer";
import { selectIsDraggingSpacingIndicators } from "@editor/reducers/drag-and-drop-reducer";
import {
  selectLeftBarActiveTab,
  setLeftBarActiveTab,
  setRightBarActiveTab,
} from "@editor/reducers/ui-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import {
  findNextSibling,
  findPreviousSibling,
  getEdgeAttribute,
  getEditorComponentNode,
  getParentComponentFromMapping,
  supportsContentEditing,
} from "@editor/utils/component";
import {
  controlOnChange,
  getSharedStateKey,
} from "@editor/utils/component-controls";
import { getElementMargin, sanitizeTreeContent } from "@editor/utils/dom";
import { processDropTargetDrag } from "@editor/utils/dropTarget";
import useDragAndDrop from "@providers/DragAndDropProvider";

import classNames from "classnames";
import { DropTarget } from "react-dnd";
import Draggable from "react-draggable";
import { BsMagic } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";
import { shallowEqual } from "react-redux";
import {
  forEachComponentAndDescendants,
  getCustomPropDefinitions,
} from "replo-runtime/shared/utils/component";
import { parseUnit } from "replo-runtime/shared/utils/units";
import {
  componentTypeToRenderData,
  getRenderData,
} from "replo-runtime/store/components";
import { getAccordionNewItems } from "replo-runtime/store/utils/accordion";
import { isEmpty } from "replo-utils/lib/misc";
import { useRequiredContext } from "replo-utils/react/context";
import { useForceUpdate } from "replo-utils/react/use-force-update";
import { useMutationObserver } from "replo-utils/react/use-mutation-observer";
import { twMerge } from "tailwind-merge";

import { CANVAS_Z_INDICES, DRAGGABLE_BOUNDS } from "./canvas-constants";
import { CanvasAreaContext, CanvasContext } from "./canvas-context";
import {
  selectActiveCanvas,
  selectCanvasDeltaXY,
  selectCanvasInteractionMode,
  selectCanvasScale,
  setCanvasInteractionMode,
} from "./canvas-reducer";
import { scalingFactor } from "./canvas-utils";
import { DimensionResizer } from "./DimensionResizer";
import { SpacingIndicators } from "./SpacingIndicators";
import {
  useCandidateNode,
  useSetCandidateNodeFromPoint,
} from "./useCandidateNode";
import { useDropHandler } from "./useDropHandler";
import useSetActiveCanvas from "./useSetActiveCanvas";

const CONTROL_BOX_HEIGHT = 28;
const FIXED_TO_HEADER_PADDING = 8;

const isEqualBoundingBox = (
  box1: { top: number; left: number; width: number; height: number },
  box2: { top: number; left: number; width: number; height: number },
) => {
  return (
    box1.top === box2.top &&
    box1.left === box2.left &&
    box1.width === box2.width &&
    box1.height === box2.height
  );
};

function useBoundingBox(node: Element | null | undefined) {
  const {
    top = 0,
    left = 0,
    width = 0,
    height = 0,
  } = node?.getBoundingClientRect() || {};

  const [boundingBox, setBoundingBox] = React.useState({
    top,
    left,
    width,
    height,
  });

  const updateBoundingBox = React.useCallback(() => {
    setBoundingBox((previousBoundingBox) => {
      if (!node) {
        return previousBoundingBox;
      }
      const { top, left, width, height } = node.getBoundingClientRect();
      const newBoundingBox = {
        top,
        left,
        width,
        height,
      };

      return isEqualBoundingBox(newBoundingBox, previousBoundingBox)
        ? previousBoundingBox
        : newBoundingBox;
    });
  }, [node]);

  useMutationObserver(node, updateBoundingBox, {
    subtree: true,
    attributes: true,
    childList: true,
  });

  React.useEffect(() => {
    updateBoundingBox();
  });

  if (!node) {
    return null;
  }

  return boundingBox;
}

export function BoundingBoxes() {
  const { canvas } = useRequiredContext(CanvasContext);
  const { currentDragType } = useRequiredContext(CanvasAreaContext);
  const targetFrameOverlayRef = React.useRef<HTMLDivElement>(null);
  const interactionMode = useEditorSelector(selectCanvasInteractionMode);
  const isDragging = interactionMode === "dragging-components";
  const dispatch = useEditorDispatch();
  const activeCanvas = useEditorSelector(selectActiveCanvas);

  const leftBarActiveTab = useEditorSelector(selectLeftBarActiveTab);
  const component = useEditorSelector(selectDraftComponent);
  const isDraggingSpacingIndicators = useEditorSelector(
    selectIsDraggingSpacingIndicators,
  );
  const draftComponentPosition = useEditorSelector(selectPosition);
  const setDraftElement = useSetDraftElement();

  const componentIdToDrag = useEditorSelector(selectComponentIdToDrag);
  const componentNodeToDrag = useEditorSelector(selectComponentNodeToDrag);

  const candidateCanvas = useEditorSelector(selectCandidateCanvas);

  const { setWasCandidateBoxJustClicked } = useEditorPerformanceContext();

  const { candidateNode, setCandidateNodeFromPoint, shouldShowCandidateBox } =
    useCandidateNode();

  useDropHandler({
    onDrop: () => {
      dispatch(setCanvasInteractionMode("edit"));
    },
    componentIdToDrag,
    componentNodeToDrag,
  });

  const shouldHideCandidateOutline =
    draftComponentPosition &&
    currentDragType === "draftComponent" &&
    ["fixed", "absolute"].includes(draftComponentPosition);

  const isActiveCanvas = canvas === activeCanvas;
  const editorMode = useEditorSelector(selectEditorMode);
  const setActiveCanvas = useSetActiveCanvas();

  return (
    <div
      className="relative h-full w-full"
      data-testid={`bounding-boxes-${canvas}`}
    >
      <TargetFrameLayer
        // @ts-expect-error
        setCandidateNode={(x: number, y: number) => {
          setCandidateNodeFromPoint(x, y);
        }}
      />

      <div ref={targetFrameOverlayRef} className="absolute inset-0">
        {editorMode === EditorMode.edit && (
          <>
            {isActiveCanvas && <ParentBox />}
            <DraftBoxes
              offsetParentRef={targetFrameOverlayRef}
              onDragStart={() => {
                if (!isDragging) {
                  dispatch(setCanvasInteractionMode("dragging-components"));
                }
              }}
            />
            <DropTargetBox />
            {shouldShowCandidateBox &&
              candidateCanvas === canvas &&
              componentNodeToDrag && (
                <DraggableComponentBox
                  type="candidate"
                  draggingComponentNode={componentNodeToDrag}
                  draggingComponentId={componentIdToDrag}
                  offsetParentRef={targetFrameOverlayRef}
                  onClick={() => {
                    setWasCandidateBoxJustClicked();
                    if (leftBarActiveTab !== null) {
                      dispatch(setLeftBarActiveTab("tree"));
                    }
                    if (!isActiveCanvas) {
                      setActiveCanvas({ canvas, source: "component" });
                    }
                    setDraftElement({
                      componentId: componentIdToDrag ?? undefined,
                      repeatedIndex:
                        componentNodeToDrag.dataset.reploRepeatedIndex ??
                        undefined,
                    });
                  }}
                  onDrag={() => {
                    dispatch(setCanvasInteractionMode("dragging-components"));
                  }}
                />
              )}
            {isActiveCanvas &&
              candidateNode &&
              Boolean(currentDragType) &&
              !shouldHideCandidateOutline && (
                <BoundingBox
                  showPaddingMargin
                  showOutline
                  node={candidateNode}
                  className="border-pink-300"
                />
              )}
            {isActiveCanvas && <ChildBoxes />}
          </>
        )}
        {isActiveCanvas && component && !isDraggingSpacingIndicators && (
          <DraftComponentControls />
        )}
        <GenerativeAIBoundingBox />
      </div>
    </div>
  );
}

interface BoundingBoxProps {
  node: Element;
  showPaddingMargin: boolean;
  showOutline: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const BoundingBox: React.FC<BoundingBoxProps> = ({
  node,
  showPaddingMargin,
  showOutline,
  className,
  style,
}) => {
  const boundingBoxStyles = useBoundingBox(node);

  if (
    !boundingBoxStyles ||
    (boundingBoxStyles.width === 0 && boundingBoxStyles.height === 0)
  ) {
    return null;
  }

  return (
    <>
      {showPaddingMargin && <SpacingIndicators node={node} />}
      <div
        className={twMerge(
          "absolute border",
          showOutline && "border-[2px] border-blue-400",
          className,
        )}
        style={{ ...boundingBoxStyles, pointerEvents: "none", ...style }}
      />
    </>
  );
};

const TextBoundingBox: React.FC<{ componentId: string }> = ({
  componentId,
}) => {
  const { canvas } = useRequiredContext(CanvasContext);
  const document = useTargetFrameDocument(canvas);

  const node = getEditorComponentNode(
    document ?? window.document,
    null,
    componentId,
  );

  // Note (Evan, 2024-10-04): This is a bit of a hack -- when generating AI text, we force
  // all collapsibles open. We need to rerender when the collapsible state changes
  // to recalculate the bounds – this ~should~ be handled by the mutationObserver, but it's
  // not -- this whole file is in need of some love.
  const [collapsibleOpenState] = usePaintSharedStateValue(
    `${componentId}.isOpen`,
  );

  const rerender = useForceUpdate();

  // biome-ignore lint/correctness/useExhaustiveDependencies: we want this dependency
  React.useEffect(() => {
    rerender();
  }, [collapsibleOpenState, rerender]);

  if (!document || !node) {
    return null;
  }

  return (
    <BoundingBox
      node={node}
      showOutline={false}
      showPaddingMargin={false}
      style={{ zIndex: CANVAS_Z_INDICES.draftBox }}
      className="border-dashed border-ai border-2"
    />
  );
};

export const TextBoundingBoxes: React.FC = () => {
  const draftComponent = useEditorSelector(selectDraftComponent);

  // Note (Evan, 2024-10-07): This will only run once per AI text generation call, since we
  // don't actually update the draft component in Redux until the changes are approved.
  const textComponentIds = React.useMemo(() => {
    const nodeIds: string[] = [];
    forEachComponentAndDescendants(draftComponent, (component) => {
      if (component.type === "text" && !component.props.text?.includes("{{")) {
        nodeIds.push(component.id);
      }
    });
    return nodeIds;
  }, [draftComponent]);

  return (
    <>
      {textComponentIds?.map((componentId) => (
        <TextBoundingBox key={componentId} componentId={componentId} />
      ))}
    </>
  );
};

export const GenerativeAIBoundingBox: React.FC = () => {
  const { canvas } = useRequiredContext(CanvasContext);
  const { isMenuOpen, menuState, generationMode, generationSource } =
    useAIStreaming();
  const draftComponentNode = useEditorSelector(
    (state) => selectDraftComponentNode(state, canvas),
    shallowEqual,
  );
  if (!draftComponentNode) {
    return null;
  }

  const shouldShowTextBoundingBoxes =
    (["text", "template"].includes(menuState) || generationMode === "textV2") &&
    generationSource !== "onboarding";

  return (
    <>
      {isMenuOpen && (
        <BoundingBox
          node={draftComponentNode}
          showOutline={false}
          showPaddingMargin={false}
          style={{ zIndex: CANVAS_Z_INDICES.draftBox }}
          className="border-ai border-2"
        />
      )}
      {shouldShowTextBoundingBoxes && <TextBoundingBoxes />}
    </>
  );
};

export const ParentBox: React.FC = () => {
  const { canvas } = useRequiredContext(CanvasContext);
  const parentComponentNode = useEditorSelector((state) =>
    selectDraftParentComponentNode(state, canvas),
  );

  if (!parentComponentNode) {
    return null;
  }

  return (
    <BoundingBox
      showOutline={true}
      showPaddingMargin={false}
      className="parent-box border border-dashed border-blue-400"
      node={parentComponentNode}
    />
  );
};

export const ChildBoxes: React.FC = () => {
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const componentMapping = useEditorSelector(selectComponentMapping);
  const getAttribute = useGetAttribute();

  const childBoxRenderComponentIds = getChildBoxRenderComponentIds(
    componentMapping,
    draftComponentId,
    getAttribute,
  );

  return (
    <>
      {childBoxRenderComponentIds.map((componentId) => {
        return <SingleChildBox key={componentId} componentId={componentId} />;
      })}
    </>
  );
};

interface DraftBoxesProps {
  offsetParentRef: React.RefObject<HTMLDivElement>;
  onDragStart(): void;
}

function DraftBoxes({ onDragStart, offsetParentRef }: DraftBoxesProps) {
  const { canvas } = useRequiredContext(CanvasContext);
  const draftComponentNodes = useEditorSelector((state) =>
    selectDraftComponentNodes(state, canvas),
  );
  const canvasScale = useEditorSelector(selectCanvasScale);
  const selectedRepeatedIndex = useEditorSelector(selectDraftRepeatedIndex);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const setDraftElement = useSetDraftElement();
  const setActiveCanvas = useSetActiveCanvas();

  if (isEmpty(draftComponentNodes)) {
    return null;
  }

  const isActiveCanvas = canvas === activeCanvas;

  let selectedIndex: string | undefined | null = selectedRepeatedIndex;

  if (
    draftComponentNodes &&
    draftComponentNodes.length > 0 &&
    !draftComponentNodes.some(
      (node) => node.dataset.reploRepeatedIndex === selectedIndex,
    )
  ) {
    selectedIndex = draftComponentNodes[0]!.dataset.reploRepeatedIndex;
  }

  return (
    <>
      {draftComponentNodes.map((draftComponentNode, mapIndex) => {
        const repeatedIndex = draftComponentNode.dataset.reploRepeatedIndex;

        return (
          <DraftBox
            key={mapIndex}
            canvasScale={canvasScale}
            draftComponentNode={draftComponentNode}
            isSelected={selectedIndex === repeatedIndex}
            draftComponentId={draftComponentId}
            draggingComponentNode={draftComponentNode}
            draggingComponentId={draftComponentId}
            offsetParentRef={offsetParentRef}
            onClick={() => {
              if (!isActiveCanvas) {
                setActiveCanvas({ canvas, source: "component" });
              }

              setDraftElement({
                componentId: draftComponentId,
                repeatedIndex: repeatedIndex ?? undefined,
              });
            }}
            onDrag={onDragStart}
          />
        );
      })}
      <AncestorWithPropsBox />
    </>
  );
}

interface DraftBoxProps {
  draftComponentNode: HTMLElement;
  draftComponentId: string | null;
  isSelected: boolean;
  canvasScale: number;

  offsetParentRef: React.RefObject<HTMLDivElement>;
  draggingComponentNode: HTMLElement;
  draggingComponentId: string | null; // This is the current dragging component's id

  onClick(): void;
  onDrag(): void;
}

function DraftBox(props: DraftBoxProps) {
  const [boxSize] = useRefreshBoxSize(props.draftComponentNode);
  const draftComponentType = useEditorSelector(selectDraftComponentType);
  const { canvas } = useRequiredContext(CanvasContext);
  const activeCanvas = useEditorSelector(selectActiveCanvas);

  const { bounds, margins, paddings, borders, paddingIndicatorStyle } = boxSize;

  // TODO (Noah, 2022-01-28): Super confusing that we look at paddingIndicatorStyle here,
  // this whole component needs to be massively cleaned up in the near future
  if (paddingIndicatorStyle.display === "none") {
    return null;
  }

  if (bounds?.width === 0 && bounds.height === 0) {
    return null;
  }

  // Note (Noah, 2023-08-29, REPL-7801): Don't render the DimensionResizer
  // if we don't need to, because it does a lot of unnecessary calculations
  // which makes it expensive to render
  let allowedOrientations: ("width" | "height")[] = [];
  if (draftComponentType != null) {
    allowedOrientations =
      componentTypeToRenderData[draftComponentType]
        ?.canvasIndicatorDragDirections ?? [];
  }

  const isActiveCanvas = canvas === activeCanvas;

  const shouldShowDimensionResizer =
    allowedOrientations.length > 0 && isActiveCanvas;

  return (
    <>
      {props.isSelected && shouldShowDimensionResizer && (
        <DimensionResizer
          draftComponentNode={props.draftComponentNode}
          draftComponentId={props.draftComponentId}
          boxSizes={{
            bounds: bounds!,
            margins: margins!,
            paddings: paddings!,
            borders: borders!,
          }}
          canvasScale={props.canvasScale}
        />
      )}
      <BoundingBox
        node={props.draftComponentNode}
        showOutline={false}
        showPaddingMargin
        style={{ zIndex: CANVAS_Z_INDICES.draftBox }}
        className="draft-box"
      />
      {props.isSelected && (
        <DraggableComponentBox
          type="draft"
          offsetParentRef={props.offsetParentRef}
          draggingComponentNode={props.draggingComponentNode}
          draggingComponentId={props.draggingComponentId}
          onClick={props.onClick}
          onDrag={props.onDrag}
        />
      )}
    </>
  );
}

export function DropTargetBox() {
  const draftElementId = useEditorSelector(selectDraftElementId);
  const { dropTarget } = useDropTarget();
  const { canvas } = useRequiredContext(CanvasContext);
  const targetDocument = useTargetFrameDocument(canvas);
  const dropTargetNode = React.useMemo(
    () =>
      targetDocument
        ? getEditorComponentNode(
            targetDocument,
            draftElementId,
            dropTarget?.componentId,
          )
        : null,
    [dropTarget, draftElementId, targetDocument],
  );

  const styles = useDropTargetLineStyles(dropTargetNode);

  if (!dropTargetNode) {
    return null;
  }

  return (
    <div className="absolute bg-blue-600" id="dropTarget" style={styles} />
  );
}

function useDropTargetLineStyles(
  dropTargetNode: HTMLElement | null,
): React.CSSProperties {
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const componentMapping = useEditorSelector(selectComponentMapping);

  const { dropTarget } = useDropTarget();
  const getAttribute = useGetAttribute();
  const { canvas } = useRequiredContext(CanvasContext);
  const targetDocument = useTargetFrameDocument(canvas);

  const getMargin = (position: string, node: HTMLElement) => {
    return getElementMargin(position, node);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  const getDropTargetStyles = React.useCallback((): React.CSSProperties => {
    if (!dropTargetNode || !dropTarget?.edge || !draftElement) {
      return {};
    }

    const style: React.CSSProperties = {};

    const componentId = dropTarget.componentId;
    const targetEdge = dropTarget.edge;

    const { top, left, bottom, right, width, height } =
      dropTargetNode.getBoundingClientRect();

    const repeatedId = dropTargetNode.dataset.reploRepeatedIndex;

    const lineWidth = 5;

    style.opacity = `${targetEdge === "inside" ? 0.2 : 1}`;

    const parent = getParentComponentFromMapping(componentMapping, componentId);
    const isValidSibling = (component: ReploComponent) => {
      const displayValue = getAttribute(
        component,
        "style.display",
        null,
      )?.value;
      const displayIsValid = !displayValue || displayValue !== "none";

      return component.type !== "modal" && displayIsValid;
    };
    const sibling =
      targetEdge === "top" || targetEdge === "left"
        ? findPreviousSibling(draftElement, componentId, isValidSibling)
        : findNextSibling(draftElement, componentId, isValidSibling);

    const getMargins = (node: HTMLElement): Record<string, number> =>
      Object.fromEntries(
        ["top", "bottom", "left", "right"].map((position) => [
          position,
          Number.parseInt(getMargin(position, node), 10),
        ]),
      );

    const getStyles = (): React.CSSProperties => {
      const margin = getMargins(dropTargetNode);

      const isTargetVertical =
        getAttribute(parent, "style.flexDirection").value === "column";

      const siblingNode =
        sibling && targetDocument
          ? getEditorComponentNode(
              targetDocument,
              draftElement.id,
              sibling.id,
              repeatedId,
            )
          : null;

      if (targetEdge === "top" || targetEdge === "bottom") {
        if (isTargetVertical && siblingNode) {
          const siblingRect = siblingNode.getBoundingClientRect();
          const siblingMargin = getMargins(siblingNode);

          const mid =
            (targetEdge === "top"
              ? siblingRect.bottom + siblingMargin.bottom! + top - margin.top!
              : siblingRect.top -
                siblingMargin.top! +
                bottom +
                margin.bottom!) / 2;

          return {
            top: mid - lineWidth / 2,
            left: Math.min(left, siblingRect.left),
            height: lineWidth,
            width: Math.max(width, siblingRect.width),
          };
        }

        return {
          top:
            targetEdge === "top"
              ? top - margin.top!
              : bottom - lineWidth + margin.bottom!,
          left,
          height: lineWidth,
          width,
        };
      }

      if (targetEdge === "left" || targetEdge === "right") {
        if (!isTargetVertical && siblingNode) {
          const siblingRect = siblingNode.getBoundingClientRect();
          const siblingMargin = getMargins(siblingNode);

          const mid =
            (targetEdge === "left"
              ? siblingRect.right + siblingMargin.right! + left - margin.left!
              : siblingRect.left -
                siblingMargin.left! +
                right +
                margin.right!) / 2;

          return {
            top: Math.min(top, siblingRect.top),
            left: mid - lineWidth / 2,
            height: Math.max(height, siblingRect.height),
            width: lineWidth,
          };
        }

        return {
          top,
          left:
            targetEdge === "left"
              ? left - margin.left!
              : right - lineWidth + margin.right!,
          height,
          width: lineWidth,
        };
      }

      if (targetEdge === "inside") {
        return {
          top,
          left,
          height,
          width,
        };
      }

      return {};
    };

    const styles = getStyles();
    if (styles) {
      style.top = `${styles.top}px`;
      style.left = `${styles.left}px`;
      style.height = `${styles.height}px`;
      style.width = `${styles.width}px`;
    }

    return style;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropTargetNode, dropTarget, draftElement]);

  return getDropTargetStyles();
}

interface DraggableComponentBoxProps {
  type: "candidate" | "draft";
  offsetParentRef: React.RefObject<HTMLDivElement>;
  draggingComponentNode: HTMLElement;
  draggingComponentId: string | null; // This is the current dragging component's id

  onClick(): void;
  onDrag(): void;
}

export function DraggableComponentBox(props: DraggableComponentBoxProps) {
  const store = useEditorStore();
  const {
    currentDragType,
    currentDragIdentifier,
    setCurrentDragTypeAndIdentifier,
  } = useCurrentDragType();
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const getAttribute = useGetAttribute();
  const position = useEditorSelector(selectPosition);
  const enableNonDynamicTextEditing = useEnableNonDynamicTextEditing();
  const { dropTarget, setDropTarget } = useDropTarget();
  const [boxSize, setBoxSize] = useRefreshBoxSize(props.draggingComponentNode);
  const draftComponent = useEditorSelector(selectDraftComponent);
  const { offset, onDrop } = useDragAndDrop();
  const modal = useModal();
  const applyComponentAction = useApplyComponentAction();
  const candidateComponent = useEditorSelector(selectCandidateComponent);
  const imageSource = useEditorSelector(selectImageSource);
  const nodeRef = React.useRef<HTMLDivElement>(null);
  const isSelected = props.draggingComponentId === draftComponent?.id;
  const draggingComponent = useComponentById(props.draggingComponentId);
  const { canvas } = useRequiredContext(CanvasContext);
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const isActiveCanvas = canvas === activeCanvas;
  const { setCandidateNodeFromPoint } = useSetCandidateNodeFromPoint();

  const onDragFromProps = props.onDrag;
  const draggingComponentId = props.draggingComponentId;
  const onDrag = React.useCallback(
    (e: DraggableEvent) => {
      // Note (Ovishek, 2022-06-16): TouchEvent doesn't support e.clientX
      // we should return on touchEvent, b/c we don't support touch drag yet
      if (!(e instanceof MouseEvent)) {
        return;
      }

      onDragFromProps();

      // Set dragging true when the user actually starts dragging. Note: we don't
      // have to update draggable state here since react-draggable manages the offset
      // internally when drag is in progress
      if (
        currentDragType !== "draftComponent" ||
        currentDragIdentifier !== draggingComponentId
      ) {
        setCurrentDragTypeAndIdentifier("draftComponent", draggingComponentId);
      }

      const candidateNode = setCandidateNodeFromPoint(e.clientX, e.clientY);

      if (candidateNode && offset) {
        const newDropTarget = processDropTargetDrag(
          {
            x: (e.clientX - offset.x) / offset.scale,
            y: (e.clientY - offset.y) / offset.scale,
          },
          candidateNode,
          {
            type: "component",
            component: draggingComponent!,
          },
          candidateComponent,
          dropTarget,
          draftElement!,
          getAttribute,
          selectComponentDataMapping(store.getState()),
        );

        if (newDropTarget) {
          setDropTarget(newDropTarget);
        }
      }
    },
    [
      draggingComponent,
      currentDragIdentifier,
      currentDragType,
      getAttribute,
      setCandidateNodeFromPoint,
      setCurrentDragTypeAndIdentifier,
      setDropTarget,
      store,
      onDragFromProps,
      dropTarget,
      draftElement,
      draggingComponentId,
      candidateComponent,
      offset,
    ],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  const onDragStop = React.useCallback(
    (data: Position) => {
      const actions: UseApplyComponentActionType[] = [];
      const nonStaticPositions = ["absolute", "fixed"];

      const deltaY = data.y - boxSize.initialPosition!.y;
      const deltaX = data.x - boxSize.initialPosition!.x;
      if (
        nonStaticPositions.includes(
          getComputedStyle(props.draggingComponentNode)?.position,
        )
      ) {
        const updatedValue = (attribute: BoxSide) => {
          // TODO (Noah, 2021-02-01): Very weird to be using the paddingIndicatorStyle
          // here, but that's currently the style which is used to figure out this
          // component's top/left/bottom/right positioning. We need to refactor this
          // away entirely in the future
          if (!boxSize.paddingIndicatorStyle[attribute]) {
            return null;
          }
          const currentStringValue = `${boxSize.paddingIndicatorStyle[attribute] ?? 0}`;
          if (!currentStringValue) {
            return null;
          }
          const unitValue = parseUnit(
            currentStringValue,
            { value: "0", unit: "px" },
            "",
            "px",
          );
          let width = "",
            height = "";
          if (props.draggingComponentNode.parentElement != null) {
            const style = getComputedStyle(
              props.draggingComponentNode.parentElement,
            );
            width = style.width;
            height = style.height;
          }
          const isFixedToPage =
            getComputedStyle(props.draggingComponentNode)?.position === "fixed";
          let numberHeight = Number.parseFloat(height.replace("px", ""));
          let numberWidth = Number.parseFloat(width.replace("px", ""));
          if (isFixedToPage && offset) {
            numberHeight = offset.canvasHeight;
            numberWidth = offset.canvasWidth;
          }
          const value = Number.parseFloat(String(unitValue.value));
          let currentValue = Number.parseInt(
            currentStringValue.replace("px", ""),
          );
          const isPercentage = unitValue.unit === "%";
          const isVertical = attribute === "top" || attribute === "bottom";
          if (isPercentage) {
            if (isVertical) {
              currentValue = (value * numberHeight) / 100;
            } else {
              currentValue = (value * numberWidth) / 100;
            }
          }

          let newValue;
          switch (attribute) {
            case "top":
              newValue = currentValue + deltaY;
              break;
            case "bottom":
              newValue = currentValue - deltaY;
              break;
            case "left":
              newValue = currentValue + deltaX;
              break;
            case "right":
              newValue = currentValue - deltaX;
              break;
            default:
              newValue = currentValue;
              break;
          }
          if (isPercentage) {
            if (isVertical) {
              return `${((newValue / numberHeight) * 100).toFixed(2)}%`;
            }
            return `${((newValue / numberWidth) * 100).toFixed(2)}%`;
          }
          return `${newValue}px`;
        };
        const newStyles: Record<string, any> = {};
        for (const attribute of ["top", "left", "bottom", "right"]) {
          const newValue = updatedValue(
            attribute as "top" | "bottom" | "left" | "right",
          );
          if (newValue) {
            newStyles[attribute] = newValue;
          }
        }
        if (!isEmpty(newStyles)) {
          actions.push({
            type: "setStyles",
            value: newStyles,
          });
        }

        setBoxSize((prevBoxSize) => {
          const draggable = prevBoxSize.draggable;
          const x = Number(data.x);
          const y = Number(data.y);
          if (draggable?.position.x === x && draggable.position.y === y) {
            return prevBoxSize;
          }
          return {
            ...prevBoxSize,
            draggable: {
              position: { x, y },
            },
          };
        });
      } else {
        setBoxSize((prevBoxSize) => {
          return {
            ...prevBoxSize,
            draggable: {
              position: boxSize.initialPosition!,
            },
          };
        });
      }
      onDrop?.current?.(dropTarget, null, actions);
      setDropTarget({ componentId: null, error: null, edge: null });
      setCurrentDragTypeAndIdentifier(null, null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dropTarget,
      offset,
      props.draggingComponentNode,
      boxSize,
      setCurrentDragTypeAndIdentifier,
    ],
  );

  const onDoubleClick = React.useCallback(() => {
    if (draftComponent?.type === "image") {
      if (imageSource) {
        return;
      }

      modal.openModal({
        type: "assetLibraryModal",
        props: {
          referrer: "modifier/image",
          value: null,
          assetContentType: "image",
          onChange: (imageUrl: string) => {
            applyComponentAction({
              type: "setStyles",
              value: { __imageSource: imageUrl },
            });
          },
        },
      });
      return;
    }

    if (!draftComponent || !supportsContentEditing(draftComponent?.type)) {
      return;
    }
    enableNonDynamicTextEditing();
  }, [
    draftComponent,
    enableNonDynamicTextEditing,
    imageSource,
    modal,
    applyComponentAction,
  ]);

  let cursor;
  if (currentDragType) {
    cursor = "grabbing";
  } else {
    if (position === "fixed" || position === "absolute") {
      cursor = "grab";
    } else {
      cursor = "default";
    }
  }

  if (!boxSize.draggable?.position || !props.draggingComponentId) {
    return null;
  }

  return (
    <Draggable
      axis="both"
      handle=".draggable--box"
      scale={offset?.scale ?? 1}
      defaultPosition={boxSize.initialPosition!}
      position={boxSize.draggable.position}
      onDrag={onDrag}
      onStop={(_, data) => onDragStop(data)}
      bounds={DRAGGABLE_BOUNDS}
      offsetParent={props.offsetParentRef?.current ?? undefined}
      nodeRef={nodeRef}
    >
      <div
        id={`draggable-box-${draftComponent?.type}`}
        data-testid={
          isSelected ? `draggable-box-${canvas}` : `candidate-box-${canvas}`
        }
        ref={nodeRef}
        className={classNames(
          "draggable--box absolute border-2",
          isSelected ? "border-blue-600" : "border-yellow-600",
          isSelected && (isActiveCanvas ? "border-solid" : "border-dashed"),
        )}
        onClick={(e) => {
          if (e.detail > 1) {
            onDoubleClick();
          } else {
            props.onClick();
          }
        }}
        onContextMenu={props.onClick}
        style={{
          width: boxSize.bounds?.width,
          height: boxSize.bounds?.height,
          cursor: cursor,
          zIndex: CANVAS_Z_INDICES.draggableComponentBox,
        }}
      />
    </Draggable>
  );
}

interface RefreshBoxSizeState {
  bounds: NumericBounds | null;
  margins: Edges | null;
  paddings: Edges | null;
  borders: Edges | null;
  paddingIndicatorStyle: React.CSSProperties;
  draggable: {
    position: Position;
  } | null;
  initialPosition: Position | null;
}

function useRefreshBoxSize(
  componentNode: HTMLElement,
): [
  state: RefreshBoxSizeState,
  setState: React.Dispatch<React.SetStateAction<RefreshBoxSizeState>>,
] {
  const [boxSize, setBoxSize] = React.useState<RefreshBoxSizeState>({
    paddingIndicatorStyle: { display: "none" },
    bounds: null,
    margins: null,
    paddings: null,
    borders: null,
    draggable: null,
    initialPosition: null,
  });
  const draftComponent = useEditorSelector(selectDraftComponent);
  const getAttribute = useGetAttribute();

  const _getEdgeAttribute = React.useCallback(
    (attribute: RuntimeStyleAttribute) => {
      return getEdgeAttribute(draftComponent, attribute, getAttribute);
    },
    [draftComponent, getAttribute],
  );

  const refreshBoxSizeInternal = React.useCallback(() => {
    const {
      top: boundingTop,
      left: boundingLeft,
      width: boundingWidth,
      height: boundingHeight,
    } = componentNode.getBoundingClientRect();
    const style = getComputedStyle(componentNode);

    setBoxSize((prevBoxSize) => {
      return {
        ...prevBoxSize,
        bounds: {
          top: boundingTop,
          height: boundingHeight,
          left: boundingLeft,
          width: boundingWidth,
        },
        margins: {
          top: style.marginTop,
          left: style.marginLeft,
          bottom: style.marginBottom,
          right: style.marginRight,
        },
        borders: {
          top: style.borderTopWidth,
          left: style.borderLeftWidth,
          bottom: style.borderBottomWidth,
          right: style.borderRightWidth,
        },
        paddings: {
          top: style.paddingTop,
          left: style.paddingLeft,
          bottom: style.paddingBottom,
          right: style.paddingRight,
        },
        paddingIndicatorStyle: {
          top: _getEdgeAttribute("top"),
          left: _getEdgeAttribute("left"),
          right: _getEdgeAttribute("right"),
          bottom: _getEdgeAttribute("bottom"),
        },
        initialPosition: { x: boundingLeft, y: boundingTop },
        draggable: {
          position: { x: boundingLeft, y: boundingTop },
        },
      };
    });
  }, [_getEdgeAttribute, componentNode]);

  useMutationObserver(componentNode, refreshBoxSizeInternal, {
    subtree: true,
    childList: true,
    attributes: true,
  });

  const boundingBox = useBoundingBox(componentNode);

  React.useEffect(() => {
    setBoxSize((prevBoxSize) => {
      return {
        ...prevBoxSize,
        bounds: Object.assign({}, prevBoxSize.bounds, boundingBox),
        initialPosition: {
          x: boundingBox?.left ?? 0,
          y: boundingBox?.top ?? 0,
        },
        draggable: {
          position: boundingBox
            ? { x: boundingBox.left, y: boundingBox.top }
            : { x: 0, y: 0 },
        },
      };
    });
  }, [boundingBox]);

  return [boxSize, setBoxSize];
}

export const SingleChildBox: React.FC<{ componentId: string }> = ({
  componentId,
}) => {
  const elementId = useEditorSelector(selectDraftElementId);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const draftRepeatedIndex = useEditorSelector(selectDraftRepeatedIndex);
  const { canvas } = useRequiredContext(CanvasContext);
  const targetDocument = useTargetFrameDocument(canvas);

  const isDraftComponent = componentId === draftComponentId;

  const componentNode = targetDocument
    ? getEditorComponentNode(
        targetDocument,
        elementId,
        componentId,
        isDraftComponent ? draftRepeatedIndex : null,
      )
    : null;

  const [children, setChildren] = React.useState(
    Array.from(componentNode?.children ?? []) as HTMLElement[],
  );

  useMutationObserver(
    componentNode,
    React.useCallback(() => {
      setChildren(Array.from(componentNode?.children ?? []) as HTMLElement[]);
    }, [componentNode]),
    { attributes: true, childList: true, subtree: true },
  );

  return (
    <>
      {children.map((child, index) => {
        let renderChild = child;
        if (!child?.dataset["rid"] && child.children[0]) {
          // Note (Ovishek, 2023-03-16): We want to only highlight replo components
          // things like grid wrapper / carousel intermediate elements should be ignored
          renderChild = child.children[0] as HTMLElement;
        }
        if (!renderChild?.dataset["rid"]) {
          // Note (Noah, 2023-06-13): Don't render child boxes for any elements that
          // aren't Replo components themselves
          return null;
        }
        return (
          <BoundingBox
            key={index}
            node={renderChild}
            showPaddingMargin={false}
            showOutline={true}
            className={twMerge(
              "child-box border-2 border-pink-300",
              isDraftComponent && "border-blue-300",
            )}
            style={{ zIndex: CANVAS_Z_INDICES.childBoundingBoxes }}
          />
        );
      })}
    </>
  );
};

const AncestorWithPropsBox: React.FC = () => {
  const component = useEditorSelector(
    selectDraftComponentAncestorWithEditorProps,
  );
  const { canvas } = useRequiredContext(CanvasContext);
  const componentNode = useEditorSelector((state) =>
    selectDraftComponentAncestorWithEditorPropsNode(state, canvas),
  );
  const boundingBoxStyles = useBoundingBox(componentNode ?? null);

  if (!component || !componentNode) {
    return null;
  }

  return (
    <div
      className="border-2 border-blue-300"
      style={{
        position: "absolute",
        pointerEvents: "none",
        // Note (Evan, 2024-06-17): "we can just do max - 1" -Yuxin
        zIndex: maxZIndex - 1,
        ...boundingBoxStyles,
      }}
    />
  );
};

export const DraftComponentControls: React.FC = () => {
  const { canvas } = useRequiredContext(CanvasContext);
  const symbols = useEditorSelector(selectSymbolsMapping);
  const elementType = useEditorSelector(selectDraftElementType);
  const component = useEditorSelector(selectDraftComponent);
  const draftComponentHasParent = useEditorSelector(
    selectDraftComponentHasParent,
  );
  const isSelfOrAncestorTooltipContent = useEditorSelector((state) =>
    selectHasDraftComponentAncestorOrSelfWithSpecificType(
      state,
      "tooltipContent",
    ),
  );

  const draftComponentNode = useEditorSelector(
    (state) => selectDraftComponentNode(state, canvas),
    shallowEqual,
  );

  const ancestorWithEditorProps = useEditorSelector(
    selectDraftComponentAncestorWithEditorProps,
  );

  const ancestorWithEditorPropsNode = useEditorSelector((state) =>
    selectDraftComponentAncestorWithEditorPropsNode(state, canvas),
  );

  const [
    { width: ancestorControlBoxWidth, height: ancestorControlBoxHeight },
    setDimensions,
  ] = React.useState({ width: 0, height: 0 });
  const ancestorControlBoxRef = React.useRef<HTMLDivElement>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies(ancestorWithEditorPropsNode): We actually want this
  React.useLayoutEffect(() => {
    if (ancestorControlBoxRef.current) {
      setDimensions({
        width: ancestorControlBoxRef.current.offsetWidth,
        height: ancestorControlBoxRef.current.offsetHeight,
      });
    }
  }, [ancestorWithEditorPropsNode]);

  const bounds = useBoundingBox(draftComponentNode);
  const ancestorBounds = useBoundingBox(ancestorWithEditorPropsNode);
  const scale = useEditorSelector(selectCanvasScale);

  const editorMode = useEditorSelector(selectEditorMode);
  const { deltaY } = useEditorSelector(selectCanvasDeltaXY);

  if (!component || !draftComponentNode || !elementType) {
    return null;
  }

  if (!bounds || (bounds.width === 0 && bounds.height === 0)) {
    return null;
  }

  const overlappingBounds =
    // NOTE (Sebas, 2024-07-04): We don't want to overlap the control box if the draft component is a tooltip content,
    // this is because the tooltip content is absolutely positioned and it will show the control box in the wrong place.
    !isSelfOrAncestorTooltipContent && ancestorBounds
      ? bounds.top < (ancestorBounds?.top ?? 0) + ancestorControlBoxHeight &&
        bounds.left < (ancestorBounds?.left ?? 0) + ancestorControlBoxWidth
      : false;

  const boundsTop = overlappingBounds ? ancestorBounds!.top : bounds.top;

  const controlBoxTop = calculateControlBoxTop({
    scale,
    boundsTop,
    boundsHeight: bounds.height,
    canvasDeltaY: deltaY,
  });

  const ancestorLeft = ancestorBounds?.left ?? 0;
  // Note (Evan, 2024-05-09): We scale the ancestor control's width and the 6px gap, since these
  // measurements are in "absolute" pixels
  const overlappingBoundsLeft = `calc(${ancestorLeft + (ancestorControlBoxWidth + 6) * scalingFactor(scale)}px)`;

  const label = draftComponentHasParent
    ? sanitizeTreeContent(getComponentName(component, symbols), scale)
    : elementTypeToEditorData[elementType].singularDisplayName;

  return (
    editorMode !== EditorMode.aiGeneration && (
      <>
        <ControlBox
          component={component}
          top={controlBoxTop}
          left={overlappingBounds ? overlappingBoundsLeft : bounds.left}
          label={label}
        />
        {!isSelfOrAncestorTooltipContent &&
          ancestorWithEditorProps &&
          ancestorWithEditorPropsNode && (
            <AncestorControlBox
              boxRef={ancestorControlBoxRef}
              component={ancestorWithEditorProps}
              top={`calc(${ancestorBounds?.top || 0}px - 36px)`}
              left={ancestorLeft}
              label={sanitizeTreeContent(
                getComponentName(ancestorWithEditorProps, symbols),
                scale,
              )}
            />
          )}
      </>
    )
  );
};

interface ControlBoxProps {
  component: ReploComponent;
  top: string | number;
  left: string | number;
  label: string;
}

const ControlBox: React.FC<ControlBoxProps> = ({
  component,
  top,
  left,
  label,
}) => {
  const hasCustomProps = getCustomPropDefinitions(component).some(
    (def) => def.type !== "component",
  );
  const editorProps = getRenderData(component.type)?.editorProps;
  const dispatch = useEditorDispatch();

  const scale = useEditorSelector(selectCanvasScale);
  return (
    <div
      className="absolute"
      style={{
        top,
        left,
        // Note (Evan, 2024-05-09): Mild hack: the translateZ(0) and backfaceVisibility: "hidden"
        // are both tricks to get the text to not be blurry when scaled, see: https://stackoverflow.com/questions/14677490/blurry-text-after-using-css-transform-scale-in-chrome
        transform: `scale(${scalingFactor(scale)}) translateZ(0) `,
        transformOrigin: "bottom left",
        backfaceVisibility: "hidden",
        // Note (Evan, 2024-06-17): "Call this max" -Yuxin
        zIndex: maxZIndex,
      }}
    >
      <div
        className={`h-[${CONTROL_BOX_HEIGHT}]px flex flex-row items-stretch gap-2 `}
      >
        <div className="flex flex-row items-center justify-start rounded-md bg-blue-600 px-2 py-1/2 text-sm font-medium leading-7 text-white">
          <span className="max-w-[200px] truncate" style={{ fontSize: "1rem" }}>
            {label}
          </span>
          {hasCustomProps && (
            <div className="ml-2 flex items-center border-l border-white/25 pl-2">
              <IconButton
                icon={<IoMdSettings size={16} />}
                type="primary"
                onClick={() => {
                  dispatch(setRightBarActiveTab("custom"));
                }}
                tooltipText="Config"
                style={{
                  backgroundColor: "transparent",
                  padding: 0,
                }}
              />
            </div>
          )}
          {editorProps &&
            Object.entries(editorProps).map(([id, definition]) => (
              <EditorPropControl
                key={id}
                component={component}
                id={id}
                propDefinition={definition}
              />
            ))}
        </div>
        <GenerateWithAIButton />
      </div>
    </div>
  );
};

// Note (Evan, 2024-06-13): Once again, this is a separate component so
// we don't rerender unnecessarily
const GenerateWithAIButton: React.FC = () => {
  const types: ReploComponentType[] = ["text"];
  types.push("container");
  const draftComponentOrDescendantIsSuitable = useEditorSelector((state) =>
    selectDraftComponentOrDescendantIsOneOfTypes(state, types),
  );
  const { setIsMenuOpen: setMenuOpen } = useAIStreaming();

  if (!draftComponentOrDescendantIsSuitable) {
    return null;
  }

  return (
    <div className="flex flex-row items-center justify-start rounded-md bg-ai  font-medium leading-7 text-white">
      <Button
        type="secondary"
        tooltipText="Generate with AI"
        style={{
          padding: "0 0.5rem",
          backgroundColor: "transparent",
          justifyContent: "flex-start",
          minWidth: "auto",
        }}
        onClick={() => {
          setMenuOpen(true, "pill");
        }}
      >
        <div className="text-white/80 hover:text-white flex flex-row gap-2 items-center text-base">
          <span className="ml-1">AI</span>
          {/* Note (Gabe, 2024-07-31): Because of the design of this icon, when centered it looks off, so we offset it up by a single pixel. */}
          <BsMagic size={18} className="relative -top-[1px]" />
        </div>
      </Button>
    </div>
  );
};

interface AncestorControlBoxProps extends ControlBoxProps {
  boxRef: React.RefObject<HTMLDivElement>;
}

function AncestorControlBox({
  boxRef,
  component,
  top,
  left,
  label,
}: AncestorControlBoxProps) {
  const hasCustomProps = getCustomPropDefinitions(component).some(
    (def) => def.type !== "component",
  );
  const editorProps = getRenderData(component.type)?.editorProps;
  const dispatch = useEditorDispatch();
  const setDraftElement = useSetDraftElement();

  const scale = useEditorSelector(selectCanvasScale);

  return (
    <div
      className="absolute z-50"
      style={{
        top,
        left,
        // Note (Evan, 2024-05-09): Mild hack: the translateZ(0) and backfaceVisibility: "hidden"
        // are both tricks to get the text to not be blurry when scaled, see: https://stackoverflow.com/questions/14677490/blurry-text-after-using-css-transform-scale-in-chrome
        transform: `scale(${scalingFactor(scale)}) translateZ(0) `,
        transformOrigin: "bottom left",
        backfaceVisibility: "hidden",
      }}
    >
      <div ref={boxRef}>
        <div className="flex flex-row items-center justify-start rounded-md bg-blue-400 px-2 py-1/2 text-sm font-medium leading-7 text-white">
          <button
            className="max-w-[200px] truncate"
            onClick={() => {
              setDraftElement({
                componentId: component.id,
              });
            }}
            style={{ fontSize: "1rem" }}
          >
            {label}
          </button>
          {hasCustomProps && (
            <div className="ml-2 flex items-center border-l border-white/25 pl-2">
              <IconButton
                icon={<IoMdSettings size={16} />}
                type="primary"
                onClick={() => {
                  setDraftElement({
                    componentId: component.id,
                  });

                  setTimeout(() => {
                    dispatch(setRightBarActiveTab("custom"));
                  });
                }}
                tooltipText="Config"
                style={{
                  backgroundColor: "transparent",
                  padding: 0,
                }}
              />
            </div>
          )}
          {editorProps &&
            Object.entries(editorProps).map(([id, definition]) => (
              <EditorPropControl
                key={id}
                component={component}
                id={id}
                propDefinition={definition}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

const EditorPropControl: React.FC<{
  id: string;
  component: ReploComponent;
  propDefinition: EditorProp;
}> = ({ id, propDefinition: { type, description }, component }) => {
  const draftElementId = useEditorSelector(selectDraftElementId);
  const { canvas } = useRequiredContext(CanvasContext);
  const targetDocument = useTargetFrameDocument(canvas);

  const accordionAncestor = useEditorSelector(
    selectDraftComponentAccordionAncestor,
  );

  const sharedKey = getSharedStateKey(component, id, {
    hasAccordionAncestor: Boolean(accordionAncestor),
    accordionId: accordionAncestor?.id,
  });
  const [currentValue, setValue] = usePaintSharedStateValue(sharedKey);

  const editorData = getEditorPropEditorData(type);
  const dispatch = useEditorDispatch();

  const onChange = (value: string) => {
    if (!draftElementId) {
      return;
    }

    if (type === "collapsibility" && Boolean(accordionAncestor)) {
      const newAccordionOpenItems = getAccordionNewItems(
        component.id,
        currentValue as any,
        false,
      );
      setValue(newAccordionOpenItems);
    } else if (type === "interactions") {
      dispatch(setRightBarActiveTab("interactions"));
    } else {
      setValue(value);
    }
    controlOnChange(targetDocument, draftElementId, component, dispatch, value);
  };

  const shouldShowEditorControl = canRenderEditorControl(type, component);
  if (!shouldShowEditorControl) {
    return null;
  }

  return (
    <div
      className="ml-2 flex items-center border-l border-white/25 pl-2"
      style={{ fontSize: "1rem" }}
    >
      {editorData?.render({
        value: currentValue ?? editorData?.defaultValue,
        id,
        component,
        onChange: (value: any) => {
          onChange(value);
        },
        description,
        componentId: component.id,
      })}
    </div>
  );
};

export const TargetFrameLayer = DropTarget(
  "component",
  {
    drop: (_props, monitor) => {
      // @ts-expect-error
      onDrop(monitor.getItem());
    },
    hover(_props, monitor) {
      const clientOffset = monitor.getClientOffset();
      // @ts-expect-error // biome-ignore lint
      setCandidateNode(clientOffset.x, clientOffset.y);
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
    didDrop: monitor.didDrop(),
    currentOffset: monitor.getClientOffset(),
  }),
)(TargetFrameOverlay);
export function TargetFrameOverlay({
  connectDropTarget,
}: {
  connectDropTarget: any;
}) {
  const { canvas } = useRequiredContext(CanvasContext);
  return (
    <div
      ref={connectDropTarget}
      id={`targetFrameOverlay-${canvas}`}
      className="absolute inset-0"
    />
  );
}

// Note (Evan, 2024-06-17): The desired behavior here is to have the control box's vertical position change
// when scrolling / zooming, such that
// 1) if the top of draft component is in view, the control box should move with it (the default behavior prior to this change)
// 2) otherwise, the control box should be either fixed to the header or the lower edge of the draft component,
// whichever is highest. This allows the control box to smoothly scroll out of view with the draft component.
function calculateControlBoxTop({
  scale,
  boundsTop,
  boundsHeight,
  canvasDeltaY,
}: {
  scale: number;
  boundsTop: number;
  boundsHeight: number;
  canvasDeltaY: number;
}) {
  // i.e., the top position which causes the control box to move with the lower edge of the bounding box
  const fixedToControlBoxBottomPosition = `calc(${boundsTop}px + ${boundsHeight}px - 28px)`;

  // i.e., the top position which causes the control box to move with the upper edge of the bounding box
  // (the default behavior prior to this change)
  const fixedToControlBoxTopPosition = `calc(${boundsTop}px - 36px)`;

  // Note (Evan, 2024-06-17): This is some voodoo magic. The goal here is to
  // calculate a top position for the control box that will result in it rendering
  // immediately below the header (i.e., sticky to the top of the canvas area). The intuitive
  // way of doing this is to simply set a top position of -1 * deltaY (the canvas scroll Y value),
  // divided by the scale. (`scale` itself, rather than the scaling factor, since it's the scale
  // applied to the canvas)
  //
  // The issue with this is that control boxes are scaled differently (they use the scaling factor),
  // so without some additional correction, they will be too low for scales greater than 1. The correction
  // can be calculated from the scaling factor and the height of the control box.
  const correction =
    Math.max(scale - 1, 0) * CONTROL_BOX_HEIGHT * scalingFactor(scale);

  // i.e., the top position which causes the control box to be fixed to the header
  const fixedToHeaderTopPosition = `${(-1 * canvasDeltaY + FIXED_TO_HEADER_PADDING) / scale - correction}px`;

  return `max(${fixedToControlBoxTopPosition}, min(${fixedToHeaderTopPosition}, ${fixedToControlBoxBottomPosition}))`;
}

function getChildBoxRenderComponentIds(
  componentMapping: ComponentMapping,
  draftComponentId: string | null,
  getAttribute: GetAttributeFunction,
) {
  if (!draftComponentId) {
    return [];
  }

  const childBoxRenderComponents: string[] = [draftComponentId];

  let currentComponentId = draftComponentId;
  while (componentMapping?.[currentComponentId]?.parentComponent?.id) {
    const parentComponent =
      componentMapping?.[currentComponentId]?.parentComponent;
    currentComponentId = parentComponent!.id;

    // Note (Ovishek, 2022-11-4): For ancestor who has a display grid
    // we are rendering childBoxes for all of them. B/c for grid the layout
    // can be complicated and if any of the children is selected there remains no clue to the layout itself.
    // In this way we highlight all the ancestors grid layout by pink and let the user a better user experience.
    if (getAttribute(parentComponent!, "style.display").value === "grid") {
      childBoxRenderComponents.push(currentComponentId);
    }
  }

  return childBoxRenderComponents;
}
