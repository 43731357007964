import type { WorkspacePartnershipStatusTier } from "schemas/generated/workspace";

import * as React from "react";

import { PartnershipLevelCardSkeleton } from "@components/dashboard/SkeletonLoaders";
import SvgReploAffiliate from "@svg/replo-affiliate";
import SvgReploGoldPartner from "@svg/replo-gold-partner";
import SvgReploPlatinumPartner from "@svg/replo-platinum-partner";
import SvgReploSilverPartner from "@svg/replo-silver-partner";

import * as Progress from "@radix-ui/react-progress";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

type PartnershipLevelCardProps = {
  referredWorkspaces: number;
  currentTier: WorkspacePartnershipStatusTier;
  size?: "small" | "large";
  isLoading?: boolean;
};
type PartnershipLevelData = {
  name: string;
  primaryColor: string;
  secondaryColor: string;
  requiredAmount: number;
  badge: React.FC | null;
  nextLevel: WorkspacePartnershipStatusTier | null;
};

const PartnershipLevelCard: React.FC<PartnershipLevelCardProps> = ({
  referredWorkspaces,
  currentTier,
  size = "large",
  isLoading,
}) => {
  const { nextLevel, currentLevel } = getPartnershipLevel(currentTier);

  if (isLoading && size === "large") {
    return <PartnershipLevelCardSkeleton />;
  }

  return (
    <div className="flex flex-col gap-2">
      <div
        className={twMerge(
          classNames("text-sm text-slate-400", {
            "text-xs": size === "small",
          }),
        )}
      >
        Partnership Level
      </div>

      <div
        className={twMerge(
          classNames("flex items-center gap-2", {
            "gap-1": size === "small",
          }),
        )}
      >
        <Diamond
          primaryColor={currentLevel.primaryColor}
          secondaryColor={currentLevel.secondaryColor}
          className={size === "small" ? "scale-75" : undefined}
        />
        <span
          className={twMerge(
            classNames("text-2xl font-semibold", {
              "text-base": size === "small",
            }),
          )}
          style={{ color: currentLevel.primaryColor }}
        >
          {currentLevel.name}
        </span>
      </div>
      {nextLevel && (
        <div
          className={twMerge(
            classNames("flex gap-6", {
              "flex-col-reverse gap-3": size === "small",
            }),
          )}
        >
          <Progress.Root
            max={nextLevel?.requiredAmount}
            value={referredWorkspaces}
            className={twMerge(
              classNames("relative h-5 w-72 bg-slate-200", {
                "w-full overflow-hidden": size === "small",
              }),
            )}
          >
            <Progress.Indicator
              style={{
                transform: `translateX(-${getProgressPercentage(
                  referredWorkspaces,
                  currentLevel,
                  nextLevel,
                )}%)`,
                backgroundColor: currentLevel.primaryColor,
              }}
              className="h-full"
            />
            <span className="absolute right-0 top-0 my-0.5 mx-1 text-xs text-slate-400">
              {nextLevel.requiredAmount}
            </span>
          </Progress.Root>
          <a
            href="https://replo.app/partner-program"
            target="_blank"
            rel="noreferrer"
          >
            <div
              className={twMerge(
                classNames("text-sm font-semibold", {
                  "text-xs": size === "small",
                }),
              )}
              style={{ color: nextLevel.primaryColor }}
            >
              {nextLevel.requiredAmount - referredWorkspaces} More to Unlock{" "}
              {nextLevel.name} Perks
            </div>
          </a>
        </div>
      )}
    </div>
  );
};

const getProgressPercentage = (
  referredWorkspaces: number,
  currentLevel: PartnershipLevelData,
  nextLevel: PartnershipLevelData,
) => {
  // Note (Sebas, 2023-06-28): If referredWorkspaces is a multiple of 25, that
  // means that the user has completed the current level and is on the next
  // one. In that case, we want to show the progress bar a little bit
  // colored.
  const isUserOnNextLevel =
    referredWorkspaces !== 75 && referredWorkspaces % 25 === 0;
  const adjustedReferredWorkspaces = isUserOnNextLevel
    ? 1
    : referredWorkspaces - currentLevel.requiredAmount;
  const rawProgressPercentage =
    (adjustedReferredWorkspaces /
      (nextLevel.requiredAmount - currentLevel.requiredAmount)) *
    100;
  const cappedProgressPercentage = 100 - rawProgressPercentage;
  return Math.max(cappedProgressPercentage, 0);
};

const Diamond: React.FC<{
  primaryColor: string;
  secondaryColor: string;
  className?: string;
}> = ({ primaryColor, secondaryColor, className }) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_17095_29389)">
        <rect
          width="24.4199"
          height="24.4199"
          transform="translate(0 17.2676) rotate(-45)"
          fill={primaryColor}
        />
        <path
          d="M17.2674 -0.00936313L34.5445 17.2677L17.3285 17.2677L17.2674 -0.00936313Z"
          fill={secondaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_17095_29389">
          <rect
            width="24.4199"
            height="24.4199"
            fill="white"
            transform="translate(0 17.2676) rotate(-45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const PARTNERSHIP_LEVEL_MAP: Record<
  WorkspacePartnershipStatusTier,
  PartnershipLevelData
> = {
  affiliate: {
    name: "Affiliate",
    primaryColor: "#2563eb", // bg-blue-600
    secondaryColor: "#bfdbfe", // bg-blue-200
    requiredAmount: 0,
    badge: SvgReploAffiliate,
    nextLevel: "silver",
  },
  silver: {
    name: "Silver Partner",
    primaryColor: "#94a3b8", // bg-slate-400
    secondaryColor: "#e2e8f0", // bg-slate-200
    requiredAmount: 25,
    badge: SvgReploSilverPartner,
    nextLevel: "gold",
  },
  gold: {
    name: "Gold Partner",
    primaryColor: "#fbbf24", // bg-amber-600
    secondaryColor: "#fde68a", // bg-amber-200
    requiredAmount: 50,
    badge: SvgReploGoldPartner,
    nextLevel: "platinum",
  },
  platinum: {
    name: "Platinum Partner",
    primaryColor: "#000000", // bg-black
    secondaryColor: "#374151", // bg-gray-700
    requiredAmount: 100,
    badge: SvgReploPlatinumPartner,
    nextLevel: null,
  },
};

const getPartnershipLevel = (currentTier: WorkspacePartnershipStatusTier) => {
  const currentLevel = PARTNERSHIP_LEVEL_MAP[currentTier];

  return {
    currentLevel: currentLevel,
    nextLevel: currentLevel?.nextLevel
      ? PARTNERSHIP_LEVEL_MAP[currentLevel.nextLevel]
      : null,
  };
};

export default PartnershipLevelCard;
