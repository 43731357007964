import type { MoveProjectModalProps } from "../AppModalTypes";

import * as React from "react";

import Button from "@common/designSystem/Button";
import Modal from "@common/designSystem/Modal";
import Selectable from "@common/designSystem/Selectable";
import toast from "@common/designSystem/Toast";
import { ModalLayout } from "@common/ModalLayout";
import { useWorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import {
  publisherApi,
  useGetUserWorkspaceDetailsQuery,
} from "@editor/reducers/api-reducer";
import { useEditorDispatch } from "@editor/store";
import {
  isShopifyIntegrationCapacityReason,
  mapShopifyIntegrationCapacityReasonToError,
} from "@editor/utils/shopifyIntegrationCapacity";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { Controller, useForm, useWatch } from "react-hook-form";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";

type FormValues = {
  newWorkspaceId: string | null;
};

export const MoveProjectModal: React.FC<MoveProjectModalProps> = ({
  projectId,
  projectHasShopifyIntegration,
  oldWorkspaceId,
  shopifyUrl,
}) => {
  const logEvent = useLogAnalytics();
  const { closeModal } = useModal();
  const { setWorkspaceId } = useWorkspaceDashboardContext();
  const dispatch = useEditorDispatch();
  const { mutate: transferProject, isPending: isLoading } =
    trpc.project.transfer.useMutation({
      onSuccess: ({ newWorkspaceId }) => {
        toast({
          header: "Project transferred",
        });
        setWorkspaceId(newWorkspaceId);
        if (pathname.includes("/workspace")) {
          navigate(
            generatePath("/workspace/:workspaceId/projects", {
              workspaceId: newWorkspaceId,
            }),
          );
        }

        // NOTE (Fran 2024-03-13): If in the future we have more than two payment processors we
        // should change this.
        const previousBilledWorkspace = filteredWorkspaces?.find(
          (workspace) => workspace.id === oldWorkspaceId,
        )?.hasShopifyActiveSubscription
          ? "shopify"
          : "stripe";
        logEvent("project.move", {
          fromWorkspaceId: oldWorkspaceId,
          toWorkspaceId: newWorkspaceId,
          previousBilledWorkspace: previousBilledWorkspace,
        });
        void trpcUtils.workspace.getUserWorkspacesList.invalidate();
        void trpcUtils.project.findByUserId.invalidate();
        // TODO (Sebas, 2024-07-15): Remove once we migrate all the project related
        // endpoints to TRPC.
        dispatch(publisherApi.util.invalidateTags(["workspaces", "projects"]));
      },
      onSettled: () => closeModal({ type: "moveProjectModal" }),
    });

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data } = useGetUserWorkspaceDetailsQuery();

  const validateWorkspace = React.useCallback(
    ({ newWorkspaceId }: { newWorkspaceId: string | null }) => {
      const workspace = data?.workspaces.find(
        (workspace) => workspace.id === newWorkspaceId,
      );
      if (
        !newWorkspaceId ||
        !workspace ||
        !projectHasShopifyIntegration ||
        workspace.canAddShopifyIntegration.hasCapacity
      ) {
        return { values: { newWorkspaceId }, errors: {} };
      }

      return {
        values: { newWorkspaceId },
        errors: {
          newWorkspaceId: {
            type: workspace.canAddShopifyIntegration.reason,
          },
        },
      };
    },
    [data, projectHasShopifyIntegration],
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      newWorkspaceId: null,
    },
    resolver: validateWorkspace,
  });
  const newWorkspaceIdValue = useWatch({ control, name: "newWorkspaceId" });

  if (!oldWorkspaceId) {
    return null;
  }

  const filteredWorkspaces = data?.workspaces.filter((workspace) => {
    return (
      workspace.id !== oldWorkspaceId &&
      workspace.workspaceMemberships[0]?.role === "owner"
    );
  });

  const hasWorkspaceIdErrors = !isEmpty(errors.newWorkspaceId);

  const onSubmit = ({ newWorkspaceId }: FormValues) => {
    if (newWorkspaceId && oldWorkspaceId) {
      transferProject({
        projectId,
        newWorkspaceId,
        oldWorkspaceId,
      });
    }
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        closeModal({ type: "moveProjectModal" });
      }}
      className="w-auto"
    >
      <ModalLayout
        height="auto"
        width="25vw"
        minWidth={400}
        mainContent={() => (
          <div className="flex flex-col gap-6 p-2 w-full">
            <h2 className="text-xl font-medium">Move Project</h2>
            <p className="text-sm text-default">
              Which workspace do you want to move this to?
            </p>
            <form
              className="flex flex-col gap-6"
              onSubmit={(data) => {
                void handleSubmit(onSubmit)(data);
              }}
            >
              <div className="flex flex-col gap-2">
                <div className="text-sm text-default font-medium">
                  Workspaces you have access to
                </div>
                <label htmlFor="workspaceName" className="sr-only">
                  Move project to workspace:
                </label>
                <Controller
                  name="newWorkspaceId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Selectable
                      placeholder="Select a workspace"
                      value={value}
                      size="sm"
                      options={
                        filteredWorkspaces?.map((workspace) => ({
                          label: workspace.name,
                          value: workspace.id,
                        })) ?? []
                      }
                      onSelect={onChange}
                    />
                  )}
                />
              </div>
              {errors.newWorkspaceId &&
                isShopifyIntegrationCapacityReason(
                  errors.newWorkspaceId.type,
                ) && (
                  <div className="text-xs text-red-600">
                    {mapShopifyIntegrationCapacityReasonToError({
                      reason: errors.newWorkspaceId.type,
                      redirectToBilling: () => {
                        closeModal({ type: "moveProjectModal" });
                        navigate(`/workspace/${newWorkspaceIdValue}/billing`);
                      },
                    })}
                  </div>
                )}
              {shopifyUrl && (
                <div className="text-xs">
                  Please note: The Shopify store currently connected to this
                  project will not be moved. To move the Shopify integration
                  along with the project, disconnect it from{" "}
                  <a
                    className="text-blue-600"
                    href={generatePath(
                      "/workspace/:workspaceId/integrations/shopify",
                      {
                        workspaceId: oldWorkspaceId,
                      },
                    )}
                  >
                    Integration Settings
                  </a>{" "}
                  then access Replo through Shopify Admin to add it to the new
                  workspace.
                </div>
              )}
              <div className="flex gap-2 self-end">
                <Button
                  type="secondary"
                  size="base"
                  textClassNames="text-xs"
                  onClick={() => closeModal({ type: "moveProjectModal" })}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="base"
                  textClassNames="text-xs"
                  htmlType="submit"
                  isDisabled={hasWorkspaceIdErrors || !newWorkspaceIdValue}
                  isLoading={isLoading}
                >
                  Confirm
                </Button>
              </div>
            </form>
          </div>
        )}
      />
    </Modal>
  );
};
