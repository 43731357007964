import type { CustomPropDefinition } from "replo-runtime/shared/Component";
import type { WidthOrHeight } from "replo-runtime/shared/types";
import type { ComponentMovementSource } from "replo-runtime/shared/utils/dragging";
import type {
  EditorPropsRecord,
  StyleElements,
} from "replo-runtime/shared/utils/renderComponents";
import type { Context } from "replo-runtime/store/AlchemyVariable";
import type { ReploComponentType } from "schemas/component";
import type { ReploElementType } from "schemas/generated/element";

import accordionBlockConfig from "replo-runtime/store/components/AccordionBlock/config";
import beforeAfterConfig from "replo-runtime/store/components/BeforeAfterSlider/config";
import buttonConfig from "replo-runtime/store/components/Button/config";
import buyWithPrimeButtonConfig from "replo-runtime/store/components/BuyWithPrimeButton/config";
import carouselConfig from "replo-runtime/store/components/Carousel/config";
import carouselV2Config from "replo-runtime/store/components/CarouselV2/config";
import carouselV3Config from "replo-runtime/store/components/CarouselV3/config";
import collapsibleConfig from "replo-runtime/store/components/Collapsible/config";
import collapsibleV2Config from "replo-runtime/store/components/CollapsibleV2/config";
import collectionConfig from "replo-runtime/store/components/Collection/config";
import collectionSelectConfig from "replo-runtime/store/components/CollectionSelect/config";
import collectionV2Config from "replo-runtime/store/components/CollectionV2/config";
import containerConfig from "replo-runtime/store/components/Container/config";
import countdownTimerConfig from "replo-runtime/store/components/CountdownTimer/config";
import dropdownConfig from "replo-runtime/store/components/Dropdown/config";
import dynamicCheckoutButtonsConfig from "replo-runtime/store/components/DynamicCheckoutButtons/config";
import feraMediaGalleryWidgetConfig from "replo-runtime/store/components/FeraMediaGalleryWidget/config";
import feraProductRatingWidgetConfig from "replo-runtime/store/components/FeraProductRatingWidget/config";
import feraProductReviewsWidgetConfig from "replo-runtime/store/components/FeraProductReviewsWidget/config";
import feraStoreReviewsWidgetConfig from "replo-runtime/store/components/FeraStoreReviewsWidget/config";
import googleMapsEmbedConfig from "replo-runtime/store/components/GoogleMapsEmbed/config";
import iconConfig from "replo-runtime/store/components/Icon/config";
import imageConfig from "replo-runtime/store/components/Image/config";
import infiniteOptionsWidgetConfig from "replo-runtime/store/components/InfiniteOptionsWidget/config";
import judgeProductRatingWidgetConfig from "replo-runtime/store/components/JudgeProductRatingWidget/config";
import judgeProductReviewsWidgetConfig from "replo-runtime/store/components/JudgeProductReviewsWidget/config";
import junipProductRatingConfig from "replo-runtime/store/components/JunipProductRating/config";
import junipReviewsConfig from "replo-runtime/store/components/JunipReviews/config";
import klaviyoEmbedConfig from "replo-runtime/store/components/KlaviyoEmbed/config";
import knoCommerceWidgetConfig from "replo-runtime/store/components/KnoCommerceWidget/config";
import looxProductRatingSummaryConfig from "replo-runtime/store/components/LooxProductRatingSummary/config";
import looxReviewsWidgetConfig from "replo-runtime/store/components/LooxReviewsWidget/config";
import marqueeConfig from "replo-runtime/store/components/Marquee/config";
import modalConfig from "replo-runtime/store/components/Modal/config";
import okendoProductRatingSummaryConfig from "replo-runtime/store/components/OkendoProductRatingSummary/config";
import okendoReviewsWidgetConfig from "replo-runtime/store/components/OkendoReviewsWidget/config";
import optionSelectConfig from "replo-runtime/store/components/OptionSelect/config";
import optionSelectDropdownConfig from "replo-runtime/store/components/OptionSelectDropdown/config";
import playerConfig from "replo-runtime/store/components/Player/config";
import postscriptSignupFormConfig from "replo-runtime/store/components/PostscriptSignupForm/config";
import productConfig from "replo-runtime/store/components/Product/config";
import productCollectionConfig from "replo-runtime/store/components/ProductCollection/config";
import rawHTMLContentConfig from "replo-runtime/store/components/RawHTMLContent/config";
import rebuyWidgetConfig from "replo-runtime/store/components/RebuyWidget/config";
import rechargeSubscriptionConfig from "replo-runtime/store/components/RechargeSubscriptionWidget/config";
import reviewsIoProductRatingSummaryConfig from "replo-runtime/store/components/ReviewsIoProductRatingSummary/config";
import reviewsIoReviewsWidgetConfig from "replo-runtime/store/components/ReviewsIoReviewsWidget/config";
import selectionListConfig from "replo-runtime/store/components/SelectionList/config";
import sellingPlanSelectConfig from "replo-runtime/store/components/SellingPlanSelect/config";
import sellingPlanSelectDropdownConfig from "replo-runtime/store/components/SellingPlanSelectDropdown/config";
import shopifyAppBlocksConfig from "replo-runtime/store/components/ShopifyAppBlocks/config";
import shopifyProductRatingWidgetConfig from "replo-runtime/store/components/ShopifyProductRatingWidget/config";
import shopifyProductReviewsWidgetConfig from "replo-runtime/store/components/ShopifyProductReviewsWidget/config";
import shopifyRawLiquidConfig from "replo-runtime/store/components/ShopifyRawLiquid/config";
import shopifySectionConfig from "replo-runtime/store/components/ShopifySection/config";
import slidingCarouselConfig from "replo-runtime/store/components/SlidingCarousel/config";
import spacerConfig from "replo-runtime/store/components/Spacer/config";
import spinnerConfig from "replo-runtime/store/components/Spinner/config";
import stampedProductRatingWidgetConfig from "replo-runtime/store/components/StampedProductRatingWidget/config";
import stampedProductReviewsWidgetConfig from "replo-runtime/store/components/StampedProductReviewsWidget/config";
import starRatingConfig from "replo-runtime/store/components/StarRating/config";
import staySubscriptionConfig from "replo-runtime/store/components/StaySubscriptionWidget/config";
import tabsBlockConfig from "replo-runtime/store/components/TabsBlock/config";
import tabsV2Config from "replo-runtime/store/components/TabsV2/config";
import temporaryCartConfig from "replo-runtime/store/components/TemporaryCart/config";
import temporaryCartItemsConfig from "replo-runtime/store/components/TemporaryCartItems/config";
import textConfig from "replo-runtime/store/components/Text/config";
import tikTokEmbedConfig from "replo-runtime/store/components/TikTokEmbed/config";
import tooltipConfig from "replo-runtime/store/components/Tooltip/config";
import variantSelectConfig from "replo-runtime/store/components/VariantSelect/config";
import variantSelectDropdownConfig from "replo-runtime/store/components/VariantSelectDropdown/config";
import vimeoEmbedConfig from "replo-runtime/store/components/VimeoEmbed/config";
import vimeoEmbedV2Config from "replo-runtime/store/components/VimeoEmbedV2/config";
import yotpoProductRatingConfig from "replo-runtime/store/components/YotpoProductRating/config";
import yotpoReviewsConfig from "replo-runtime/store/components/YotpoReviews/config";
import youtubeEmbedConfig from "replo-runtime/store/components/YoutubeEmbed/config";
import youtubeEmbedV2Config from "replo-runtime/store/components/YoutubeEmbedV2/config";

type ComponentType<T extends ReploComponentType> =
  // Extract second segment of component type
  T extends `${string}__${infer U}` ? U : T;

export type ComponentConfig = {
  /** ComponentRenderData as an optional property */
  renderData?: ComponentRenderData;
  /**
   * Some Components can be nested (ex: CarouselV3) so we enable explicit
   * relationships via the optional children property. This also lets us have
   * a single config per component even if the component file has multiple
   * sub-components.
   */
  children?: Partial<
    Record<ComponentType<ReploComponentType>, ComponentConfig>
  >;
};

/**
 * Each component can have a different set of props, so we define a generic
 * type that can be used to set those props on the component.
 */
export type ComponentRenderData = {
  customProps?: CustomPropDefinition[];
  editorProps?: EditorPropsRecord;
  newInstancesUseNumbering: boolean;
  allowsLayoutModification:
    | boolean
    | ((opts: { textValue: string | undefined }) => boolean);
  allowChildrenReorder?: boolean;
  /**
   * @returns Whether the component can accept any other component as a child.
   * @param hasTextProp Whether the component's `props.ext
   * @param potentialChildIsTemplate Whether the component which we're checking to potentially
   * add as a child is a template being dragged in from the left bar.
   *
   * Note (Ovishek, 2022-12-13): We need this hasTextProp only for button to determine whether
   * it should accept children or not, this text we actually get from component.props.text.
   * TODO (Ovishek, 2022-12-13): This is for legacy buttons only, we should have a plan of migrating those buttons
   * and replace them with new ones and get remove this exception maybe!
   */
  acceptsArbitraryChildren: (config: {
    hasTextProp: boolean;
    movementSource: ComponentMovementSource;
  }) => boolean;
  /**
   * This tells us whether the component is always dynamic.
   * For example, variant lists is always dynamic based on the ancestor product.
   */
  isAlwaysDynamic: boolean;
  /**
   * This field is the custom prop id of a component,
   * which tells us where to find dynamic data if it's involved.
   */
  dynamicItemsPropName?: string;
  /**
   * This function returns if for current component type and context,
   * it's repeating it's children with dynamic items
   */
  extractIsDynamicFromContext?(context: Context): boolean;
  canContainChildren: boolean;
  canvasIndicatorDragDirections: WidthOrHeight[];
  showComponentControlsFromChildren?: boolean;
  // NOTE (Gabe 2023-06-23): ancestorAllow is a single record because it only
  // makes sense to have a single message. If both 'product' and 'container'
  // ancestors are allowed, we can't have two separate messages, because only
  // one can be shown. It wouldn't make sense to say "This component can only be
  // used inside a product" or "This component can only be used inside a
  // container", but it would make sense to say "This component can only be used
  // inside a product or container".
  ancestorAllow?: {
    ancestorTypes: ReploComponentType[];
    message: string;
    // NOTE (Sebas, 2024-05-22): This is useful when a component can only be directly
    // nested inside another component but can't be nested within a container inside
    // the allowed type.
    directChildOnly?: boolean;
  };
  // NOTE (Sebas, 2024-05-24): This is useful when a component can only allow specific
  // types of children.
  childrenAllow?: {
    childTypes: ReploComponentType[];
    message: string;
    directChildOnly?: boolean;
  };
  // NOTE (Gabe 2023-06-23): The same is not true for disallow, because if we
  // disallow both 'product' & 'container' ancestors, we can use two separate
  // messages something like "This component cannot be used inside a product"
  // and another message that says "This component cannot be used inside a
  // container".
  ancestorDisallowList?: {
    elementTypes?: ReploElementType[];
    ancestorTypes?: ReploComponentType[];
    // NOTE (Sebas, 2024-05-20): This is useful when a component cannot be directly
    // nested inside another component but can be nested within a container inside
    // the disallowed type.
    directChildOnly?: boolean;
    message: string;
  }[];
  styleElements?: StyleElements;
  // NOTE (Evan 7/25/23) We have to store this separately (rather than just using configMenuMap)
  // to avoid a dependency issue where the config menu components are initialized before the reducers.
  showAccessibilityMenu?: boolean;
  // NOTE (Fran 2024-07-02): This is used to determine if the component should be dropped in the root
  // of the canvas.
  shouldBeRootChild?(movementSource: ComponentMovementSource): boolean;
};

export function getRenderData(
  componentType: ReploComponentType,
): AllComponentRenderDataGeneric[ReploComponentType];
export function getRenderData<T extends ReploComponentType>(
  componentType: T,
): AllComponentRenderData[T];

export function getRenderData(componentType: ReploComponentType) {
  return componentTypeToRenderData[componentType];
}

export const componentTypeToRenderData = {
  marquee: marqueeConfig.renderData,
  countdownTimer: countdownTimerConfig.renderData,
  rechargeSubscriptionWidget: rechargeSubscriptionConfig.renderData,
  staySubscriptionWidget: staySubscriptionConfig.renderData,
  okendoReviewsWidget: okendoReviewsWidgetConfig.renderData,
  okendoProductRatingSummary: okendoProductRatingSummaryConfig.renderData,
  yotpoReviews: yotpoReviewsConfig.renderData,
  yotpoProductRating: yotpoProductRatingConfig.renderData,
  looxReviews: looxReviewsWidgetConfig.renderData,
  knoCommerceWidget: knoCommerceWidgetConfig.renderData,
  looxProductRating: looxProductRatingSummaryConfig.renderData,
  reviewsIoReviews: reviewsIoReviewsWidgetConfig.renderData,
  reviewsIoProductRating: reviewsIoProductRatingSummaryConfig.renderData,
  junipReviews: junipReviewsConfig.renderData,
  junipProductRating: junipProductRatingConfig.renderData,
  symbolRef: null,
  shopifyAppBlocks: shopifyAppBlocksConfig.renderData,
  shopifyRawLiquid: shopifyRawLiquidConfig.renderData,
  shopifySection: shopifySectionConfig.renderData,
  rawHtmlContent: rawHTMLContentConfig.renderData,
  starRating: starRatingConfig.renderData,
  spinner: spinnerConfig.renderData,
  tabsBlock: tabsBlockConfig.renderData,
  tabs__onePanelContent: tabsBlockConfig.children.onePanelContent.renderData,
  tabs__panelsContent: tabsBlockConfig.children.panelsContent.renderData,
  tabs__list: tabsBlockConfig.children.list.renderData,
  tabsV2__block: tabsV2Config.renderData,
  tabsV2__panelsContent: tabsV2Config.children.panelsContent.renderData,
  tabsV2__list: tabsV2Config.children.list.renderData,
  carouselV2: carouselV2Config.renderData,
  carouselV2__panels: carouselV2Config.children.panels.renderData,
  carouselV2__indicator: carouselV2Config.children.indicator.renderData,
  carouselV3: carouselV3Config.renderData,
  carouselV3Slides: carouselV3Config.children.carouselV3Slides.renderData,
  carouselV3Control: carouselV3Config.children.carouselV3Control.renderData,
  carouselV3Indicators:
    carouselV3Config.children.carouselV3Indicators.renderData,
  temporaryCart: temporaryCartConfig.renderData,
  temporaryCartItems: temporaryCartItemsConfig.renderData,
  sellingPlanSelect: sellingPlanSelectConfig.renderData,
  variantSelectDropdown: variantSelectDropdownConfig.renderData,
  sellingPlanSelectDropdown: sellingPlanSelectDropdownConfig.renderData,
  variantSelect: variantSelectConfig.renderData,
  optionSelect: optionSelectConfig.renderData,
  optionSelectDropdown: optionSelectDropdownConfig.renderData,
  collectionSelect: collectionSelectConfig.renderData,
  collection: collectionConfig.renderData,
  collectionV2: collectionV2Config.renderData,
  googleMapsEmbed: googleMapsEmbedConfig.renderData,
  vimeoEmbed: vimeoEmbedConfig.renderData,
  vimeoEmbedV2: vimeoEmbedV2Config.renderData,
  youtubeEmbed: youtubeEmbedConfig.renderData,
  youtubeEmbedV2: youtubeEmbedV2Config.renderData,
  tikTokEmbed: tikTokEmbedConfig.renderData,
  klaviyoEmbed: klaviyoEmbedConfig.renderData,
  dropdown: dropdownConfig.renderData,
  accordionBlock: accordionBlockConfig.renderData,
  container: containerConfig.renderData,
  circle: containerConfig.renderData,
  toggleContainer: {
    ...containerConfig.renderData,
    ancestorDisallowList: [
      {
        ancestorTypes: ["marquee"],
        message: "Toggle Containers cannot be nested inside tickers.",
      },
    ],
    acceptsArbitraryChildren: () => false,
  },
  toggleIndicator: {
    ...containerConfig.renderData,
    ancestorAllow: {
      ancestorTypes: ["toggleContainer"],
      message: "Toggle Indicators can only be added inside Toggle Containers.",
    },
    acceptsArbitraryChildren: () => false,
  },
  collapsible: collapsibleConfig.renderData,
  collapsibleV2: collapsibleV2Config.renderData,
  collapsibleV2Header: {
    ...containerConfig.renderData,
    ancestorAllow: {
      ancestorTypes: ["collapsibleV2"],
      message:
        "Collapsible Headers can only be added as a direct child of Collapsibles",
      directChildOnly: true,
    },
  },
  collapsibleV2Content: {
    ...containerConfig.renderData,
    ancestorAllow: {
      ancestorTypes: ["collapsibleV2"],
      message:
        "Collapsible Content can only be added as a direct child of Collapsibles",
      directChildOnly: true,
    },
  },
  spacer: spacerConfig.renderData,
  icon: iconConfig.renderData,
  button: buttonConfig.renderData,
  carousel: carouselConfig.renderData,
  carouselPanelsCount: {
    ...containerConfig.renderData,
    ancestorAllow: {
      ancestorTypes: ["carouselV3"],
      message: "Carousel Panels Count can only be added inside Carousels.",
    },
  },
  modal: modalConfig.renderData,
  text: textConfig.renderData,
  h1: {
    newInstancesUseNumbering: false,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
  },
  h2: {
    newInstancesUseNumbering: false,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
  },
  h3: {
    newInstancesUseNumbering: false,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
  },
  image: imageConfig.renderData,
  slidingCarousel: slidingCarouselConfig.renderData,
  product: productConfig.renderData,
  productCollection: productCollectionConfig.renderData,
  quantitySelector: {
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: true,
    canContainChildren: true,
    showComponentControlsFromChildren: true,
    ancestorAllow: {
      ancestorTypes: ["product"],
      message: "Quantity Selectors can only be added inside Product boxes.",
    },
  },
  dynamicCheckoutButtons: dynamicCheckoutButtonsConfig.renderData,
  player: playerConfig.renderData,
  player__playIcon: playerConfig.children.playIcon.renderData,
  player__muteIcon: playerConfig.children.muteIcon.renderData,
  player__fullScreenIcon: playerConfig.children.fullScreenIcon.renderData,
  rebuyWidget: rebuyWidgetConfig.renderData,
  buyWithPrimeButton: buyWithPrimeButtonConfig.renderData,
  stampedProductReviewsWidget: stampedProductReviewsWidgetConfig.renderData,
  stampedProductRatingWidget: stampedProductRatingWidgetConfig.renderData,
  feraProductRatingWidget: feraProductRatingWidgetConfig.renderData,
  feraProductReviewsWidget: feraProductReviewsWidgetConfig.renderData,
  feraStoreReviewsWidget: feraStoreReviewsWidgetConfig.renderData,
  feraMediaGalleryWidget: feraMediaGalleryWidgetConfig.renderData,
  shopifyProductReviewsWidget: shopifyProductReviewsWidgetConfig.renderData,
  shopifyProductRatingWidget: shopifyProductRatingWidgetConfig.renderData,
  judgeProductRatingWidget: judgeProductRatingWidgetConfig.renderData,
  judgeProductReviewsWidget: judgeProductReviewsWidgetConfig.renderData,
  infiniteOptionsWidget: infiniteOptionsWidgetConfig.renderData,
  postscriptSignupForm: postscriptSignupFormConfig.renderData,
  tooltip: tooltipConfig.renderData,
  tooltipContent: tooltipConfig.children.tooltipContent.renderData,
  subscribeAndSave: {
    ...containerConfig.renderData,
    ancestorAllow: {
      ancestorTypes: ["product"],
      message: "Subscribe & Save can only be added inside Product boxes.",
    },
  },
  beforeAfterSlider: beforeAfterConfig.renderData,
  beforeAfterSliderThumb:
    beforeAfterConfig.children.beforeAfterSliderThumb.renderData,
  beforeAfterSliderBeforeContent:
    beforeAfterConfig.children.beforeAfterSliderBeforeContent.renderData,
  beforeAfterSliderAfterContent:
    beforeAfterConfig.children.beforeAfterSliderAfterContent.renderData,
  selectionList: selectionListConfig.renderData,
} satisfies AllComponentRenderDataGeneric;

export type AllComponentRenderDataGeneric = {
  [key in ReploComponentType]: ComponentRenderData | null;
};

export type AllComponentRenderData = typeof componentTypeToRenderData;
