import type { ConditionField } from "schemas/generated/symbol";
import type { Context, ContextKey } from "../../store/AlchemyVariable";
import type { AlchemyActionType } from "../enums";
import type {
  ProductRef,
  ProductRefOrDynamic,
  ReploShopifyProduct,
} from "../types";

import get from "lodash-es/get";
import isArray from "lodash-es/isArray";
import isPlainObject from "lodash-es/isPlainObject";
import set from "lodash-es/set";
import { canUseDOM } from "replo-utils/dom/misc";
import { isPrimitive } from "replo-utils/lib/misc";
import { shallowEqual } from "replo-utils/lib/object";

import { isContextRef } from "../../store/ReploProduct";
import { getAlchemyEditorWindow } from "../Window";

export type ComponentEditorData = {
  actions: AlchemyActionType[];
  variantTriggers: ConditionField[];
};

export const getCurrentComponentContext = (
  componentId: string | null,
  repeatedIndex: number,
): Context | undefined => {
  if (!componentId || !canUseDOM) {
    return undefined;
  }
  const editorWindow = getAlchemyEditorWindow();
  // NOTE (Chance 2024-05-29): Global window access here is OK because of the
  // canUseDOM check above. If we're in the editor, editorWindow will be defined
  // so global window is assured to be in the context of the published page.
  // biome-ignore lint/style/noRestrictedGlobals: allow window
  return (editorWindow?.alchemyEditor || window.alchemy)
    ?.componentIdToContext?.[`${componentId}.${repeatedIndex}`];
};

export const setCurrentComponentContext = (
  componentId: string,
  repeatedIndex: string,
  context: Context,
) => {
  if (!canUseDOM) {
    return;
  }
  const editorWindow = getAlchemyEditorWindow();
  // biome-ignore lint/style/noRestrictedGlobals: allow window
  const globalWindow = window;
  if (editorWindow?.alchemyEditor) {
    if (!editorWindow.alchemyEditor.componentIdToContext) {
      editorWindow.alchemyEditor.componentIdToContext = {};
    }
    editorWindow.alchemyEditor.componentIdToContext[
      `${componentId}.${repeatedIndex}`
    ] = context;
  } else if (globalWindow?.alchemy) {
    if (!globalWindow.alchemy.componentIdToContext) {
      globalWindow.alchemy.componentIdToContext = {};
    }
    globalWindow.alchemy.componentIdToContext[
      `${componentId}.${repeatedIndex}`
    ] = context;
  }
};

export const getCurrentComponentEditorData = (
  componentId: string | null,
): ComponentEditorData | undefined => {
  if (!componentId) {
    return undefined;
  }
  return getAlchemyEditorWindow()?.alchemyEditor?.componentIdToEditorData?.[
    componentId
  ];
};

export const setCurrentComponentEditorData = (
  componentId: string,
  data: ComponentEditorData,
) => {
  const editorWindow = getAlchemyEditorWindow();
  if (editorWindow) {
    if (!editorWindow.alchemyEditor.componentIdToEditorData) {
      editorWindow.alchemyEditor.componentIdToEditorData = {};
    }
    editorWindow.alchemyEditor.componentIdToEditorData[componentId] = data;
  }
};

export function resolveContextValue<Context extends object>(
  valueOrContextRef: ProductRefOrDynamic | ReploShopifyProduct | null,
  context?: Context,
): ProductRef | ReploShopifyProduct | null {
  if (!valueOrContextRef) {
    return null;
  }
  if (isContextRef(valueOrContextRef)) {
    return get(
      context,
      valueOrContextRef.ref,
      null,
    ) as ReploShopifyProduct | null;
  }
  return valueOrContextRef as ProductRef | null;
}

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object | undefined
      ? RecursivePartial<T[P]>
      : T[P];
};

/**
 * This function merges two contexts (component runtime context), as we need them
 * Since lodash merge is slow lodash merge is slow for massive objects because it recursively merges, and every
 * merge including the recursive ones reallocate a lot of memory, especially for our contexts which include many
 * functions (not just primitives like integers and strings)
 */
export const mergeContext = <A extends Context, B extends RecursivePartial<A>>(
  a: A,
  b: B,
): A & B => {
  let contextChanged = false;
  let context = a as A & B;

  /**
   * Updates the context with the given path and value and only creates a new
   * object reference if the value is different and the context has not already
   * been updated.
   */
  const setContextValue = (path: string, value: unknown) => {
    if (Object.is(get(context, path), value)) {
      return;
    }
    if (!contextChanged) {
      contextChanged = true;
      context = { ...context };
    }
    set(context, path, value);
  };

  const keysByLevel: [ContextKey, number][] = [
    ["actionHooks", 1],
    ["variantTriggers", 1],
    ["attributes", 1],
    ["attributeKeyToComponentId", 1],
    ["group", 2],
    ["state", 3],
    ["componentOverrides", 2],
  ];

  if (!a) {
    return b as A & B;
  }

  const isPrimitiveLikeNonObject = (value: any) => {
    return isArray(value) || isPrimitive(value);
  };

  const mergeSimple = (key: string) => {
    const leftHandValue = get(a, key);
    const rightHandValue = get(b, key);
    if (!b) {
      return a;
    }

    if (isPrimitiveLikeNonObject(rightHandValue)) {
      return rightHandValue;
    }

    if (isPlainObject(b)) {
      // NOTE (Chance 2024-05-02): We can avoid making a copy of the object if
      // the values are effectively the same. This is a performance optimization
      // because this function is primarily called in runtime components and we
      // don't want to create a new object every time we merge contexts if a
      // render isn't necessary, as render is often more expensive than the
      // comparison.
      if (shallowEqual(leftHandValue, rightHandValue)) {
        return rightHandValue;
      }
      return { ...leftHandValue, ...rightHandValue };
    } else if (isPlainObject(a)) {
      return a;
    }
    return b;
  };

  const mergeByLevel = (key: string, level: number) => {
    if (level === 1) {
      return mergeSimple(key);
    }
    let leftHandValue = get(a, key);
    const rightHandValue = get(b, key);
    if (isPrimitiveLikeNonObject(rightHandValue)) {
      return rightHandValue;
    }
    if (isPlainObject(rightHandValue)) {
      const rightHandKeys = Object.keys(rightHandValue);
      if (rightHandKeys.length === 0) {
        return leftHandValue ?? rightHandValue;
      }

      // NOTE (Chance 2024-05-02): Again, we don't want to create a new object
      // if nothing has actually changed here. We can track changes as we
      // iterate through the keys, and only make a copy if necessary.
      let changed = false;
      if (!leftHandValue) {
        changed = true;
        leftHandValue = {};
      }

      for (let i = 0; i < rightHandKeys.length; i++) {
        const secondKey = rightHandKeys[i]!;
        const finalKey = `${key}.${secondKey}`;
        const value = mergeByLevel(finalKey, level - 1);
        if (shallowEqual(leftHandValue[secondKey], value)) {
          continue;
        } else {
          if (!changed) {
            changed = true;
            leftHandValue = { ...leftHandValue };
          }
          leftHandValue[secondKey] = value;
        }
      }

      return leftHandValue;
    } else if (isPlainObject(leftHandValue)) {
      return leftHandValue;
    }
    return rightHandValue;
  };

  for (let i = 0; i < keysByLevel.length; i++) {
    const [key, level] = keysByLevel[i]!;
    const mergedValue = mergeByLevel(key, level);
    if (mergedValue === undefined) {
      continue;
    }

    setContextValue(key, mergedValue);
  }

  type ValueType = "array" | "object";
  const differentLevelMerge: [string, ValueType][] = [
    ["actionHooks.componentIdToVariantSetters", "object"],
  ];

  for (const pair of differentLevelMerge) {
    const path = pair[0]!;
    const type = pair[1];
    const leftHand = get(a, path);
    const rightHand = get(b, path);

    if (!rightHand) {
      continue;
    }

    switch (type) {
      // we replace if they are array
      case "array":
        setContextValue(path, rightHand);
        break;
      case "object": {
        const value = Object.is(leftHand, rightHand)
          ? rightHand
          : { ...leftHand, ...rightHand };
        setContextValue(path, value);
        break;
      }

      default:
        break;
    }
  }

  const keys = Object.keys(b);
  for (const key of keys) {
    const rightValue = (b as any)[key];
    const isTakenCareOf = keysByLevel.find(
      ([keyByLevel]) => keyByLevel === key,
    );
    if (!isTakenCareOf && rightValue != null) {
      setContextValue(key, rightValue);
    }
  }

  return context;
};
