import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

export const useComponentTemplateCollectionsOptions = () => {
  const projectId = useCurrentProjectId();

  const { data } = trpc.componentTemplates.collections.get.useQuery(
    projectId ? { storeId: projectId, includeEmpty: true } : skipToken,
  );

  if (data?.collections) {
    return {
      options: data.collections.map((collection) => ({
        label: collection.name,
        value: collection.id,
        isSelectable: true,
      })),
    };
  }

  return { options: [] };
};
