import * as React from "react";

import { App } from "@editor/App";
import SentryBoundary from "@editor/components/common/SentryBoundary";
import {
  initializeAnalytics,
  initializeErrorTracking,
  initializePerformanceMonitoring,
} from "@editor/infra/analytics";
import { store } from "@editor/store";
import { TRPCProvider } from "@editor/utils/trpc";

import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";

initializeErrorTracking();
initializeAnalytics();
initializePerformanceMonitoring();

const root = createRoot(document.querySelector("#root") as HTMLElement);

root.render(
  <SentryBoundary>
    <ReduxProvider store={store}>
      <TRPCProvider>
        <App />
      </TRPCProvider>
    </ReduxProvider>
  </SentryBoundary>,
);
