import type { Component } from "../../../shared/Component";
import type {
  RenderComponentAttributes,
  RenderComponentProps,
} from "../../../shared/types";
import type { Context } from "../../AlchemyVariable";

import * as React from "react";

import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
} from "body-scroll-lock-upgrade";
import { default as ReactModal } from "react-modal";

import snippetStyles from "../../../scss/snippet.scss?inline";
import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  ReploEditorCanvasContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { executeAction } from "../../AlchemyAction";
import useSharedState from "../../hooks/useSharedState";
import { modalMountPointQuerySelector } from "../../utils/cssSelectors";
import { ReploComponent } from "../ReploComponent";

let lastWindowPageOffset = 0;
const Modal: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
}) => {
  const { repeatedIndexPath } = context;
  const closeModalOnOutsideClick = component.props._closeModalOnOutsideClick;
  const overlayColor = component.props._overlayColor;
  const {
    _isOpen,
    modalBodyProps,
    shouldBeVisible,
    topOffset,
    shouldRenderEditorPositioning,
    overlayRef,
  } = useReploRuntimeModalDetails({
    component,
    context,
    attributes: componentAttributes,
    repeatedIndexPath,
    closeModalOnOutsideClick,
    lastWindowPageOffset,
  });
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  return (
    <ReactModal
      // Note (Noah, 2024-10-08): If we focus after render in the editor canvas,
      // then immediately after clicking on a popup component, copying that
      // popup component won't work
      shouldFocusAfterRender={!isEditorCanvas}
      ariaHideApp={false}
      // biome-ignore lint/suspicious/noAssignInExpressions: allow assign in expression in parens
      overlayRef={(node) => (overlayRef.current = node)}
      overlayElement={(overlayProps, contentElement) => (
        <div {...overlayProps} data-rid={component.id}>
          {shouldBeVisible && contentElement}
        </div>
      )}
      style={{
        /* Begun the Z-Index War Has! - Yoda https://www.youtube.com/watch?v=dylqDO4uEXc */
        overlay: {
          zIndex: 10_000_001,
          width: "100vw",
          display: "block",
          pointerEvents: shouldBeVisible ? "auto" : "none",
          backgroundColor: shouldBeVisible
            ? String(overlayColor) || "rgba(255, 255, 255, 0.75)"
            : "transparent",
        },
        content: {
          padding: 0,
          zIndex: 10_000_001,
          top: topOffset,
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: `translate(-50%, ${
            shouldRenderEditorPositioning ? "0" : "-50%"
          })`,
          position: "absolute",
          border: "none",
          letterSpacing: "0px",
          backgroundColor: "transparent",
          maxWidth: "100vw",
          // Note (Noah, 2022-10-20): If we're in the editor edit mode, constrict the
          // max height of the modal content so that it acts like it's 100% of a relatively
          // tall screen. Combined with the topOffset, this means that the modal appears
          // roughly the size it should be, wherever you've panned to on the canvas
          maxHeight: isEditorCanvas ? "1000px" : "100%",
          width: "100%",
          height: "100%",
        },
      }}
      parentSelector={() => {
        return (
          globalWindow?.document.querySelector(modalMountPointQuerySelector) ||
          (globalWindow?.document.body as HTMLElement)
        );
      }}
      isOpen={_isOpen}
      contentLabel="Modal"
      onAfterOpen={() => {
        lastWindowPageOffset = globalWindow?.scrollY ?? 0;
        if (globalWindow?.document) {
          const modalBody = modalBodyProps.modalBodyRef.current;
          if (!modalBody) {
            return;
          }
          globalWindow.document.body.classList.add("replo-modal-after-open");
          // NOTE (Gabe 2023-08-25, USE-384): We have to apply
          // disableBodyScroll on the scrollable div, not an ancestor of it.
          // More context here:
          // https://github.com/willmcpo/body-scroll-lock/issues/102
          const firstDescendantWithOverflowY = findFirstDescendantWithOverflowY(
            modalBody,
          ) as HTMLElement | null;

          disableBodyScroll(
            firstDescendantWithOverflowY ??
              // If we don't find a scrollable descendent we still need to
              // disableBodyScroll.
              modalBody,
            // NOTE (Chance 2023-10-25, USE-495): In the
            // `body-scroll-lock-upgrade` package, `disableBodyScroll` calls
            // `preventDefault` on touchmove events by default. This breaks
            // scrolling on nested elements in iOS (unclear to me why it doesn't
            // seem to do this on android). This PR allows us to skip that for
            // events triggered on elements inside the modal body.
            // https://github.com/rick-liruixin/body-scroll-lock-upgrade/blob/v1.0.4/src/body-scroll-lock.ts#L70-L76
            { allowTouchMove: (target) => modalBody.contains(target as Node) },
          );
        }
      }}
      onAfterClose={() => {
        if (globalWindow?.document) {
          globalWindow.document.body.classList.remove("replo-modal-after-open");
          clearAllBodyScrollLocks();
        }
      }}
    >
      <ModalBody {...modalBodyProps} />
    </ReactModal>
  );
};

export default Modal;

interface ModalBodyProps {
  attributes: RenderComponentAttributes;
  component: Component;
  context: Context;
  repeatedIndexPath: string;
  closeModalOnOutsideClick?: boolean;
  modalBodyRef: React.RefObject<HTMLDivElement>;
}

function ModalBody({
  attributes,
  component,
  context,
  repeatedIndexPath,
  closeModalOnOutsideClick,
  modalBodyRef,
}: ModalBodyProps) {
  const { templateProduct } = useRuntimeContext(ShopifyStoreContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        ref={modalBodyRef}
        style={attributes.style}
        className={attributes.className}
        // TODO (Noah, 2023-06-08): This attribute is here to serve as a target selector for
        // styles, since Modal is the only component which adds styles to a different
        // DOM element than it adds the data-rid attribute to. We should standardize this
        data-replo-modal-body
        data-testid="popup-component"
        onClick={(e) => {
          e.stopPropagation();

          if (closeModalOnOutsideClick && e.target === modalBodyRef.current) {
            void executeAction(
              {
                type: "closeModalComponent",
                id: "onRequestClose",
                value: null,
              },
              {
                componentId: component.id,
                componentContext: context,
                repeatedIndex: repeatedIndexPath,
                products,
                templateProduct,
              },
            );
          }
        }}
      >
        {(component.children ?? []).map((child) => {
          return (
            <ReploComponent
              key={child.id}
              component={child}
              context={context}
              repeatedIndexPath={repeatedIndexPath ?? ".0"}
            />
          );
        })}
      </div>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: snippetStyles,
        }}
      />
    </div>
  );
}

interface UseReploRuntimeModalDetailsProps {
  component: Component;
  repeatedIndexPath: string;
  context: Context;
  attributes: RenderComponentAttributes;
  closeModalOnOutsideClick: boolean | undefined;
  lastWindowPageOffset: number;
}

function useReploRuntimeModalDetails({
  component,
  repeatedIndexPath,
  context,
  attributes,
  closeModalOnOutsideClick,
  lastWindowPageOffset,
}: UseReploRuntimeModalDetailsProps) {
  const [isVisible] = useSharedState([component.id, "isVisible"], true);
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const isPreviewMode = isEditorApp && !isEditorCanvas;
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const { editorCanvasYOffset, editorCanvasScale } = useRuntimeContext(
    ReploEditorCanvasContext,
  );
  const editorOverrideOpenModalId =
    useRuntimeContext(RuntimeHooksContext).useEditorOverrideOpenModalId();
  const shouldRenderEditorPositioning = isEditorCanvas;

  let topOffset = "50%";
  if (shouldRenderEditorPositioning) {
    topOffset = `${
      -Math.min(0, (editorCanvasYOffset ?? 50) / (editorCanvasScale ?? 0.9)) +
      150
    }px`;
  }

  const _isOpen = React.useMemo(() => {
    if (!isEditorCanvas) {
      const isOpenId = context.openModalComponent?.id === component.id;

      const openRepeatedIndex = context.openModalComponent?.repeatedIndex;
      const thisRepeatedIndex = repeatedIndexPath;

      if (openRepeatedIndex) {
        // HACK (Noah, 2021-08-27): Currently repeated indexes work as a dot-separated
        // integer path. We want to render this modal only if the component it was opened
        // from isn't in a different branch of repeated-ness - otherwise, we'd be
        // rendering the wrong repeated instance of the modal. However we support
        // opening modals above _and_ below this in the tree, so we check startsWith
        // in both orders
        return (
          (openRepeatedIndex.startsWith(thisRepeatedIndex) ||
            thisRepeatedIndex.startsWith(openRepeatedIndex)) &&
          isOpenId
        );
      }
      return isOpenId;
    }

    // Note (Noah, 2022-04-27, REPL-1746): If we're in the editor, then only render
    // the first repetition of the modal. This ensures that we don't render 16 modals
    // if the modal happens to be in a data collection repeater with 16 items. In the
    // future, we might want to support having a preview of a specific repetition in
    // the editor, but right now we just always show the first one
    const repeatedIndexPathIsAllZeros = /^(\.0)*$/.test(
      repeatedIndexPath ?? "",
    );

    return (
      repeatedIndexPathIsAllZeros && editorOverrideOpenModalId === component.id
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEditorCanvas,
    context.openModalComponent,
    repeatedIndexPath,
    component,
    editorOverrideOpenModalId,
  ]);

  // Note (Noah, 2024-03-19, REPL-10980): In the editor, we want the modal to initially
  // show up positioned in the center of wherever in the canvas you were looking at. But,
  // we only want this positioning to change when you re-open the modal. So in the editor,
  // we use an extra state which is only updated when isOpen changes.
  const [topOffsetForEditor, setTopOffsetForEditor] = React.useState(topOffset);
  const _isOpenRef = React.useRef(_isOpen);
  React.useEffect(() => {
    if (_isOpenRef.current !== _isOpen) {
      setTopOffsetForEditor(topOffset);
    }
    _isOpenRef.current = _isOpen;
  }, [_isOpen, topOffset]);

  const overlayRef = React.useRef<HTMLElement | null>(null);
  const modalBodyRef = React.useRef<HTMLDivElement | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: missing deps globalWindow, lastWindowPageOffset
  React.useEffect(() => {
    if (!_isOpen && overlayRef.current) {
      // On iOS we might have made the body fixed, which scrolled it up. We have
      // to scroll back down
      const window = globalWindow;
      if (window && window.scrollY !== lastWindowPageOffset) {
        window.scrollTo(0, lastWindowPageOffset);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_isOpen]);

  const shouldBeVisible = isVisible || isPreviewMode;

  const modalBodyProps: ModalBodyProps = {
    attributes,
    component,
    context,
    repeatedIndexPath,
    closeModalOnOutsideClick,
    modalBodyRef,
  };

  return {
    topOffset: shouldRenderEditorPositioning ? topOffsetForEditor : topOffset,
    shouldBeVisible,
    modalBodyProps,
    _isOpen,
    shouldRenderEditorPositioning,
    overlayRef,
  };
}

function findFirstDescendantWithOverflowY(parentElement: HTMLElement) {
  const globalWindow = parentElement.ownerDocument.defaultView;
  for (const descendant of parentElement.querySelectorAll("*")) {
    if (descendant) {
      const computedStyle = globalWindow?.getComputedStyle(descendant);
      if (
        (computedStyle?.overflowY === "auto" ||
          computedStyle?.overflowY === "scroll") &&
        descendant.scrollHeight > descendant.clientHeight
      ) {
        return descendant;
      }
    }
  }
  return null;
}
