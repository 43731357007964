import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useInterval } from "replo-utils/react/use-interval";

import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { firstGroupMatchOfExpression } from "../../../shared/utils/regex";
import { RenderComponentPlaceholder } from "../RenderComponentPlaceholder";

/**
 * EXTREME HACK (Noah, 2021-08-03, 2022-03-20): I would love to just render a
 * <div class="klaviyo-embed-123"></div> here like Klaviyo's embed documentation
 * says, but there's a problem:
 *
 * Klaviyo's js only embeds for those divs which are present when their script
 * loads, which means embeds are completely broken for all SPAs, which includes
 * us because we render dynamically
 * https://community.klaviyo.com/custom-integrations-29/implement-embed-klaviyo-forms-in-spa-apps-like-gatsbyjs-reactjs-808
 *
 * I.e., if we have a <div id="klaviyo-form-123"> in the page when it loads, Klaviyo
 * will correctly fill that with the embed HTML. However, after that initial fill,
 * Klaviyo will never update that element again (I've tried messing with Klaviyo internals,
 * deleting and re-adding the Klaviyo script, etc - maybe there's a way but if there
 * is it's somewhere deep within Klaviyo that we can't access). There's no way to tell
 * Klaviyo to update the embed when our props change.
 *
 * So, we render the recommended Klaviyo embed div during publishing (which makes published
 * pages work correctly), and during the times when we know we can never render the embed again
 * without a reload (directly after publishing and when the embed code has changed), we render a
 * placeholder in the editor.
 *
 * Note (Noah, 2022-03-20, REPL-1315): We used to have to do a way bigger hack where
 * we would grab the Klaviyo script source from a script in the <head> and transpose
 * it into an iframe, because Klaviyo didn't support shadow DOMs, but now that none
 * of our elements are rendered in a shadow DOM this is is luckily a thing of the past.
 *
 * (Shouldn't Klaviyo just embed using an iframe anyway? Yes they should, but
 * they don't. Maybe when we're worth 1B we can partner with them and get access
 * to a JS API that works better)
 */

const KlaviyoEmbed: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
}) => {
  const embedCode = component.props._embedCode;
  const loadIndicatorImage = component.props._loadIndicatorImage;
  const { isEditorApp, isPublishing } = useRuntimeContext(
    RenderEnvironmentContext,
  );

  const [initialEmbedCode] = React.useState<string>(embedCode ?? "");

  // Note (Noah, 2022-02-20): Track whether we've ever been published since
  // mounting - if so, we have to display a placeholder message since we got
  // rid of the klaviyo div's content during publishing, but since Klaviyo doesn't
  // support dynamic refresh of embeds, there's no way to get it back :(
  const hasBeenPublishedThisMount = React.useRef<boolean>(false);
  if (isPublishing) {
    hasBeenPublishedThisMount.current = true;
  }

  const identifier = firstGroupMatchOfExpression(
    /(klaviyo-form-[\dA-z].+)"/g,
    embedCode ?? "",
  );

  const isKlaviyoLoading = useIsKlaviyoLoading();

  if (
    (isEditorApp && !embedCode) ||
    embedCode !== initialEmbedCode ||
    (!isPublishing && hasBeenPublishedThisMount.current)
  ) {
    let renderPlaceholderText: string | null = null;
    if (!identifier) {
      renderPlaceholderText =
        "Once you set your Klayvio embed code in the Config tab, your embedded Klaviyo Form will appear here.";
    } else if (identifier && embedCode !== initialEmbedCode) {
      renderPlaceholderText =
        "Please refresh the page to view the updated Klaviyo form.";
    } else if (hasBeenPublishedThisMount.current) {
      renderPlaceholderText =
        "Please refresh the page to view the published Klaviyo form.";
    }

    return (
      <>
        <div {...componentAttributes}>
          <RenderComponentPlaceholder title={renderPlaceholderText} />
        </div>
      </>
    );
  }

  return (
    <div
      {...componentAttributes}
      style={{
        position: "relative",
        ...componentAttributes.style,
      }}
    >
      {loadIndicatorImage && isKlaviyoLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...loadIndicatorImage.style,
          }}
        >
          <img src={loadIndicatorImage.src} />
        </div>
      )}
      {/*
      Note (Ovishek/Noah, 2022-11-15, REPL-5052, REPL-5046) How Klaviyo works is
      it looks for an empty div with class="klaviyo-form-xxx" when its javascript loads, exactly
      once. We render this empty div in replo-publisher when the page is publisehd. That means
      that if the Klaviyo javascript loads before our script loads:

      1. The form will show up (since Klaviyo's js found the server-rendered div)
      2. Then our javascript will run and the page will hydrate
      3. React will find a div that it thinks is supposed to be empty but isn't and it will
         clear the div (yes, React does that during hydration apparently)
      4. The klaviyo form will disappear, never to return, since Klaviyo's js will never
         load on the page again until a refresh.

      To fix this, we take advantage of the fact that React's hydration will not clear the
      div content if its dangerouslySetInnerHTML attribute is the same as what was rendered
      on the server AND supressHydrationWarning is true. We set dangerouslySetInnerHTML to
      empty string, which basically tells React to never do anything with this div - this
      makes the Klaviyo form persist.

      The real way to do this would be to trigger Klaviyo to populate the form dynamically
      inside a useEffect or something, but unfortunately Klaviyo doesn't provide that API.

      https://github.com/facebook/react/issues/8017#issuecomment-413871768
      https://replohq.slack.com/archives/C01JYU70754/p1668455513687799
      */}
      <div
        className={identifier ?? undefined}
        style={isKlaviyoLoading ? { display: "none" } : {}}
        dangerouslySetInnerHTML={{ __html: "" }}
        suppressHydrationWarning
      />
    </div>
  );
};

export default KlaviyoEmbed;

/**
 * A custom hook that checks if Klaviyo is loaded and ready in the Alchemy Paintable Window.
 * It uses an interval to periodically check for the presence of the Klaviyo-related properties
 * in the window object. The interval runs every 100ms until Klaviyo is detected.
 *
 * @returns A boolean value indicating if Klaviyo is still loading (true) or ready (false).
 */
function useIsKlaviyoLoading(): boolean {
  const [isLoading, setIsLoading] = React.useState(true);
  const globalWindow = useRuntimeContext(GlobalWindowContext);

  function checkKlaviyoStatus() {
    return (
      globalWindow?._learnq !== undefined ||
      globalWindow?.KlaviyoSubscribe !== undefined
    );
  }

  useInterval(
    () => {
      if (checkKlaviyoStatus()) {
        setIsLoading(false);
      }
    },
    isLoading ? 300 : null,
  );

  return isLoading;
}

declare global {
  interface Window {
    _learnq?: unknown;
    KlaviyoSubscribe?: unknown;
  }
}
