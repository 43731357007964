import type { BorderSuffix } from "replo-runtime/shared/styleAttribute";

import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";

import startCase from "lodash-es/startCase";
import { allCSSSideNames } from "replo-runtime/shared/utils/css";

export type BorderProperties = "Color" | "Width" | "Style";

export function createAllSidesBorderProperty(
  property: BorderProperties,
  value: string | null,
) {
  const style: Record<string, string | null> = {};

  allCSSSideNames("border", property, { includeShorthand: false }).forEach(
    (key) => {
      style[key] = value;
    },
  );
  // Note (Noah, 2022-08-08): There's an issue with borderStyle where many
  // components on production have borderStyle set, which overrides the
  // indivdual border side properties. We should run some sort of migration
  // to remove these, but for now we always set the shorthand property to
  // none when we set all sides.
  style[`border${startCase(property)}`] = null;

  return style;
}

export function prepareBorderStyles(
  activeSide: BorderSuffix | null,
  width: string | null = styleAttributeToEditorData.borderWidth.defaultValue,
  color: string | null = styleAttributeToEditorData.borderColor.defaultValue,
  borderStyle: string | null = styleAttributeToEditorData.borderStyle
    .defaultValue,
) {
  const borderStyles =
    activeSide !== ""
      ? {
          [`border${activeSide}Width`]: width,
          [`border${activeSide}Color`]: color,
          [`border${activeSide}Style`]: borderStyle,
        }
      : {
          ...createAllSidesBorderProperty("Width", width),
          ...createAllSidesBorderProperty("Color", color),
          ...createAllSidesBorderProperty("Style", borderStyle),
        };

  return borderStyles;
}
