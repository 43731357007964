import type { BillingStripeNotificationModalProps } from "../AppModalTypes";

import * as React from "react";

import Button from "@common/designSystem/Button";
import Modal from "@editor/components/common/designSystem/Modal";
import { useModal } from "@editor/hooks/useModal";

export const BillingStripeNotificationModal: React.FC<
  BillingStripeNotificationModalProps
> = ({ buttonText, onConfirm }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const modal = useModal();

  const closeModal = () => {
    modal.closeModal({ type: "billingStripeNotificationModal" });
  };
  const onClick = () => {
    setIsLoading(true);
    onConfirm();
  };

  return (
    <Modal
      isOpen
      className="w-[600px]"
      includesCloseIcon
      data-testid="billing-plan-confirmation-modal"
      onRequestClose={closeModal}
    >
      <div className="flex flex-col justify-between mt-8 ">
        <div className="flex flex-col pb-12 px-4">
          <div className="flex flex-col gap-4">
            <h2 className="text-xl font-semibold">
              Welcome to a New Billing Portal
            </h2>
            To enhance your Replo experience, we&apos;ve transitioned to a new
            billing portal that offers:
            <ul className="list-disc pl-5 space-y-2">
              <li>
                <span className="font-bold">Enhanced Security</span> - Payment
                information is safer
              </li>
              <li>
                <span className="font-bold">Full Control</span> - Easily upgrade
                or downgrade
              </li>
              <li>
                <span className="font-bold">Clear Visibility</span> - Understand
                exactly how and when you are billed
              </li>
              <li>
                <span className="font-bold">Direct Support</span> - Work
                directly with the Replo team for any billing issues
              </li>
            </ul>
            Please Note: You&apos;ll need to input your payment information
            again in the new portal. Your current plan billed through Shopify
            will be discontinued and a prorated refund will be issued.
          </div>
        </div>

        <div className="flex flex-row justify-end">
          <Button
            type="primary"
            size="lg"
            onClick={onClick}
            isLoading={isLoading}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
