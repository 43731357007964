import type {
  ContextRef,
  ProductMetafieldMapping,
  ProductRef,
  ProductRefOrDynamic,
  ProductStatus,
  ReploShopifyProduct,
  StoreProduct,
  VariantMetafieldMapping,
} from "../shared/types";

import { exhaustiveSwitch, hasOwnProperty } from "replo-utils/lib/misc";
import { isObjectLike } from "replo-utils/lib/type-check";

import { mapStoreProductToReploProduct } from "../shared/mappers/product";
import { resolveContextValue } from "../shared/utils/context";
import { getRandomProductId } from "../shared/utils/product";

// NOTE (Chance 2024-04-23): It may be worth considering using a zod schema
// here. Not sure we want that much overhead since we use these in React
// components that could already be perf-sensitive. These may not check for
// every potential property but should be good enough in most cases.

export const isProductRef = (
  value: ProductRefOrDynamic,
): value is ProductRef => {
  return !isContextRef(value);
};

export const isContextRef = (value: unknown): value is ContextRef => {
  return (
    isObjectLike(value) &&
    hasOwnProperty(value, "type") &&
    value.type === "contextRef" &&
    hasOwnProperty(value, "ref")
  );
};

/**
 * Types of ways we can grab a default product during ref resolution if the product is not found.
 *
 * Note (Noah, 2022-05-07): This is just one case right now, we can turn it into a union type when
 * we have more fallback strategies
 */
export type GetProductFallbackStrategy =
  | { type: "nthProduct"; defaultIndex: number }
  | { type: "defaultProduct" };

export type ProductResolutionDependencies = {
  products: StoreProduct[];
  currencyCode: string;
  moneyFormat: string | null;
  language: string;
  fakeProducts?: StoreProduct[] | null;
  templateProduct: StoreProduct | null;
};

export interface ProductResolutionConfig {
  isEditor?: boolean;
  fallbackStrategy?: GetProductFallbackStrategy | null;
  isInsideTemplateProductComponentOverride?: boolean | null;
  fakeProducts?: StoreProduct[] | null;
  productMetafieldValues: ProductMetafieldMapping;
  variantMetafieldValues: VariantMetafieldMapping;
  products: StoreProduct[];
  templateProduct: StoreProduct | null;
  currencyCode: string;
  moneyFormat: string | null;
  language: string;
}

const EMPTY_PRODUCT_REF = {
  productId: 0,
  variantId: 0,
  id: 0,
  status: "ACTIVE" as ProductStatus,
};

/**
 * Transform a ProductRef (a regular one, or a dynamic data ref) into a fully formed
 * ReploShopifyProduct. If the product is not found, a fallback strategy can be provided
 * to try to return a default. Returns null if no product is found and no default can be determined.
 *
 * @param productRef Ref to transform
 * @param context Context of the component for which to transform the ref (used to resolve dynamic refs)
 * @param config Configuration for the product transformation
 */
export function getProduct<
  Context extends {
    isInsideTemplateProductComponent?: boolean;
  },
>(
  productRef: ProductRefOrDynamic | null,
  context: Context | null,
  config: ProductResolutionConfig,
): ReploShopifyProduct | null {
  const {
    currencyCode,
    products,
    templateProduct,
    isInsideTemplateProductComponentOverride,
    productMetafieldValues,
    variantMetafieldValues,
    fallbackStrategy,
    isEditor,
    fakeProducts,
    moneyFormat,
    language,
  } = config;

  // Note (Noah, 2022-05-07): If resolvedProductRef is nullish, set it to an invalid product
  // ref so that we'll fall through and use the fallbackStrategy, if provided, at the
  // end of this function
  const resolvedProductRef =
    resolveContextValue(productRef, context ?? undefined) ?? EMPTY_PRODUCT_REF;
  if (
    !isProductRef(resolvedProductRef) ||
    resolvedProductRef?.productId == null
  ) {
    return null;
  }

  const { productId, variantId } = resolvedProductRef;

  let isInsideTemplateProductComponent = false;
  if (isInsideTemplateProductComponentOverride != null) {
    isInsideTemplateProductComponent = isInsideTemplateProductComponentOverride;
  } else if (context?.isInsideTemplateProductComponent != null) {
    isInsideTemplateProductComponent = context.isInsideTemplateProductComponent;
  }

  if (isInsideTemplateProductComponent && templateProduct) {
    return mapStoreProductToReploProduct(templateProduct, {
      quantity: resolvedProductRef.quantity ?? 1,
      metafields: {
        productMetafieldsMapping: productMetafieldValues,
        variantMetafieldsMapping: variantMetafieldValues,
      },
      currencyCode,
      moneyFormat,
      language,
    });
  }
  // NOTE (Gabe 2024-02-01): make a shallow copy so we don't mutate the original
  // product array passed in.
  const productsToCheck = [...products];

  if (fakeProducts) {
    // Note (Noah, 2023-12-31): Important to also check fake products here,
    // e.g. for e2e tests where we don't actually create shopify pages
    productsToCheck.push(...fakeProducts);
  }

  for (const product of productsToCheck) {
    if (String(product.id) === String(productId)) {
      if (!variantId) {
        return mapStoreProductToReploProduct(product, {
          quantity: resolvedProductRef.quantity ?? 1,
          metafields: {
            productMetafieldsMapping: productMetafieldValues,
            variantMetafieldsMapping: variantMetafieldValues,
          },
          currencyCode,
          moneyFormat,
          language,
        });
      }

      for (const variant of product.variants) {
        if (String(variant.id) === String(variantId)) {
          return mapStoreProductToReploProduct(product, {
            variantId: variantId ?? null,
            quantity: resolvedProductRef.quantity ?? 1,
            metafields: {
              productMetafieldsMapping: productMetafieldValues,
              variantMetafieldsMapping: variantMetafieldValues,
            },
            currencyCode,
            moneyFormat,
            language,
          });
        }
      }
    }
  }

  // Note (Noah, 2023-08-18, USE-362): If the variant is not found, try to look
  // for a matching product id and use that. This ensures that if the user selected
  // a product then deleted the variant that was there when it was selected, that
  // we can still find the product
  for (const product of productsToCheck) {
    if (String(product.id) === String(productId)) {
      return mapStoreProductToReploProduct(product, {
        quantity: resolvedProductRef.quantity ?? 1,
        metafields: {
          productMetafieldsMapping: productMetafieldValues,
          variantMetafieldsMapping: variantMetafieldValues,
        },
        currencyCode,
        moneyFormat,
        language,
      });
    }
  }

  // Note (Mariano, 2022-09-02): We need this hack for displaying hardcoded
  // products, useful mostly for templates
  const fakeProduct = fakeProducts?.find(
    (fakeProduct) => Number(fakeProduct.id) === Number(productId),
  );

  if (fakeProduct) {
    return mapStoreProductToReploProduct(fakeProduct, {
      variantId: variantId ?? null,
      quantity: resolvedProductRef.quantity ?? 1,
      metafields: {
        productMetafieldsMapping: productMetafieldValues,
        variantMetafieldsMapping: variantMetafieldValues,
      },
      currencyCode,
      moneyFormat,
      language,
    });
  }

  // Note (Noah, 2024-01-30, USE-695): Don't log this warning if we're about to
  // fall back to an empty product (this happens e.g. when we have an empty product
  // in a product component, then try to access it again in a child)
  if (Number(productId) !== Number(EMPTY_PRODUCT.id)) {
    if (Number(productId) === Number(EMPTY_PRODUCT_REF.id)) {
      console.warn(
        "[Replo] Unable to resolve product. Products may be incorrectly configured, the page might reference products which have been deleted or removed from the Online Store sales channel, or the page may be referencing Replo placeholder products.",
      );
    } else {
      console.warn(
        "[Replo] Unable to find product. The page may look incorrect, or product interactions may not work correctly. This product may have been deleted, moved to draft, or removed from the Online Store sales channel.",
        productId,
        variantId,
        fallbackStrategy,
      );
    }
  }

  // Note (Noah, 2022-05-06): If we couldn't find the product but we've got a
  // fallback strategy, that means we should try the strategy to return something
  // if we can
  if (fallbackStrategy) {
    return exhaustiveSwitch(fallbackStrategy)({
      nthProduct: ({ defaultIndex }) => {
        if (products.length > 0) {
          // Note (Noah, 2022-04-20): If we're rendering in a component preview and we
          // have an empty product, by default use the first one so the preview doesn't
          // look empty
          const effectiveIndex =
            products.length <= defaultIndex
              ? products.length - 1
              : defaultIndex;

          return getProduct(
            {
              productId: products[effectiveIndex]!.id,
              variantId:
                products[effectiveIndex]!.variants.length > 0
                  ? products[effectiveIndex]!.variants[0]!.id
                  : undefined,
              id: getRandomProductId(),
            },
            context,
            {
              productMetafieldValues,
              variantMetafieldValues,
              products,
              currencyCode,
              moneyFormat,
              language,
              templateProduct,
            },
          );
        }
        return null;
      },
      defaultProduct: () => {
        return mapStoreProductToReploProduct(
          isEditor || process.env.IS_TESTING
            ? fakeProducts?.[0] ?? EMPTY_PRODUCT
            : EMPTY_PRODUCT,
          {
            // Note (Noah, 2024-03-17): Okay to pass quantity = 1 for the empty product,
            // since this is a fallback anyways for when we can't find the product
            quantity: 1,
            metafields: {
              productMetafieldsMapping: productMetafieldValues,
              variantMetafieldsMapping: variantMetafieldValues,
            },
            currencyCode,
            moneyFormat,
            language,
          },
        );
      },
    });
  }
  return null;
}

const EMPTY_PRODUCT: StoreProduct = {
  id: 7_519_341_183_114,
  title: "",
  description: "",
  handle: "empty-product",
  status: "ACTIVE",
  type: "",
  images: [],
  variants: [
    {
      id: 42_241_497_071_754,
      sku: "",
      available: false,
      title: "",
      name: "",
      price: "0.00",
      compare_at_price: null,
      option1: "",
      featured_image: null,
    },
  ],
  options: ["Title"],
  featured_image: "",
  selling_plan_groups: [],
};
