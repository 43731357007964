import type { PlanInfo } from "schemas/billing";
import type { BillingPlanChangeSurveyModalProps } from "../AppModalTypes";

import * as React from "react";

import Button from "@common/designSystem/Button";
import SelectableButtons from "@common/designSystem/SelectableButtons";
import Textarea from "@common/designSystem/Textarea";
import { ModalLayout } from "@common/ModalLayout";
import Modal from "@editor/components/common/designSystem/Modal";
import useInitializeSubscriptionUpdateMutation from "@editor/hooks/trpc/useInitializeSubscriptionUpdateMutation";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useModal } from "@editor/hooks/useModal";
import { analytics } from "@editor/infra/analytics";

import classNames from "classnames";
import { BsDashCircleFill, BsXCircleFill } from "react-icons/bs";
import { useSearchParams } from "react-router-dom";
import { filterNulls } from "replo-utils/lib/array";
import {
  BILLING_PLAN_CONFIRMATION_PARAM,
  BILLING_PLAN_CONFIRMATION_TYPE_PARAM,
  regularBillingPlans,
} from "schemas/billing";
import { twMerge } from "tailwind-merge";

export const BillingPlanChangeSurveyModal: React.FC<
  BillingPlanChangeSurveyModalProps
> = ({ tier, downgradeTo, type, source, workspaceId }) => {
  const modal = useModal();
  const buttonData = [
    { label: "🎓 Missing Features", value: "missing_features" },
    {
      label: `🛒 Haven't Been Using It`,
      value: "havent_been_using_it",
    },
    { label: `💸 Too Expensive`, value: "too_expensive" },
    { label: `🐛 Too Many Bugs`, value: "too_many_bugs" },
    { label: `👌 Something Else`, value: "something_else" },
    { label: `😇 I'll Be Back`, value: "ill_be_back" },
  ];
  const [selectedButtons, setSelectedButtons] = React.useState<string[]>([]);
  const { user } = useCurrentUser();

  const currentPlan = regularBillingPlans.find((obj) => obj.tier === tier);
  const lowerTier = regularBillingPlans.find((obj) => obj.tier === downgradeTo);

  const [value, setValue] = React.useState("");

  const submitDataToPostHog = () => {
    analytics.logEvent("billing.downgrade.survey", {
      previousPlan: tier,
      currentPlan: downgradeTo,
      reasons: filterNulls(
        selectedButtons.map(
          (button) => buttonData.find((data) => data.value === button)?.label,
        ),
      ),
      feedback: value,
    });
  };

  const [, setSearchParams] = useSearchParams();

  const { initializeSubscriptionUpdate } =
    useInitializeSubscriptionUpdateMutation();

  // Note (Evan, 2024-04-24): Handle loading state ourselves since we still want to show a
  // loading state if the mutation has succeeded and we are waiting for a redirect.
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const result = await initializeSubscriptionUpdate({
        workspaceId,
        tier: downgradeTo,
        source,
      });

      if (!result.success) {
        setIsLoading(false);
      }
      // Note (Evan, 2024-04-24): The case where confirmationRequired is true
      // is handled in the RTK API slice
      else if (!result.confirmationRequired) {
        modal.closeModal({ type: "billingPlanChangeSurvey" });
        setSearchParams(
          (params) => {
            params.set(BILLING_PLAN_CONFIRMATION_PARAM, "true");
            params.set(BILLING_PLAN_CONFIRMATION_TYPE_PARAM, type);
            return new URLSearchParams(params);
          },
          { replace: true },
        );
      }
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  return (
    <Modal
      isOpen
      onRequestClose={() => {
        if (!isLoading) {
          setSearchParams(
            (params) => {
              if (!params.has(BILLING_PLAN_CONFIRMATION_PARAM)) {
                return params;
              }
              params.delete(BILLING_PLAN_CONFIRMATION_PARAM);
              return new URLSearchParams(params);
            },
            { replace: true },
          );
          modal.closeModal({ type: "billingPlanChangeSurvey" });
        }
      }}
      className="h-auto w-auto overflow-scroll no-scrollbar"
      style={{ maxHeight: "100vh" }}
      includesCloseIcon
      data-testid="billing-plan-change-survey"
    >
      <ModalLayout
        width={1000}
        height="auto"
        mainContent={() => {
          return (
            <div className="flex h-full flex-col justify-between gap-2 pt-6">
              <div className="flex flex-row justify-between gap-8">
                <div className="min-h-[400px] w-[520px] bg-slate-50 p-4 pl-6">
                  <DowngradeFeatureComparison
                    currentPlan={currentPlan}
                    lowerTier={lowerTier}
                  />
                </div>
                <div className="pr-5 text-lg font-light text-default">
                  <p className="my-2 gap-2 self-center text-xl font-semibold">
                    {user?.firstName}, Before You Go... 😭{" "}
                  </p>
                  <p className="text-normal text-sm">
                    Can you tell us a bit about why Replo is not a good fit?
                    Select as many answers as you need.
                  </p>
                  <SelectableButtons
                    multiSelect={true}
                    options={buttonData}
                    value={selectedButtons}
                    onChange={(button) => {
                      setSelectedButtons(button);
                    }}
                    className="my-4"
                  />
                  <p className="text-sm pt-2">
                    Anything you want to share with the team?
                  </p>
                  <Textarea
                    debounce
                    className="mt-2 h-48 w-full text-sm"
                    value={value}
                    onChange={(value) => {
                      setValue(value);
                    }}
                    placeholder="Type here"
                    autoFocus
                  />
                </div>
              </div>
              <hr className="my-3 border-slate-200" />
              <div className="flex flex-row justify-end gap-4">
                <Button
                  type="tertiary"
                  size="lg"
                  onClick={() => {
                    if (!isLoading) {
                      modal.closeModal({ type: "billingPlanChangeSurvey" });
                      modal.openModal({
                        type: "billingModal",
                        props: { source },
                      });
                    }
                  }}
                  className="text-blue-600"
                >
                  Never Mind
                </Button>
                <Button
                  type="primary"
                  size="lg"
                  onClick={() => {
                    if (!isLoading) {
                      submitDataToPostHog();
                      void handleSubmit();
                    }
                  }}
                  isLoading={isLoading}
                >
                  {lowerTier?.tier === "free"
                    ? "Cancel Plan"
                    : "Downgrade Plan"}
                </Button>
              </div>
            </div>
          );
        }}
      />
    </Modal>
  );
};

// TODO (Evan, 2024-03-28): move this to /shared
export const DowngradeFeatureComparison: React.FC<{
  currentPlan: PlanInfo | undefined;
  lowerTier: PlanInfo | undefined;
}> = ({ currentPlan, lowerTier }) => {
  // Note (Juan, 2023-03-28): This function removes the Everything
  // in the lowerPlan feature, this feature should not be rendered
  // on the plan change modal"
  function removeLowerTierFeatures(featureStrings: string[]) {
    if (featureStrings) {
      return featureStrings.filter(
        (str) => filterNulls([str]) && !str.includes("Everything"),
      );
    }
  }
  return (
    <>
      <p className="text-base font-extralight text-slate-400">
        You&apos;ll Lose Access To
      </p>
      <div className="pl-3 pt-2">
        <h1 className="flex flex-row text-xl font-medium">
          <div
            className={classNames(
              twMerge(
                "bg-gradient-to-r bg-clip-text text-transparent",
                currentPlan?.titleColor,
              ),
            )}
          >
            Replo&nbsp;
          </div>
          {currentPlan?.displayName}
        </h1>
        {currentPlan?.features &&
          removeLowerTierFeatures(currentPlan.features)?.map((feature) => {
            return (
              <div
                key={feature}
                className="flex items-center gap-2 py-2 text-sm"
              >
                <div className="w-6">
                  <BsXCircleFill size={20} className="text-red-600" />
                </div>
                <p className="w-full text-sm font-light">{feature}</p>
              </div>
            );
          })}
        <p className="py-2 text-sm font-extralight text-slate-400">
          You&apos;ll Keep Access To
        </p>
        {currentPlan?.tier === "basic" ? (
          <div className="flex items-center gap-2 py-2 text-sm">
            <div className="w-6">
              <BsDashCircleFill size={20} className="text-gray-200" />
            </div>
            <p className="w-full text-sm font-light">
              Your Existing Pages Stay Live
            </p>
          </div>
        ) : (
          lowerTier?.features &&
          removeLowerTierFeatures(lowerTier?.features)?.map((feature, i) => {
            return (
              <div
                key={i.toString()}
                className="flex items-center gap-2 py-2 text-sm"
              >
                <div className="w-6">
                  <BsDashCircleFill size={20} className="text-gray-200" />
                </div>
                <p className="w-full text-sm font-light">{feature}</p>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};
