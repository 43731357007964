import type { CustomPropDefinition } from "replo-runtime/shared/Component";
import type {
  MediaSize,
  SupportedMediaSize,
} from "replo-runtime/shared/utils/breakpoints";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";

import { selectDraftComponent } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import {
  currentlySupportedMediaSizes,
  defaultMediaSize,
  editorCanvasToMediaSize,
} from "replo-runtime/shared/utils/breakpoints";
import {
  getCustomPropDefinitions,
  getPropsWithOverrides,
} from "replo-runtime/shared/utils/component";

export type CustomPropDefinitionAndValue = {
  definition: CustomPropDefinition;
  value: any;
};

const isMediaSizeSupported = (
  mediaSize: MediaSize,
): mediaSize is SupportedMediaSize => {
  return currentlySupportedMediaSizes.includes(mediaSize as SupportedMediaSize);
};

// Note (Evan, 2024-06-19): Gets custom prop definitions and values for the current draft component,
// applying overrides as necessary.
export const useCustomPropDefinitionsWithValues = (opts?: {
  activeCanvas?: EditorCanvas;
}): CustomPropDefinitionAndValue[] => {
  const draftComponent = useEditorSelector(selectDraftComponent);
  if (!draftComponent) {
    return [];
  }

  const activeCanvas = opts?.activeCanvas;

  const customPropDefinitions = getCustomPropDefinitions(draftComponent);

  let draftComponentProps = draftComponent.props;

  // Note (Evan, 2024-06-19): If an active canvas is passed, apply overrides
  if (activeCanvas) {
    const mediaSize = editorCanvasToMediaSize[activeCanvas].mediaSize;

    const draftComponentPropsWithOverrides = getPropsWithOverrides(
      draftComponent.props,
      isMediaSizeSupported(mediaSize) ? mediaSize : defaultMediaSize,
    );

    if (draftComponentPropsWithOverrides) {
      draftComponentProps = draftComponentPropsWithOverrides;
    }
  }

  return customPropDefinitions.map((propDefinition) => {
    let value =
      draftComponentProps[propDefinition.id] ?? propDefinition.defaultValue;

    // NOTE (Evan, 7/27/23) Some of our integer props are mistakenly strings
    // on prod (due to a bug in the custom prop controls), so we try to remedy
    // this by parsing as an integer
    if (propDefinition.type === "integer" && typeof value === "string") {
      value = Number.parseInt(value);
    }
    return {
      definition: propDefinition,
      value: value,
    };
  });
};

type TypeValidator<T> = (value: any) => value is T;
const typeValidators = {
  boolean: (value: any): value is boolean => typeof value === "boolean",
  integer: (value: any): value is number => typeof value === "number",
  string: (value: any): value is string => typeof value === "string",
};

type ValidatablePropType = keyof typeof typeValidators;

// NOTE (Evan, 7/25/23) This TypeScript wizardry (courtesy of GPT)
// allows us to do stuff like: `customPropHasType(itemsPerPanel, "number")`
// after which TS will know that itemsPerPanel has the type (number) corresponding with
// the CustomComponentPropType ("number"). This is pretty trivial in the case of primitive types,
// but we can add more validators to cover arbitrary custom prop types.
export function customPropHasType<T extends ValidatablePropType>(
  value: any,
  type: T,
): value is (typeof typeValidators)[T] extends TypeValidator<infer U>
  ? U
  : never {
  const validator = typeValidators[type];
  if (!validator) {
    return false;
  }
  return validator(value);
}

export function getOptionsFromCustomPropDefinition(
  definition: CustomPropDefinition,
) {
  if (
    definition.selectableValues &&
    definition.selectableValues.type === "options"
  ) {
    return definition.selectableValues.options;
  }
  return undefined;
}
