import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _autoClose: {
      type: "boolean",
      name: "Auto-close Collapsibles",
      description:
        "If enabled, only one Collapsible inside this Accordion Block can be opened at a time.",
      defaultValue: true,
    },
  };
}

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: true,
    canContainChildren: true,
    showComponentControlsFromChildren: true,
    styleElements,
    ancestorDisallowList: [
      {
        ancestorTypes: ["button"],
        message: "Accordions cannot be nested inside buttons.",
      },
      {
        ancestorTypes: ["marquee"],
        message: "Accordions cannot be nested inside tickers.",
      },
    ],
  },
} satisfies ComponentConfig;

export default config;
