import * as React from "react";

import Avatar from "@editor/components/common/designSystem/Avatar";
import Input from "@editor/components/common/designSystem/Input";
import Popover from "@editor/components/common/designSystem/Popover";
import { SidebarWorkspacesSkeleton } from "@editor/components/dashboard/SkeletonLoaders";
import { WorkspaceCardSkeleton } from "@editor/components/editor/SkeletonLoaders";
import { useWorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { storeToken } from "@editor/reducers/utils/store-token";
import { trpc } from "@editor/utils/trpc";

import classNames from "classnames";
import { BsCaretDownFill, BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDebouncedState } from "replo-utils/react/use-debounced-state";
import { twMerge } from "tailwind-merge";

const EMPTY_ARRAY: any[] = [];

const WorkspaceCard: React.FC = () => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [searchStringInputValue, searchString, setSearchString] =
    useDebouncedState("");
  const logEvent = useLogAnalytics();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { data, isPending: isLoading } =
    trpc.workspace.getUserWorkspacesList.useQuery();
  const { setWorkspaceId, workspaceId } = useWorkspaceDashboardContext();

  const workspaces = data?.workspaces ?? EMPTY_ARRAY;
  const currentWorkspaceName = React.useMemo(
    () => workspaces.find((workspace) => workspace.id === workspaceId)?.name,
    [workspaceId, workspaces],
  );
  const filteredWorkspaces = React.useMemo(() => {
    return workspaces.filter((workspace) => {
      return (
        workspace.name.toLowerCase().includes(searchString.toLowerCase()) &&
        workspace.id !== workspaceId
      );
    });
  }, [workspaces, searchString, workspaceId]);

  const { mutateAsync: logout } = trpc.auth.logout.useMutation({
    onSuccess: async () => {
      storeToken(null);
      window.location.replace("/auth/login");
    },
  });

  const isEmptyWorkspaces = filteredWorkspaces.length === 0;
  const uniqueWorkspace = workspaces.length === 1;
  const showSearchInput = workspaces.length <= 5;

  const actions = [
    {
      label: "Add new workspace",
      onClick: () => openModal({ type: "addWorkspaceModal" }),
    },
    {
      label: "Your profile",
      onClick: () => navigate("/settings/profile"),
    },
    {
      label: "Logout",
      onClick: () => {
        logEvent("dashboard.logout", {});
        void logout({});
      },
    },
  ];

  const onWorkspaceClick = (workspaceId: string, workspaceName: string) => {
    setWorkspaceId(workspaceId);
    navigate(`/workspace/${workspaceId}/projects`);
    setIsPopoverOpen(false);
    logEvent("dashboard.workspace.switch", {
      toWorkspaceName: workspaceName,
      toWorkspaceId: workspaceId,
    });
  };

  const onPopoverOpenChange = (isOpen: boolean) => {
    setIsPopoverOpen(isOpen);
    setSearchString("");
  };

  return (
    <Popover isOpen={isPopoverOpen} onOpenChange={onPopoverOpenChange}>
      <Popover.Content
        shouldPreventDefaultOnInteractOutside={false}
        side="bottom"
        fromYTransform={-4}
        sideOffset={4}
        hideCloseButton
        align="start"
        alignOffset={0}
        className="p-0 w-[226px] border-0.5"
      >
        <div className="flex flex-col">
          {!uniqueWorkspace ||
          (uniqueWorkspace && workspaceId !== workspaces[0]?.id) ? (
            <div className="flex flex-col py-2 px-4">
              <div className="flex flex-col gap-2 my-1">
                <div className="font-normal text-xs text-slate-400 tracking-tight">
                  All Workspaces
                </div>
                {!showSearchInput ? (
                  <Input
                    size="sm"
                    placeholder="Search"
                    type="text"
                    value={searchStringInputValue}
                    onChange={(e) => setSearchString(e.target.value ?? "")}
                    startEnhancer={() => (
                      <BsSearch className="text-xs text-slate-400" />
                    )}
                  />
                ) : null}
              </div>
              <div
                className="flex flex-col max-h-44 overflow-y-scroll styled-scrollbar"
                aria-label="workspaces"
              >
                {!isLoading &&
                  filteredWorkspaces.map((workspace, i) => (
                    <div
                      key={i}
                      className="flex gap-2 py-1.5 items-center cursor-pointer"
                      onClick={() =>
                        onWorkspaceClick(workspace.id, workspace.name)
                      }
                    >
                      <Avatar name={workspace.name} size="xs" />
                      <div className="text-xs font-slate-800 truncate overflow-hidden">
                        {workspace.name}
                      </div>
                    </div>
                  ))}
                {!isLoading && isEmptyWorkspaces && (
                  <p className="text-xs py-2 text-slate-400 font-normal">
                    No workspaces found.{" "}
                    {workspaces.length > 0
                      ? "Try searching for a different workspace or ensuring you have access to the workspace you’re searching for."
                      : "Ensure you have access to a workspace or connect a Shopify Store."}
                  </p>
                )}
                {isLoading &&
                  Array.from({ length: 5 }).map((_, i) => (
                    <SidebarWorkspacesSkeleton key={i.toString()} />
                  ))}
              </div>
            </div>
          ) : null}
          <div
            className={classNames("flex flex-col bg-gray-50 ", {
              "border-t border-slate-200": !uniqueWorkspace,
            })}
          >
            {actions.map(({ label, onClick }, index) => (
              <div
                key={index}
                onClick={onClick}
                className="flex flex-row items-center py-2 px-4 text-slate-600 text-xs cursor-pointer hover:bg-gray-100"
              >
                {label}
              </div>
            ))}
          </div>
        </div>
      </Popover.Content>
      <Popover.Trigger className="w-full" aria-label="select workspace">
        {isLoading ? (
          <WorkspaceCardSkeleton />
        ) : (
          <Card
            workspaceName={currentWorkspaceName}
            isEmptyWorkspaces={workspaces.length === 0}
            endEnhancer={
              <BsCaretDownFill
                size={9}
                className={classNames("text-default ml-auto", {
                  "rotate-180": isPopoverOpen,
                })}
              />
            }
          />
        )}
      </Popover.Trigger>
    </Popover>
  );
};

const Card: React.FC<{
  workspaceName: string;
  endEnhancer?: React.ReactNode;
  className?: string;
  disableConditionalStyles?: boolean;
  isEmptyWorkspaces?: boolean;
}> = ({
  workspaceName,
  endEnhancer,
  className,
  disableConditionalStyles,
  isEmptyWorkspaces,
}) => {
  const { user } = useCurrentUser();

  if (!user) {
    return null;
  }
  const getCardTitle = () => {
    if (workspaceName) {
      return workspaceName;
    } else if (isEmptyWorkspaces) {
      return "No Workspaces";
    }
    return "Select a Workspace";
  };

  return (
    <div
      className={twMerge(
        classNames("flex flex-row items-center gap-x-2 p-3 rounded-md", {
          "hover:bg-blue-50 cursor-pointer": !disableConditionalStyles,
        }),
        className,
      )}
    >
      <div className="w-9">
        <Avatar name={workspaceName} size="base" />
      </div>
      <div className="flex flex-col overflow-hidden text-left">
        <div className="text-sm font-semibold w-full text-default truncate overflow-hidden">
          {getCardTitle()}
        </div>
        <div className="text-xs text-slate-400 truncate overflow-hidden">
          {user.email}
        </div>
      </div>
      {endEnhancer}
    </div>
  );
};

export default WorkspaceCard;
