import type { PendingShopifyIntegrationCookie } from "@hooks/usePendingShopifyIntegrationCookie";

import * as React from "react";

import { useModal } from "@editor/hooks/useModal";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";
import usePendingShopifyIntegrationCookie from "@hooks/usePendingShopifyIntegrationCookie";
import useUserProjects from "@hooks/useUserProjects";

import { skipToken } from "@tanstack/react-query";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

type PendingIntegrationAutoConnectTarget =
  | PendingShopifyIntegrationCookie
  | { type: "onboarding"; projectId: string; workspaceId: string }
  | null;

function getTargetPathBasedOnCookieType(
  pendingIntegrationAutoConnectTarget: PendingIntegrationAutoConnectTarget,
) {
  if (!pendingIntegrationAutoConnectTarget) {
    return;
  }
  const { workspaceId, projectId } = pendingIntegrationAutoConnectTarget;
  return exhaustiveSwitch(pendingIntegrationAutoConnectTarget)({
    onboarding: () =>
      generatePath(routes.editor.project, { projectId: projectId! }),
    noShopifyErrorModal: () =>
      projectId
        ? generatePath(routes.editor.project, { projectId: projectId! })
        : generatePath(routes.workspace.billing, { workspaceId }),
    projectSettings: () =>
      generatePath(routes.editor.integrations, { projectId: projectId! }),
    newProject: () =>
      generatePath(routes.editor.project, { projectId: projectId! }),
    sections: () =>
      generatePath(routes.editor.project, { projectId: projectId! }),
    publish: () =>
      generatePath(routes.editor.project, { projectId: projectId! }),
    assets: () =>
      generatePath(routes.editor.project, { projectId: projectId! }),
    productPicker: () =>
      generatePath(routes.editor.project, { projectId: projectId! }),
    templateDefault: () =>
      generatePath(routes.editor.project, { projectId: projectId! }),
    integrationHub: () =>
      generatePath(routes.workspace.integrations.shopify, {
        workspaceId,
      }),
    billingModal: () => generatePath(routes.workspace.billing, { workspaceId }),
  });
}

/**
 * NOTE (Matt 2024-09-26): This hook is responsible for navigating a user coming in from Shopify. There are several scenarios that this hooks needs
 * to manage, primarily those related to the shopify integration installation process but including the use-case of a user simply clicking on
 * Replo from within Shopify after it has already been installed.
 *
 * The backend sends a user to `/home` with either a `pendingAppInstallationId` or a `shopifyIntegrationId`, indicating whether it is a fresh install
 * or if the Shopify Store has already been connected to a workspace. Additionally, the user may have `pendingShopifyIntegrationCookie` set, which would
 * indicate if the user had started the shopify install flow within the last 10 min from within Replo. That cookie can indicate a workspace (and project,
 * where applicable) that the shopify store needs to be connected to.
 *
 * Take a look at the notes peppered throughout the useEffect to get a sense of how we handle each case in this flow.
 */
export default function useConnectShopifyIntegrationNavigator() {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const [searchParams] = useSearchParams();
  const pendingAppInstallationId = searchParams.get("pendingAppInstallationId");
  const shopifyIntegrationId = searchParams.get("shopifyIntegrationId");

  const {
    data: integrationDataFromParams,
    isLoading: isIntegrationDataLoading,
  } = trpc.integration.get.useQuery(
    shopifyIntegrationId ? { id: shopifyIntegrationId } : skipToken,
  );
  const { data: userWorkspaces, isLoading: isUserWorkspacesDataLoading } =
    trpc.workspace.getUserWorkspacesList.useQuery();
  const { data: userProjects } = useUserProjects();

  const { pendingShopifyIntegrationCookie } =
    usePendingShopifyIntegrationCookie();

  // NOTE (Matt 2024-09-30): If there is a pendingShopifyIntegrationCookie, then that is the target workspace
  // and/or project that we auto-connect an integration to. Alternatively, if the user only has access to 1
  // workspace and 1 project, then we autoconnect to that workspace and that project. This would only happen
  // if a user is coming from onboarding and they have not been invited to other projects or workspaces.
  const pendingIntegrationAutoConnectTarget: PendingIntegrationAutoConnectTarget =
    React.useMemo(() => {
      if (pendingShopifyIntegrationCookie) {
        return pendingShopifyIntegrationCookie;
      }
      const projects = userProjects?.allProjects ?? [];
      if (projects[0] && userWorkspaces?.workspaces[0]) {
        const userHasOnlyOneWorkspace = userWorkspaces.workspaces.length === 1;
        const userHasOnlyOneProject = projects.length === 1;
        const usersOnlyProjectIsOwnedByUsersOnlyWorkspace =
          userHasOnlyOneWorkspace &&
          userHasOnlyOneProject &&
          projects[0].ownerWorkspace?.id === userWorkspaces.workspaces[0].id;
        if (usersOnlyProjectIsOwnedByUsersOnlyWorkspace) {
          return {
            type: "onboarding",
            projectId: projects[0].id,
            workspaceId: userWorkspaces.workspaces[0].id,
          };
        }
      }
      return null;
    }, [pendingShopifyIntegrationCookie, userWorkspaces, userProjects]);

  const integrationOwningWorkspaceId =
    integrationDataFromParams?.integration.workspaceId;

  const integrationOwningWorkspace = userWorkspaces?.workspaces.find(
    ({ id }) => id === integrationOwningWorkspaceId,
  );
  const targetPath = getTargetPathBasedOnCookieType(
    pendingIntegrationAutoConnectTarget,
  );

  const {
    mutate: connectIntegration,
    isPending: isConnectingWorkspace,
    isError: isWorkspaceError,
  } = trpc.integration.convertPendingAppInstallationToIntegration.useMutation({
    onSuccess: () => {
      navigate(targetPath!);
    },
  });
  const {
    mutate: connectProjectToPendingInstallation,
    isPending: isConnectingProjectToPendingInstallation,
    isError: isProjectPendingConnectionError,
  } = trpc.project.connectToPendingInstallation.useMutation({
    onSuccess: () => {
      navigate(targetPath!);
    },
  });
  const {
    mutate: connectProjectToIntegration,
    isPending: isConnectingProjectToIntegration,
    isError: isProjectIntegrationConnectionError,
  } = trpc.project.connectToIntegration.useMutation({
    onSuccess: () => {
      navigate(targetPath!);
    },
  });

  const isMutationLoading =
    isConnectingWorkspace ||
    isConnectingProjectToPendingInstallation ||
    isConnectingProjectToIntegration;
  const isMutationError =
    isWorkspaceError ||
    isProjectPendingConnectionError ||
    isProjectIntegrationConnectionError;
  const isQueryLoading =
    isIntegrationDataLoading || isUserWorkspacesDataLoading;

  React.useEffect(() => {
    if (isMutationLoading || isMutationError || isQueryLoading) {
      return;
    }

    if (!pendingAppInstallationId && !integrationOwningWorkspaceId) {
      return;
    }

    // NOTE (Matt 2024-09-26): If there is a pendingAppInstallationId, and a pendingIntegrationAutoConnectTarget
    // we should automatically connect the corresponding project or workspace to the pending install.
    if (pendingAppInstallationId) {
      if (!pendingIntegrationAutoConnectTarget) {
        // NOTE (Matt 2024-09-26): If there is a pendingAppInstallationId and no cookie,
        // we open the pendingShopifyIntegrationModal which prompts the user to connect
        // a workspace.
        openModal({ type: "pendingShopifyIntegrationModal" });
        return;
      }

      if (pendingIntegrationAutoConnectTarget.projectId) {
        connectProjectToPendingInstallation({
          projectId: pendingIntegrationAutoConnectTarget.projectId,
          shopify: { pendingAppInstallationId },
        });
      } else {
        connectIntegration({
          workspaceId: pendingIntegrationAutoConnectTarget?.workspaceId,
          pendingAppInstallationId,
        });
      }
      return;
    }

    // NOTE (Matt 2024-09-26): If a cookie exists, and the workspace from that cookie matches
    // the workspace that owns the Shopify Integration, then we redirect the user back to where they
    // came from. In the event that the cookie includes a projectId, we need to first connect that
    // project.
    if (
      pendingIntegrationAutoConnectTarget?.workspaceId ===
      integrationOwningWorkspaceId
    ) {
      if (
        pendingIntegrationAutoConnectTarget?.projectId &&
        shopifyIntegrationId
      ) {
        connectProjectToIntegration({
          projectId: pendingIntegrationAutoConnectTarget.projectId,
          integrationId: shopifyIntegrationId,
        });
      } else {
        navigate(targetPath!);
      }
      return;
    }

    const isUserMemberOfIntegrationOwningWorkspace = Boolean(
      integrationOwningWorkspace,
    );
    if (!isUserMemberOfIntegrationOwningWorkspace) {
      // NOTE (Matt 2024-09-26): At this point, this means the user has attempted to add a shopify store to Replo, but that Shopify Store has been installed
      // by a workspace the user does not belong to. We explain this error and tell them to reach out to support.
      openModal({
        type: "fullPageErrorModal",
        props: {
          details: {
            header: "Shopify Store already installed",
            message:
              "This Shopify Store is already installed on another workspace that you do not have access to. In order to install this Shopify Store on your workspace, get access from the workspace admin, or contact support@replo.app for help.",
            callToAction: {
              type: "closeModal",
              name: "Close",
            },
          },
        },
      });
      return;
    }

    // NOTE (Matt 2024-09-26): If the user is a member of the workspace that owns the integration, but they have a cookie whose
    // workspaceId does not match that of the workspace that owns the integration, we need to inform them that what they are
    // trying to do won't work, and link them to the integrations hub for the workspace that owns the integration.
    if (pendingIntegrationAutoConnectTarget) {
      openModal({
        type: "fullPageErrorModal",
        props: {
          details: {
            header: "Shopify Store already installed",
            message: `This Shopify Integration is already installed on another workspace that you belong to. In order to install this Shopify Integration on a different workspace, you will first need to uninstall it from "${integrationOwningWorkspace!.name}".`,
            isFullPage: true,
            callToAction: {
              type: "callback",
              name: `View ${integrationOwningWorkspace!.name}`,
              onClick: () => {
                navigate(
                  generatePath(routes.workspace.integrations.shopify, {
                    workspaceId: integrationOwningWorkspaceId,
                  }),
                );
                closeModal({ type: "fullPageErrorModal" });
              },
            },
          },
        },
      });
    } else {
      // NOTE (Matt 2024-09-26): If the user does not have a cookie, then they are just trying to access Replo
      // via Shopify, and we take them to the integration-owning workspace's projects list.
      navigate(
        generatePath(routes.workspace.projects, {
          workspaceId: integrationOwningWorkspaceId,
        }),
      );
    }
  }, [
    pendingAppInstallationId,
    shopifyIntegrationId,
    isMutationLoading,
    isMutationError,
    isQueryLoading,
    pendingIntegrationAutoConnectTarget,
    integrationOwningWorkspaceId,
    integrationOwningWorkspace,
    connectIntegration,
    connectProjectToPendingInstallation,
    connectProjectToIntegration,
    openModal,
    closeModal,
    navigate,
    targetPath,
  ]);
}
