import * as React from "react";

import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { trpc } from "@editor/utils/trpc";

import AllMetricsTable from "@/features/analytics/AllMetricsTable";
import AnalyticsLayout from "@/features/analytics/Layout";
import { SetupPage } from "@/features/analytics/Setup";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";

const Analytics: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();
  const { data } = trpc.analytics.isEnabled.useQuery(
    workspaceId ? { workspaceId } : reactQuerySkipToken,
  );
  const isEnabled = data ?? false;
  return (
    <AnalyticsLayout>
      {isEnabled ? <AllMetricsTable /> : <SetupPage />}
    </AnalyticsLayout>
  );
};

export default Analytics;
