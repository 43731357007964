import { format } from "date-fns";
import { DateRange } from "react-day-picker";

export function hours(hoursToAdd: number) {
  return hoursToAdd * 60 * 60 * 1000;
}

export function minutes(minutesToAdd: number) {
  return minutesToAdd * 60 * 1000;
}

export function seconds(secondsToAdd: number) {
  return secondsToAdd * 1000;
}

export function convertDaysToMs(days: number) {
  return days * 24 * 60 * 60 * 1000;
}

export const dateRangeFormatter = (dateRange: DateRange) => {
  if (!dateRange.from || !dateRange.to) {
    return "Select a date range";
  }
  return `${dateFormatter(dateRange.from)} - ${dateFormatter(dateRange.to)}`;
};

export const dateFormatter = (date: Date) => {
  return format(date, "LLL dd, y");
};

/**
 * Given a UNIX timestamp, and an arbitrary date, it converts the timestamp
 * to UTC by:
 * 1) Calculating the offset between the date argument and UTC
 * 2) Substracting this offset from the UNIX timestamp.
 *
 * We're passing a date argument instead of defining it here, to catch the
 * timezone of where the date came from. We do this e.g. if we call this function
 * from the editor, which may be deployed in another region (e.g. us-east-1) than
 * the publisher, where this code lives (e.g. us-west-2). We wanna make sure we use
 * the timezone of the editor, not the publisher.
 */
export function convertTimestampToUTC(unixTimestamp: number, date: Date) {
  const offsetMinutes = date.getTimezoneOffset();
  const offsetMs = offsetMinutes * 60 * 1000;
  return unixTimestamp - offsetMs;
}
