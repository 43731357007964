import type { RectReadOnly } from "react-use-measure";
import type { Animation } from "../../shared/types";

import * as React from "react";

import useMeasure from "react-use-measure";
import {
  RenderEnvironmentContext,
  RuntimeHooksContext,
  useRuntimeContext,
} from "replo-runtime/shared/runtime-context";
import {
  disableAnimation,
  getActiveAnimation,
  resetAnimation,
  slidingAnimations,
} from "replo-runtime/store/utils/animations";

interface ComponentAnimationWrapperProps {
  animations: Animation[];
  children: (
    measureRef: React.RefCallback<HTMLOrSVGElement>,
    animationRef: React.RefCallback<HTMLOrSVGElement>,
  ) => React.ReactNode;
}

export type RenderAnimationWrapper = (
  rect: Partial<RectReadOnly>,
) => React.ReactNode;

const ComponentAnimationWrapper = ({
  animations,
  children,
}: ComponentAnimationWrapperProps) => {
  const [measureRef, rect] = useMeasure({
    debounce: 200,
    offsetSize: true,
  });
  const animationRef = useAnimateComponent(animations);

  const hasSlidingAnimation = animations?.some((animation) =>
    slidingAnimations.includes(animation.type),
  );

  return (
    <>
      {children(measureRef, animationRef)}
      {hasSlidingAnimation && (
        <div
          style={{
            top: 0,
            left: 0,
            width: rect.width,
            height: rect.height,
            position: "absolute",
            pointerEvents: "none",
            // Note (Noah, 2022-06-14, REPL-1871): Always set this to display:
            // block because on some themes, including Dawn, divs that are empty
            // (`div:empty` in CSS) are set to display: none. This could cause the
            // animation to not render properly, because this is the div we pass
            // to the IntersectionObserver to figure out when to play the
            // animation.
            display: "block",
          }}
        />
      )}
    </>
  );
};

const useAnimateComponent = (animations: Animation[]) => {
  const intersectionObserverRef = React.useRef<IntersectionObserver | null>(
    null,
  );

  const [componentNode, setComponentNode] = React.useState<HTMLElement | null>(
    null,
  );

  // TODO (Martin, 2024-09-20): This is a hack to use the proper node for
  // animations to work. The reason its needed is because ReploComponent's
  // componentRef won't trigger a re-render when it's updated so we will have
  // an incorrect value here. We need to stop using React refs as dependencies
  // and switch to callback refs (as this one) for those cases.
  const setComponentNodeRef = React.useCallback((node: HTMLElement | null) => {
    if (!node) {
      return;
    }

    setComponentNode(node);
  }, []);

  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();

  const animationsObserver = React.useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (!componentNode || animations.length === 0) {
        return;
      }

      const animation = getActiveAnimation(componentNode, animations);

      if (!animation || animation.trigger.type !== "onViewportEnter") {
        return;
      }

      for (const entry of entries) {
        if (entry.isIntersecting) {
          componentNode.style.animationPlayState = "running";
          break;
        }
      }
    },
    [componentNode, animations],
  );

  React.useEffect(() => {
    if (!isEditorApp || !componentNode || animations.length === 0) {
      return;
    }

    if (isEditorCanvas) {
      // Disable animation if on editor canvas
      disableAnimation(componentNode);
    } else {
      // Reset animation if on editor preview and animation should run on the
      // current media size
      const animation = getActiveAnimation(componentNode, animations);

      if (!animation) {
        return;
      }

      resetAnimation(
        componentNode,
        animation.value?.styles?.animationName,
        !animation.runOnlyOnce,
      );
    }
  }, [animations, componentNode, isEditorApp, isEditorCanvas]);

  React.useEffect(() => {
    if (isEditorCanvas || !componentNode || animations.length === 0) {
      return;
    }

    if (intersectionObserverRef.current) {
      intersectionObserverRef.current.disconnect();
    }

    intersectionObserverRef.current = new IntersectionObserver(
      animationsObserver,
      { rootMargin: "0px" },
    );

    intersectionObserverRef.current.observe(componentNode);

    return () => {
      intersectionObserverRef.current?.disconnect();
    };
  }, [animationsObserver, componentNode, isEditorCanvas, animations]);

  return setComponentNodeRef;
};

export default ComponentAnimationWrapper;
