import * as React from "react";

import { ButtonLink } from "@editor/components/common/designSystem/Button";
import SvgEmpyProjects from "@svg/empty-projects";

export function EmptyProjectMembership({
  shouldShowCreateProjectButton = true,
}: {
  shouldShowCreateProjectButton?: boolean;
}) {
  return (
    <div className="max-w-80 mx-auto mt-20 text-default flex flex-col items-center gap-8">
      <SvgEmpyProjects />
      <h3 className="font-medium text-xl">
        {shouldShowCreateProjectButton
          ? "Start by adding a new project"
          : "It looks like you don’t have any projects. Please connect a Shopify Store."}
      </h3>
      {shouldShowCreateProjectButton && (
        <ButtonLink size="lg" type="primary" to="../project/new">
          New Replo Project
        </ButtonLink>
      )}
    </div>
  );
}

export function DeprecatedEmptyProjectMembership({
  shouldShowCreateProjectButton = true,
}: {
  shouldShowCreateProjectButton?: boolean;
}) {
  return (
    <div className="max-w-80 mx-auto mt-20 text-default flex flex-col items-center gap-4">
      <h2 className="font-bold">No projects</h2>
      <p className="text-sm text-center">
        It looks like you don’t have any projects. Let’s add one to get started!
      </p>
      {shouldShowCreateProjectButton && (
        <ButtonLink size="lg" type="primary" to="../project/new">
          New Replo Project
        </ButtonLink>
      )}
    </div>
  );
}
