import type { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import type { Gradient } from "replo-runtime/shared/types";
import type { ProductResolutionDependencies } from "replo-runtime/store/ReploProduct";

import { isColor } from "@editor/utils/string";

import { filterDeep } from "deepdash-es/standalone";
import get from "lodash-es/get";
import isArray from "lodash-es/isArray";
import isObject from "lodash-es/isObject";
import pickBy from "lodash-es/pickBy";
import startCase from "lodash-es/startCase";
import { isGradient } from "replo-runtime/shared/types";
import { hasOwnProperty } from "replo-utils/lib/misc";
import { isValidHttpUrl } from "replo-utils/lib/url";
import { validate as uuidValidate } from "uuid";

type DynamicDataType =
  | "productOnly"
  | "productVariant"
  | "productOption"
  | "sellingPlan"
  | "dataTableRow"
  | "list"
  | "text"
  | "text/color"
  | "text/currency"
  | "text/url"
  | "object"
  | "swatches"
  | "text/integer"
  | "?";

type DynamicDataValue = {
  type: DynamicDataType;
  displayValue: string | null;
  displayName: string | null;
};

// Smart fields are fields that should always be present on the list of options,
// even if they are not currently present as dynamic data.
type SmartField = {
  key: string;
  type: DynamicDataType;
};

const SmartFields: {
  product: SmartField[];
  variant: SmartField[];
  sellingPlan: SmartField[];
} = {
  product: [
    { key: "description", type: "text" },
    { key: "featured_image", type: "text/url" },
  ],
  sellingPlan: [{ key: "_selectedSellingPlan", type: "sellingPlan" }],
  variant: [{ key: "featuredImage", type: "text/url" }],
};

export function getDynamicDataValue(
  key: string | null,
  targetType: DynamicDataType | null,
  value: any,
  productDependencies: ProductResolutionDependencies,
): DynamicDataValue {
  if (isArray(value)) {
    return generateDynamicDataValue("list", "");
  }

  if (key === "metafieldsWithNamespaceAndKey") {
    // TODO (Noah, 2022-11-26, REPL-5268): Assume that if we're looking
    // at a metafield value, the type of that value is always the target type,
    // because we can't know ahead of time before we fetch the definitions whether
    // to filter metafield definitions out or not.
    //
    // Consider the case where we're looking for a text/url value. We COULD filter
    // down the definitions to just include file_reference, but then we'd accidentally
    // filter out an single_line_text values that were a valid url, which we actually
    // want to include. You might think that we could filter out the definitions based
    // on their value like normal dynamic data fields, but we don't actually know the
    // metafield values until we fetch them.
    //
    // Probably the correct solution here is to fetch the metafield values at the same
    // time that we fetch the definitions, but putting this hack here now so that image
    // metafields work correctly.
    return generateDynamicDataValue(targetType ?? "text", null, null);
  }

  if (key === "_templateProduct") {
    // NOTE (Evan, 8/18/23): Attempt to resolve the template productRef to a StoreProduct
    value =
      productDependencies.products.find((p) => p.id === value?.productId) ??
      value;
  }

  // Special treatment for smart fields that have no value
  if (!value) {
    const smartField =
      (SmartFields.product.find((f) => f.key === key) ||
        SmartFields.variant.find((f) => f.key === key)) ??
      null;

    if (smartField) {
      return generateDynamicDataValue(
        smartField.type,
        null,
        "No available value",
      );
    }
  }

  if (typeof value === "string") {
    if (isColor(value)) {
      return generateDynamicDataValue("text/color", null, value);
    } else if (isValidHttpUrl(value)) {
      return generateDynamicDataValue("text/url", null, value);
    }
    if (Boolean(value) && !Number.isNaN(Number(value))) {
      return generateDynamicDataValue("text/integer", null, value);
    }
    return generateDynamicDataValue("text", null, value);
  }
  if (isGradient(value)) {
    return generateDynamicDataValue("text/color", null, value);
  }

  const fromKey = getDynamicDataValueFromKey(key, value);

  if (fromKey) {
    return fromKey;
  }

  if (hasOwnProperty(value, "productId")) {
    return generateDynamicDataValue("productOnly", "Product", value.title);
  }

  if (
    hasOwnProperty(value, "id") &&
    hasOwnProperty(value, "priceAdjustments") &&
    hasOwnProperty(value, "name")
  ) {
    return generateDynamicDataValue(
      "sellingPlan",
      "Selling Plan",
      get(value, "name"),
    );
  }

  if (isObject(value)) {
    const count = Object.keys(value).length;
    return generateDynamicDataValue(
      "object",
      key,
      `${count} value${count > 1 ? "s" : ""}`,
    );
  }

  return generateDynamicDataValue("?", null, "?");
}

export function filterDynamicDataByType(
  dynamicData: any,
  targetType: DynamicDataTargetType | null,
  excludedKeys: string[],
  productDependencies: ProductResolutionDependencies,
) {
  return filterDeep(
    pickBy(dynamicData, (_, key) => !uuidValidate(key)),
    (value: any, key: any, parentValue: any) => {
      if (excludedKeys.includes(key)) {
        return false;
      }

      // HACK (Noah, 2023-02-17, REPL-6353): This placeholder is here to ensure that we don't
      // filter out products/variants where the only thing applicable to the targetType is a
      // metafield. We should really make this dynamic data system have more of a semantic understanding
      // of what fields a product has, so we wouldn't need this
      if (key === "metafieldsPlaceholder") {
        return true;
      }

      // Special treatment for smart fields that have no value
      if (!value) {
        if (parentValue?.productId) {
          if (SmartFields.product.map((field) => field.key).includes(key)) {
            return true;
          }
          if (
            parentValue?.variantId &&
            SmartFields.variant.map((field) => field.key).includes(key)
          ) {
            return true;
          }
        }
        if (
          SmartFields.sellingPlan.some(
            (field) => field.type === targetType && key === field.key,
          )
        ) {
          return true;
        }

        return false;
      }

      // Lists are unstable, don't ever include text in a list
      if (targetType !== "list" && isArray(parentValue)) {
        return false;
      }

      const { type } = getDynamicDataValue(
        key,
        targetType,
        value,
        productDependencies,
      );

      const matchesType = targetType ? type.startsWith(targetType) : false;

      if (matchesType) {
        return true;
      }

      // Include list of images if looking for "text/url"
      if (targetType === "text/url" && key == "images" && isArray(value)) {
        return true;
      }

      // Returning undefined means we maybe include children
      return isArrayOrObject(value) ? undefined : false;
    },
    { leavesOnly: false },
  );
}

export function isArrayOrObject(value: unknown) {
  return isArray(value) || isObject(value);
}

export const getPathFromVariable = (variable: string | undefined): string[] => {
  // Note (Noah, 2022-01-17, REPL-6017): replace out any content outside of the {{ }},
  // like closing <p> tags for RTE content
  return (
    variable?.replace(/.*{{/, "").replace(/}}.*/, "").split(".").slice(1) ?? []
  );
};

function generateDynamicDataValue(
  type: DynamicDataType,
  name?: string | null | undefined,
  value?: string | Gradient | null | undefined,
): DynamicDataValue {
  return {
    type,
    displayValue: isGradient(value) ? "Gradient" : value ?? null,
    displayName: isGradient(value) ? "Gradient" : name ?? null,
  };
}

function getDynamicDataValueFromKey(
  key: string | null,
  value: any,
): DynamicDataValue | null {
  switch (key) {
    case "_product":
      return generateDynamicDataValue(
        "productOnly",
        "Current Product",
        value.title,
      );

    case "_templateProduct":
      return generateDynamicDataValue(
        "productOnly",
        "Template Product",
        value.title,
      );

    case "_variant":
      return generateDynamicDataValue(
        "productVariant",
        "Selected Variant",
        value?.title || "None Currently Selected",
      );
    case "_currentVariant":
      return generateDynamicDataValue(
        "productVariant",
        "Repeated Variant",
        value?.title || "No Repeated Variant",
      );
    case "_selectedSellingPlan":
      return generateDynamicDataValue(
        "sellingPlan",
        "Selected Selling Plan",
        value?.name || "No Selected Selling Plan",
      );
    case "_currentSellingPlan":
      return generateDynamicDataValue(
        "sellingPlan",
        "Current Selling Plan",
        value?.name || "No Current Selling Plan",
      );
    case "_selectedOptionValues":
      return generateDynamicDataValue(
        "object",
        "Selected Options",
        isObject(value)
          ? `${Object.keys(value).join(", ")}`
          : "None Currently Selected",
      );
    case "_currentOption":
      return generateDynamicDataValue(
        "productOption",
        "Repeated Option",
        value.title || "No Repeated Option",
      );
    case "_currentTemporaryCartItem":
      return generateDynamicDataValue(
        "productVariant",
        "Current Temporary Cart Variant",
        value.title,
      );
    case "_currentSelection":
      return generateDynamicDataValue(
        "dataTableRow",
        "Current Selected Item",
        "Data Collection Row",
      );
    case "_currentItem":
      return generateDynamicDataValue(
        "dataTableRow",
        "Current Row",
        "Data Collection Row",
      );
    case "_swatches":
      return generateDynamicDataValue(
        "swatches",
        "Swatches",
        "Available swatches",
      );
    default:
      return null;
  }
}

export const getDynamicDataValueDisplayName = (template: string) => {
  return startCase(
    template
      .replace("{{", "")
      .replace("}}", "")
      .replace("<p>", "")
      .replace("</p>", "")
      .split(".")
      .slice(-1)[0] ?? "",
  );
};
