import type { ToggleGroupOption } from "@common/designSystem/ToggleGroup";
import type { LeftBarTab } from "@reducers/ui-reducer";

import * as React from "react";

import ToggleGroup from "@common/designSystem/ToggleGroup";
import ComponentTemplatePane from "@components/ComponentTemplatePane";
import ComponentTreePane from "@components/ComponentTreePane";
import {
  debugPanelWidth,
  headerHeight,
  minimumLeftBarWidth,
} from "@components/editor/constants";
import ElementsPane from "@components/ElementsPane";
import TourStepTrigger from "@components/flows/TourStepTrigger";
import ResizablePane from "@components/ResizablePane";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useEditorPerformanceContext } from "@editor/contexts/editor-performance.context";
import { isNewLeftBarUIEnabled } from "@editor/infra/featureFlags";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  selectLeftBarActiveTab,
  selectLeftBarNavigationOpenedMenu,
  selectLeftBarWidth,
  setLeftBarActiveTab,
  setLeftBarWidth,
} from "@editor/reducers/ui-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import useCreateComponentForm from "@hooks/useCreateComponentForm";
import useCurrentProjectId from "@hooks/useCurrentProjectId";
import {
  selectEditorMode,
  selectInternalDebugModeOn,
} from "@reducers/core-reducer";

import { Spinner } from "@replo/design-system/components/spinner";
import { Tooltip } from "@replo/design-system/components/tooltip";
import classNames from "classnames";
import { BsBook, BsFiles, BsLayers, BsX } from "react-icons/bs";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import Separator from "./common/designSystem/Separator";
import { HotkeyIndicator } from "./common/HotkeyIndicator";
import DocumentationInfoIcon from "./editor/page/element-editor/components/DocumentationInfoIcon";

const TOGGLE_OPTIONS: ToggleGroupOption<LeftBarTab>[] = [
  {
    label: "Layers",
    value: "tree",
    tooltipContent: "Navigation and Layers",
    attributes: {
      "data-testid": "component-library-tree",
    },
  },
  {
    label: "Components",
    value: "components",
    tooltipContent: "Component Library",
    attributes: {
      "data-testid": "component-library-tab",
    },
  },
];

const LeftBar: React.FC = React.memo(function LeftBar() {
  const editorMode = useEditorSelector(selectEditorMode);
  const projectId = useCurrentProjectId();
  const { isLoading } = useCurrentProjectContext();
  const activeTab = useEditorSelector(selectLeftBarActiveTab);
  const leftBarWidth = useEditorSelector(selectLeftBarWidth);
  const leftBarNavigationOpenedMenu = useEditorSelector(
    selectLeftBarNavigationOpenedMenu,
  );
  const isLeftBarNavigationOpen = leftBarNavigationOpenedMenu !== "";
  const dispatch = useEditorDispatch();
  const { leftBarElementRef } = useEditorPerformanceContext();

  // Note (Evan, 2024-10-08): This hook ~will~ re-render with each streamed action,
  // but it's not an issue because we're just returning null while streaming. There is
  // a more global issue with this, which see: https://replohq.slack.com/archives/C03AACVP08Y/p1728413180369459.
  const { hasQueuedGeneration } = useAIStreaming();

  useCreateComponentForm(() => dispatch(setLeftBarActiveTab("components")));

  if (editorMode !== EditorMode.edit || isLoading || hasQueuedGeneration) {
    return null;
  }

  if (isNewLeftBarUIEnabled()) {
    return <LeftPanel />;
  }

  return (
    // NOTE (Gabe 2023-06-13): z-0 creates a new stacking context which enables
    // using z-index for sticky headers that don't overlay portals in the rest
    // of the app.
    <div className="flex z-0" ref={leftBarElementRef}>
      <ResizablePane
        minSize={minimumLeftBarWidth}
        maxSize={500}
        handleClassName="bg-slate-200 w-[1px]"
        className="flex h-full"
        size={leftBarWidth}
        contentClassName="flex-1 bg-default"
        onResize={(size) => dispatch(setLeftBarWidth(size))}
        isDisabled={isLeftBarNavigationOpen}
      >
        <div className="mx-2 mb-2">
          <ToggleGroup
            allowsDeselect={false}
            type="single"
            options={TOGGLE_OPTIONS}
            value={activeTab}
            onChange={(value) => {
              dispatch(setLeftBarActiveTab(value));
            }}
            style={{ width: "100%" }}
          />
        </div>

        {activeTab === "tree" ? (
          <TreeTabPane projectId={projectId} />
        ) : (
          <div className="p-2">
            <ComponentTemplatePane />
          </div>
        )}
      </ResizablePane>
    </div>
  );
});

const TreeTabPane: React.FC<{
  projectId: string | undefined;
}> = ({ projectId }) =>
  projectId ? (
    <>
      <TourStepTrigger step="step-1">
        <ElementsPane />
      </TourStepTrigger>
      <Separator />
      <ComponentTreePane />
    </>
  ) : (
    <div className="flex h-full w-full items-center justify-center">
      <Spinner width={1} size={30} />
    </div>
  );

export default LeftBar;

type LeftPanelMenuTab = {
  id: LeftBarTab;
  icon: React.ReactNode;
  label?: React.ReactNode;
};

const tabToTitleMap: Record<LeftBarTab, string> = {
  tree: "Layers",
  elements: "Pages",
  components: "Components",
};

const LeftPanelMenu: React.FC = () => {
  const dispatch = useEditorDispatch();

  const leftBarActiveTab = useEditorSelector(selectLeftBarActiveTab);
  const isGivenTabActive = (tab: LeftBarTab) => leftBarActiveTab === tab;

  const tabs: Array<LeftPanelMenuTab> = [
    {
      id: "elements",
      icon: <BsFiles />,
      label: <HotkeyIndicator hotkey="setElementsPanel" title="Pages" />,
    },
    {
      id: "tree",
      icon: <BsLayers />,
      label: <HotkeyIndicator hotkey="setLayersPanel" title="Layers" />,
    },
    {
      id: "components",
      icon: <BsBook />,
      label: <HotkeyIndicator hotkey="setComponentsPanel" title="Components" />,
    },
  ];

  return (
    <div
      className={classNames(
        "bg-default flex flex-col gap-2 rounded h-fit shadow-lg",
        {
          "p-2": !isNewLeftBarUIEnabled(),
          "p-1": isNewLeftBarUIEnabled(),
        },
      )}
    >
      {tabs.map((tab) => {
        const isActive = isGivenTabActive(tab.id);
        const title = tabToTitleMap[tab.id];
        return (
          <Tooltip
            key={tab.id}
            content={tab.label}
            trigger={({ triggerProps, triggerRef }) => {
              return (
                <button
                  className={classNames("p-2 cursor-pointer rounded", {
                    "bg-accent text-onEmphasis": isActive,
                    "hover:bg-hover hover:text-accent": !isActive,
                  })}
                  aria-label={title}
                  onClick={() =>
                    dispatch(setLeftBarActiveTab(isActive ? null : tab.id))
                  }
                  {...triggerProps}
                  ref={triggerRef}
                >
                  {tab.icon}
                </button>
              );
            }}
          />
        );
      })}
    </div>
  );
};

const LeftPanel: React.FC = () => {
  const isDebugPanelVisible = useEditorSelector(selectInternalDebugModeOn);

  const gap = isNewLeftBarUIEnabled() ? 4 : 8;
  return (
    <div
      className={classNames("absolute flex gap-1", {
        "gap-1": isNewLeftBarUIEnabled(),
        "gap-2": !isNewLeftBarUIEnabled(),
      })}
      // NOTE (Fran 2024-09-24): Position the menu below the header, which has a fixed height.
      // So we should calculate the header's height and add the margin we need.
      // We have the same issue with the debug panel.
      style={{
        top: headerHeight + gap,
        left: isDebugPanelVisible ? debugPanelWidth + gap : gap,
        // NOTE (Fran 2024-09-26): The Left panel should take the entire viewport height minus the
        // header height and the space between the header height and the left panel.
        height: `calc(100vh - ${headerHeight + gap * 2}px)`,
      }}
    >
      <LeftPanelMenu />
      <LeftPanelContent />
    </div>
  );
};

const LeftPanelContent: React.FC = () => {
  const dispatch = useEditorDispatch();

  const leftBarActiveTab = useEditorSelector(selectLeftBarActiveTab);

  if (leftBarActiveTab === null) {
    return null;
  }

  const content = exhaustiveSwitch({ type: leftBarActiveTab })<{
    component: React.ReactNode;
    documentationLink: string | null;
  }>({
    components: () => ({
      documentationLink:
        "https://support.replo.app/collections/8618926849-components",
      component: <ComponentTemplatePane />,
    }),
    elements: () => ({
      documentationLink:
        "https://support.replo.app/collections/9149515681-content-types",
      component: (
        <TourStepTrigger step="step-1">
          <ElementsPane />
        </TourStepTrigger>
      ),
    }),
    tree: () => ({
      // TODO (Fran 2024-09-20): Add this documentation link
      documentationLink: null,
      component: <ComponentTreePane />,
    }),
  });

  const paddingIsOnResizable = leftBarActiveTab !== "tree";
  return (
    <ResizableLeftPanelContentWrapper padded={paddingIsOnResizable}>
      <div className="flex flex-col gap-2 h-full">
        <div
          className={classNames("flex justify-between items-center", {
            "px-3 pt-3": !paddingIsOnResizable,
          })}
        >
          <div className="flex gap-2 items-center">
            <span className="text-xs font-bold text-black">
              {tabToTitleMap[leftBarActiveTab]}
            </span>
            {content.documentationLink ? (
              <DocumentationInfoIcon href={content.documentationLink} />
            ) : null}
          </div>
          <BsX
            className="h-4 w-4 text-subtle cursor-pointer"
            onClick={() => dispatch(setLeftBarActiveTab(null))}
          />
        </div>
        {content.component}
      </div>
    </ResizableLeftPanelContentWrapper>
  );
};

const ResizableLeftPanelContentWrapper: React.FC<
  React.PropsWithChildren<{ padded: boolean }>
> = ({ children, padded }) => {
  const dispatch = useEditorDispatch();

  const leftBarWidth = useEditorSelector(selectLeftBarWidth);
  const leftBarNavigationOpenedMenu = useEditorSelector(
    selectLeftBarNavigationOpenedMenu,
  );

  const { leftBarElementRef } = useEditorPerformanceContext();

  const isLeftBarNavigationOpen = leftBarNavigationOpenedMenu !== "";

  return (
    // NOTE (Gabe 2023-06-13): z-0 creates a new stacking context which enables
    // using z-index for sticky headers that don't overlay portals in the rest
    // of the app.
    <div className="flex z-0" ref={leftBarElementRef}>
      <ResizablePane
        minSize={minimumLeftBarWidth}
        maxSize={500}
        handleClassName="bg-slate-200 w-[1px]"
        className="flex shadow-lg"
        size={leftBarWidth}
        contentClassName={classNames("bg-default rounded overflow-y-hidden", {
          "p-3": padded,
        })}
        onResize={(size) => dispatch(setLeftBarWidth(size))}
        isDisabled={isLeftBarNavigationOpen}
      >
        {children}
      </ResizablePane>
    </div>
  );
};
