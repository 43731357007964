import type { ComponentTemplate } from "@editor/types/component-template";

import { getCanBeAddedFunction } from "@editor/utils/template";

import { Env } from "replo-runtime/shared/enums";
import { ItemsConfigType } from "replo-runtime/store/utils/items";

export const carouselWithProductImages: ComponentTemplate = {
  id: "7f094b9b-77ee-498c-8f28-f3900af074f2",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Product Images",
  leftBarCategory: { main: "product", subCategory: "productData" },
  tags: ["carousel"],
  preview: "product/images.png",
  canBeAddedAsChild: getCanBeAddedFunction(
    "product",
    "Product Image Carousels can only be added inside Product boxes.",
  ),
  template: {
    id: "237512e6-d49c-4c87-98bd-a75bed5ce6c4",
    name: "Carousel 1",
    type: "carouselV3",
    props: {
      style: {
        display: "flex",
        maxWidth: "100%",
        alignSelf: "stretch",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
      _items: {
        type: ItemsConfigType.inline,
        valueType: "dynamic",
        dynamicPath: "{{attributes._product.images}}",
      },
    },
    children: [
      {
        id: "ac74165c-9773-450c-992d-b17523ca2604",
        name: "Previous Button 1",
        type: "carouselV3Control",
        props: {
          style: {
            cursor: "pointer",
            display: "flex",
            flexGrow: "unset",
            flexWrap: "wrap",
            alignSelf: "stretch",
            flexBasis: "auto",
            alignItems: "center",
            justifyContent: "center",
          },
          direction: "previous",
        },
        children: [
          {
            id: "0432d84f-2ea6-4e74-aa83-f3b43b7378db",
            type: "icon",
            props: {
              style: {
                width: "60px",
                height: "60px",
                __width: "60px",
                __height: "60px",
                flexGrow: "unset",
              },
              iconName: "chevronLeft",
            },
            variantOverrides: {},
          },
        ],
        variants: [
          {
            id: "ded2a465-3c15-40f0-b1a4-15969eed3f1b",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "0d6f4db8-0a8e-45b9-9c21-9150cec45825",
            name: "First Item",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "bf8b0b4c-79fd-4043-a18b-9840a038a6e3",
                  field: "state.group.isFirstItemActive",
                  value: null,
                  operator: null,
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "0d6f4db8-0a8e-45b9-9c21-9150cec45825": {
            componentOverrides: {
              "0432d84f-2ea6-4e74-aa83-f3b43b7378db": {
                props: { style: { opacity: "20%" } },
              },
              "ac74165c-9773-450c-992d-b17523ca2604": {
                props: { style: { cursor: "default" } },
              },
            },
          },
        },
      },
      {
        id: "53da05a0-e7a8-48aa-947a-a3c0455a5930",
        name: "Container 1010",
        type: "container",
        props: {
          style: {
            display: "flex",
            flexGrow: "1",
            maxWidth: "100%",
            alignSelf: "stretch",
            flexBasis: "auto",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "flex-start",
          },
        },
        children: [
          {
            id: "5e9ea28c-afb9-4df3-a2aa-bd25bfca8671",
            name: "Carousel Slides",
            type: "carouselV3Slides",
            props: {
              style: {
                width: "auto",
                height: null,
                __width: "1197px",
                flexGrow: 1,
                overflow: "hidden",
                alignSelf: "stretch",
                flexBasis: "auto",
              },
            },
            children: [
              {
                id: "2188bda5-9908-4cc6-9c01-7a8717ec3e65",
                name: "Slide 1",
                type: "container",
                props: {
                  style: {
                    __width: "auto",
                    display: "flex",
                    flexGrow: "1",
                    __flexGap: "12px",
                    flexBasis: "auto",
                    alignItems: "center",
                    paddingTop: "24px",
                    paddingBottom: "24px",
                    justifyContent: "center",
                  },
                },
                children: [
                  {
                    id: "969c627a-dae3-4ba7-a3cf-4ecc98033f2c",
                    name: "Image 1",
                    type: "image",
                    props: {
                      src: "",
                      style: {
                        flexGrow: 1,
                        objectFit: "cover",
                        alignSelf: "stretch",
                        __imageSource: "{{attributes._currentItem}}",
                      },
                    },
                  },
                ],
              },
              {
                id: "7f0e3569-c0a4-4faf-acc8-ada24c30b107",
                name: "Slide 2",
                type: "container",
                props: {
                  style: {
                    __width: "auto",
                    display: "flex",
                    flexGrow: "1",
                    __flexGap: "12px",
                    flexBasis: "auto",
                    alignItems: "center",
                    paddingTop: "24px",
                    paddingBottom: "24px",
                    justifyContent: "center",
                  },
                },
                children: [
                  {
                    id: "28c94652-b311-4218-89af-c52fce42bd5a",
                    name: "Image 2",
                    type: "image",
                    props: {
                      src: "",
                      style: { flexBasis: "auto", objectFit: "cover" },
                    },
                  },
                  {
                    id: "cd466111-e2d0-4cdf-a115-94e0dbe099f9",
                    type: "text",
                    props: {
                      text: "<p>Slide 2 of 2</p>",
                      style: {
                        flexGrow: "unset",
                        fontSize: "20px",
                        flexBasis: "auto",
                        lineHeight: "24px",
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "411a7736-1bcf-4164-b875-9b1c40b1ed23",
            name: "Carousel Indicators",
            type: "carouselV3Indicators",
            props: {
              style: {
                top: "auto",
                left: "auto",
                right: "auto",
                bottom: "auto",
                zIndex: 2,
                display: "flex",
                position: "relative",
                alignSelf: "stretch",
                marginTop: "0px",
                alignItems: "center",
                marginLeft: "0px",
                paddingTop: "16px",
                paddingBottom: "16px",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "6def7401-2b38-49ba-8aca-5a791780a24d",
                name: "Container 1011",
                type: "container",
                props: {
                  style: {
                    width: "10px",
                    cursor: "pointer",
                    height: "10px",
                    __width: "10px",
                    display: "flex",
                    __height: "10px",
                    flexGrow: "unset",
                    maxWidth: "100%",
                    alignSelf: "auto",
                    alignItems: "flex-start",
                    marginLeft: "10px",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    backgroundColor: "#0000004D",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  },
                },
                children: [],
                variants: [
                  {
                    id: "5701ee18-03da-4a6f-a2a7-4c8b30915e26",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "7fa8382f-138e-4ca4-a510-1d245b168fb1",
                    name: "Current item is active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "a5eefa18-3bc3-4890-a191-335f2c071f83",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "7fa8382f-138e-4ca4-a510-1d245b168fb1": {
                    componentOverrides: {
                      "6def7401-2b38-49ba-8aca-5a791780a24d": {
                        props: { style: { backgroundColor: "#000000FF" } },
                      },
                    },
                  },
                },
              },
            ],
          },
        ],
      },
      {
        id: "9169e0dd-ccb1-4708-bcc3-301d3121b2df",
        name: "Next Button 1",
        type: "carouselV3Control",
        props: {
          style: {
            cursor: "pointer",
            display: "flex",
            flexGrow: "unset",
            alignSelf: "stretch",
            flexBasis: "auto",
            alignItems: "center",
            justifyContent: "center",
          },
          direction: "next",
        },
        children: [
          {
            id: "2edd75e1-65a7-4955-ba1c-88e3541d97f0",
            type: "icon",
            props: {
              style: {
                width: "60px",
                height: "60px",
                __width: "60px",
                __height: "60px",
                flexGrow: "unset",
              },
              iconName: "chevronRight",
            },
            variantOverrides: {},
          },
        ],
        variants: [
          {
            id: "425c80ee-d156-4891-9122-bd4e8cd4bac5",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "24c2e5b0-8360-41c3-bcc4-160915eecf18",
            name: "Last Item",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "fa7db26c-7755-450b-b10f-b5f0f5c6a624",
                  field: "state.group.isLastItemActive",
                  value: null,
                  operator: null,
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "24c2e5b0-8360-41c3-bcc4-160915eecf18": {
            componentOverrides: {
              "2edd75e1-65a7-4955-ba1c-88e3541d97f0": {
                props: { style: { opacity: "20%" } },
              },
              "9169e0dd-ccb1-4708-bcc3-301d3121b2df": {
                props: { style: { cursor: "default" } },
              },
            },
          },
        },
      },
    ],
  },
};

export const carouselWithImageIndicators: ComponentTemplate = {
  id: "80fbdd01-7ff7-4216-ba77-c4cff8eac3f9",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Carousel/Carousel With Image Indicators",
  leftBarCategory: { main: "interactive", subCategory: "carousel" },
  preview: "carousel/carousel-with-image-indicators.png",
  template: {
    id: "eb2d65c0-245e-45f9-9dad-0a2b6334ede4",
    name: "Carousel",
    type: "carouselV3",
    props: {
      style: {
        display: "flex",
        flexGrow: "unset",
        __flexGap: "24px",
        flexBasis: 0,
        alignItems: "flex-start",
        alignSelf: "start",
      },
      "style@sm": {
        flexDirection: "column-reverse",
      },
      _animationStyle: "fade",
    },
    children: [
      {
        id: "d5cdf256-447f-4cf2-991b-4a326bf9540f",
        name: "Indicators",
        type: "carouselV3Indicators",
        props: {
          style: {
            display: "flex",
            __flexGap: "12px",
            alignSelf: "stretch",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          },
          "style@sm": {
            flexDirection: "row",
          },
        },
        children: [
          {
            id: "b94e9677-cc71-4d93-b4e9-b218295983b0",
            name: "For Slide 1",
            type: "container",
            props: {
              style: {
                cursor: "pointer",
                display: "flex",
                flexGrow: "unset",
                flexBasis: 0,
                alignItems: "flex-start",
                justifyContent: "flex-start",
                opacity: "50%",
              },
            },
            children: [
              {
                id: "7678e778-3984-4fd7-822a-3dafc218db4e",
                name: "Yellow Chair Small",
                type: "image",
                props: {
                  src: "",
                  style: {
                    width: "80px",
                    height: "80px",
                    __width: "80px",
                    __height: "80px",
                    flexGrow: "unset",
                    flexBasis: "auto",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1586023492125-27b2c045efd7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80",
                    borderTopLeftRadius: "100%",
                    borderTopRightRadius: "100%",
                    borderBottomLeftRadius: "100%",
                    borderBottomRightRadius: "100%",
                  },
                  "style@sm": {
                    width: "64px",
                    __width: "64px",
                    height: "64px",
                    __height: "64px",
                  },
                  loading: "lazy",
                },
              },
            ],
            variants: [
              {
                id: "2fc40493-19d4-4063-ab1c-9b6c8c126cc2",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "08b75eeb-d10a-4504-addd-5b27bfe85bfb",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "b12d763b-41ce-416f-8413-848d43f79390",
                      field: "state.group.isCurrentItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "08b75eeb-d10a-4504-addd-5b27bfe85bfb": {
                componentOverrides: {
                  "b94e9677-cc71-4d93-b4e9-b218295983b0": {
                    props: {
                      style: {
                        opacity: "100%",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "70aff19a-6abf-4f6b-b8ea-0142ed4f9806",
            name: "For Slide 2",
            type: "container",
            props: {
              style: {
                cursor: "pointer",
                display: "flex",
                opacity: "50%",
                flexGrow: "unset",
                flexBasis: 0,
                alignItems: "flex-start",
                justifyContent: "flex-start",
              },
            },
            children: [
              {
                id: "4bbf8404-ddd2-4623-b177-8c37f0fd0f3a",
                name: "Orange Couch Small",
                type: "image",
                props: {
                  src: "",
                  style: {
                    width: "80px",
                    height: "80px",
                    __width: "80px",
                    __height: "80px",
                    flexGrow: "unset",
                    alignSelf: "flex-start",
                    flexBasis: "auto",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1616047006789-b7af5afb8c20?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80",
                    borderTopLeftRadius: "100%",
                    borderTopRightRadius: "100%",
                    borderBottomLeftRadius: "100%",
                    borderBottomRightRadius: "100%",
                  },
                  "style@sm": {
                    width: "64px",
                    __width: "64px",
                    height: "64px",
                    __height: "64px",
                  },
                  loading: "lazy",
                },
              },
            ],
            variants: [
              {
                id: "ff39390f-a8ec-4253-b1e9-876ec35dfe01",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "c9069894-1ec0-4108-a409-f0ec253e8848",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "e71b9830-523f-4d86-a920-09edd1f1b4d4",
                      field: "state.group.isCurrentItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "c9069894-1ec0-4108-a409-f0ec253e8848": {
                componentOverrides: {
                  "70aff19a-6abf-4f6b-b8ea-0142ed4f9806": {
                    props: {
                      style: {
                        opacity: "100%",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "e3e72abb-43a1-4b37-aec5-c57c37c85db8",
            name: "For Slide 3",
            type: "container",
            props: {
              style: {
                cursor: "pointer",
                display: "flex",
                opacity: "50%",
                flexGrow: "unset",
                flexBasis: 0,
                alignItems: "flex-start",
                justifyContent: "flex-start",
              },
            },
            children: [
              {
                id: "126b0531-91f3-47db-b920-cf45a62eb4c3",
                name: "Living Room Small",
                type: "image",
                props: {
                  src: "",
                  style: {
                    width: "80px",
                    height: "80px",
                    __width: "80px",
                    __height: "80px",
                    flexGrow: "unset",
                    alignSelf: "flex-start",
                    flexBasis: "auto",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1615875221248-d4b820203f97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80",
                    borderTopLeftRadius: "100%",
                    borderTopRightRadius: "100%",
                    borderBottomLeftRadius: "100%",
                    borderBottomRightRadius: "100%",
                  },
                  "style@sm": {
                    width: "64px",
                    __width: "64px",
                    height: "64px",
                    __height: "64px",
                  },
                  loading: "lazy",
                },
              },
            ],
            variants: [
              {
                id: "8b1986dc-80a3-458e-9c60-ccf60771144e",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "fc5ca624-d720-45ae-bc74-8303ad81faa6",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "b12d763b-41ce-416f-8413-848d43f79390",
                      field: "state.group.isCurrentItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "fc5ca624-d720-45ae-bc74-8303ad81faa6": {
                componentOverrides: {
                  "e3e72abb-43a1-4b37-aec5-c57c37c85db8": {
                    props: {
                      style: {
                        opacity: "100%",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "83493df8-3f45-4f98-9b35-06bb2dd480dd",
            name: "For Slide 4",
            type: "container",
            props: {
              style: {
                cursor: "pointer",
                display: "flex",
                opacity: "50%",
                flexGrow: "unset",
                flexBasis: 0,
                alignItems: "flex-start",
                justifyContent: "flex-start",
              },
            },
            children: [
              {
                id: "d9f86cf5-a640-4965-8a63-6e179f9d703a",
                name: "Minimal Setup Small",
                type: "image",
                props: {
                  src: "",
                  style: {
                    width: "80px",
                    height: "80px",
                    __width: "80px",
                    __height: "80px",
                    flexGrow: "unset",
                    alignSelf: "flex-start",
                    flexBasis: "auto",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1587117238257-8cacadca6ae4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80",
                    borderTopLeftRadius: "100%",
                    borderTopRightRadius: "100%",
                    borderBottomLeftRadius: "100%",
                    borderBottomRightRadius: "100%",
                  },
                  "style@sm": {
                    width: "64px",
                    __width: "64px",
                    height: "64px",
                    __height: "64px",
                  },
                  loading: "lazy",
                },
              },
            ],
            variants: [
              {
                id: "ff73c908-1a66-4e03-b61d-bd1ec7a3a9e7",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "03d7a27a-3c73-443c-bfb9-5ce505ae41a5",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "b12d763b-41ce-416f-8413-848d43f79390",
                      field: "state.group.isCurrentItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "03d7a27a-3c73-443c-bfb9-5ce505ae41a5": {
                componentOverrides: {
                  "83493df8-3f45-4f98-9b35-06bb2dd480dd": {
                    props: {
                      style: {
                        opacity: "100%",
                      },
                    },
                  },
                },
              },
            },
          },
        ],
      },
      {
        id: "5ccdbffe-aee0-4a65-ac77-f3fbf00e17d1",
        name: "Slides",
        type: "carouselV3Slides",
        props: {
          style: {
            width: "557px",
            height: "557px",
            __width: "557px",
            __height: "557px",
            flexGrow: "unset",
            overflow: "hidden",
            flexBasis: 0,
          },
          "style@sm": {
            width: "350px",
            __width: "350px",
            height: "350px",
            __height: "350px",
          },
        },
        children: [
          {
            id: "7397c77c-9ffa-41f8-abe8-368755f4e315",
            name: "Slide 1",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexGrow: "unset",
                flexBasis: 0,
                alignItems: "center",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "924a8905-4993-4955-88b6-8f7399ec57ac",
                name: "Yellow Chair Large",
                type: "image",
                props: {
                  src: "",
                  style: {
                    width: "100%",
                    height: "100%",
                    __width: "100%",
                    __height: "100%",
                    flexGrow: "unset",
                    flexBasis: 0,
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1586023492125-27b2c045efd7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1916&q=80",
                  },
                  loading: "lazy",
                },
              },
            ],
          },
          {
            id: "aa061e8c-8088-42c0-aebf-8008639e7e7f",
            name: "Slide 2",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexGrow: "unset",
                flexBasis: 0,
                alignItems: "center",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "fca4de5b-6a09-48e2-bf7b-031e0e0d8dfe",
                name: "Orange Couch Large",
                type: "image",
                props: {
                  src: "",
                  style: {
                    width: "100%",
                    height: "100%",
                    __width: "100%",
                    __height: "100%",
                    flexGrow: "unset",
                    flexBasis: "auto",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1616047006789-b7af5afb8c20?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1760&q=80",
                  },
                  loading: "lazy",
                },
              },
            ],
          },
          {
            id: "a6e362d9-0a8a-4166-aaaa-48b1a6ba6d0b",
            name: "Slide 3",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexGrow: "unset",
                flexBasis: 0,
                alignItems: "center",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "442927e7-e5f8-4aa6-9504-26ccd663f856",
                name: "Living Room Large",
                type: "image",
                props: {
                  src: "",
                  style: {
                    width: "100%",
                    height: "100%",
                    __width: "100%",
                    __height: "100%",
                    flexGrow: "unset",
                    flexBasis: "auto",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1615875221248-d4b820203f97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1760&q=80",
                  },
                  loading: "lazy",
                },
              },
            ],
          },
          {
            id: "8e2abbbb-4f91-4e0a-88df-f59ff7c64dde",
            name: "Slide 3",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexGrow: "unset",
                flexBasis: 0,
                alignItems: "center",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "b27262c5-27e9-4e66-a13c-8a139b6700c4",
                name: "Minimal Setup Large",
                type: "image",
                props: {
                  src: "",
                  style: {
                    width: "100%",
                    height: "100%",
                    __width: "100%",
                    __height: "100%",
                    flexGrow: "unset",
                    flexBasis: "auto",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1587117238257-8cacadca6ae4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
                  },
                  loading: "lazy",
                },
              },
            ],
          },
        ],
      },
    ],
  },
};
