import * as React from "react";

import { isNewRightBarUIEnabled } from "@editor/infra/featureFlags";
import { selectFontFamily } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import ModifierGroup from "@editorExtras/ModifierGroup";
import { FontFamilyControl } from "@editorModifiers/TextStyleModifier";

import { isNotNullish } from "replo-utils/lib/misc";

import DocumentationInfoIcon from "../DocumentationInfoIcon";

const FontModifier: React.FC<React.PropsWithChildren<unknown>> = () => {
  const fontFamily = useEditorSelector(selectFontFamily);
  return (
    <>
      {isNewRightBarUIEnabled() ? (
        <ModifierGroup
          title="Font"
          isDefaultOpen={isNotNullish(fontFamily)}
          titleEnhancer={
            <DocumentationInfoIcon href="https://support.replo.app/articles/1719322615-fonts" />
          }
        >
          <FontFamilyControl />
        </ModifierGroup>
      ) : (
        <ModifierGroup title="Font" isDefaultOpen={isNotNullish(fontFamily)}>
          <FontFamilyControl />
        </ModifierGroup>
      )}
    </>
  );
};

export default FontModifier;
