import type { AnalyticsReadQuery } from "schemas/generated/analyticsRead";

import * as React from "react";

import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";

import { DEFAULT_QUERY } from "@/features/analytics/constants";
import useWorkspaceUrlHosts from "@/features/analytics/useWorkspaceUrlHosts";
import isEqual from "lodash-es/isEqual";

import { AnalyticsQueryContext } from "./contexts/AnalyticsQueryContext";

const AnalyticsContext: React.FC<React.PropsWithChildren> = ({ children }) => {
  const workspaceId = useCurrentWorkspaceId();
  const workspaceUrlHostsResult = useWorkspaceUrlHosts(workspaceId);

  const [query, setQuery] = React.useState<AnalyticsReadQuery>({
    ...DEFAULT_QUERY,
    urlHosts: workspaceUrlHostsResult.data.map((host) => host.value),
  });

  const prevUrlHostResult = React.useRef(workspaceUrlHostsResult);

  React.useEffect(() => {
    if (!isEqual(workspaceUrlHostsResult, prevUrlHostResult.current)) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        urlHosts: workspaceUrlHostsResult.data.map((host) => host.value),
      }));
    }
    prevUrlHostResult.current = workspaceUrlHostsResult;
  }, [workspaceUrlHostsResult]);

  return (
    <AnalyticsQueryContext.Provider
      value={{
        query,
        setQuery,
        workspaceUrlHosts: workspaceUrlHostsResult,
      }}
    >
      {children}
    </AnalyticsQueryContext.Provider>
  );
};

export default AnalyticsContext;
