import type { DeleteReferralCodeModalProps } from "../AppModalTypes";

import * as React from "react";

import Button from "@common/designSystem/Button";
import Modal from "@common/designSystem/Modal";
import toast from "@common/designSystem/Toast";
import { ModalLayout } from "@common/ModalLayout";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";

export const DeleteReferralCodeModal: React.FC<
  DeleteReferralCodeModalProps
> = ({ code }) => {
  const { user } = useCurrentUser();
  const { closeModal } = useModal();
  const logEvent = useLogAnalytics();
  const workspaceId = useCurrentWorkspaceId();

  const { mutateAsync: deleteReferralCode } =
    trpc.referralCode.delete.useMutation({
      onSuccess: async () => {
        if (workspaceId) {
          toast({ header: "Referral Code Deleted" });
          logEvent("workspace.referralCode.deleted", {
            workspaceId: workspaceId,
            email: user?.email ?? "",
            code,
          });

          void trpcUtils.workspace.getById.invalidate({
            id: workspaceId,
          });
          void trpcUtils.referralCode.getReferredUsers.invalidate({
            workspaceId,
          });
          void trpcUtils.referralCode.getReferredWorkspaces.invalidate({
            workspaceId,
          });
        }
      },
    });

  const deleteCode = () => {
    if (!code || !workspaceId) {
      return;
    }

    void deleteReferralCode({
      code,
      workspaceId,
    }).finally(() => closeModal({ type: "deleteReferralCodeModal" }));
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => closeModal({ type: "deleteReferralCodeModal" })}
      className="w-auto"
    >
      <ModalLayout
        height="auto"
        width="25vw"
        minWidth={400}
        mainContent={() => (
          <div className="flex flex-col gap-6 p-2 w-full">
            <h2 className="text-xl font-medium">Delete Referral Code</h2>
            <p className="text-sm text-default">
              Once a referral code is deleted,{" "}
              <span className="font-bold">
                there will be no future payouts using this referral code
              </span>{" "}
              and no one will be able to use this referral code to sign up for
              Replo. <br />
              This action is permanent and cannot be undone.
            </p>
            <div className="flex gap-2 self-end">
              <Button
                type="secondary"
                size="base"
                textClassNames="text-xs"
                onClick={() => closeModal({ type: "deleteReferralCodeModal" })}
              >
                Cancel
              </Button>
              <Button
                type="danger"
                size="base"
                textClassNames="text-xs"
                onClick={() => void deleteCode()}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
      />
    </Modal>
  );
};
