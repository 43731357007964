import type { ToastProps } from "@common/designSystem/Toast";
import type {
  SetDraftElementPayload,
  UpdateEditorAction,
} from "@editor/actions/core-actions";
import type { EditorRootState } from "@editor/store";
import type { ComponentActionType } from "@editor/types/component-action-type";
import type { ReploComponentIssue } from "@editor/types/component-issues";
import type { CoreState, StreamingUpdate } from "@editor/types/core-state";
import type {
  GetAttributeDependencies,
  GetAttributeFunction,
} from "@editor/types/get-attribute-function";
import type { RichTextEditorTag } from "@editor/types/rich-text-editor";
import type { RightBarTab } from "@reducers/ui-reducer";
import type { PositionAttribute } from "@reducers/utils/core-reducer-utils";
import type { PayloadAction, Reducer, Selector } from "@reduxjs/toolkit";
import type { Exception } from "@sentry/react";
import type { AssetLoadingType } from "replo-runtime/shared/asset-loading";
import type {
  Component,
  ComponentData,
  ComponentStylesForChildren,
} from "replo-runtime/shared/Component";
import type { DataTable } from "replo-runtime/shared/DataTable";
import type { AlchemyActionType } from "replo-runtime/shared/enums";
import type {
  BorderSide,
  BorderSuffix,
  RuntimeStyleProperties,
  SupportedCssProperties,
} from "replo-runtime/shared/styleAttribute";
import type {
  PredefinedVariant,
  ProductRef,
  ProductsDependency,
  ReploShopifyOption,
  StoreProduct,
  VariantWithState,
} from "replo-runtime/shared/types";
import type { Context } from "replo-runtime/store/AlchemyVariable";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { ReploComponentType } from "schemas/component";
import type {
  ReploElement,
  ReploElementType,
  ReploElementVersionRevision,
  ReploSimpleElement,
} from "schemas/generated/element";
import type { ReploProject } from "schemas/generated/project";
import type { ConditionField, ReploVariant } from "schemas/generated/symbol";

import * as React from "react";

import {
  errorToast,
  toast,
  ToastCTALink,
  warningToast,
} from "@common/designSystem/Toast";
import { API_ACTIONS } from "@constants/action-types";
import EditorActionType from "@constants/editor-action-types";
import { createElementUpdatingThunk } from "@editor/actions/core-actions";
import { getComponentName } from "@editor/components/editor/component";
import { elementTypeToEditorData } from "@editor/components/editor/element";
import { trackError } from "@editor/infra/analytics";
import { EditorMode } from "@editor/types/core-state";
import {
  getVariants,
  memoizedGetAttributeRetriever,
} from "@editor/utils/component-attribute";
import { canvasToStyleMap } from "@editor/utils/editor";
import { getPageUrl } from "@editor/utils/element";
import {
  isImageSourceTooLongError,
  UpdateElementFailedError,
} from "@editor/utils/errors";
import getIssuesForComponent from "@editor/utils/getIssuesForComponent";
import { saveCurrentElementId } from "@editor/utils/localStorage";
import { objectId } from "@editor/utils/objectId";
import { getStoreData } from "@editor/utils/project-utils";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import { parseTextShadows } from "@editor/utils/textShadow";
import { trpcUtils } from "@editor/utils/trpc";
import {
  selectTemplateEditorProduct,
  selectTemplateEditorStoreProduct,
} from "@reducers/template-reducer";
import {
  getComponentMappingFromElement,
  getDefaultPositionAttribute,
  getFieldMapping,
  getHistoryAfterPatch,
  getNextElements,
  hardcodedActions,
  mergeElementsStateWithNewElement,
  updateElementMappingWithRevision,
} from "@reducers/utils/core-reducer-utils";
import {
  findAncestorComponent,
  findAncestorComponentData,
  findAncestorComponentOrSelf,
  findAncestorRepeatedIndex,
  findComponentById,
  findSymbolAncestor,
  getComponentData,
  getEdgeAttribute,
  getEditorComponentNode,
  getEditorComponentNodes,
  isModal,
} from "@utils/component";
import { calculateDependencies } from "@utils/dependencies";
import { expandAllSymbolsOfElement } from "@utils/editorSymbols";

import {
  selectActiveCanvas,
  selectCanvases,
} from "@/features/canvas/canvas-reducer";
import {
  createAction,
  createReducer,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import isEqual from "fast-deep-equal";
import { applyPatches, isDraft, original, produce } from "immer";
import filter from "lodash-es/filter";
import isString from "lodash-es/isString";
import omit from "lodash-es/omit";
import startCase from "lodash-es/startCase";
import reduceReducers from "reduce-reducers";
import { isDynamicDataValue } from "replo-runtime";
import { DependencyType } from "replo-runtime/shared/types";
import {
  findComponent,
  forEachComponentAndDescendants,
  getCustomPropDefinitions,
} from "replo-runtime/shared/utils/component";
import {
  getCurrentComponentContext,
  getCurrentComponentEditorData,
} from "replo-runtime/shared/utils/context";
import { getNormalizedFlexDirection } from "replo-runtime/shared/utils/flexDirection";
import {
  getFromRecordOrNull,
  mapNull,
} from "replo-runtime/shared/utils/optional";
import {
  buildPredefinedVariant,
  getVariantsWithState,
  hasVariantWithConditionField,
} from "replo-runtime/shared/variant";
import { executeAction } from "replo-runtime/store/AlchemyAction";
import { evaluateVariableAsString } from "replo-runtime/store/AlchemyVariable";
import { getRenderData } from "replo-runtime/store/components";
import { getProduct } from "replo-runtime/store/ReploProduct";
import { enhanceVariantsAndOptions } from "replo-runtime/store/utils/product";
import { removeFolderNameFromElementName } from "replo-utils/element";
import { filterNulls } from "replo-utils/lib/array";
import {
  coerceNumberToString,
  hasOwnProperty,
  isEmpty,
  isNotNullish,
} from "replo-utils/lib/misc";
import { isShopifyIntegrationEnabled } from "schemas/utils";
import { v4 as uuidv4 } from "uuid";

import { selectLocaleData } from "./commerce-reducer";

const INITIAL_HISTORY_STATE = {
  index: -1,
  lastOperation: null,
  operations: {},
  maxSize: Number.POSITIVE_INFINITY,
};

export const initialState: CoreState = {
  project: null,
  elements: {
    draftElementId: null,
    draftComponentId: null,
    draftSymbolInstanceId: null,
    draftRepeatedIndex: null,
    componentIdToDraftVariantId: {},
    draftSymbolId: null,
    mapping: {},
    versionMapping: {},
    isLoading: false,
    selectedRevisionId: null,
    elementRevisions: {},
    draftElementColors: [],
    draftElementFontFamilies: [],
    streamingUpdate: null,
  },
  symbols: {
    mapping: {},
  },
  dataTables: {
    draft: null,
    mapping: {},
    isLoading: false,
  },
  editorOptions: {
    isDebugPanelVisible: false,
  },
  history: INITIAL_HISTORY_STATE,
  isLoading: false,
  isPublishing: false,
  isPreviewMode: false,
  editorMode: EditorMode.edit,
  publishingError: null,
  elementRecentlyPublished: false,
  pendingElementUpdates: [],
  updatesSinceLastRequestFinished: 0,
  elementUpdateInProgress: false,
  componentDataMapping: {},
};

// #region Default reducer
const defaultReducer: Reducer<CoreState, UpdateEditorAction> = (
  draftState = initialState,
  action,
): CoreState => {
  // Note (Noah, 2024-08-17, REPL-13239): We're going to do a bunch of property
  // accesses for this state as part of generating the component data mapping,
  // and those are slow if we use immer's proxy. So, we grab the original state
  // to pass it through to the functions where the state is readonly.
  const originalState = (
    isDraft(draftState) ? original(draftState) : draftState
  ) as CoreState;
  switch (action.type) {
    case EditorActionType.UPDATE_ELEMENT:
      if (action.payload.projectId === originalState.project?.id) {
        const currentElement = {
          ...originalState.elements.mapping[action.payload.id],
          ...action.payload,
        };

        // Only update componentDataMapping and componentColors if the  element
        // to be updated is the draft element
        const { componentDataMapping, componentColors, componentFontFamilies } =
          currentElement.id === originalState.elements.draftElementId &&
          currentElement.component
            ? getComponentData({
                component: currentElement.component as Component,
                context: {
                  symbols: originalState.symbols.mapping,
                  componentDataMapping: originalState.componentDataMapping,
                },
              })
            : {
                componentDataMapping: originalState.componentDataMapping,
                componentColors: originalState.elements.draftElementColors,
                componentFontFamilies:
                  originalState.elements.draftElementFontFamilies,
              };

        return Object.assign({}, originalState, {
          elements: {
            ...originalState.elements,
            mapping: {
              ...originalState.elements.mapping,
              [action.payload.id]: currentElement,
            },
            draftElementColors: [...componentColors],
            draftElementFontFamilies: [...componentFontFamilies],
          },
          elementRecentlyPublished: false,
          componentDataMapping,
        });
      }
      return draftState;
    default:
      return draftState;
  }
};
// #endregion

// #region Elements slice
type CreateUpdateElementSuccessPayload = {
  success: true;
  elementId: string;
  shopifyPageId: string | null;
};

type CreateUpdateElementErrorPayload = {
  error: string;
  element: ReploElement;
  detail?: string;
};

// Note (Evan, 2024-01-03): This little type guard will help us handle the create/update element
// response in a cleaner way.
const createUpdateElementPayloadIsSuccess = (
  body: CreateUpdateElementSuccessPayload | CreateUpdateElementErrorPayload,
): body is CreateUpdateElementSuccessPayload => {
  return hasOwnProperty(body, "success") && body.success === true;
};

const elementsSlice = createSlice({
  name: "elements",
  initialState,
  reducers: {
    setDraftElement: (state, action: PayloadAction<SetDraftElementPayload>) => {
      /**
       * Note (Noah, 2021-06-17): Check for undefined here since we may use null
       * to remove a draft component id etc
       */
      const newDraftElementId =
        action.payload.id === undefined
          ? state.elements.draftElementId
          : action.payload.id;
      const newDraftComponentId =
        action.payload.componentId === undefined
          ? state.elements.draftComponentId
          : action.payload.componentId;
      const newDraftRepeatedIndex =
        action.payload.repeatedIndex === undefined
          ? state.elements.draftRepeatedIndex
          : action.payload.repeatedIndex;
      const newDraftSymbolId =
        action.payload.symbolId === undefined
          ? state.elements.draftSymbolId
          : action.payload.symbolId;
      const newComponentIdToDraftVariantId = action.payload.variantId
        ? // TODO (Chance 2023-11-10): Handle `null` case in payload types and remove assertion
          { [newDraftComponentId!]: action.payload.variantId }
        : null;

      const idToSave = newDraftElementId ?? state.elements.draftElementId;
      if (idToSave && state.elements.mapping[idToSave]?.projectId) {
        saveCurrentElementId(
          idToSave,
          state.elements.mapping[idToSave]!.projectId,
        );
      }

      let newDraftSymbolInstanceId =
        state.elements.draftSymbolInstanceId ?? null;
      const expandedElement = expandAllSymbolsOfElement(
        // TODO (Chance 2023-11-10): Handle `null` cases and remove assertion
        state.elements.mapping[newDraftElementId!] as ReploElement,
        state.symbols.mapping,
        state.elements.componentIdToDraftVariantId,
      );
      const draftComponent = findComponentById(
        expandedElement,
        newDraftComponentId,
      );
      const symbolAncestor = findSymbolAncestor(
        expandedElement,
        // TODO (Chance 2023-11-10): Handle `null` cases and remove assertion
        newDraftComponentId!,
      );
      if (symbolAncestor) {
        newDraftSymbolInstanceId = symbolAncestor.id;
      } else {
        newDraftSymbolInstanceId = null;
      }

      // Note (Noah, 2021-08-30): When we select a tab panel in the tree (or via
      // other means) we find the closest panels content component and automatically
      // activate the selected panel. This makes it better UX since the user doesn't
      // have to select the tab manually via the banner.
      const tabPanelAncestor = findAncestorComponentOrSelf(
        expandedElement,
        draftComponent?.id ?? null,
        (component) => {
          return component.type === "tabs__onePanelContent";
        },
      );

      const tabListSingleComponent = findAncestorComponentOrSelf(
        expandedElement,
        draftComponent?.id ?? null,
        (component) => {
          return component.type === "tabs__list";
        },
      );

      if (tabListSingleComponent) {
        const tabRepeatedIndex = findAncestorRepeatedIndex(
          expandedElement,
          draftComponent,
          newDraftRepeatedIndex,
          (component) => {
            return component.type === "tabs__list";
          },
        );

        const tabListChildComponent = tabListSingleComponent.children?.[0];

        const context =
          tabListChildComponent && isNotNullish(tabRepeatedIndex)
            ? getCurrentComponentContext(
                tabListChildComponent.id,
                tabRepeatedIndex,
              )
            : null;

        if (context) {
          const tabItem = context?.state?.tabsBlock?.currentTabsListItem;
          void executeAction(
            {
              id: "alchemyEditor:toggleTab",
              type: "activateTabId",
              value: {
                tabItemId: tabItem.id,
              },
            },
            {
              componentId: tabItem.id,
              componentContext: context,
              repeatedIndex: "",
              products: [],
              templateProduct: null,
            },
          );
        }
      }

      if (tabPanelAncestor) {
        const tabRepeatedIndex = findAncestorRepeatedIndex(
          expandedElement,
          draftComponent,
          newDraftRepeatedIndex,
          (component) => {
            return component.type === "tabs__onePanelContent";
          },
        );
        const context = mapNull(tabRepeatedIndex, (tabRepeatedIndex) =>
          getCurrentComponentContext(tabPanelAncestor.id, tabRepeatedIndex),
        );
        if (
          context &&
          context?.state?.tabsBlock?.activeTabId !== tabPanelAncestor.id
        ) {
          void executeAction(
            {
              id: "alchemyEditor:toggleTab",
              type: "activateTabId",
              value: {
                tabItemId: tabPanelAncestor.id,
              },
            },
            {
              componentId: tabPanelAncestor.id,
              componentContext: context,
              repeatedIndex: "",
              products: [],
              templateProduct: null,
            },
          );
        }
      }

      // Note (Noah, 2022-07-21): Don't create a new object for
      // componentIdToDraftVariantId if we don't need to, since
      // this would cause some components to rerender unnecessarily.
      const componentIdToDraftVariantId = newComponentIdToDraftVariantId
        ? {
            ...state.elements.componentIdToDraftVariantId,
            ...newComponentIdToDraftVariantId,
          }
        : state.elements.componentIdToDraftVariantId;

      // Note (Chance, 2023-06-28): We also don't want to update our element
      // state unless any of its values have changed, as this will trigger
      // unnecessary repaints of the canvas.
      const elementsToCompare: [any, any][] = [
        [state.elements.draftElementId, newDraftElementId],
        [state.elements.draftComponentId, newDraftComponentId],
        [state.elements.draftRepeatedIndex, newDraftRepeatedIndex],
        [state.elements.draftSymbolId, newDraftSymbolId],
        [state.elements.draftSymbolInstanceId, newDraftSymbolInstanceId],
        [
          state.elements.componentIdToDraftVariantId,
          componentIdToDraftVariantId,
        ],
      ];

      const elements = elementsToCompare.some(([a, b]) => !Object.is(a, b))
        ? {
            ...state.elements,
            draftElementId: newDraftElementId,
            draftComponentId: newDraftComponentId,
            draftRepeatedIndex: newDraftRepeatedIndex,
            draftSymbolId: newDraftSymbolId,
            draftSymbolInstanceId: newDraftSymbolInstanceId,
            componentIdToDraftVariantId,
          }
        : state.elements;

      // Note (Chance, 2023-06-28): If nothing in our state has changed at this
      // point we can just return it and skip a rerender everywhere.
      const newElementRecentlyPublished = false;

      state.elements = elements;
      state.elementRecentlyPublished = newElementRecentlyPublished;
    },
    createElement: (
      state,
      action: PayloadAction<{ element: ReploElement & { version: number } }>,
    ) => {
      const element = action.payload.element;
      state.elements = mergeElementsStateWithNewElement(
        state.elements,
        element,
      );
    },
    deleteElement: (state, action: PayloadAction<ReploElement["id"]>) => {
      const elementId = action.payload;
      if (elementId in state.elements.mapping) {
        state.elements.mapping = omit(state.elements.mapping, elementId);
      }
    },
    setDraftRepeatedIndex: (state, action: PayloadAction<string>) => {
      state.elements.draftRepeatedIndex = action.payload;
    },
    setElementsLoading: (state, action: PayloadAction<boolean>) => {
      state.elements.isLoading = action.payload;
    },
    setElementOnGetProject: (
      state,
      action: PayloadAction<{
        project: ReploProject;
        draftElementId?: string;
        elementMapping: Record<string, ReploSimpleElement>;
        versionMapping: Record<string, number>;
      }>,
    ) => {
      state.project = action.payload.project;

      state.elements = {
        ...state.elements,
        draftElementId: action.payload.draftElementId ?? null,
        mapping: action.payload.elementMapping as Record<string, ReploElement>,
        versionMapping: action.payload.versionMapping,
      };

      state.symbols.mapping = getFieldMapping(
        action.payload.project.symbols ?? [],
        "id",
      );

      state.dataTables.mapping = getFieldMapping(
        action.payload.project.dataTables ?? [],
        "id",
      );
      state.isLoading = false;
    },
    setPendingElementUpdate: (
      state,
      action: PayloadAction<ReploElement["id"]>,
    ) => {
      const pendingElementUpdates = new Set(state.pendingElementUpdates);
      pendingElementUpdates.add(action.payload);

      state.pendingElementUpdates = Array.from(pendingElementUpdates);
      state.updatesSinceLastRequestFinished =
        state.updatesSinceLastRequestFinished + 1;
    },
    setElementRevisions: (
      state,
      action: PayloadAction<
        Record<ReploElement["id"], ReploElementVersionRevision[]>
      >,
    ) => {
      state.elements.elementRevisions = {
        ...state.elements.elementRevisions,
        ...action.payload,
      };
    },
    restoreElementRevision: (state, action: PayloadAction<ReploElement>) => {
      state.editorMode = EditorMode.edit;
      state.elements.mapping = {
        ...state.elements.mapping,
        [state.elements.draftElementId!]: action.payload,
      };
      state.elements.versionMapping = {
        ...state.elements.versionMapping,
        [state.elements.draftElementId!]: action.payload.version,
      };
      state.elements.selectedRevisionId = null;
    },
    setSelectedRevisionId: (
      state,
      action: PayloadAction<ReploElementVersionRevision["id"] | null>,
    ) => {
      state.elements.selectedRevisionId = action.payload;
    },
    setComponentDataMappingStylesForChildren: (
      state,
      action: PayloadAction<Record<string, ComponentStylesForChildren>>,
    ) => {
      for (const [componentId, stylesForChildren] of Object.entries(
        action.payload,
      )) {
        const componentData = state.componentDataMapping?.[componentId];
        if (componentData) {
          componentData.stylesForChildren = JSON.stringify(stylesForChildren);
        }
      }
    },
    setStreamingUpdate: (
      state,
      action: PayloadAction<StreamingUpdate | null>,
    ) => {
      state.elements.streamingUpdate = action.payload;
    },

    // Note (Evan, 2024-06-04): Restore any originalStyles set before streaming.
    // BIG ASSUMPTION here is that the styles in originalStyles are not being modified by any streamed actions.
    // If you are using originalStyles, be careful of this!
    markStreamingUpdateFinished: (state) => {
      if (!state.elements.streamingUpdate) {
        return;
      }

      const originalStyles = state.elements.streamingUpdate?.originalStyles;

      if (!originalStyles) {
        return;
      }

      for (const [canvas, canvasStyles] of Object.entries(originalStyles)) {
        const styleKey = canvasToStyleMap[canvas as EditorCanvas];
        forEachComponentAndDescendants(
          state.elements.streamingUpdate.draftElementComponent,
          (component) => {
            if (canvasStyles[component.id]) {
              component.props[styleKey] = {
                ...component.props[styleKey],
                ...canvasStyles[component.id],
              };
            }
          },
        );
      }

      state.elements.streamingUpdate.isStreaming = false;
    },

    /**
     * Given a ComponentAction (with definitely-defined componentId), applies
     * that action to the draft element component copy in the streamingUpdate state.
     *
     * We essentially maintain a divergent copy of the draft element component, and an
     * array of actions that have been applied to it. This copy is only used for rendering -
     * if the changes are confirmed, we replay the actions on the actual draft element component.
     */
    handleStreamingAction: (
      state,
      action: PayloadAction<ComponentActionType & { componentId: string }>,
    ) => {
      if (!state.elements.streamingUpdate || !state.elements.draftElementId) {
        return;
      }

      // Note (Evan, 2024-06-04): A little confusing, here the payload of the Redux action
      // is the ComponentAction to apply.
      const componentAction = action.payload;

      state.elements.streamingUpdate.actions.push(componentAction);

      // Note (Evan, 2024-07-16): When setting text, we want to pass those values down through context
      // instead (for faster updates). So we split out any updated text values and add those to the map
      // in the redux store.
      if (
        componentAction.type === "setProps" &&
        componentAction.value.text &&
        isString(componentAction.value.text)
      ) {
        const { text, ...rest } = componentAction.value;

        // NOTE (Gabe 2024-09-10): If there is an unclosed html start or end tag
        // we block the text from being set since the html tag is in the process
        // of being generated and we don't want this to show in the editor.
        if (
          (text.at(0) === "<" && !/>/.test(text)) ||
          (/<\//.test(text) && text.at(-1) !== ">")
        ) {
          return;
        }

        state.elements.streamingUpdate.textMap[componentAction.componentId] =
          text;

        // Note (Evan, 2024-07-16): If rest is empty (meaning the only entry in the setProps action's value is text),
        // just return. Otherwise, we need to continue on to set the other props via the usual strategy. Note that
        // we do not increment the repaint key here, since we don't need to repaint the whole canvas when just the text
        // changes (the text components will rerender because of the useEditorOverrideTextValue hook).
        if (isEmpty(rest)) {
          return;
        }
      }

      // Note (Evan, 2024-06-07): To apply the componentAction to our copy of the draft element component, we
      // 1) create a copy of the state with our draftElementComponent swapped in
      const modifiedState = produce(state, (draft) => {
        if (
          state.elements.draftElementId &&
          state.elements.streamingUpdate?.draftElementComponent &&
          state.elements.mapping[state.elements.draftElementId]
        ) {
          // Note (Evan, 2024-06-07): We know this is defined (by checking)
          // but TS doesn't because it's a WritableDraft.
          // @ts-expect-error
          draft.elements.mapping[state.elements.draftElementId].component =
            state.elements.streamingUpdate.draftElementComponent;
        }
      });

      // 2) apply the component action to the modified state
      const { elements: nextElements } = getNextElements({
        action,
        state: modifiedState,
      });

      // 3) extract the next version of the draft element component, and save it to state
      const nextDraftElementComponent =
        nextElements[state.elements.draftElementId]?.component;
      if (nextDraftElementComponent) {
        state.elements.streamingUpdate.draftElementComponent =
          nextDraftElementComponent;
      }

      state.elements.streamingUpdate.repaintKey += 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      API_ACTIONS.CREATE_OR_UPDATE_ELEMENT.start,
      (
        state,
        action: PayloadAction<
          {},
          "CREATE_OR_UPDATE_ELEMENT_START",
          { elementId: ReploElement["id"] }
        >,
      ) => {
        const pendingElementUpdates = new Set(state.pendingElementUpdates);
        pendingElementUpdates.delete(action.meta.elementId);
        state.elements.isLoading = true;
        state.elementRecentlyPublished = false;
        state.pendingElementUpdates = Array.from(pendingElementUpdates);
        state.elementUpdateInProgress = true;
      },
    );
    builder.addCase(
      API_ACTIONS.CREATE_OR_UPDATE_ELEMENT.success,
      (
        state,
        action: PayloadAction<
          CreateUpdateElementSuccessPayload | CreateUpdateElementErrorPayload,
          "CREATE_OR_UPDATE_ELEMENT_SUCCESS",
          { elementId: ReploElement["id"]; isSettingHomepage: boolean }
        >,
      ) => {
        if (createUpdateElementPayloadIsSuccess(action.payload)) {
          const updatedElementId = action.payload.elementId;

          // NOTE (Fran 2024-04-08): If we are setting a new homepage, we need to remove all old homepages
          // elements.
          if (action.meta.isSettingHomepage) {
            const oldHomepagesElements = filter(
              state.elements.mapping,
              (element) => {
                return element.isHomepage && element.id !== updatedElementId;
              },
            );

            for (const element of oldHomepagesElements) {
              const elementWithHomepage = state.elements.mapping[element.id];
              if (elementWithHomepage) {
                elementWithHomepage.isHomepage = false;
              }
            }
          }

          state.elements.isLoading = false;
          state.elements.versionMapping = {
            ...state.elements.versionMapping,
            [updatedElementId]:
              (state.elements.versionMapping[updatedElementId!] ?? 0) + 1,
          };
          // Note (Evan, 2024-01-03): In some scenarios (REPL-9755) the shopify page id changes.
          // We update it here so editing continues to work without a refresh.
          if (
            action.payload.shopifyPageId &&
            state.elements.draftElementId === updatedElementId &&
            state.elements.mapping[state.elements.draftElementId] &&
            action.payload.shopifyPageId !==
              state.elements.mapping[state.elements.draftElementId!]
                ?.shopifyPageId
          ) {
            // Note (Evan, 2024-01-03): Because state is a WritableDraft here (because of immer), Typescript doesn't
            // recognize that state.elements.mapping[state.elements.draftElementId] is defined, even though we
            // check this in the if-statement. So we have to assert.
            state.elements.mapping[
              state.elements.draftElementId
            ]!.shopifyPageId = action.payload.shopifyPageId;
          }
          state.updatesSinceLastRequestFinished = 0;
          state.elementUpdateInProgress = false;
        } else if (
          ["unmatchedSaveVersion", "genericElementUpdateError"].includes(
            action.payload.error,
          )
        ) {
          if (action.payload.error === "unmatchedSaveVersion") {
            warningToast(
              "Someone else made a change",
              "We updated the page with the latest changes. Try again.",
            );
          } else {
            errorToast(
              "Failed Updating Element",
              action.payload.detail ??
                "This element could not be updated. Please try again or reach out to support@replo.app for help.",
            );
          }
          state.elements.isLoading = false;
          state.elements.mapping = {
            ...state.elements.mapping,
            [action.payload.element.id]: action.payload.element,
          };
          state.elements.versionMapping = {
            ...state.elements.versionMapping,
            [action.payload.element.id]: action.payload.element.version,
          };
          state.updatesSinceLastRequestFinished = 0;
          state.elementUpdateInProgress = false;
        }
      },
    );
    builder.addCase(
      API_ACTIONS.CREATE_OR_UPDATE_ELEMENT.error,
      (
        state,
        action: PayloadAction<
          {
            response: {
              key: string;
              element: ReploElement;
            };
            status: number;
          },
          "CREATE_OR_UPDATE_ELEMENT_ERROR",
          { elementId: ReploElement["id"] }
        >,
      ) => {
        state.elements.isLoading = false;
        state.updatesSinceLastRequestFinished = 0;
        state.elementUpdateInProgress = false;

        if (
          action.payload.response?.key?.startsWith("billingPlan") ||
          action.payload.response?.key?.startsWith("pathAlreadyInUse")
        ) {
          const element = action.payload.response.element;
          state.elements.mapping = {
            ...state.elements.mapping,
            [element.id]: element,
          };
          state.elements.versionMapping = {
            ...state.elements.versionMapping,
            [element.id]: element.version,
          };
        } else {
          // Note (Noah, 2024-05-27, REPL-11101): If there's a transient issue with the
          // connection, or for whatever reason the server fails to connect, there will
          // be no response. In these cases we don't want to track an error, since it's
          // transient and just pollutes our error logs. We do want to track other server
          // errors here out of an abundance of caution, since we've had data-loss issues
          // in the pase where e.g. if the endpoint responds with 400 incorrectly, we're
          // not notified.
          if (action.payload.response) {
            trackError(
              new UpdateElementFailedError({
                message: "Update element request failed",
                additionalData: {
                  key: action.payload.response?.key ?? "Unknown error",
                  payload: action.payload,
                  response: action.payload.response,
                  statusCode: action.payload.status,
                },
              }) as Exception,
            );
          }
        }
      },
    );
    builder.addCase(API_ACTIONS.PUBLISH_SNIPPET.start, (state) => {
      state.isPublishing = true;
    });
    builder.addCase(
      API_ACTIONS.PUBLISH_SNIPPET.success,
      (
        state,
        action: PayloadAction<
          { element: ReploElement },
          "PUBLISH_SNIPPET_SUCCESS"
        >,
      ) => {
        const element = action.payload.element;
        const pageEditorData = elementTypeToEditorData[element.type];
        let toastProps: ToastProps = {
          header: `${pageEditorData.singularDisplayName} Published`,
          message: `Your ${pageEditorData.singularDisplayName} was published to Shopify`,
        };
        if (element.type === "page") {
          const url = getPageUrl({
            storeUrl: getStoreData(state.project)?.url ?? undefined,
            shopifyPagePath: element.shopifyPagePath,
          });
          if (url) {
            toastProps = {
              ...toastProps,
              // NOTE (Chance 2024-03-22): Not a tsx file, we can change this
              // later if we want but this is the only React element
              cta: React.createElement(
                ToastCTALink,
                { to: url, rel: "noreferrer", target: "_blank" },
                "View Live Page",
              ),
            };
          }
        }

        toast(toastProps);

        state.isPublishing = false;
        state.elementRecentlyPublished = true;
        state.publishingError = null;
        state.elements.mapping = {
          ...state.elements.mapping,
          [element.id]: element,
        };

        void trpcUtils.element.findRevisions.invalidate({
          elementId: element.id,
        });
      },
    );
    builder.addCase(
      API_ACTIONS.PUBLISH_SNIPPET.error,
      (
        state,
        action: PayloadAction<{ response: unknown }, "PUBLISH_SNIPPET_ERROR">,
      ) => {
        state.isPublishing = false;
        state.publishingError = action.payload.response;
      },
    );
  },
});

export const {
  createElement,
  deleteElement,
  handleStreamingAction,
  markStreamingUpdateFinished,
  setDraftRepeatedIndex,
  setElementsLoading,
  setElementOnGetProject,
  setPendingElementUpdate,
  setElementRevisions,
  restoreElementRevision,
  setSelectedRevisionId,
  setDraftElement,
  setComponentDataMappingStylesForChildren,
  setStreamingUpdate,
} = elementsSlice.actions;
const elementsReducer = elementsSlice.reducer;
// #endregion

// #region Component action slice
const componentActionSlice = createSlice({
  name: "componentAction",
  initialState,
  reducers: {
    applyComponentRTKAction: (
      draftState,
      action: PayloadAction<ComponentActionType>,
    ) => {
      // Note (Noah, 2024-08-17, REPL-13239): We're going to do a bunch of property
      // accesses for this state as part of generating the component data mapping,
      // and those are slow if we use immer's proxy. So, we grab the original state
      // to pass it through to the functions where the state is readonly.
      const originalState = (
        isDraft(draftState) ? original(draftState) : draftState
      ) as CoreState;
      try {
        const payload = action.payload;
        const elementId =
          payload.elementId ?? originalState.elements.draftElementId!;
        const { elements, patches, inversePatches, updatedComponentIds } =
          getNextElements({
            action,
            state: originalState,
          });

        const currentElement = elements[elementId]!;

        const { componentDataMapping, componentColors, componentFontFamilies } =
          getComponentData({
            component: currentElement.component as Component,
            context: {
              symbols: originalState.symbols.mapping,
              componentDataMapping: originalState.componentDataMapping,
            },
          });

        draftState.elementRecentlyPublished = false;
        draftState.elements.mapping = elements;
        draftState.elements.draftElementColors = [...componentColors];
        draftState.elements.draftElementFontFamilies = [
          ...componentFontFamilies,
        ];
        if (!payload.shouldBypassHistory) {
          draftState.history = getHistoryAfterPatch({
            action,
            history: draftState.history,
            patches,
            inversePatches,
            elementId,
            componentIds: updatedComponentIds,
          });
        }
        draftState.componentDataMapping = componentDataMapping;
      } catch (error) {
        if (isImageSourceTooLongError(error)) {
          toast({
            header: "Invalid image source",
            message:
              "The image you selected contains a source value that is too long. This can happen when copying SVG content with embedded images. Please reach out to support@replo.app if you need assistance.",
            type: "error",
          });
        }
        throw error;
      }
    },
    setComponentVariant: (
      state,
      action: PayloadAction<{
        componentId?: Component["id"];
        variantId?: ReploVariant["id"];
      }>,
    ) => {
      if (action.payload.componentId) {
        state.elements.componentIdToDraftVariantId[action.payload.componentId] =
          action.payload.variantId;
      }
    },
  },
});

export const { applyComponentRTKAction, setComponentVariant } =
  componentActionSlice.actions;
const componentReducer = componentActionSlice.reducer;
// #endregion

// #region Editor options slice
const editorOptionsSlice = createSlice({
  name: "editorOptions",
  initialState,
  reducers: {
    setDebugPanelVisibility: (state, action: PayloadAction<boolean>) => {
      state.editorOptions = {
        ...state.editorOptions,
        isDebugPanelVisible: action.payload,
      };
    },
    setEditorMode: (state, action: PayloadAction<EditorMode>) => {
      const isPreviewMode = action.payload === EditorMode.preview;

      let elements = state.elements;
      if (state.elements.selectedRevisionId !== null) {
        elements = {
          ...state.elements,
          selectedRevisionId: null,
        };
      }

      const editorMode = action.payload;

      state.elements = elements;
      state.isPreviewMode = isPreviewMode;
      state.editorMode = editorMode;
    },
  },
});

const editorOptionsReducer = editorOptionsSlice.reducer;
export const { setDebugPanelVisibility, setEditorMode } =
  editorOptionsSlice.actions;
// #endregion

// #region Data table slice
const defaultDataTable: DataTable = {
  id: "",
  name: "New Data Collection",
  singularItemName: null,
  pluralItemName: null,
  data: {
    schema: [],
    rows: [],
  },
};

const dataTableSlice = createSlice({
  name: "editorOptions",
  initialState,
  reducers: {
    mappingDataTables: (state) => {
      const draftDataTable = state.dataTables.draft ?? defaultDataTable;
      state.dataTables = {
        ...state.dataTables,
        draft: draftDataTable,
        mapping: {
          ...state.dataTables.mapping,
          [draftDataTable.id]: draftDataTable,
        },
      };
    },
    setDraftDataTable: (
      state,
      action: PayloadAction<{ id: string | null }>,
    ) => {
      let draft = {
        ...defaultDataTable,
        id: uuidv4(),
      };

      if (action.payload.id) {
        const dataTableFromMapping =
          state.dataTables.mapping[action.payload.id];
        if (dataTableFromMapping) {
          draft = dataTableFromMapping;
        }
      }

      state.dataTables.draft = draft;
    },
    updateDraftDataTable: (state, action: PayloadAction<DataTable>) => {
      state.dataTables.draft = action.payload;
    },
    updateDraftDataTableRow: (
      state,
      action: PayloadAction<{
        rowIndex: number;
        schemaId: string;
        value: any;
      }>,
    ) => {
      const dataTable = state.dataTables.draft ?? defaultDataTable;
      const { rowIndex, schemaId, value } = action.payload;

      state.dataTables.draft = {
        ...dataTable,
        data: {
          ...dataTable.data,
          rows: dataTable.data.rows.map((row, index) => {
            return index === rowIndex
              ? {
                  ...row,
                  [schemaId]: value,
                }
              : row;
          }),
        },
      };
    },
  },
});

export const {
  mappingDataTables,
  setDraftDataTable,
  updateDraftDataTable,
  updateDraftDataTableRow,
} = dataTableSlice.actions;
const dataTableReducer = dataTableSlice.reducer;
// #endregion

// #region History reducer
// Note (Noah, 2022-08-19): These are not exported because they should not be used
// by themselves - they need to be used with createElementUpdatingThunk so that they
// get autosaved
const _undoOperation = createAction("history/undo");
const _redoOperation = createAction("history/redo");

export const undoOperation = () => {
  return createElementUpdatingThunk(_undoOperation(), (getState) => {
    const state = getState();
    const nextOperation =
      state.core.history.operations[state.core.history.index - 1];
    return nextOperation?.elementId ?? state.core.elements.draftElementId;
  });
};

export const redoOperation = () => {
  return createElementUpdatingThunk(_redoOperation(), (getState) => {
    const state = getState();
    const nextOperation =
      state.core.history.operations[state.core.history.index + 1];
    return nextOperation?.elementId ?? state.core.elements.draftElementId;
  });
};

export const resetHistory = createAction("history/reset");

const historyReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(_redoOperation, (state) => {
      // Early exit if there is nothing to redo
      if (!state.history.operations[state.history.index + 1]) {
        return;
      }

      const patch = state.history.operations[state.history.index + 1]!.redo;
      const next = applyPatches(state.elements.mapping, patch);
      state.elements.mapping = next;
      state.history.index = state.history.index + 1;
      state.history.lastOperation = "redo";
    })
    .addCase(_undoOperation, (state) => {
      // Early exit if there is nothing to undo
      if (!state.history.operations[state.history.index]) {
        return;
      }

      const patch = state.history.operations[state.history.index]!.undo;
      const next = applyPatches(state.elements.mapping, patch);
      state.elements.mapping = next;
      state.history.index = state.history.index - 1;
      state.history.lastOperation = "undo";
    })
    .addCase(resetHistory, (state) => {
      state.history = INITIAL_HISTORY_STATE;
    });
});
// #endregion

export const coreReducer = reduceReducers<CoreState>(
  initialState,
  defaultReducer as Reducer<CoreState>,
  historyReducer,
  elementsReducer,
  editorOptionsReducer,
  componentReducer,
  dataTableReducer,
) as Reducer<CoreState>;

// #region Basic selectors
export const selectElementsMapping = (state: EditorRootState) => {
  return state.core.elements.mapping;
};

/**
 * Select the current draft element as an object. WARNING: this selector should
 * usually not be used, since it will cause whatever component its used it to
 * re-render every time anything about any component of the element changes, since
 * that will result in a different object in-memory for the element. Instead of
 * using this, usually you should write a selector that returns the thing you want
 * about the element as a memoizable value (string, number, etc)
 */
export const selectDraftElement_warningThisWillRerenderOnEveryUpdate = (
  state: EditorRootState,
) => {
  return selectDraftElementFromCoreState(state.core) as ReploElement;
};

export const selectDraftComponentId = (state: EditorRootState) => {
  return state.core.elements.draftComponentId;
};

export const selectIsDraftComponentExists = (state: EditorRootState) => {
  return Boolean(state.core.elements.draftComponentId);
};

export const selectDraftRepeatedIndex = (state: EditorRootState) => {
  return state.core.elements.draftRepeatedIndex;
};

export const selectDraftRepeatedIndexLastComponent = (
  state: EditorRootState,
) => {
  const repeatedIndexPath = state.core.elements.draftRepeatedIndex;
  if (!repeatedIndexPath) {
    return null;
  }
  const repeatedIndexComponents = repeatedIndexPath.split(".");
  return Number(repeatedIndexComponents[repeatedIndexComponents.length - 1]);
};

export const selectDraftSymbolInstanceId = (state: EditorRootState) => {
  return state.core.elements.draftSymbolInstanceId;
};

export const selectSymbolsMapping = (state: EditorRootState) => {
  return state.core.symbols.mapping;
};

export const selectComponentDataMapping = (state: EditorRootState) => {
  return state.core.componentDataMapping;
};

export const selectComponentIdToDraftVariantId = (state: EditorRootState) => {
  return state.core.elements.componentIdToDraftVariantId;
};

export const selectStore = (state: EditorRootState) =>
  getStoreData(state.core.project);

export const selectIsShopifyIntegrationEnabled = (state: EditorRootState) =>
  isShopifyIntegrationEnabled(state.core.project);

export const selectProject = (state: EditorRootState) => state.core.project;

export const selectProjectId = (state: EditorRootState) => {
  return state.core.project?.id;
};

export const selectProjectName = (state: EditorRootState) => {
  return state.core.project?.name;
};

export const selectStoreUrl = (state: EditorRootState) => {
  return getStoreData(state.core.project)?.url;
};

export const selectStoreShopifyUrl = (state: EditorRootState) =>
  getStoreData(state.core.project)?.shopifyUrl;

export const selectElementIsLoading = (state: EditorRootState) =>
  state.core.elements.isLoading;

export const selectIsPublishing = (state: EditorRootState) =>
  state.core.isPublishing;

export const selectPublishingError = (state: EditorRootState) =>
  state.core.publishingError;

export const selectSelectedRevisionId = (state: EditorRootState) =>
  state.core.elements.selectedRevisionId;

export const selectPendingElementUpdatesSize = (state: EditorRootState) =>
  state.core.pendingElementUpdates.length;

/**
 * Select the draft component from the state, then return all the DOM nodes
 * which represent it. There may be multiple DOM nodes if the draft component is
 * inside a repeated list.
 *
 * @returns The nodes which represent the draft component, and the node which
 * the user clicked on to select it.
 *
 * TODO (Noah, 2022-06-12, REPL-2602): This selector is intentionally NOT
 * memoized since if we memoize it, there are problems when you pass the result
 * into a mutation observer - if the draft component just changed position,
 * we'll render a new element and thus get a new node, but that new node won't
 * appear until after React renders our tree, which means the result of the
 * memoized selector will be the stale node that USED to represent the draft
 * component before we moved it. Not memoizing means that we'll always get the
 * most up-to-date DOM node. There's probably some better way to do this, maybe
 * with useLayoutEffect?
 */
export const selectDraftComponentNodes = (
  state: EditorRootState,
  canvas: EditorCanvas,
) => {
  const draftElementId = selectDraftElementId(state);
  const draftComponentId = selectDraftComponentId(state);
  const canvases = selectCanvases(state);
  if (!draftElementId || !draftComponentId) {
    return [];
  }
  const targetCanvas = canvases[canvas];
  const targetDocument = targetCanvas.targetFrame?.contentDocument;
  const nodes = targetDocument
    ? getEditorComponentNodes(targetDocument, draftElementId, draftComponentId)
    : [];
  return nodes;
};

export const selectDataTablesMapping = (state: EditorRootState) => {
  return state.core.dataTables.mapping;
};

export const selectDataTables = (state: EditorRootState) => {
  return state.core.dataTables;
};

export const selectDraftDataTable = (state: EditorRootState) => {
  return state.core.dataTables.draft;
};

export const selectDraftComponentHasParent = (state: EditorRootState) =>
  isNotNullish(selectDraftParentComponentId(state));

// Special selector used in places where only the core state is available
export const selectDraftElementFromCoreState = (state: CoreState) => {
  return getFromRecordOrNull(
    state.elements.mapping,
    state.elements.draftElementId,
  );
};

export const selectUpdatesSinceLastRequestFinishedFromCoreState = (
  state: CoreState,
) => state.updatesSinceLastRequestFinished;

export const selectUpdatesSinceLastRequestFinished = (state: EditorRootState) =>
  selectUpdatesSinceLastRequestFinishedFromCoreState(state.core);

export const selectVersionMapping = (state: EditorRootState) => {
  return state.core.elements.versionMapping;
};

export const selectIsPreviewMode = (state: EditorRootState) => {
  return state.core.isPreviewMode;
};

export const selectEditorMode = (state: EditorRootState) =>
  state.core.editorMode;

export const selectInternalDebugModeOn = (state: EditorRootState) => {
  return state.core.editorOptions.isDebugPanelVisible;
};

export const selectHistoryState = (state: EditorRootState) =>
  state.core.history;

export const selectIsElementUpdateInProgressOrQueued = (
  state: EditorRootState,
) => {
  return (
    state.core.elementUpdateInProgress ||
    state.core.pendingElementUpdates.length > 0
  );
};

export const selectDraftElementColors = (state: EditorRootState) => {
  return state.core.elements.draftElementColors;
};

export const selectDraftElementFontFamilies = (state: EditorRootState) => {
  return state.core.elements.draftElementFontFamilies;
};

export const selectDraftElementVersionFromCoreState = (state: CoreState) => {
  if (!state.elements.draftElementId) {
    return null;
  }
  return state.elements.versionMapping[state.elements.draftElementId];
};

export const selectStreamingUpdate = (state: EditorRootState) => {
  return state.core.elements.streamingUpdate;
};

export const selectStreamingUpdateTextMap = (state: EditorRootState) => {
  return state.core.elements.streamingUpdate?.textMap;
};

export const selectStreamingUpdateActions = (state: EditorRootState) => {
  return state.core.elements.streamingUpdate?.actions;
};

export const selectStreamingUpdateIsStreaming = (state: EditorRootState) => {
  return state.core.elements.streamingUpdate?.isStreaming;
};

export const selectStreamingUpdateId = (state: EditorRootState) => {
  return state.core.elements.streamingUpdate?.id;
};

// #endregion

// #region Memoized selectors
export const selectPublishedElementsAmount = createSelector(
  selectElementsMapping,
  (elementsMapping) => {
    return Object.values(elementsMapping).filter(
      (element) => element.isPublished,
    ).length;
  },
);

export const selectComponentMapping = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => {
    return getComponentMappingFromElement(draftElement);
  },
);

export const selectDraftElementHashmarks = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => {
    const hashmarks = new Set<string>();
    forEachComponentAndDescendants(draftElement.component, (component) => {
      if (component.props._urlHashmark) {
        hashmarks.add(component.props._urlHashmark);
      }
      const componentVariantOverrides = component.variantOverrides;
      if (componentVariantOverrides) {
        Object.values(componentVariantOverrides).forEach(
          ({ componentOverrides }) => {
            if (componentOverrides) {
              Object.values(componentOverrides).forEach(({ props }) => {
                const urlHashmark = props?._urlHashmark;
                if (urlHashmark) {
                  hashmarks.add(urlHashmark);
                }
              });
            }
          },
        );
      }
    });
    return Array.from(hashmarks);
  },
);

export const selectIsLoading = (state: EditorRootState) => {
  return state.core.isLoading;
};

export const selectLoadableProject = createSelector(
  selectProject,
  selectIsLoading,
  (project, isLoading) => ({
    isLoading,
    project,
  }),
);

export const selectDraftComponentAccordionAncestor = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftComponentId,
  (element, componentId) => {
    return findAncestorComponent(
      element,
      componentId,
      ({ type }) => type === "accordionBlock",
    );
  },
);

export const selectTemplateShopifyProductIdsLength = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => {
    return draftElement?.templateShopifyProductIds?.length ?? 0;
  },
);

export const selectGetAttributeDependencies = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectComponentDataMapping,
  selectComponentMapping,
  selectDraftSymbolInstanceId,
  selectDraftComponentId,
  selectSymbolsMapping,
  selectComponentIdToDraftVariantId,
  (
    element,
    componentDataMapping,
    componentMapping,
    draftSymbolInstanceId,
    draftComponentId,
    symbolMapping,
    componentIdToDraftVariantId,
  ): Omit<GetAttributeDependencies, "activeCanvas"> => {
    return {
      element,
      componentDataMapping,
      componentMapping,
      draftSymbolInstanceId,
      symbolMapping,
      draftComponentId,
      componentIdToDraftVariantId,
    };
  },
);

// NOTE (Chance 2024-06-14): Because the active canvas is no longer a part of
// core state, we use this selector in cases where the device is provided as an
// argument in case it comes from some other context.
export const selectGetAttributeWithoutCanvas = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectGetAttributeDependencies,
  (_state: EditorRootState, activeCanvas: EditorCanvas) => activeCanvas,
  (draftElement, dependencies, activeCanvas): GetAttributeFunction => {
    // Note (Noah, 2022-02-21): Important that this selector is memoized,
    // because otherwise all components which used this selector would
    // rerender on every redux change, since functions are not memoizable
    return (component, attribute, defaults) => {
      return memoizedGetAttributeRetriever(
        component,
        { ...dependencies, activeCanvas },
        draftElement ? objectId(draftElement) : null,
      )(attribute, defaults);
    };
  },
);

export const selectGetAttribute = createSelector(
  (state: EditorRootState) => state,
  selectActiveCanvas,
  (state, activeCanvas) => selectGetAttributeWithoutCanvas(state, activeCanvas),
);

export const selectDraftElementId = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.id,
);

export const selectProductsIdsFromDraftElement = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDataTablesMapping,
  selectTemplateEditorProduct,
  selectLocaleData,
  selectTemplateEditorStoreProduct,
  (
    draftElement,
    dataTablesMapping,
    templateEditorProduct,
    locale,
    templateProduct,
  ) => {
    if (draftElement) {
      // TODO (Gabe 2024-09-16): We shouldn't have to call calculateDependencies
      // here. We should create a different function for obtaining the
      // productIds referenced by a component.
      const { dependencies: dependenciesMap } = calculateDependencies(
        draftElement.component,
        {
          dataTables: dataTablesMapping,
          productResolutionDependencies: {
            products: [],
            currencyCode: locale.activeCurrency,
            moneyFormat: locale.moneyFormat,
            language: locale.activeLanguage,
            templateProduct: templateProduct ?? null,
          },
          // Note (Noah, 2022-11-26): It's okay to send an empty mapping of metafield types
          // here because this call to calculateDependencies is only used to know which _products_
          // to fetch, and doesn't care about metafields
          metafieldsNamespaceKeyTypeMapping: { product: {}, variant: {} },
        },
      );
      const productsDependency = Object.values(dependenciesMap)
        .flat()
        .filter(
          (dependency) => dependency.type === DependencyType.products,
        ) as ProductsDependency[];

      let productIds = productsDependency.reduce(
        (productsIdArray: (string | number)[], dependency) => {
          return [...productsIdArray, ...dependency.productIds];
        },
        [],
      );

      // NOTE (Evan, 8/18/23) We have to add the template editor product as a
      // dependency for product templates
      if (
        draftElement.type === "shopifyProductTemplate" &&
        templateEditorProduct
      ) {
        // NOTE (Gabe 2023-08-28): The Template product must be the first
        // product in the array. This is because getProduct assumes that the
        // first product is the templatized product.
        productIds = [Number(templateEditorProduct.productId), ...productIds];
      }

      return productIds;
    }
    return [];
  },
);

export const selectRootComponentId = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.component?.id ?? null,
);

export const selectDoesExistDraftElement = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  Boolean,
);

export const selectRootComponent = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.component,
);

export const selectDraftElementProjectId = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.projectId,
);

export const selectDraftElementName = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => {
    return removeFolderNameFromElementName(draftElement?.name ?? "");
  },
);

export const selectDraftElementType = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.type || "page",
);

export const selectDraftElementHideDefaultHeader = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.hideDefaultHeader,
);

export const selectDraftElementHideDefaultFooter = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.hideDefaultFooter,
);

export const selectDraftElementHideShopifyAnnouncementBar = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.hideShopifyAnnouncementBar,
);

export const selectDraftElementIsPublished = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.isPublished,
);

export const selectDraftElementUseSectionSettings = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.useSectionSettings,
);

export const selectDraftElementPublishedAt = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.publishedAt,
);

export const selectDraftElementProductTemplateSlug = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.productTemplateSlug,
);

export const selectDraftElementShopifyBlogId = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.shopifyBlogId,
);

export const selectDraftElementShopifyPagePath = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.shopifyPagePath,
);

export const selectDraftElementIsTurbo = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.isTurbo ?? false,
);

export const selectDraftComponent = createSelector(
  selectComponentMapping,
  selectDraftComponentId,
  (componentMapping, draftComponentId) => {
    return (
      getFromRecordOrNull(componentMapping, draftComponentId)?.component ?? null
    );
  },
);

export const selectDraftComponentContext = createSelector(
  selectDraftComponentId,
  selectDraftRepeatedIndexLastComponent,
  (draftComponentId, draftRepeatedIndex) => {
    return (
      getCurrentComponentContext(draftComponentId, draftRepeatedIndex ?? 0) ??
      null
    );
  },
);

export type ComponentNameID = { id: string; name: string };
function createAllComponentNameOfSpecificTypeSelector(
  componentType: ReploComponentType,
) {
  return createSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
    (draftElement) => {
      if (!draftElement || !componentType) {
        return [];
      }
      const possibleComponents: ComponentNameID[] = [];
      forEachComponentAndDescendants(draftElement.component, (component) => {
        if (component.type === componentType) {
          possibleComponents.push({
            id: component.id,
            name: getComponentName(component, null),
          });
        }
      });
      return possibleComponents;
    },
  );
}
export const selectAllTextComponentNames =
  createAllComponentNameOfSpecificTypeSelector("text");
export const selectAllButtonComponents =
  createAllComponentNameOfSpecificTypeSelector("button");
export const selectAllImageComponents =
  createAllComponentNameOfSpecificTypeSelector("image");

export const selectComponent = createSelector(
  selectComponentMapping,
  (_: EditorRootState, componentId: string) => componentId,
  (componentMapping, componentId) => {
    return (
      getFromRecordOrNull(componentMapping, componentId)?.component ?? null
    );
  },
);

export type ReploComponentIssueWithComponentData = {
  componentId: string;
  componentData: ComponentData;
  issues: ReploComponentIssue[];
};

export const selectUnpublishableComponentIssues = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectComponentDataMapping,
  selectComponentMapping,
  selectLocaleData,
  selectTemplateEditorStoreProduct,
  (_state: EditorRootState, products: StoreProduct[]) => products,
  (
    draftElement,
    componentDataMapping,
    componentMapping,
    locale,
    templateProduct,
    products,
  ) => {
    const componentErrors: ReploComponentIssueWithComponentData[] = [];
    if (!componentDataMapping) {
      return componentErrors;
    }
    return Object.entries(componentDataMapping).reduce(
      (
        componentErrors: ReploComponentIssueWithComponentData[],
        [componentId, componentData],
      ) => {
        const mappingEntry = componentMapping[componentId];
        if (!mappingEntry) {
          return componentErrors;
        }
        const issues = getIssuesForComponent({
          type: "unpublishable",
          component: mappingEntry.component,
          context: {
            useSectionSettings:
              draftElement.useSectionSettings &&
              draftElement.type === "shopifySection",
            shopify: {
              products,
              locale,
              templateProduct: templateProduct ?? null,
            },
          },
        });
        if (issues.length > 0) {
          componentErrors.push({
            componentId,
            componentData,
            issues,
          });
        }
        return componentErrors;
      },
      [],
    );
  },
);

export const selectDraftComponentName = createSelector(
  selectDraftComponent,
  selectSymbolsMapping,
  (draftComponent, symbolsMapping) => {
    if (!draftComponent) {
      return null;
    }

    return getComponentName(draftComponent, symbolsMapping);
  },
);

export const selectHasDraftComponent = createSelector(
  selectDraftComponent,
  Boolean,
);

export const selectDraftComponentVariants = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftComponent,
  selectSymbolsMapping,
  selectComponentIdToDraftVariantId,
  (
    draftElement,
    draftComponent,
    symbolsMapping,
    componentIdToDraftVariantId,
  ): VariantWithState[] | [] => {
    if (!draftComponent || !draftElement) {
      return [];
    }

    return getVariantsWithState(
      getVariants(draftComponent, symbolsMapping),
      componentIdToDraftVariantId[draftComponent.id]!,
    );
  },
);

export const selectDraftComponentHasVariants = createSelector(
  selectDraftComponentVariants,
  (variants) => {
    return variants.length > 1;
  },
);

export const selectDraftComponentOrAncestorWithVariants = createSelector(
  selectComponentDataMapping,
  selectComponentMapping,
  selectDraftComponentId,
  selectSymbolsMapping,
  (
    componentDataMapping,
    componentMapping,
    draftComponentId,
    symbolsMapping,
  ) => {
    if (!draftComponentId) {
      return null;
    }

    const ancestorOrSelfWithVariantsId =
      componentDataMapping[draftComponentId]?.ancestorOrSelfWithVariantsId;
    const component = getFromRecordOrNull(
      componentMapping,
      ancestorOrSelfWithVariantsId,
    )?.component;

    if (!component) {
      return null;
    }

    return {
      ...component,
      name: getComponentName(component, symbolsMapping),
    };
  },
);

export const selectDraftComponentOrAncestorVariants = createSelector(
  selectDraftComponentOrAncestorWithVariants,
  selectSymbolsMapping,
  selectComponentIdToDraftVariantId,
  (component, symbolsMapping, componentIdToDraftVariantId) => {
    if (!component) {
      return [];
    }

    return getVariantsWithState(
      getVariants(component, symbolsMapping),
      componentIdToDraftVariantId[component.id]!,
    );
  },
);

export const selectDraftComponentOrAncestorVariantId = createSelector(
  selectDraftComponentOrAncestorVariants,
  (selectDraftComponentOrAncestorVariants) => {
    if (!selectDraftComponentOrAncestorVariants) {
      return undefined;
    }
    return selectDraftComponentOrAncestorVariants.find(
      (variant) => variant.isActive,
    )?.id;
  },
);

export const selectDraftComponentOrAncestorVariant = createSelector(
  selectDraftComponentOrAncestorVariants,
  (selectDraftComponentOrAncestorVariants) => {
    if (!selectDraftComponentOrAncestorVariants) {
      return null;
    }
    const activeVariant = selectDraftComponentOrAncestorVariants.find(
      (variant) => variant.isActive,
    );
    if (!activeVariant) {
      return null;
    }
    return {
      id: activeVariant.id,
      name: activeVariant.name,
    };
  },
);

export const selectNearestComponentWithVariantsName = createSelector(
  selectDraftComponentOrAncestorWithVariants,
  selectSymbolsMapping,
  (componentWithVariants, symbolMapping) => {
    if (!componentWithVariants) {
      return null;
    }
    return getComponentName(componentWithVariants, symbolMapping);
  },
);

export const selectNearestComponentWithVariantsId = createSelector(
  selectDraftComponentOrAncestorWithVariants,
  (componentWithVariants) => {
    return componentWithVariants?.id;
  },
);

export const selectNearestAncestorComponentWithVariants = createSelector(
  selectComponentIdToDraftVariantId,
  selectDraftComponentOrAncestorWithVariants,
  selectDraftComponentId,
  (
    componentIdToDraftVariantId,
    ancestorComponentWithVariants,
    draftComponentId,
  ) => {
    const ancestorComponentId = ancestorComponentWithVariants?.id;
    if (ancestorComponentId === draftComponentId) {
      return {
        variantsWithState: [],
        ancestorComponentId: null,
        ancestorComponentName: null,
      };
    }
    const ancestorVariants = ancestorComponentWithVariants?.variants ?? [];
    const ancestorComponentName = ancestorComponentWithVariants?.name;
    return {
      variantsWithState: getVariantsWithState(
        ancestorVariants,
        componentIdToDraftVariantId[ancestorComponentId!]!,
      ),
      ancestorComponentId,
      ancestorComponentName,
    };
  },
);

export const selectNearestAncestorHasVariants = createSelector(
  selectNearestAncestorComponentWithVariants,
  (ancestorComponentWithVariants) => {
    return ancestorComponentWithVariants.variantsWithState.length > 1;
  },
);

export const selectDraftComponentChildren = createSelector(
  selectDraftComponent,
  (draftComponent) => {
    if (!draftComponent) {
      return [];
    }
    return draftComponent.children ?? [];
  },
);

export const selectPredefinedVariantsForDraftComponent = createSelector(
  selectDraftComponent,
  selectComponentDataMapping,
  selectDraftComponentOrAncestorVariants,
  (draftComponent, componentDataMapping, selfOrAncestorVariants) => {
    if (!draftComponent) {
      return [];
    }

    const componentData = componentDataMapping[draftComponent.id];

    const hasHoverVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "interaction.hover",
    );
    const predefinedVariants: PredefinedVariant[] = !hasHoverVariant
      ? [buildPredefinedVariant("hover")]
      : [];

    // NOTE (Fran 2024-04-15): It might be nice to convert this to an exhaustive switch in the future. For
    // now, I think it is okay to have only one if statement.
    const hasLoadingVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.action.loading",
    );
    if (draftComponent?.type === "button" && !hasLoadingVariant) {
      predefinedVariants.push(buildPredefinedVariant("loading"));
    }

    const hasProductAncestor = componentData?.ancestorComponentData.some(
      (ancestorComponentType) => ancestorComponentType.includes("product"),
    );
    const hasOutOfStockVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.product.selectedVariantUnavailable",
    );
    if (hasProductAncestor && !hasOutOfStockVariant) {
      predefinedVariants.push(buildPredefinedVariant("outOfStock"));
    }

    const hasTabAncestor = componentData?.ancestorComponentData.some(
      (ancestorComponentType) =>
        ancestorComponentType.includes("tabsV2__block") ||
        ancestorComponentType.includes("tabsV2__list") ||
        ancestorComponentType.includes("tabsV2__panelsContent"),
    );
    const hasActiveTabVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.tabsV2Block.isCurrentTab",
    );
    if (hasTabAncestor && !hasActiveTabVariant) {
      predefinedVariants.push(buildPredefinedVariant("activeTab"));
    }

    const hasOptionsAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "optionSelect",
    );
    const hasSelectedOptionVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.product.selectedOptionValues",
    );
    if (hasOptionsAncestor && !hasSelectedOptionVariant) {
      predefinedVariants.push(buildPredefinedVariant("selectedOption"));
    }

    const hasVariantAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "variantSelect",
    );
    const hasSelectedVariantVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.product.selectedVariant",
    );
    if (hasVariantAncestor && !hasSelectedVariantVariant) {
      predefinedVariants.push(buildPredefinedVariant("selectedVariant"));
    }

    const hasSellingPlanAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "sellingPlanSelect",
    );
    const hasSelectedSellingPlanVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.product.selectedSellingPlan",
    );
    if (hasSellingPlanAncestor && !hasSelectedSellingPlanVariant) {
      predefinedVariants.push(buildPredefinedVariant("selectedSellingPlan"));
    }

    const hasCollapsibleAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "collapsibleV2",
    );
    const hasCollapsibleOpenVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.collapsibleV2.isOpen",
    );
    if (hasCollapsibleAncestor && !hasCollapsibleOpenVariant) {
      predefinedVariants.push(buildPredefinedVariant("collapsibleOpen"));
    }

    const hasBeforeAndAfterAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "beforeAfterSliderThumb",
    );
    const hasBeforeAndAfterDraggingVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.beforeAfterSlider.isDragging",
    );
    if (hasBeforeAndAfterAncestor && !hasBeforeAndAfterDraggingVariant) {
      predefinedVariants.push(buildPredefinedVariant("beforeAfterDragging"));
    }

    const hasTooltipAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "tooltip",
    );
    const hasTooltipOpenVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.tooltip.isOpen",
    );
    if (hasTooltipAncestor && !hasTooltipOpenVariant) {
      predefinedVariants.push(buildPredefinedVariant("tooltipOpen"));
    }

    const hasSelectionListAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "selectionList",
    );
    const hasSelectedOptionListVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.selectionList.isItemSelected",
    );
    if (hasSelectionListAncestor && !hasSelectedOptionListVariant) {
      predefinedVariants.push(buildPredefinedVariant("selectedListItem"));
    }

    return predefinedVariants;
  },
);

export const selectDraftComponentConditionFields = createSelector(
  selectDraftComponent,
  selectGetAttribute,
  selectPredefinedVariantsForDraftComponent,
  (draftComponent, getAttribute, predefinedVariants) => {
    if (draftComponent) {
      const conditionFields: Set<ConditionField> = new Set([
        "screen.pageY",
        "element.offsetY",
        "page.hashmark",
        "interaction.hover",
        ...(getCurrentComponentEditorData(draftComponent.id ?? "")
          ?.variantTriggers ?? []),
      ]);
      const onClickActions = getAttribute(draftComponent, "props.onClick");
      if (onClickActions.value) {
        conditionFields.add("state.action.loading");
      }

      // NOTE (Fran 2024-04-30): Adding predefined variant condition fields to the draft component
      // condition fields allows the user to create a variant based on the predefined variants.
      // Otherwise, there are some cases where, for example, a button can have a predefined variant
      // for loading, but the user won't see it in the variant edition popover.
      for (const predefinedVariant of predefinedVariants) {
        conditionFields.add(predefinedVariant.query.statements[0]!.field);
      }

      return Array.from(conditionFields);
    }
    return [];
  },
);

export const selectDraftComponentText = createSelector(
  selectDraftComponent,
  selectGetAttribute,
  (draftComponent, getAttribute) => {
    if (!draftComponent) {
      return null;
    }

    return getAttribute(draftComponent, "props.text")?.value;
  },
);

export const selectDraftComponentTextTag = createSelector(
  selectDraftComponentText,
  (text): RichTextEditorTag | undefined => {
    if (!text) {
      return undefined;
    }
    if (text.startsWith("<h1>")) {
      return "1";
    } else if (text.startsWith("<h2>")) {
      return "2";
    } else if (text.startsWith("<h3>")) {
      return "3";
    }
    return "P";
  },
);

export const selectDraftParentComponent = createSelector(
  selectComponentMapping,
  selectDraftComponentId,
  (componentMapping, draftComponentId) => {
    return componentMapping[String(draftComponentId)]?.parentComponent || null;
  },
);

export const selectDraftParentComponentId = createSelector(
  selectDraftParentComponent,
  (draftParentComponent) => {
    if (!draftParentComponent) {
      return null;
    }

    return draftParentComponent.id;
  },
);

export const selectedDraftComponentIsRoot = createSelector(
  selectDraftComponentHasParent,
  (draftComponentHasParent) => {
    return !draftComponentHasParent;
  },
);

export const selectProjectOrStoreName = createSelector(
  selectProjectName,
  selectStoreUrl,
  selectStoreShopifyUrl,
  (projectName, storeUrl, storeShopifyUrl) => {
    if (projectName) {
      return projectName;
    }

    if (storeUrl) {
      return startCase(storeUrl);
    }

    if (storeShopifyUrl) {
      return startCase(storeShopifyUrl.replace(".myshopify.com", ""));
    }

    return "";
  },
);

function createElementTypeSelector(type: ReploElementType) {
  return createSelector(selectElementsMapping, (elementsMapping) => {
    return Object.values(elementsMapping).filter((value) => {
      return value.type === type;
    });
  });
}

export const selectPages = createElementTypeSelector("page");
export const selectProductTemplates = createElementTypeSelector(
  "shopifyProductTemplate",
);
export const selectArticles = createElementTypeSelector("shopifyArticle");
export const selectSections = createElementTypeSelector("shopifySection");

export const selectElementMetadata = createSelector(
  selectElementsMapping,
  (_: EditorRootState, elementType: ReploElementType) => {
    return elementType;
  },
  (elementsMapping, elementType) => {
    return Object.values(elementsMapping)
      .filter((element) => element.type === elementType)
      .map((element) => ({
        id: element.id,
        name: element.name,
        type: element.type,
        isPublished: element.isPublished,
        isHomepage: element.isHomepage,
        isTurbo: element.isTurbo,
        createdAt: element.createdAt,
        publishedAt: element.publishedAt,
        projectId: element.projectId,
      }));
  },
);

export const selectElementById = createSelector(
  selectElementsMapping,
  (_: EditorRootState, elementId: string) => {
    return elementId;
  },
  (elementsMapping, elementId) => {
    return elementsMapping[elementId];
  },
);

export const selectDraftComponentNode = createSelector(
  selectDraftComponentNodes,
  selectDraftRepeatedIndex,
  (nodes, draftRepeatedIndex) => {
    if (nodes.length === 0) {
      return null;
    }

    const selectedNode: HTMLElement | undefined =
      nodes.find((n) => n.dataset.reploRepeatedIndex === draftRepeatedIndex) ??
      nodes[0];

    return selectedNode || null;
  },
);

/**
 * @returns all the descendant nodes of the draft component that satisfy the
 * evaluator function (including the draft component itself)
 */
export const selectDraftComponentDescendantNodes = createSelector(
  selectDraftElementId,
  selectDraftComponent,
  selectCanvases,
  (_: EditorRootState, canvas: EditorCanvas) => canvas,
  (
    _state: EditorRootState,
    _canvas: EditorCanvas,
    evaluator: (component: Component) => boolean,
  ) => evaluator,
  (draftElementId, draftComponent, canvases, canvas, evaluator) => {
    const contentDocument = canvases[canvas]?.targetFrame?.contentDocument;
    if (!contentDocument) {
      return null;
    }

    const result: HTMLElement[] = [];
    forEachComponentAndDescendants(draftComponent, (component) => {
      if (evaluator(component)) {
        const htmlElement = getEditorComponentNode(
          contentDocument,
          draftElementId,
          component.id,
        );
        if (htmlElement) {
          result.push(htmlElement);
        }
      }
    });
    return result;
  },
);

export const selectDraftComponentNodeFromActiveCanvas = createSelector(
  (state: EditorRootState) => state,
  selectActiveCanvas,
  (state, activeCanvas) => {
    return selectDraftComponentNode(state, activeCanvas);
  },
);

export const selectDraftParentComponentNode = createSelector(
  selectDraftElementId,
  selectDraftParentComponentId,
  selectDraftRepeatedIndex,
  selectCanvases,
  (_state: EditorRootState, canvas: EditorCanvas) => canvas,
  (draftElementId, parentComponentId, draftRepeatedIndex, canvases, canvas) => {
    if (!draftElementId || !parentComponentId) {
      return null;
    }
    const targetDocument = canvases[canvas].targetFrame?.contentDocument;
    const parentComponentNode = targetDocument
      ? getEditorComponentNodes(
          targetDocument,
          draftElementId,
          parentComponentId,
        ).find((node) =>
          draftRepeatedIndex?.startsWith(node.dataset.reploRepeatedIndex!),
        ) ?? null
      : null;
    return parentComponentNode;
  },
);

export const selectDraftComponentCustomProps = createSelector(
  selectDraftComponent,
  (draftComponent) => {
    if (!draftComponent) {
      return null;
    }
    return getCustomPropDefinitions(draftComponent).filter(
      (def) => def.type !== "component",
    );
  },
);

export const selectDraftComponentRightBarTabs = createSelector(
  selectDraftComponent,
  selectDraftComponentCustomProps,
  selectGetAttribute,
  (draftComponent, customProps, getAttribute) => {
    if (!draftComponent) {
      return null;
    }

    const clickActions = getAttribute(draftComponent, "props.onClick", {
      defaultValue: [],
    }).value;
    const hoverActions = getAttribute(draftComponent, "props.onHover", {
      defaultValue: [],
    }).value;
    const actions = [...clickActions, ...hoverActions];
    const animations = draftComponent?.animations ?? [];

    const interactionsCount = actions.length + animations.length;

    const tabOptions: {
      value: RightBarTab;
      label: string;
      isVisible?: boolean;
    }[] = [
      // NOTE (Reinaldo, 2022-04-29): Hide the design tab for modals since with modal you can only edit its content's design
      {
        value: "design",
        label: "Design",
        isVisible: !isModal(draftComponent.type),
      },
      {
        value: "custom",
        label: "Config",
        isVisible: !isEmpty(customProps),
      },
      {
        value: "interactions",
        label: `Interactions${
          interactionsCount > 0 ? ` (${interactionsCount})` : ""
        }`,
      },
      {
        value: "accessibility",
        label: "Accessibility",
        isVisible: Boolean(
          getRenderData(draftComponent.type)?.showAccessibilityMenu,
        ),
      },
    ];
    return tabOptions;
  },
);

export const selectDraftComponentEditorProps = createSelector(
  selectDraftComponent,
  (draftComponent) => {
    if (!draftComponent) {
      return null;
    }

    return getRenderData(draftComponent.type)?.editorProps;
  },
);

export const selectDraftComponentAncestorWithEditorProps = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftComponent,
  (draftElement, draftComponent) => {
    if (!draftElement || !draftComponent) {
      return null;
    }

    return findAncestorComponent(
      draftElement,
      draftComponent.id,
      (component) => {
        const renderData = getRenderData(component.type);
        return (
          Boolean(renderData?.editorProps) ||
          Boolean(renderData?.showComponentControlsFromChildren)
        );
      },
    );
  },
);

export const selectDraftComponentAncestorOrSelfWithSpecificType =
  createSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
    selectDraftComponent,
    (_: EditorRootState, componentType: ReploComponentType) => componentType,
    selectDraftComponent,
    (draftElement, draftComponent, componentType) => {
      if (!draftElement || !draftComponent || !componentType) {
        return null;
      }

      return findAncestorComponentOrSelf(
        draftElement,
        draftComponent.id,
        (component) => {
          return Boolean(component.type === componentType);
        },
      );
    },
  );

function createAncestorOrSelfWithSpecificTypeSelector(
  componentType: ReploComponentType,
) {
  return createSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
    selectDraftComponent,
    (draftElement, draftComponent) => {
      if (!draftElement || !draftComponent || !componentType) {
        return null;
      }

      return findAncestorComponentOrSelf(
        draftElement,
        draftComponent.id,
        (component) => {
          return Boolean(component.type === componentType);
        },
      );
    },
  );
}

export const selectAncestorOrSelfWithProductType =
  createAncestorOrSelfWithSpecificTypeSelector("product");

export const selectAncestorOrSelfWithProductCollectionType =
  createAncestorOrSelfWithSpecificTypeSelector("productCollection");

export const selectDraftComponentAncestorWithEditorPropsNode = createSelector(
  selectDraftElementId,
  selectDraftComponentAncestorWithEditorProps,
  selectCanvases,
  (_state: EditorRootState, canvas: EditorCanvas) => canvas,
  (draftElementId, ancestorWithEditorProps, canvases, canvas) => {
    if (!draftElementId || !ancestorWithEditorProps) {
      return null;
    }

    const targetDocument = canvases[canvas].targetFrame?.contentDocument;
    const nodes = targetDocument
      ? getEditorComponentNodes(
          targetDocument,
          draftElementId,
          ancestorWithEditorProps.id,
        )
      : [];
    return nodes[0] ?? null;
  },
);

/**
 * This selector is used to access text on components that are not Text
 * components. It is useful specifically for legacy buttons that have text on
 * them.
 */
export const selectNonTextComponentText = createSelector(
  selectDraftComponent,
  (draftComponent) => {
    if (draftComponent?.type !== "text") {
      return draftComponent?.props?.text;
    }
    return undefined;
  },
);

export const selectHasTextProp = createSelector(
  selectDraftComponentText,
  Boolean,
);

export const selectDraftComponentType = createSelector(
  selectDraftComponent,
  selectSymbolsMapping,
  (draftComponent, symbols) => {
    if (!draftComponent) {
      return null;
    }
    const type =
      draftComponent.type === "symbolRef"
        ? symbols[`${draftComponent.symbolId}.component.type`] ?? "unknown"
        : draftComponent.type;
    return type as ReploComponentType;
  },
);

export const selectDraftComponentTypeIsModal = createSelector(
  selectDraftComponentType,
  (draftComponentType) => {
    // Note (Noah, 2023-10-16): For symbols, draftComponentType will be
    // unknown. We assume that symbols are not modals for now, since
    // this will cause crashes elsewhere
    // @ts-expect-error
    return draftComponentType && draftComponentType !== "unknown"
      ? isModal(draftComponentType)
      : false;
  },
);

export const selectDraftComponentAcceptsArbitraryChildren = createSelector(
  selectDraftComponentType,
  selectHasTextProp,
  (draftComponentType, hasTextProp) => {
    if (!draftComponentType) {
      return false;
    }
    const renderData = getRenderData(draftComponentType);
    return Boolean(
      renderData?.acceptsArbitraryChildren({
        hasTextProp,
        movementSource: "gridModifier",
      }),
    );
  },
);

export const selectIsDraftComponentDynamic = createSelector(
  selectDraftComponent,
  selectDraftComponentContext,
  (component, context) => {
    const componentType = component?.type;
    if (!componentType || !context) {
      return false;
    }
    const renderData = getRenderData(componentType);
    // for the component type those are always dynamic
    const isAlwaysDynamic = renderData?.isAlwaysDynamic;
    const dynamicItemsPropName = renderData?.dynamicItemsPropName;
    // For components which might be dynamic based on props like _items/_products
    const isDynamicFromItemProp = Boolean(
      dynamicItemsPropName && component?.props[dynamicItemsPropName],
    );

    // Note (Ovishek, 2022-11-18): For some complex components like tabs/carousels knowing if the component
    // dynamic/repeated is complex, b/c the _items prop belongs to any ancestor parents, for example, for tabsList, _items
    // belongs to tabsBlock component which is it's parent.
    const isDynamicFromContext =
      renderData?.extractIsDynamicFromContext?.(context) ?? false;
    return isAlwaysDynamic || isDynamicFromContext || isDynamicFromItemProp;
  },
);

export const selectDraftComponentPositionKey = createSelector(
  selectDraftComponent,
  selectGetAttribute,
  (draftComponent, getAttribute): (PositionAttribute | "center")[] | [] => {
    if (!draftComponent) {
      return [];
    }

    const vertical = getDefaultPositionAttribute(
      ["top", "bottom"],
      draftComponent,
      getAttribute,
    );
    const horizontal = getDefaultPositionAttribute(
      ["left", "right"],
      draftComponent,
      getAttribute,
    );

    return [vertical, horizontal];
  },
);

export const selectDraftComponentActions = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftComponentId,
  selectDraftComponentType,
  (draftElement, draftComponentId, draftComponentType) => {
    // TODO (Noah, 2021-12-02): How actions appear based on context is currently
    // pretty screwed, they either come from hardcoded actions, or through this
    // logic which looks at ancestors, or they're automatically loaded if their
    // case name in AlchemyAction matches a key of an ancestor's context's action
    // hooks, which is super hacky. We should figure out a different way to represent
    // this, maybe something like storing a mapping of ActionType: true in the context
    // like we do for variant triggers
    const actionsFromAncestors =
      getCurrentComponentEditorData(draftComponentId)?.actions ?? [];

    let shouldShowActiveAlchemyVariant = false;
    forEachComponentAndDescendants(
      draftElement?.component ?? null,
      (component) => {
        const variants = component.variants;
        if (variants) {
          shouldShowActiveAlchemyVariant = true;
        }
      },
    );

    let actions: AlchemyActionType[] = [
      ...hardcodedActions,
      ...actionsFromAncestors,
    ];
    if (shouldShowActiveAlchemyVariant) {
      actions.push("setActiveAlchemyVariant");
    }

    if (draftComponentType) {
      const shouldHavePosibilityOfSpecialLinks = ["button", "text"].includes(
        draftComponentType,
      );
      if (shouldHavePosibilityOfSpecialLinks) {
        actions.push("phoneNumber");
      }
    }

    const ancestorTypeToActionTypes = {
      collectionSelect: ["setCurrentCollectionSelection"],
      player: ["togglePlay"],
      modal: ["closeModalComponent"],
      carousel: ["carouselNext", "carouselPrevious"],
      product: ["setActiveSellingPlan"],
    } as const;

    for (const [ancestorType, ancestorActions] of Object.entries(
      ancestorTypeToActionTypes,
    )) {
      if (
        draftElement &&
        findAncestorComponent(draftElement, draftComponentId, (component) => {
          return component.type === ancestorType;
        })
      ) {
        actions = [...actions, ...ancestorActions];
      }
    }

    const componentTypeFnToActionTypes = [
      [isModal, ["openModal"]],
      [
        (type: ReploComponentType) => type == "klaviyoEmbed",
        ["openKlaviyoModal"],
      ],
    ] as const;

    const matchingActionTypes =
      draftElement &&
      componentTypeFnToActionTypes.find(([typeFn]) =>
        findComponent(draftElement, (component) => typeFn(component.type)),
      )?.[1];
    if (matchingActionTypes) {
      actions = [...actions, ...matchingActionTypes];
    }

    return actions;
  },
);

export const selectDraftComponentAnimations = createSelector(
  selectDraftComponent,
  (draftComponent) => {
    if (!draftComponent) {
      return null;
    }

    return draftComponent.animations;
  },
);

/**
 * This selector is useful for when we need to know if the current component has been removed.
 *
 * E.g.: In case we duplicate a component and then delete it, the draftComponentId doesn't change,
 * that is when this selector is useful, because we know if the component was removed or not.
 */
export const selectDraftComponentIdFromDraftComponent = createSelector(
  selectDraftComponent,
  (draftComponent) => draftComponent?.id,
);

export const selectHasDraftComponentFromDraftElement = createSelector(
  selectDraftComponentIdFromDraftComponent,
  Boolean,
);

export const selectDraftComponentOrDescendantMeetsCondition = (
  state: EditorRootState,
  test: (component: Component) => boolean,
) => {
  const draftComponent = selectDraftComponent(state);
  if (!draftComponent) {
    return false;
  }
  let result = false;
  forEachComponentAndDescendants(draftComponent, (component) => {
    if (test(component)) {
      result = true;
      return "stop";
    }
  });
  return result;
};

export const selectDraftComponentOrDescendantIsOneOfTypes = (
  state: EditorRootState,
  types: ReploComponentType[],
) => {
  const draftComponent = selectDraftComponent(state);
  if (!draftComponent) {
    return false;
  }
  const componentDataMapping = selectComponentDataMapping(state);
  const componentData = componentDataMapping[draftComponent.id];
  if (!componentData) {
    return false;
  }
  if (types.includes(componentData?.type)) {
    return true;
  }
  return types.some((type) =>
    componentData.containedComponentData.some(
      ([_, componentType]) => componentType === type,
    ),
  );
};

export const selectDraftElementVersion = createSelector(
  selectDraftElementId,
  selectVersionMapping,
  (id, versionMapping) => {
    if (!id) {
      return null;
    }
    return versionMapping[id];
  },
);

export const selectDraftElementTemplateProducts = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => {
    return draftElement?.templateShopifyProductIds
      ? draftElement.templateShopifyProductIds.map((id) => Number(id))
      : undefined;
  },
  {
    // Note (Evan, 2023-11-17): Since the result of this is an array, we pass this equality function
    // so that the return array only changes when its contents change.
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);

export const selectAllComponentIdsWithTemplateProductEqualsStates =
  createSelector(
    selectRootComponent,
    (rootComponent) => {
      const results: string[] = [];
      if (rootComponent) {
        forEachComponentAndDescendants(rootComponent, (component) => {
          const productEqualsState = component.variants?.find(
            (variant) =>
              variant.query?.statements[0]?.field ===
              "state.product.templateProductEquals",
          );
          if (productEqualsState) {
            results.push(component.id);
          }
        });
      }
      return results;
    },
    {
      // Note (Evan, 2023-11-17): Since the result of this is an array, we pass this equality function
      // so that the return array only changes when its contents change.
      memoizeOptions: {
        resultEqualityCheck: isEqual,
      },
    },
  );

export const selectDraftComponentComputedStyleValue = createSelector(
  (
    state: EditorRootState,
    attribute: keyof SupportedCssProperties,
    canvas: EditorCanvas,
  ) => [state, attribute, canvas] as const,
  ([state, attribute, canvas]) => {
    const draftComponentNode = selectDraftComponentNode(state, canvas);
    if (!draftComponentNode) {
      return null;
    }
    return getComputedStyle(draftComponentNode)[attribute];
  },
);

// Note (Evan, 2024-03-18): Selects history operations if they reference the same
// draft element, otherwise null -- this prevents edits in the background.
export const selectValidPreviousOperation = createSelector(
  selectHistoryState,
  selectDraftElementId,
  (historyState, draftElementId) => {
    const previousOperation = historyState.operations[historyState.index];
    return previousOperation?.elementId === draftElementId
      ? previousOperation
      : null;
  },
);

export const selectValidNextOperation = createSelector(
  selectHistoryState,
  selectDraftElementId,
  (historyState, draftElementId) => {
    const nextOperation = historyState.operations[historyState.index + 1];
    return nextOperation?.elementId === draftElementId ? nextOperation : null;
  },
);

export const selectValidCurrentOperation = createSelector(
  selectHistoryState,
  selectDraftElementId,
  (historyState, draftElementId) => {
    const currentOperation = historyState.operations[historyState.index];
    return currentOperation?.elementId === draftElementId
      ? currentOperation
      : null;
  },
);

export const selectComponentIdsToStyle = createSelector(
  selectHistoryState,
  selectDraftElementId,
  selectStreamingUpdateActions,
  (historyState, draftElementId, streamingActions) => {
    if (streamingActions) {
      const streamingActionComponentIds = streamingActions.map(
        ({ componentId }) => componentId,
      );
      return filterNulls(streamingActionComponentIds);
    }

    const operationToStyle =
      historyState.operations[
        historyState.lastOperation === "undo"
          ? historyState.index + 1
          : historyState.index
      ];

    if (!operationToStyle) {
      return [];
    }

    return operationToStyle.elementId === draftElementId
      ? operationToStyle.componentIds
      : [];
  },
);

// Style attribute related selectors
function createDraftComponentStyleSelector<
  T extends keyof RuntimeStyleProperties,
>(attribute: T): Selector<EditorRootState, RuntimeStyleProperties[T]> {
  return createSelector(
    selectDraftComponent,
    selectGetAttribute,
    (draftComponent, getAttribute) => {
      return getEdgeAttribute(draftComponent, attribute, getAttribute);
    },
  );
}

export const selectFontFamily = createDraftComponentStyleSelector("fontFamily");
export const selectFontWeight = createDraftComponentStyleSelector("fontWeight");
export const selectFontSize = createDraftComponentStyleSelector("fontSize");
export const selectLetterSpacing =
  createDraftComponentStyleSelector("letterSpacing");
export const selectFontStyle = createDraftComponentStyleSelector("fontStyle");
export const selectLineHeight = createDraftComponentStyleSelector("lineHeight");
export const selectTextAlign = createDraftComponentStyleSelector("textAlign");
export const selectTextDecoration =
  createDraftComponentStyleSelector("textDecoration");
export const selectTextTransform =
  createDraftComponentStyleSelector("textTransform");
export const selectAlignSelf = createDraftComponentStyleSelector("alignSelf");
export const selectFlexGap = createDraftComponentStyleSelector("__flexGap");
export const selectColumnGap = createDraftComponentStyleSelector("columnGap");
export const selectRowGap = createDraftComponentStyleSelector("rowGap");
export const selectGridColumnEnd =
  createDraftComponentStyleSelector("gridColumnEnd");
export const selectGridRowEnd = createDraftComponentStyleSelector("gridRowEnd");
export const selectNumberOfColumns =
  createDraftComponentStyleSelector("__numberOfColumns");
export const selectDisplay = createDraftComponentStyleSelector("display");
export const selectGridColumnEndTemplate = createDraftComponentStyleSelector(
  "gridTemplateColumns",
);
export const selectFlexDirection =
  createDraftComponentStyleSelector("flexDirection");
export const selectFlexGrow = createDraftComponentStyleSelector("flexGrow");
export const selectAliasedFlexShrink =
  createDraftComponentStyleSelector("__flexShrink");
export const selectFlexWrap = createDraftComponentStyleSelector("flexWrap");
export const selectJustifyContent =
  createDraftComponentStyleSelector("justifyContent");
export const selectAlignItems = createDraftComponentStyleSelector("alignItems");
export const selectColor = createDraftComponentStyleSelector("color");
export const selectBackgroundColor =
  createDraftComponentStyleSelector("backgroundColor");
export const selectBackgroundImage =
  createDraftComponentStyleSelector("backgroundImage");
export const selectBackgroundSize =
  createDraftComponentStyleSelector("backgroundSize");
export const selectBackgroundRepeat =
  createDraftComponentStyleSelector("backgroundRepeat");
export const selectBackgroundPositionX = createDraftComponentStyleSelector(
  "backgroundPositionX",
);
export const selectBackgroundPositionY = createDraftComponentStyleSelector(
  "backgroundPositionY",
);
export const selectTop = createDraftComponentStyleSelector("top");
export const selectBottom = createDraftComponentStyleSelector("bottom");
export const selectOpacity = createDraftComponentStyleSelector("opacity");
export const selectZoom = createDraftComponentStyleSelector("__zoom");
export const selectCursor = createDraftComponentStyleSelector("cursor");
export const selectObjectFit = createDraftComponentStyleSelector("objectFit");
export const selectObjectPosition =
  createDraftComponentStyleSelector("objectPosition");
export const selectPosition = createDraftComponentStyleSelector("position");
export const selectWidth = createDraftComponentStyleSelector("width");
export const selectPrivateWidth = createDraftComponentStyleSelector("__width");
export const selectHeight = createDraftComponentStyleSelector("height");
export const selectMaxHeight = createDraftComponentStyleSelector("maxHeight");
export const selectMinHeight = createDraftComponentStyleSelector("minHeight");
export const selectMinWidth = createDraftComponentStyleSelector("minWidth");
export const selectMaxWidth = createDraftComponentStyleSelector("maxWidth");
export const selectZIndex = createDraftComponentStyleSelector("zIndex");
export const selectPrivateHeight =
  createDraftComponentStyleSelector("__height");
export const selectOverflow = createDraftComponentStyleSelector("overflow");
export const selectPrivateOverflow =
  createDraftComponentStyleSelector("__overflow");
export const selectImageSource =
  createDraftComponentStyleSelector("__imageSource");
export const selectAnimateVariantTransitions =
  createDraftComponentStyleSelector("__animateVariantTransitions");
export const selectBackgroundGradientTilt = createDraftComponentStyleSelector(
  "__alchemyGradient__backgroundColor__tilt",
);
export const selectBackgroundGradientStops = createDraftComponentStyleSelector(
  "__alchemyGradient__backgroundColor__stops",
);
export const selectColorGradientTilt = createDraftComponentStyleSelector(
  "__alchemyGradient__color__tilt",
);
export const selectColorGradientStops = createDraftComponentStyleSelector(
  "__alchemyGradient__color__stops",
);
export const selectRotation =
  createDraftComponentStyleSelector("__alchemyRotation");
export const selectIconAltText =
  createDraftComponentStyleSelector("__iconAltText");
export const selectImageAltText =
  createDraftComponentStyleSelector("__imageAltText");
export const selectBorderTopLeftRadius = createDraftComponentStyleSelector(
  "borderTopLeftRadius",
);
export const selectBorderTopRightRadius = createDraftComponentStyleSelector(
  "borderTopRightRadius",
);
export const selectBorderBottomLeftRadius = createDraftComponentStyleSelector(
  "borderBottomLeftRadius",
);
export const selectBorderBottomRightRadius = createDraftComponentStyleSelector(
  "borderBottomRightRadius",
);
export const selectBorderTopWidth =
  createDraftComponentStyleSelector("borderTopWidth");
export const selectBorderRightWidth =
  createDraftComponentStyleSelector("borderRightWidth");
export const selectBorderBottomWidth =
  createDraftComponentStyleSelector("borderBottomWidth");
export const selectBorderLeftWidth =
  createDraftComponentStyleSelector("borderLeftWidth");
export const selectBorderTopColor =
  createDraftComponentStyleSelector("borderTopColor");
export const selectBorderRightColor =
  createDraftComponentStyleSelector("borderRightColor");
export const selectBorderBottomColor =
  createDraftComponentStyleSelector("borderBottomColor");
export const selectBorderLeftColor =
  createDraftComponentStyleSelector("borderLeftColor");
export const selectBorderTopStyle =
  createDraftComponentStyleSelector("borderTopStyle");
export const selectBorderRightStyle =
  createDraftComponentStyleSelector("borderRightStyle");
export const selectBorderBottomStyle =
  createDraftComponentStyleSelector("borderBottomStyle");
export const selectBorderLeftStyle =
  createDraftComponentStyleSelector("borderLeftStyle");
export const selectTransform = createDraftComponentStyleSelector("__transform");
export const selectTransformOrigin =
  createDraftComponentStyleSelector("transformOrigin");
export const selectBoxShadow = createDraftComponentStyleSelector("boxShadow");
export const selectTextShadow = createDraftComponentStyleSelector("textShadow");
export const selectParsedTextShadows = createSelector(
  selectTextShadow,
  (textShadow) => {
    if (!textShadow) {
      return null;
    }

    const draftComponentTextShadows = textShadow.split(",") ?? [];
    const textShadows =
      draftComponentTextShadows.length > 0
        ? parseTextShadows(draftComponentTextShadows)
        : [];

    return textShadows;
  },
);
export const selectTextOutline =
  createDraftComponentStyleSelector("__textStroke");

export const selectAncestorTextColor = createSelector(
  selectDraftComponentId,
  selectComponentDataMapping,
  selectComponentMapping,
  selectGetAttribute,
  (draftComponentId, componentDataMapping, componentMapping, getAttribute) => {
    if (!draftComponentId) {
      return null;
    }

    const ancestorWithTextColorId = findAncestorComponentData(
      draftComponentId,
      (componentData) => {
        const component = componentMapping[componentData.id]?.component;
        return getEdgeAttribute(component ?? null, "color", getAttribute);
      },
      componentDataMapping,
    )?.id;

    return getEdgeAttribute(
      getFromRecordOrNull(componentMapping, ancestorWithTextColorId)
        ?.component ?? null,
      "color",
      getAttribute,
    );
  },
);

export const selectBorderRadius = createSelector(
  selectBorderTopLeftRadius,
  selectBorderTopRightRadius,
  selectBorderBottomLeftRadius,
  selectBorderBottomRightRadius,
  (
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  ) => {
    return [
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomLeftRadius,
      borderBottomRightRadius,
    ];
  },
);

export const selectHasBorderStyles = createSelector(
  selectBorderLeftStyle,
  selectBorderRightStyle,
  selectBorderTopStyle,
  selectBorderBottomStyle,
  (borderLeftStyle, borderRightStyle, borderTopStyle, borderBottomStyle) => {
    return [
      borderLeftStyle,
      borderRightStyle,
      borderTopStyle,
      borderBottomStyle,
    ].some((elem) => elem !== null);
  },
);

export const selectParentFlexDirection = createSelector(
  selectDraftParentComponent,
  selectGetAttribute,
  (parentComponent, getAttribute) => {
    const parentFlexDirection = getEdgeAttribute(
      parentComponent,
      "flexDirection",
      getAttribute,
    );
    return getNormalizedFlexDirection(
      parentFlexDirection ||
        styleAttributeToEditorData.flexDirection.defaultValue,
    );
  },
);

export const selectComponentChildrenPrivateDimensions = createSelector(
  selectDraftComponentChildren,
  selectGetAttribute,
  (draftComponentChildren, getAttribute) => {
    if (!draftComponentChildren) {
      return null;
    }
    return draftComponentChildren.map((component) => {
      const width = getAttribute(component, "style.__width");
      const height = getAttribute(component, "style.__height");
      return {
        width,
        height,
      };
    });
  },
);

export const selectIsParentGrid = createSelector(
  selectDraftParentComponent,
  selectGetAttribute,
  (parentComponent, getAttribute) => {
    return (
      getEdgeAttribute(parentComponent, "display", getAttribute) === "grid"
    );
  },
);

export const selectBorderWidth = createSelector(
  selectBorderTopWidth,
  selectBorderRightWidth,
  selectBorderBottomWidth,
  selectBorderLeftWidth,
  (borderTopWidth, borderRightWidth, borderBottomWidth, borderLeftWidth) => {
    return {
      Left: coerceNumberToString(borderLeftWidth),
      Top: coerceNumberToString(borderTopWidth),
      Right: coerceNumberToString(borderRightWidth),
      Bottom: coerceNumberToString(borderBottomWidth),
    };
  },
);

export const selectBorderColor = createSelector(
  selectBorderTopColor,
  selectBorderRightColor,
  selectBorderBottomColor,
  selectBorderLeftColor,
  (borderTopColor, borderRightColor, borderBottomColor, borderLeftColor) => {
    return {
      Left: borderLeftColor,
      Top: borderTopColor,
      Right: borderRightColor,
      Bottom: borderBottomColor,
    };
  },
);

export const selectBorderStyle = createSelector(
  selectBorderTopStyle,
  selectBorderRightStyle,
  selectBorderBottomStyle,
  selectBorderLeftStyle,
  (borderTopStyle, borderRightStyle, borderBottomStyle, borderLeftStyle) => {
    return {
      Left: borderLeftStyle,
      Top: borderTopStyle,
      Right: borderRightStyle,
      Bottom: borderBottomStyle,
    };
  },
);

export const selectInitialBorderSide = createSelector(
  selectBorderWidth,
  (borderWidth): BorderSuffix => {
    const entries = Object.entries(borderWidth).filter(([, value]) => value);
    if (entries.length === Object.values(borderWidth).length) {
      return "";
    }

    if (entries.length > 0) {
      return entries[0]![0]! as BorderSuffix;
    }

    return "";
  },
);

export const selectActiveBorderSideWidth = createSelector(
  selectBorderWidth,
  (_: EditorRootState, activeBorderSide: BorderSuffix | null) =>
    activeBorderSide,
  (
    borderWidth: Record<BorderSide, string | null | undefined>,
    activeBorderSide: BorderSuffix | null,
  ) => {
    if (activeBorderSide === null) {
      return null;
    }

    if (activeBorderSide == "") {
      return borderWidth[Object.keys(borderWidth)[0] as BorderSide];
    }

    return borderWidth[activeBorderSide as BorderSide];
  },
);

export const selectActiveBorderSideColor = createSelector(
  selectBorderColor,
  (_: EditorRootState, activeBorderSide: BorderSuffix | null) =>
    activeBorderSide,
  (
    borderColor: Record<BorderSide, string | null | undefined>,
    activeBorderSide: BorderSuffix | null,
  ) => {
    if (activeBorderSide === null) {
      return null;
    }

    if (activeBorderSide == "") {
      return borderColor[Object.keys(borderColor)[0] as BorderSide];
    }

    return borderColor[activeBorderSide as BorderSide];
  },
);

export const selectActiveBorderSideStyle = createSelector(
  selectBorderStyle,
  (_: EditorRootState, activeBorderSide: BorderSuffix | null) =>
    activeBorderSide,
  (
    borderStyle: Record<BorderSide, string | null | undefined>,
    activeBorderSide: BorderSuffix | null,
  ) => {
    if (activeBorderSide === null) {
      return null;
    }

    if (activeBorderSide === "") {
      return borderStyle[Object.keys(borderStyle)[0] as BorderSide];
    }

    return borderStyle[activeBorderSide as BorderSide];
  },
);

export const selectNumberOfMinAndMaxDimensionsSet = createSelector(
  selectMaxHeight,
  selectMinHeight,
  selectMaxWidth,
  selectMinWidth,
  (maxHeight, minHeight, maxWidth, minWidth) => {
    const minAndMaxDimensions = {
      minWidth:
        minWidth &&
        minWidth !== String(styleAttributeToEditorData.minWidth.defaultValue),
      maxWidth:
        maxWidth &&
        maxWidth !== String(styleAttributeToEditorData.maxWidth.defaultValue),
      minHeight:
        minHeight &&
        minHeight !== String(styleAttributeToEditorData.minHeight.defaultValue),
      maxHeight:
        maxHeight &&
        maxHeight !== String(styleAttributeToEditorData.maxHeight.defaultValue),
    };

    return filter(minAndMaxDimensions, (value) => Boolean(value))?.length;
  },
);

export const selectDraftComponentActionIssues = createSelector(
  selectDraftComponent,
  selectDraftComponentOrAncestorVariant,
  selectComponentDataMapping,
  (component, activeVariant, componentDataMapping) => {
    return getIssuesForComponent({
      type: "actions",
      component,
      activeVariant,
      componentDataMapping,
    });
  },
);

export const selectDraftComponentAnimationIssues = createSelector(
  selectDraftComponentAnimations,
  (animations) => {
    return getIssuesForComponent({
      type: "animations",
      animations: animations ?? null,
    });
  },
);

// Prop attribute related selectors
function createDraftComponentPropSelector<T = any>(attribute: string) {
  return createSelector(
    selectDraftComponent,
    selectGetAttribute,
    (draftComponent, getAttribute) => {
      if (!draftComponent) {
        return null;
      }

      return getAttribute(draftComponent, `props.${attribute}`).value as T;
    },
  );
}

export const selectPropSrc = createDraftComponentPropSelector("src");
export const selectPropLoading = createDraftComponentPropSelector<
  AssetLoadingType | undefined
>("loading");
export const selectPropImageSource = createDraftComponentPropSelector(
  "style.__imageSource",
);
export const selectPropIconName = createDraftComponentPropSelector("iconName");
export const selectPropUrl = createDraftComponentPropSelector("url");
export const selectPropPoster = createDraftComponentPropSelector<
  string | undefined
>("poster");
export const selectPropOnClick = createDraftComponentPropSelector("onClick");
export const selectPropOnHover = createDraftComponentPropSelector("onHover");
export const selectPropProductRef =
  createDraftComponentPropSelector("_product");
export const selectPropProductRefOrFallbackToContext = createSelector(
  selectDraftComponentContext,
  selectPropProductRef,
  (context, productRef: ProductRef | null) => {
    if (!productRef && context?.attributes?._product) {
      const product = context!.attributes!._product;
      return {
        productId: product.id,
        variantId: product.variants?.[0]?.id,
        status: product.status,
      };
    }
    return productRef;
  },
);
export const selectPropHTMLContent =
  createDraftComponentPropSelector("_htmlContent");
export const selectPropCSSContent = createDraftComponentPropSelector("_css");
export const selectPropLiquidContent =
  createDraftComponentPropSelector("_liquidContent");

/**
 * Select either the current options of the _product prop of the draft component, or
 * if no _product prop is specified, the options from the context of the draft component
 */
export const selectProductOptionValues = createSelector(
  selectPropProductRefOrFallbackToContext,
  selectDraftComponentId,
  selectTemplateEditorStoreProduct,
  (_state: EditorRootState, products: StoreProduct[]) => products,
  (
    _state: EditorRootState,
    _products: StoreProduct[],
    context: Context | undefined,
  ) => context,
  selectLocaleData,
  (
    productRef,
    draftComponentId,
    templateProduct,
    products,
    context,
    locale,
  ): ReploShopifyOption[] => {
    // Note (Noah, 2023-01-02, REPL-5766): The options to pick from should be the options
    // EITHER of the product specified by the draft component (we assume the prop is called _product
    // for now) or the options from the current context (current product component), which are
    // available in `attributes`
    return (
      mapNull(draftComponentId, () => {
        // On first render of the OptionsCustomPropModifier optionsFromContext
        // is null. It seems like the context is being maintained outside of
        // redux and therefore when context updates after this is run this
        // selector does not rerun.
        const optionsFromContext = context?.attributes?._options;
        const resolvedProduct = getProduct(
          productRef ?? null,
          context ?? null,
          {
            productMetafieldValues: {},
            variantMetafieldValues: {},
            products,
            currencyCode: locale.activeCurrency,
            moneyFormat: locale.moneyFormat,
            language: locale.activeLanguage,
            templateProduct: templateProduct ?? null,
          },
        );
        if (!resolvedProduct) {
          return optionsFromContext;
        }
        return enhanceVariantsAndOptions({
          product: resolvedProduct,
          variantMetafieldValues: {},
          swatches: [],
          selectedOptionValues: null,
          showOptionsNotSoldTogether: false,
        }).options;
      }) ?? []
    );
  },
);

export const selectImageSourceComponentProps = createSelector(
  selectPropImageSource,
  (_state: EditorRootState, url: string) => url,
  (_state: EditorRootState, _url: string, imageComponentId?: string) =>
    imageComponentId,
  (imageSourceProp, imageUrl, imageComponentId) => {
    return {
      imageUrl,
      imageSourceProp,
      imageComponentId,
    };
  },
);

export const selectHasDraftComponentAncestorOrSelfWithSpecificType =
  createSelector(
    selectComponentDataMapping,
    selectDraftComponentId,
    (_: EditorRootState, componentType: ReploComponentType) => componentType,
    (componentDataMapping, draftComponentId, componentType) => {
      if (!draftComponentId) {
        return false;
      }
      const componentData = componentDataMapping[draftComponentId];
      if (!componentData) {
        return false;
      }
      if (componentData.type === componentType) {
        return true;
      }

      if (
        componentData?.ancestorComponentData.some(
          ([, ancestorType]) => ancestorType === componentType,
        )
      ) {
        return true;
      }

      return false;
    },
  );

const selectSupportedComponentDynamicDataIssues = createSelector(
  selectComponentMapping,
  selectActiveCanvas,
  (_state: EditorRootState, nodeId: string) => nodeId,
  (componentMapping, activeCanvas, nodeId) => {
    const componentData = componentMapping[nodeId]?.component;
    const componentType = componentData?.type;
    // NOTE (Sebas, 2024-05-16): Regex to validate that it has a valid
    // dynamic data format.
    const regex = /^{{.*}}$/;
    if (componentType === "text") {
      const rawData = componentData?.props.text;
      if (rawData) {
        const withoutPTags = rawData.replace(/<\/?p>/g, "");
        const isValid = regex.test(withoutPTags);

        if (isValid) {
          return withoutPTags;
        }
      }
    } else if (componentType === "image") {
      const style = canvasToStyleMap[activeCanvas];
      const activeCanvasStyles = componentData?.props[style];
      const imageSrc = (activeCanvasStyles?.__imageSource ??
        componentData?.props.src) as string;
      if (imageSrc) {
        const isValid = regex.test(imageSrc);

        if (isValid) {
          return imageSrc;
        }
      }
    }
    return null;
  },
);

const selectDynamicDataIssue = createSelector(
  (_state: EditorRootState, componentId: string) => componentId,
  (state: EditorRootState, componentId: string) =>
    selectSupportedComponentDynamicDataIssues(state, componentId),
  (componentId, dynamicDataString) => {
    const componentContext = getCurrentComponentContext(componentId, 0);
    if (!dynamicDataString || !componentContext) {
      return null;
    }

    const dynamicDataValue = evaluateVariableAsString(
      dynamicDataString,
      componentContext,
    );

    const componentIssues = getIssuesForComponent({
      type: "dynamicData",
      dynamicDataValue,
    });

    if (componentIssues.length > 0) {
      return componentIssues[0];
    }

    return null;
  },
);

const selectComponentMismatchIssue = createSelector(
  selectComponentDataMapping,
  (_state: EditorRootState, componentId: string) => componentId,
  (componentDataMapping, componentId) => {
    const componentType = componentDataMapping[componentId]?.type;

    if (componentType) {
      const mismatchIssue = getIssuesForComponent({
        type: "componentMismatch",
        componentDataMapping,
        componentId,
      });

      if (mismatchIssue.length > 0) {
        return mismatchIssue[0];
      }
    }

    return null;
  },
);

export const selectComponentActionIssues = createSelector(
  selectComponentMapping,
  selectDraftComponentOrAncestorVariant,
  selectComponentDataMapping,
  (_state: EditorRootState, componentId: string) => componentId,
  (componentMapping, activeVariant, componentDataMapping, componentId) => {
    const component = getFromRecordOrNull(
      componentMapping,
      componentId,
    )?.component;
    if (!component) {
      return null;
    }

    return getIssuesForComponent({
      type: "actions",
      component,
      activeVariant,
      componentDataMapping,
    });
  },
);

const selectOptionVariantListIssue = createSelector(
  selectComponentDataMapping,
  (_state: EditorRootState, componentId: string) => componentId,
  (componentDataMapping, componentId) => {
    const componentType = componentDataMapping[componentId]?.type;
    const componentContext = getCurrentComponentContext(componentId, 0);

    const typesToCheck: Array<ReploComponentType> = [
      "optionSelect",
      "optionSelectDropdown",
      "variantSelect",
      "variantSelectDropdown",
      "sellingPlanSelect",
      "sellingPlanSelectDropdown",
    ];

    if (
      componentType &&
      componentContext &&
      typesToCheck.includes(componentType)
    ) {
      const componentIssues = getIssuesForComponent({
        type: "product",
        componentType,
        context: componentContext,
      });

      if (componentIssues.length > 0) {
        return componentIssues[0];
      }
    }

    return null;
  },
);

const selectAllowedChildrenIssue = createSelector(
  selectComponentDataMapping,
  (_state: EditorRootState, componentId: string) => componentId,
  (componentDataMapping, componentId) => {
    const componentType = componentDataMapping[componentId]?.type;

    if (componentType) {
      const componentIssues = getIssuesForComponent({
        type: "childrenType",
        componentId,
        componentDataMapping,
        componentType,
      });

      if (componentIssues.length > 0) {
        return componentIssues[0];
      }
    }

    return null;
  },
);

export const selectTreeComponentIssue = createSelector(
  selectComponentMapping,
  (_state: EditorRootState, componentId: string) => componentId,
  (state: EditorRootState, componentId: string) =>
    selectComponentMismatchIssue(state, componentId),
  (state: EditorRootState, componentId: string) =>
    selectDynamicDataIssue(state, componentId),
  (state: EditorRootState, componentId: string) =>
    selectAllowedChildrenIssue(state, componentId),
  (state: EditorRootState, componentId: string) =>
    selectOptionVariantListIssue(state, componentId),
  (state: EditorRootState, componentId: string) =>
    selectComponentActionIssues(state, componentId),
  (
    _componentMapping,
    _componentId,
    mismatchIssue,
    dynamicDataIssue,
    getAllowedChildrenIssue,
    optionVariantListIssue,
    actionIssues,
  ) => {
    if (mismatchIssue) {
      return mismatchIssue;
    }

    if (dynamicDataIssue) {
      return dynamicDataIssue;
    }

    if (getAllowedChildrenIssue) {
      return getAllowedChildrenIssue;
    }

    if (optionVariantListIssue) {
      return optionVariantListIssue;
    }

    if (actionIssues && actionIssues.length > 0) {
      return actionIssues[0];
    }

    return null;
  },
);

/**
 * Note (Evan, 2024-07-18): You may notice that these parameters are all derivable
 * from the elements state. The reason we parameterize it this way (instead of
 * passing the entire elements state) is to avoid recomputing this selector when
 * a streaming text action is applied -- in that scenario, streamingUpdate.actions
 * and streamingUpdate.textMap are updated, but not anything actually used in this calculation.
 * By only using those parts of the elements state that are necessary, then, we avoid a
 * full repaint.
 */
export const selectElementWithRevisionState = createSelector(
  [
    selectDraftElementId,
    (state: EditorRootState) => state.core.elements.elementRevisions,
    selectSelectedRevisionId,
    selectVersionMapping,
    selectElementsMapping,
    (state: EditorRootState) =>
      state.core.elements.streamingUpdate?.draftElementComponent,
  ],
  (
    draftElementId,
    elementRevisions,
    selectedRevisionId,
    elementVersionMapping,
    elementMapping,
    streamingDraftElementComponent,
  ) => {
    if (!selectedRevisionId && !streamingDraftElementComponent) {
      return { elementMapping, elementVersionMapping, elementRevisions };
    }
    const updatedMapping = updateElementMappingWithRevision({
      draftElementId,
      elementRevisions,
      selectedRevisionId,
      elementMapping,
      streamingDraftElementComponent,
    });
    return {
      elementMapping: updatedMapping,
      elementVersionMapping,
      elementRevisions,
    };
  },
);

export const selectIsLabelledByOtherComponent = createSelector(
  selectComponentDataMapping,
  (_state: EditorRootState, componentId: string) => componentId,
  (componentDataMapping, componentId) => {
    return (
      componentDataMapping[componentId]?.isLabelledByOtherComponent ?? false
    );
  },
);

export const selectIsTextDynamicData = createSelector(
  selectDraftComponentText,
  (text) => {
    if (isDynamicDataValue(text)) {
      return true;
    }
  },
);

export const selectModalComponentIdFromDraftComponent = createSelector(
  selectDraftComponentId,
  selectComponentDataMapping,
  (draftComponentId, componentDataMapping) => {
    if (!draftComponentId) {
      return null;
    }

    const draftComponentData = componentDataMapping[draftComponentId];
    if (!draftComponentData) {
      return null;
    }

    if (draftComponentData.type === "modal") {
      return draftComponentId;
    }

    const ancestorModalFromDraftComponent =
      draftComponentData.ancestorComponentData.find(
        ([_, ancestorComponentType]) => ancestorComponentType.includes("modal"),
      );

    if (ancestorModalFromDraftComponent) {
      return ancestorModalFromDraftComponent[0];
    }

    return null;
  },
);
// #endregion
