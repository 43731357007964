import type { ButtonSharedProps } from "@common/designSystem/Button";

import * as React from "react";

import { ButtonLink } from "@common/designSystem/Button";
import { SimpleSkeletonLoader } from "@editor/components/common/designSystem/SkeletonLoader";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { trpc } from "@editor/utils/trpc";

import PagesOverview from "@/features/analytics/PagesOverview";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import { BsGraphUp } from "react-icons/bs";

export const SetupPage = () => {
  const { oauthLink, isLoading } = useAnalyticsOnboardingOAuthLink();
  const analytics = useLogAnalytics();
  const commonProps: ButtonSharedProps = {
    size: "base",
    type: "primary",
    textClassNames: "font-normal",
    className: "bg-[#2563EB] px-14",
  };
  return (
    <div className="max-h-[80vh]">
      <div className="absolute inset-0 z-10 w-full bg-white bg-opacity-[0.90]">
        <div className="flex h-[70%] w-full justify-center items-center">
          <div className="flex flex-col space-y-6 items-center">
            <BsGraphUp className="h-10 w-10 text-black" />
            <div className="text-base font-semibold max-w-[400px] text-center">
              Setup Analytics to get insights on your top converting pages.{" "}
              <span className="text-blue-600">Learn more</span>
            </div>
            <ButtonLink
              {...commonProps}
              to={oauthLink ?? ""}
              isLoading={isLoading || !oauthLink}
              onClick={() => {
                analytics("analytics.connect", {
                  tab: "analytics_tab",
                });
              }}
            >
              Set up Analytics
            </ButtonLink>
          </div>
        </div>
      </div>
      <div className="relative max-w-xl xl:max-w-7xl pr-5">
        <div className="flex w-full flex-col">
          <div className="flex flex-row space-x-4">
            <SimpleSkeletonLoader height="14" width="160" />
            <SimpleSkeletonLoader height="14" width="160" />
            <SimpleSkeletonLoader height="14" width="160" />
          </div>
          <div className="text-2xl font-semibold my-8">
            <SimpleSkeletonLoader height="14" width="141" />
          </div>
          <div className="flex gap-5 flex-col min-[950px]:flex-row">
            {/* <ReploLineChart
              title={<SimpleSkeletonLoader width="141" height="10" />}
            /> */}
            {/* <ReploPieChart
              skeleton={<SimpleSkeletonLoader width="141" height="10" />}
            /> */}
          </div>
          <PagesOverview skeleton={true} />
        </div>
      </div>
    </div>
  );
};

/**
 * NOTE Ben 2024-10-01: This is intended to be a stop-gap measure to allow self-onboarding from
 * the setup screen while we work on the rest of self-onboarding. It will be removed and replaced
 * in REPL-13622, REPL-13621, or REPL-13620.
 */
function useAnalyticsOnboardingOAuthLink() {
  const workspaceId = useCurrentWorkspaceId();
  const { data: integrationsData, isLoading: isLoadingIntegration } =
    trpc.integration.getWorkspaceShopifyIntegrationData.useQuery(
      workspaceId ? { workspaceId } : reactQuerySkipToken,
    );
  const integration = (integrationsData?.integrations ?? [])[0];
  const shopifyUrl = integration?.shopifyIntegrationConfig?.shopifyUrl;
  const { data: oauthLink, isLoading: isLoadingOauthLink } =
    trpc.analytics.generateOAuthLink.useQuery(
      workspaceId && shopifyUrl
        ? {
            workspaceId,
            shopifyUrl,
          }
        : reactQuerySkipToken,
    );
  const isLoading = isLoadingIntegration || isLoadingOauthLink;
  return {
    isLoading,
    oauthLink,
  };
}
