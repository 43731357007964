import type { Experiment } from "schemas/generated/experiment";
import type { ReploProject } from "schemas/generated/project";
import type { Variation } from "schemas/generated/variation";

import * as React from "react";

import Button from "@components/common/designSystem/Button";
import {
  positiveIntToCapitalLetter,
  useExperimentApi,
} from "@components/projectDashboard/experiments/common";
import InputComponent from "@editor/components/common/designSystem/Input";
import Popover from "@editor/components/common/designSystem/Popover";
import Textarea from "@editor/components/common/designSystem/Textarea";
import { successToast } from "@editor/components/common/designSystem/Toast";
import { Loader } from "@editor/components/common/Loader";
import { BetaTag } from "@editor/components/projectDashboard/common/BetaTag";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { selectPages } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { getStoreData } from "@editor/utils/project-utils";
import { routes } from "@editor/utils/router";
import { RegularList } from "@editorComponents/Lists";
import ReploLogoBadge from "@svg/logo-badge";

import classNames from "classnames";
import isEqual from "lodash-es/isEqual";
import { BsChevronRight, BsPlus, BsTrash } from "react-icons/bs";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import { isValidHttpUrl } from "replo-utils/lib/url";
import { BillingTiers } from "schemas/billing";
import { getExperimentStatus } from "schemas/experiment";
import { isPathSafeSlug } from "schemas/utils";
import { v4 as uuid } from "uuid";

export const ExperimentEditTab = () => {
  const { experimentId } = useParams();
  const { isLoading: isLoadingProject, project } = useCurrentProjectContext();
  const workspaceId = project?.ownerWorkspaceId;
  const {
    list: { data, isFetching: isFetchingExperiments },
  } = useExperimentApi({ workspaceId });
  const experiment = data?.experiments.find((exp) => exp.id === experimentId);
  if (
    isLoadingProject ||
    !project ||
    !experiment ||
    !data ||
    isFetchingExperiments
  ) {
    return <Loader />;
  }
  return (
    <ExperimentEditComponent
      project={project}
      experiments={data.experiments}
      experiment={experiment}
    />
  );
};

export type ExperimentValidation = {
  isSlugValid: boolean;
  isSlugUnique: boolean;
  variations: {
    areSlugsValid: boolean;
    areSlugsUnique: boolean;
    areTargetsValid: boolean;
    arePercentagesValid: boolean;
  };
};

export type ExperimentState = {
  data: Experiment;
  validation: ExperimentValidation;
};

/**
 * An experiment is considered valid IFF:
 *
 * - Slug is valid.
 * - Slug is unique among other experiments.
 * - Variations must have unique slugs.
 * - Variations must have valid target URLs.
 * - Variation percentages must total 100%.
 */
export function validateExperiment(
  experiments: Array<Experiment>,
  experiment: Experiment,
): ExperimentValidation {
  const variations = experiment.variations;
  return {
    isSlugValid: isPathSafeSlug(experiment.slug),
    isSlugUnique: !Boolean(
      experiments.find(
        (e) => e.slug === experiment.slug && e.id !== experiment.id,
      ),
    ),
    variations: {
      areSlugsValid:
        variations
          .map((variation) => isPathSafeSlug(variation.slug))
          .filter((value) => value).length === variations.length,
      areSlugsUnique:
        new Set(variations.map((variation) => variation.slug)).size ===
        variations.length,
      areTargetsValid:
        variations
          .map(({ target }) => isValidHttpUrl(target))
          .filter((valid) => valid).length === variations.length,
      arePercentagesValid:
        variations.reduce((sum, v) => v.allocationPercent + sum, 0) === 100,
    },
  };
}

const ExperimentEditComponent = ({
  project,
  experiments,
  experiment,
}: {
  project: ReploProject;
  experiments: Array<Experiment>;
  experiment: Experiment;
}) => {
  const projectId = project.id;
  const workspaceId = project.ownerWorkspaceId;
  const allExperimentsPath = generatePath(routes.editor.experiments.list, {
    projectId,
  });
  const experimentsPath = generatePath(routes.editor.experiments.detail, {
    projectId,
    experimentId: experiment.id,
  });
  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;
  const logEvent = useLogAnalytics();
  const navigate = useNavigate();
  const {
    update: { mutateAsync: update, isPending: isUpdatingExperiment },
  } = useExperimentApi({ workspaceId });
  const experimentBaseUrl = `https://${
    project.customDomain ?? `reploedge.com/${project.slug}`
  }/`;
  const placeholderUrl = `https://${getStoreData(project)?.url}/pages/example`;
  const [state, setState] = React.useState<ExperimentState>({
    data: experiment,
    validation: validateExperiment(experiments, experiment),
  });
  const dataChanged = !isEqual(experiment, state.data);
  const experimentSlugInputRef = React.useRef<HTMLInputElement>(null);
  const status = getExperimentStatus(experiment);
  const isExperimentValid =
    state.validation.isSlugUnique &&
    state.validation.isSlugValid &&
    state.validation.variations.arePercentagesValid &&
    state.validation.variations.areSlugsUnique &&
    state.validation.variations.areSlugsValid &&
    state.validation.variations.areTargetsValid;
  const isUpdateAllowed = dataChanged && isExperimentValid;

  const onAddVariation = () => {
    let nextSlug = positiveIntToCapitalLetter(state.data.variations.length + 1);
    if (state.data.variations.find((v) => v.slug === nextSlug)) {
      nextSlug = uuid().slice(0, 8);
    }
    const data = {
      ...state.data,
      variations: [
        ...state.data.variations,
        {
          id: uuid(),
          target: placeholderUrl,
          slug: nextSlug,
          allocationPercent: 10,
        },
      ],
    };
    const validation = validateExperiment(experiments, data);
    setState({
      data,
      validation,
    });
  };

  const onVariationChange = (changed: Variation) => {
    const data = {
      ...state.data,
      variations: state.data.variations.map((v) =>
        v.id === changed.id ? { ...v, ...changed } : v,
      ),
    };
    const validation = validateExperiment(experiments, data);
    setState({
      data,
      validation,
    });
  };

  const onVariationRemoval = (id: string) => {
    const data = {
      ...state.data,
      variations: state.data.variations.filter(
        (variation) => variation.id !== id,
      ),
    };
    const validation = validateExperiment(experiments, data);
    setState({
      data,
      validation,
    });
  };

  const onPropertyChange = (changes: Partial<Experiment>) => {
    const data = {
      ...state.data,
      ...changes,
    };
    const validation = validateExperiment(experiments, data);
    setState({
      data,
      validation,
    });
  };

  const onSave = async () => {
    void update({
      ...state.data,
      status,
      description: "",
    });
    logEvent("experiment.updated", { billingPlanTier: subscriptionTier });
    successToast("Experiment Updated", "");
    navigate(
      generatePath(routes.editor.experiments.detail, {
        projectId,
        experimentId: state.data.id,
      }),
    );
  };

  const onExperimentSlugClick = () => experimentSlugInputRef.current?.focus();

  return (
    <div className="space-y-6 pb-32">
      <div className="flex items-center content-center justify-between gap-x-3 text-sm max-w-full">
        <div className="flex-shrink-0 flex-none flex justify-between items-center content-center gap-x-3">
          <Link className="font-semibold text-blue-600" to={allExperimentsPath}>
            Experiments
          </Link>
          <BetaTag />
          <span>
            <BsChevronRight size={18} />
          </span>
        </div>
        <div className="truncate flex-grow">{experiment.name}</div>
        <div className="flex-none flex items-center content-center justify-between gap-x-3">
          <Button
            type="tertiary"
            size="base"
            onClick={() => navigate(experimentsPath)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="base"
            onClick={() => void onSave()}
            isLoading={isUpdatingExperiment}
            isDisabled={!isUpdateAllowed}
          >
            Save changes
          </Button>
        </div>
      </div>
      <div className="space-y-6 text-sm">
        <div className="flex flex-col w-full max-w-3xl">
          <div className="font-semibold">Name</div>
          <InputComponent
            size="base"
            type="text"
            maxLength={256}
            onChange={(e) => onPropertyChange({ name: e.currentTarget.value })}
            value={state.data.name}
          />
        </div>
        <div className="flex flex-col w-full max-w-3xl">
          <div className="font-semibold">URL Path Name</div>
          <div className="text-slate-400">
            This name will be used to direct traffic, and must be unique for
            this project.
          </div>
          <div
            className={classNames(
              "flex flex-row items-center content-center justify-start rounded mt-2 bg-slate-100 text-sm",
              {
                "ring-1 ring-red-500": !(
                  state.validation.isSlugUnique && state.validation.isSlugValid
                ),
                "focus-within:ring-1 focus-within:ring-blue-500":
                  state.validation.isSlugUnique && state.validation.isSlugValid,
              },
            )}
            onClick={onExperimentSlugClick}
          >
            <div className="rounded-l pl-2 pr-1 py-2 bg-slate-200 text-slate-500 max-w-full shrink-0">
              {experimentBaseUrl}
            </div>
            <input
              ref={experimentSlugInputRef}
              className="rounded-r bg-transparent pl-1 pr-2 py-2 max-w-full focus:outline-none w-full flex-grow"
              type="text"
              maxLength={256}
              onChange={(e) =>
                onPropertyChange({ slug: e.currentTarget.value })
              }
              value={state.data.slug}
              placeholder="unique-experiment-name-here"
            />
          </div>
        </div>
        <div className="flex flex-col w-full max-w-3xl">
          <div className="font-semibold">Description</div>
          <Textarea
            className="grow"
            maxLength={256}
            placeholder="A short description on what this experiment is about so others will understand."
            onChange={(description) => onPropertyChange({ description })}
            value={state.data.description ?? ""}
          />
        </div>
        <div className="flex flex-col">
          <div className="font-semibold">Variations</div>
          <div className="text-slate-400">
            Unique variations name your experiment groups.
          </div>
          <div className="p-4 mt-2 rounded border border-slate-300">
            <div className="grid grid-cols-12 gap-4 h-8 items-center content-center font-semibold">
              <div className="col-span-3">Variation Name</div>
              <div className="col-span-7">Redirect To URL</div>
              <div className="col-span-1">Percent</div>
              <div className="col-span-1"></div>
            </div>
            <div className="space-y-1">
              {state.data.variations.map((variation) => (
                <VariationEntry
                  key={variation.id}
                  {...variation}
                  onChange={onVariationChange}
                  onRemove={onVariationRemoval}
                  isAllocationPercentValid={
                    state.validation.variations.arePercentagesValid
                  }
                  allowRemoval={state.data.variations.length > 1}
                  storeURL={getStoreData(project)?.url ?? ""}
                />
              ))}
            </div>
            <ExperimentValidityComponent {...state.validation} />
            <div className="mt-2">
              <Button type="primary" size="base" onClick={onAddVariation}>
                <div className="flex flex-row items-center content-center">
                  <BsPlus size={20} />
                  <span>Add Variation</span>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

const ExperimentValidityComponent = ({ variations }: ExperimentValidation) => {
  return (
    <div className="pt-2 grid grid-cols-12 gap-4 items-center content-center text-slate-400">
      <div
        className={classNames("col-span-3 opacity-0", {
          "opacity-100 text-red-500":
            !variations.areSlugsUnique || !variations.areSlugsValid,
        })}
      >
        Must be unique, between 1 and 256 characters, with only letters,
        numbers, underscores, and hyphens.
      </div>
      <div
        className={classNames("col-span-7 opacity-0", {
          "opacity-100 text-red-500": !variations.areTargetsValid,
        })}
      >
        Target URLs must be valid and start with{" "}
        <span className="font-mono">https://</span> or{" "}
        <span className="font-mono">http://</span>
      </div>
      <div
        className={classNames("col-span-2 opacity-0", {
          "opacity-100 text-red-500": !variations.arePercentagesValid,
        })}
      >
        Must total 100%
      </div>
    </div>
  );
};

const VariationEntry = ({
  onChange,
  onRemove,
  allowRemoval,
  storeURL,
  isAllocationPercentValid,
  ...variation
}: Variation & {
  onChange: (v: Variation) => void;
  onRemove: (id: string) => void;
  allowRemoval: boolean;
  isAllocationPercentValid: boolean;
  storeURL: string;
}) => {
  const pagePrefix = `https://${storeURL}/pages/`;
  return (
    <div className="grid grid-cols-12 gap-4 items-center content-center">
      <div className="col-span-3">
        <InputComponent
          size="base"
          value={variation.slug}
          type="text"
          unsafe_className={classNames({
            "text-red-600 ring-1 ring-red-600": !isPathSafeSlug(variation.slug),
          })}
          maxLength={256}
          onChange={(e) =>
            onChange({ ...variation, slug: e.currentTarget.value })
          }
        />
      </div>
      <div className="col-span-7">
        <InputComponent
          size="base"
          unsafe_className={classNames({
            "text-red-600 ring-1 ring-red-600": !isValidHttpUrl(
              variation.target,
            ),
          })}
          placeholder="https://example.com/pages/example-page"
          value={variation.target}
          type="url"
          onChange={(e) =>
            onChange({ ...variation, target: e.currentTarget.value })
          }
          endEnhancer={() => (
            <StoresPagesPopover
              currentValue={variation.target}
              pagePrefix={pagePrefix}
              onPageSelect={(value) => {
                onChange({
                  ...variation,
                  target: `${pagePrefix}${value}`,
                });
              }}
            />
          )}
        />
      </div>
      <div className="col-span-1">
        <InputComponent
          size="base"
          value={variation.allocationPercent.toString()}
          unsafe_className={classNames({
            "text-red-600 ring-1 ring-red-600": !isAllocationPercentValid,
          })}
          type="number"
          onChange={(e) => {
            const percent = Number.parseInt(e.currentTarget.value);
            const allocationPercent = Number.isNaN(percent)
              ? 0
              : Math.max(percent, 0);
            onChange({ ...variation, allocationPercent });
          }}
        />
      </div>
      <div className="col-span-1">
        <div className="mt-2">
          <Button
            type="tertiary"
            size="sm"
            className="h-7"
            isDisabled={!allowRemoval}
            onClick={() => onRemove(variation.id)}
          >
            <BsTrash size={16} />
          </Button>
        </div>
      </div>
    </div>
  );
};

const StoresPagesPopover: React.FC<{
  currentValue: string;
  pagePrefix: string;
  onPageSelect: (value: string) => void;
}> = ({ currentValue, pagePrefix, onPageSelect }) => {
  const storePages = useEditorSelector(selectPages);

  return (
    <Popover shouldClose>
      <Popover.Content
        title="Replo Pages"
        shouldPreventDefaultOnInteractOutside={false}
      >
        <RegularList
          itemSize={50}
          options={storePages.map((page) => ({
            id: page.id,
            label: (
              <div className="flex flex-col gap-1">
                <div className="font-bold">{page.name}</div>
                <div className="text-slate-400">
                  /pages/{page.shopifyPagePath}
                </div>
              </div>
            ),
            value: page.shopifyPagePath,
          }))}
          selectedItems={storePages
            .filter(
              (page) => currentValue === `${pagePrefix}${page.shopifyPagePath}`,
            )
            .map((page) => page.shopifyPagePath)}
          onSelect={(value) => {
            if (value) {
              onPageSelect(String(value));
            }
          }}
        />
      </Popover.Content>
      <Popover.Trigger>
        <ReploLogoBadge className="h-4 w-4 text-slate-400 cursor-pointer hover:text-default" />
      </Popover.Trigger>
    </Popover>
  );
};
