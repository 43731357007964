import type { CustomPropDefinitionAndValue } from "@editor/components/editor/page/element-editor/components/config-menus/utils";
import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { Component } from "replo-runtime/shared/Component";

import * as React from "react";

import { getCustomComponentPropTypeEditorData } from "@components/editor/customComponentProps";
import { Group } from "@editor/components/common/designSystem/Group";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useStoreProductsFromDraftElement } from "@editor/hooks/useStoreProducts";
import { selectLocaleData } from "@editor/reducers/commerce-reducer";
import {
  selectAllTextComponentNames,
  selectDraftComponentContext,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { useEditorSelector } from "@editor/store";
import {
  getExtraActionsBasedOnComponentType,
  getSelectableOptionValues,
} from "@editorModifiers/utils";

type StandardCustomPropControlProps = {
  draftComponent: Component;
  // NOTE (Evan, 7/25/23) customProp is optional here to make usage simpler –
  // TypeScript isn't smart enough to know that every custom prop will be defined,
  // so doing .find() on the array of CustomProps will potentially return undefined.
  // It's cleaner to do the check here rather than in the config menu that uses this
  // component.
  customProp?: CustomPropDefinitionAndValue;
  isEnabled?: boolean;
  controlledOnChange?: (value: any) => void;
  noWrapper?: boolean;
  description?: string;
};

const StandardCustomPropControl: React.FC<StandardCustomPropControlProps> = ({
  draftComponent,
  customProp,
  isEnabled = true,
  controlledOnChange,
  noWrapper,
  description,
}) => {
  const context = useEditorSelector(selectDraftComponentContext);
  const allTextComponents = useEditorSelector(selectAllTextComponentNames);
  const { activeCurrency, activeLanguage, moneyFormat } =
    useEditorSelector(selectLocaleData);
  const { products } = useStoreProductsFromDraftElement();
  const templateProduct =
    useEditorSelector(selectTemplateEditorStoreProduct) ?? null;
  const applyComponentAction = useApplyComponentAction();

  if (!customProp) {
    return null;
  }

  const editorData = getCustomComponentPropTypeEditorData(
    customProp.definition.type,
  );

  const onChange =
    controlledOnChange ??
    ((value: any) => {
      const actions: UseApplyComponentActionType[] = [
        {
          componentId: draftComponent.id,
          type: "setProps",
          value: { [customProp.definition.id]: value },
        },
      ];
      const extraActionsBasedOnComponentType =
        getExtraActionsBasedOnComponentType(
          value,
          draftComponent,
          customProp.definition,
        );

      applyComponentAction({
        type: "applyCompositeAction",
        value: [...actions, ...extraActionsBasedOnComponentType],
      });
    });

  const onDelete = () => {
    applyComponentAction({
      type: "deleteProps",
      propName: customProp.definition.id,
    });
  };

  const options = getSelectableOptionValues({
    customPropDefinition: customProp.definition,
    draftComponent,
    context,
    productResolutionDependencies: {
      products,
      moneyFormat,
      currencyCode: activeCurrency,
      language: activeLanguage,
      templateProduct,
    },
    allTextComponents,
  });

  const contentToRender = editorData?.render({
    component: draftComponent,
    products,
    value: customProp.value,
    customPropId: customProp.definition.id,
    description: description ?? customProp.definition.description ?? "",
    onChange,
    onDelete,
    options,
    attributeName: customProp.definition.id,
    isEnabled,
    disabledDescription: customProp.definition.disabledDescription,
    definition: customProp.definition,
    context,
    disableDynamicData: customProp.definition.disableDynamicData,
    templateProduct,
  });

  if (editorData.hasCustomWrapper || noWrapper) {
    return <div className="w-full">{contentToRender}</div>;
  }

  return (
    <Group
      name={customProp.definition.name}
      isDefaultOpen
      isCollapsible
      key={customProp.definition.id}
    >
      <div className="w-full">{contentToRender}</div>
    </Group>
  );
};

export default StandardCustomPropControl;
