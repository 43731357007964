import type { Editor as CoreEditor } from "@tiptap/core";
import type { Editor } from "@tiptap/react";

import * as React from "react";

import { TIPTAP_EDITOR_SCROLLABLE_DIV_ID } from "@editor/components/editor/constants";
import { stringToHTML } from "@editor/utils/html";

import BulletList from "@tiptap/extension-bullet-list";
import Color from "@tiptap/extension-color";
import HightLight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { EditorContent, mergeAttributes, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { DOMSerializer } from "prosemirror-model";

type TipTapTextEditorProps = {
  editor: Editor | null;
};

const TipTap: React.FC<TipTapTextEditorProps> = ({
  editor,
}: TipTapTextEditorProps) => {
  return <EditorContent editor={editor} />;
};

function getCurrentScrollTop(): number {
  const scrollContainer = document.getElementById(
    TIPTAP_EDITOR_SCROLLABLE_DIV_ID,
  ) as HTMLElement;
  const currentScrollTop = scrollContainer.scrollTop;

  return currentScrollTop;
}

function setCurrentScrollTop(value: number) {
  const scrollContainer = document.getElementById(
    TIPTAP_EDITOR_SCROLLABLE_DIV_ID,
  ) as HTMLElement;

  requestAnimationFrame(() => {
    scrollContainer.scrollTop = value;
  });
}

const additionalScrollMargin = 15;

const CustomStarterKit = StarterKit.extend({
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        const currentScrollTop = getCurrentScrollTop();
        this.editor.commands.splitBlock({ keepMarks: true });
        setCurrentScrollTop(currentScrollTop + additionalScrollMargin);

        // Note (Ovishek, 2022-08-20): Returns true means it prevents default
        return true;
      },
    };
  },
}).configure();

const defaultText = "<p></p>";

const extensionsForTiptap = [
  CustomStarterKit,
  Underline,
  Color.configure({
    types: ["textStyle"],
  }),
  TextStyle,
  HightLight.configure({
    multicolor: true,
  }),
  BulletList.extend({
    addKeyboardShortcuts() {
      return {
        "Shift-Tab": () => {
          if (!this.editor.isActive("listItem")) {
            return false;
          }

          const currentScrollTop = getCurrentScrollTop();
          const success = this.editor.commands.liftListItem("listItem");
          setCurrentScrollTop(currentScrollTop);

          return success;
        },
        Enter: () => {
          if (!this.editor.isActive("listItem")) {
            return false;
          }
          const { state } = this.editor;
          const { $from } = state.selection;

          const currentScrollTop = getCurrentScrollTop();

          if ($from.parent.content.size === 0) {
            this.editor.commands.liftListItem("listItem");
            this.editor.commands.splitBlock({ keepMarks: true });

            return true;
          }

          this.editor.commands.splitListItem("listItem");
          setCurrentScrollTop(currentScrollTop + additionalScrollMargin);

          // Note (Max, 2024-05-06): Return true if we're in a list item to indicate command
          // was successful. Otherwise return false to to let the other Enter() handler take
          // care of it
          return this.editor.isActive("listItem");
        },
      };
    },
  }),
  Link.extend({
    // Note (Noah, 2022-11-14, REPL-5058): For whatever reason, the Link extension
    // doesn't support setting rel as an HTML attribute. So, we have to merge it here
    // based on the target attribute.
    renderHTML({ HTMLAttributes }) {
      const effectiveAttributes =
        HTMLAttributes.target === "_blank"
          ? { ...HTMLAttributes, rel: "noopener nofollow noreferrer" }
          : HTMLAttributes;
      return [
        "a",
        mergeAttributes(this.options.HTMLAttributes, effectiveAttributes),
        0,
      ];
    },
  }).configure({
    openOnClick: false,
    // Note (Noah, 2022-11-14, REPL-5058): for some reason, the default behavior of
    // the Tiptap link extension is to open links in a new tab with a noopener nofollow
    // noreferrer rel. We don't want that because we only want to set those when the
    // user chooses to open in new tab, so we override that here
    // See: https://github.com/ueberdosis/tiptap/blob/9591865795b489da062d93050b1780daedeb629b/packages/extension-link/src/link.ts#L83
    HTMLAttributes: {
      target: null,
      rel: null,
    },
  }),
  Superscript,
  Subscript,
];

export const useTipTapEditor = (
  value: string,
  onChange?: (value: string) => void,
  onBlur?: () => void,
  onFocus?: () => void,
  onSelectionUpdate?: (editor: CoreEditor) => void,
  onCreate?: (editor: CoreEditor) => void,
) => {
  const editor = useEditor(
    {
      editorProps: {
        transformPastedText(text) {
          return text.replaceAll("&nbsp;", " ");
        },
        transformPastedHTML(html) {
          return html.replaceAll("&nbsp;", " ");
        },
        attributes: {
          id: "in-tiptap-editor",
        },
      },
      extensions: extensionsForTiptap,
      content: value || defaultText,
      onUpdate: ({ editor }) => {
        // NOTE (Max 2024-05-06): We use DOMSerializer because editor.getHTML() doens't capture
        // the empty <p> tags. We need them to manipulate stringHTML below (to insert <br> tags)
        const schema = editor.schema;
        const serializer = DOMSerializer.fromSchema(schema);
        const doc = editor.state.doc;
        const div = document.createElement("div");
        serializer.serializeFragment(doc.content, { document }, div);

        // NOTE (Max 2024-05-06): Replace empty <p> tags with <br> in the HTML string, otherwise
        // they won't show up in the rendered output (outside the TipTap editor)
        const stringHTML = div.innerHTML.replace(/<p><\/p>/g, "<br>");

        // NOTE (Sebas, 2023-01-04): This is in charge of filtering empty HTML tags
        const html = stringToHTML(stringHTML);
        const htmlElements = html.querySelectorAll(
          "p, span, strong, h1, h2, h3, em, s, u", // These are the tags used by tiptap editor
        );
        htmlElements.forEach((element) => {
          if (element.innerHTML === "") {
            element.remove();
          }
        });
        const filteredHTML = html.innerHTML.toString();
        onChange?.(filteredHTML);
      },
      onBlur: () => {
        onBlur?.();
      },
      onFocus: () => {
        onFocus?.();
      },
      onSelectionUpdate: ({ editor }) => {
        onSelectionUpdate?.(editor);
      },
      onCreate: ({ editor }) => {
        onCreate?.(editor);
      },
    },
    [onChange, onBlur, onFocus],
  );

  return editor;
};

export default TipTap;
