import type { RectReadOnly } from "react-use-measure";

import * as React from "react";

import Tooltip from "@editor/components/common/designSystem/Tooltip";

import { Item, Root } from "@radix-ui/react-toggle-group";
import classNames from "classnames";
import every from "lodash-es/every";
import isEqual from "lodash-es/isEqual";
import isUndefined from "lodash-es/isUndefined";
import pickBy from "lodash-es/pickBy";
import getSize from "lodash-es/size";
import { animated, config, useSpring, useSprings } from "react-spring";
import useMeasure from "react-use-measure";
import { usePrevious } from "replo-runtime/shared/hooks/usePrevious";
import { isNotNullish } from "replo-utils/lib/misc";
import { twMerge } from "tailwind-merge";

type ArrayOrReadonlyArray<T> = T[] | readonly T[];

export type ToggleGroupOption<T extends string = string> = {
  label: React.ReactNode;
  value: T;
  tooltipContent?: React.ReactNode;
  attributes?: { [key: string]: string | boolean };
};

type ToggleGroupValueProps<Value extends string> =
  | {
      type: "multi";
      value: Value[];
      allowsDeselect?: boolean;
      onChange(value: Value[]): void;
    }
  | {
      type: "single";
      allowsDeselect: false;
      value: Value | null;
      onChange(value: Value): void;
    }
  | {
      type: "single";
      allowsDeselect?: true;
      value: Value | null;
      onChange(value: Value | null): void;
    };

type ToggleGroupProps<T extends string> = {
  options: ArrayOrReadonlyArray<ToggleGroupOption<T>>;
  className?: string;
  style?: React.CSSProperties;
  allowsDeselect?: boolean;
  size?: "sm" | "lg" | "xl";
  toggleItemClassName?: string;
  animatedItemClassName?: string;
  isDark?: boolean;
} & ToggleGroupValueProps<T>;

// NOTE (Chance 2023-12-04): See usage in component for the why these are here.
// Using :-----: as delimiter since it's extremely improbable for values.
const DELIMITER = ":-----:";
function serializeValues(values: string[]) {
  if (values.length === 0) {
    return null;
  }
  return [...values].sort().join(DELIMITER);
}

function deserializeValues(valuesString: string | null | undefined) {
  return !valuesString ? [] : valuesString.split(DELIMITER).sort();
}

const Separator = ({
  options,
  optionActive,
  selected,
  index,
  selectedIndexes,
}: {
  options: ArrayOrReadonlyArray<ToggleGroupOption>;
  optionActive: ToggleGroupOption;
  selected: string | null;
  index: number;
  selectedIndexes: { index: number; isSelected: boolean }[];
}) => {
  const optionSelected = options.find((opt) => opt.value === selected);

  let selectedNextOption = false;

  selectedIndexes.forEach((item) => {
    if (item.isSelected && item.index + 1 === index) {
      selectedNextOption = true;
    }
  });

  const shouldShow =
    !selectedNextOption &&
    optionSelected &&
    optionActive !== optionSelected &&
    options[options.indexOf(optionSelected) + 1] !== optionActive;

  const opacityStyles = useSpring({
    opacity: shouldShow ? 1 : 0,
  });

  return (
    <animated.div
      className="h-4 self-center border-r border-slate-300"
      style={opacityStyles}
    />
  );
};

function ToggleItem({
  option,
  selected,
  index,
  syncMeasurements,
  withoutWidth,
  length,
  size,
  className,
}: {
  length: number;
  option: ToggleGroupOption;
  selected: string | null;
  index: number;
  syncMeasurements: (index: number, measures: RectReadOnly) => void;
  withoutWidth: boolean;
  size: "sm" | "lg" | "xl";
  className?: string;
}) {
  const [itemRef, measures] = useMeasure();

  React.useEffect(() => {
    syncMeasurements(index, measures);
  }, [syncMeasurements, measures, index]);

  const Wrapper = option.tooltipContent ? Tooltip : React.Fragment;
  const wrapperProps = (
    option.tooltipContent
      ? { content: option.tooltipContent, triggerAsChild: true }
      : {}
  ) as any;
  const sizeClassName = classNames({
    "h-5 text-xs": size === "sm",
    "h-7 text-sm": size === "lg",
    "h-8 text-base": size === "xl",
  });
  return (
    <Wrapper {...wrapperProps}>
      <Item
        ref={itemRef}
        value={option.value}
        style={{
          position: "relative",
          flexShrink: 1,
        }}
        className={twMerge(
          classNames(
            "flex cursor-pointer select-none items-center justify-center overflow-hidden text-ellipsis",
            {
              "text-accent dark:text-white": option.value === selected,
              "text-muted dark:text-slate-600": option.value !== selected,
              "py-1 px-2": withoutWidth,
              "flex-1": !withoutWidth,
              "mx-0.5": index !== 0 && index !== length - 1,
              "mr-0.5": index === 0,
              "ml-0.5": index === length - 1,
            },
          ),
          sizeClassName,
          className,
        )}
        {...(option?.attributes ?? {})}
      >
        {option.label}
      </Item>
    </Wrapper>
  );
}

export const ToggleGroup = <T extends string = string>(
  props: ToggleGroupProps<T>,
) => {
  const {
    options,
    className,
    style,
    allowsDeselect = true,
    size = "sm",
    toggleItemClassName,
    animatedItemClassName,
    isDark = false,
  } = props;
  // NOTE (Chance 2023-12-04): It's really annoying and error-prone to enforce
  // memoization on a small array of strings that can easily be stringified and
  // parsed with a delimiter. This lets us use the values in effects and
  // memoized values safely.
  const serializedSelectedValues =
    props.type === "multi" ? serializeValues(props.value) : null;

  const selectedValues = React.useMemo(() => {
    if (props.type === "multi") {
      return deserializeValues(serializedSelectedValues);
    }
    return props.value == null ? [] : [props.value];
  }, [serializedSelectedValues, props.type, props.value]);

  const prevSelectedValues = usePrevious(selectedValues);

  const [itemsMeasures, setItemMeasures] = React.useState<RectReadOnly[]>([]);

  const hasEverChanged = React.useRef<boolean>(props.type === "multi");
  const [parentMeasureRef, parentMeasures] = useMeasure();

  const itemsMeasuresSpecs = (val: string) => {
    const absoluteMeasures = itemsMeasures[getSelectedIndex(val)]!;
    const parentMeasuresFiltered = parentMeasures;

    const bounds =
      getSize(absoluteMeasures) > 0 && getSize(parentMeasuresFiltered) > 0
        ? {
            left: absoluteMeasures.left - parentMeasuresFiltered.left,
            width: absoluteMeasures.width,
            height: absoluteMeasures.height,
            top: absoluteMeasures.top - parentMeasuresFiltered.top,
          }
        : { top: 0, left: 0, width: 0, height: 0 };

    // Note (Noah, 2022-01-14): If the selection value has never changed,
    // then we want no animation, so ensure that the `from` matches the `to`.
    // Otherwise, we'd see the indicator fly in from 0, 0 on first selection
    if (
      !hasEverChanged.current ||
      // TODO (Noah, 2022-02-23): I'm not sure why, but we have to check if all
      // the bounds are 0 here - they seem to be 0 for like 1-2 renders, I think
      // something might be off inside useMeasure somewhere. At any rate, if
      // everything is 0, we want no animation at all: this fixes issues where
      // the selection indicator animtes in from 0, 0, that is, from the corner
      // of the toggle.
      every(bounds, (value) => value === 0) ||
      every(parentMeasures, (value) => value === 0)
    ) {
      return {
        from: bounds,
        to: bounds,
      };
    }

    return bounds;
  };

  const isAnythingSelected =
    selectedValues.length > 0 && isNotNullish(selectedValues[0]!);

  const shouldAnimatePosition =
    prevSelectedValues?.[0] != null && selectedValues[0] != null;
  const positionStyles = useSpring({
    ...(shouldAnimatePosition
      ? itemsMeasuresSpecs(selectedValues[0]!)
      : {
          from: itemsMeasuresSpecs(
            prevSelectedValues?.[0] ?? selectedValues[0]!,
          ),
          to: itemsMeasuresSpecs(selectedValues[0]! ?? prevSelectedValues?.[0]),
        }),
    config: config.stiff,
  });

  const opacityStyles = useSpring({
    opacity: isAnythingSelected ? 1 : 0,
    transform: isAnythingSelected ? `scale(1)` : `scale(0)`,
    config: config.stiff,
  });

  const opacityMultipleStyles = useSprings(
    options.length,
    options.map((option) => {
      const isSelected = selectedValues.find((value) => value === option.value);

      const itemsSelectedSpecs = pickBy(
        itemsMeasuresSpecs(option.value),
        (value) => (value as number) > 0,
      );

      let styles: {
        config: any;
        opacity: number;
        transform: string;
        width?: number;
        height?: number;
        top?: number;
        left?: number;
      } = {
        opacity: isSelected ? 1 : 0,
        transform: isSelected ? `scale(1)` : `scale(0)`,
        config: config.stiff,
      };
      if (isSelected && itemsSelectedSpecs) {
        styles = {
          ...itemsSelectedSpecs,
          ...styles,
        };
      }
      return styles;
    }),
  );

  function getSelectedIndex(valueSelected: string) {
    return options.findIndex((opt) => opt.value === valueSelected);
  }

  const onUpdateMeasurements = React.useCallback(
    (index: number, measures: RectReadOnly) => {
      setItemMeasures((itemsMeasures) => {
        const itemsMeasuresNew = [...itemsMeasures];
        itemsMeasuresNew.splice(index, 1, measures);
        if (isEqual(itemsMeasures, itemsMeasuresNew)) {
          return itemsMeasures;
        }
        return itemsMeasuresNew;
      });
    },
    [],
  );

  function onChangeSingleValue(value: string) {
    if (props.type !== "single") {
      return;
    }
    const _value = toToggleGroupValue(value);
    if (_value === props.value || (_value == null && !allowsDeselect)) {
      return;
    }
    hasEverChanged.current = true;
    props.onChange?.(_value as T);
  }

  function onChangeMultiValues(values: string[]) {
    if (
      props.type !== "multi" ||
      (values.length === 0 && !allowsDeselect) ||
      serializeValues(values) === serializedSelectedValues
    ) {
      return;
    }

    hasEverChanged.current = true;
    props.onChange?.(values as T[]);
  }

  const selectedIndexes = options.map((opt, index) => {
    const selected = selectedValues.find((val) => val === opt.value);
    return {
      index,
      isSelected: !isUndefined(selected),
    };
  });

  const radixProps =
    props.type === "multi"
      ? {
          type: "multiple" as const,
          value: selectedValues,
          onValueChange: onChangeMultiValues,
        }
      : {
          type: "single" as const,
          value: selectedValues[0]!,
          onValueChange: onChangeSingleValue,
        };

  return (
    <Root {...radixProps} className="flex self-center" asChild>
      <div
        ref={parentMeasureRef}
        style={style}
        className={twMerge(
          classNames(
            "relative flex items-center bg-subtle justify-center rounded p-0.5 outline-none",
            { "bg-slate-900 dark": isDark },
          ),
          className,
        )}
      >
        {props.type === "multi" ? (
          opacityMultipleStyles.map((styles, i) => {
            return (
              <animated.div
                key={i.toString()}
                style={styles}
                className={twMerge(
                  classNames(
                    "absolute flex-1 cursor-pointer self-center justify-self-center rounded bg-default shadow outline-none",
                    {
                      "bg-slate-700": isDark,
                    },
                  ),
                  animatedItemClassName,
                )}
              />
            );
          })
        ) : (
          <animated.div
            style={{
              ...positionStyles,
              ...opacityStyles,
            }}
            className={twMerge(
              classNames(
                "absolute flex-1 cursor-pointer self-center justify-self-center rounded bg-default shadow outline-none",
                {
                  "bg-slate-700": isDark,
                },
              ),
              animatedItemClassName,
            )}
          />
        )}
        {options.map((option, index) => {
          const selected =
            props.type === "single"
              ? selectedValues[0]!
              : selectedValues.find((opt) => opt === option.value) ?? null;

          return (
            <React.Fragment key={index.toString()}>
              {index > 0 && (
                <Separator
                  options={options}
                  optionActive={option}
                  selected={selected}
                  index={index}
                  selectedIndexes={selectedIndexes}
                />
              )}
              <ToggleItem
                option={option}
                selected={selected}
                index={index}
                withoutWidth={!style?.width}
                syncMeasurements={onUpdateMeasurements}
                length={options.length}
                size={size}
                className={toggleItemClassName}
              />
            </React.Fragment>
          );
        })}
      </div>
    </Root>
  );
};

export default ToggleGroup;

/**
 * NOTE (Chance 2023-12-05): Radix uses an empty string to represent no value
 * for some reason, but we use null.
 *
 * @returns `null` if the original value is `undefined` or an empty string,
 * otherwise the original the value.
 */
function toToggleGroupValue(value: string | null | undefined) {
  return value === "" || value === undefined ? null : value;
}
