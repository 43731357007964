import type {
  GradientValue,
  ReploShopifyVariant,
  SolidOrGradient,
  Swatch,
  SwatchImage,
  SwatchValue,
} from "../types";

import { isLiquidDynamicData } from "replo-runtime/store/utils/liquid";

import { isSolidValue } from "../types";

type SwatchValueMappingValue = string | string[] | GradientValue;
type SwatchValueMapping = Record<string, SwatchValueMappingValue>;

function getSwatchValueMapping(values: SwatchValue): SwatchValueMapping {
  return Object.fromEntries(
    Object.entries(values).map(([key, value]) => {
      switch (key) {
        case "color": {
          const colorValue = value as SolidOrGradient;
          return [
            key,
            isSolidValue(colorValue) ? colorValue.color : colorValue.gradient,
          ];
        }
        case "image":
          return [key, (value as SwatchImage)?.src];

        case "imageList":
          return [key, (value as SwatchImage[]).map((image) => image.src)];

        default:
          return [];
      }
    }),
  );
}

export function getOptionSwatchValueMapping(
  swatches: Swatch[],
  productId: string | number,
  option: { name: string; value: string },
) {
  const { name, value } = option;
  const mappedSwatches = swatches.reduce(
    (currentMappedSwatches: (string | SwatchValueMapping)[][], swatch) => {
      const {
        data: { options },
      } = swatch;

      let currentOption = options?.find(
        (swatchOptionRow) =>
          swatchOptionRow.key.name === name &&
          swatchOptionRow.key.value === value &&
          (!swatchOptionRow.key.productId ||
            String(swatchOptionRow.key.productId) === String(productId)),
      );

      // NOTE (Gabe 2023-10-09): If we can't find the swatch value for the
      // current product we'll fallback to any product. This way if Green is
      // defined on any product it will be used for any products where this
      // option value is not explicitly defined.
      if (!currentOption?.value) {
        currentOption = options?.find(
          (swatchOptionRow) =>
            swatchOptionRow.key.name === name &&
            swatchOptionRow.key.value === value,
        );
      }

      if (!currentOption?.value) {
        return currentMappedSwatches;
      }

      return [
        ...currentMappedSwatches,
        [swatch.name, getSwatchValueMapping(currentOption.value)],
      ];
    },
    [],
  );

  return mappedSwatches.some((mappedSwatch) => mappedSwatch.length > 0)
    ? Object.fromEntries(mappedSwatches)
    : null;
}

export function getVariantSwatchValueMapping(
  swatches: Swatch[],
  variant: ReploShopifyVariant,
) {
  const { variantId, productId } = variant;
  const mappedSwatches = swatches.reduce(
    (currentMappedSwatches: (string | SwatchValueMapping)[][], swatch) => {
      const {
        data: { variants },
      } = swatch;

      const currentVariant = variants?.find(
        (v) =>
          String(v.key.variantId) === String(variantId) &&
          String(v.key.productId) === String(productId),
      );
      if (!currentVariant?.value) {
        return currentMappedSwatches;
      }

      return [
        ...currentMappedSwatches,
        [swatch.name, getSwatchValueMapping(currentVariant.value)],
      ];
    },
    [],
  );

  return mappedSwatches.some((mappedSwatch) => mappedSwatch.length > 0)
    ? Object.fromEntries(mappedSwatches)
    : null;
}

export const isDynamicDataValue = (value: string | undefined) => {
  return (
    (value?.startsWith("{{") ||
      value?.startsWith('url("{{') ||
      value?.startsWith("<p>{{") ||
      isLiquidDynamicData(value)) ??
    false
  );
};
