import type { AnalyticsReadQuery } from "schemas/generated/analyticsRead";

import * as React from "react";

import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

const useAnalyticsRead = (
  query: AnalyticsReadQuery,
  workspaceId: string | null,
) => {
  const shouldFetch = workspaceId && query.urlHosts.length > 0;

  const {
    data,
    isLoading,
    refetch: refetchRangeResults,
  } = trpc.analytics.find.useQuery(
    shouldFetch ? { query, workspaceId } : skipToken,
  );

  const [rangeResults, setRangeResults] = React.useState(data?.rangeResults);
  const [totalRowsCount, setTotalRowsCount] = React.useState(
    data?.totalRowsCount,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: We want this for now
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await refetchRangeResults();
      setRangeResults(response.data?.rangeResults);
      setTotalRowsCount(response.data?.totalRowsCount);
    };

    if (shouldFetch) {
      void fetchData();
    }
  }, [query, workspaceId, refetchRangeResults, shouldFetch]);
  return {
    rangeResults: rangeResults ?? null,
    totalRowsCount: totalRowsCount ?? null,
    isLoading,
  };
};

export default useAnalyticsRead;
