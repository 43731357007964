import type { UseTransitionProps } from "@react-spring/web";
import type { TooltipTriggerProps, TooltipTriggerState } from "react-stately";
import type { FocusableElement } from "replo-utils/dom/dom-types";
import type { AriaLabelProps, UnsafeStyleProps } from "../../utils/props";

import * as React from "react";

import {
  animated,
  config,
  useTransition as useReactSpringTransition,
} from "@react-spring/web";
import classNames from "classnames";
import {
  OverlayContainer,
  useOverlayPosition,
  useTooltip,
  useTooltipTrigger,
} from "react-aria";
import { useTooltipTriggerState } from "react-stately";
import { useComposedRefs } from "replo-utils/react/use-composed-refs";
import { useIsHydrated } from "replo-utils/react/use-is-hydrated";
import { unsafe_useLayoutEffectWithoutWarning as useLayoutEffect } from "replo-utils/react/use-layout-effect";

import { TooltipContext, useTooltipContext } from "./tooltip-shared";

type TooltipOverlayProps = Omit<
  React.DOMAttributes<HTMLDivElement>,
  "style" | "className"
> &
  AriaLabelProps &
  UnsafeStyleProps;

type TooltipTriggerRenderFunction<TriggerElement extends FocusableElement> =
  (props: {
    triggerRef: React.Ref<TriggerElement>;
    triggerProps: React.DOMAttributes<TriggerElement>;
    state: TooltipTriggerState;
  }) => React.ReactNode;

type TooltipStateProps =
  | {
      /**
       * Whether or not the tooltip is open. Used only when you are controlling
       * the tooltip's state.
       */
      isOpen: boolean;
      /**
       * Whether or not the tooltip is initially open. Used only when you are
       * not controlling the tooltip's state.
       */
      defaultOpen?: never;
    }
  | {
      isOpen?: never;
      defaultOpen: boolean;
    }
  | {
      isOpen?: never;
      defaultOpen?: never;
    };

function TooltipProvider<TriggerElement extends FocusableElement>({
  value,
  children,
}: {
  value: TooltipContextValue<TriggerElement>;
  children: React.ReactNode;
}) {
  return (
    <TooltipContext.Provider value={value}>{children}</TooltipContext.Provider>
  );
}

type TooltipContextValue<TriggerElement extends FocusableElement> = {
  allowPointerEvents: boolean | undefined;
  closeDelay: number;
  delay: number;
  isDisabled: boolean;
  offset: number;
  position: TooltipPosition;
  state: TooltipTriggerState;
  tooltipProps: React.DOMAttributes<HTMLDivElement>;
  triggerEvents: "focus" | "hover focus";
  triggerProps: React.DOMAttributes<TriggerElement>;
  triggerRef: React.RefObject<TriggerElement>;
};

export type TooltipPosition = "top" | "left" | "right" | "bottom";

export type TooltipProps<TriggerElement extends FocusableElement> =
  TooltipStateProps & {
    /**
     * Whether or not pointer events are allowed on the tooltip overlay.
     * Generally this isn't desired, as tooltips are ephiemeral, should not be
     * interactive, and user hovering over them might block intended
     * interactions with the underlying content.
     */
    allowPointerEvents?: boolean;
    /**
     * The delay in milliseconds before the tooltip closes after the user moves
     * away from the trigger.
     * @default 300
     */
    closeDelay?: number;
    /**
     * Content that appears inside the tooltip overlay.
     */
    content: React.ReactNode;
    /**
     * The delay in milliseconds before the tooltip opens after the user hovers
     * or focuses on the trigger.
     * @default 150
     */
    delay?: number;
    /**
     * Whether or not the tooltip is disabled. If true, the tooltip will not
     * open on hover or focus interactions.
     * @default false
     */
    isDisabled?: boolean;
    /**
     * The distance in pixels from the trigger to the tooltip overlay.
     * @default 10
     */
    offset?: number;
    /**
     * Event handler called when the open state of the tooltip changes.
     * @param isOpen Whether or not the tooltip is open.
     */
    onOpenChange?: (isOpen: boolean) => void;
    /**
     * Optional ref to attach to the tooltip overlay element.
     */
    overlayRef?: React.RefObject<HTMLDivElement>;
    /**
     * The side of the trigger the tooltip should appear on. If there are
     * collisions with the viewport, the tooltip will flip to another side.
     * @default "top"
     */
    position?: TooltipPosition;
    /**
     * The trigger element for the tooltip. This should always be an interactive element.
     */
    trigger: TooltipTriggerRenderFunction<TriggerElement>;
    /**
     * Which events should trigger the tooltip to open. In almost all cases you
     * should prefer "hover focus" unless there's a good reason to avoid showing
     * tooltips on hover.
     * @default "hover focus"
     */
    triggerEvents?: "focus" | "hover focus";
    /**
     * Optional ref to attach to the tooltip trigger element. This is important
     * because the tooltip trigger uses a ref internally, so if you need your
     * own ref the tooltip component will merge them and return a single ref
     * callback to be passed to the underlying element.
     */
    triggerRef?: React.RefObject<TriggerElement>;
  };

export function Tooltip<
  TriggerElement extends FocusableElement = HTMLButtonElement,
>({
  allowPointerEvents,
  closeDelay = 200,
  content,
  defaultOpen,
  delay = 150,
  isDisabled = false,
  isOpen,
  offset = 10,
  onOpenChange,
  overlayRef,
  position = "top",
  trigger,
  triggerEvents = "hover focus",
  triggerRef: forwardedTriggerRef,
}: TooltipProps<TriggerElement>) {
  const _trigger = triggerEvents === "focus" ? triggerEvents : undefined;
  const reactAriaTriggerProps: TooltipTriggerProps = {
    closeDelay,
    defaultOpen,
    delay,
    isDisabled,
    isOpen,
    onOpenChange,
    trigger: _trigger,
  };
  const state = useTooltipTriggerState(reactAriaTriggerProps);
  const ownRef = React.useRef<TriggerElement>(null);
  const triggerRef = useComposedRefs(ownRef, forwardedTriggerRef);

  const { triggerProps, tooltipProps } = useTooltipTrigger(
    reactAriaTriggerProps,
    state,
    ownRef,
  );

  return (
    <TooltipProvider
      value={{
        allowPointerEvents,
        closeDelay,
        delay,
        isDisabled,
        offset,
        position,
        state,
        tooltipProps,
        triggerEvents,
        triggerProps,
        triggerRef: ownRef,
      }}
    >
      {trigger({ triggerProps, triggerRef, state })}
      <TooltipOverlay forwardedRef={overlayRef} {...tooltipProps}>
        {content}
      </TooltipOverlay>
    </TooltipProvider>
  );
}

function TooltipOverlay<TriggerElement extends FocusableElement>({
  forwardedRef,
  ...props
}: TooltipOverlayProps & {
  id?: string;
  forwardedRef: React.Ref<HTMLDivElement> | undefined;
}) {
  // NOTE (Chance 2024-04-03): We want an entrance + exit animation, which is
  // why we use React Spring. However we want to bail on the animation if the
  // tooltip is closing and another tooltip is opening, otherwise there's a
  // weird overlap. We use this state to coordinate this between React Aria's
  // tooltip state and React Spring.
  const [forceHidden, setForceHidden] = React.useState(false);

  const { state, position, closeDelay } = useTooltipContext<TriggerElement>();
  const transitions = useReactSpringTransition(state.isOpen, {
    ...getTooltipTransition(position, {
      onAnimationEnd: () => {
        setForceHidden(false);
      },
    }),
    config: { ...config.stiff, duration: closeDelay },
  });

  const uuid = `replo-${React.useId()}`;
  const id = props.id ?? uuid;

  // NOTE (Chance 2024-04-03): Only return null if the tooltip state is not
  // open, otherwise there might be a delay in opening the tooltip if a previous
  // spring animation hasn't ended when another is triggered. If the tooltip
  // state is open we never want to hide it.
  if (forceHidden && !state.isOpen) {
    return null;
  }

  return (
    <>
      {transitions((styles, isOpen) => {
        return isOpen ? (
          <OverlayContainer>
            <TooltipOverlayImpl<TriggerElement>
              {...props}
              id={id}
              transitionStyles={styles}
              forwardedRef={forwardedRef}
              forceHidden={forceHidden}
              setForceHidden={setForceHidden}
            />
          </OverlayContainer>
        ) : null;
      })}
    </>
  );
}

function TooltipOverlayImpl<TriggerElement extends FocusableElement>({
  children,
  forwardedRef,
  transitionStyles,
  forceHidden,
  setForceHidden,
  ...props
}: TooltipOverlayProps & {
  transitionStyles: React.ComponentProps<typeof animated.div>["style"];
  forwardedRef: React.Ref<HTMLDivElement> | undefined;
  id: string;
  forceHidden: boolean;
  setForceHidden: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { allowPointerEvents, offset } = useTooltipContext<TriggerElement>();
  const { unsafe_style: style, unsafe_className: className } = props;

  const { triggerRef, position, state } = useTooltipContext<TriggerElement>();

  const overlayRef = React.useRef<HTMLDivElement | null>(null);
  const { overlayProps } = useOverlayPosition({
    placement: position,
    targetRef: triggerRef,
    overlayRef,
    offset,
    isOpen: state.isOpen,
  });

  const { tooltipProps } = useTooltip(props, state);
  const tooltipId = tooltipProps.id!;

  useForceHideExitingTooltip({
    overlayRef,
    isOpen: state.isOpen,
    tooltipId,
    setForceHidden,
  });

  return (
    <animated.div
      ref={overlayRef}
      {...overlayProps}
      className={classNames(
        "replo--tooltip-overlay",
        !allowPointerEvents && "pointer-events-none",
      )}
      style={{ ...overlayProps.style }}
      data-replo-ds-tooltip=""
      data-tooltip-id={tooltipId}
    >
      <div
        {...tooltipProps}
        ref={forwardedRef}
        className={classNames("replo--tooltip", className)}
        style={style}
      >
        {children}
      </div>
    </animated.div>
  );
}

/**
 * If a tooltip is exiting because another tooltip is opening, we want to force
 * hide the exiting tooltip to avoid the animation overlap. This hook will check
 * if there are any open tooltips while the current tooltip is closed and set
 * the forceHidden state accordingly.
 */
function useForceHideExitingTooltip(props: {
  overlayRef: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
  tooltipId: string;
  setForceHidden: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { overlayRef, isOpen, tooltipId, setForceHidden } = props;
  const isHydrated = useIsHydrated();

  // NOTE (Chance 2024-04-03): Use layout effect here to ensure state is updated
  // before paint to avoid flickering.
  useLayoutEffect(() => {
    const overlay = overlayRef.current;
    // NOTE (Chance 2024-04-03): Check for hydration since this is a layout
    // effect, and in the off-chance a controlled tooltip is initially rendered
    // as open we want to avoid a client/server mismatch.
    if (!overlay || !isHydrated) {
      return;
    }
    if (isOpen) {
      setForceHidden(false);
      return;
    }
    const _document = overlayRef.current?.ownerDocument ?? document;
    const openTooltips = _document.querySelectorAll(
      `[data-replo-ds-tooltip]:not([data-tooltip-id="${tooltipId}"])`,
    );
    if (openTooltips.length === 0) {
      setForceHidden(false);
    } else {
      setForceHidden(true);
    }
  }, [isOpen, tooltipId, isHydrated, setForceHidden, overlayRef]);
}

/**
 * Returns a transition object that will be passed into React Spring's
 * useTransition hook
 */
function getTooltipTransition(
  side: TooltipPosition,
  { onAnimationEnd }: { onAnimationEnd?: () => void },
) {
  const sideToTransition = {
    left: {
      fromX: "50%",
      fromY: "0",
      toX: "0%",
      toY: "0",
      leaveX: "50%",
      leaveY: "0",
    },
    right: {
      fromX: "-50%",
      fromY: "0",
      toX: "0%",
      toY: "0",
      leaveX: "-50%",
      leaveY: "0",
    },
    top: {
      fromX: "0",
      fromY: "50%",
      toX: "0",
      toY: "0%",
      leaveX: "0",
      leaveY: "50%",
    },
    bottom: {
      fromX: "0",
      fromY: "-50%",
      toX: "0",
      toY: "0%",
      leaveX: "0",
      leaveY: "-50%",
    },
  } satisfies Record<TooltipPosition, TransitionElement>;
  return {
    from: {
      "--exiting": 0,
      opacity: 0,
      transform: `translate(${sideToTransition[side].fromX}, ${sideToTransition[side].fromY})`,
    },
    enter: {
      "--exiting": 0,
      opacity: 1,
      transform: `translate(${sideToTransition[side].toX},${sideToTransition[side].toY})`,
    },
    leave: {
      "--exiting": 1,
      opacity: 0,
      transform: `translate(${sideToTransition[side].fromX}, ${sideToTransition[side].fromY})`,
    },
    onDestroyed: onAnimationEnd,
  } satisfies UseTransitionProps<boolean>;
}

interface TransitionElement {
  fromX: string;
  fromY: string;
  toX: string;
  toY: string;
  leaveX: string;
  leaveY: string;
}
