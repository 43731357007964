import * as React from "react";

import { infoToast, successToast } from "@common/designSystem/Toast";
import useContextMenuActions from "@editor/hooks/useContextMenuActions";
import useGetFlexboxWidthOrHeightOnChange from "@editor/hooks/useGetFlexboxWidthOrHeightOnChange";
import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import useSelection from "@editor/hooks/useSelection";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { useTargetFrameDocument } from "@editor/hooks/useTargetFrame";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  redoOperation,
  selectComponentDataMapping,
  selectDraftComponentId,
  selectDraftComponentName,
  selectDraftComponentOrAncestorVariantId,
  selectEditorMode,
  selectInternalDebugModeOn,
  selectProjectId,
  selectValidNextOperation,
  selectValidPreviousOperation,
  setDebugPanelVisibility,
  setEditorMode,
  undoOperation,
} from "@editor/reducers/core-reducer";
import {
  selectLastMarketplacePath,
  setLastMarketplacePath,
} from "@editor/reducers/marketplace-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import {
  setLeftBarActiveTab,
  setRightBarActiveTab,
} from "@editor/reducers/ui-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { routes } from "@editor/utils/router";
import useOpenCodeEditor from "@hooks/useOpenCodeEditor";

import { handleTransformWillChange } from "@/features/canvas/canvas-actions";
import { INITIAL_CANVAS_SCALE } from "@/features/canvas/canvas-constants";
import {
  selectCanvasInteractionMode,
  setCanvasInteractionMode,
} from "@/features/canvas/canvas-reducer";
import { useCanvasZoom } from "@/features/canvas/useCanvasZoom";
import { generatePath, useNavigate } from "react-router-dom";
import { useEffectEvent } from "replo-utils/react/use-effect-event";

const GlobalHotkeysListener: React.FC = () => {
  const store = useEditorStore();
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const draftComponentName = useEditorSelector(selectDraftComponentName);
  const dispatch = useEditorDispatch();
  const isRichTextEditorFocused = useEditorSelector(
    (state) => state.ui.isRichTextEditorFocused,
  );
  const navigate = useNavigate();
  const analytics = useLogAnalytics();
  const isEditMode = useEditorSelector(selectEditorMode) === EditorMode.edit;
  const isDebugPanelVisible = useEditorSelector(selectInternalDebugModeOn);
  // TODO (Noah, 2022-02-07): These actions are no longer really specific to
  // the context menu - they should be moved out of this hook and just made
  // available as either util functions (e.g. canPaste) or ComponentActionTypes
  // (e.g. paste) or normal redux actions (e.g. copy)
  const contextMenuActions = useContextMenuActions();
  const { selectedIds } = useSelection();
  const editorMode = useEditorSelector(selectEditorMode);
  const setDraftElement = useSetDraftElement();
  const modal = useModal();
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const { isMenuOpen: isAIMenuOpen, setIsMenuOpen: setIsAIMenuOpen } =
    useAIStreaming();
  const openCodeEditor = useOpenCodeEditor();
  const onFlexboxWidthOrHeightChange = useGetFlexboxWidthOrHeightOnChange();

  const { handleCanvasZoom, handleCanvasZoomIn, handleCanvasZoomOut } =
    useCanvasZoom();

  const handlePaste = useEffectEvent((event: ClipboardEvent) => {
    if (
      isRichTextEditorFocused ||
      [HTMLInputElement, HTMLTextAreaElement, HTMLSelectElement].some(
        (elementType) => {
          return event.target instanceof elementType;
        },
      )
    ) {
      return;
    }

    void contextMenuActions?.handlePaste("normal", event.clipboardData);
  });

  const handleCopy = useEffectEvent(function handleCopy(event: ClipboardEvent) {
    const selection = document.getSelection()?.toString();
    if (selection) {
      return;
    }

    if (selectedIds.length > 0) {
      contextMenuActions?.handleCopy(selectedIds, event.clipboardData);
    }
    event.preventDefault();
  });

  React.useEffect(() => {
    window.addEventListener("paste", handlePaste);
    window.addEventListener("copy", handleCopy);
    return () => {
      window.removeEventListener("paste", handlePaste);
      window.removeEventListener("copy", handleCopy);
    };
  }, [handlePaste, handleCopy]);

  const handleGroupDelete = React.useCallback(() => {
    if (selectedIds.length > 0) {
      contextMenuActions?.onGroupDelete(selectedIds);
    }
  }, [selectedIds, contextMenuActions]);

  const handleDelete = React.useCallback(() => {
    if (selectedIds.length > 0) {
      contextMenuActions?.onDelete(selectedIds[0]!, "hotkey");
    }
  }, [selectedIds, contextMenuActions]);

  const handleDuplicate = React.useCallback(() => {
    if (draftComponentId) {
      contextMenuActions?.handleDuplicate(draftComponentId);
    }
  }, [contextMenuActions, draftComponentId]);

  const handleDebug = React.useCallback(() => {
    dispatch(setDebugPanelVisibility(!isDebugPanelVisible));
  }, [dispatch, isDebugPanelVisible]);

  const handleCopyStyles = React.useCallback(() => {
    if (draftComponentId) {
      const activeDraftComponentOrAncestorVariantId =
        selectDraftComponentOrAncestorVariantId(store.getState());
      contextMenuActions?.handleCopyStyles(
        draftComponentId,
        activeDraftComponentOrAncestorVariantId,
      );
    }
  }, [contextMenuActions, draftComponentId, store]);

  const handlePasteStyles = React.useCallback(() => {
    void contextMenuActions?.handlePaste("alt");
  }, [contextMenuActions]);

  const handleGroupIntoContainer = React.useCallback(() => {
    if (selectedIds.length > 0) {
      contextMenuActions?.onGroupContainer(selectedIds);
    }
  }, [contextMenuActions, selectedIds]);

  const handleTogglePreviewMode = React.useCallback(() => {
    dispatch(setEditorMode(isEditMode ? EditorMode.preview : EditorMode.edit));
  }, [dispatch, isEditMode]);

  const handleToggleAIMenu = React.useCallback(() => {
    if (isAIMenuOpen) {
      setIsAIMenuOpen(false);
    }
    // Note (Evan, 2024-07-16) If the AI menu is enabled, it's not a modal,
    // so we can just toggle without any additional checks or anything
    else {
      setIsAIMenuOpen(true, "shortcut");
    }
  }, [isAIMenuOpen, setIsAIMenuOpen]);

  const handleToggleCodeEditor = React.useCallback(() => {
    if (openCodeEditor) {
      openCodeEditor?.();
    } else {
      infoToast(
        "Code Editor not available",
        "To open the code editor please select a custom code, liquid or integration component.",
      );
    }
  }, [openCodeEditor]);

  const handleToggleVersionHistory = React.useCallback(() => {
    dispatch(
      setEditorMode(isEditMode ? EditorMode.versioning : EditorMode.edit),
    );
  }, [dispatch, isEditMode]);

  const handleOpenProjectSettings = React.useCallback(() => {
    const projectId = selectProjectId(store.getState());
    const path = generatePath(routes.editor.settings, { projectId });
    navigate(path);
  }, [navigate, store]);

  const handleOpenPageSettings = React.useCallback(() => {
    modal.openModal({
      type: "updateElementModal",
    });
  }, [modal]);

  const handleMoveUpInTheTree = React.useCallback(() => {
    if (draftComponentId) {
      const componentDataMapping = selectComponentDataMapping(store.getState());
      const draftComponentData = componentDataMapping[draftComponentId];
      const ancestors = draftComponentData?.ancestorComponentData;
      if (ancestors) {
        const nearAncestor = ancestors[ancestors.length - 1];
        const nearAncestorId = nearAncestor?.[0];
        if (nearAncestorId) {
          setDraftElement({ componentId: nearAncestorId });
        }
      }
    }
  }, [draftComponentId, store, setDraftElement]);

  const handleMoveDownInTheTree = React.useCallback(() => {
    if (draftComponentId) {
      const componentDataMapping = selectComponentDataMapping(store.getState());
      const draftComponentData = componentDataMapping[draftComponentId];
      const descendants = draftComponentData?.containedComponentData;
      if (descendants) {
        const nearDescendant = descendants[0];
        const nearDescendantId = nearDescendant?.[0];
        if (nearDescendantId) {
          setDraftElement({ componentId: nearDescendantId });
        }
      }
    }
  }, [draftComponentId, store, setDraftElement]);

  const handleOpenExportToSectionModal = React.useCallback(() => {
    if (draftComponentId) {
      modal.openModal({
        type: "exportToSectionModal",
      });
    }
  }, [draftComponentId, modal]);

  const handleSetWidthToFillAvailable = React.useCallback(
    () => onFlexboxWidthOrHeightChange("fill", "width"),
    [onFlexboxWidthOrHeightChange],
  );

  const handleSetWidthToWrapContent = React.useCallback(
    () => onFlexboxWidthOrHeightChange("wrap", "width"),
    [onFlexboxWidthOrHeightChange],
  );

  const handleSetHeightToFillAvailable = React.useCallback(
    () => onFlexboxWidthOrHeightChange("fill", "height"),
    [onFlexboxWidthOrHeightChange],
  );

  const handleSetHeightToWrapContent = React.useCallback(
    () => onFlexboxWidthOrHeightChange("wrap", "height"),
    [onFlexboxWidthOrHeightChange],
  );

  const handleOpenMarketplace = React.useCallback(() => {
    const lastPath =
      selectLastMarketplacePath(store.getState()) ?? routes.marketplaceModal;
    analytics("editor.marketplace.browse", {
      from: "shortcut",
    });
    dispatch(setLastMarketplacePath(null));
    navigate(lastPath, {
      state: {
        marketplaceModalRequestType: draftComponentId ? "browse" : "create",
      },
    });
  }, [analytics, dispatch, draftComponentId, navigate, store]);

  const hasPreviousOperation = useEditorSelector(selectValidPreviousOperation);
  const hasNextOperation = useEditorSelector(selectValidNextOperation);

  // NOTE (Sebas, 2024-05-15): For some reason when you click on a modal for the
  // first time and you press the hotkey, the hotkey is not triggered. This is a
  // workaround to make sure the modal is deleted when the hotkey is pressed.
  //
  // TODO (Chance 2024-06-12): Debug this issue. We may need to resolve it
  // without depending on the iframe. With multi-canvas enabled a modal may be
  // visible in each frame. Currently we use the desktop frame so behavior
  // should be consistent without the multi-canvas feature.
  const targetFrameDocument = useTargetFrameDocument("desktop");
  const modalMountingPoint =
    targetFrameDocument?.querySelector("#alchemy-modal-mount-point") ?? null;
  useReploHotkeys({ delete: handleDelete }, modalMountingPoint);

  // Note (Evan, 2024-07-16): We should only allow undo/redo when no global
  // modals are open.
  const shouldAllowUndoRedo = Object.values(modal.modals).length === 0;

  useReploHotkeys({
    delete: selectedIds.length > 1 ? handleGroupDelete : handleDelete,
    grabCanvas: [
      (e) => {
        if (selectCanvasInteractionMode(store.getState()) === "locked") {
          return;
        }
        const { type } = e;
        dispatch(handleTransformWillChange());
        dispatch(
          setCanvasInteractionMode(type === "keydown" ? "readyToGrab" : "edit"),
        );
      },
      {
        filter: (event: KeyboardEvent) => !event.repeat,
        keyup: true,
      },
    ],
    undo: [
      () => {
        if (hasPreviousOperation) {
          dispatch(undoOperation());
        }
      },
      {
        filter: () => shouldAllowUndoRedo,
      },
    ],
    redo: [
      () => {
        if (hasNextOperation) {
          dispatch(redoOperation());
        }
      },
      {
        filter: () => shouldAllowUndoRedo,
      },
    ],
    duplicate: handleDuplicate,
    debug: handleDebug,
    groupIntoContainer: handleGroupIntoContainer,
    // Note (Fran 2022-09-07): To add a new hotkey we need to create a second
    // one with the same function.
    groupIntoContainerTwo: handleGroupIntoContainer,
    zoomIn: () => {
      handleCanvasZoomIn();
    },
    saveComponentTemplate: () => {
      if (draftComponentId) {
        modal.openModal({
          type: "saveTemplateModal",
          props: { initialName: draftComponentName },
        });
      }
    },
    openHotkeysModal: () =>
      modal.openModal({
        type: "hotkeysModal",
      }),
    mockSave: () => {
      successToast(`Changes Saved Automatically ✌️`, "");
    },
    zoomOut: () => {
      handleCanvasZoomOut();
    },
    resetZoom: () => {
      handleCanvasZoom(INITIAL_CANVAS_SCALE);
    },
    deselectCurrentComponent: () => {
      // Note (Evan, 2024-07-19): Don't do anything in AI generation mode
      if (editorMode === EditorMode.aiGeneration) {
        return;
      }
      // if it's preview, get out of preview instead
      if (editorMode === EditorMode.preview) {
        handleTogglePreviewMode();
      } else {
        // NOTE (Sebas, 2024-06-17): If there are modals open, we should not
        // deselect the current component, as it will close the modals and
        // deselect the component at the same time looking weird.
        if (!areModalsOpen) {
          setDraftElement({ componentId: null });
        }
      }
    },
    preventSelectingAllTextsOnEditor: (e) => {
      e.stopPropagation();
    },
    togglePreviewMode: handleTogglePreviewMode,
    copyStyles: handleCopyStyles,
    pasteStyles: handlePasteStyles,
    toggleAIMenu: handleToggleAIMenu,
    setDesignPanel: () => dispatch(setRightBarActiveTab("design")),
    setConfigPanel: () => dispatch(setRightBarActiveTab("custom")),
    setInteractionsPanel: () => dispatch(setRightBarActiveTab("interactions")),
    setLayersPanel: () => dispatch(setLeftBarActiveTab("tree")),
    setElementsPanel: () => dispatch(setLeftBarActiveTab("elements")),
    setComponentsPanel: () => dispatch(setLeftBarActiveTab("components")),
    toggleCodeEditor: handleToggleCodeEditor,
    toggleVersionHistory: handleToggleVersionHistory,
    openProjectSettings: handleOpenProjectSettings,
    openPageSettings: handleOpenPageSettings,
    moveUpInTheTree: handleMoveUpInTheTree,
    moveDownInTheTree: handleMoveDownInTheTree,
    exportToSection: handleOpenExportToSectionModal,
    setWidthToFillAvailable: handleSetWidthToFillAvailable,
    setWidthToWrapContent: handleSetWidthToWrapContent,
    setHeightToFillAvailable: handleSetHeightToFillAvailable,
    setHeightToWrapContent: handleSetHeightToWrapContent,
    openMarketplace: handleOpenMarketplace,
  });

  return null;
};

export default GlobalHotkeysListener;
