import type { Project } from "replo-runtime/shared/types";
import type { ReploElement } from "schemas/generated/element";

import pickBy from "lodash-es/pickBy";
import { generatePath } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";

export type EditorRoute = {
  projectId: string;
  elementId?: string;
  productId?: string;
};

export const routes = {
  dashboard: "/home",
  editor: {
    project: "/editor/:projectId",
    element: "/editor/:projectId/:elementId",
    templateDefaults: "/editor/:projectId/template-defaults",
    experiments: {
      list: "/editor/:projectId/experiments",
      detail: "/editor/:projectId/experiments/:experimentId",
      edit: "/editor/:projectId/experiments/:experimentId/edit",
    },
    product: "/editor/:projectId/:elementId/:productId",
    settings: "/editor/:projectId/project-settings",
    integrations: "/editor/:projectId/integrations",
  },
  workspace: {
    projects: "/workspace/:workspaceId/projects",
    integrations: {
      list: "/workspace/:workspaceId/integrations",
      shopify: "/workspace/:workspaceId/integrations/shopify",
    },
    billing: "/workspace/:workspaceId/billing",
  },
  marketplaceModal: "marketplace",
  marketplaceModalCategory: "marketplace/category/:categorySlug",
  marketplaceModalCollection: "marketplace/collection/:collectionId",
  marketplaceModalDetails: "marketplace/details/:templateId",
  templateSharedDetails: "details/:templateId",
  analytics: {
    dashboard: "/workspace/:workspaceId/analytics",
    overview: "/workspace/:workspaceId/analytics/overview",
    pageDetails: "/workspace/:workspaceId/analytics/:host/:pageUrlPath",
  },
};

/**
 * Generate the pathname for the editor route based on the provided parameters.
 *
 * @example
 * const pathname = generateEditorPathname('settings', { projectId: '123' });
 * '/editor/123/settings'
 *
 * @example
 * const pathname = generateEditorPathname('', { projectId: '123', elementId: '456' });
 * '/editor/123/456'
 */
export function generateEditorPathname(
  to: string,
  params: {
    projectId: Project["id"];
    elementId?: ReploElement["id"];
    productId?: string;
    [key: string]: string | undefined;
  },
  queryParams?: Record<string, string>,
) {
  let basePath = routes.editor.project;

  if (params.elementId) {
    basePath = routes.editor.element;
  } else if (params.productId) {
    basePath = routes.editor.product;
  }

  const pathWithoutSearchParams = generatePath(
    `${basePath}/${to}`,
    pickBy(params),
  );

  if (!isEmpty(queryParams)) {
    const urlSearchParams = new URLSearchParams(queryParams);
    return `${pathWithoutSearchParams}?${urlSearchParams.toString()}`;
  }

  return pathWithoutSearchParams;
}
