import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { mapAndConvertComponentStylePropsToStyle } from "../../../shared/utils/breakpoints";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    url: {
      type: "string",
      name: "Vimeo URL",
      description:
        'The url of the Vimeo video to display. For example: "https://vimeo.com/275929094".',
      defaultValue: "",
    },
    hideControls: {
      type: "boolean",
      name: "Hide Controls",
      defaultValue: false,
      description:
        "If enabled, no play/pause or fullscreen controls will be shown on the video.",
    },
    autoPlay: {
      name: "Autoplay",
      type: "boolean",
      description: "If enabled, the video will autoplay on mute when loaded.",
      defaultValue: false,
    },
    loop: {
      name: "Autoloop",
      type: "boolean",
      defaultValue: false,
      description: "If enabled, the video will loop after it ends.",
    },
    defaultMuted: {
      name: "Default Muted",
      type: "boolean",
      defaultValue: false,
      description: "If enabled, the video will play on mute by default.",
      isEnabled: ({ component }) => !component.props.autoPlay,
      disabledDescription: "Videos that autoplay must be muted.",
      preserveValueWhenDisabled: true,
    },
  };
}

export const customProperties = {
  height: "--rhe",
  aspectRatio: "--rar",
};

export const styleElements = {
  root: {
    overrideStyles({ styleProps }) {
      return mapAndConvertComponentStylePropsToStyle(styleProps, (styles) => {
        return {
          [customProperties.height]: styles.__hasHeight ? "100%" : "auto",
          [customProperties.aspectRatio]: styles.__hasHeight ? "auto" : "16/9",
        };
      });
    },
  },
} satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
