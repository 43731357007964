import type {
  CompareRangePredefinedPeriod,
  MainRangePredefinedTimeframe,
} from "@/features/analytics/time";
import type { DateRange } from "react-day-picker";
import type { FilterName } from "schemas/analyticsRead";

import * as React from "react";

import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import { Input } from "@editor/components/common/designSystem/Input";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { isCustomDateRange } from "@editor/utils/analytics";

import {
  DEFAULT_FILTERS,
  DEFAULT_URL_HOST,
} from "@/features/analytics/constants";
import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import AppliedFiltersTags from "@/features/analytics/moreFilters/AppliedFiltersTags";
import BarPopover from "@/features/analytics/moreFilters/BarPopover";
import TriggerBarPopoverCombobox from "@/features/analytics/moreFilters/TriggerBarPopoverCombobox";
import AnalyticsHostPagesCombobox from "@/features/analytics/selects/AnalyticsHostPagesCombobox";
import AnalyticsTimeRangeCombobox from "@/features/analytics/selects/AnalyticsTimeRangeCombobox";
import AnalyticsUrlHostCombobox from "@/features/analytics/selects/AnalyticsUrlHostCombobox";
import {
  calculateCompareRangePredefined,
  calculateCustomRange,
  calculateQueryRangesForCustomTimeframe,
  calculateQueryRangesForPredefinedTimeframe,
  COMPARE_PREVIOUS_PERIOD,
  COMPARE_RANGE_PREDEFINED_OPTIONS,
  DEFAULT_COMPARE_RANGE_PERIOD,
  DEFAULT_MAIN_RANGE_TIMEFRAME,
  MAIN_RANGE_PREDEFINED_OPTIONS,
} from "@/features/analytics/time";
import { BsSearch } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { ConditionOperatorEnum } from "schemas/analyticsRead";

type AnalyticsFiltersProps = {
  resetPagination?: () => void;
  enableSearch?: boolean;
};

const AnalyticsFilters: React.FC<AnalyticsFiltersProps> = ({
  resetPagination,
  enableSearch = false,
}) => {
  const { query, setQuery, workspaceUrlHosts } = useAnalyticsQueryContext();
  const analytics = useLogAnalytics();
  const shopifyUrlOptionValues = React.useMemo(
    () => workspaceUrlHosts.data.map((option) => option.value),
    [workspaceUrlHosts.data],
  );

  const handleUrlHostChange = (value: string) => {
    setQuery({
      ...query,
      urlHosts: value === DEFAULT_URL_HOST ? shopifyUrlOptionValues : [value],
    });
  };

  const [selectedMainTimeframe, setSelectedMainTimeframe] = React.useState<
    MainRangePredefinedTimeframe | string
  >(DEFAULT_MAIN_RANGE_TIMEFRAME);

  const [selectedComparePeriod, setSelectedComparePeriod] = React.useState<
    CompareRangePredefinedPeriod | string
  >(DEFAULT_COMPARE_RANGE_PERIOD);

  const [isMainRangePickerOpen, setIsMainRangePickerOpen] =
    React.useState<boolean>(false);
  const [isCompareRangePickerOpen, setIsCompareRangePickerOpen] =
    React.useState<boolean>(false);
  const [isHostPagePickerOpen, setIsHostPagePickerOpen] =
    React.useState<boolean>(false);

  const { pageUrlPath = "" } = useParams<{ pageUrlPath: string }>();

  const [selectedPageUrlPath, setSelectedPageUrlPath] = React.useState<string>(
    decodeURIComponent(pageUrlPath),
  );

  /**
   * This function only ever updates the compareAt range.
   * Either the user selects to compare with a predefined period
   * (e.g. previous-period), or the user selects to compare with a
   * custom date period.
   *
   * @author Max 2024-09-17
   */
  const handleCompareRangeChange = (
    selectedComparePeriodValue: CompareRangePredefinedPeriod | DateRange,
  ) => {
    const { range: compareAtRange, selectedPeriod } = isCustomDateRange(
      selectedComparePeriodValue,
    )
      ? calculateCustomRange(
          selectedComparePeriodValue.from!,
          selectedComparePeriodValue.to!,
        )
      : calculateCompareRangePredefined(
          selectedComparePeriodValue as CompareRangePredefinedPeriod,
          query.ranges.mainRange,
        );

    setSelectedComparePeriod(selectedPeriod);
    resetPagination?.();
    setQuery({
      ...query,
      ranges: {
        ...query.ranges,
        compareAtRanges: [compareAtRange],
      },
      offset: 0,
    });
    analytics("analytics.compare", {
      compareDetail:
        selectedPeriod === COMPARE_PREVIOUS_PERIOD
          ? "previous-period"
          : "custom",
      location:
        pageUrlPath === "" ? "analytics_overview" : "analytics_deep_dive",
    });
  };

  /**
   * Unlike handleCompareRangeChange(), this function can update
   * both ranges: it always updates the mainRange, AND if the compare period
   * is set to 'previous-period', it also updates the compareAt range (by setting
   * it to be the previous continuous range).
   *
   * @author Max 2024-09-17
   */
  const handleMainRangeChange = (
    selectedTimeframeValue: MainRangePredefinedTimeframe | DateRange,
  ) => {
    const shouldUpdateCompareRange =
      selectedComparePeriod === COMPARE_PREVIOUS_PERIOD;

    const { updatedRanges, selectedTimeframe } = isCustomDateRange(
      selectedTimeframeValue,
    )
      ? calculateQueryRangesForCustomTimeframe(
          selectedTimeframeValue.from!,
          selectedTimeframeValue.to!,
        )
      : calculateQueryRangesForPredefinedTimeframe(
          selectedTimeframeValue as MainRangePredefinedTimeframe,
        );

    setSelectedMainTimeframe(selectedTimeframe);
    resetPagination?.();
    setQuery({
      ...query,
      ranges: {
        mainRange: updatedRanges.mainRange,
        compareAtRanges: shouldUpdateCompareRange
          ? updatedRanges.compareAtRanges
          : query.ranges.compareAtRanges,
      },
      offset: 0,
    });
    analytics("analytics.time.filter", {
      range:
        typeof selectedTimeframeValue === "string"
          ? selectedTimeframeValue
          : "custom",
      location:
        pageUrlPath === "" ? "analytics_overview" : "analytics_deep_dive",
    });
  };

  /**
   * NOTE (Max, 2024-09-05): If the value is empty, it means the user doens't
   * want to apply any filter, so we apply the default filtes (e.g. /checkouts/ etc..)
   */
  const handleFilterChange = (filterName: FilterName, value: string) => {
    resetPagination?.();
    setQuery({
      ...query,
      filters: {
        ...query.filters,
        [filterName]: value
          ? [
              {
                // NOTE (Max, 2024-09-05): For now we're hard-coding to CONTAINS - in the future
                // we'll need some more complex logic to account for other operators "OR" / "AND"
                // "NOT" ...
                operator: ConditionOperatorEnum.CONTAINS,
                value,
              },
            ]
          : DEFAULT_FILTERS[filterName],
      },
      offset: 0,
    });
    analytics("analytics.search", {});
  };

  const [searchUrlPathValue, setSearchUrlPathValue] =
    React.useState<string>("");

  const searchUrlPathInputProps = useOverridableInput({
    value: searchUrlPathValue,
    onValueChange: (value: string) => {
      handleFilterChange("urlPath", value);
      setSearchUrlPathValue(value);
    },
  });

  const [isMoreFiltersBarOpen, setIsMoreFiltersBarOpen] =
    React.useState<boolean>(false);
  const [selectedUrlParamOptionValue, setSelectedUrlParamOptionValue] =
    React.useState<string>("");

  const handleUrlParamOptionClick = (value: string) => {
    setSelectedUrlParamOptionValue(value);
    setIsMoreFiltersBarOpen(!isMoreFiltersBarOpen);
  };

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-9">
        <div className="flex flex-row gap-2 mr-2">
          <AnalyticsTimeRangeCombobox<MainRangePredefinedTimeframe | DateRange>
            currentQueryRange={query.ranges}
            options={MAIN_RANGE_PREDEFINED_OPTIONS}
            value={selectedMainTimeframe}
            handleRangeChange={handleMainRangeChange}
            open={isMainRangePickerOpen}
            onOpenChange={setIsMainRangePickerOpen}
            isCompare={false}
          />
          <AnalyticsTimeRangeCombobox<CompareRangePredefinedPeriod | DateRange>
            currentQueryRange={query.ranges}
            options={COMPARE_RANGE_PREDEFINED_OPTIONS}
            placeholderPrefix="Compare to: "
            value={selectedComparePeriod}
            handleRangeChange={handleCompareRangeChange}
            open={isCompareRangePickerOpen}
            onOpenChange={setIsCompareRangePickerOpen}
            isCompare={true}
          />
          <AnalyticsUrlHostCombobox
            onChange={handleUrlHostChange}
            disabled={selectedPageUrlPath !== ""}
          />
          {selectedPageUrlPath !== "" && (
            <AnalyticsHostPagesCombobox
              onChange={setSelectedPageUrlPath}
              value={selectedPageUrlPath}
              open={isHostPagePickerOpen}
              onOpenChange={setIsHostPagePickerOpen}
            />
          )}
          <TriggerBarPopoverCombobox
            handleUrlParamOptionClick={handleUrlParamOptionClick}
          />
        </div>
        <div className="mt-2 flex flex-wrap gap-2">
          <AppliedFiltersTags />
        </div>
      </div>
      {enableSearch && (
        <div className="col-span-3">
          <Input
            placeholder="Search path..."
            size="base"
            startEnhancer={() => (
              <BsSearch className="text-xs text-slate-400" />
            )}
            {...searchUrlPathInputProps}
          />
        </div>
      )}

      <BarPopover
        isOpen={isMoreFiltersBarOpen}
        onOpenChange={setIsMoreFiltersBarOpen}
        selectedUrlParamOptionValue={selectedUrlParamOptionValue}
      />
    </div>
  );
};

export default AnalyticsFilters;
