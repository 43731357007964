import type {
  AnalyticsEvent,
  AnalyticsEventParamsOf,
  GlobalEventProperties,
} from "@editor/infra/analytics";

import * as React from "react";

import { analytics } from "@editor/infra/analytics";
import {
  selectDraftElementId,
  selectDraftElementName,
  selectDraftElementType,
  selectDraftElementUseSectionSettings,
  selectDraftElementVersion,
} from "@editor/reducers/core-reducer";
import { useEditorStore } from "@editor/store";
import useCurrentProjectId from "@hooks/useCurrentProjectId";
import useGetStoreNameAndUrl from "@hooks/useGetStoreNameAndUrl";

export const useLogAnalytics = () => {
  const { storeUrl } = useGetStoreNameAndUrl();
  const store = useEditorStore();
  const projectId = useCurrentProjectId();

  const logEvent = React.useCallback(
    function logEvent<EventName extends AnalyticsEvent["type"]>(
      eventName: EventName,
      eventProperties: AnalyticsEventParamsOf<EventName>,
    ) {
      const state = store.getState();
      const elementId = selectDraftElementId(state);
      const elementName = selectDraftElementName(state);
      const elementType = selectDraftElementType(state);
      const elementVersion = selectDraftElementVersion(state);
      const useSectionSettings = selectDraftElementUseSectionSettings(state);

      const globalProperties: GlobalEventProperties = {
        storeUrl,
        storeId: projectId ?? "",
        elementId,
        elementName,
        elementType,
        elementVersion,
        elementSectionSettingsEnabled: Boolean(useSectionSettings),
      };
      // Note (Noah, 2024-01-07): I'm not sure how to get TS to recognize that
      // this is the correct type - the second param here definitely expects
      // AnalyticsEventParamsOf<EventName> & GlobalEventProperties, which is
      // what we're passing, but I think the types are not written in the correct
      // way or something
      // @ts-expect-error
      analytics.logEvent(eventName, {
        ...eventProperties,
        ...globalProperties,
      });
    },
    [storeUrl, projectId, store],
  );
  return logEvent;
};
