import type {
  BackgroundControlType,
  ControlType,
  LayoutControlType,
  ModifierType,
  ModifierTypeToControlType,
  PositionControlType,
  SizeControlType,
  TextControlType,
} from "schemas/modifiers";

const sizeStyleControlTypesInOrder: ControlType<SizeControlType> = {
  width: {
    shouldRenderByDefault: true,
  },
  height: {
    shouldRenderByDefault: true,
  },
  minWidth: {
    shouldRenderByDefault: false,
  },
  minHeight: {
    shouldRenderByDefault: false,
  },
  maxWidth: {
    shouldRenderByDefault: false,
  },
  maxHeight: {
    shouldRenderByDefault: false,
  },
  alignSelf: {
    shouldRenderByDefault: false,
  },
};

const positionStyleControlTypesInOrder: ControlType<PositionControlType> = {
  position: {
    shouldRenderByDefault: false,
  },
  zIndex: {
    shouldRenderByDefault: false,
  },
};

const layoutStyleControlTypesInOrder: ControlType<LayoutControlType> = {
  layoutBox: {
    shouldRenderByDefault: true,
  },
  order: {
    shouldRenderByDefault: false,
  },
  overflowX: {
    shouldRenderByDefault: false,
  },
  overflowY: { shouldRenderByDefault: false },
};

const textStyleControlTypesInOrder: ControlType<TextControlType> = {
  textAlign: {
    shouldRenderByDefault: true,
  },
  fontFamily: {
    shouldRenderByDefault: true,
  },
  fontWeight: {
    shouldRenderByDefault: true,
  },
  fontSize: {
    shouldRenderByDefault: true,
  },
  lineHeight: {
    shouldRenderByDefault: true,
  },
  color: {
    shouldRenderByDefault: true,
  },
  letterSpacing: {
    shouldRenderByDefault: true,
  },
  textDecoration: {
    shouldRenderByDefault: false,
  },
  htmlTag: {
    shouldRenderByDefault: true,
  },
  textTransform: {
    shouldRenderByDefault: false,
  },
  textOutline: {
    shouldRenderByDefault: false,
  },
  textShadow: {
    shouldRenderByDefault: false,
  },
};

const backgroundStyleControlTypesInOrder: ControlType<BackgroundControlType> = {
  backgroundImage: {
    shouldRenderByDefault: false,
  },
  backgroundColor: {
    shouldRenderByDefault: false,
  },
};

// const effectsStyleControlTypesInOrder: ControlType<EffectsControlType> = {
//   transform: {
//     shouldRenderByDefault: false,
//   },
//   transformOrigin: {
//     shouldRenderByDefault: false,
//   },
//   cursor: {
//     shouldRenderByDefault: false,
//   },
//   opacity: {
//     shouldRenderByDefault: false,
//   },
//   boxShadow: {
//     shouldRenderByDefault: false,
//   },
// };

export const ModifierTypeToControls: Record<
  ModifierType,
  Record<string, { shouldRenderByDefault: boolean }>
> = {
  size: sizeStyleControlTypesInOrder,
  position: positionStyleControlTypesInOrder,
  layout: layoutStyleControlTypesInOrder,
  text: textStyleControlTypesInOrder,
  background: backgroundStyleControlTypesInOrder,
  //   effects: effectsStyleControlTypesInOrder,
};

export const getDefaultControls = <T extends ModifierType>(
  controlTypesInOrder: (typeof ModifierTypeToControls)[T],
) => {
  const defaultControls = new Set<ModifierTypeToControlType[T]>();
  for (const control in controlTypesInOrder) {
    if (controlTypesInOrder[control]?.shouldRenderByDefault) {
      defaultControls.add(control as unknown as ModifierTypeToControlType[T]);
    }
  }
  return defaultControls;
};
