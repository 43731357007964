import type { Context } from "../../store/AlchemyVariable";

import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../runtime-context";

export function withLiquidAlternate<Props extends { context: Context }>(
  OriginalComponent: React.FC<Props>,
  LiquidComponent: React.FC<Props>,
) {
  const WithLiquidAlternateComponent: React.FC<Props> = (props) => {
    const { context } = props;
    const { isPublishing } = useRuntimeContext(RenderEnvironmentContext);

    if (
      isPublishing &&
      context.isInsideProductComponent &&
      !context.overrideProductLiquidOff
    ) {
      return <LiquidComponent {...props} />;
    }
    return <OriginalComponent {...props} />;
  };
  WithLiquidAlternateComponent.displayName =
    OriginalComponent.displayName || OriginalComponent.name;
  return WithLiquidAlternateComponent;
}
