import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { ReploComponentType } from "schemas/component";
import type { CanvasData, CanvasState } from "./canvas-types";

import { MEDIA_MAX_SIZE } from "replo-utils/lib/misc/breakpoints";

/**
 * The selector to query for using `closest()` when hovering over a DOM node to
 * find the closest component to the point.
 *
 * Note (Noah, 2023-08-22, REPL-8287): We exclude data-replo-editor-liquid here
 * because it's possible they could have an embedded ShopifySection component
 * (or liquid which otherwise renders content that Replo created) and in that
 * case, if there's Replo content inside the embedded component, we don't want
 * to consider it for candidate component selection since it's controlled by
 * another Replo Element.
 */
export const REPLO_COMPONENT_SELECTOR =
  "[data-rid]:not([data-replo-editor-liquid] [data-rid])";

export const CANVAS_SET = new Set<EditorCanvas>([
  "desktop",
  "tablet",
  "mobile",
]);

export const CANVAS_DATA = {
  desktop: {
    canvasName: "desktop",
    defaultFrameWidth: 1440 as const,
    range: [MEDIA_MAX_SIZE.md + 1, 2400],
  },
  tablet: {
    canvasName: "tablet",
    defaultFrameWidth: 810 as const,
    range: [MEDIA_MAX_SIZE.sm + 1, MEDIA_MAX_SIZE.md],
  },
  mobile: {
    canvasName: "mobile",
    defaultFrameWidth: 393 as const,
    range: [320, MEDIA_MAX_SIZE.sm],
  },
} satisfies Record<EditorCanvas, CanvasData>;

export const PRESETS = {
  mobile: [
    { label: "iPhone SE", value: 375 },
    { label: "iPhone 15", value: 393 },
    { label: "iPhone 15 Pro Max", value: 430 },
    { label: "Pixel 8", value: 412 },
    { label: "Pixel 8 Pro", value: 448 },
    { label: "Samsung Galaxy S22", value: 360 },
    { label: "Samsung Galaxy S22+", value: 384 },
  ],
  tablet: [
    { label: "iPad Mini", value: 768 },
    { label: "iPad", value: 810 },
    { label: "Surface Pro 7", value: 912 },
    { label: "iPad Pro", value: 1024 },
  ],
  desktop: [
    { label: "Macbook Air", value: 1280 },
    { label: "Chromebook", value: 1366 },
    { label: "Macbook Pro 14″", value: 1512 },
    { label: "Macbook Pro 16″", value: 1728 },
    { label: "Default", value: 1440 },
  ],
} satisfies Record<EditorCanvas, { label: string; value: number }[]>;

// TODO (Gabe 2023-06-08): Can we do away with this too? (editorZIndexes already
// removed)
export const CANVAS_Z_INDICES = {
  childBoundingBoxes: 0,
  draftBox: 20,
  draggableComponentBox: 35,
  // Note (Noah, 2022-02-25): Drag resizers must go hover candidate
  // box to allow adjusting padding of a container whose edges intersect
  // with its children's edges
  dragResizers: 40,
};

export const DEFAULT_UNIT_MAP: Partial<Record<ReploComponentType, string>> = {
  text: "px",
  image: "px",
  icon: "px",
  player__fullScreenIcon: "px",
  player__muteIcon: "px",
  player__playIcon: "px",
};

export const DRAGGABLE_BOUNDS = {
  left: 0,
  top: 0,
};

// Offset limit for snapping components from outside of the frame.
export const OFFSET_LIMIT = 100;

// min/max scale values for zoom/pan controls
export const MAX_SCALE = 1.8;
export const MIN_SCALE = 0.1;

export const INITIAL_CANVAS_SCALE = 0.7;
export const INITIAL_CANVAS_HEIGHT = 1000;
export const INITIAL_CANVAS_WILL_CHANGE = {
  status: "auto",
  timeoutId: null,
} satisfies CanvasState["willChange"];

export const CANVAS_VERTICAL_SCROLL_TOLERANCE = 110;
export const CANVAS_HORIZONTAL_SCROLL_TOLERANCE = 60;
export const CANVAS_FRAME_GAP = 40;
export const DEFAULT_DELTA_Y = 50;
export const CANVAS_NAVBAR_HEIGHT = 48;
export const CANVAS_NAVBAR_VERTICAL_SPACING = 24;
export const DEFAULT_FRAME_HEIGHT = 2000;
export const HEADER_HEIGHT = 60;
