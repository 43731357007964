import type {
  ReploShopifyOption,
  ReploShopifyOptionKey,
  ReploShopifyProduct,
  ReploShopifyVariant,
  SelectedOptionValuesMapping,
  ShopifySellingPlan,
  Swatch,
  VariantMetafieldMapping,
} from "replo-runtime/shared/types";

import { mapEntityIdMetafieldsMappingToMappingWithoutType } from "replo-runtime/shared/mappers/product";
import {
  getOptionSwatchValueMapping,
  getVariantSwatchValueMapping,
} from "replo-runtime/shared/utils/dynamic-data";
import { filterNulls } from "replo-utils/lib/array";
import { hasOwnProperty, isNullish } from "replo-utils/lib/misc";

/**
 * Note (Noah, 2023-03-12): This sentinel value is used to indicate that the
 * user has specifically selected one-time purchase. If that's the case, then
 * the dynamic data value for selected selling plan id will always resolve to
 * null. If the selectedSellingPlanId is not set and an action references it
 * with dynamic data, it will resolved to the first selling plan (instead of no
 * selling plan).
 *
 * This is because we need to support the case where you have two
 * tabs, one with OPT and one with SNS, where the SNS tab has a selling plan
 * dropdown - we want that selling plan dropdown to default to the first selling
 * plan, and for the buttons inside that tab to resolve the first selling plan
 * with dynamic data. However, the other case we want to support is just having
 * one selling plan dropdown, not inside tabs - in this case, the selected selling
 * plan dynamic data value needs to resolve to null if the user specifically selects
 * OTP from the dropdown.
 *
 * Note: we could do this with null vs undefined, but a sentinel value is harder
 * to screw up.
 */
export type SelectedSellingPlanIdOrOneTimePurchase =
  | number
  | { __reploOneTimePurchase: true };

export type ProductOptionWithValues = { label: string; values?: string[] };

export const isOneTimePurchase = (
  sellingPlanId: SelectedSellingPlanIdOrOneTimePurchase | null,
): sellingPlanId is { __reploOneTimePurchase: true } => {
  return sellingPlanId
    ? hasOwnProperty(sellingPlanId, "__reploOneTimePurchase")
    : false;
};

/**
 * Selects the variant from a product that best matches the
 * given option values and includes specified must-have options.
 *
 *
 * @param product The state's product.
 * @param selectedOptionValues The new set of selected options (key-value pairs) after the user
 * clicked on a new option (i.e. before knowing if there's a variant for this specific
 * set of options. We'll find the best-matching variant for this set of options in this
 * function).
 * @param requiredOptionValues options key-value pairs that MUST be present
 * in the variant we return.
 * @returns the variant that best matches the given selectedOptionValues with mandatory
 * requiredOptionValues. For example, if:
 * selectedOptionValues = {'height': 5, 'width': 2, 'color': 'red'} and
 * requiredOptionValues = {'height': 5, 'width': 2, 'color': 'red'}
 * Then this function will only return a variant that contains these 3 options.
 * If it can't find any, it returns null.
 *
 * Now, if:
 * requiredOptionValues = {'height': 5}
 * then this function could return a variant that e.g. only has the height option
 * (but potentially not the other ones, as they're not specified in requiredOptionValues)
 * if no better match was found.
 *
 * @author Max 2024-08-06
 */
export const selectedVariantFromOptionValues = (
  product: ReploShopifyProduct,
  selectedOptionValues: Record<string, string | null>,
  requiredOptionValues: Record<string, string | null>,
) => {
  function isVariantOptionEqualToSelectedOption(
    variant: ReploShopifyVariant,
    option: ReploShopifyOption,
    index: number,
  ) {
    // TODO (Ovishek, 2022-05-04, REPL-1961): also converting them to a string before checking equality
    // b/c sometimes the value of variant option${index} can be an array (which we need to fix on backend) with a single element, and then
    // this check will fail. These cases should equal imo,
    // ['Dapple Grey (Cream Sole)'] === ['Dapple Grey (Cream Sole)']
    // ['Dapple Grey (Cream Sole)'] === 'Dapple Grey (Cream Sole)'
    // for ref see this issue - https://linear.app/replo/issue/REPL-1961/option-list-not-pulling-in-the-right-values-and-not-previewing-right
    return (
      String(variant?.[`option${index + 1}` as ReploShopifyOptionKey]) ===
      String(selectedOptionValues[option.name])
    );
  }
  let variants = product.variants;

  // NOTE (Max, 2024-08-06): Only keep variants that contain options within requiredOptionValues
  if (requiredOptionValues) {
    variants = variants.filter((variant) => {
      return product.options.every((option, index) => {
        // NOTE (Max, 2024-08-06): We only care to check for a product.option if it's in
        // requiredOptionValues.
        if (!Object.keys(requiredOptionValues).includes(option.name)) {
          return true;
        }

        return isVariantOptionEqualToSelectedOption(variant, option, index);
      });
    });
  }

  // NOTE (Max, 2024-08-06): Sort the variants by the number of matched options in descending order
  const sortedVariants = variants
    .map((variant) => {
      // NOTE (Max, 2024-08-06): Count how many product.options match for this variant
      const matchCount = product.options.reduce((count, option, index) => {
        if (
          !selectedOptionValues[option.name] ||
          isVariantOptionEqualToSelectedOption(variant, option, index)
        ) {
          return count + 1;
        }

        return count;
      }, 0);

      return { variant, matchCount };
    })
    .sort((a, b) => b.matchCount - a.matchCount);

  return sortedVariants[0]?.variant ?? null;
};

export const selectedVariantFromStoreProductOptions = (
  options: ReploShopifyOption[],
  variants: ReploShopifyVariant[],
  selectedOptionValues: Record<ReploShopifyOptionKey, string | null>,
) => {
  return variants.find((variant) => {
    return options.every((option, index) => {
      return (
        !selectedOptionValues[option.key] ||
        String(variant?.[`option${index + 1}` as ReploShopifyOptionKey]) ===
          String(selectedOptionValues[option.key])
      );
    });
  });
};

/**
 * Given a product, a variant, and a sellingPlanId, this function returns an array of sellingPlans
 * and a working selectedSellingPlan. The trick here is that we need to return a sellingPlan that
 * applies to the variant,and it is possible that when this function is called the `sellingPlanId`
 * does not work with the selected variant. If it does not work, we see if there is an applicable
 * sellingPlan with the same name as the other plan, or we just grab the first plan.
 */
export const resolveProductSellingPlans = (
  product: ReploShopifyProduct | null,
  variant?: ReploShopifyVariant,
  selectedSellingPlanId?: SelectedSellingPlanIdOrOneTimePurchase | null,
) => {
  if (!product) {
    return {
      selectedSellingPlan: null,
      sellingPlans: [],
    };
  }
  // NOTE (Matt 2024-03-12): If there is no variant being passed, we want to
  // show all selling plan options.
  const applicableSellingPlanGroupIds =
    variant?.sellingPlanGroupIds ??
    product.sellingPlanGroups.map(({ id }) => id) ??
    [];

  // NOTE (Gabe 2024-04-09): Just because a selling plan group is applicable to
  // a variant does not mean all selling plans in that group are applicable. We
  // must also check applicably at the selling plan level.

  const applicableSellingPlanIds =
    variant?.sellingPlanIds ??
    product.sellingPlanGroups.flatMap(({ sellingPlans }) =>
      sellingPlans.map(({ id }) => id),
    ) ??
    [];

  const sellingPlans = product.sellingPlanGroups
    .filter(({ id }) => applicableSellingPlanGroupIds.includes(id))
    .flatMap((sellingPlanGroup) =>
      sellingPlanGroup.sellingPlans.filter(({ id }) =>
        applicableSellingPlanIds.includes(id),
      ),
    )
    // Note (Noah, 2023-03-13): Important to sort here so that the order of the
    // selling plans when determining which one to use as the "first" one is consistent
    // with the selling plan list component. Otherwise we'll get incorrect hydration
    // on the published page, since this component will think one selling plan is selected
    // but the prerendered page will think a different one is selected
    .sort((sellingPlanA, sellingPlanB) => {
      if (Number(sellingPlanA.id) < Number(sellingPlanB.id)) {
        return -1;
      }
      return 1;
    });

  let selectedSellingPlan: ShopifySellingPlan | null = null;
  if (isNullish(selectedSellingPlanId)) {
    selectedSellingPlan = sellingPlans?.[0] ?? null;
  } else if (isOneTimePurchase(selectedSellingPlanId)) {
    selectedSellingPlan = null;
  } else {
    selectedSellingPlan =
      sellingPlans?.find(
        (plan) => Number(plan.id) === Number(selectedSellingPlanId),
      ) ?? null;
  }
  // NOTE (Matt 2024-03-04): Now that variants can have different selling plans,
  // it is possible to get to this point where there was a previously selected
  // selling plan that does not exist as an option in the sellingPlans array.
  // In this instance, we see if there is an applicable selling plan with the
  // same name as the last selling plan (as is common with apps like Skio), or
  // we just grab the first available selling plan.
  if (
    !selectedSellingPlan &&
    selectedSellingPlanId &&
    !isOneTimePurchase(selectedSellingPlanId)
  ) {
    const oldSellingPlan =
      product?.sellingPlanGroups
        .flatMap(({ sellingPlans }) => sellingPlans)
        .find(({ id }) => Number(id) === Number(selectedSellingPlanId)) ?? null;
    const planWithSameName =
      oldSellingPlan &&
      sellingPlans?.find((plan) => plan.name == oldSellingPlan.name);
    selectedSellingPlan = planWithSameName ?? sellingPlans?.[0] ?? null;
  }

  return {
    selectedSellingPlan,
    sellingPlans,
  };
};

/**
 * Given a product and some additional data, return the fully "enhanced" variant and option
 * objects which are ready to be inserted into the context for dynamic data. This function
 * adds things like all our known dynamic data values for compare-at price percentages, swatch
 * values, metafields, etc.
 */
export const enhanceVariantsAndOptions = (config: {
  product: ReploShopifyProduct | null;
  variantMetafieldValues: VariantMetafieldMapping;
  swatches: Swatch[];
  selectedOptionValues: SelectedOptionValuesMapping | null;
  showOptionsNotSoldTogether: boolean;
}) => {
  const {
    product,
    variantMetafieldValues,
    swatches,
    selectedOptionValues,
    showOptionsNotSoldTogether,
  } = config;
  const variants =
    product?.variants.map((variant) => {
      return {
        ...variant,
        variantMetafields:
          mapEntityIdMetafieldsMappingToMappingWithoutType(
            variantMetafieldValues[variant.id],
          ) ?? {},
        _swatches: swatches
          ? getVariantSwatchValueMapping(
              swatches.filter((swatch) => swatch.data.type === "variant"),
              variant,
            )
          : null,
      };
    }) ?? [];

  const options =
    product?.options.map((option) => ({
      ...option,
      values: filterNulls(
        option.values?.map((value) => {
          const currentOptions = {
            ...selectedOptionValues,
            [option.name]: value.title,
          };
          const variantIfThisOptionWasSelected =
            selectedVariantFromOptionValues(
              product,
              currentOptions,
              currentOptions,
            );

          /**
           * Note (Noah, 2023-01-03, REPL-5807): Products in Shopify are not required to have variants
           * which have EVERY combination of option values (for example, if I have S, M, L sizes and red,
           * blue colors, I might not sell blue in large). In this case, we don't want to show the option
           * values that don't correspond to any variant (i.e. if we have large selected, we don't want to
           * show blue). So if there's no variant which corresponds to this option value, we return null!
           */
          if (!variantIfThisOptionWasSelected && !showOptionsNotSoldTogether) {
            return null;
          }
          if (!variantIfThisOptionWasSelected) {
            return {
              ...value,
              _swatches: swatches
                ? getOptionSwatchValueMapping(
                    swatches.filter((swatch) => swatch.data.type === "option"),
                    product.id,
                    { name: option.name, value: value.title },
                  )
                : null,
            };
          }
          return {
            ...value,
            // Note (Noah, 2022-10-25, REPL-4739): Add prices for the variant that would
            // become selected if you select this option. This makes it so that option
            // lists can display their prices dynamically, even if the prices of the variants
            // that correspond to those options are depended on the other currently selected
            // options.
            price: variantIfThisOptionWasSelected.price,
            priceWithoutSellingPlanDiscount:
              variantIfThisOptionWasSelected.priceWithoutSellingPlanDiscount,
            priveWithSellingPlanDiscountRounded:
              variantIfThisOptionWasSelected.priceWithoutSellingPlanDiscountRounded,
            priceRounded: variantIfThisOptionWasSelected.priceRounded,
            displayPrice: variantIfThisOptionWasSelected.displayPrice,
            displayPriceRounded:
              variantIfThisOptionWasSelected.displayPriceRounded,
            compareAtPrice: variantIfThisOptionWasSelected.compareAtPrice,
            compareAtPriceRounded:
              variantIfThisOptionWasSelected.compareAtPriceRounded,
            compareAtDisplayPrice:
              variantIfThisOptionWasSelected.compareAtDisplayPrice,
            compareAtDisplayPriceRounded:
              variantIfThisOptionWasSelected.compareAtDisplayPriceRounded,
            compareAtPriceDifference:
              variantIfThisOptionWasSelected.compareAtPriceDifference,
            compareAtPriceDifferencePercentage:
              variantIfThisOptionWasSelected.compareAtPriceDifferencePercentage,
            compareAtPriceDifferenceRounded:
              variantIfThisOptionWasSelected.compareAtPriceDifferenceRounded,
            compareAtDisplayPriceDifference:
              variantIfThisOptionWasSelected.compareAtDisplayPriceDifference,
            compareAtDisplayPriceDifferenceRounded:
              variantIfThisOptionWasSelected.compareAtDisplayPriceDifferenceRounded,
            _swatches: swatches
              ? getOptionSwatchValueMapping(
                  swatches.filter((swatch) => swatch.data.type === "option"),
                  product.id,
                  {
                    name: option.name,
                    value: value.title,
                  },
                )
              : null,
          };
        }),
      ),
    })) ?? [];

  return { variants, options };
};
