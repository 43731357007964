import type { UrlFormValues } from "schemas/url";

import * as React from "react";

import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import { useGetCurrentStepResultsData } from "@components/flows/hooks/useGetCurrentStepResultsData";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import { useReploFlowsStepContext } from "@editor/components/flows/context/ReploFlowsStepContext";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepsLayout from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { urlFormSchema } from "schemas/url";

import StepSubtitle from "./components/StepSubtitle";

const ImportFromUrlStep: React.FC = () => {
  const stepResultsData =
    useGetCurrentStepResultsData<"onboarding.user.import-from-url">();
  const { currentStep, submitStep } = useReploFlowsStepContext();

  const initialUrl = stepResultsData?.url ?? "";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      url: initialUrl,
    },
    resolver: zodResolver(urlFormSchema),
  });

  const { submitOrSkipStepCallback: submitStepCallback } =
    useRouterFlowCallbacks();

  const onSubmit = ({ url }: UrlFormValues) => {
    if (currentStep) {
      submitStep(
        currentStep.id,
        currentStep.type,
        {
          url,
        },
        ({ instance, nextStep }) => {
          submitStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          });
        },
      );
    }
  };

  const urlError = errors.url?.message;

  return (
    <OnboardingStepsLayout
      rightPanelContent={<StepImage src="/images/flows/styles.png" />}
    >
      <form
        className="flex flex-col gap-14"
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
      >
        <div>
          <StepTitle>Tell us about your brand (or one you like)</StepTitle>
          <StepSubtitle>
            We’ll pull key details – tone, audience, and products – to help
            generate personalized content and speed up building.
          </StepSubtitle>
        </div>
        <div className="grid grid-cols-1 gap-6">
          <LabeledControl
            label="Pull details from:"
            className="text-default font-medium"
            size="sm"
          >
            <div className="flex flex-col gap-2">
              <Input
                aria-invalid={Boolean(urlError) ? "true" : undefined}
                aria-describedby={
                  Boolean(urlError) ? "error-first-name" : undefined
                }
                autoComplete="off"
                placeholder="https://www.mywebsite.com"
                {...register("url", {
                  required: "First Name is required",
                })}
                type="text"
                size="base"
                validityState={Boolean(urlError) ? "invalid" : "valid"}
                autoFocus
              />
              {urlError && (
                <ErrorMessage id="error-first-name" error={urlError} />
              )}
            </div>
          </LabeledControl>
        </div>
        <FlowActionButtons
          shouldDisableNextButton={false}
          handleSkip={() => {
            onSubmit({ url: "" });
          }}
        />
      </form>
    </OnboardingStepsLayout>
  );
};

export default ImportFromUrlStep;
