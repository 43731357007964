import type {
  SolidOrGradient,
  StoreProduct,
  Swatch,
  SwatchImage,
  SwatchOptionRow as SwatchOptionRowType,
  SwatchValue,
  SwatchVariantRow as SwatchVariantRowType,
} from "replo-runtime/shared/types";

import * as React from "react";

import Badge from "@common/designSystem/Badge";
import {
  arrayMove,
  SortableItem,
  SortableList,
} from "@common/designSystem/SortableList";
import {
  Group,
  GroupHeader,
  GroupHeaderActionButton,
} from "@editor/components/common/designSystem/Group";
import InlineAssetSelector from "@editor/components/common/designSystem/InlineAssetSelector";
import Popover from "@editor/components/common/designSystem/Popover";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useModal } from "@editor/hooks/useModal";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import { getFormattedColorWithoutOpacity } from "@editor/utils/colors";
import { trpc } from "@editor/utils/trpc";
import { ColorSelector } from "@editorModifiers/ColorModifier";

import omit from "lodash-es/omit";
import { MdAdd } from "react-icons/md";
import { RiCloseFill } from "react-icons/ri";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { getProductOptionValues } from "replo-runtime/shared/utils/component";
import { twMerge } from "tailwind-merge";
import { v4 as uuidv4 } from "uuid";

interface SwatchOptionRowWithIndex extends SwatchOptionRowType {
  rowIndex: number;
}

interface SwatchVariantRowWithIndex extends SwatchVariantRowType {
  rowIndex: number;
}

/**
 * React component for rendering a row in any swatch list.
 */
const SwatchRow: React.FC<{
  label: string;
  swatchValue: SwatchValue;
  handleChange: (value: SwatchValue) => void;
  handleRemove: () => void;
  isReadOnly: boolean;
}> = ({ label, swatchValue, handleChange, handleRemove, isReadOnly }) => {
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);

  const onChangeImageList = React.useCallback(
    (imageList: SwatchImage[]) =>
      handleChange({
        ...swatchValue,
        imageList,
      }),
    [swatchValue, handleChange],
  );

  const [imageList, setImageList] = useOverridableState(
    swatchValue?.imageList ?? [],
    onChangeImageList,
  );

  const rowTitle = React.useMemo((): string | null => {
    const fieldsCount = Object.keys(swatchValue ?? {}).reduce(
      (valuesCount, key) => {
        if (
          swatchValue &&
          Array.isArray(swatchValue[key as keyof SwatchValue]) &&
          // @ts-ignore
          swatchValue[key as keyof SwatchValue].length === 0
        ) {
          return valuesCount;
        }
        return valuesCount + 1;
      },
      0,
    );

    if (!swatchValue || fieldsCount === 0) {
      return null;
    }

    if (fieldsCount > 1) {
      return `${fieldsCount} Fields Set`;
    }

    if (swatchValue?.color) {
      return swatchValue.color?.type === "solid"
        ? getFormattedColorWithoutOpacity(swatchValue.color?.color) ?? null
        : "Gradient";
    }

    if (swatchValue?.image) {
      return swatchValue?.image?.src ?? null;
    }

    if (swatchValue?.imageList?.length === 1) {
      return swatchValue?.imageList[0]?.src ?? null;
    }

    return `${swatchValue.imageList?.length ?? 0} Images Set`;
  }, [swatchValue]);

  const showSelectionIndicatorBadge =
    Object.keys(swatchValue ?? {}).length <= 1 &&
    (swatchValue?.imageList?.length ?? 0) <= 1;

  const badgeImageSrc =
    swatchValue?.image?.src || swatchValue?.imageList?.[0]?.src || null;

  const showImageRow = !isReadOnly || imageList.length > 0;

  const anchorRef = React.useRef<HTMLDivElement>(null);
  const {
    onAnchorRectChange,
    ref: composedAnchorRef,
    virtualRef: virtualAnchorRef,
  } = useVirtualPopoverAnchorRef(anchorRef);

  return (
    // NOTE (Evan, 03-17-23) We disable the popover when read-only and no value is currently set,
    // because the popover won't contain any info in that case.
    <Popover
      isOpen={isReadOnly && swatchValue === null ? false : undefined}
      onOpenChange={() => {
        onAnchorRectChange(anchorRef.current?.getBoundingClientRect() ?? null);
      }}
    >
      <Popover.Anchor asChild virtualRef={virtualAnchorRef} />
      <div
        ref={composedAnchorRef}
        className="flex cursor-text items-center rounded bg-slate-50 px-2 py-1 text-default"
      >
        {label}
      </div>
      <Popover.Trigger asChild>
        <SelectionIndicator
          className="flex h-auto items-center"
          startEnhancer={
            showSelectionIndicatorBadge && (
              <Badge
                isFilled={!badgeImageSrc}
                className="h-4 w-4 rounded"
                backgroundColor={swatchValue?.color || "bg-slate-200"}
              >
                {badgeImageSrc && (
                  <img className="h-full w-full" src={badgeImageSrc} />
                )}
              </Badge>
            )
          }
          title={rowTitle}
          placeholder={isReadOnly ? "No Field Set" : "Select Value"}
          endEnhancer={
            swatchValue &&
            !isReadOnly &&
            Object.keys(swatchValue).length > 0 && (
              <RiCloseFill
                size={12}
                className="cursor-pointer text-slate-400"
                onClick={handleRemove}
              />
            )
          }
        />
      </Popover.Trigger>

      <Popover.Content
        title={label}
        shouldPreventDefaultOnInteractOutside={areModalsOpen}
      >
        <Group name="Swatch Color" className="mt-3 flex flex-col">
          <ColorSelector
            popoverTitle="Text Color"
            allowsGradientSelection={true}
            value={swatchValue?.color ?? null}
            isDisabled={isReadOnly}
            onChange={(newValue: SolidOrGradient) => {
              if (newValue.type === "solid" && !newValue?.color) {
                // Note (Mariano, 2022-23-08): When the color gets removed
                // the type will always be "solid" and its value "null"
                // Ideally, this component should take a "onRemove" prop
                const swatchWithoutColor = omit(swatchValue, "color");
                handleChange({
                  ...swatchWithoutColor,
                });
              } else {
                handleChange({
                  ...swatchValue,
                  color: newValue,
                });
              }
            }}
          />
        </Group>
        <Group name="Swatch Image" className="mt-1 flex flex-col">
          <ImageRow
            value={swatchValue?.image?.src ?? null}
            onChange={(newValue) => {
              if (newValue) {
                handleChange({
                  ...swatchValue,
                  image: {
                    src: newValue,
                  },
                });
              }
            }}
            onRemove={() => {
              const swatchWithoutImage = omit(swatchValue, "image");
              handleChange({
                ...swatchWithoutImage,
              });
            }}
            isDisabled={isReadOnly}
          />
        </Group>
        {showImageRow && (
          <Group
            name="Images"
            className="mt-2 flex flex-col"
            header={
              <GroupHeader
                endEnhancer={
                  !isReadOnly ? (
                    <GroupHeaderActionButton
                      aria-label="Add image"
                      onClick={() => {
                        setImageList([
                          ...imageList,
                          {
                            src: "",
                            id: uuidv4(),
                          },
                        ]);
                      }}
                    >
                      <MdAdd />
                    </GroupHeaderActionButton>
                  ) : null
                }
              />
            }
          >
            <div className="w-full">
              <SortableList
                onReorderEnd={({ oldIndex, newIndex }) => {
                  const reorderedList = arrayMove(
                    imageList,
                    oldIndex,
                    newIndex,
                  );
                  setImageList(reorderedList);
                }}
                withDragHandle
              >
                {imageList.map((image, index) => {
                  const itemKey = image.id ?? image.src ?? `img-item-${index}`;
                  return (
                    <SortableItem key={itemKey} id={itemKey} className="w-full">
                      <ImageRow
                        value={image.src}
                        onChange={(newValue) => {
                          if (newValue) {
                            const newSwatchImageList = [
                              ...(swatchValue?.imageList ?? []),
                            ];
                            newSwatchImageList[index] = {
                              id: newSwatchImageList[index]?.id ?? uuidv4(),
                              src: newValue,
                            };
                            setImageList(newSwatchImageList);
                          }
                        }}
                        alwaysShowRemoveButton
                        onRemove={() => {
                          const newSwatchImageList = [...(imageList ?? [])];
                          newSwatchImageList.splice(index, 1);
                          setImageList(newSwatchImageList);
                        }}
                        isDisabled={isReadOnly}
                      />
                    </SortableItem>
                  );
                })}
              </SortableList>
            </div>
          </Group>
        )}
      </Popover.Content>
    </Popover>
  );
};

/**
 * NOTE (Chance, 2023-04-17): The Radix Popover.Anchor component observes its
 * anchor element's DOM node for changes and updates the popover's position
 * accordingly. This is problematic when the anchor element moves around in the
 * context of another popover that is scrollable.
 *
 * To work around this, we use a "virtual ref" which Radix accepts as an
 * alternative to a DOM node. All it needs is a `getBoundingClientRect` method,
 * which we can update ourselves instead of monitoring the DOM node for changes.
 *
 * @see https://www.loom.com/share/69e51bda35564bbcb3cf0a66df94cdd6
 */
function useVirtualPopoverAnchorRef(
  anchorRef: React.MutableRefObject<HTMLDivElement | null>,
) {
  const [anchorRect, setAnchorRect] = React.useState<ClientRect | null>(null);

  const onRefMount = React.useCallback(
    (node: HTMLDivElement | null) => {
      anchorRef.current = node;
      setAnchorRect(anchorRef.current?.getBoundingClientRect() || null);
    },
    [anchorRef],
  );

  const virtualRef = React.useMemo<{
    current: { getBoundingClientRect(): ClientRect };
  }>(() => {
    return {
      current: {
        getBoundingClientRect() {
          if (anchorRect) {
            return anchorRect;
          }

          // NOTE (Chance, 2023-04-17): We need to return a valid rect object as
          // Radix depends on its type to match that of a DOM element's client
          // rect.
          return {
            bottom: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            width: 0,
            x: 0,
            y: 0,
            toJSON() {
              return {};
            },
          };
        },
      },
    };
  }, [anchorRect]);

  return {
    onAnchorRectChange: setAnchorRect,
    ref: onRefMount,
    virtualRef,
  };
}

const ImageRow: React.FC<{
  value?: string | null;
  onChange: (value: string | null) => void;
  onRemove?: () => void;
  className?: string;
  alwaysShowRemoveButton?: boolean;
  isDisabled: boolean;
}> = ({
  value,
  onChange,
  onRemove,
  className,
  alwaysShowRemoveButton,
  isDisabled,
}) => {
  const modal = useModal();

  const openModal = () => {
    modal.openModal({
      type: "assetLibraryModal",
      props: {
        referrer: "modifier/image",
        value: value ?? null,
        onChange,
        assetContentType: "image",
      },
    });
  };

  return (
    <div className={twMerge("max-w-full", className)}>
      <InlineAssetSelector
        size="sm"
        swatchTooltip="Image"
        emptyTitle="Select Image"
        onClickSelectAsset={openModal}
        onRemoveAsset={onRemove}
        allowRemoveAsset={!isDisabled}
        alwaysShowRemoveButton={alwaysShowRemoveButton}
        asset={
          value
            ? {
                type: "image",
                src: value,
              }
            : undefined
        }
        onInputChange={onChange}
        isDisabled={isDisabled}
      />
    </div>
  );
};

/**
 * React component for rendering a row in the swatch options list.
 */
export const SwatchOptionRow: React.FC<{
  option: SwatchOptionRowWithIndex;
  swatch: Swatch;
  isReadOnly: boolean;
}> = ({ option, swatch, isReadOnly }) => {
  const projectId = useCurrentProjectId()!;
  const utils = trpc.useUtils();
  const { mutate: mutateSwatch } = trpc.swatch.update.useMutation({
    onMutate: async ({ swatch: updatedSwatch }) => {
      await utils.swatch.list.cancel();
      const previousSwatches = utils.swatch.list.getData();
      utils.swatch.list.setData({ projectId }, (old: Swatch[] | undefined) => {
        const index = old?.findIndex((s) => s.id === updatedSwatch.id);
        if (index === -1 || index === undefined) {
          return [...(old || []), updatedSwatch];
        }
        return old?.map((swatch) =>
          swatch.id === updatedSwatch.id
            ? { ...swatch, ...updatedSwatch }
            : swatch,
        );
      });
      return { previousSwatches };
    },
    onError: (_err, _updatedSwatch, context) => {
      utils.swatch.list.setData({ projectId }, context?.previousSwatches);
    },
    onSettled: () => {
      void utils.swatch.list.invalidate();
    },
  });

  const handleChange = (value: SwatchValue) => {
    const { rowIndex, ...optionRow } = option;
    const newOptions = [...(swatch.data.options ?? [])];
    const newOptionValue: SwatchOptionRowType = {
      ...optionRow,
      value,
    };

    if (rowIndex !== -1) {
      newOptions[rowIndex] = newOptionValue;
    } else {
      newOptions.push(newOptionValue);
    }
    if (projectId) {
      mutateSwatch({
        projectId,
        swatch: {
          ...swatch,
          data: { ...swatch.data, options: newOptions },
        },
      });
    }
  };

  const handleRemove = () => {
    const newOptions = [...(swatch.data.options ?? [])];
    newOptions.splice(option.rowIndex, 1);
    if (projectId) {
      mutateSwatch({
        projectId,
        swatch: { ...swatch, data: { ...swatch.data, options: newOptions } },
      });
    }
  };

  return (
    <SwatchRow
      swatchValue={option.value!}
      label={option.label}
      handleChange={handleChange}
      handleRemove={handleRemove}
      isReadOnly={isReadOnly}
    />
  );
};

/**
 * React component for rendering a row in the swatch variants list.
 */
export const SwatchVariantRow: React.FC<{
  variant: SwatchVariantRowWithIndex;
  swatch: Swatch;
  isReadOnly: boolean;
}> = ({ variant, swatch, isReadOnly }) => {
  const projectId = useCurrentProjectId()!;
  const utils = trpc.useUtils();
  const { mutate: mutateSwatch } = trpc.swatch.update.useMutation({
    onMutate: async ({ swatch: updatedSwatch }) => {
      await utils.swatch.list.cancel();
      const previousSwatches = utils.swatch.list.getData();
      utils.swatch.list.setData({ projectId }, (old: Swatch[] | undefined) => {
        const index = old?.findIndex((s) => s.id === updatedSwatch.id);
        if (index === -1 || index === undefined) {
          return [...(old || []), updatedSwatch];
        }
        return old?.map((swatch) =>
          swatch.id === updatedSwatch.id
            ? { ...swatch, ...updatedSwatch }
            : swatch,
        );
      });
      return { previousSwatches };
    },
    onError: (_err, _updatedSwatch, context) => {
      utils.swatch.list.setData({ projectId }, context?.previousSwatches);
    },
    onSettled: () => {
      void utils.swatch.list.invalidate();
    },
  });

  const handleChange = (value: SwatchValue) => {
    const { rowIndex, ...variantRow } = variant;
    const newVariants = [...(swatch.data.variants ?? [])];
    const newVariantValue: SwatchVariantRowType = {
      ...variantRow,
      value,
    };

    if (rowIndex !== -1) {
      newVariants[rowIndex] = newVariantValue;
    } else {
      newVariants.push(newVariantValue);
    }

    if (projectId) {
      mutateSwatch({
        projectId,
        swatch: { ...swatch, data: { ...swatch.data, variants: newVariants } },
      });
    }
  };

  const handleRemove = () => {
    const newVariants = [...(swatch.data.variants ?? [])];
    newVariants.splice(variant.rowIndex, 1);
    if (projectId) {
      mutateSwatch({
        projectId,
        swatch: { ...swatch, data: { ...swatch.data, variants: newVariants } },
      });
    }
  };

  return (
    <SwatchRow
      label={variant.label}
      handleChange={handleChange}
      handleRemove={handleRemove}
      swatchValue={variant.value!}
      isReadOnly={isReadOnly}
    />
  );
};

/**
 * Helper function that takes a product, a swatch, and return a list of swatch
 * options that are valid for the given product, formatted in the correct format
 * for storing it in the DB.
 */

export function getSwatchOptionsRows(
  product: StoreProduct,
  swatch: Swatch,
): SwatchOptionRowWithIndex[] {
  const productOptionsWithValues = product.options.flatMap((option) => {
    return getProductOptionValues(
      product.variants,
      product.options,
      option,
    ).map((value) => ({ option, value }));
  });
  const productIdString = product.id.toString();
  return productOptionsWithValues.map(({ option, value }) => {
    const rowIndex =
      swatch.data.options?.findIndex(
        (optionRow) =>
          optionRow.key.name === option &&
          optionRow.key.value === value &&
          (!optionRow.key.productId ||
            optionRow.key.productId.toString() === productIdString),
      ) ?? -1;
    const optionRow = swatch.data.options?.[rowIndex];
    return {
      ...(optionRow ?? {
        id: uuidv4(),
        key: {
          name: option ?? "",
          value: value ?? "",
          productId: product.id,
        },
        label: `${option}/${value}`,
      }),
      rowIndex,
    };
  });
}

/**
 * Helper function that takes a product, a swatch, and return a
 * list of swatch variants that are valid for the given product, formatted
 * already in the correct format for storing it on the DB.
 */
export function getSwatchVariantsRows(
  product: StoreProduct,
  swatch: Swatch,
): SwatchVariantRowWithIndex[] {
  return product.variants.map((variant) => {
    // Format the variant value as a swatch variant row by checking on the
    // existing data stored in swatch.data.variants.
    const rowIndex =
      swatch.data.variants?.findIndex(
        (variantRow) =>
          Number(variantRow.key.productId) === Number(product.id) &&
          Number(variantRow.key.variantId) === Number(variant.id),
      ) ?? -1;
    const variantRow = swatch.data.variants?.[rowIndex];
    return {
      ...(variantRow ?? {
        id: uuidv4(),
        key: {
          productId: Number(product.id),
          variantId: variant.id,
        },
        label: `${variant.option1}${
          variant.option2 ? `/${variant.option2}` : ""
        }${variant.option3 ? `/${variant.option3}` : ""}`,
        value: undefined,
      }),
      rowIndex,
    };
  });
}
