import type { ComboboxProps } from "@replo/design-system/components/shadcn/combobox/types";
import type { FilterName } from "schemas/analyticsRead";
import type { AnalyticsReadQuery } from "schemas/generated/analyticsRead";

import * as React from "react";
import { useCallback } from "react";

import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { sanitizePageUrlPath } from "@editor/utils/analytics";
import { routes } from "@editor/utils/router";

import { DEFAULT_FILTERS } from "@/features/analytics/constants";
import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import useAnalyticsRead from "@/features/analytics/useAnalyticsRead";
import { Combobox } from "@replo/design-system/components/shadcn/combobox/Combobox";
import { BsCaretDownFill, BsWindow } from "react-icons/bs";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useControllableState } from "replo-utils/react/use-controllable-state";
import { ConditionOperatorEnum } from "schemas/analyticsRead";

type AnalyticsHostPagesComboboxProps = Pick<
  ComboboxProps,
  "open" | "onOpenChange"
> & {
  onChange: (value: string) => void;
  disabled?: boolean;
  value?: string;
};

const AnalyticsHostPagesCombobox = ({
  onChange,
  disabled,
  value: controlledValue,
  ...props
}: AnalyticsHostPagesComboboxProps) => {
  const { query } = useAnalyticsQueryContext();
  const workspaceId = useCurrentWorkspaceId();

  const navigate = useNavigate();

  const [value, setValue] = useControllableState(controlledValue, "", onChange);

  const { host = "" } = useParams<{ host: string }>();
  const decodedHost = decodeURIComponent(host);

  const [searchTerm, setSearchTerm] = React.useState("");

  const [searchQuery, setSearchQuery] = React.useState<AnalyticsReadQuery>(
    () => ({
      ...query,
      limit: 10,
      sortMetric: "unique_sessions",
      order: "DESC",
      urlHosts: [decodedHost],
    }),
  );

  const { rangeResults: initialRangeResult } = useAnalyticsRead(
    searchQuery,
    workspaceId,
  );

  const uniqueRangeResults = React.useMemo(() => {
    if (!initialRangeResult) {
      return [];
    }

    const uniqueResults = new Map();
    initialRangeResult.forEach((result) => {
      if (!uniqueResults.has(result.urlPath)) {
        uniqueResults.set(result.urlPath, result);
      }
    });

    return Array.from(uniqueResults.values());
  }, [initialRangeResult]);

  const handleFilterChange = (filterName: FilterName, value: string) => {
    setSearchQuery((prevQuery) => ({
      ...prevQuery,
      filters: {
        ...prevQuery.filters,
        [filterName]: value
          ? [
              {
                operator: ConditionOperatorEnum.CONTAINS,
                value,
              },
            ]
          : DEFAULT_FILTERS[filterName],
      },
      offset: 0,
    }));
  };

  const { value: inputValue, onChange: onInputChange } = useOverridableInput({
    value: searchTerm,
    onValueChange: (value: string) => {
      handleFilterChange("urlPath", value);
      setSearchTerm(value);
    },
  });

  const handleOptionClick = useCallback(
    (urlPath: string) => {
      navigate(
        generatePath(routes.analytics.pageDetails, {
          workspaceId,
          host: decodedHost,
          pageUrlPath: encodeURIComponent(urlPath),
        }),
      );
    },
    [navigate, workspaceId, decodedHost],
  );

  const pageOptions = React.useMemo(() => {
    return uniqueRangeResults.map((result) => ({
      value: result.urlPath,
      label: sanitizePageUrlPath(result.urlPath),
      onClick: () => {
        handleOptionClick(result.urlPath);
      },
    }));
  }, [uniqueRangeResults, handleOptionClick]);

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <Combobox
      options={pageOptions}
      value={value}
      onChange={handleChange}
      isDisabled={disabled}
      areOptionsSearchable={true}
      input={inputValue}
      onInputChange={(value: string) =>
        onInputChange({
          target: { value },
        } as React.ChangeEvent<HTMLInputElement>)
      }
      startEnhancer={() => <BsWindow className="h-4 w-4" />}
      endEnhancer={() => <BsCaretDownFill className="h-2 w-2 text-subtle" />}
      {...props}
    />
  );
};

export default AnalyticsHostPagesCombobox;
