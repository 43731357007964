import type { Component } from "replo-runtime/shared/Component";

import * as React from "react";

import {
  generateStyleId,
  getComponentRuleName,
} from "replo-runtime/shared/styles";
import { mapMediaCanvasStyles } from "replo-runtime/shared/utils/breakpoints";

export const GridWrapper = ({
  children,
  component,
}: {
  children: React.ReactNode;
  component: Component;
}) => {
  let hasGrid = false;
  mapMediaCanvasStyles(component.props, (styles) => {
    if (styles.display === "grid") {
      hasGrid = true;
    }

    return {};
  });

  if (!hasGrid) {
    return <>{children}</>;
  }

  const className = generateStyleId(
    getComponentRuleName(component.id, { isGridWrapper: true }),
  );

  return (
    <div className={className} data-rid="1">
      {children}
    </div>
  );
};
