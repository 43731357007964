import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";
import { ItemsConfigType } from "replo-runtime/store/utils/items";

const productWithCarousel: ComponentTemplate = {
  id: "999dbb78-a442-4b29-b164-07ae213c4c2d",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Product with Carousel",
  leftBarCategory: { main: "product", subCategory: "productLayouts" },
  preview: "product/product-with-carousel.png",
  template: {
    id: "97d7505e-f776-41c2-b37a-0e410870bf8d",
    name: "Product 1",
    type: "product",
    props: {
      style: {
        display: "flex",
        __flexGap: "36px",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
      },
      "style@sm": {
        display: "flex",
        __flexGap: "0px",
        flexDirection: "column",
      },
    },
    children: [
      {
        id: "90fdc455-81f1-4bd2-8320-c982953ca0d5",
        name: "Carousel 1",
        type: "carouselV3",
        props: {
          style: {
            display: "flex",
            flexGrow: "1",
            maxWidth: "100%",
            alignSelf: "stretch",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          },
          _items: {
            type: ItemsConfigType.inline,
            valueType: "dynamic",
            dynamicPath: "{{attributes._product.images}}",
          },
        },
        children: [
          {
            id: "eaf99201-bd95-4f46-8596-7a7108b53fd2",
            name: "Previous Button 1",
            type: "carouselV3Control",
            props: {
              style: {
                cursor: "pointer",
                display: "flex",
                flexGrow: "unset",
                flexWrap: "wrap",
                alignSelf: "stretch",
                flexBasis: "auto",
                alignItems: "center",
                justifyContent: "center",
              },
              direction: "previous",
            },
            children: [
              {
                id: "fe86f896-a904-42be-b3a9-97f01ca9258f",
                type: "icon",
                props: {
                  style: {
                    width: "60px",
                    height: "60px",
                    __width: "60px",
                    __height: "60px",
                    flexGrow: "unset",
                  },
                  iconName: "chevronLeft",
                },
                variantOverrides: {},
              },
            ],
            variants: [
              {
                id: "378d3ebf-eb7f-49e8-8cff-06d85b8e77e4",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "3c7e3714-58c2-4e75-bb54-3f6f3e26a254",
                name: "First Item",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "bf8b0b4c-79fd-4043-a18b-9840a038a6e3",
                      field: "state.group.isFirstItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "3c7e3714-58c2-4e75-bb54-3f6f3e26a254": {
                componentOverrides: {
                  "eaf99201-bd95-4f46-8596-7a7108b53fd2": {
                    props: {
                      style: {
                        cursor: "default",
                      },
                    },
                  },
                  "fe86f896-a904-42be-b3a9-97f01ca9258f": {
                    props: {
                      style: {
                        opacity: "20%",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "45bd235b-9624-4339-b791-b17f9a4a82ac",
            name: "Container 2",
            type: "container",
            props: {
              style: {
                display: "flex",
                flexGrow: "1",
                maxWidth: "100%",
                alignSelf: "stretch",
                flexBasis: "auto",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "flex-start",
              },
            },
            children: [
              {
                id: "6fe7dd98-e04b-4dca-9079-dca9d1b4a839",
                name: "Carousel Slides",
                type: "carouselV3Slides",
                props: {
                  style: {
                    __width: "1197px",
                    flexGrow: 1,
                    overflow: "hidden",
                    alignSelf: "stretch",
                  },
                  _useCustomConfig: false,
                },
                children: [
                  {
                    id: "796a9327-343b-4be2-8942-5c9b2a856056",
                    name: "Slide 1",
                    type: "container",
                    props: {
                      style: {
                        __width: "auto",
                        display: "flex",
                        flexGrow: "1",
                        __flexGap: "12px",
                        flexBasis: "auto",
                        alignItems: "center",
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        justifyContent: "center",
                      },
                    },
                    children: [
                      {
                        id: "b31d76ff-6cda-4a89-a1eb-b5c0f4f60107",
                        name: "Image 1",
                        type: "image",
                        props: {
                          src: "",
                          style: {
                            objectFit: "cover",
                            __imageSource: "{{attributes._currentItem}}",
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                id: "775e0821-7998-4338-9692-a6178a3f4b62",
                name: "Carousel Indicators",
                type: "carouselV3Indicators",
                props: {
                  style: {
                    top: "auto",
                    left: "auto",
                    right: "auto",
                    bottom: "auto",
                    zIndex: 2,
                    display: "flex",
                    position: "relative",
                    alignSelf: "stretch",
                    marginTop: "0px",
                    alignItems: "center",
                    marginLeft: "0px",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    justifyContent: "center",
                  },
                },
                children: [
                  {
                    id: "b29a95d9-3d00-4a53-b102-676f3cca6d9a",
                    name: "Container 3",
                    type: "container",
                    props: {
                      style: {
                        width: "10px",
                        cursor: "pointer",
                        height: "10px",
                        __width: "10px",
                        display: "flex",
                        __height: "10px",
                        flexGrow: "unset",
                        maxWidth: "100%",
                        alignSelf: "auto",
                        alignItems: "flex-start",
                        marginLeft: "10px",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        backgroundColor: "#0000004D",
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px",
                      },
                    },
                    children: [],
                    variants: [
                      {
                        id: "3ed9062a-fa99-46f0-a38c-4ebcab25c4c3",
                        name: "default",
                        query: null,
                        componentOverrides: null,
                      },
                      {
                        id: "d7c171e8-a3b9-475d-b493-8e6618355ac9",
                        name: "Current item is active",
                        query: {
                          type: "expression",
                          operator: "or",
                          statements: [
                            {
                              id: "a5eefa18-3bc3-4890-a191-335f2c071f83",
                              field: "state.group.isCurrentItemActive",
                              value: null,
                              operator: null,
                            },
                          ],
                        },
                        componentOverrides: null,
                      },
                    ],
                    variantOverrides: {
                      "d7c171e8-a3b9-475d-b493-8e6618355ac9": {
                        componentOverrides: {
                          "b29a95d9-3d00-4a53-b102-676f3cca6d9a": {
                            props: {
                              style: {
                                backgroundColor: "#000000FF",
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "e13d88f4-936b-4483-8714-4e89e53a51ff",
            name: "Next Button 1",
            type: "carouselV3Control",
            props: {
              style: {
                cursor: "pointer",
                display: "flex",
                flexGrow: "unset",
                alignSelf: "stretch",
                flexBasis: "auto",
                alignItems: "center",
                justifyContent: "center",
              },
              direction: "next",
            },
            children: [
              {
                id: "1cbcccd4-47b9-4706-852c-175b471a4766",
                type: "icon",
                props: {
                  style: {
                    width: "60px",
                    height: "60px",
                    __width: "60px",
                    __height: "60px",
                    flexGrow: "unset",
                  },
                  iconName: "chevronRight",
                },
                variantOverrides: {},
              },
            ],
            variants: [
              {
                id: "ce049bfd-23e8-4a0b-aea5-bad33983a40a",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "c3b88d42-9044-4e40-912e-9a48f69a528a",
                name: "Last Item",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "fa7db26c-7755-450b-b10f-b5f0f5c6a624",
                      field: "state.group.isLastItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "c3b88d42-9044-4e40-912e-9a48f69a528a": {
                componentOverrides: {
                  "1cbcccd4-47b9-4706-852c-175b471a4766": {
                    props: {
                      style: {
                        opacity: "20%",
                      },
                    },
                  },
                  "e13d88f4-936b-4483-8714-4e89e53a51ff": {
                    props: {
                      style: {
                        cursor: "default",
                      },
                    },
                  },
                },
              },
            },
          },
        ],
      },
      {
        id: "322982d6-ee56-4583-880e-65ec3f1a3754",
        name: "Container 1",
        type: "container",
        props: {
          style: {
            color: "#000000FF",
            display: "flex",
            flexGrow: "1",
            maxWidth: "800px",
            __flexGap: "24px",
            alignSelf: "stretch",
            flexBasis: 0,
            alignItems: "flex-start",
            paddingTop: "24px",
            paddingLeft: "24px",
            paddingRight: "24px",
            flexDirection: "column",
            paddingBottom: "24px",
            justifyContent: "center",
          },
          "style@md": {
            flexGrow: "1",
            maxWidth: "auto",
            flexBasis: 0,
          },
          "style@sm": {
            flexGrow: 1,
            flexBasis: "auto",
          },
        },
        children: [
          {
            id: "f6105d7c-d9b0-473d-89b8-5202410d66fb",
            type: "text",
            props: {
              text: "<p>{{attributes._product.title}}</p>",
              style: {
                fontSize: "48px",
                fontWeight: "400",
                lineHeight: "48px",
              },
            },
          },
          {
            id: "bf4f9856-8a5a-46ab-86e2-df40d94624b0",
            type: "text",
            props: {
              text: "{{attributes._product.description}}",
              style: {
                fontSize: "20px",
                lineHeight: "24px",
              },
            },
          },
          {
            id: "9f06ffd2-6d20-4bb3-800c-4b60fe8adffa",
            type: "text",
            props: {
              text: "<p>{{attributes._variant.displayPrice}}</p>",
              style: {
                fontSize: "32px",
                fontWeight: 300,
                lineHeight: "48px",
              },
            },
          },
          {
            id: "8f01528f-5ac8-4895-a266-60ac2a5ae658",
            type: "button",
            props: {
              style: {
                color: "#FFFFFF",
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "20px",
                paddingTop: "20px",
                paddingLeft: "32px",
                paddingRight: "32px",
                letterSpacing: "2px",
                paddingBottom: "20px",
                backgroundColor: "#000000FF",
              },
              onClick: [
                {
                  id: "$uid",
                  type: "addProductVariantToCart",
                  value: {
                    product: {
                      ref: "attributes._variant",
                      type: "contextRef",
                    },
                    redirectToCart: true,
                    redirectToCheckout: false,
                  },
                },
              ],
            },
            children: [
              {
                id: "c4a96983-2cdc-4715-88b4-b47c68acb50c",
                type: "text",
                props: {
                  text: "<p>Add To Cart</p>",
                  style: {
                    color: "#FFFFFF",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    letterSpacing: "2px",
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

export default productWithCarousel;
