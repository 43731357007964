import type { ButtonSharedProps } from "@common/designSystem/Button";

import * as React from "react";

import { Button, ButtonLink } from "@common/designSystem/Button";

import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type DashboardMenuItemProps = {
  item: {
    title: string;
    onClick?: () => void;
    path?: string;
    startEnhancer?: () => React.ReactNode;
    endEnhancer?: () => React.ReactNode;
    href?: string;
    className?: string;
    textClassName?: string;
    isSelected?: boolean;
    hasSubItems?: boolean;
  };
};

const DashboardMenuItem: React.FC<DashboardMenuItemProps> = ({
  item: {
    title,
    onClick,
    path,
    startEnhancer,
    href,
    endEnhancer,
    className,
    textClassName,
    isSelected,
    hasSubItems,
  },
}) => {
  const { pathname } = useLocation();

  const buttonProps = {
    type: "tertiary",
    size: "base",
    isFullWidth: true,
    className: twMerge(
      classNames("flex gap-2 justify-start font-normal hover:bg-blue-50", {
        "bg-blue-50 text-default":
          path && pathname.startsWith(path) && isSelected && !hasSubItems,
      }),
      className,
    ),
    textClassNames: twMerge("text-slate-600", textClassName),
    startEnhancer,
    endEnhancer,
  } satisfies ButtonSharedProps;

  if (href) {
    return (
      <ButtonLink key={title} {...buttonProps} to={href} target="_blank">
        {title}
      </ButtonLink>
    );
  }

  return (
    <Button key={title} {...buttonProps} onClick={onClick}>
      {title}
    </Button>
  );
};

export default DashboardMenuItem;
