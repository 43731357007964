import type { Component } from "replo-runtime/shared/Component";

import {
  mapMediaCanvasStyles,
  media,
  mediaSizes,
} from "replo-runtime/shared/utils/breakpoints";

/**
 * This function loop into each media query and test a particular condition provided
 * by the second argument of the function. This is useful to test if the media
 * queries has a particular style, like if has width set for example.
 *
 * @param component CarouselV3Slides component to check for defined widths (the children
 * of this component will be checked, because they're slides)
 * @param test Function that test a provided condition with the carousels styles
 */
export const mediaQueriesPassingTest = (
  component: Component,
  test: (style: Component["props"]["style"]) => boolean,
) => {
  const componentMediaStyles = mapMediaCanvasStyles(
    component.props,
    (styles) => {
      return { testResult: test(styles) };
    },
  );

  return (
    ["none", ...Object.values(media)] as (`@media ${string}` | "none")[]
  ).filter((value) => {
    if (value === "none") {
      return componentMediaStyles.testResult ?? false;
    }

    return componentMediaStyles[value]?.testResult ?? false;
  });
};

export const allMediaSizesPassTest = (
  component: Component,
  test: (style: Component["props"]["style"]) => boolean,
): boolean => {
  return (
    mediaQueriesPassingTest(component, test).length ===
    // Note (Noah, 2023-02-22): The +1 here is for the empty default desktop media
    // query (props.style)
    mediaSizes.length + 1
  );
};
