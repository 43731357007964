import type { Component } from "replo-runtime/shared/Component";
import type { EditorPropType } from "replo-runtime/shared/types";

import * as React from "react";

import IconButton from "@common/designSystem/IconButton";
import Selectable from "@common/designSystem/Selectable";
import usePaintSharedState, {
  usePaintSharedStateValue,
} from "@editor/hooks/usePaintSharedState";
import { useSyncRuntimeValue } from "@editor/hooks/useSyncRuntimeValue";

import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsLightningChargeFill } from "react-icons/bs";
import { CgArrowsMergeAltV, CgArrowsShrinkV } from "react-icons/cg";
import { RiEyeCloseLine, RiEyeLine } from "react-icons/ri";

type EditorDataRenderProps = {
  value: any;
  id: string;
  component: Component;
  onChange: Function;
  description: string;
  componentId: string;
};

type EditorData = {
  render(props: EditorDataRenderProps): React.ReactNode;
  defaultValue: any;
};

export const getEditorPropEditorData = (
  editorPropType: EditorPropType,
): EditorData => {
  return editorPropTypeToEditorData[editorPropType]!;
};

export const canRenderEditorControl = (
  editorPropType: EditorPropType,
  component: Component,
) => {
  if (editorPropType === "interactions") {
    return (component.props.onClick ?? []).length === 0;
  }

  return true;
};

export const editorPropTypeToEditorData: Record<
  EditorPropType,
  EditorData | null
> = {
  index: {
    render: (props) => {
      return <ActiveIndexControl {...props} />;
    },
    defaultValue: 0,
  },
  visibility: {
    render: (props) => {
      return <VisibilityControl {...props} />;
    },
    defaultValue: false,
  },
  collapsibility: {
    render: (props) => {
      return <CollapsibilityControl {...props} />;
    },
    defaultValue: false,
  },
  interactions: {
    render: (props) => {
      return <InteractionsControl {...props} />;
    },
    defaultValue: false,
  },
  activeBeforeAfterState: {
    render: (props) => {
      return <ActiveBeforeAfterSlideControl {...props} />;
    },
    defaultValue: "both",
  },
};

const ActiveIndexControl: React.FC<EditorDataRenderProps> = ({
  value,
  onChange,
  componentId,
}) => {
  const totalItems = useSyncRuntimeValue(`${componentId}.totalItems`);
  if (!totalItems || typeof totalItems !== "number") {
    return null;
  }

  return (
    <div className="flex items-center">
      <IconButton
        icon={<BiChevronLeft size={16} />}
        type="primary"
        onClick={() => {
          if (value > 0) {
            onChange(value - 1);
          }
        }}
        style={{
          backgroundColor: "transparent",
          padding: 0,
        }}
        tooltipText="Previous"
      />
      <div className="border-x border-white/25 px-3 whitespace-nowrap">
        {value + 1} of {totalItems}
      </div>
      <IconButton
        icon={<BiChevronRight size={16} />}
        type="primary"
        onClick={() => {
          if (value < totalItems - 1) {
            onChange(value + 1);
          }
        }}
        style={{
          backgroundColor: "transparent",
          padding: 0,
        }}
        tooltipText="Next"
      />
    </div>
  );
};

const VisibilityControl: React.FC<EditorDataRenderProps> = ({
  value: isVisible,
  onChange,
  component,
}) => {
  const label = `${isVisible ? "Hide" : "Show"} ${component.name}`;
  return (
    <IconButton
      icon={isVisible ? <RiEyeLine /> : <RiEyeCloseLine />}
      type="primary"
      onClick={() => {
        onChange(!isVisible);
      }}
      style={{
        backgroundColor: "transparent",
        padding: 0,
      }}
      tooltipText={label}
    />
  );
};

const CollapsibilityControl: React.FC<EditorDataRenderProps> = ({
  value: isOpen,
  onChange,
}) => {
  return (
    <IconButton
      icon={
        isOpen ? <CgArrowsMergeAltV size={18} /> : <CgArrowsShrinkV size={18} />
      }
      type="primary"
      onClick={() => {
        onChange(!isOpen);
      }}
      style={{
        backgroundColor: "transparent",
        padding: 0,
      }}
      tooltipText={isOpen ? "Collapse" : "Expand"}
    />
  );
};

const InteractionsControl: React.FC<EditorDataRenderProps> = ({
  onChange,
  description,
}) => {
  return (
    <IconButton
      icon={<BsLightningChargeFill size={16} />}
      type="primary"
      onClick={() => onChange()}
      tooltipText={description}
      style={{
        backgroundColor: "transparent",
        padding: 0,
      }}
      className="text-white/50 hover:text-white"
    />
  );
};

type BeforeAfterControlState = "before" | "both" | "after" | null;

const ActiveBeforeAfterSlideControl: React.FC<EditorDataRenderProps> = ({
  value,
  onChange,
  componentId,
}) => {
  const { setSharedState } = usePaintSharedState();
  const [position] = usePaintSharedStateValue(`${componentId}.position`);

  const onPositionChange = (value: BeforeAfterControlState) => {
    let newValue: BeforeAfterControlState = null;
    let newPosition: number | null = null;

    if (value === "before") {
      newValue = "before";
      newPosition = 100;
    } else if (value === "both") {
      newValue = "both";
      newPosition = 50;
    } else {
      newValue = "after";
      newPosition = 0;
    }

    if (newValue && newPosition !== null) {
      onChange(newValue);
      setSharedState({
        key: `${componentId}.position`,
        value: newPosition,
      });
    }
  };

  // NOTE (Sebas, 2024-06-04): This effect is used to update the position of the slider
  // when the user changes the selected component in the editor.
  React.useEffect(() => {
    if (position === 0) {
      onChange("after");
    }
    if (position === 50) {
      onChange("both");
    }
    if (position === 100) {
      onChange("before");
    }
  }, [onChange, position]);

  const selectableOptions = [
    { value: "before", label: "Before" },
    { value: "both", label: "Both" },
    { value: "after", label: "After" },
  ];

  return (
    <Selectable
      placeholder="Position"
      options={selectableOptions}
      value={value}
      defaultValue="both"
      onSelect={onPositionChange}
      disableDropdownFixedWidth
      className="bg-transparent"
      labelClassName="text-white text-sm pr-1"
      arrowClassName="text-white"
    />
  );
};
