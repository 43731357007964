import * as React from "react";

import Badge from "@editor/components/common/designSystem/Badge";
import BadgeV2 from "@editor/components/common/designSystem/BadgeV2";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import { isNewRightBarUIEnabled } from "@editor/infra/featureFlags";
import { getDynamicDataValueDisplayName } from "@editor/utils/dynamic-data";

import { BsFonts, BsX } from "react-icons/bs";
import { FiDatabase } from "react-icons/fi";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { evaluateVariable } from "replo-runtime/store/AlchemyVariable";
import tinycolor from "tinycolor2";

type DynamicDataIndicatorType =
  | "color"
  | "gradient"
  | "text"
  | "image"
  | "video"
  | "other";

type DynamicDataValueIndicatorProps = {
  type: DynamicDataIndicatorType;
  templateValue: string | null;
  onClick(): void;
  onRemove?(): void;
  componentId?: string;
};

export const DynamicDataValueIndicator = ({
  type,
  templateValue,
  onClick,
  onRemove,
  componentId,
}: DynamicDataValueIndicatorProps) => {
  const renderBadge = (
    templateValue: string | null,
    type: DynamicDataIndicatorType,
  ) => {
    const isText = type === "text";
    let bgColor = "bg-accent";
    let isValidColor = false;
    if (
      (type === "color" || type === "gradient") &&
      templateValue &&
      componentId
    ) {
      const context = getCurrentComponentContext(componentId, 0);
      const evaluatedVariable = evaluateVariable(templateValue, context);
      if (typeof evaluatedVariable === "string") {
        isValidColor = tinycolor(evaluatedVariable).isValid();
        if (isValidColor) {
          bgColor = evaluatedVariable;
        }
      }
    }

    if (isNewRightBarUIEnabled()) {
      if (isText || !isValidColor) {
        const Icon = isText ? BsFonts : FiDatabase;
        return <BadgeV2 type="icon" icon={<Icon size={12} />} />;
      }
      return <BadgeV2 type="color" isFilled backgroundColor={bgColor} />;
    }

    return (
      <Badge
        isFilled
        backgroundColor={bgColor}
        foregroundColor="text-onEmphasis"
      >
        {!isText && !isValidColor && <FiDatabase size={10} />}
        {isText && <BsFonts size={10} />}
      </Badge>
    );
  };

  const title = templateValue
    ? getDynamicDataValueDisplayName(templateValue)
    : "Select Dynamic Data";

  return (
    <SelectionIndicator
      title={title}
      className="cursor-pointer"
      labelClassname={templateValue ? undefined : "text-slate-400"}
      onClick={onClick}
      startEnhancer={templateValue && renderBadge(templateValue, type)}
      endEnhancer={
        onRemove &&
        templateValue && (
          <div
            className="flex items-center justify-center h-4 w-4 cursor-pointer text-gray-400"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              onRemove();
            }}
          >
            <BsX />
          </div>
        )
      }
    />
  );
};
