import type { IconType } from "react-icons";

import * as React from "react";

import { isTailwindClassName } from "@editor/utils/tailwind-classname";

import classNames from "classnames";
import { RiDatabase2Fill } from "react-icons/ri";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

type BaseBadgeProps = {
  isFilled?: boolean;
  onClick?: () => void;
};

type ImageBadgeProps = BaseBadgeProps & {
  type: "image";
  src: string;
};

type IconBadgeProps = BaseBadgeProps & {
  type: "icon";
  icon: IconType | React.ReactNode;
};

type DynamicDataBadgeProps = BaseBadgeProps & {
  type: "dynamicData";
};

type ColorBadgeProps = BaseBadgeProps & {
  type: "color";
  foregroundColor?: string;
  backgroundColor: string;
};

type VideoBadgeProps = BaseBadgeProps & {
  type: "video";
  src: string;
};

type BadgeProps =
  | ImageBadgeProps
  | IconBadgeProps
  | DynamicDataBadgeProps
  | ColorBadgeProps
  | VideoBadgeProps;

const BadgeV2 = (props: BadgeProps) => {
  const content = exhaustiveSwitch(props)({
    image: (props) => {
      if (props.src) {
        return (
          <img
            src={props.src}
            alt="Preview Image"
            className="w-full h-full object-cover object-center"
            onError={(e) => {
              (e.target as HTMLImageElement).style.display = "none";
            }}
          />
        );
      }
      return null;
    },
    icon: (props) => {
      const { icon } = props;
      if (typeof icon === "function") {
        const Icon = icon;
        return <Icon />;
      }
      return icon;
    },
    dynamicData: () => (
      <RiDatabase2Fill className="text-onEmphasis" size={12} />
    ),
    color: (props) => {
      const {
        backgroundColor,
        foregroundColor = "text-subtle",
        isFilled,
      } = props;

      const textColorStyle = isTailwindClassName(foregroundColor, "text")
        ? foregroundColor
        : undefined;
      const backgroundColorStyle =
        isFilled && !isTailwindClassName(backgroundColor, "bg")
          ? backgroundColor
          : undefined;

      return (
        <div
          className={classNames("w-full h-full rounded", {
            [backgroundColor]:
              isFilled && isTailwindClassName(backgroundColor, "bg"),
            [foregroundColor]: isTailwindClassName(foregroundColor, "text"),
          })}
          style={{
            // NOTE (Sebas, 2024-09-29): These styles are necessary in case we don't pass a background color
            // from tailwind.
            background: backgroundColorStyle,
            color: textColorStyle,
          }}
        />
      );
    },
    video: (props) => {
      return (
        <video
          className="h-full w-full object-cover object-center"
          src={props.src ?? undefined}
        />
      );
    },
  });

  const shouldHaveBorder = !["dynamicData", "icon"].includes(props.type);

  return (
    <div
      className={classNames(
        "flex items-center justify-center rounded h-4 w-4",
        {
          "border border-slate-200": shouldHaveBorder,
          "cursor-pointer": Boolean(props.onClick),
          "bg-accent": props.type === "dynamicData",
          "overflow-hidden": props.type === "image",
        },
      )}
      role={
        props.type === "dynamicData" || !props.onClick ? undefined : "button"
      }
      onClick={props.onClick}
    >
      {content}
    </div>
  );
};

export default BadgeV2;
