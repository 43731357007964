import type { BorderSuffix } from "replo-runtime/shared/styleAttribute";
import type { PreviewableProperty } from "replo-runtime/shared/utils/preview";

import * as React from "react";

import Border from "@common/icons/Border";
import BorderLeft from "@common/icons/BorderLeft";
import Selectable from "@editor/components/common/designSystem/Selectable";
import ToggleGroup from "@editor/components/common/designSystem/ToggleGroup";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useModal } from "@editor/hooks/useModal";
import {
  selectActiveBorderSideColor,
  selectActiveBorderSideStyle,
  selectActiveBorderSideWidth,
  selectBorderColor,
  selectBorderRadius,
  selectBorderWidth,
  selectDraftComponentId,
  selectedDraftComponentIsRoot,
  selectHasBorderStyles,
  selectInitialBorderSide,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { prepareBorderStyles } from "@editor/utils/border";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DraggingDirections, DraggingTypes } from "@editor/utils/editor";
import DocumentationInfoIcon from "@editorComponents/DocumentationInfoIcon";
import ModifierGroup, {
  ModifierPlusTrigger,
} from "@editorExtras/ModifierGroup";
import { DynamicColorSelector } from "@editorModifiers/DynamicColorModifier";
import LengthInputModifier, {
  LengthInputSelector,
} from "@editorModifiers/LengthInputModifier";
import BorderAllSides from "@svg/icons/border-all-sides";
import BorderSide from "@svg/icons/border-side";

import { AiOutlineRadiusUpleft } from "react-icons/ai";
import { RxBox } from "react-icons/rx";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { usePrevious } from "replo-runtime/shared/hooks/usePrevious";
import { CSS_LENGTH_TYPES } from "replo-runtime/shared/utils/units";

import ModifierLabel from "../extras/ModifierLabel";

const BorderModifier = () => {
  const applyComponentAction = useApplyComponentAction();
  const hasBorderStyles = useEditorSelector(selectHasBorderStyles);
  const borderRadiusValues = useEditorSelector(selectBorderRadius);
  const hasBorderRadius = borderRadiusValues.some((borderRadius) => {
    return borderRadius !== null && borderRadius !== "0px";
  });

  const addDefaultBorder = () => {
    const styles = prepareBorderStyles("", "1px", "#000000", "solid");
    applyComponentAction({
      type: "setStyles",
      value: styles,
    });
  };
  const hasBorderOrRadius = hasBorderStyles || hasBorderRadius;

  return (
    <ModifierGroup
      title="Border"
      titleEnhancer={<DocumentationInfoIcon documentationType="border" />}
      isDefaultOpen={hasBorderOrRadius}
      endEnhancer={
        !hasBorderOrRadius ? (
          <button
            type="button"
            aria-label="Add default border"
            onClick={addDefaultBorder}
            disabled={hasBorderOrRadius}
          >
            <ModifierPlusTrigger />
          </button>
        ) : null
      }
      isCollapsible={hasBorderOrRadius}
    >
      {hasBorderOrRadius ? <BorderControls /> : null}
    </ModifierGroup>
  );
};

const iconSize = 16;

export const radiusOptions = [
  {
    value: "",
    label: "Reset",
  },
  {
    value: "4px",
    label: "4px",
  },
  {
    value: "8px",
    label: "8px",
  },
  {
    value: "12px",
    label: "12px",
  },
];

export const widthOptions = [
  {
    value: "",
    label: "Reset",
  },
  {
    value: "1px",
    label: "1px",
  },
  {
    value: "2px",
    label: "2px",
  },
];

const borderOptions = [
  {
    value: "solid",
    label: "Solid",
  },
  {
    value: "dashed",
    label: "Dashed",
  },
  {
    value: "dotted",
    label: "Dotted",
  },
  {
    value: "double",
    label: "Double",
  },
  {
    value: "inset",
    label: "Inset",
  },
  {
    value: "none",
    label: "None",
  },
];

const borderRadiusIndividualOptions = [
  {
    key: "borderTopLeftRadius",
    enhancerClassName: "",
  },
  {
    key: "borderTopRightRadius",
    enhancerClassName: "rotate-90",
  },
  {
    key: "borderBottomLeftRadius",
    enhancerClassName: "-rotate-90",
  },
  {
    key: "borderBottomRightRadius",
    enhancerClassName: "rotate-180",
  },
];

const BorderControls: React.FC = () => {
  const initialBorderSide = useEditorSelector(selectInitialBorderSide);
  const [activeSide, setActiveSide] = useOverridableState(initialBorderSide);
  const [isExpandedBorderRadius, setIsExpandedBorderRadius] =
    React.useState(false);
  const isRoot = useEditorSelector(selectedDraftComponentIsRoot);

  return (
    <div className="flex flex-col gap-2">
      <BorderSideToggles
        value={activeSide}
        onChange={(newValue: BorderSuffix | null) => {
          setActiveSide(newValue || "");
        }}
      />
      <BorderStyleSelectable activeSide={activeSide} />
      <BorderColorControl activeSide={activeSide} />
      <BorderWidthControl activeSide={activeSide} />
      {/* Note (Noah, 2023-02-16, REPL-5519): Don't allow setting border radius on the root component,
        because it sets overflow to hidden and thus does not allow sticky positioning to work */}
      {!isRoot && (
        <BorderRadiusControls
          isExpandedBorderRadius={isExpandedBorderRadius}
          setIsExpandedBorderRadius={setIsExpandedBorderRadius}
        />
      )}
    </div>
  );
};

const sidesOptions = [
  {
    value: "all",
    label: <BorderAllSides />,
  },
  {
    value: "oneSide",
    label: <BorderSide />,
  },
];

const sideOptions = [
  {
    value: "",
    label: <Border size={iconSize} className="rounded-sm text-slate-200" />,
    tooltipContent: "All Sides",
  },
  {
    value: "Left",
    label: <BorderLeft size={iconSize} className="rounded-sm text-slate-200" />,
    tooltipContent: "Left Border",
  },
  {
    value: "Top",
    label: (
      <BorderLeft
        size={iconSize}
        className="rotate-90 rounded-sm text-slate-200"
      />
    ),
    tooltipContent: "Top Border",
  },
  {
    value: "Right",
    label: (
      <BorderLeft
        size={iconSize}
        className="rotate-180 rounded-sm text-slate-200"
      />
    ),
    tooltipContent: "Right Border",
  },
  {
    value: "Bottom",
    label: (
      <BorderLeft
        size={iconSize}
        className="rotate-[270deg] rounded-sm text-slate-200"
      />
    ),
    tooltipContent: "Bottom Border",
  },
];

const BorderSideToggles: React.FC<{
  value: BorderSuffix | null;
  onChange: (value: BorderSuffix | null) => void;
}> = ({ value, onChange }) => {
  const [sideSelection, setSideSelection] = React.useState<"all" | "oneSide">(
    "all",
  );

  return (
    <div className="flex w-full">
      <ModifierLabel label="Sides" className="pt-1.5" />
      <div className="flex flex-col gap-2 w-full">
        <ToggleGroup
          type="single"
          style={{ width: "100%" }}
          options={sidesOptions}
          onChange={(value) => setSideSelection(value as "all" | "oneSide")}
          value={sideSelection}
        />
        {sideSelection === "oneSide" && (
          <ToggleGroup
            type="single"
            style={{ width: "100%" }}
            options={sideOptions}
            onChange={onChange}
            value={value}
          />
        )}
      </div>
    </div>
  );
};

const BorderColorControl: React.FC<{
  activeSide: BorderSuffix | null;
}> = ({ activeSide }) => {
  const applyComponentAction = useApplyComponentAction();
  const modal = useModal();
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const value = useEditorSelector((state) => {
    return selectActiveBorderSideColor(state, activeSide);
  });
  const activeBorderSideWidth = useEditorSelector((state) => {
    return selectActiveBorderSideWidth(state, activeSide);
  });
  const activeBorderSideStyle = useEditorSelector((state) => {
    return selectActiveBorderSideStyle(state, activeSide);
  });
  const field = `style.border${activeSide}Color`;

  if (!draftComponentId) {
    return null;
  }

  const onChange = (value: string | null) => {
    const width = value ? activeBorderSideWidth || "1px" : null;
    const borderStyle = value ? activeBorderSideStyle || "solid" : "none";
    const styles = prepareBorderStyles(activeSide, width, value, borderStyle);

    applyComponentAction({
      type: "setStyles",
      value: styles,
    });
  };

  return (
    <div className="flex items-center">
      <ModifierLabel label="Color" />
      <DynamicColorSelector
        allowsGradientSelection={false}
        value={value ?? null}
        hasDynamicData
        field={field}
        popoverTitle="Border Color"
        onChange={onChange}
        openDynamicData={() => {
          modal.openModal({
            type: "dynamicDataModal",
            props: {
              requestType: "prop",
              targetType: DynamicDataTargetType.TEXT_COLOR,
              referrerData: {
                type: "callback",
                onChange: (value: string) => {
                  onChange(value);
                },
              },
              initialPath: value ? getPathFromVariable(value) : undefined,
            },
          });
        }}
        onRemove={() => onChange(null)}
        componentId={draftComponentId}
        popoverSideOffset={86}
      />
    </div>
  );
};

const BorderWidthControl: React.FC<{
  activeSide: BorderSuffix | null;
}> = ({ activeSide }) => {
  const applyComponentAction = useApplyComponentAction();
  const value = useEditorSelector((state) => {
    return selectActiveBorderSideWidth(state, activeSide);
  });
  const activeBorderSideColor = useEditorSelector((state) => {
    return selectActiveBorderSideColor(state, activeSide);
  });
  const activeBorderSideStyle = useEditorSelector((state) => {
    return selectActiveBorderSideStyle(state, activeSide);
  });

  const onChange = (value: string | null) => {
    const width = value === "reset" ? "" : value;
    const color = activeBorderSideColor || "#000";
    const borderStyle = value ? activeBorderSideStyle || "solid" : "none";
    const styles = prepareBorderStyles(
      activeSide,
      width ?? null,
      color,
      borderStyle,
    );

    applyComponentAction({
      type: "setStyles",
      value: styles,
    });
  };

  return (
    <LengthInputSelector
      label={<ModifierLabel label="Width" />}
      draggingType={DraggingTypes.Vertical}
      dragTrigger="label"
      minDragValues={{ px: 0 }}
      resetValue=""
      placeholder="auto"
      key={`border${activeSide}Width`}
      onChange={onChange}
      field={`style.border${activeSide}Width`}
      value={value}
      menuOptions={widthOptions}
      metrics={CSS_LENGTH_TYPES}
      previewProperty={`border${activeSide}Width` as PreviewableProperty}
    />
  );
};

const BorderStyleSelectable: React.FC<{
  activeSide: BorderSuffix | null;
}> = ({ activeSide }) => {
  const applyComponentAction = useApplyComponentAction();
  const value = useEditorSelector((state) => {
    return selectActiveBorderSideStyle(state, activeSide);
  });
  const selectableValue = value ?? "none";
  const activeBorderWidth = useEditorSelector((state) =>
    selectActiveBorderSideWidth(state, activeSide),
  );
  const activeBorderColor = useEditorSelector((state) =>
    selectActiveBorderSideColor(state, activeSide),
  );

  const borderColor = useEditorSelector(selectBorderColor);
  const borderWidth = useEditorSelector(selectBorderWidth);

  const previousBorderColor = usePrevious(borderColor);
  const previousBorderWidth = usePrevious(borderWidth);

  const handleChangeStyle = (value: string) => {
    if (value === "none") {
      applyComponentAction({
        type: "setStyles",
        value: {
          ...prepareBorderStyles(activeSide, "0px", null, value),
        },
      });
    } else {
      const borderColorToApply =
        activeBorderColor ??
        previousBorderColor?.[activeSide || "Top"] ??
        "#000000";
      const borderWidthToApply =
        activeBorderWidth ??
        previousBorderWidth?.[activeSide || "Top"] ??
        "1px";

      applyComponentAction({
        type: "setStyles",
        value: prepareBorderStyles(
          activeSide,
          String(borderWidthToApply),
          borderColorToApply,
          value,
        ),
      });
    }
  };

  return (
    <div className="flex items-center">
      <ModifierLabel label="Style" />
      <Selectable
        options={borderOptions}
        onSelect={handleChangeStyle}
        defaultValue="none"
        value={selectableValue}
        onRemove={
          selectableValue !== "none"
            ? () => {
                handleChangeStyle("none");
              }
            : undefined
        }
      />
    </div>
  );
};

const borderRadiusOptions = [
  {
    value: "single",
    label: <RxBox size={iconSize} />,
  },
  {
    value: "individual",
    label: <AiOutlineRadiusUpleft size={iconSize} />,
  },
];

const BorderRadiusControls: React.FC<{
  setIsExpandedBorderRadius: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedBorderRadius: boolean;
}> = ({ setIsExpandedBorderRadius, isExpandedBorderRadius }) => {
  const isRoot = useEditorSelector(selectedDraftComponentIsRoot);
  const applyComponentAction = useApplyComponentAction();
  const borderRadiusValues = useEditorSelector(selectBorderRadius);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-1">
        <BorderRadiusSingleControl isDisabled={isExpandedBorderRadius} />
        <ToggleGroup
          type="single"
          size="sm"
          options={borderRadiusOptions}
          value={isExpandedBorderRadius ? "individual" : "single"}
          onChange={(value) => {
            // NOTE (Sebas, 2024-10-01): In case we are switching from individual to single, we need to
            // set the same value for all corners.
            if (value === "single") {
              applyComponentAction({
                type: "setStyles",
                value: {
                  borderTopLeftRadius: borderRadiusValues[0],
                  borderTopRightRadius: borderRadiusValues[0],
                  borderBottomLeftRadius: borderRadiusValues[0],
                  borderBottomRightRadius: borderRadiusValues[0],
                },
              });
            }
            setIsExpandedBorderRadius(value === "individual");
          }}
        />
      </div>
      {!isRoot && isExpandedBorderRadius && <BorderRadiusIndividualControls />}
    </div>
  );
};

const BorderRadiusSingleControl: React.FC<{ isDisabled?: boolean }> = ({
  isDisabled,
}) => {
  const applyComponentAction = useApplyComponentAction();

  return (
    <LengthInputModifier
      isDisabled={isDisabled}
      placeholder="auto"
      // TODO (Noah, 2021-06-27): We use borderTopLeftRadius here because
      // this is the one that shows before you expand all the individual border
      // radii inputs. If we just use `borderRadius`, it gets reset since the
      // actual onchange sets the radii individually, as it should. However,
      // when the radii are open, this box doesn't make sense since its value
      // reflects only the top left radius. Maybe there's a better way to handle
      // this UX
      field="style.borderTopLeftRadius"
      draggingType={DraggingTypes.Vertical}
      allowsNegativeValue={false}
      minValues={{ px: 0 }}
      minDragValues={{ px: 0 }}
      resetValue="0px"
      label={<ModifierLabel label="Radius" />}
      dragTrigger="label"
      onChange={(newValue: string) => {
        applyComponentAction({
          type: "setStyles",
          value: {
            borderTopLeftRadius: newValue,
            borderTopRightRadius: newValue,
            borderBottomLeftRadius: newValue,
            borderBottomRightRadius: newValue,
          },
        });
      }}
      menuOptions={radiusOptions}
      metrics={CSS_LENGTH_TYPES}
      previewProperty="borderRadius"
    />
  );
};

const BorderRadiusIndividualControls: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  return (
    <div className="w-[178px] self-end grid grid-cols-2 grid-rows-2 gap-1">
      {borderRadiusIndividualOptions.map((option) => {
        return (
          <LengthInputModifier
            key={option.key}
            metrics={CSS_LENGTH_TYPES}
            placeholder="0"
            field={`style.${option.key}`}
            dragTrigger="label"
            draggingDirection={DraggingDirections.Positive}
            draggingType={DraggingTypes.Vertical}
            resetValue="0px"
            allowsNegativeValue={false}
            minValues={{ px: 0 }}
            label={
              <div className="mr-1">
                <AiOutlineRadiusUpleft
                  size={iconSize}
                  className={option.enhancerClassName}
                />
              </div>
            }
            minDragValues={{ px: 0 }}
            onChange={(newValue) => {
              applyComponentAction({
                type: "setStyles",
                value: {
                  [option.key]: newValue === "reset" ? null : newValue,
                },
              });
            }}
            previewProperty={option.key as PreviewableProperty}
          />
        );
      })}
    </div>
  );
};

export default BorderModifier;
