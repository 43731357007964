import type { FlowStepConfigPropsValueOf } from "schemas/flow";

import * as React from "react";

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import StepSubtitle from "@components/flows/onboardingSteps/components/StepSubtitle";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import SelectableButtons from "@editor/components/common/designSystem/SelectableButtons";
import { useGetCurrentStepResultsData } from "@editor/components/flows/hooks/useGetCurrentStepResultsData";
import useIdentifyOtherValue from "@editor/components/flows/hooks/useIdentifyOtherValue";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepsLayout from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";

import { Controller, useForm, useWatch } from "react-hook-form";
import { isEmpty } from "replo-utils/lib/misc";

type FormValues = {
  whatToDo: string[];
  whatToDoOther?: string;
};
// TODO (Gabe 2024-09-25): Need to update this step to match the design
const WhatDoYouWantToDoInReplo: React.FC = () => {
  const { currentStep, skipStep, submitStep } = useReploFlowsStepContext();
  const stepResultsData =
    useGetCurrentStepResultsData<"onboarding.user.what-do-you-want-to-do-in-replo">();
  const stepProps =
    currentStep?.props as FlowStepConfigPropsValueOf<"onboarding.user.what-do-you-want-to-do-in-replo">;

  const stepCommonOptions = stepProps.options.map((option) => option.value);
  const { otherValue: otherInitialValue, values: initialHeardFromValues } =
    useIdentifyOtherValue(stepCommonOptions, stepResultsData?.whatToDo);
  const { submitOrSkipStepCallback } = useRouterFlowCallbacks();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      whatToDo: initialHeardFromValues,
      whatToDoOther: otherInitialValue,
    },
  });

  const whatToDoValue = useWatch({ control, name: "whatToDo" });
  const whatToDoOtherValue = useWatch({ control, name: "whatToDoOther" });
  const isOtherSelected = whatToDoValue?.includes("other");

  const onSubmit = ({ whatToDo }: FormValues) => {
    if (currentStep) {
      if (currentStep?.isSkippable && isEmpty(whatToDo)) {
        skipStep(currentStep.id, currentStep.type, ({ instance, nextStep }) =>
          submitOrSkipStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          }),
        );
      } else {
        void submitStep(
          currentStep.id,
          currentStep.type,
          {
            whatToDo,
          },
          ({ instance, nextStep }) =>
            submitOrSkipStepCallback({
              nextStep: nextStep ?? null,
              flowSlug: instance.flow.slug,
            }),
        );
      }
    }
  };
  const whatToDoOtherError = errors.whatToDoOther?.message;

  const shouldDisableNextButton =
    !currentStep?.isSkippable ||
    (isOtherSelected &&
      (!Boolean(whatToDoOtherValue) || Boolean(whatToDoOtherError)));

  const newOptions: {
    value: string;
    label: string;
    subLabel: string;
  }[] = [
    {
      value: "build",
      label: "Build",
      subLabel:
        "Create high converting page using optimized templates and powerful AI tools",
    },
    {
      value: "measure",
      label: "Measure",
      subLabel: "Use Analytics to track page performance and measure success",
    },
    {
      value: "test",
      label: "Test",
      subLabel:
        "AB test your Replo & Shopify pages to optimize sections, pages, and funnels",
    },
    {
      value: "expert",
      label: "Hire an expert",
      subLabel: "Get matched with a vetted Replo Expert to do it all for you",
    },
  ];

  return (
    <OnboardingStepsLayout
      rightPanelContent={<StepImage src="/images/flows/grid.png" />}
    >
      <div className="flex flex-col gap-10">
        <div>
          <StepTitle>
            What are you looking to do with{" "}
            <span className="whitespace-nowrap">
              Replo?
              <span className="text-red-600">*</span>
            </span>
          </StepTitle>
          <StepSubtitle>Select all that apply.</StepSubtitle>
        </div>
        <form
          className="flex flex-col gap-10"
          onSubmit={(data) => {
            void handleSubmit(onSubmit)(data);
          }}
        >
          <Controller
            name="whatToDo"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectableButtons
                multiSelect
                options={newOptions}
                renderOptionChildren={(option) => (
                  <div className="flex flex-col items-start px-2 py-1 gap-1">
                    <div className="text-sm font-medium text-slate-800">
                      {option.label}
                    </div>
                    <div className="text-xs font-normal text-left text-slate-500">
                      {option.subLabel}
                    </div>
                  </div>
                )}
                value={value}
                onChange={(value) => onChange(value ?? "")}
                className="my-4 flex-col items-stretch"
                buttonClassName="w-full justify-start"
                textClassName="font-normal text-left"
                unselectedClassName="p-1.5 whitespace-break-spaces h-fit border border-slate-200 bg-transparent text-default hover:border-blue-600 hover:bg-blue-200 hover:text-blue-600"
                selectedClassName="p-1.5 whitespace-break-spaces h-fit border border-blue-600 bg-blue-200 text-blue-600 hover:bg-blue-300"
              />
            )}
          />
          <FlowActionButtons
            shouldDisableNextButton={shouldDisableNextButton}
          />
        </form>
      </div>
    </OnboardingStepsLayout>
  );
};

export default WhatDoYouWantToDoInReplo;
