import * as React from "react";

import StandardCustomPropControl from "@editor/components/editor/page/element-editor/components/config-menus/StandardCustomPropControl";
import { useCustomPropDefinitionsWithValues } from "@editor/components/editor/page/element-editor/components/config-menus/utils";
import { selectDraftComponent } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

const CarouselAccessibilityMenu: React.FC = () => {
  const customProps = useCustomPropDefinitionsWithValues();
  const draftComponent = useEditorSelector(selectDraftComponent);
  if (!draftComponent) {
    return null;
  }

  return (
    <div className="relative flex flex-col gap-4">
      <StandardCustomPropControl
        draftComponent={draftComponent}
        customProp={customProps.find(
          (customProp) => customProp.definition.id === "_name",
        )}
      />
    </div>
  );
};

export default CarouselAccessibilityMenu;
