import * as React from "react";

import { twMerge } from "tailwind-merge";

export const InfoTag = ({
  children,
  className,
}: React.HTMLProps<HTMLSpanElement>) => {
  return (
    <span
      className={twMerge(
        "px-2 py-1 rounded border border-black/10 font-medium bg-green-300",
        className,
      )}
    >
      {children}
    </span>
  );
};
