import type { EditorRootState } from "@editor/store";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  SharedStatePayload,
  SyncRuntimeStatePayload,
} from "replo-runtime/shared/types";

import { createSlice } from "@reduxjs/toolkit";
import { isFunction } from "replo-utils/lib/type-check";

export type PaintState = {
  sharedState: Record<string, any>;
  syncRuntimeState: Record<string, any>;
};

const initialState: PaintState = {
  sharedState: {},
  syncRuntimeState: {},
};

const paintSlice = createSlice({
  name: "paint",
  initialState,
  reducers: {
    setSharedState: (state, action: PayloadAction<SharedStatePayload>) => {
      const { key, value } = action.payload;
      const currentValue = state.sharedState[key];
      const next = isFunction(value) ? value(currentValue) : value;
      if (currentValue !== next) {
        state.sharedState[key] = next;
      }
    },
    setSyncRuntimeState: (
      state,
      action: PayloadAction<SyncRuntimeStatePayload>,
    ) => {
      const { key, value } = action.payload;
      if (state.syncRuntimeState[key] !== value) {
        state.syncRuntimeState[key] = value;
      }
    },
  },
});

export const selectSharedState = (state: EditorRootState) =>
  state.paint.sharedState;
export const selectSyncRuntimeState = (state: EditorRootState) =>
  state.paint.syncRuntimeState;

const { actions, reducer } = paintSlice;

export const { setSharedState, setSyncRuntimeState } = actions;
export default reducer;
