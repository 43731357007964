import type {
  ComponentTemplateBadgeId,
  ComponentTemplateCategoryType,
  ComponentTemplateIndustryId,
  ComponentTemplateSelectionId,
} from "schemas/componentTemplates";
import type { ComponentTemplateScope } from "schemas/generated/componentTemplates";

import * as React from "react";

import { selectProjectId } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";
import useCurrentUser from "@hooks/useCurrentUser";

import { getFindComponentTemplatesQuery } from "replo-runtime/shared/componentTemplates";

type Props = {
  searchString?: string;
  collectionId?: string;
  scope?: Omit<ComponentTemplateScope, "left-bar">;
  templateType: ComponentTemplateCategoryType | null;
  /**
   * If true, the component templates will include the component data. Used by the saved templates
   * list in the sidebar.
   */
  includeComponent: boolean;
  selectedCategories?: ComponentTemplateSelectionId[];
  selectedIndustries?: ComponentTemplateIndustryId[];
  selectedBadges?: ComponentTemplateBadgeId[];
};

/**
 * Hook to fetch and paginate component templates.
 */
export default function useInfiniteTemplates({
  searchString,
  collectionId,
  scope = "global",
  includeComponent,
  selectedIndustries,
  selectedBadges,
  selectedCategories,
  templateType,
}: Props) {
  const projectId = useEditorSelector(selectProjectId);
  const { user } = useCurrentUser();

  const queryParams = getFindComponentTemplatesQuery({
    selectedIndustries,
    selectedBadges,
    selectedCategories,
    projectId,
    userId: user?.id,
    componentCollectionId: collectionId,
    searchText:
      searchString && searchString.length > 0 ? searchString : undefined,
    scope,
    includeComponent,
    templateType,
  });

  const { data, isLoading, fetchNextPage, isSuccess } =
    trpc.componentTemplates.find.useInfiniteQuery(queryParams, {
      getNextPageParam: ({ nextSkip }) => (nextSkip ? nextSkip : 0),
    });

  const templatesFromServer = React.useMemo(() => {
    return data?.pages.map((page) => page.componentTemplates).flat();
  }, [data]);

  const pagesLength = data?.pages.length ?? 0;
  const pageData = data?.pages[pagesLength ? pagesLength - 1 : 0];
  const queryHasResults = pageData?.queryHasResults;
  const hasNextPage = Boolean(pageData?.nextSkip);

  return {
    fetchNextPage,
    componentTemplatesList:
      !isLoading && templatesFromServer ? templatesFromServer : null,
    isLoading,
    isLoadingInitialPage: !templatesFromServer,
    isSuccessComponentTemplates: isSuccess,
    hasNextPage,
    queryHasResults,
  };
}
