import type { ComboboxProps } from "@replo/design-system/components/shadcn/combobox/types";

import * as React from "react";

import { OptionsList } from "@replo/design-system/components/shadcn/combobox/components/OptionsList";
import { TriggerInput } from "@replo/design-system/components/shadcn/combobox/components/triggers/TriggerInput";
import { Command } from "@replo/design-system/components/shadcn/core/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@replo/design-system/components/shadcn/core/popover";
import classNames from "classnames";
import { useControllableState } from "replo-utils/react/use-controllable-state";

type ComboboxMultiSelectProps = Pick<
  ComboboxProps,
  | "options"
  | "defaultValue"
  | "value"
  | "onChange"
  | "open"
  | "onOpenChange"
  | "input"
  | "onInputChange"
  | "inputPlaceholder"
>;

export function ComboboxMultiSelect({
  options,
  defaultValue,
  value: controlledValue,
  onChange: onControlledChange,
  open: controlledOpen,
  onOpenChange: onControlledOpenChange,
  input: inputValue,
  onInputChange: onControlledInputChange,
  inputPlaceholder,
}: ComboboxMultiSelectProps) {
  const [open, setOpen] = useControllableState(
    controlledOpen,
    false,
    onControlledOpenChange,
  );
  const [value, setValue] = useControllableState(
    controlledValue,
    defaultValue ?? "",
    onControlledChange,
  );

  const [input, setInput] = useControllableState(
    inputValue,
    "",
    onControlledInputChange,
  );

  return (
    <Command>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger>
          <TriggerInput
            input={input}
            setInput={setInput}
            inputPlaceholder={inputPlaceholder}
          />
        </PopoverTrigger>
        <PopoverContent
          className={classNames("w-full p-0 max-w-[550px]", {
            hidden: !open,
          })}
          style={{ minWidth: "var(--radix-popper-anchor-width)" }}
          forceMount={true}
        >
          <OptionsList
            options={options}
            setValue={setValue}
            setOpen={setOpen}
            value={value}
          />
        </PopoverContent>
      </Popover>
    </Command>
  );
}
