import type { TRPCClientErrorLike } from "@trpc/client";
import type {
  UseTRPCMutationResult,
  UseTRPCQueryResult,
} from "@trpc/react-query/dist/shared";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import type { AppRouter } from "replo-publisher/src/trpc/root";

import * as React from "react";

import { toast } from "@editor/components/common/designSystem/Toast";
import { getTokenFromStorage } from "@editor/infra/auth";
import { getErrorDetails } from "@editor/reducers/error-reducer";
import { handleErrorDetails } from "@editor/reducers/modals-reducer";

import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { httpLink, TRPCClientError } from "@trpc/client";
import {
  createTRPCClient,
  createTRPCQueryUtils,
  createTRPCReact,
} from "@trpc/react-query";
import { getPublisherUrl } from "replo-runtime/shared/config";
import { errorUserFacingDetailsSchema } from "schemas/errors";
import superjson from "superjson";

/*
 * NOTE Ben 2024-04-22: These types allow us to add types when using trpc procedures in
 * RTK for the time being, because there are places where we're using `createApi` for example
 * to hold some complex logic. In time, will no longer need this. One thing it's useful for is
 * adding types to createApi so we can more incrementally add types while we replace RTK.
 *
 * Ene example of how to use it:
 *
 * ```
 * list: builder.query<
 *    TrpcQueryReturn<"experiment", "list">,
 *    TrpcRouterArgs<"experiment", "list">
 *  >
 * ```
 *
 * Another thing these types are useful for is explicity typing args for procedures. Eg:
 *
 * type CreateExperimentArgs = AppRouterQueryArgs["experiment"]["create"];
 *
 */
export type AppRouterOutputs = inferRouterOutputs<AppRouter>;
export type AppRouterQueryArgs = inferRouterInputs<AppRouter>;
type AppRouterKey = keyof AppRouterOutputs;
type AppRouterQueryCallKey<RouterName extends AppRouterKey> =
  keyof AppRouterOutputs[RouterName];
type AppRouterQueryArgsKey = keyof AppRouterQueryArgs;
type AppRouterQueryName<RouterName extends AppRouterQueryArgsKey> =
  keyof AppRouterQueryArgs[RouterName];

export type TrpcQueryReturn<
  RouterName extends AppRouterKey,
  QueryName extends AppRouterQueryCallKey<RouterName>,
> = NonNullable<
  UseTRPCQueryResult<
    AppRouterOutputs[RouterName][QueryName],
    TRPCClientErrorLike<AppRouter>
  >["data"]
>;

export type TrpcMutationReturn<
  RouterName extends AppRouterKey,
  MutationName extends AppRouterQueryCallKey<RouterName>,
> = NonNullable<
  UseTRPCMutationResult<
    AppRouterOutputs[RouterName][MutationName],
    TRPCClientErrorLike<AppRouter>,
    AppRouterQueryArgs[RouterName][MutationName],
    unknown
  >["data"]
>;

export type TrpcRouterArgs<
  RouterName extends AppRouterQueryArgsKey,
  ProcedureName extends AppRouterQueryName<RouterName>,
> = AppRouterQueryArgs[RouterName][ProcedureName];

export const transformer = superjson;

export const trpc = createTRPCReact<AppRouter>();

/**
 * Trpc client for raw-use, usually outside of React, or in RTK.
 */
export const trpcClient = createTRPCClient<AppRouter>({
  links: [
    httpLink({
      url: `${getPublisherUrl()}/api/v1/trpc`,
      headers: () => ({
        "Cache-Control": "no-cache",
        Authorization: `Token ${getTokenFromStorage()}`,
      }),
      transformer,
    }),
  ],
});

export function handleTRPCClientError(error: Error) {
  if (error instanceof TRPCClientError) {
    const result = errorUserFacingDetailsSchema.safeParse(error.data);
    const details = getErrorDetails({
      type: "trpcError",
      userFacingDetails: result.success ? result.data : undefined,
    });
    if (details && details.type === "toast") {
      toast({ type: "error", ...details.errorDetails });
    }
    if (details && details.type === "fullPageModal") {
      // Note (Noah, 2024-05-27): We need to import the store dynamically to avoid a circular dependency,
      // since core-reducer imports this file in order to invalidate queries
      void import("@editor/store").then((module) => {
        module.store.dispatch(handleErrorDetails({ details }));
      });
    }
  }
}

/**
 * Client used for all queries, and gives us some sane default options. We set `staleTime` to
 * infinity so we only only refetch when we need to by default - we can override when we need to.
 */
export const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Number.POSITIVE_INFINITY,
    },
  },
  mutationCache: new MutationCache({
    onError: (error, _, __, mutation) => {
      if (mutation.meta?.reploIgnoreDefaultErrorHandling) {
        // If the caller specified that they don't want default error handling,
        // we won't do anything. This is useful for endpoints which return 400s
        // or something where the useQuery wants to catch and handle the error
        // directly instead of having a toast
        return;
      }
      handleTRPCClientError(error);
    },
  }),
  // Note (Noah, 2024-05-27): This is how you define a global error handler in TRPC, see
  // https://tkdodo.eu/blog/react-query-error-handling#the-global-callbacks
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta?.reploIgnoreDefaultErrorHandling) {
        // If the caller specified that they don't want default error handling,
        // we won't do anything. This is useful for endpoints which return 400s
        // or something where the useQuery wants to catch and handle the error
        // directly instead of having a toast
        return;
      }
      handleTRPCClientError(error);
    },
  }),
});

/**
 * Trpc client for use inside react, used in provider.
 */
const reactClient = trpc.createClient({
  links: [
    httpLink({
      url: `${getPublisherUrl()}/api/v1/trpc`,
      headers: () => ({
        "Cache-Control": "no-cache",
        Authorization: `Token ${getTokenFromStorage()}`,
      }),
      transformer,
    }),
  ],
});

export const trpcUtils = createTRPCQueryUtils({
  queryClient,
  client: trpcClient,
});

export function TRPCProvider({ children }: React.PropsWithChildren) {
  return (
    <trpc.Provider client={reactClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
