import type { QueryRange, QueryRanges } from "schemas/analyticsRead";

import { generateRange } from "@editor/utils/analytics";

import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  setDate,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";
import {
  convertDaysToMs,
  convertTimestampToUTC,
  dateRangeFormatter,
} from "replo-utils/lib/datetime";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

export type MainRangePredefinedTimeframe =
  | "today"
  | "yesterday"
  | "last-7-days"
  | "last-14-days"
  | "last-30-days"
  | "last-90-days"
  | "last-180-days"
  | "last-week"
  | "week-to-date"
  | "last-month"
  | "month-to-date"
  | "year-to-date"
  | "all-time";

// TODO (Max, 2024-09-17): We'll later add e.g 'no-comparison'
export type CompareRangePredefinedPeriod = "previous-period";

export const MAIN_RANGE_PREDEFINED_OPTIONS: Array<{
  value: MainRangePredefinedTimeframe;
  label: string;
}> = [
  { value: "today", label: "Today" },
  { value: "yesterday", label: "Yesterday" },
  { value: "last-7-days", label: "Last 7 days" },
  { value: "last-14-days", label: "Last 14 days" },
  { value: "last-30-days", label: "Last 30 days" },
  { value: "last-90-days", label: "Last 90 days" },
  { value: "last-180-days", label: "Last 180 days" },
  { value: "last-week", label: "Last week" },
  { value: "week-to-date", label: "Week to date" },
  { value: "last-month", label: "Last month" },
  { value: "month-to-date", label: "Month to date" },
  { value: "year-to-date", label: "Year to date" },
  { value: "all-time", label: "All time" },
];

export const COMPARE_RANGE_PREDEFINED_OPTIONS: Array<{
  value: CompareRangePredefinedPeriod;
  label: string;
}> = [{ value: "previous-period", label: "Previous period" }];

export const DEFAULT_MAIN_RANGE_TIMEFRAME: MainRangePredefinedTimeframe =
  "week-to-date";

export const DEFAULT_COMPARE_RANGE_PERIOD: CompareRangePredefinedPeriod =
  "previous-period";

export const COMPARE_PREVIOUS_PERIOD = "previous-period";

/**
 * Given the startDatetime and endDatetime from the main range,
 * this returns the startDatetime and endDatetime for the default
 * compareAt range (i.e. the previous continuous range).
 *
 * @author Max 2024-09-16
 */
export function calculatePreviousPeriodDatetimes(
  mainStartDatetime: number,
  mainEndDatetime: number,
) {
  const interval = mainEndDatetime - mainStartDatetime;
  return {
    compareStartDatetime: mainStartDatetime - interval,
    compareEndDatetime: mainEndDatetime - interval,
    compareInterval: interval,
  };
}

export function calculateCompareRangePredefined(
  predefinedPeriod: CompareRangePredefinedPeriod,
  mainRange: QueryRange,
) {
  const compareAtRange = exhaustiveSwitch({
    type: predefinedPeriod,
  })({
    "previous-period": () => {
      const { startDatetime: mainStartDatetime, endDatetime: mainEndDatetime } =
        mainRange;

      const { compareStartDatetime, compareEndDatetime, compareInterval } =
        calculatePreviousPeriodDatetimes(mainStartDatetime, mainEndDatetime);

      return generateRange(
        compareStartDatetime,
        compareEndDatetime,
        compareInterval,
      );
    },
  });

  return {
    range: compareAtRange,
    selectedPeriod: predefinedPeriod,
  };
}

/**
 * Given a "from" & "to" selected by a custom date picker, it returns the associated
 * range. This function can be used both for the main range (custom timeframe), and the
 * compareAt range (custom period).
 *
 * @author Max 2024-09-16
 */
export function calculateCustomRange(
  customRangeFrom: Date,
  customRangeTo: Date,
) {
  const startDatetime = startOfDay(customRangeFrom).valueOf();

  /**
   * NOTE (Max, 2024-09-16): Adding + 1 to round the endOfDay (23:59)
   * to the next 1ms, so that the interval is dividable by 10
   */
  const endDatetime = endOfDay(customRangeTo).valueOf() + 1;
  const interval = endDatetime - startDatetime;

  const currentDate = new Date();

  const range = generateRange(
    convertTimestampToUTC(startDatetime, currentDate),
    convertTimestampToUTC(endDatetime, currentDate),
    interval,
  );

  const selectedPeriod = dateRangeFormatter({
    from: customRangeFrom,
    to: customRangeTo,
  });

  return {
    range,
    selectedPeriod,
  };
}

/**
 * Given the "from" & "to" selected custom range, it returns
 * the main range, starting at the startOfDay of the "from", and
 * at the endOfDay of "to". For the compareAtRange, it uses the
 * default previous continuous range.
 *
 * Datetimes are in UTC.
 *
 * @author Max 2024-09-16
 */
export function calculateQueryRangesForCustomTimeframe(
  customRangeFrom: Date,
  customRangeTo: Date,
) {
  const { range: mainRange } = calculateCustomRange(
    customRangeFrom,
    customRangeTo,
  );

  const { compareStartDatetime, compareEndDatetime, compareInterval } =
    calculatePreviousPeriodDatetimes(
      mainRange.startDatetime,
      mainRange.endDatetime,
    );

  const compareAtRange = generateRange(
    compareStartDatetime,
    compareEndDatetime,
    compareInterval,
  );

  const updatedRanges = {
    mainRange,
    compareAtRanges: [compareAtRange],
  };

  const selectedTimeframe = dateRangeFormatter({
    from: customRangeFrom,
    to: customRangeTo,
  });

  return {
    updatedRanges,
    selectedTimeframe,
  };
}

/**
 * Given a predefined timeframe ("last-7-days", "yesterday", returned the
 * updatedRanges, consisting of mainRange and compareAtRange)
 *
 * @author Max 2024-09-16
 */
export function calculateQueryRangesForPredefinedTimeframe(
  predefinedTimeframe: MainRangePredefinedTimeframe,
): { updatedRanges: QueryRanges; selectedTimeframe: string } {
  const now = new Date();
  const endOfToday = endOfDay(now);

  // NOTE (Max, 2024-09-15): We're treating "all-time" separately as it's an edge-case: it doesn't have
  // a compareAt range, and the startDatetime is 0, so we don't want to do the conversion to UTC like
  // we do for other default timeframe options.
  if (predefinedTimeframe === "all-time") {
    const mainEndDatetime = endOfToday.valueOf() + 1;
    const mainEndDatetimeUTC = convertTimestampToUTC(
      mainEndDatetime,
      new Date(),
    );

    const mainRange = generateRange(0, mainEndDatetimeUTC, mainEndDatetimeUTC);
    const updatedRanges = {
      mainRange,
      compareAtRanges: [],
    };

    return { updatedRanges, selectedTimeframe: predefinedTimeframe };
  } else {
    const endOfYesterday = endOfDay(subDays(now, 1));
    const lastWeek = subWeeks(now, 1);
    const lastMonth = subMonths(now, 1);

    const {
      mainStartDatetime,
      mainEndDatetime,
      compareStartDatetime,
      compareEndDatetime,
    } = exhaustiveSwitch({ type: predefinedTimeframe })({
      today: () => {
        const mainStartDatetime = startOfDay(now).valueOf();
        const mainEndDatetime = endOfToday.valueOf();

        const { compareStartDatetime, compareEndDatetime } =
          calculatePreviousPeriodDatetimes(mainStartDatetime, mainEndDatetime);

        return {
          mainStartDatetime,
          mainEndDatetime,
          compareStartDatetime,
          compareEndDatetime,
        };
      },
      yesterday: () => {
        const mainStartDatetime = startOfDay(subDays(now, 1)).valueOf();
        const mainEndDatetime = endOfYesterday.valueOf();

        const { compareStartDatetime, compareEndDatetime } =
          calculatePreviousPeriodDatetimes(mainStartDatetime, mainEndDatetime);

        return {
          mainStartDatetime,
          mainEndDatetime,
          compareStartDatetime,
          compareEndDatetime,
        };
      },
      "last-7-days": () => {
        const mainStartDatetime = startOfDay(subDays(now, 7)).valueOf();
        const mainEndDatetime = endOfYesterday.valueOf();

        const { compareStartDatetime, compareEndDatetime } =
          calculatePreviousPeriodDatetimes(mainStartDatetime, mainEndDatetime);

        return {
          mainStartDatetime,
          mainEndDatetime,
          compareStartDatetime,
          compareEndDatetime,
        };
      },
      "last-14-days": () => {
        const mainStartDatetime = startOfDay(subDays(now, 14)).valueOf();
        const mainEndDatetime = endOfYesterday.valueOf();

        const { compareStartDatetime, compareEndDatetime } =
          calculatePreviousPeriodDatetimes(mainStartDatetime, mainEndDatetime);

        return {
          mainStartDatetime,
          mainEndDatetime,
          compareStartDatetime,
          compareEndDatetime,
        };
      },
      "last-30-days": () => {
        const mainStartDatetime = startOfDay(subDays(now, 30)).valueOf();
        const mainEndDatetime = endOfYesterday.valueOf();

        const { compareStartDatetime, compareEndDatetime } =
          calculatePreviousPeriodDatetimes(mainStartDatetime, mainEndDatetime);

        return {
          mainStartDatetime,
          mainEndDatetime,
          compareStartDatetime,
          compareEndDatetime,
        };
      },
      "last-90-days": () => {
        const mainStartDatetime = startOfDay(subDays(now, 90)).valueOf();
        const mainEndDatetime = endOfYesterday.valueOf();

        const { compareStartDatetime, compareEndDatetime } =
          calculatePreviousPeriodDatetimes(mainStartDatetime, mainEndDatetime);

        return {
          mainStartDatetime,
          mainEndDatetime,
          compareStartDatetime,
          compareEndDatetime,
        };
      },
      "last-180-days": () => {
        const mainStartDatetime = startOfDay(subDays(now, 180)).valueOf();
        const mainEndDatetime = endOfYesterday.valueOf();

        const { compareStartDatetime, compareEndDatetime } =
          calculatePreviousPeriodDatetimes(mainStartDatetime, mainEndDatetime);

        return {
          mainStartDatetime,
          mainEndDatetime,
          compareStartDatetime,
          compareEndDatetime,
        };
      },
      "last-week": () => {
        const mainStartDatetime = startOfWeek(lastWeek, {
          weekStartsOn: 1,
        }).valueOf();
        const mainEndDatetime = endOfWeek(lastWeek, {
          weekStartsOn: 1,
        }).valueOf();

        const weekBeforeLastWeek = subWeeks(lastWeek, 1);
        const compareStartDatetime = startOfWeek(weekBeforeLastWeek, {
          weekStartsOn: 1,
        }).valueOf();
        const compareEndDatetime = endOfWeek(weekBeforeLastWeek, {
          weekStartsOn: 1,
        }).valueOf();

        return {
          mainStartDatetime,
          mainEndDatetime,
          compareStartDatetime,
          compareEndDatetime,
        };
      },
      "week-to-date": () => {
        const mainStartDatetime = startOfWeek(now, {
          weekStartsOn: 1,
        }).valueOf();
        const mainEndDatetime = endOfToday.valueOf();

        const compareStartDatetime = mainStartDatetime - convertDaysToMs(7);
        const compareEndDatetime = mainEndDatetime - convertDaysToMs(7);

        return {
          mainStartDatetime,
          mainEndDatetime,
          compareStartDatetime,
          compareEndDatetime,
        };
      },
      "last-month": () => {
        const mainStartDatetime = startOfMonth(lastMonth).valueOf();
        const mainEndDatetime = endOfMonth(lastMonth).valueOf();

        const monthBeforeLastMonth = subMonths(lastMonth, 1);

        const compareStartDatetime =
          startOfMonth(monthBeforeLastMonth).valueOf();
        const compareEndDatetime = endOfMonth(monthBeforeLastMonth).valueOf();

        return {
          mainStartDatetime,
          mainEndDatetime,
          compareStartDatetime,
          compareEndDatetime,
        };
      },
      "month-to-date": () => {
        const mainStartDatetime = startOfMonth(now).valueOf();
        const mainEndDatetime = endOfToday.valueOf();

        const compareStartDatetime = startOfMonth(lastMonth).valueOf();
        const compareEndDatetime = endOfDay(
          setDate(lastMonth, now.getDate()).valueOf(),
        ).valueOf();

        return {
          mainStartDatetime,
          mainEndDatetime,
          compareStartDatetime,
          compareEndDatetime,
        };
      },
      "year-to-date": () => {
        const mainStartDatetime = startOfYear(now).valueOf();
        const mainEndDatetime = endOfToday.valueOf();

        const lastYear = subYears(now, 1);

        const compareStartDatetime = startOfYear(lastYear).valueOf();
        const compareEndDatetime = endOfDay(
          setDate(lastYear, now.getDate()),
        ).valueOf();

        return {
          mainStartDatetime,
          mainEndDatetime,
          compareStartDatetime,
          compareEndDatetime,
        };
      },
    });

    const currentDate = new Date();

    /**
     * NOTE (Max, 2024-09-15): We're adding + 1 to the endDatetimes because
     * we obtain them using e.g. endOfToday(), which will return the datetime
     * at 23:59 (1 ms before the next day). This + 1 rounds the endDatetimes
     * to have a even interval (which has to be a dividable by 10)
     */
    const adjustedMainEndDatetime = mainEndDatetime + 1;
    const adjustedCompareEndDatetime = compareEndDatetime + 1;

    const mainInterval = adjustedMainEndDatetime - mainStartDatetime;
    const compareInterval = adjustedCompareEndDatetime - compareStartDatetime;

    const mainRange = generateRange(
      convertTimestampToUTC(mainStartDatetime, currentDate),
      convertTimestampToUTC(adjustedMainEndDatetime, currentDate),
      mainInterval,
    );

    const compareAtRange = generateRange(
      convertTimestampToUTC(compareStartDatetime, currentDate),
      convertTimestampToUTC(adjustedCompareEndDatetime, currentDate),
      compareInterval,
    );

    const updatedRanges = {
      mainRange,
      compareAtRanges: [compareAtRange],
    };

    return {
      updatedRanges,
      selectedTimeframe: predefinedTimeframe,
    };
  }
}

export function getRangeInDays(range: QueryRange) {
  const start = new Date(range.startDatetime);
  const end = new Date(range.endDatetime);
  return Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
}
