import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { getDefaultStyleRulesIfEmpty } from "../../../shared/utils/breakpoints";
import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

function getConfigurableProps(): CustomPropsRecord {
  return {
    _product: {
      name: "Product",
      type: "product",
      description: "Shopify product which should be displayed.",
      defaultValue: null,
    },
    _autoSelectVariant: {
      name: "Auto Select Product Variant",
      type: "boolean",
      description: "Automatically select a variant",
      defaultValue: true,
    },
    _defaultSelectedVariantId: {
      name: "Default Product Variant",
      type: "product_variant",
      description:
        "This variant will be automatically selected for variant lists, dropdowns, etc",
      defaultValue: null,
      selectableValues: {
        type: "product",
      },
    },
    _defaultSelectedSellingPlanId: {
      name: "Default Selling Plan",
      type: "productSellingPlan",
      description:
        "If the product has selling plans, this selling plan will be automatically selected for selling plan lists, dropdowns, etc",
      defaultValue: null,
    },
    _defaultQuantity: {
      name: "Initially selected Quantity",
      type: "integer",
      description:
        "If specified, this quantity will be automatically selected for quantity selectors",
      defaultValue: 1,
      min: 1,
    },
    // NOTE (Matt 2024-03-20, USE-804): This paramter helps when third party apps (like Appstle Subscriptions)
    // assign an incorrect variantId to the url. We default to true, but if a user is having issues
    // like in USE 804, they can update it.
    _useVariantUrlParameter: {
      name: "Use variant from URL",
      type: "boolean",
      description:
        "If there is an assigned default variant ID in the URL, use it as the default variant.",
      defaultValue: true,
    },
  };
}

// Note (Chance, 2023-05-12): The `styleElements` export is not typed as
// `StyleElements`, but instead should use `satisfies`. This ensures that the
// definition doesn't deviate from the type, while giving us more specific type
// information when we use it.
//
// TODO: We should document this somewhere other than this file since it'll be
// used across all component config files.
// https://linear.app/replo/issue/REPL-7398/document-types-and-updated-api-for-component-config
export const styleElements = {
  root: {
    defaultStyles: {
      display: "flex",
    },
    overrideStyles: ({ component, styleProps }) => {
      return getDefaultStyleRulesIfEmpty(component, styleProps);
    },
  },
} satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    allowsLayoutModification: true,
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    canContainChildren: true,
    showComponentControlsFromChildren: true,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
