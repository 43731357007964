import * as React from "react";

import { Screen, ScreenData } from "@components/account/common";
import { FullScreenLoader } from "@editor/components/common/FullScreenLoader";
import { ReploLogoBlack } from "@svg/replo-logo-black";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

/**
 * We persist these between SIGNUP/LOGIN routes so that when someone installs the app
 * but already has an account, we can link the shopify URL to the correct account.
 *
 * Probably the right way to do this is to switch to react-router or equivalent
 */
const getQueryDefaultsFromLocation = (searchParams: URLSearchParams) => {
  const query: Record<string, string> = {};
  for (const key of [
    "email",
    "first_name",
    "last_name",
    "shopify_shop_url",
    "nonce",
    "next",
  ]) {
    const value = searchParams.get(key);
    if (value) {
      query[key] = value;
    }
  }
  return query;
};

const AuthLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const renderData = ScreenData[location.pathname ?? ""];
  const [searchParams] = useSearchParams();

  if (!Object.values(Screen).includes(location.pathname)) {
    return <FullScreenLoader />;
  }

  return (
    <div className="flex flex-col lg:flex-row justify-center items-center no-scrollbar max-w-7xl mx-auto md:py-10 lg:h-screen lg:w-screen w-full lg:gap-10 gap-0 px-4 lg:px-0">
      <div className="order-2 lg:order-1 w-full lg:w-1/2 flex flex-col justify-center items-center lg:items-end max-w-[400px] lg:max-w-[600px] lg:pl-8 h-full">
        <div className="flex flex-col justify-center gap-8 sm:h-full w-full">
          <FeaturedCaseStudy />
          <div className="py-1 w-full bg-gray-50 rounded-full border border-slate-200 flex justify-center items-center gap-2">
            <div className="text-slate-600 text-xs text-center px-2">
              Over 5000+ companies use Replo to improve ecommerce conversion
              rates
            </div>
          </div>
          <Logos />
        </div>
      </div>
      <div className="order-1 lg:order-2 w-full lg:w-1/2 flex justify-center items-center my-4 lg:my-0">
        <div className="sm:max-w-[400px] w-full sm:h-full mx-auto md:mx-0 gap-10 flex flex-col md:justify-center items-start">
          <div className="gap-4 flex-col flex">
            <ReploLogoBlack className="h-8 w-28" />
            <div className="text-3xl font-bold">{renderData?.header}</div>
          </div>
          <div className="w-full flex flex-col gap-3">
            {children}
            <div className="text-sm text-slate-500">
              {renderData?.footerText}
              <span
                className="cursor-pointer pl-2 text-blue-600"
                onClick={() => {
                  const query = getQueryDefaultsFromLocation(searchParams);
                  if (renderData?.footerCtaRedirectTo) {
                    // Note (Sebas, 2023-06-30): This is the place where we set the next
                    // parameter for the login/signup flow to prevent losing the value.
                    navigate(
                      // Note (Noah, 2024-02-14): Preserve query strings in this navigate call, since
                      // we have query params like shopifyShopURL and pendingInstallationId we need to
                      // preserve in order to make the claim flow work if they just came from installing
                      // the shopify integration
                      {
                        pathname: renderData.footerCtaRedirectTo,
                        search: searchParams.toString(),
                      },
                      {
                        state: { next: query.next ?? null },
                      },
                    );
                  }
                }}
              >
                {renderData?.footerCtaText}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LOGOS = [
  {
    src: "/images/customers/caraway.png",
    alt: "Caraway",
    width: 105,
    height: 48,
  },
  {
    src: "/images/customers/vayner.png",
    alt: "Vayner Media",
    width: 120,
    height: 48,
  },
  {
    src: "/images/customers/studs.png",
    alt: "Studs",
    width: 105,
    height: 48,
  },
  {
    src: "/images/customers/supply.png",
    alt: "Supply",
    width: 105,
    height: 48,
  },
  {
    src: "/images/customers/jones-road.png",
    alt: "Jones Road",
    width: 105,
    height: 48,
  },
  {
    src: "/images/customers/hexclad.png",
    alt: "HexClad",
    width: 105,
    height: 48,
  },
];

const Logos = () => {
  return (
    <div className="mx-auto grid grid-cols-3 sm:grid-cols-6 gap-6 max-w-lg sm:max-w-xl lg:max-w-none justify-items-center items-center">
      {LOGOS.map((logo, index) => (
        <div key={index} className="max-h-12 w-full object-contain">
          <img
            src={logo.src}
            alt={logo.alt}
            width={logo.width}
            height={logo.height}
          />
        </div>
      ))}
    </div>
  );
};

const FeaturedCaseStudy = () => {
  return (
    <div
      className="hidden lg:block relative overflow-hidden rounded-xl h-full pt-5 max-h-[700px] bg-cover bg-center"
      style={{
        backgroundImage: "url('/images/jot.png')",
      }}
    >
      <div className="absolute bottom-0 left-0 p-8 bg-gradient-to-t from-black via-transparent to-transparent w-full">
        <img className="h-10 mb-3" src="/images/logos/jot.svg" alt="Jot logo" />
        <h2 className="text-white text-xl font-semibold max-w-[400px]">
          Jot Coffee sold millions and boosted their conversion rate by 20% with
          Replo
        </h2>
      </div>
    </div>
  );
};

export default AuthLayout;
