import type { StyleSheet } from "jss";
import type { Component } from "replo-runtime/shared/Component";
import type { ComponentStyleRulesMapping } from "replo-runtime/shared/styles";

import jss from "jss";

/**
 * Class which manages inserting component style rules. Wrapper around jss's
 * APIs, which need to be carefully managed so that we always deatch stylesheets
 * when they're no longer needed in order to not leak memory.
 */
class StyleRulesManager {
  documentKeyToComponentStylesheetsMapping: Record<
    string,
    Record<Component["id"], StyleSheet[]>
  > = {};

  /**
   * Destroy (and allow to be garbage collected) all stylesheets for a given
   * document key and component id.
   * @param documentKey Unique key for the document the stylesheets were added
   * to (e.g. canvas key)
   * @param componentId Component id to remove stylesheets for
   */
  destroyStylesheets(documentKey: string, componentId: string) {
    const componentIdToInsertedStylesheets =
      this.documentKeyToComponentStylesheetsMapping[documentKey];
    if (
      componentIdToInsertedStylesheets &&
      componentIdToInsertedStylesheets[componentId]
    ) {
      componentIdToInsertedStylesheets[componentId]!.forEach((sheet) => {
        jss.removeStyleSheet(sheet);
      });
      componentIdToInsertedStylesheets[componentId] = [];
    }
  }

  /**
   * Attach a stylesheet to the DOM
   *
   * @param documentKey Unique key for the document which the stylesheet was
   * created with (e.g. canvas key)
   * @param componentId Id of component this stylesheet is for
   * @param stylesheet Stylesheet to attach
   */
  attachStylesheet(
    documentKey: string,
    componentId: string,
    stylesheet: StyleSheet,
  ) {
    let componentIdToInsertedStylesheets =
      this.documentKeyToComponentStylesheetsMapping[documentKey];
    if (!componentIdToInsertedStylesheets) {
      const newMapping: Record<Component["id"], StyleSheet[]> = {};
      this.documentKeyToComponentStylesheetsMapping[documentKey] = newMapping;
      componentIdToInsertedStylesheets = newMapping;
    }
    stylesheet.attach();
    if (!componentIdToInsertedStylesheets[componentId]) {
      componentIdToInsertedStylesheets[componentId] = [];
    }
    componentIdToInsertedStylesheets[componentId]?.push(stylesheet);
  }
}

const reploEditorStyleRulesManager = new StyleRulesManager();

export function injectStyleElementIntoDocument({
  document,
  documentKey,
  componentId,
  rules,
}: {
  document: Document;
  documentKey: string;
  componentId: string;
  rules: ComponentStyleRulesMapping;
}) {
  const styleElement = document.createElement("style");
  styleElement.dataset.reploEditorComponent = componentId;
  // NOTE Ben 2024-05-01: Need to specify this or runtime won't build correctly
  // in edgeserver.
  document.head.append(styleElement as any);

  reploEditorStyleRulesManager.destroyStylesheets(documentKey, componentId);

  // Note (Noah, 2023-07-11): We don't pass the `link` attribute here because we don't
  // use addRule/replaceRule/deleteRule due to issues with managing/replacing JSS stylesheets
  // (see e.g. REPL-7896, REPL-7898, REPL-7771, REPL-7806). If we ever want to use these
  // APIs, we'll need to pass `link: true` here
  const newSheet = jss.createStyleSheet({}, { element: styleElement });
  newSheet.addRules(rules);
  reploEditorStyleRulesManager.attachStylesheet(
    documentKey,
    componentId,
    newSheet,
  );
}
