import type { ReploShopifyProduct } from "replo-runtime/shared/types";
import type { ProductResolutionDependencies } from "replo-runtime/store/ReploProduct";

import { getProduct } from "replo-runtime/store/ReploProduct";

export type { DataTable } from "schemas/generated/element";

export const DataTableColumnTypes = [
  "product",
  "text",
  "color",
  "image",
  "imageArray",
  "plainText",
] as const;
export type DataTableColumnType = (typeof DataTableColumnTypes)[number];

export type DataTableColumn = {
  id: string;
  type: DataTableColumnType;
  name: string;
};
export type DataTableRow = { [schemaId: string]: any };

export const defaultValueMapping: Record<DataTableColumnType, any> = {
  text: { text: "" },
  product: { productId: "", variantId: "" },
  color: { type: "", color: "" },
  image: { imageSource: "" },
  imageArray: { value: [] },
  plainText: { text: "" },
};

export const dataTableColumnToEvaluator: Record<
  DataTableColumnType,
  (
    value: any,
    productResolutionDependencies: ProductResolutionDependencies,
  ) => any
> = {
  product: (
    productRef,
    productResolutionDependencies,
  ): ReploShopifyProduct | null => {
    return getProduct(productRef, null, {
      productMetafieldValues: {},
      variantMetafieldValues: {},
      products: productResolutionDependencies.products,
      currencyCode: productResolutionDependencies.currencyCode,
      moneyFormat: productResolutionDependencies.moneyFormat,
      language: productResolutionDependencies.language,
      templateProduct: productResolutionDependencies.templateProduct,
    });
  },
  text: (textValue: { text: string }) => textValue.text,
  plainText: (textValue: { text: string }) => textValue.text,
  image: (imageValue: { imageSource: string }) => imageValue.imageSource,
  color: (colorValue: { color: string }) => colorValue.color,
  imageArray: (imageValue: { value: { imageSource: string }[] }) => {
    return (imageValue.value ?? []).map((v) => v.imageSource);
  },
};
