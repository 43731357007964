import * as React from "react";

import Tooltip from "@common/designSystem/Tooltip";
import DynamicData from "@svg/dynamic-data";

type DynamicDataButtonProps = {
  onClick(e: React.MouseEvent<HTMLButtonElement>): void;
  tooltipText?: string;
};

const DynamicDataButton = ({
  onClick,
  tooltipText = "Add Dynamic Data",
}: DynamicDataButtonProps) => {
  return (
    <Tooltip content={tooltipText} triggerAsChild>
      <button
        // Note (Noah, 2023-07-19, USE-306): Always use type="button", otherwise if this button's inside a form,
        // clicking on it will submit the form (this is the default behavior in html if you don't specify `type`)
        type="button"
        aria-label={tooltipText}
        className="flex h-6 w-6 cursor-pointer items-center justify-center gap-1 rounded bg-subtle shrink-0"
        onClick={onClick}
      >
        <DynamicData />
      </button>
    </Tooltip>
  );
};

export default DynamicDataButton;
