import * as React from "react";

const isUsingLocalhost =
  process.env.NODE_ENV === "development" || process.env.IS_TESTING;

const isUsingHttps = process.env.REPLO_LOCAL_HTTPS === "true";

export const getRuntimeScriptURL = ({
  projectId,
  elementId,
  version,
  isElementPreview,
}: {
  projectId: string;
  elementId?: string | null;
  version?: string | null;
  isElementPreview: boolean;
}) => {
  const basePath = isUsingLocalhost
    ? `http${isUsingHttps ? "s" : ""}://localhost:3004/w`
    : "https://replocdn.com/w";

  let url: string;
  if (isElementPreview) {
    url = `${basePath}/${projectId}/elementPreview${
      elementId ? `/${elementId}` : ""
    }.min.js`;
  } else {
    url = `${basePath}/${projectId}${elementId ? `/${elementId}` : ""}.min.js`;
  }

  if (version != null) {
    url += `?v=${version}`;
  }

  return url;
};

export const RuntimeScript: React.FC<{
  projectId: string;
  elementId?: string | null;
  version?: string | null;
  isElementPreview: boolean;
}> = ({ projectId, elementId, version, isElementPreview }) => {
  const url = getRuntimeScriptURL({
    projectId,
    elementId,
    version,
    isElementPreview,
  });

  // Note (Noah, 2022-07-01): Commenting this out because I have a hunch that it's
  // breaking production, see https://linear.app/replo/issue/REPL-2819/studs-iframe-embed-does-not-work

  // NOTE (Mariano, 2022-30-06): If the id already exists means the script is already in the DOM
  // so we don't want to add it twice
  // if (typeof document !== "undefined" && document.getElementById(url)) {
  //   return null;
  // }
  // Note (Noah, 2023-01-05, REPL-5878): The data-em-disable attribute here disables an
  // app called Edgemesh (which apparently is very popular in Shopify stores) from caching
  // our script proactively. The fact that it does this is dumb, but it can cause issues
  // because during the cache timeout, an older version of the script will be loaded. Adding
  // the attribute disables the caching :shrug:
  return <script data-em-disable async src={url} id={url} />;
};
