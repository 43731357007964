import type { RGBColor } from "react-color";
import type { SaturationProps } from "react-color/lib/components/common/Saturation";
import type {
  Gradient,
  GradientOrSolidOnChangeProps,
  SolidOrGradient,
} from "replo-runtime/shared/types";

import * as React from "react";

import Badge from "@common/designSystem/Badge";
import GradientSlider from "@common/designSystem/GradientSlider";
import { Group } from "@common/designSystem/Group";
import IconButton from "@common/designSystem/IconButton";
import ToggleGroup from "@common/designSystem/ToggleGroup";
import Tooltip from "@common/designSystem/Tooltip";
import {
  getAutoCompletedColor,
  getHex8Color,
  getHexColor,
  getRGB,
} from "@common/designSystem/utils/colors";
import ModifierLabel from "@editor/components/editor/page/element-editor/components/extras/ModifierLabel";
import { LengthInputSelector } from "@editor/components/editor/page/element-editor/components/modifiers/LengthInputModifier";
import { isNewRightBarUIEnabled } from "@editor/infra/featureFlags";
import {
  getFormattedColor,
  getFormattedColorWithoutOpacity,
  getFormattedOpacity,
} from "@editor/utils/colors";
import HashIcon from "@svg/hash";

import classNames from "classnames";
import debounce from "lodash-es/debounce";
import { CustomPicker } from "react-color";
import { Alpha, Hue, Saturation } from "react-color/lib/components/common";
import { BsArrow90DegDown, BsArrow90DegRight } from "react-icons/bs";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { CSS_ANGLE_TYPES } from "replo-runtime/shared/utils/units";
import { isEmpty, isNotNullish, isNullish } from "replo-utils/lib/misc";
import { v4 as uuidv4 } from "uuid";

import BadgeV2 from "./BadgeV2";
import Input from "./Input";

type ColorPickerProps = GradientOrSolidOnChangeProps & {
  documentColors?: string[];
  debounce?: boolean;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
};

const CustomPointer = ({ isSaturation = false }) => (
  <div
    className={classNames(
      "relative flex h-4 w-4 self-center justify-self-center rounded-full border-[3.8px] border-white shadow",
      {
        "-top-2 -left-2": isSaturation,
        "-top-[3px] -left-2": !isSaturation,
      },
    )}
  />
);

const Picker = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<Saturation> &
    Readonly<SaturationProps> &
    Readonly<{ children?: React.ReactNode }> & {
      onDragStart?(e: React.MouseEvent): void;
      onDragEnd?(e: React.MouseEvent): void;
    },
) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <div
        className="relative h-[150px] w-full"
        onMouseDown={(e) => {
          props.onDragStart?.(e);
        }}
        onMouseUp={(e) => {
          props.onDragEnd?.(e);
        }}
        data-testid="colorPickerSaturation"
      >
        <Saturation
          {...props}
          pointer={
            (() => <CustomPointer isSaturation />) as unknown as React.ReactNode
          }
          color={props.color}
          // NOTE (Sebas, 2024-09-13): The type definition for these components seems to be incorrect,
          // the prop `radius` exists but is not recognized by typescript.
          // @ts-ignore
          radius={4}
        />
      </div>
      <div className="relative h-[10px] w-full">
        <Hue
          {...props}
          pointer={CustomPointer as unknown as React.ReactNode}
          color={props.color}
          // @ts-ignore
          radius={4}
          className="w-full h-[10px]"
        />
      </div>
      <div className="relative h-[10px] w-full">
        <Alpha
          {...props}
          pointer={CustomPointer as unknown as React.ReactNode}
          color={props.color}
          // @ts-ignore
          radius={4}
        />
      </div>
    </div>
  );
};

const PickerComponent = CustomPicker(Picker);

function _getInitialColorValue(value: string | SolidOrGradient | null) {
  if (isNullish(value)) {
    return getRGB("#000000");
  }
  if (typeof value === "string") {
    return getRGB(value);
  }
  if (value.type === "solid") {
    return getRGB(value.color);
  }

  return getRGB(value?.gradient?.stops[0]?.color || "");
}

/**
 * Return whether a value should be displayed as a solid color (as opposed to gradient)
 * in the color picker.
 *
 * Note (Noah, 2022-03-23): This returns true in null cases since we want to default to
 * showing the solid color selector if there's a null value.
 */
function _isSolidColor(value: string | SolidOrGradient | null) {
  if (!value) {
    return true;
  }
  if (typeof value !== "string") {
    return value.type === "solid";
  }
  return true;
}

function _getInitialTiltValue(value: string | SolidOrGradient | null) {
  if (
    isNotNullish(value) &&
    typeof value !== "string" &&
    value.type === "gradient"
  ) {
    return value.gradient.tilt;
  }
  return "90deg";
}

function _getInitialGradientValue(
  value: string | SolidOrGradient | null,
  tilt: string,
) {
  if (
    isNotNullish(value) &&
    typeof value !== "string" &&
    value.type === "gradient"
  ) {
    return value.gradient;
  }
  return { tilt: tilt, stops: [] };
}

const ColorPicker = ({
  documentColors,
  allowsGradientSelection,
  onPreviewChange,
  onChange,
  onDragEnd,
  onDragStart,
  value,
  debounce: isDebounced = false,
}: ColorPickerProps) => {
  const [tilt, setTilt] = React.useState(_getInitialTiltValue(value));
  const [color, setColor] = React.useState<RGBColor>(
    _getInitialColorValue(value),
  );
  const [gradientColors, setGradientColors] = useOverridableState<Gradient>(
    _getInitialGradientValue(value, tilt),
    (value) => {
      if (!isSolid) {
        submitChange({
          type: "gradient",
          gradient: { tilt, stops: value.stops },
        });
      }
    },
  );
  const [selectedDraggable, setSelectedDraggable] = React.useState(
    gradientColors.stops[0]?.id || null,
  );
  const [isSolid, setIsSolid] = React.useState(_isSolidColor(value));
  const formattedColor = getFormattedColorWithoutOpacity(getHexColor(color));
  const [inputColorValue, setInputColorValue] = React.useState(formattedColor);
  const [lastValidColorValue, setLastValidColorValue] =
    React.useState(formattedColor);
  const rightBarUIEnabled = isNewRightBarUIEnabled();

  const getOpacity = () => {
    if (gradientColors.stops.length > 0) {
      const color =
        gradientColors.stops?.find((g) => g.id === selectedDraggable)?.color ??
        null;
      return getFormattedOpacity(getRGB(color).a);
    } else if (isNotNullish(color.a)) {
      return getFormattedOpacity(color.a);
    }
    return "100%";
  };

  const opacity = getOpacity();

  const _debouncedOnChange = React.useMemo(() => {
    return debounce((value) => onChange?.(value), 300);
  }, [onChange]);

  const submitChange = (value: any) => {
    onPreviewChange?.(value);
    _debouncedOnChange(value);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: I believe biome is wrong about this
  const debouncedPickerOnChange = React.useMemo(() => {
    return debounce((rgb) => {
      _pickerOnChange(rgb);
    }, 300);
  }, [_pickerOnChange]);

  function _internalOnChange(color: RGBColor, tilt: string, isSolid: boolean) {
    const hex8Color = getHex8Color(color);
    const newColor = getFormattedColor(hex8Color);
    const formattedColor = getFormattedColorWithoutOpacity(hex8Color);
    setInputColorValue(getFormattedColorWithoutOpacity(formattedColor));
    setLastValidColorValue(formattedColor);

    if (typeof value === "string") {
      submitChange(newColor);
    } else if (!isSolid) {
      // If there is no draggable we add a new one with the previous solid color selected
      if (isEmpty(gradientColors.stops)) {
        const uuid = uuidv4();
        setGradientColors({
          tilt: tilt,
          stops: [
            {
              id: uuid,
              color: color ? hex8Color : "transparent",
              location: "0%",
            },
          ],
        });
        setSelectedDraggable(uuid);
      }
      submitChange({
        type: "gradient",
        gradient: {
          tilt,
          stops: gradientColors.stops,
        },
      });
    } else {
      submitChange({
        type: "solid",
        color: newColor,
      });
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function _pickerOnChange(rgb: RGBColor) {
    _internalOnChange(rgb, tilt, isSolid);
    setColor(rgb);
  }

  function _onTiltChange(value: string) {
    setTilt(value);
    setGradientColors({
      tilt: value,
      stops: gradientColors.stops,
    });
    _internalOnChange(color, value, isSolid);
  }

  function _onDocumentColorClick(color: string) {
    const rgbColor = getRGB(color);
    _internalOnChange(rgbColor, tilt, isSolid);
    setColor(rgbColor);
  }

  function _rotateGradient(to: "left" | "right") {
    const deg = Number.parseInt(tilt.replace(/\D+/g, ""), 10);
    let newDeg = deg;
    if (to === "left") {
      newDeg -= 90;
      if (newDeg < 0) {
        newDeg = 360 + newDeg;
      }
    } else {
      newDeg += 90;
      if (newDeg > 360) {
        newDeg = newDeg - 360;
      }
    }
    _onTiltChange(`${newDeg}deg`);
  }

  function handleInputColorChange() {
    const completeColor = getAutoCompletedColor(inputColorValue);
    if (completeColor) {
      const colorValue = getFormattedColor(completeColor);
      const rgbColor = getRGB(colorValue);
      setColor(rgbColor);
      setLastValidColorValue(colorValue);
      _internalOnChange(rgbColor, tilt, isSolid);
    } else if (lastValidColorValue) {
      setInputColorValue(lastValidColorValue);
    }
  }

  function handleInputOpacityChange(value: string) {
    const rgbColor = {
      ...color,
      a: Number.parseInt(value.replace("%", ""), 10) / 100,
    };
    setColor(rgbColor);
    _internalOnChange(rgbColor, tilt, isSolid);
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    if (selectedDraggable) {
      const stop = gradientColors.stops.find(
        (stop) => stop.id === selectedDraggable,
      );
      if (stop) {
        const { id, location } = stop;
        setGradientColors({
          tilt: gradientColors.tilt,
          stops: gradientColors.stops.map((stop) => {
            if (stop.id === selectedDraggable) {
              return {
                id,
                color: getFormattedColor(getHex8Color(color))!,
                location,
              };
            }
            return stop;
          }),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, selectedDraggable]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    if (selectedDraggable) {
      const stop = gradientColors.stops.find(
        (stop) => stop.id === selectedDraggable,
      );
      if (stop) {
        const rgbColor = getRGB(stop.color);
        setColor(rgbColor);
        setInputColorValue(getFormattedColorWithoutOpacity(stop.color));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDraggable]);

  return (
    <div className="flex select-none flex-col items-center gap-2">
      {allowsGradientSelection && (
        <>
          <ToggleGroup
            allowsDeselect={false}
            type="single"
            style={{ width: "100%" }}
            options={[
              { label: "Solid", value: "solid" },
              { label: "Linear", value: "linear" },
            ]}
            value={isSolid ? "solid" : "linear"}
            onChange={(value) => {
              setIsSolid(value === "solid");
              _internalOnChange(color, tilt, value === "solid");
            }}
          />
          {!isSolid && (
            <div className="flex flex-col gap-2">
              <div className="flex gap-1">
                <LengthInputSelector
                  label={<ModifierLabel label="Rotation" />}
                  field="tilt"
                  placeholder="90deg"
                  resetValue="90deg"
                  metrics={CSS_ANGLE_TYPES}
                  onChange={_onTiltChange}
                  value={tilt}
                  dragTrigger="label"
                  autofocus
                />
                <IconButton
                  icon={<BsArrow90DegDown size={12} className="text-muted" />}
                  onClick={() => _rotateGradient("left")}
                  isPhonyButton={false}
                  type="secondary"
                  aria-label="Rotate gradient left"
                />
                <IconButton
                  icon={
                    <BsArrow90DegRight
                      size={12}
                      className="text-muted rotate-90"
                    />
                  }
                  onClick={() => _rotateGradient("right")}
                  isPhonyButton={false}
                  type="secondary"
                  aria-label="Rotate gradient right"
                />
              </div>
              <GradientSlider
                onChange={setGradientColors}
                gradient={gradientColors}
                selectedStopId={selectedDraggable ?? undefined}
                onChangeSelection={setSelectedDraggable}
                debounceDragging
                colorForFirstStop={getHex8Color(color)}
              />
            </div>
          )}
        </>
      )}
      <PickerComponent
        color={color}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onChange={({ rgb }) =>
          isDebounced ? debouncedPickerOnChange(rgb) : _pickerOnChange(rgb)
        }
      />
      <div className="flex w-full gap-2">
        <Input
          unsafe_className="flex-grow"
          value={inputColorValue ?? ""}
          placeholder="#000000"
          onChange={(e) => setInputColorValue(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
          startEnhancer={() =>
            rightBarUIEnabled ? (
              <BadgeV2
                type="color"
                isFilled
                backgroundColor={inputColorValue ?? ""}
              />
            ) : (
              <HashIcon />
            )
          }
          onBlur={handleInputColorChange}
          onEnter={handleInputColorChange}
          autoFocus={isSolid}
        />
        <LengthInputSelector
          field="opacity"
          placeholder="100%"
          metrics={["%"]}
          onChange={handleInputOpacityChange}
          className="w-14"
          value={opacity}
          allowsNegativeValue={false}
          minValues={{ "%": 0 }}
          maxValues={{ "%": 100 }}
        />
      </div>
      {!isEmpty(documentColors) && (
        <Group name="Page Colors" isCollapsible={false} className="w-full">
          <div className="flex flex-wrap gap-x-2 gap-y-1">
            {documentColors.map((color, i) => (
              <Tooltip content={color.toUpperCase()} key={i} triggerAsChild>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => _onDocumentColorClick(color)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" || event.key === " ") {
                      event.preventDefault();
                      event.currentTarget.click();
                    }
                  }}
                >
                  <Badge
                    isFilled
                    backgroundColor={color}
                    className="h-5 w-5 cursor-pointer shadow-symmetrical"
                  />
                </div>
              </Tooltip>
            ))}
          </div>
        </Group>
      )}
    </div>
  );
};

export default ColorPicker;
