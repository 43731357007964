import type { MenuItem } from "@editor/components/common/designSystem/Menu";
import type { IconType } from "react-icons/lib";
import type { PreviewableProperty } from "replo-runtime/shared/utils/preview";
import type { SizeControlType } from "schemas/modifiers";

import * as React from "react";

import ToggleGroup from "@common/designSystem/ToggleGroup";
import { HotkeyIndicator } from "@common/HotkeyIndicator";
import { useGetModifierControls } from "@editor/hooks/rightBar/useGetModifierControls";
import { useApplyComponentAction } from "@editor/hooks/useApplyComponentAction";
import useGetFlexboxWidthOrHeightOnChange from "@editor/hooks/useGetFlexboxWidthOrHeightOnChange";
import {
  selectAlignSelf,
  selectedDraftComponentIsRoot,
  selectFlexGrow,
  selectHeight,
  selectIsParentGrid,
  selectParentFlexDirection,
  selectPosition,
  selectWidth,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { DraggingTypes } from "@editor/utils/editor";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import ModifierGroup from "@editorExtras/ModifierGroup";
import LengthInputModifier, {
  LengthInputSelector,
} from "@editorModifiers/LengthInputModifier";
import { useCurrentPosition } from "@editorModifiers/PositioningModifier";
import FixedWidthHeight from "@svg/icons/fixed-width-height";
import MaxSvg from "@svg/max";
import MinSvg from "@svg/min";

import capitalize from "lodash-es/capitalize";
import {
  BsAlignBottom,
  BsAlignCenter,
  BsAlignEnd,
  BsAlignMiddle,
  BsAlignStart,
  BsAlignTop,
  BsChevronContract,
  BsChevronExpand,
} from "react-icons/bs";
import { styleAttributeToDefaultStyle } from "replo-runtime/shared/styleAttribute";
import { CSS_LENGTH_TYPES_COMPUTED_WITH_PERCENTAGE } from "replo-runtime/shared/utils/units";
import { coerceNumberToString } from "replo-utils/lib/misc";

import ModifierLabel from "../extras/ModifierLabel";

const FLEX_START_PROPERTY_VALUES = ["flex-start", "start"];
const FLEX_END_PROPERTY_VALUES = ["flex-end", "end"];

const ALIGNMENT_PROPERTY_VALUES = [
  ...FLEX_START_PROPERTY_VALUES,
  ...FLEX_END_PROPERTY_VALUES,
  "center",
];

const SizeModifier: React.FC = () => {
  const [controls, addControl] = useGetModifierControls<"size">("size");
  const isPageRootComponent = useEditorSelector(selectedDraftComponentIsRoot);
  const draftComponentPosition = useEditorSelector(selectPosition);
  const isFixedOrAbsolutePosition =
    draftComponentPosition &&
    ["fixed", "absolute"].includes(draftComponentPosition);
  const shouldShowAlignSelfModifiers =
    /* If the position is absolute or fixed, there's no point in aligning yourself */
    /* TODO (Yuxin, 2022-02-22) This should be disabled instead of magically hidden */
    !isFixedOrAbsolutePosition &&
    // Note (Sebas, 2022-08-31): If it is the page root component the
    // align self property doesn't make sense
    !isPageRootComponent &&
    controls.has("alignSelf");
  const shouldShowSizeInputs = !isPageRootComponent;

  const menuItems: MenuItem[] = [
    {
      id: "minWidth",
      title: "Min Width",
      type: "leaf",
      onSelect: () => {
        addControl("minWidth");
      },
      isDisabled: controls.has("minWidth"),
    },
    {
      id: "maxWidth",
      title: "Max Width",
      type: "leaf",
      onSelect: () => {
        addControl("maxWidth");
      },
      isDisabled: controls.has("maxWidth"),
    },
    {
      id: "minHeight",
      title: "Min Height",
      type: "leaf",
      onSelect: () => {
        addControl("minHeight");
      },
      isDisabled: controls.has("minHeight"),
    },
    {
      id: "maxHeight",
      title: "Max Height",
      type: "leaf",
      onSelect: () => {
        addControl("maxHeight");
      },
      isDisabled: controls.has("maxHeight"),
    },
    {
      id: "alignment",
      title: "Alignment",
      type: "leaf",
      onSelect: () => {
        addControl("alignSelf");
      },
      isDisabled: controls.has("alignSelf"),
    },
  ];

  return shouldShowAlignSelfModifiers || shouldShowSizeInputs ? (
    <ModifierGroup title="Size" menuItems={menuItems}>
      <div className="flex flex-col gap-2">
        {shouldShowSizeInputs ? (
          <SizeInputs controlsToRender={controls} />
        ) : null}
        {shouldShowAlignSelfModifiers ? <AlignmentToggles /> : null}
      </div>
    </ModifierGroup>
  ) : null;
};

const widthOptions = [
  {
    label: <BsChevronExpand size={12} className="rotate-90" />,
    value: "fill",
    tooltipContent: (
      <HotkeyIndicator
        hotkey="setWidthToFillAvailable"
        title="Fill Available Width"
      />
    ),
  },
  {
    label: <BsChevronContract size={12} className="rotate-90" />,
    value: "wrap",
    tooltipContent: (
      <HotkeyIndicator hotkey="setWidthToWrapContent" title="Wrap Content" />
    ),
  },
  {
    label: <FixedWidthHeight className="rotate-90" />,
    value: "fixed",
    tooltipContent: "Fixed Width",
    attributes: {
      "data-testid": "fixed-width-button",
    },
  },
];

const heightOptions = [
  {
    label: <BsChevronExpand size={12} />,
    value: "fill",
    tooltipContent: (
      <HotkeyIndicator
        hotkey="setHeightToFillAvailable"
        title="Fill Available Height"
      />
    ),
  },
  {
    label: <BsChevronContract size={12} />,
    value: "wrap",
    tooltipContent: (
      <HotkeyIndicator hotkey="setHeightToWrapContent" title="Wrap Content" />
    ),
  },
  {
    label: <FixedWidthHeight />,
    value: "fixed",
    tooltipContent: "Fixed Height",
    attributes: {
      "data-testid": "fixed-height-button",
    },
  },
];

function getToggleGroupOptions(value: string | null) {
  return [
    {
      value: "Fill",
      label: "Fill",
      isDisabled: value === "fill",
    },
    {
      value: "Wrap",
      label: "Wrap",
      isDisabled: value === "wrap",
    },
    {
      value: "Fixed",
      label: "Fixed",
      isDisabled: value === "fixed",
    },
    { value: "100vh", label: "100vh" },
  ];
}

const SizeInputs: React.FC<{
  controlsToRender: Set<SizeControlType>;
}> = ({ controlsToRender }) => {
  const flexGrow = useEditorSelector(selectFlexGrow);
  const width = useEditorSelector(selectWidth);
  const height = useEditorSelector(selectHeight);
  const parentFlexDirection = useEditorSelector(selectParentFlexDirection);
  const alignSelf = useEditorSelector(selectAlignSelf);
  const currentPosition = useCurrentPosition();
  const onChange = useGetFlexboxWidthOrHeightOnChange();
  const isAbsolutePosition = currentPosition === "absolute";

  const toggleGroupWidthValue = getToggleGroupValue(
    "width",
    flexGrow ?? null,
    alignSelf ?? null,
    parentFlexDirection,
    coerceNumberToString(width) ?? null,
    coerceNumberToString(height) ?? null,
    isAbsolutePosition,
  );
  const toggleGroupHeightValue = getToggleGroupValue(
    "height",
    flexGrow ?? null,
    alignSelf ?? null,
    parentFlexDirection,
    coerceNumberToString(width) ?? null,
    coerceNumberToString(height) ?? null,
    isAbsolutePosition,
  );

  const applyComponentAction = useApplyComponentAction();
  const widthDefaultValue = styleAttributeToEditorData.width.defaultValue;
  const widthValue = (() => {
    if (
      toggleGroupWidthValue &&
      ["fill", "wrap"].includes(toggleGroupWidthValue)
    ) {
      return capitalize(toggleGroupWidthValue);
    }
    return String(width);
  })();
  const onChangeWidthValue = (value: string) => {
    if (["fill", "wrap", "fixed"].includes(value.toLowerCase())) {
      const newValue = value.toLowerCase() as "fill" | "wrap" | "fixed";
      if (newValue === toggleGroupWidthValue) {
        return;
      }
      onChange(newValue, "width");
    } else {
      const values: Record<string, string> = {
        width: value,
        __width: value,
      };

      // Reset align-self if necessary
      if (parentFlexDirection === "column" && alignSelf === "stretch") {
        values.alignSelf = "auto";
      }

      // Reset flex-grow if necessary
      if (parentFlexDirection === "row") {
        values.flexGrow = "unset";
      }

      applyComponentAction({
        type: "setStyles",
        value: values,
      });
    }
  };

  const heightDefaultValue = styleAttributeToEditorData.height.defaultValue;
  const heightValue = (() => {
    if (
      toggleGroupHeightValue &&
      ["fill", "wrap"].includes(toggleGroupHeightValue)
    ) {
      return capitalize(toggleGroupHeightValue);
    }
    return String(height);
  })();
  const onChangeHeightValue = (value: string) => {
    if (["fill", "wrap", "fixed"].includes(value.toLowerCase())) {
      const newValue = value.toLowerCase() as "fill" | "wrap" | "fixed";
      if (newValue === toggleGroupHeightValue) {
        return;
      }
      onChange(newValue, "height");
    } else {
      const values: Record<string, string> = {
        height: value,
        __height: value,
      };

      // Reset align-self if necessary
      if (parentFlexDirection === "row" && alignSelf === "stretch") {
        values.alignSelf = "auto";
      }

      if (parentFlexDirection === "column") {
        values.flexGrow = "unset";
      }

      applyComponentAction({
        type: "setStyles",
        value: values,
      });
    }
  };

  return (
    <>
      <div className="flex items-center">
        <LengthInputSelector.Root
          resetValue={widthDefaultValue}
          anchorValue="200px"
          minValues={{ px: 0 }}
          value={widthValue}
          field="style.width"
          allowsNegativeValue={false}
          onChange={onChangeWidthValue}
          previewProperty="width"
          allowedNonUnitValues={["wrap", "fixed", "fill"]}
          dragTrigger="label"
          minDragValues={{ px: 0 }}
          maxDragValues={{ "%": 100 }}
          draggingType={DraggingTypes.Vertical}
          metrics={CSS_LENGTH_TYPES_COMPUTED_WITH_PERCENTAGE}
        >
          <LengthInputSelector.DraggableArea>
            <ModifierLabel label="Width" />
          </LengthInputSelector.DraggableArea>
          <div className="flex flex-row gap-2">
            <ToggleGroup
              type="single"
              allowsDeselect={false}
              style={{
                width: "100%",
              }}
              value={toggleGroupWidthValue ?? "fixed"}
              options={widthOptions}
              onChange={(value) =>
                onChange(value as "fill" | "wrap" | "fixed", "width")
              }
            />

            <LengthInputSelector.Input
              placeholder={widthDefaultValue}
              key="style.width"
              menuOptions={getToggleGroupOptions(toggleGroupWidthValue)}
            />
          </div>
        </LengthInputSelector.Root>
      </div>
      <div className="flex items-center">
        <LengthInputSelector.Root
          resetValue={heightDefaultValue}
          anchorValue="200px"
          minValues={{ px: 0 }}
          value={heightValue}
          field="style.height"
          allowsNegativeValue={false}
          onChange={onChangeHeightValue}
          previewProperty="height"
          allowedNonUnitValues={["wrap", "fixed", "fill"]}
          dragTrigger="label"
          minDragValues={{ px: 0 }}
          maxDragValues={{ "%": 100 }}
          draggingType={DraggingTypes.Vertical}
          metrics={CSS_LENGTH_TYPES_COMPUTED_WITH_PERCENTAGE}
        >
          <LengthInputSelector.DraggableArea>
            <ModifierLabel label="Height" />
          </LengthInputSelector.DraggableArea>
          <div className="flex flex-row gap-2">
            <ToggleGroup
              type="single"
              allowsDeselect={false}
              style={{
                width: "100%",
              }}
              value={toggleGroupHeightValue ?? "fixed"}
              options={heightOptions}
              onChange={(value) =>
                onChange(value as "fill" | "wrap" | "fixed", "height")
              }
            />

            <LengthInputSelector.Input
              placeholder={heightDefaultValue}
              key="style.height"
              menuOptions={getToggleGroupOptions(toggleGroupHeightValue)}
            />
          </div>
        </LengthInputSelector.Root>
      </div>
      <MaxMinWidthAndHeightControls controlsToRender={controlsToRender} />
    </>
  );
};

const AlignmentToggles: React.FC = () => {
  const { value, options, onChange } = useAlign();
  return (
    <div className="flex items-center">
      <ModifierLabel label="Alignment" />
      <ToggleGroup
        type="single"
        style={{
          width: "100%",
        }}
        value={value ?? styleAttributeToEditorData.alignSelf.defaultValue}
        options={options}
        onChange={onChange}
        allowsDeselect={false}
      />
    </div>
  );
};

export function useAlign() {
  const alignSelf = useEditorSelector(selectAlignSelf);
  const parentIsGrid = useEditorSelector(selectIsParentGrid);
  const parentFlexDirection = useEditorSelector(selectParentFlexDirection);
  const applyComponentAction = useApplyComponentAction();

  const options = [
    parentIsGrid || parentFlexDirection === "row"
      ? [
          {
            Icon: BsAlignTop,
            size: "16px",
            value: "flex-start",
            label: "Align Top",
          },
          {
            Icon: BsAlignMiddle,
            size: "16px",
            value: "center",
            label: "Align Center",
          },
          {
            Icon: BsAlignBottom,
            size: "16px",
            value: "flex-end",
            label: "Align Bottom",
          },
        ]
      : [
          {
            Icon: BsAlignStart,
            size: "16px",
            value: "flex-start",
            label: "Align Left",
          },
          {
            Icon: BsAlignCenter,
            size: "16px",
            value: "center",
            label: "Align Center",
          },
          {
            Icon: BsAlignEnd,
            size: "16px",
            value: "flex-end",
            label: "Align Right",
          },
        ],
    [
      {
        Icon: () => <span className="font-medium">A</span>,
        value: "auto",
        label: "Auto",
      },
    ],
  ]
    .flat()
    .map((option) => formatOption(option));

  const onChange = React.useCallback(
    (value: string) =>
      applyComponentAction({
        type: "setStyles",
        value: {
          alignSelf: value,
        },
      }),
    [applyComponentAction],
  );

  return {
    // Note (Sebas, 2022-10-12): In case we set "alignSelf: stretch" with
    // the width or height toggle group (depends on the flex direction), we
    // should return 'auto' as value to have an option selected.
    value:
      alignSelf === "stretch"
        ? styleAttributeToDefaultStyle.alignSelf
        : alignSelf,
    options,
    onChange,
  };
}

function formatOption(option: {
  Icon: IconType;
  value: string;
  label: string;
  size?: string;
}) {
  return {
    value: option.value,
    label: (
      <div style={{ margin: "auto" }} key={option.value}>
        <option.Icon size={option?.size ?? 18} />
      </div>
    ),
    tooltipContent: option.label,
  };
}

const getToggleGroupValue = (
  type: "width" | "height",
  flexGrow: string | number | null,
  alignSelf: string | null,
  parentFlexDirection: "row" | "column",
  width: string | null,
  height: string | null,
  isAbsolute: boolean,
) => {
  const typeIsWidth = type === "width";
  const isWidthAuto =
    !width || width === styleAttributeToEditorData.width.defaultValue;
  const isHeightAuto =
    !height || height === styleAttributeToEditorData.height.defaultValue;
  const isAlignSelfNotStretch =
    alignSelf && [...ALIGNMENT_PROPERTY_VALUES, "auto"].includes(alignSelf);
  const isFullWidth = width === "100%";
  const isFullHeight = height === "100%";
  const parentFlexDirectionIsRow = parentFlexDirection === "row";

  if (!isAbsolute) {
    if (typeIsWidth && width && width !== "auto") {
      return "fixed";
    }
    if (!typeIsWidth && height && height !== "auto") {
      return "fixed";
    }
  }
  if (isAbsolute) {
    if (
      (typeIsWidth && parentFlexDirectionIsRow && isFullWidth) ||
      (!typeIsWidth && !parentFlexDirectionIsRow && isFullHeight) ||
      (typeIsWidth && !parentFlexDirectionIsRow && isFullWidth) ||
      (!typeIsWidth && parentFlexDirectionIsRow && isFullHeight)
    ) {
      return "fill";
    }
    if (typeIsWidth && width && width !== "auto") {
      return "fixed";
    }
    if (!typeIsWidth && height && height !== "auto") {
      return "fixed";
    }
    if (
      (typeIsWidth && parentFlexDirectionIsRow && !isFullWidth) ||
      (!typeIsWidth && !parentFlexDirectionIsRow && !isFullHeight) ||
      (typeIsWidth && !parentFlexDirectionIsRow && !isFullWidth) ||
      (!typeIsWidth && parentFlexDirectionIsRow && !isFullHeight)
    ) {
      return "wrap";
    }
  }

  if (
    (typeIsWidth && parentFlexDirection === "row" && isWidthAuto) ||
    (!typeIsWidth && parentFlexDirection === "column" && isHeightAuto)
  ) {
    return flexGrow && flexGrow !== "unset" ? "fill" : "wrap";
  }

  if (
    (typeIsWidth && parentFlexDirection === "column" && isWidthAuto) ||
    (!typeIsWidth && parentFlexDirection === "row" && isHeightAuto)
  ) {
    if (isAlignSelfNotStretch) {
      return "wrap";
    }
    return alignSelf ? "fill" : "wrap";
  }
  return null;
};

const LengthInputControl: React.FC<{
  label: string;
  field: string;
  placeholder: string;
  resetValue: string;
  previewProperty: PreviewableProperty;
  startEnhancer: React.ReactNode;
  onChange: (value: string) => void;
  menuOptions: Array<{ value: string; label: string }>;
}> = ({
  label,
  field,
  placeholder,
  resetValue,
  previewProperty,
  startEnhancer,
  onChange,
  menuOptions,
}) => (
  <LengthInputModifier
    startEnhancer={() => startEnhancer}
    placeholder={placeholder}
    resetValue={resetValue}
    anchorValue="200px"
    label={<ModifierLabel label={label} />}
    dragTrigger="label"
    minDragValues={{ px: 0 }}
    minValues={{ px: 0 }}
    key={field}
    field={field}
    maxDragValues={{ "%": 100 }}
    draggingType={DraggingTypes.Vertical}
    allowsNegativeValue={false}
    onChange={onChange}
    menuOptions={menuOptions}
    previewProperty={previewProperty}
    metrics={CSS_LENGTH_TYPES_COMPUTED_WITH_PERCENTAGE}
  />
);

const MaxMinWidthAndHeightControls: React.FC<{
  controlsToRender: Set<SizeControlType>;
}> = ({ controlsToRender }) => {
  const applyComponentAction = useApplyComponentAction();
  const draftComponentIsRoot = useEditorSelector(selectedDraftComponentIsRoot);

  /* Note (Fran, 2022-08-23): If the component doesn't has parent (aka is the
      root component) we don't allow min and max width/height */
  if (draftComponentIsRoot) {
    return null;
  }

  const minWidthDefaultValue = styleAttributeToEditorData.minWidth.defaultValue;
  const maxWidthDefaultValue = styleAttributeToEditorData.maxWidth.defaultValue;
  const minHeightDefaultValue =
    styleAttributeToEditorData.minHeight.defaultValue;
  const maxHeightDefaultValue =
    styleAttributeToEditorData.maxHeight.defaultValue;

  const minMenuOptions = [
    { value: "auto", label: "Reset" },
    { value: "100%", label: "100%" },
  ];
  const maxMenuOptions = [
    { value: "none", label: "Reset" },
    { value: "100%", label: "100%" },
  ];

  const inputs = [
    {
      label: "Min Width",
      field: "style.minWidth",
      placeholder: minWidthDefaultValue,
      resetValue: minWidthDefaultValue,
      previewProperty: "minWidth" as PreviewableProperty,
      startEnhancer: <MinSvg />,
      onChange: (value: string) =>
        applyComponentAction({
          type: "setStyles",
          value: {
            minWidth: value,
          },
        }),
      menuOptions: minMenuOptions,
    },
    {
      label: "Min Height",
      field: "style.minHeight",
      placeholder: minHeightDefaultValue,
      resetValue: minHeightDefaultValue,
      previewProperty: "minHeight" as PreviewableProperty,
      startEnhancer: <MinSvg />,
      onChange: (value: string) =>
        applyComponentAction({
          type: "setStyles",
          value: {
            minHeight: value,
          },
        }),
      menuOptions: minMenuOptions,
    },
    {
      label: "Max Width",
      field: "style.maxWidth",
      placeholder: maxWidthDefaultValue,
      resetValue: maxWidthDefaultValue,
      previewProperty: "maxWidth" as PreviewableProperty,
      startEnhancer: <MaxSvg />,
      onChange: (value: string) =>
        applyComponentAction({
          type: "setStyles",
          value: {
            maxWidth: value,
          },
        }),
      menuOptions: maxMenuOptions,
    },
    {
      label: "Max Height",
      field: "style.maxHeight",
      placeholder: maxHeightDefaultValue,
      resetValue: maxHeightDefaultValue,
      previewProperty: "maxHeight" as PreviewableProperty,
      startEnhancer: <MaxSvg />,
      onChange: (value: string) =>
        applyComponentAction({
          type: "setStyles",
          value: {
            maxHeight: value,
          },
        }),
      menuOptions: maxMenuOptions,
    },
  ];

  const inputsToRender = inputs.filter((input) =>
    controlsToRender.has(input.field.split(".")[1] as SizeControlType),
  );

  if (inputsToRender.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      {inputsToRender.map((input) => {
        return <LengthInputControl key={input.field} {...input} />;
      })}
    </div>
  );
};

export default SizeModifier;
