import type { BoxShadow } from "replo-runtime/shared/types";

import * as React from "react";

import Badge from "@editor/components/common/designSystem/Badge";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Popover from "@editor/components/common/designSystem/Popover";
import Selectable from "@editor/components/common/designSystem/Selectable";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import Slider from "@editor/components/common/designSystem/Slider";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import {
  selectBoxShadow,
  selectDraftComponentId,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import {
  getBoxShadowObject,
  getBoxShadowString,
} from "@editor/utils/boxShadow";
import { DraggingTypes } from "@editor/utils/editor";
import { getIntegerValueFromString } from "@editor/utils/getIntegerValueFromString";
import SolidColorSelector from "@editorComponents/SolidColorSelector";
import ModifierGroup from "@editorExtras/ModifierGroup";
import { LengthInputSelector } from "@editorModifiers/LengthInputModifier";

import { BsSubtract } from "react-icons/bs";
import { MdAdd } from "react-icons/md";
import { RiCloseFill, RiPaintBrushLine } from "react-icons/ri";
import { TbDropletFilled } from "react-icons/tb";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { CSS_LENGTH_TYPES } from "replo-runtime/shared/utils/units";
import { v4 as uuidv4 } from "uuid";

/**
  Note (Sebas, 2022-08-30): This component is reusing the ids of the ref when switching
  between variants. This had to be done because I could not find a way to reset the ref
  when switching between states without breaking the functionality.
 */
const BoxShadowModifierDeprecated = () => {
  const applyComponentAction = useApplyComponentAction();
  const [activeShadow, setActiveShadow] = useOverridableState<BoxShadow | null>(
    null,
  );
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const draftComponentBoxShadow = useEditorSelector(selectBoxShadow) || "";
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const draftComponentBoxShadows = draftComponentBoxShadow.split(",");
  // Note (Sebas, 2022-08-30): This is necessary to prevent breaking the editing of box shadows.
  const boxShadowIds = React.useRef<string[]>([]);
  const boxShadows =
    draftComponentBoxShadow.length > 0
      ? getBoxShadowObject(draftComponentBoxShadows, boxShadowIds)
      : [];

  const onCreate = (value: string) => {
    applyComponentAction({
      componentId: draftComponentId,
      type: "setStyles",
      value: {
        boxShadow: value,
      },
    });
  };

  const handleAddBoxShadow = () => {
    let newId = boxShadowIds.current[boxShadows.length]!;
    // Note (Sebas, 2022-09-01): In case there is no id
    // in the ref we need to create a new one
    if (!boxShadowIds.current[boxShadows.length]) {
      newId = uuidv4();
      boxShadowIds.current = [...boxShadowIds.current, newId];
    }
    const newBoxShadow = {
      id: newId,
      shadowType: "dropShadow" as const,
      offsetX: "0px",
      offsetY: "4px",
      blur: "4px",
      spread: "0px",
      shadowColor: "#00000040",
    };
    const newBoxShadowsArray = [...boxShadows, newBoxShadow];
    const newBoxShadows = getBoxShadowString(newBoxShadowsArray);
    setActiveShadow(newBoxShadow);
    setIsPopoverOpen(true);
    onCreate(newBoxShadows);
  };

  const handleRemoveBoxShadow = (id: string) => {
    // Note (Sebas, 2022-08-30): Remove id from ref
    boxShadowIds.current = boxShadowIds.current.filter((uuid) => uuid !== id);
    const filteredBoxShadows = boxShadows.filter(
      (boxShadow) => boxShadow.id !== id,
    );
    const updatedBoxShadowes = getBoxShadowString(filteredBoxShadows);
    onCreate(updatedBoxShadowes);
  };

  const handleEditBoxShadowPopover = (boxShadow: BoxShadow) => {
    setActiveShadow(boxShadow);
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleBoxShadowChange = (value: BoxShadow) => {
    setActiveShadow(value);
    const newBoxShadows = boxShadows.map((boxShadow) => {
      return boxShadow.id === value.id ? value : boxShadow;
    });
    const updatedBoxShadows = getBoxShadowString(newBoxShadows);
    onCreate(updatedBoxShadows);
  };

  const currentBoxShadowIndex = boxShadows.findIndex(
    (boxShadow) => boxShadow.id === activeShadow?.id,
  );

  return (
    <ModifierGroup
      title="Box Shadows"
      endEnhancer={
        <MdAdd className="ml-3" size={12} onClick={handleAddBoxShadow} />
      }
      isDefaultOpen={boxShadows.length > 0}
    >
      <div className="flex flex-col gap-1">
        {boxShadows.length > 0 ? (
          boxShadows.map((boxShadow) => (
            <SelectionIndicator
              key={boxShadow.id}
              title={formatTitle(boxShadow)}
              onClick={() => handleEditBoxShadowPopover(boxShadow)}
              startEnhancer={
                <Badge
                  isFilled
                  backgroundColor="bg-blue-600"
                  className="h-[18px] w-[18px]"
                >
                  <BsSubtract size={10} className="text-white" />
                </Badge>
              }
              endEnhancer={
                <RiCloseFill
                  size={12}
                  className="cursor-pointer text-slate-400"
                  onClick={(e) => {
                    // NOTE (Sebas, 2024-10-10): This is necessary to prevent executing the onClick event of
                    // the parent element.
                    e.stopPropagation();
                    handleRemoveBoxShadow(boxShadow.id);
                  }}
                />
              }
            />
          ))
        ) : (
          <div
            className="mx-auto w-full cursor-pointer text-left text-xs text-gray-400"
            onClick={handleAddBoxShadow}
          >
            <span>Click the + icon to add a box shadow.</span>
          </div>
        )}
        <BoxShadowPopover
          isOpen={isPopoverOpen}
          setIsOpen={setIsPopoverOpen}
          activeShadow={activeShadow!}
          handleBoxShadowChange={handleBoxShadowChange}
          boxShadowIndex={currentBoxShadowIndex}
        />
      </div>
    </ModifierGroup>
  );
};

const Label: React.FC<React.PropsWithChildren<{ title?: string }>> = ({
  title,
}) => (
  <div className="flex items-center gap-2 w-full">
    <Badge isFilled backgroundColor="bg-blue-600" className="h-[18px] w-[18px]">
      <BsSubtract size={10} className="text-white" />
    </Badge>
    {title}
  </div>
);

const BoxShadowPopover: React.FC<
  React.PropsWithChildren<{
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    activeShadow: BoxShadow;
    handleBoxShadowChange(value: BoxShadow): void;
    boxShadowIndex: number;
  }>
> = ({
  isOpen = false,
  setIsOpen,
  activeShadow,
  handleBoxShadowChange,
  boxShadowIndex,
}) => {
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const handleInputChange = (
    value: string,
    inputType:
      | "shadowType"
      | "offsetX"
      | "offsetY"
      | "blur"
      | "spread"
      | "shadowColor",
  ) => {
    const newBoxShadow = {
      ...activeShadow,
      [inputType]: value,
    };
    handleBoxShadowChange(newBoxShadow);
  };

  return (
    <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
      <Popover.Content
        title="Box Shadow"
        shouldPreventDefaultOnInteractOutside={areModalsOpen}
      >
        <div className="grid columns-2 gap-2">
          <Selectable
            className="col-span-2"
            onSelect={(v: string) => handleInputChange(v, "shadowType")}
            startEnhancer={() => (
              <Badge
                isFilled
                backgroundColor="bg-blue-600"
                className="h-[18px] w-[18px]"
              >
                <BsSubtract size={10} className="text-white" />
              </Badge>
            )}
            value={activeShadow?.shadowType}
            options={[
              {
                label: <Label title="None" />,
                value: "none",
              },
              {
                label: <Label title="Drop Shadow" />,
                value: "dropShadow",
              },
              { label: <Label title="Inset" />, value: "inset" },
            ]}
          />
          <LengthInputSelector
            metrics={CSS_LENGTH_TYPES}
            className="col-span-1"
            startEnhancer={() => <span className="text-xs">X</span>}
            field="offsetX"
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            draggingType={DraggingTypes.Vertical}
            value={activeShadow?.offsetX || null}
            onChange={(value: string) => handleInputChange(value, "offsetX")}
            isDisabled={activeShadow?.shadowType === "none"}
            previewProperty="boxShadow"
            previewSubProperty="offsetX"
            previewPropertyIndex={boxShadowIndex}
            autofocus
          />
          <LengthInputSelector
            metrics={CSS_LENGTH_TYPES}
            className="col-span-1"
            startEnhancer={() => <span className="text-xs">Y</span>}
            field="offsetY"
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            draggingType={DraggingTypes.Vertical}
            value={activeShadow?.offsetY || null}
            onChange={(value: string) => handleInputChange(value, "offsetY")}
            isDisabled={activeShadow?.shadowType === "none"}
            previewProperty="boxShadow"
            previewSubProperty="offsetY"
            previewPropertyIndex={boxShadowIndex}
          />
          <div className="col-span-2 flex flex-col gap-2">
            <LabeledControl label="Blur" size="sm">
              <div className="flex gap-2">
                <LengthInputSelector
                  metrics={CSS_LENGTH_TYPES}
                  className="col-span-1"
                  startEnhancer={() => <TbDropletFilled />}
                  minDragValues={{ px: 0 }}
                  minValues={{ px: 0 }}
                  field="blur"
                  resetValue="0px"
                  anchorValue="0px"
                  placeholder="0px"
                  draggingType={DraggingTypes.Vertical}
                  value={activeShadow?.blur || null}
                  onChange={(value: string) => handleInputChange(value, "blur")}
                  isDisabled={activeShadow?.shadowType === "none"}
                  previewProperty="boxShadow"
                  previewSubProperty="blur"
                  previewPropertyIndex={boxShadowIndex}
                />
                <Slider
                  value={getIntegerValueFromString(activeShadow?.blur || null)}
                  debounce
                  minimum={0}
                  maximum={10}
                  onChange={(value: number) =>
                    handleInputChange(`${value.toString()}px`, "blur")
                  }
                  isDisabled={activeShadow?.shadowType === "none"}
                />
              </div>
            </LabeledControl>
            <LabeledControl label="Spread" size="sm">
              <div className="flex gap-2">
                <LengthInputSelector
                  metrics={CSS_LENGTH_TYPES}
                  className="col-span-1"
                  startEnhancer={() => <RiPaintBrushLine />}
                  field="spread"
                  resetValue="0px"
                  anchorValue="0px"
                  placeholder="0px"
                  draggingType={DraggingTypes.Vertical}
                  value={activeShadow?.spread || null}
                  onChange={(value: string) =>
                    handleInputChange(value, "spread")
                  }
                  isDisabled={activeShadow?.shadowType === "none"}
                  previewProperty="boxShadow"
                  previewSubProperty="spread"
                  previewPropertyIndex={boxShadowIndex}
                />
                <Slider
                  value={getIntegerValueFromString(
                    activeShadow?.spread || null,
                  )}
                  debounce
                  minimum={0}
                  maximum={10}
                  onChange={(value: number) =>
                    handleInputChange(`${value.toString()}px`, "spread")
                  }
                  isDisabled={activeShadow?.shadowType === "none"}
                />
              </div>
            </LabeledControl>
            <LabeledControl label="Shadow Color" size="sm">
              <SolidColorSelector
                popoverTitle="Shadow Color"
                value={activeShadow?.shadowColor || ""}
                onChange={(value: string) =>
                  handleInputChange(value, "shadowColor")
                }
                isDisabled={activeShadow?.shadowType === "none"}
                defaultValue="#00000040"
              />
            </LabeledControl>
          </div>
        </div>
      </Popover.Content>
      <Popover.Anchor className="relative top-0 left-0" />
    </Popover>
  );
};

const formatTitle = (boxShadow: BoxShadow) => {
  const boxShadowTitle = getBoxShadowString([boxShadow], true);
  return boxShadowTitle.includes("none") ? "None" : boxShadowTitle;
};

export default BoxShadowModifierDeprecated;
