import type * as React from "react";

import { getFormattedColorWithoutOpacity } from "@editor/utils/colors";

import { filterNulls } from "replo-utils/lib/array";
import { v4 as uuidv4 } from "uuid";

export type TextShadow = {
  id: string;
  offsetX: string;
  offsetY: string;
  blur: string;
  color?: string;
};

export const parseTextShadows = (
  textShadows: string[],
  textShadowIds?: React.MutableRefObject<string[]>,
) => {
  const textShadowObject = textShadows.map((shadow, idx) => {
    const textShadowComponents = shadow.split(" ");
    let id = uuidv4();

    // TODO (Fran 2024-10-09): We should remove this conditional and the need of the `textShadowIds`
    // argument from this function when we remove the isNewRightBarUIEnabled feature flag.
    // We only need this for the old component Text Shadow modifier.
    if (textShadowIds) {
      if (textShadowIds.current[idx]) {
        id = textShadowIds.current[idx]!;
      } else {
        textShadowIds.current = [...textShadowIds.current, id];
      }
    }

    const [offsetX, offsetY, blur, color] = textShadowComponents;
    return {
      id,
      offsetX: offsetX!,
      offsetY: offsetY!,
      blur: blur!,
      color: color!,
    };
  });

  return textShadowObject;
};

export const getTextShadowString = (
  textShadows: TextShadow[],
  withoutOpacity = false,
) => {
  const propertiesOrder = ["offsetX", "offsetY", "blur", "color"] as const;

  const textShadowsString = textShadows.map((value) => {
    const orderedShadowValues = filterNulls(
      propertiesOrder.map((prop) => {
        if (value[prop]?.includes("#") && withoutOpacity) {
          return getFormattedColorWithoutOpacity(value[prop]);
        }
        return value[prop];
      }),
    );

    return orderedShadowValues.join(" ");
  });

  return textShadowsString.join(",").trim();
};

export const formatTitle = (textShadow: TextShadow) => {
  return getTextShadowString([textShadow], true);
};
