import type { CustomPropsRecord } from "../../../shared/Component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

function getConfigurableProps(): CustomPropsRecord {
  return {
    items: {
      type: "dynamicItems",
      name: "Dynamic items",
      description:
        "If set, the carousel will dynamically update its content based on the given items.",
      defaultValue: null,
    },
  };
}

export const styleElements = {
  root: {
    defaultStyles: {
      display: "flex",
      // Note (Chance, 2023-05-19) `as const` here is because `React.CSSProperties`
      // expects some properties (like `flexDirection`) to be a union of specific
      // strings, which can't be inferred in usage here. Our style prop is a bit
      // more permissive since non-standard CSS syntax is used, so this is a simple
      // workaround for that.
      //
      // TODO: After we remove Radium completely we should be able to remove the
      // cast since we'll never provide this value to the `style` prop.
      flexDirection: "row" as const,
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    allowsLayoutModification: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    canContainChildren: true,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
