import type { ButtonSharedProps } from "@common/designSystem/Button";

import * as React from "react";

import Button, { ButtonLink } from "@common/designSystem/Button";
import {
  SubtitleSkeleton,
  TitleSkeleton,
} from "@editor/components/dashboard/SkeletonLoaders";

import { isNotNullish } from "replo-utils/lib/misc";

type HeaderProps = {
  title: string | null;
  subtitle?: string;
  buttonLabel?: string;
  onButtonClick?(): void;
  href?: string;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  buttonTooltipText?: string;
};

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  buttonLabel,
  href,
  onButtonClick,
  isLoading,
  isButtonDisabled,
  buttonTooltipText,
}) => {
  const shouldShowButtonLink = isNotNullish(href);
  const shouldShowButton = !shouldShowButtonLink && isNotNullish(onButtonClick);
  const commonProps: ButtonSharedProps = {
    size: "lg",
    type: "primary",
    textClassNames: "font-normal",
    isDisabled: isButtonDisabled,
    tooltipText: buttonTooltipText,
    className: isButtonDisabled ? "bg-slate-200" : undefined,
  };
  return (
    <div className="flex w-full justify-between items-center">
      <div className="flex flex-col gap-2">
        {isLoading && (
          <>
            <TitleSkeleton />
            <SubtitleSkeleton />
          </>
        )}
        {!isLoading && title && (
          <h2 className="text-lg font-medium">{title}</h2>
        )}

        {!isLoading && subtitle && (
          <p className="text-sm font-normal text-slate-400">{subtitle}</p>
        )}
      </div>
      {shouldShowButtonLink && (
        <ButtonLink {...commonProps} to={href} target="_blank">
          {buttonLabel}
        </ButtonLink>
      )}
      {shouldShowButton && (
        <Button {...commonProps} onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      )}
    </div>
  );
};

export default Header;
