import type {
  CategorySlug,
  ComponentTemplateBadge,
  ComponentTemplateBadgeId,
  ComponentTemplateCategorySelectionSlug,
  ComponentTemplateCategoryType,
  ComponentTemplateIndustry,
  ComponentTemplateIndustryId,
  ComponentTemplateSelectionCategories,
  ComponentTemplateSelectionId,
  ComponentTemplateType,
  Filter,
  SharedCategoryId,
} from "schemas/componentTemplates";
import type { ReploElementType } from "schemas/generated/element";

import { exhaustiveSwitch } from "replo-utils/lib/misc";
import {
  CATEGORIES_IDS,
  SHARED_CATEGORIES_SELECTIONS,
  sharedCategoryIdSchema,
} from "schemas/componentTemplates";

export const isSharedCategoryId = (
  categoryId: string,
): categoryId is SharedCategoryId => {
  return sharedCategoryIdSchema.safeParse(categoryId).success;
};

export const getCategorySlugFromId = (
  categoryId: ComponentTemplateSelectionId,
) => {
  return COMPONENT_TEMPLATE_CATEGORIES.find(
    (category) => category.id === categoryId,
  )?.slug as ComponentTemplateCategorySelectionSlug;
};

export const getCategoryIdFromSlug = (
  slug: ComponentTemplateCategorySelectionSlug,
) => {
  return COMPONENT_TEMPLATE_CATEGORIES.find(
    (category) => category.slug === slug,
  )?.id as ComponentTemplateSelectionId;
};

export const getCategoryNameFromSlug = (slug: CategorySlug) => {
  return COMPONENT_TEMPLATE_CATEGORIES.find(
    (category) => category.slug === slug,
  )?.name;
};

// NOTE (Fran, 2023-04-17): After adding a new category we should add an icon in
// useGetComponentTemplateCategories hook. Otherwise it will use a default icon.
export const COMPONENT_TEMPLATE_CATEGORIES: ComponentTemplateSelectionCategories[] =
  [
    {
      id: CATEGORIES_IDS.heros,
      name: "Heros",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "heros",
    },
    {
      id: CATEGORIES_IDS.banners,
      name: "Banners",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "banners",
    },
    {
      id: CATEGORIES_IDS.productPageTemplates,
      name: "Product Page Templates",
      // TODO (Juan, 2023-10-06): This needs to be componentTemplateCategoryType: "section" in the future (https://github.com/replohq/andytown/pull/5847#discussion_r1348959952)
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "product-page-templates",
    },
    {
      id: CATEGORIES_IDS.sections,
      name: "Sections",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "sections",
    },
    {
      id: CATEGORIES_IDS.aboutUs,
      name: "About Us",
      componentTemplateCategoryType: "section",
      order: 3,
      slug: "about-us",
    },
    {
      id: CATEGORIES_IDS.products,
      name: "Products",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "products",
    },
    {
      id: CATEGORIES_IDS.bundles,
      name: "Bundles",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "bundles",
    },
    {
      id: CATEGORIES_IDS.footers,
      name: "Footers",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "footers",
    },
    {
      id: CATEGORIES_IDS.faqs,
      name: "FAQs",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "faqs",
    },
    {
      id: CATEGORIES_IDS.content,
      name: "Content",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "content",
    },
    {
      id: CATEGORIES_IDS.testimonials,
      name: "Testimonials",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "testimonials",
    },
    {
      id: CATEGORIES_IDS.logos,
      name: "Logos",
      componentTemplateCategoryType: "section",
      order: 2,
      slug: "logos",
    },
    {
      id: CATEGORIES_IDS.landingPage,
      name: "Landing Pages",
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "landing-pages",
    },
    {
      id: CATEGORIES_IDS.advertorialPage,
      name: "Advertorial",
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "advertorial",
    },
    {
      id: CATEGORIES_IDS.listiclePage,
      name: "Listicle",
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "listicle",
    },
    {
      id: CATEGORIES_IDS.videoSalesLetterPage,
      name: "Video Sales Letter",
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "video-sales-letter",
    },
    {
      id: CATEGORIES_IDS.blogPostPage,
      name: "Blogs",
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "blog-posts",
    },
    {
      id: CATEGORIES_IDS.productDropPage,
      name: "Product Drop",
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "product-drop",
    },
    {
      id: CATEGORIES_IDS.aboutUs,
      name: "About Us",
      componentTemplateCategoryType: "page",
      order: 3,
      slug: "about-us",
    },
    {
      id: CATEGORIES_IDS.faqs,
      name: "FAQs",
      componentTemplateCategoryType: "page",
      order: 2,
      slug: "faqs",
    },
    {
      id: SHARED_CATEGORIES_SELECTIONS.sharedComponents,
      name: "Shared Components",
      componentTemplateCategoryType: "shared",
      order: 2,
      slug: "shared-components",
    },
    {
      id: SHARED_CATEGORIES_SELECTIONS.sharedPages,
      name: "Shared Pages",
      componentTemplateCategoryType: "shared",
      order: 1,
      slug: "shared-pages",
    },
  ];

const getCategoriesByType = (type: ComponentTemplateCategoryType) => {
  return COMPONENT_TEMPLATE_CATEGORIES.filter(
    (category) => category.componentTemplateCategoryType === type,
  );
};

export const getCategorySlugFromElementType = (
  elementType: ReploElementType,
) => {
  exhaustiveSwitch({ type: elementType })({
    page: "all-pages",
    shopifyArticle: "blog-posts",
    shopifyProductTemplate: "product-page-templates",
    shopifySection: "all-sections",
  });
};

export const componentTemplateIndustries: ComponentTemplateIndustry[] = [
  { id: "dbec89c6-0222-4115-a994-967c07514b5b", name: "Accessories" },
  { id: "58785c14-4db0-47b5-9fcf-bd48cbb92938", name: "Activities & Outdoors" },
  { id: "ef386249-c49f-49b3-8fcc-350a7b8ab1e0", name: "Baby & Kids" },
  {
    id: "a95bc923-28ac-41da-978f-46be5c024793",
    name: "Beauty",
  },
  { id: "d16c03d2-c5b8-40e9-8f8b-a59d2c5755a1", name: "Bags & Luggage" },
  { id: "26fd6a4e-525c-48b9-bfb4-718332f2a661", name: "Clothing" },
  {
    id: "5b5f7cf6-2aee-4ea3-85c6-ea73111aca9e",
    name: "Drinks",
  },
  { id: "2932fe19-bbbf-4219-8b8d-b181b670f5d8", name: "Food" },
  { id: "3f39766e-9c1b-44ae-b105-5d64d93f2cad", name: "Hair Care" },
  { id: "68395a9c-e77b-460b-9806-9cb1ec7e0dc1", name: "Health & Wellness" },
  { id: "9107a7ec-4a26-4ed3-9307-98fbad60fbb1", name: "Home" },
  { id: "56ec366e-8626-46c3-ba69-6343b651f534", name: "Personal Care" },
  { id: "be3a36d9-113e-4188-99dc-97a4420409e5", name: "Pets" },
  { id: "73cb5719-5bd0-47a0-a861-9fa66818debf", name: "Shoes" },
  { id: "b785dd5d-efb4-4fe0-85a6-c3b3b59a34db", name: "Skin Care" },
  { id: "d4e28d8a-9c59-4744-9044-121162f9b247", name: "Supplements" },
  { id: "7c7825e2-bf9f-4cae-81c4-2692d5213603", name: "Tech" },
];

export const getComponentTemplateIndustryName = (
  industryId: ComponentTemplateIndustryId,
) => {
  return componentTemplateIndustries.find(
    (industry) => industry.id === industryId,
  )?.name;
};

export const getComponentTemplateIndustrySlugFromName = (
  industryName: string,
) => {
  return industryName.replace(/&/g, "and").replace(/ /g, "-").toLowerCase();
};
/** End Industries */

/** Begin Badges */

export const componentTemplateBadges: ComponentTemplateBadge[] = [
  {
    id: "295e4057-1b55-46ea-89e9-b0b26bbc3bb7",
    name: "CRO Top Pick",
    primaryColor: "#2563eb", // blue-600
    secondaryColor: "#1e40af", // blue-800
  },
  {
    id: "d4e28d8a-28ac-46ea-85c6-b181b670f5d8",
    name: "Hottest Brands",
    primaryColor: "#2563eb", // blue-600
    secondaryColor: "#1e40af", // blue-800
  },
];

export const getComponentTemplateBadgeName = (
  badgeId: ComponentTemplateBadgeId,
) => {
  return componentTemplateBadges.find((badge) => badge.id === badgeId)?.name;
};

export const getComponentTemplateBadgeColor = (
  badgeId: ComponentTemplateBadgeId,
) => {
  const badge = componentTemplateBadges.find((badge) => badge.id === badgeId);
  return {
    primaryColor: badge?.primaryColor,
    secondaryColor: badge?.secondaryColor,
  };
};
/** End Badges */

export function getFiltersByType(
  type: ComponentTemplateCategoryType,
): Filter[] {
  return exhaustiveSwitch({ type })({
    page: () => [
      { label: "Our Picks", options: componentTemplateBadges, type: "badge" },
      {
        label: "By Category",
        options: getCategoriesByType("page"),
        type: "category",
      },
      {
        label: "By Industry",
        options: componentTemplateIndustries,
        type: "industry",
      },
    ],
    section: () => [
      { label: "Our Picks", options: componentTemplateBadges, type: "badge" },
      {
        label: "By Category",
        options: getCategoriesByType("section"),
        type: "category",
      },
    ],
    shared: () => [
      {
        label: "By Category",
        options: getCategoriesByType("shared"),
        type: "category",
      },
    ],
  });
}

type FindComponentTemplatesQuery = {
  projectId?: string;
  skip?: string;
  userId?: string;
  componentCollectionId?: string;
  searchText?: string;
  scope?: Omit<ComponentTemplateType, "left-bar">;
  includeComponent: boolean;
  selectedCategories?: ComponentTemplateSelectionId[];
  selectedBadges?: ComponentTemplateBadgeId[];
  selectedIndustries?: ComponentTemplateIndustryId[];
  templateType: ComponentTemplateCategoryType | null;
};

type FindComponentTemplatesPayload = {
  storeId?: string;
  userId?: string;
  componentCategoryType: ComponentTemplateType[];
  selectedCategories?: ComponentTemplateSelectionId[];
  selectedBadges?: ComponentTemplateBadgeId[];
  selectedIndustries?: ComponentTemplateIndustryId[];
  componentCollectionId?: string;
  searchText?: string;
  pageSize?: string;
  skip?: string;
};

export const getFindComponentTemplatesQuery = (
  payload: FindComponentTemplatesQuery,
): FindComponentTemplatesPayload => {
  const templateTypeMapping: Record<
    ComponentTemplateCategoryType,
    ComponentTemplateType[]
  > = {
    page: ["page"],
    section: ["section", "component"],
    shared: componentTemplateTypes,
  };

  const query: FindComponentTemplatesPayload = {
    // NOTE (Fran 2023-11-14): To avoid showing saved components templates in the marketplace
    // we should add the store id only for shared categories.
    storeId: undefined,
    componentCategoryType: payload.templateType
      ? templateTypeMapping[payload.templateType]
      : componentTemplateTypes,
    userId: undefined,
  };

  const selectedCategoriesContainsSharedCategories =
    payload.selectedCategories?.some((category) =>
      isSharedCategoryId(category),
    );
  if (selectedCategoriesContainsSharedCategories) {
    query.componentCollectionId = undefined;
    query.selectedCategories = undefined;
    query.userId = payload.userId;
    query.storeId = payload.projectId;

    if (
      payload.selectedCategories?.includes(
        SHARED_CATEGORIES_SELECTIONS.sharedPages,
      )
    ) {
      query.componentCategoryType = ["page"];
    } else {
      query.componentCategoryType = ["component", "section"];
    }
  }

  if (payload.searchText) {
    query.componentCollectionId = undefined;
    // NOTE (Fran 2023-11-14): We add the user id to find also the shared templates.
    query.userId = payload.userId;
  }

  if (payload.scope === "store") {
    query.storeId = payload.projectId;
  }

  return {
    ...payload,
    ...query,
  };
};

const componentTemplateTypes: ComponentTemplateType[] = [
  "page",
  "section",
  "component",
];

export const componentTemplateCategoryTypeToModalTitle: Record<
  ComponentTemplateCategoryType,
  string
> = {
  page: "Full Pages Templates",
  section: "Sections Templates",
  shared: "Shared Templates",
};
