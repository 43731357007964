import * as React from "react";

import IconButton from "@common/designSystem/IconButton";
import LabeledControl from "@common/designSystem/LabeledControl";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import { useGetStepResultsData } from "@components/flows/hooks/useGetCurrentStepResultsData";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import Button from "@editor/components/common/designSystem/Button";
import Input from "@editor/components/common/designSystem/Input";
import Selectable from "@editor/components/common/designSystem/Selectable";
import { SimpleSkeletonLoader } from "@editor/components/common/designSystem/SkeletonLoader";
import Textarea from "@editor/components/common/designSystem/Textarea";
import { useReploFlowsStepContext } from "@editor/components/flows/context/ReploFlowsStepContext";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepsLayout from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";
import { trpc } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import { Controller, useForm, useWatch } from "react-hook-form";
import { BsFileEarmarkExcel, BsMagic, BsX } from "react-icons/bs";
import {
  componentTemplateIndustries,
  getComponentTemplateIndustrySlugFromName,
} from "replo-runtime/shared/componentTemplates";
import { z } from "zod";

import StepSubtitle from "./components/StepSubtitle";

const formSchema = z.object({
  brandName: z.string().optional(),
  industry: z.string(),
  brandVoice: z.string().optional(),
  whatBusinessSells: z.string().optional(),
  whoIsCustomer: z.string().optional(),
});
type FormValues = z.infer<typeof formSchema>;

const BrandDetailsStep: React.FC = () => {
  const importFromUrlStepResultsData =
    useGetStepResultsData<"onboarding.user.import-from-url">("import-from-url");

  const { currentStep, submitStep, goBack } = useReploFlowsStepContext();

  const urlProvided = importFromUrlStepResultsData?.url !== "";

  const {
    data: brandDetailsAndIndustryData,
    isLoading: areBrandDetailsQueryLoading,
    error: brandDetailsAndIndustryError,
  } = trpc.ai.generateBrandDetailsAndIndustryV2.useQuery(
    importFromUrlStepResultsData?.url
      ? {
          url: importFromUrlStepResultsData.url,
        }
      : skipToken,
    { retry: false },
  );
  // NOTE (Gabe 2024-10-08): Because the step data is not initially available, we
  // have to construct our own loading variable.
  const areBrandDetailsLoading =
    !importFromUrlStepResultsData || areBrandDetailsQueryLoading;

  const hasSomeData =
    brandDetailsAndIndustryData?.brandDetails.brandName ||
    brandDetailsAndIndustryData?.industry ||
    brandDetailsAndIndustryData?.brandDetails.brandVoice ||
    brandDetailsAndIndustryData?.brandDetails.whatBusinessSells ||
    brandDetailsAndIndustryData?.brandDetails.whoIsCustomer;

  const hasAllData =
    brandDetailsAndIndustryData?.brandDetails.brandName &&
    brandDetailsAndIndustryData?.industry &&
    brandDetailsAndIndustryData?.brandDetails.brandVoice &&
    brandDetailsAndIndustryData?.brandDetails.whatBusinessSells &&
    brandDetailsAndIndustryData?.brandDetails.whoIsCustomer;

  const gotBrandDetailsFromUrl = urlProvided && hasSomeData;
  const unableToGetAnyBrandDetailsFromUrl = urlProvided && !hasSomeData;

  const [hideWarning, setHideWarning] = React.useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      brandName: "",
      industry: "",
      brandVoice: "",
      whatBusinessSells: "",
      whoIsCustomer: "",
    },
    resolver: zodResolver(formSchema),
  });

  React.useEffect(() => {
    if (brandDetailsAndIndustryError) {
      setValue("brandName", "");
      setValue("industry", "");
      setValue("brandVoice", "");
      setValue("whatBusinessSells", "");
      setValue("whoIsCustomer", "");
      return;
    }
    if (brandDetailsAndIndustryData) {
      setValue(
        "brandName",
        brandDetailsAndIndustryData.brandDetails.brandName ?? "",
      );
      setValue(
        "industry",
        getComponentTemplateIndustrySlugFromName(
          brandDetailsAndIndustryData.industry ?? "",
        ),
      );
      setValue(
        "brandVoice",
        brandDetailsAndIndustryData.brandDetails.brandVoice ?? "",
      );
      setValue(
        "whatBusinessSells",
        brandDetailsAndIndustryData.brandDetails.whatBusinessSells ?? "",
      );
      setValue(
        "whoIsCustomer",
        brandDetailsAndIndustryData.brandDetails.whoIsCustomer ?? "",
      );
    }
  }, [brandDetailsAndIndustryData, setValue, brandDetailsAndIndustryError]);

  const { submitOrSkipStepCallback: submitStepCallback } =
    useRouterFlowCallbacks();

  const industry = useWatch({ control, name: "industry" });

  const industryIsDefined = Boolean(industry);

  const onSubmit = (values: FormValues) => {
    if (currentStep) {
      submitStep(
        currentStep.id,
        currentStep.type,
        values,
        ({ instance, nextStep }) => {
          submitStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          });
        },
      );
    }
  };

  const brandNameError = errors.brandName?.message;
  const brandVoiceError = errors.brandVoice?.message;
  const whatBusinessSellsError = errors.whatBusinessSells?.message;
  const whoIsCustomerError = errors.whoIsCustomer?.message;

  const industryOptions = componentTemplateIndustries.map((industry) => ({
    value: getComponentTemplateIndustrySlugFromName(industry.name),
    label: industry.name,
  }));

  return (
    <OnboardingStepsLayout
      rightPanelContent={<StepImage src="/images/flows/styles.png" />}
    >
      <form
        className="flex flex-col gap-10"
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
      >
        <div className="flex flex-col gap-2">
          <div>
            <StepTitle>Brand details</StepTitle>
            <StepSubtitle>
              These details will help generate personalized, high-quality
              content with Replo AI.
            </StepSubtitle>
          </div>
          <div className="text-xs text-ai flex flex-row gap-2 items-center h-4">
            {gotBrandDetailsFromUrl && (
              <>
                <BsMagic />
                {hasAllData
                  ? "Results generated from URL"
                  : "Some results generated from URL"}
              </>
            )}
          </div>
          {!areBrandDetailsLoading &&
            unableToGetAnyBrandDetailsFromUrl &&
            !hideWarning && (
              <div className="flex flex-row gap-2 items-center bg-blue-50 rounded-md px-4 py-2 text-blue-600 justify-between">
                <div className="flex flex-row gap-2 items-center">
                  <BsFileEarmarkExcel />
                  <p className="text-xs">Couldn’t retrieve details from URL</p>
                </div>
                <div className="flex flex-row gap-2 items-center">
                  <Button
                    type="tertiary"
                    onClick={() => goBack()}
                    className="text-blue-600 text-xs cursor-pointer hover:text-blue-500"
                  >
                    Try new link
                  </Button>
                  <IconButton
                    tooltipText="dismiss"
                    isPhonyButton={false}
                    type="tertiary"
                    className="bg-clear hover:bg-slate-50"
                    icon={<BsX size={12} />}
                    onClick={() => setHideWarning(true)}
                  />
                </div>
              </div>
            )}
        </div>
        <div className="grid grid-cols-1 gap-6">
          <LabeledControl
            label="What is your brand's name?"
            className="text-default font-medium w-full"
            size="base"
          >
            <div className="flex flex-col gap-2">
              <Controller
                name="brandName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    {areBrandDetailsLoading ? (
                      <SimpleSkeletonLoader width="100%" height="50" />
                    ) : (
                      <Input
                        size="base"
                        value={value}
                        onChange={(value) => onChange(value)}
                        aria-invalid={
                          Boolean(brandNameError) ? "true" : undefined
                        }
                        aria-describedby={
                          Boolean(brandNameError)
                            ? "error-brand-name"
                            : undefined
                        }
                        placeholder="Rockstar Bags"
                        unsafe_className={classNames(
                          "w-full px-[10px] text-sm",
                          {
                            "bg-ai-emphasis":
                              urlProvided &&
                              brandDetailsAndIndustryData?.brandDetails
                                .brandName,
                          },
                        )}
                      />
                    )}
                  </>
                )}
              />
              {brandNameError && (
                <ErrorMessage id="error-brand-name" error={brandNameError} />
              )}
            </div>
          </LabeledControl>
          <LabeledControl
            label={
              <>
                What is your industry? <span className="text-red-500">*</span>
              </>
            }
            className="text-default font-medium"
            size="base"
          >
            <div className="flex flex-row gap-2">
              <Controller
                name="industry"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const options = [
                    ...industryOptions,
                    {
                      value: "other",
                      label: "Other",
                    },
                  ];
                  const valueIsStandardIndustry = industryOptions.some(
                    (industry) => industry.value === value,
                  );
                  if (areBrandDetailsLoading) {
                    return <SimpleSkeletonLoader width="100%" height="32" />;
                  }
                  return (
                    <>
                      <Selectable
                        options={options}
                        value={valueIsStandardIndustry ? value : "other"}
                        size="sm"
                        onSelect={(value) => {
                          if (value) {
                            onChange(value !== "other" ? value : "");
                          }
                        }}
                        className={classNames("text-sm h-8", {
                          "bg-ai-emphasis":
                            urlProvided &&
                            brandDetailsAndIndustryData?.industry,
                        })}
                      />
                      {!valueIsStandardIndustry && (
                        <Input
                          placeholder="Enter your industry"
                          value={value}
                          // NOTE (Gabe 2024-09-25): This is the height of a
                          // small sized Selectable.
                          unsafe_inputClassName="text-sm h-8"
                          unsafe_className="h-8"
                          onChange={(value) => onChange(value)}
                        />
                      )}
                    </>
                  );
                }}
              />
            </div>
          </LabeledControl>

          <LabeledControl
            label="What are you selling?"
            className="text-default font-medium w-full"
            size="base"
          >
            <div className="flex flex-col gap-2">
              <Controller
                name="whatBusinessSells"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    {areBrandDetailsLoading ? (
                      <SimpleSkeletonLoader width="100%" height="50" />
                    ) : (
                      <Textarea
                        value={value}
                        onChange={(value) => onChange(value)}
                        aria-invalid={
                          Boolean(whatBusinessSellsError) ? "true" : undefined
                        }
                        aria-describedby={
                          Boolean(whatBusinessSellsError)
                            ? "error-what-business-sells"
                            : undefined
                        }
                        placeholder="We sell handmade purses made from recycled concert tshirts"
                        className={classNames("w-auto text-sm", {
                          "bg-ai-emphasis":
                            urlProvided &&
                            brandDetailsAndIndustryData?.brandDetails
                              .whatBusinessSells,
                        })}
                      />
                    )}
                  </>
                )}
              />
              {whatBusinessSellsError && (
                <ErrorMessage
                  id="error-what-business-sells"
                  error={whatBusinessSellsError}
                />
              )}
            </div>
          </LabeledControl>
          <LabeledControl
            label="Who are you selling to?"
            className="text-default font-medium"
            size="base"
          >
            <div className="flex flex-col gap-2">
              <Controller
                name="whoIsCustomer"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    {areBrandDetailsLoading ? (
                      <SimpleSkeletonLoader width="100%" height="50" />
                    ) : (
                      <Textarea
                        value={value}
                        onChange={(value) => onChange(value)}
                        aria-invalid={
                          Boolean(whoIsCustomerError) ? "true" : undefined
                        }
                        aria-describedby={
                          Boolean(whoIsCustomerError)
                            ? "error-who-is-customer"
                            : undefined
                        }
                        placeholder="Our customers are usually Gen Z teenagers who love TikTok..."
                        className={classNames("w-auto text-sm", {
                          "bg-ai-emphasis":
                            urlProvided &&
                            brandDetailsAndIndustryData?.brandDetails
                              .whoIsCustomer,
                        })}
                      />
                    )}
                  </>
                )}
              />
              {whoIsCustomerError && (
                <ErrorMessage
                  id="error-who-is-customer"
                  error={whoIsCustomerError}
                />
              )}
            </div>
          </LabeledControl>
          <LabeledControl
            label="Tone and voice"
            className="text-default font-medium"
            size="base"
          >
            <div className="flex flex-col gap-2">
              <Controller
                name="brandVoice"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    {areBrandDetailsLoading ? (
                      <SimpleSkeletonLoader width="100%" height="50" />
                    ) : (
                      <Textarea
                        value={value}
                        onChange={(value) => onChange(value)}
                        aria-invalid={
                          Boolean(brandVoiceError) ? "true" : undefined
                        }
                        aria-describedby={
                          Boolean(brandVoiceError)
                            ? "error-brand-voice"
                            : undefined
                        }
                        placeholder="Fun, quirky, and playful - we use humor to connect with our audience and keep things light-hearted..."
                        className={classNames("w-auto text-sm", {
                          "bg-ai-emphasis":
                            urlProvided &&
                            brandDetailsAndIndustryData?.brandDetails
                              .brandVoice,
                        })}
                      />
                    )}
                  </>
                )}
              />
              {brandVoiceError && (
                <ErrorMessage id="error-brand-voice" error={brandVoiceError} />
              )}
            </div>
          </LabeledControl>
        </div>
        <FlowActionButtons shouldDisableNextButton={!industryIsDefined} />
      </form>
    </OnboardingStepsLayout>
  );
};

export default BrandDetailsStep;
