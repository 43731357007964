import type { ReploElementType } from "schemas/generated/element";

import { exhaustiveSwitch } from "replo-utils/lib/misc";

const isLiquidAllowed = (elementType: ReploElementType | undefined) => {
  if (!elementType) {
    return false;
  }
  const allowsLiquid = exhaustiveSwitch({ type: elementType })({
    page: true,
    shopifyProductTemplate: true,
    shopifySection: true,
    shopifyArticle: false,
  });
  return allowsLiquid;
};

export default isLiquidAllowed;
