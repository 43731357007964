import type { StorePasswordRequiredModalProps } from "@editor/components/AppModalTypes";

import * as React from "react";

import Button from "@common/designSystem/Button";
import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import { ErrorMessage } from "@editor/components/account/common";
import Modal from "@editor/components/common/designSystem/Modal";
import { useLazyCanvasDocumentPublisherQuery } from "@editor/reducers/api-reducer";
import {
  selectDraftElementId,
  selectProjectId,
} from "@editor/reducers/core-reducer";
import { closeModal } from "@editor/reducers/modals-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { z } from "zod";

const formSchema = z.object({
  password: z.string().min(1, "Please provide your store password"),
});
type FormData = z.infer<typeof formSchema>;

export const StorePasswordRequiredModal: React.FC<
  StorePasswordRequiredModalProps
> = ({ type }) => {
  const projectId = useEditorSelector(selectProjectId);
  const draftElementId = useEditorSelector(selectDraftElementId);
  const dispatch = useEditorDispatch();
  const [isIncorrectPassword, setIsIncorrectPassword] = useOverridableState(
    type === "wrongPassword",
  );

  const [triggerPublisherCanvasDocumentQuery, { isLoading }] =
    useLazyCanvasDocumentPublisherQuery();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({ resolver: zodResolver(formSchema) });

  const isButtonLoading = isSubmitting || isLoading;

  const onSubmit = async (data: FormData) => {
    setIsIncorrectPassword(false);
    if (isButtonLoading) {
      return;
    }
    const result = await triggerPublisherCanvasDocumentQuery({
      storeId: projectId,
      storePassword: data.password,
      draftElementId,
    });
    if (result?.data?.status === "success") {
      dispatch(closeModal({ type: "storePasswordRequiredModal" }));
    }
  };

  return (
    <Modal onRequestClose={() => null} isOpen={true} style={{ width: "420px" }}>
      <form
        className="flex flex-col items-start bg-white p-4"
        onSubmit={(e) => {
          void handleSubmit(onSubmit)(e);
        }}
      >
        <div className="flex flex-col gap-y-4">
          <div className="text-xl font-semibold text-default">
            Store Password Required
          </div>
          {!isIncorrectPassword && (
            <p className="text-bold text-slate-400">
              Please enter your Shopify store&apos;s password.
            </p>
          )}
          {isIncorrectPassword && (
            <p className="text-bold text-red-400">
              Your Shopify store&apos;s password was incorrect.
            </p>
          )}
          <div className="flex flex-col gap-y-2 text-slate-400">
            <div className="text-sm">
              Looks like your Shopify store is password protected! Please enter
              your password to allow editing pages.
            </div>
            <div className="text-sm">
              You can find this under Shopify Admin under Sales Channels &gt;
              Online Store &gt; Preferences &gt; Password Protection
            </div>
          </div>

          <div className="w-full gap-2">
            <LabeledControl label="Store Password" className="gap-2">
              <Input
                size="base"
                autoFocus={true}
                placeholder="Password"
                {...register("password")}
              />
              <ErrorMessage errorMessage={errors.password?.message ?? null} />
            </LabeledControl>
            <Button
              className="mt-4"
              type="primary"
              size="lg"
              htmlType="submit"
              isFullWidth={true}
              isLoading={isButtonLoading}
            >
              Submit Password
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
