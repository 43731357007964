import type { ProductRequestType } from "@editor/hooks/useStoreProducts";
import type {
  ProductRef,
  ProductRefOrDynamic,
} from "replo-runtime/shared/types";

import * as React from "react";

import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import {
  arrayMove,
  SortableItem,
  SortableList,
} from "@common/designSystem/SortableList";
import * as ProductSelectionPopover from "@components/editor/page/ProductSelectionPopover";
import { useModal } from "@editor/hooks/useModal";
import {
  selectAncestorOrSelfWithProductCollectionType,
  selectAncestorOrSelfWithProductType,
  selectDraftComponentId,
  selectDraftElementType,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";

import classNames from "classnames";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { isContextRef } from "replo-runtime/store/ReploProduct";

type MultiProductProps = {
  isMultiProducts: true;
  onChange: (value: ProductRef[]) => void;
  selectedProductRef: ProductRefOrDynamic[] | null;
};

type SingleProductProps = {
  isMultiProducts?: false;
  onChange: (value: ProductRef | null) => void;
  selectedProductRef: ProductRefOrDynamic | null;
};

type ProductSelectorSharedProps = {
  excludedDynamicDataKeys?: string[];
  allowDynamicData?: boolean;
  productRequestType?: ProductRequestType;
  isVariantSelectable?: boolean;
  dataType?: "summary" | "full";
  className?: string;
  showPlaceholders?: boolean;
};

type SingleProductSelectorProps = SingleProductProps &
  ProductSelectorSharedProps;

type MultiProductSelectorProps = MultiProductProps & ProductSelectorSharedProps;

export function ProductSelector(
  props: SingleProductSelectorProps,
): React.ReactElement<any, any> | null;
export function ProductSelector(
  props: MultiProductSelectorProps,
): React.ReactElement<any, any> | null;

export function ProductSelector({
  selectedProductRef,
  allowDynamicData = true,
  onChange,
  isMultiProducts,
  excludedDynamicDataKeys,
  productRequestType,
  isVariantSelectable = false,
  dataType = "summary",
  className,
  showPlaceholders = true,
}: ProductSelectorSharedProps & (MultiProductProps | SingleProductProps)) {
  const modal = useModal();
  const isDynamicDataSelected =
    !isMultiProducts && selectedProductRef && isContextRef(selectedProductRef);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const draftElementType = useEditorSelector(selectDraftElementType);
  const productCollectionComponentAncestor = useEditorSelector(
    selectAncestorOrSelfWithProductCollectionType,
  );
  const productComponentAncestor = useEditorSelector(
    selectAncestorOrSelfWithProductType,
  );

  const selectedComponentIsProductCollection =
    productCollectionComponentAncestor &&
    productCollectionComponentAncestor.id === draftComponentId;
  const selectedComponentIsProduct =
    !productCollectionComponentAncestor &&
    productComponentAncestor &&
    productComponentAncestor.id === draftComponentId;

  const shouldExcludeProductKey = (() => {
    // Note (Sebas, 2022-11-25): If the selected component has no dynamic data and
    // if the current selected component is a productCollection or product we need
    // to exclude the _product key to prevent from showing on the dynamicDataModal.
    // This is necessary because if you manually select a product, and then try to
    // add dynamic data, the current selected product will show on the dynamicDataModal
    // but if you select it, it won't work.
    if (
      !isDynamicDataSelected &&
      (selectedComponentIsProductCollection || selectedComponentIsProduct)
    ) {
      return true;
    }

    // NOTE (Evan, 9/11/23) For product templates, we want to avoid showing both "Template Product" and "Current Product"
    // at the same time in the Dynamic Data selector (it's confusing). So we pursue the following strategy:
    // - hide "Current Product" for Product components (this happens here)
    // - hide "Template Product" for everything else (this happens in the useDynamicData hook)
    // This encourages the pattern of having the template product set for the Product component, then having
    // its children use "current product" to transitively reference the template product.
    if (
      selectedComponentIsProduct &&
      draftElementType === "shopifyProductTemplate"
    ) {
      return true;
    }

    return false;
  })();

  const _openDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        excludedAttributeKeys: [
          ...(excludedDynamicDataKeys ?? []),
          ...(shouldExcludeProductKey ? ["_product"] : []),
        ],
        targetType: isVariantSelectable
          ? DynamicDataTargetType.PRODUCT_VARIANT
          : DynamicDataTargetType.PRODUCT,
        referrerData: {
          type: "callback",
          onChange: (value: any) => {
            onChange(value);
          },
        },
        initialPath:
          !isMultiProducts &&
          selectedProductRef &&
          isContextRef(selectedProductRef)
            ? getPathFromVariable(selectedProductRef.ref as string)
            : undefined,
      },
    });
  };

  if (
    !isMultiProducts &&
    selectedProductRef &&
    isContextRef(selectedProductRef)
  ) {
    return (
      <DynamicDataValueIndicator
        type="other"
        templateValue={selectedProductRef.ref as string}
        onClick={() => {
          _openDynamicData();
        }}
        onRemove={() => {
          if (!isMultiProducts) {
            onChange(null);
          }
        }}
      />
    );
  }

  const _getValue = (): ProductRef[] => {
    if (!selectedProductRef) {
      return [];
    }

    if (isMultiProducts) {
      return selectedProductRef as ProductRef[];
    }

    return [selectedProductRef as ProductRef];
  };

  return (
    <div
      className={classNames(
        "flex flex-row items-center justify-between gap-2",
        className,
      )}
    >
      {isMultiProducts ? (
        <div className="w-full">
          <SortableList
            onReorderEnd={({ oldIndex, newIndex }) => {
              const reorderedList = arrayMove(
                (selectedProductRef ?? []) as ProductRef[],
                oldIndex,
                newIndex,
              );
              onChange(reorderedList);
            }}
            withDragHandle
          >
            {((selectedProductRef ?? []) as ProductRef[]).map(
              (productRef, index) => (
                <SortableItem
                  key={productRef?.id ?? `undefined-${index}`}
                  id={productRef?.id ?? `undefined-${index}`}
                >
                  <ProductSelectionPopover.Root
                    value={[productRef]}
                    isMultiSelection={false}
                    productRequestType={productRequestType}
                    onSubmit={(newValue) => {
                      const newProductList = [
                        ...((selectedProductRef ?? []) as ProductRef[]),
                      ];
                      if (newValue?.productId) {
                        newProductList[index] = newValue;
                        onChange(newProductList);
                      } else {
                        newProductList.splice(index, 1);
                        onChange(newProductList);
                      }
                    }}
                    className="w-full"
                    dataType={dataType}
                  >
                    <ProductSelectionPopover.Content />
                    <ProductSelectionPopover.Trigger />
                  </ProductSelectionPopover.Root>
                </SortableItem>
              ),
            )}
          </SortableList>
        </div>
      ) : (
        <ProductSelectionPopover.Root
          onSubmit={onChange}
          value={_getValue()}
          isMultiSelection={false}
          productRequestType={productRequestType}
          dataType={dataType}
          showPlaceholders={showPlaceholders}
        >
          <ProductSelectionPopover.Content />
          <ProductSelectionPopover.Trigger />
        </ProductSelectionPopover.Root>
      )}
      {allowDynamicData && (
        <DynamicDataButton
          onClick={(e) => {
            e.stopPropagation();
            _openDynamicData();
          }}
          tooltipText="Use dynamic value"
        />
      )}
    </div>
  );
}
