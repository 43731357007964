import type { To } from "react-router-dom";

import * as React from "react";

import TourStepTrigger from "@components/flows/TourStepTrigger";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useModal } from "@editor/hooks/useModal";
import {
  selectDoesExistDraftElement,
  selectDraftComponentId,
  selectDraftComponentName,
} from "@editor/reducers/core-reducer";
import {
  selectLastMarketplacePath,
  setLastMarketplacePath,
} from "@editor/reducers/marketplace-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { routes } from "@editor/utils/router";
import { useLogAnalytics } from "@hooks/useLogAnalytics";

import { Tooltip } from "@replo/design-system/components/tooltip";
import classNames from "classnames";
import { BsBookmark, BsSliders } from "react-icons/bs";
import { FiGlobe } from "react-icons/fi";
import { generatePath, Link } from "react-router-dom";

const ActionButtons = () => {
  const modal = useModal();
  const projectId = useCurrentProjectId();
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const draftComponentName = useEditorSelector(selectDraftComponentName);
  const dispatch = useEditorDispatch();
  const lastPath = useEditorSelector(selectLastMarketplacePath);
  const analytics = useLogAnalytics();
  const draftElementExists = useEditorSelector(selectDoesExistDraftElement);
  return (
    <div className="flex items-center rounded gap-1 text-default px-4">
      <TourStepTrigger step="step-2">
        <ActionLink
          tooltip="Marketplace Templates"
          to={lastPath ?? routes.marketplaceModal}
          state={{
            // Note (Noah, 2024-04-23, REPL-11091): If there aren't any pages, we
            // always want to have the marketplace create a new one, not add
            marketplaceModalRequestType: draftElementExists
              ? "browse"
              : "create",
          }}
          onClick={() => {
            analytics("editor.marketplace.browse", {
              from: "header",
            });
            dispatch(setLastMarketplacePath(null));
          }}
        >
          <FiGlobe size={20} />
        </ActionLink>
      </TourStepTrigger>
      <ActionButton
        tooltip="Save Component"
        disabled={!draftComponentId}
        onClick={() => {
          if (draftComponentId) {
            modal.openModal({
              type: "saveTemplateModal",
              props: { initialName: draftComponentName },
            });
          }
        }}
      >
        <BsBookmark size={20} />
      </ActionButton>
      <ActionLink
        tooltip="Project Settings"
        disabled={!projectId}
        to={
          projectId ? generatePath(routes.editor.settings, { projectId }) : "."
        }
      >
        <BsSliders size={20} />
      </ActionLink>
    </div>
  );
};

function ActionButton({
  onClick,
  children,
  disabled = false,
  tooltip,
}: React.PropsWithChildren<{
  tooltip: string;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}>) {
  return (
    <Tooltip
      content={tooltip}
      trigger={({ triggerProps, triggerRef }) => (
        <button
          ref={triggerRef}
          {...triggerProps}
          type="button"
          className={classNames("p-2 rounded text-center", {
            "text-slate-300 cursor-not-allowed": disabled,
            "hover:bg-slate-100": !disabled,
          })}
          onClick={onClick}
          aria-label={tooltip}
          disabled={disabled || undefined}
        >
          {children}
        </button>
      )}
    />
  );
}

function ActionLink({
  children,
  disabled = false,
  onClick,
  to,
  tooltip,
  state,
}: React.PropsWithChildren<{
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  state?: any;
  to: To;
  tooltip: string;
}>) {
  return (
    <Tooltip<HTMLAnchorElement>
      content={tooltip}
      trigger={({ triggerProps, triggerRef }) => (
        <Link
          {...triggerProps}
          ref={triggerRef}
          className={classNames("p-2 rounded text-center", {
            "text-slate-300 cursor-not-allowed pointer-events-none": disabled,
            "hover:bg-slate-100": !disabled,
          })}
          to={to}
          aria-label={tooltip}
          aria-disabled={disabled || undefined}
          onClick={onClick}
          state={state}
        >
          {children}
        </Link>
      )}
    ></Tooltip>
  );
}

export default ActionButtons;
