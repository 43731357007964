import type { UninstallShopifyIntegrationModalProps } from "../AppModalTypes";

import * as React from "react";

import Button from "@common/designSystem/Button";
import Modal from "@common/designSystem/Modal";
import { ModalLayout } from "@common/ModalLayout";
import toast from "@editor/components/common/designSystem/Toast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { docs } from "@editor/utils/docs";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import { BsInfoCircleFill } from "react-icons/bs";

export const UninstallShopifyIntegrationModal: React.FC<
  UninstallShopifyIntegrationModalProps
> = ({ workspaceId, integrationId }) => {
  const { closeModal } = useModal();

  const { data: shopifyIntegrationData } =
    trpc.integration.getWorkspaceShopifyIntegrationData.useQuery(
      workspaceId ? { workspaceId } : reactQuerySkipToken,
    );

  const analytics = useLogAnalytics();

  // NOTE (Matt 2024-08-09): If the workspace has no capacity for another Shopify Integration
  // and the reason for no capacity is that it is a shopifyWorkspace, then we know that this
  // workspace's billing plan is managed through the sole connected shopify store. Removing
  // this shopify integration will cancel the billing plan, so we need to inform the user.
  const isRemovingSoleShopifyIntegrationFromShopifyWorkspace =
    shopifyIntegrationData?.canAddShopifyIntegration?.hasCapacity === false &&
    shopifyIntegrationData.canAddShopifyIntegration.reason ===
      "shopifyWorkspace";

  const { mutate: removeShopifyIntegration, isPending: isLoading } =
    trpc.integration.deleteIntegration.useMutation({
      onSuccess: async () => {
        void trpcUtils.integration.getWorkspaceShopifyIntegrationData.invalidate(
          {
            workspaceId,
          },
        );
        toast({
          header: "Shopify integration uninstalled from workspace",
        });
      },
      onSettled: () => {
        closeModal({ type: "uninstallShopifyIntegrationModal" });
      },
    });

  const handleConfirm = () => {
    analytics("integration.shopify.remove", { workspaceId });
    removeShopifyIntegration({ workspaceId, integrationId });
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        closeModal({ type: "uninstallShopifyIntegrationModal" });
      }}
      className="w-auto"
    >
      <ModalLayout
        height="auto"
        width="25vw"
        minWidth={400}
        mainContent={() => (
          <div className="flex flex-col gap-6 p-2 w-full">
            <h2 className="text-xl font-medium">
              Uninstall Shopify Integration
            </h2>
            <p className="text-sm text-default">
              Removing this Shopify integration is the same as uninstalling the
              Replo app from your Shopify store.
            </p>
            {isRemovingSoleShopifyIntegrationFromShopifyWorkspace && (
              <p className="text-sm text-default inline">
                The Shopify Store you are about to disconnect is responsible for
                the billing plan for this workspace. Uninstalling this Shopify
                Integration will cancel your current billing plan. If you wish
                to maintain a billing plan for this workspace that is not
                managed through Shopify, reach out to support.{" "}
                <a
                  href={docs.billingPlans}
                  className="inline-flex cursor-pointer items-center text-xs text-blue-600"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More <BsInfoCircleFill aria-hidden className="ml-1" />
                </a>
              </p>
            )}
            <div className="flex gap-2 self-end">
              <Button
                type="secondary"
                size="base"
                textClassNames="text-xs"
                onClick={() =>
                  closeModal({ type: "uninstallShopifyIntegrationModal" })
                }
              >
                Cancel
              </Button>
              <Button
                type="danger"
                size="base"
                textClassNames="text-xs"
                htmlType="submit"
                isLoading={isLoading}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </div>
          </div>
        )}
      />
    </Modal>
  );
};
